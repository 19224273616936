import {
  Avatar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import useResponsive from '../../../../hooks/useResponsive';
import { useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'grey',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

// 스트링 감시 테이블 컴포넌트
export default function StringTable({ data }) {
  const isDesktop = useResponsive('up', 'lg');

  const [tooltipOpenArray, setTooltipOpenArray] = useState(new Array(data.length).fill(false));

  const columns = [
    { field: 'channel', headerName: '채널', width: 70 },
    { field: 'volt', headerName: '전압(V)', width: 85 },
    { field: 'curr', headerName: '전류(A)', width: 85 },
    { field: 'state', headerName: '상태', width: 60 },
  ];

  // 클릭한 행의 툴팁 상태를 열기
  const handleAvatarClick = (index) => {
    const newTooltipOpenArray = [...tooltipOpenArray];
    newTooltipOpenArray[index] = true;
    setTooltipOpenArray(newTooltipOpenArray);
  };

  // 닫힌 행의 툴팁 상태를 닫기
  const handleTooltipClose = (index) => {
    const newTooltipOpenArray = [...tooltipOpenArray];
    newTooltipOpenArray[index] = false;
    setTooltipOpenArray(newTooltipOpenArray);
  };

  return (
    <>
      <StyledTableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.field}
                  align="center"
                  style={{
                    minWidth: column.width,
                    backgroundColor: '#2065D1',
                    borderRight: '1px solid #d0d0d0',
                  }}
                >
                  {column.headerName}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              const bgColor =
                row.nodata === 1
                  ? 'red'
                  : row.nodata === 0 && row.volt === 0
                  ? 'orange'
                  : row.nodata === 0 && row.volt !== 0
                  ? '#73C000'
                  : '#FFFFFF';

              const tooltipContent =
                row.nodata === 1
                  ? '발전 중단'
                  : row.nodata === 0 && row.volt === 0
                  ? '대기 중'
                  : row.nodata === 0 && row.volt !== 0
                  ? '발전 중'
                  : 'content';

              return (
                <TableRow key={index}>
                  {columns.map((column, columnIndex) => (
                    <TableCell
                      key={column.field}
                      align="center"
                      sx={{
                        borderRight: columnIndex === columns.length - 1 ? 'none' : '1px solid #d0d0d0',
                        backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0', // 회색
                        //backgroundColor: index % 2 === 0 ? '#fff' : '#e1f5fe', // 라이트 블루
                        //backgroundColor: index % 2 === 0 ? '#fff' : '#e3f2fd', // 블루
                        //backgroundColor: index % 2 === 0 ? '#fff' : '#DDEDF7', // 블루 그레이 2
                        //backgroundColor: index % 2 === 0 ? '#fff' : '#E0ECF3', // 블루 그레이 1
                        //backgroundColor: index % 2 === 0 ? '#fff' : '#e8eaf6', // 인디고
                        //backgroundColor: index % 2 === 0 ? '#fff' : '#e0f2f1', // 초록
                      }}
                    >
                      {column.field === 'state' ? (
                        isDesktop ? (
                          <Tooltip title={tooltipContent} arrow>
                            <Avatar
                              sizes="small"
                              sx={{
                                width: 13,
                                height: 13,
                                bgcolor: bgColor,
                                color: bgColor,
                                ml: 'auto',
                                mr: 'auto',
                              }}
                            >
                              {''}
                            </Avatar>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            title={tooltipContent}
                            open={tooltipOpenArray[index]}
                            onClose={() => handleTooltipClose(index)}
                            arrow
                          >
                            <Avatar
                              sizes="small"
                              sx={{
                                width: 13,
                                height: 13,
                                bgcolor: bgColor,
                                color: bgColor,
                                ml: 'auto',
                                mr: 'auto',
                              }}
                              onClick={() => handleAvatarClick(index)}
                            >
                              {''}
                            </Avatar>
                          </Tooltip>
                        )
                      ) : column.field === 'channel' ? (
                        row[column.field]
                      ) : row.nodata === 1 ? null : (
                        row[column.field]
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
}
