import { Box, Button, Card, Chip, Divider, TextField, Typography } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import { convertFormatString, convertFormatStringToYMD } from '../../utils/dateUtil';
import { useEffect, useState } from 'react';
import SearchSubscribeKeyModal from './SearchSubscribeKeyModal';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import dayjs from 'dayjs';

// 구독 라이선스 입력 RTU 리스트 컴포넌트
export default function SelectRtuToPlant({ rtu, plantkey }) {
  const isDesktop = useResponsive('up', 'lg');
  const today = dayjs();

  const [searchSubscribeModal, setSearchSubscribeModal] = useState(false);

  const [selectSubscribekey, setSelectSubscribekey] = useState('');
  const [selectServiceterm, setSelectServiceTerm] = useState(0);

  const openSearchSubscibeModalClickHandler = () => {
    setSearchSubscribeModal(true);
  };
  const closeSearchSubscirbeModalClickHandler = (event, reason) => {
    setSearchSubscribeModal(false);
  };

  const expiryDate = dayjs(rtu.enddate);
  const daysRemaining = expiryDate.diff(today, 'days');

  const backendRequest = useBackendV2();

  const registerSubscriptionClickHandler = async () => {
    if (selectSubscribekey === '') {
      alert('이용권을 선택해주세요.');
      return;
    }

    try {
      const registerSubscriptionRequestConfig = {
        method: 'POST',
        body: {
          subscribekey: selectSubscribekey,
          serviceterm: selectServiceterm,
          rtun: rtu.rtun,
        },
      };

      const registerSubscriptionResult = await backendRequest.sendRequest(
        config.backend.endPoint.carts.keyReg,
        registerSubscriptionRequestConfig
      );

      if (registerSubscriptionResult.data === true) {
        alert('이용권 적용이 완료되었습니다.');
        window.location.reload();
      } else {
        alert('이용권 적용이 실패했습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    setSelectSubscribekey('');
  }, [plantkey]);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          padding: {
            xs: 0,
            sm: 2,
          },
          borderRadius: '10px',
          boxShadow: '0px 3px 12px #00000014',
          width: '100%',
          mb: 1,
        }}
      >
        <Box sx={{ p: 1 }}>
          <Box display="flex" justifyContent="flex-start" alignItems="center">
            <Typography variant="subtitle1" display="flex" alignContent="center">
              RTU 번호 : {rtu.rtun}
            </Typography>
            {rtu.subscribeday >= -90 ? (
              rtu.subscribeday > 0 ? (
                <Chip
                  label={<Typography variant="subtitle2">서비스 만료</Typography>}
                  size={isDesktop ? 'large' : 'small'}
                  color="error"
                  sx={{ ml: 1 }}
                />
              ) : (
                <Chip
                  label={<Typography variant="subtitle2">서비스 만료 예정</Typography>}
                  size={isDesktop ? 'large' : 'small'}
                  color="error"
                  variant="outlined"
                  sx={{ ml: 1 }}
                />
              )
            ) : null}
          </Box>
          <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 1 }}>
            서비스 만료일 :{' '}
            {rtu.enddate && dayjs(rtu.enddate).isValid()
              ? convertFormatStringToYMD(rtu.enddate)
              : '서비스 만료 일자가 경과되지 않았습니다.'}
          </Typography>
          {daysRemaining >= 91 && selectSubscribekey !== '' ? (
            <Typography variant="subtitle2" color="red">
              해당 RTU는 이용기간이 충분히 남은 상태입니다.
            </Typography>
          ) : null}
          <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 3 }}>
            <TextField
              label="구매한 이용권을 조회 후 적용해 주세요."
              variant="outlined"
              size="small"
              value={selectSubscribekey}
              InputProps={{
                readOnly: true,
              }}
              sx={{ width: isDesktop ? '65%' : '100%' }}
            />
            {isDesktop ? (
              <Box display="flex" justifyContent="flex-end" gap={1.5}>
                <Button variant="outlined" onClick={openSearchSubscibeModalClickHandler}>
                  이용권 조회
                </Button>
                <Divider orientation="vertical" variant="middle" flexItem sx={{ bgcolor: '#a0a0a0' }} />
                <Button
                  variant="contained"
                  onClick={registerSubscriptionClickHandler}
                  disabled={selectSubscribekey === ''}
                >
                  이용권 적용
                </Button>
              </Box>
            ) : null}
          </Box>
          {isDesktop ? null : (
            <Box display="flex" justifyContent="flex-end" gap={1.5} sx={{ mt: 1.5 }}>
              <Button variant="outlined" onClick={openSearchSubscibeModalClickHandler}>
                이용권 조회
              </Button>
              <Divider orientation="vertical" variant="middle" flexItem sx={{ bgcolor: '#a0a0a0' }} />
              <Button
                variant="contained"
                onClick={registerSubscriptionClickHandler}
                disabled={selectSubscribekey === ''}
              >
                이용권 적용
              </Button>
            </Box>
          )}
        </Box>
      </Card>
      {searchSubscribeModal && (
        <SearchSubscribeKeyModal
          plantkey={rtu.plantkey}
          setSelectSubscribekey={setSelectSubscribekey}
          setSelectServiceTerm={setSelectServiceTerm}
          open={searchSubscribeModal}
          onClose={closeSearchSubscirbeModalClickHandler}
          isDesktop={isDesktop}
        />
      )}
    </>
  );
}
