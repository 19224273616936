import EntirePlant from '../../components/Dashboard/EntirePlant';
import EachPlant from '../../components/Dashboard/EachPlant';
import { useContext, useEffect, useState } from 'react';
import config from '../../config';
import useBackendV2 from '../../hooks/use-backend-v2';
import AuthContext from '../../store/auth-context';
import { Box, Breadcrumbs } from '@mui/material';
import NoticePopup from '../../components/Authentication/NoticePopup';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PlantNumberGuidanceModal from '../../components/Dashboard/PlantNumberGuidanceModal';
import ConnPlantAlarmModal from '../../components/Dashboard/ConnPlantAlarmModal';
import Header02 from '../../components/Layout/Header02';
import useResponsive from '../../hooks/useResponsive';
import SubHeader from '../../components/Layout/SubHeader';
import Nav from '../../components/Layout/Nav';
import { useNavigate } from 'react-router-dom';

// 대시보드 페이지 컴포넌트
export default function DashboardPage() {
  const authCtx = useContext(AuthContext);
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false); // DNE Templat 상태 추가

  const distance = 0;

  const [noticeType, setNoticeType] = useState('1');

  const pageConditionInitialState = {
    pageSearch: '',
    pagetype: '',
    plantkey: '',
    plantname: '',
    plantAddress: '',
    plantNodata: '',
    plantInvNodata: '',
    subscribeday: '',
  };
  const [page, setPage] = useState(pageConditionInitialState);
  const [plantControll, setPlantControll] = useState(false);
  const [plant, setPlant] = useState();
  const [plantNumberGudiance, setPlantNumberGudiance] = useState(false);
  const [isRequestPlantModal, setIsRequestPlantModal] = useState(false);
  const [connRequestPlantCnt, setConnRequestPlantCnt] = useState(0);

  const backendRequest = useBackendV2();

  const fetchPlants = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.userPlantsV2}/${authCtx.currentUser.id}`
      );

      const selectPlantkey = localStorage.getItem('selectPlantkey');

      if (data.length === 0) {
        setPlantNumberGudiance(true);
        setPage((prevPage) => ({ ...prevPage, pagetype: 'entire' }));
        localStorage.setItem('menuSubValue', 0);
      } else if (data.length === 1) {
        setNoticeType('1');
        setPage((prevPage) => ({
          ...prevPage,
          pageSearch: 1,
          pagetype: 'each',
          plantkey: data[0].plantkey,
          plantname: data[0].plantname,
          plantAddress: data[0].street,
        }));
        setPlantControll(false);
        setPlant(data);
        localStorage.setItem('menuSubValue', 1);
      } else {
        if (selectPlantkey) {
          const selectedPlant = data.find((plant) => plant.plantkey === selectPlantkey);
          if (selectedPlant) {
            setPage((prevPage) => ({
              ...prevPage,
              pagetype: 'each',
              plantkey: selectedPlant.plantkey,
              plantname: selectedPlant.plantname,
              plantAddress: selectedPlant.street,
            }));
            localStorage.setItem('selectPlantkey', selectedPlant.plantkey);
            localStorage.setItem('menuSubValue', 1);
            setPlantControll(true);
            setPlant(data);
          } else {
            setNoticeType('1');
            setPage((prevPage) => ({ ...prevPage, pagetype: 'entire' }));
            setPlantControll(true);
            setPlant(data);
            localStorage.setItem('menuSubValue', 0);
          }
        } else {
          setNoticeType('1');
          setPage((prevPage) => ({ ...prevPage, pagetype: 'entire' }));
          setPlantControll(true);
          setPlant(data);
          localStorage.setItem('menuSubValue', 0);
        }
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다. 인터넷 연결 상태를 확인 후 새로고침을 해주세요.');
    }
  };

  const fetchConnPlantRequestCnt = async () => {
    try {
      const cnt = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.count}/${authCtx.currentUser.id}`
      );
      if (cnt.data > 0) {
        setConnRequestPlantCnt(cnt.data);
        setIsRequestPlantModal(true);
      } else {
        setConnRequestPlantCnt(0);
        setIsRequestPlantModal(false);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const closeRequestPlantModal = () => {
    setIsRequestPlantModal(false);
  };

  const closePlantNumberGuidanceModal = () => {
    setPlantNumberGudiance(false);
  };

  useEffect(() => {
    fetchPlants();
    fetchConnPlantRequestCnt();
    fetchUserStylePreference();
  }, []);

  useEffect(() => {
    if (authCtx.currentUser.role === '사이트 관리자' && page.pagetype === 'entire') {
      navigate('/allStatusPlate');
    }
  }, [page]);

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        plantkey={page.plantkey}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE} // 상태를 Header02에 전달
        setIsMobileViewDNE={setIsMobileViewDNE} // 상태 변경 함수를 전달
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'flex',
          },
        }}
      >
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <StyledBreadcrumb label="모니터링" icon={<ConnectedTvIcon fontSize="small" color="primary" />} />
          <StyledBreadcrumb label={page.pagetype === 'entire' ? '전체 현황' : '개별 현황'} />
        </Breadcrumbs>
      </Box>
      {page.pagetype === 'entire' ? (
        <EntirePlant setPage={setPage} page={page} />
      ) : page.pagetype === 'each' ? (
        <EachPlant
          setPage={setPage}
          page={page}
          setPlantControll={setPlantControll}
          plantControll={plantControll}
          plant={plant}
          isMobileViewDNE={isMobileViewDNE} // isMobileViewDNE 상태 전달
        />
      ) : null}
      <Box>
        <NoticePopup noticeType={noticeType} />
      </Box>
      {plantNumberGudiance && (
        <PlantNumberGuidanceModal open={plantNumberGudiance} onClose={closePlantNumberGuidanceModal} />
      )}
      {isRequestPlantModal && (
        <ConnPlantAlarmModal
          open={isRequestPlantModal}
          onClose={closeRequestPlantModal}
          cnt={connRequestPlantCnt}
        />
      )}
    </>
  );
}
