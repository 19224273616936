import { Box, Card, Typography } from '@mui/material';
import {
  PiCloudDuotone,
  PiCloudFogDuotone,
  PiCloudLightningDuotone,
  PiCloudMoonDuotone,
  PiCloudRainDuotone,
  PiCloudSnowDuotone,
  PiCloudSunDuotone,
  PiMoonStarsDuotone,
  PiSunDuotone,
} from 'react-icons/pi';
import { useState } from 'react';
import WeatherInfoModal from '../WeatherInfoModal';

const weatherIconMap = {
  '001-1': <PiSunDuotone style={{ fontSize: '2rem', color: 'red' }} />,
  '001-2': <PiMoonStarsDuotone style={{ fontSize: '2rem', color: '#D5C450' }} />,
  '002-1': <PiCloudSunDuotone style={{ fontSize: '2rem', color: '#999999' }} />,
  '002-2': <PiCloudMoonDuotone style={{ fontSize: '2rem', color: '#999999' }} />,
  '003': <PiCloudDuotone style={{ fontSize: '2rem', color: '#999999' }} />,
  '004': <PiCloudRainDuotone style={{ fontSize: '2rem', color: '#8490e1' }} />,
  '005': <PiCloudFogDuotone style={{ fontSize: '2rem', color: '#999999' }} />,
  '006': <PiCloudSnowDuotone style={{ fontSize: '2rem', color: '#9B9CA2' }} />,
  '007': <PiCloudLightningDuotone style={{ fontSize: '2rem', color: '#D3A612' }} />,
};

// 개별 발전소 웹뷰 오늘의 날씨 컴포넌트
export default function WebWidgetTodayWeatherInfo({ todayWeather, page, nowTime }) {
  const [openWeatherInfoModal, setOpenWeatherInfoModal] = useState(false);

  const showWeatherInfoModalHandler = () => {
    setOpenWeatherInfoModal(true);
  };
  const closeWeatherInfoModalHandler = () => {
    setOpenWeatherInfoModal(false);
  };

  return (
    <>
      <Card
        sx={{
          // bgcolor: '#0059B2',
          borderRadius: '15px',
          boxShadow: '5px 6px 12px 5px #00000014',
          height: 135,
          p: 1,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle2">오늘의 날씨</Typography>
          <Box onClick={showWeatherInfoModalHandler} display="flex" sx={{ cursor: 'pointer' }}>
            {todayWeather !== '' && weatherIconMap[todayWeather.v_img]}
            <Typography color="#000" variant="h6" sx={{ ml: 1 }}>
              {todayWeather !== '' ? todayWeather.v_temp : null}°C
            </Typography>
          </Box>
        </Box>
        <Box display="flex" sx={{ mt: 1.8 }}>
          <Card
            sx={{
              border: '3px solid #FFF',
              width: '50%',
              borderTop: 'none',
              borderBottom: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0',
              bgcolor: '#F6F6F6',
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography sx={{ ml: 1 }} variant="subtitle2" color="text.secondary" gutterBottom>
                최고
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: 17, mr: 0.5 }} variant="subtitle2" color="error" gutterBottom>
                  {todayWeather !== '' ? todayWeather.v_heig : null}
                </Typography>
                <Typography sx={{ fontSize: 14, mr: 1 }} variant="subtitle2" gutterBottom>
                  ℃
                </Typography>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              border: '3px solid #FFF',
              width: '50%',
              borderTop: 'none',
              borderBottom: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0',
              bgcolor: '#F6F6F6',
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography sx={{ ml: 1 }} variant="subtitle2" color="text.secondary" gutterBottom>
                최저
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: 17, mr: 0.5 }} variant="subtitle2" color="error" gutterBottom>
                  {todayWeather !== '' ? todayWeather.v_low : null}
                </Typography>
                <Typography sx={{ fontSize: 14, mr: 1 }} variant="subtitle2" gutterBottom>
                  ℃
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
        <Box display="flex">
          <Card
            sx={{
              border: '3px solid #FFF',
              width: '50%',
              borderTop: 'none',
              borderBottom: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0',
              bgcolor: '#F6F6F6',
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography sx={{ ml: 1 }} variant="subtitle2" color="text.secondary" gutterBottom>
                습도
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: 17, mr: 0.5 }} variant="subtitle2" color="error" gutterBottom>
                  {todayWeather !== '' ? todayWeather.v_humidity : null}
                </Typography>
                <Typography sx={{ fontSize: 14, mr: 1 }} variant="subtitle2" gutterBottom>
                  %
                </Typography>
              </Box>
            </Box>
          </Card>
          <Card
            sx={{
              border: '3px solid #FFF',
              width: '50%',
              borderTop: 'none',
              borderBottom: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0',
              bgcolor: '#F6F6F6',
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography sx={{ ml: 1 }} variant="subtitle2" color="text.secondary" gutterBottom>
                바람
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography sx={{ fontSize: 17, mr: 0.5 }} variant="subtitle2" color="error" gutterBottom>
                  {todayWeather !== '' ? todayWeather.v_wind : null}
                </Typography>
                <Typography sx={{ fontSize: 14, mr: 1 }} variant="subtitle2" gutterBottom>
                  ㎧
                </Typography>
              </Box>
            </Box>
          </Card>
        </Box>
      </Card>
      {openWeatherInfoModal && (
        <WeatherInfoModal
          open={openWeatherInfoModal}
          onClose={closeWeatherInfoModalHandler}
          page={page}
          nowTime={nowTime}
        />
      )}
    </>
  );
}
