import {
  Autocomplete,
  Box,
  Chip,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import sol_logo_login from '../../assets/EtcImg/sol_logo_login.png';
import { useContext, useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import AuthContext from '../../store/auth-context';
import PlantInverterChartCard from '../../components/StatusPlate/PlantInverterChartCard';
import PlantInverterInfoTableCard from '../../components/StatusPlate/PlantInverterInfoTableCard';
import PlantAcbVcbInfoCard from '../../components/StatusPlate/PlantAcbVcbInfoCard';
import useInterval from '../../hooks/use-interval';
import { convertFormatStringToYMD, convertStringToFormatDateString, formatTime } from '../../utils/dateUtil';
import useResponsive from '../../hooks/useResponsive';
import {
  PiCloudDuotone,
  PiCloudFogDuotone,
  PiCloudLightningDuotone,
  PiCloudMoonDuotone,
  PiCloudRainDuotone,
  PiCloudSnowDuotone,
  PiCloudSunDuotone,
  PiMoonStarsDuotone,
  PiSunDuotone,
} from 'react-icons/pi';
import PlantEletricSystemCard from '../../components/StatusPlate/PlantEletricSystemCard';
import UseCustomSort from '../../hooks/useCustomSort';

// 경륜 작업 임포트
import PlantImageCard_02 from '../../components/StatusPlate/PlantImageCard_02';
import PlantImageCard_03 from '../../components/StatusPlate/PlantImageCard_03';
import PlantImageCard_04 from '../../components/StatusPlate/PlantImageCard_04';
import PlantTotoalDevelopmentCard_02 from '../../components/StatusPlate/PlantTotoalDevelopmentCard_02';
import PlantMonthlyDevelopmentCard_02 from '../../components/StatusPlate/PlantMonthlyDevelopmentCard_02';
import PlantDailyDevelopmentCard_02 from '../../components/StatusPlate/PlantDailyDevelopmentCard_02';
import PlantDailyDevelopmentTimeCard_02 from '../../components/StatusPlate/PlantDailyDevelopmentTimeCard_02';
import PlantTCO2Card_02 from '../../components/StatusPlate/PlantTCO2Card_02';
import PlantWeatherCard_02 from '../../components/StatusPlate/PlantWeatherCard_02';
import PlantRtuInverterStateCard_02 from '../../components/StatusPlate/PlantRtuInverterStateCard_02';
import PlantRtuInverterStateCard_03 from '../../components/StatusPlate/PlantRtuInverterStateCard_03';
import PlantRtuInverterStateCard_04 from '../../components/StatusPlate/PlantRtuInverterStateCard_04';
import PlantWeatherObserveInfoCard_02 from '../../components/StatusPlate/PlantWeatherObserveInfoCard_02';
import { useNavigate } from 'react-router-dom';
import usePageLogRecord from '../../hooks/usePageLogRecord';

const weatherIconMap = {
  '001-1': <PiSunDuotone style={{ fontSize: '2rem', color: 'red' }} />,
  '001-2': <PiMoonStarsDuotone style={{ fontSize: '2rem', color: '#D5C450' }} />,
  '002-1': <PiCloudSunDuotone style={{ fontSize: '2rem', color: '#999999' }} />,
  '002-2': <PiCloudMoonDuotone style={{ fontSize: '2rem', color: '#999999' }} />,
  '003': <PiCloudDuotone style={{ fontSize: '2rem', color: '#999999' }} />,
  '004': <PiCloudRainDuotone style={{ fontSize: '2rem', color: '#8490e1' }} />,
  '005': <PiCloudFogDuotone style={{ fontSize: '2rem', color: '#999999' }} />,
  '006': <PiCloudSnowDuotone style={{ fontSize: '2rem', color: '#9B9CA2' }} />,
  '007': <PiCloudLightningDuotone style={{ fontSize: '2rem', color: '#D3A612' }} />,
};

// 현황판 모달 페이지 컴포넌트
export default function StatusPlatePage() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const pageConditionInitialState = {
    pagetype: '',
    plantkey: '',
    plantname: '',
    street: '',
  };

  const isDesktop = useResponsive('up', 'lg');
  const isIpad = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const isNexthub = useMediaQuery('(min-width: 1025px) and (max-width: 1366px)');

  const [page, setPage] = useState(pageConditionInitialState);
  const [selectPlant, setSelectPlant] = useState([]);
  const [basePlantInfo, setBasePlantInfo] = useState([]);
  const [invDailyDevelopment, setInvDailyDevelopment] = useState([]);
  const [acbVcbDailyDevelopment, setAcbVcbDailyDevelopment] = useState([]);
  const [nowTime, setNowTime] = useState(Date.now());

  const [todayWeather, setTodayWeather] = useState('');
  const [subscribeday, setSubscribeday] = useState(null);
  const [graceperiodDay, setGraceperiodDay] = useState(null);

  // 전체 발전소
  const numPlants = Array.isArray(selectPlant) ? selectPlant.length : 0;

  // 총 발전량 계산
  const totalYieldSum = invDailyDevelopment.reduce((total, inverter) => {
    return total + (inverter.totalyield || 0);
  }, 0);

  // 월발전량 계산
  const totalMonthyYield = invDailyDevelopment.reduce((total, inverter) => {
    return total + (inverter.monthyield || 0);
  }, 0);

  // 일일발전량 계산
  const totalDailyYield = invDailyDevelopment.reduce((total, inverter) => {
    return total + (inverter.dailyyield || 0);
  }, 0);

  // 발전시간 계산
  const roundedTotalDailyYield = parseFloat(totalDailyYield.toFixed(1));

  const totalDailyDevelopmentTime =
    basePlantInfo.length !== 0 ? roundedTotalDailyYield / basePlantInfo[0].capacity : 0;

  const roundedTotalDailyDevelopmentTime = parseFloat(totalDailyDevelopmentTime.toFixed(1));

  // 온실가스 감축량 총합
  const totalGhgReduction = invDailyDevelopment.reduce((total, inverter) => {
    return total + (inverter.ghgReduction || 0);
  }, 0);

  const roundedTotalGhgReduction = parseFloat(totalGhgReduction.toFixed(1));

  // 나무 계산 총합
  const totalPineTree = invDailyDevelopment.reduce((total, inverter) => {
    return total + (inverter.pineTree || 0);
  }, 0);

  const roundedTotalPineTree = parseFloat(totalPineTree.toFixed(1));

  const [currentData, setCurrentData] = useState(roundedTotalGhgReduction);
  const [currentFormCO2, setCurrentFormCO2] = useState(true);

  const selectPlantChangeHandler = (plant) => {
    setPage((prev) => {
      return { ...prev, plantkey: plant.plantkey, plantname: plant.plantname, street: plant.street };
    });

    localStorage.setItem('selectPlantkey', plant.plantkey);
  };

  const backendRequest = useBackendV2();

  const fetchUserPlants = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.userPlantsV2}/${authCtx.currentUser.id}`
      );

      if (data.length === 1) {
        setPage((prevPage) => ({
          ...prevPage,
          plantkey: data[0].plantkey,
          plantname: data[0].plantname,
          street: data[0].street,
        }));
        setSelectPlant(data);
      } else {
        setSelectPlant(data);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchSubscriptionPeriod = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.subscribe}/${page.plantkey}`
      );

      const filteredData = data.filter((item) => item.subscribeday > -90);

      if (filteredData.length > 0) {
        const maxSubdcribedayItem = filteredData.reduce((maxItem, currentItem) => {
          return currentItem.subscribeday > maxItem.subscribeday ? currentItem : maxItem;
        });

        setSubscribeday(maxSubdcribedayItem.subscribeday);
        setGraceperiodDay(data[0]?.graceperiod);
      } else {
        setSubscribeday(null);
        setGraceperiodDay(null);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchBasePlantInfoData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.baseInfo}/${page.plantkey}`
      );
      setBasePlantInfo(data);
    } catch (error) {
      setBasePlantInfo([]);
    }
  };

  const fetchDailyInvDevelopmentData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.inverters.extReal}/${page.plantkey}`
      );

      const sortedData = UseCustomSort(data, 'invertername');

      setInvDailyDevelopment(sortedData);
    } catch (error) {
      setInvDailyDevelopment([]);
    }
  };

  const fetchAcbVcbDailyDevelopmentData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.acbvcbs.extReal}/${page.plantkey}`
      );
      setAcbVcbDailyDevelopment(data);
    } catch (error) {
      setAcbVcbDailyDevelopment([]);
    }
  };

  const weatherObservationRequest = useBackendV2();

  const fetchWeatherObservation = async () => {
    try {
      const weatherObservationRequestConfig = {
        method: 'POST',
        body: {
          address: page.street,
        },
      };
      const weatherObservationResult = await weatherObservationRequest.sendRequest(
        config.backend.endPoint.envs.weather,
        weatherObservationRequestConfig
      );

      if (weatherObservationResult.data !== null) {
        setTodayWeather(weatherObservationResult.data);
      } else {
        alert('오류가 발생하였습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const pollingInterval = 1000; // unit: milliseconds
  useInterval(() => {
    setNowTime(Date.now());
  }, pollingInterval);

  const fetchPollingInterval = 60000;

  useInterval(() => {
    if (page.plantkey !== '') {
      fetchBasePlantInfoData();
      fetchDailyInvDevelopmentData();
      fetchAcbVcbDailyDevelopmentData();
    }
  }, fetchPollingInterval);

  useEffect(() => {
    fetchUserPlants();
  }, []);

  useEffect(() => {
    if (page.plantkey !== '') {
      fetchBasePlantInfoData();
      fetchDailyInvDevelopmentData();
      fetchAcbVcbDailyDevelopmentData();
      fetchSubscriptionPeriod();
    }
  }, [page]);

  useInterval(() => {
    fetchWeatherObservation();
  }, fetchPollingInterval);

  useEffect(() => {
    fetchWeatherObservation();
  }, [page]);

  useEffect(() => {
    // 초기 렌더링 시에 호출될 로직
    const localStorageSelectPlantkey = localStorage.getItem('selectPlantkey');

    // selectPlantState에서 해당하는 발전소 탐색
    const initialSelectedPlant = selectPlant.find((option) => option.plantkey === localStorageSelectPlantkey);

    // 첫번째 발전소 탐색
    const initialSelectedPlantN = selectPlant[0];

    // 찾은 발전소를 초기값으로 설정, initialSelectedPlant가 undefined인 경우에 대한 처리 추가
    if (initialSelectedPlant) {
      selectPlantChangeHandler(initialSelectedPlant);

      setPage((prev) => ({
        ...prev,
        plantkey: initialSelectedPlant.plantkey,
        plantname: initialSelectedPlant.plantname,
        street: initialSelectedPlant.street,
      }));
    } else if (initialSelectedPlantN) {
      //  initialSelectedPlant가 없는 경우에 대한 첫번째 발전소를 선택하는 로직을 추가.
      selectPlantChangeHandler(initialSelectedPlantN);

      setPage((prev) => ({
        ...prev,
        plantkey: initialSelectedPlantN.plantkey,
        plantname: initialSelectedPlantN.plantname,
        street: initialSelectedPlantN.street,
      }));
    }
  }, [selectPlant]);

  useEffect(() => {
    setCurrentData(() => (currentFormCO2 ? roundedTotalGhgReduction : roundedTotalPineTree));
  }, [currentFormCO2, roundedTotalGhgReduction, roundedTotalPineTree]);

  useEffect(() => {
    const dataInterval = setInterval(() => {
      setCurrentFormCO2((prevForm) => !prevForm);
    }, 3500);

    // 컴포넌트가 마운트 해제되면 타이머 클리어
    return () => {
      clearInterval(dataInterval);
    };
  }, []);

  const closeModalHandler = () => {
    localStorage.setItem('menuValue', 0);
    localStorage.setItem('menuSubValue', 0);
    navigate('/', { state: { subValue: 0 } });
  };

  usePageLogRecord('statusPlate', page.plantkey);

  return (
    <>
      <Container
        sx={{
          minWidth: '100%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            bgcolor: '#fff',
          }}
        >
          <Box display="flex" alignItems="center" sx={{ mb: 1, mt: 2, ml: 2 }}>
            {basePlantInfo.length > 0 && basePlantInfo[0].logoimgkey ? (
              <img
                src={`https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=${process.env.REACT_APP_DOMAIN}&id=${basePlantInfo[0].logoimgkey}`}
                alt="Plant Logo"
                style={{ width: isDesktop ? 200 : 180 }}
              />
            ) : (
              <>
                <img src={sol_logo_login} alt="SOLARCUBE" style={{ width: 45, height: 45 }} />
                <Typography
                  variant="h6"
                  color="inherit"
                  sx={{
                    display: 'flex',
                    fontFamily: '"Segoe UI Symbol"',
                    ml: 2,
                    fontWeight: 'bold',
                  }}
                >
                  SOLAR CUBE
                </Typography>
              </>
            )}
            {selectPlant.length > 1 ? (
              numPlants > 11 ? (
                <Autocomplete
                  disableClearable
                  id="combo-box-demo"
                  options={selectPlant}
                  getOptionLabel={(option) => option.plantname || ''}
                  value={
                    selectPlant.find((option) => option.plantkey === page.plantkey) ||
                    selectPlant.find(
                      (option) => option.plantkey === parseInt(localStorage.getItem('selectPlantkey'), 10)
                    ) ||
                    selectPlant[0]
                  }
                  onChange={(e, newValue) => {
                    if (newValue) {
                      const selectedPlant = selectPlant.find(
                        (option) => option.plantkey === newValue.plantkey
                      );
                      selectPlantChangeHandler(selectedPlant);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="발전소"
                      variant="outlined"
                      sx={{ background: '#F8F9FE 0% 0% no-repeat padding-box' }}
                    />
                  )}
                  sx={{ width: 550, ml: 4 }}
                />
              ) : (
                <FormControl sx={{ width: 550, ml: 4, bgcolor: '#F8F9FE' }}>
                  <InputLabel>발전소</InputLabel>
                  <Select
                    label="발전소"
                    value={page.plantkey || ''}
                    onChange={(e) => {
                      const selectedPlant = selectPlant.find((option) => option.plantkey === e.target.value);
                      selectPlantChangeHandler(selectedPlant);
                    }}
                  >
                    {selectPlant.map((option) => (
                      <MenuItem key={option.plantkey} value={option.plantkey}>
                        {option.plantname}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              )
            ) : (
              <Typography variant="h5" sx={{ ml: 3 }}>
                {page.plantname}
              </Typography>
            )}
            {subscribeday !== null && subscribeday > -90 ? (
              subscribeday < 0 ? (
                <Chip
                  label={`서비스 만료 D${subscribeday}`}
                  color="error"
                  variant="outlined"
                  sx={{ ml: 1 }}
                />
              ) : (
                <Chip label="서비스 만료" color="error" variant="contained" sx={{ ml: 1 }} />
              )
            ) : null}
          </Box>
          <IconButton onClick={closeModalHandler} size="large">
            <CloseIcon sx={{ fontSize: '2.1rem' }} />
          </IconButton>
        </Box>
        <hr />
        <Box
          sx={{
            width: '100%',
            p: 1.5,
            background: '#F8F9FE 0% 0% no-repeat padding-box',
            filter: subscribeday - graceperiodDay > 0 ? 'blur(15px)' : '',
          }}
        >
          <Box>
            <Grid container spacing={0}>
              <Grid item xs={24}>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={6} sm={6} xs={6}>
                    <Grid container spacing={1.5}>
                      <Grid item sm={6} xs={12} md={3} lg={12}>
                        <PlantImageCard_02
                          capacity={basePlantInfo.length === 0 ? null : basePlantInfo[0].capacity}
                          isIpad={isIpad}
                          isNexthub={isNexthub}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12} md={3} lg={12}>
                        <PlantImageCard_03
                          nowDateValue={convertFormatStringToYMD(nowTime)}
                          isIpad={isIpad}
                          isNexthub={isNexthub}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12} md={3} lg={12}>
                        <PlantImageCard_04
                          nowTimeValue={formatTime(nowTime)}
                          isIpad={isIpad}
                          isNexthub={isNexthub}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={2} md={6} sm={6} xs={6}>
                    <PlantTotoalDevelopmentCard_02
                      totalValue={(totalYieldSum * 0.001).toFixed(1)}
                      isIpad={isIpad}
                      isNexthub={isNexthub}
                    />
                  </Grid>
                  <Grid item lg={2} md={6} sm={6} xs={6}>
                    <PlantMonthlyDevelopmentCard_02
                      monthValue={totalMonthyYield.toFixed(1)}
                      isIpad={isIpad}
                      isNexthub={isNexthub}
                    />
                  </Grid>
                  <Grid item lg={2} md={6} sm={6} xs={6}>
                    <PlantDailyDevelopmentCard_02
                      dateValue={totalDailyYield.toFixed(1)}
                      isIpad={isIpad}
                      isNexthub={isNexthub}
                    />
                  </Grid>
                  <Grid item lg={2} md={6} sm={6} xs={6}>
                    <PlantDailyDevelopmentTimeCard_02
                      generatedValue={roundedTotalDailyDevelopmentTime}
                      isIpad={isIpad}
                      isNexthub={isNexthub}
                    />
                  </Grid>
                  <Grid item lg={2} md={6} sm={6} xs={6}>
                    <Grid container spacing={2}>
                      <Grid item sm={6} xs={12} md={3} lg={12}>
                        <PlantTCO2Card_02
                          currentFormCO2={currentFormCO2}
                          currentData={currentData}
                          isIpad={isIpad}
                          isNexthub={isNexthub}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12} md={3} lg={12}>
                        <PlantWeatherCard_02
                          temp={todayWeather !== '' ? todayWeather.v_temp : null}
                          tempHigh={todayWeather !== '' ? todayWeather.v_heig : null}
                          tempLow={todayWeather !== '' ? todayWeather.v_low : null}
                          humidity={todayWeather !== '' ? todayWeather.v_humidity : null}
                          wind={todayWeather !== '' ? todayWeather.v_wind : null}
                          weatherIconMap={todayWeather !== '' && weatherIconMap[todayWeather.v_img]}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mt: 2 }}>
              <Grid item xs={24}>
                <Grid container spacing={2}>
                  <Grid item lg={2} md={6} sm={6} xs={6}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <Grid container spacing={1}>
                          <Grid item lg={6} md={6} sm={6} xs={6}>
                            <PlantRtuInverterStateCard_02
                              basePlantInfo={basePlantInfo}
                              isIpad={isIpad}
                              isNexthub={isNexthub}
                            />
                          </Grid>
                          <Grid item lg={6} md={6} sm={6} xs={6}>
                            <PlantRtuInverterStateCard_03
                              basePlantInfo={basePlantInfo}
                              invDailyDevelopment={invDailyDevelopment}
                              isIpad={isIpad}
                              isNexthub={isNexthub}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item sm={6} xs={12} md={3} lg={12}>
                        <PlantRtuInverterStateCard_04
                          lastReceptionDate={convertStringToFormatDateString(
                            basePlantInfo[0]?.rtuinfos[0].localdate
                          )}
                          isIpad={isIpad}
                          isNexthub={isNexthub}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item lg={8} md={6} sm={6} xs={6}>
                    <PlantEletricSystemCard
                      invDailyDevelopment={invDailyDevelopment}
                      acbVcbDailyDevelopment={acbVcbDailyDevelopment}
                      basePlantInfo={basePlantInfo}
                      isIpad={isIpad}
                      isNexthub={isNexthub}
                    />
                  </Grid>
                  <Grid item lg={2} md={6} sm={6} xs={6}>
                    <PlantWeatherObserveInfoCard_02
                      tempHigh={todayWeather !== '' ? todayWeather.v_heig : null}
                      tempLow={todayWeather !== '' ? todayWeather.v_low : null}
                      humidity={todayWeather !== '' ? todayWeather.v_humidity : null}
                      wind={todayWeather !== '' ? todayWeather.v_wind : null}
                      page={page}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={0} sx={{ mt: 4 }}>
              <Grid item xs={24}>
                <Grid container spacing={2}>
                  <Grid item lg={acbVcbDailyDevelopment.length === 0 ? 6 : 4} md={6} sm={6} xs={6}>
                    <PlantInverterChartCard invDailyDevelopment={invDailyDevelopment} page={page} />
                  </Grid>
                  <Grid item lg={acbVcbDailyDevelopment.length === 0 ? 6 : 4} md={6} sm={6} xs={6}>
                    <PlantInverterInfoTableCard
                      basePlantInfo={basePlantInfo}
                      invDailyDevelopment={invDailyDevelopment}
                    />
                  </Grid>
                  {acbVcbDailyDevelopment.length === 0 ? null : (
                    <Grid item lg={4} md={6} sm={6} xs={6}>
                      <PlantAcbVcbInfoCard
                        basePlantInfo={basePlantInfo}
                        invDailyDevelopment={invDailyDevelopment}
                        acbVcbDailyDevelopment={acbVcbDailyDevelopment}
                      />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignContent: 'center',
            bgcolor: '#e8eaf6',
            width: '100%',
          }}
        >
          <Typography variant="subtitle2" color="#455a64" sx={{ mt: 2.5, mb: 2.5, ml: 2 }}>
            발전소 주소 : {page.street}
          </Typography>
          <Typography variant="subtitle1" color="#455a64" sx={{ mt: 2.5, mb: 2.5, mr: 2 }}>
            Copyright 2023. QubeSoft. All rights reserved
          </Typography>
        </Box>
      </Container>
    </>
  );
}
