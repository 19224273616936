import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useResponsive from '../../hooks/useResponsive';
import Scrollbar from '../Scrollbar/Scrollbar';
import { Avatar, Box, Chip, Divider, Drawer, IconButton, Typography } from '@mui/material';
import NavSection from './NavSection';
import logo from '../../assets/EtcImg/SOLARCUBE.png';
import qubelogo from '../../assets/EtcImg/sol_logo_login.png';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

// 사이드 네비게이션 컴포넌트
export default function Nav({
  openNav,
  onCloseNav,
  value,
  setValue,
  setSubValue,
  subValue,
  webNavOpen,
  setWebNavOpen,
}) {
  const { pathname } = useLocation();

  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'lg');

  const NAV_WIDTH = webNavOpen ? 350 : 75;
  const NAV_WIDTH_MO = 250;

  const navWidthHandler = () => {
    setWebNavOpen(!webNavOpen);
  };

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
  }, [pathname]);

  const renderContent = (
    <>
      <Box
        sx={{
          minHeight: 0,
          overflow: 'hidden auto',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          bgcolor: '#FFF',
        }}
      >
        <Scrollbar
          sx={{
            height: 1,
            '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' },
          }}
        >
          <Box display="flex" sx={{ p: 2, justifyContent: webNavOpen ? 'space-between' : 'center' }}>
            <Box display="flex" sx={{ display: 'inline-flex', alignItems: 'center' }}>
              {webNavOpen ? (
                <>
                  <img src={qubelogo} alt="SOLARCUBE" style={{ width: 35 }} />
                  <Typography
                    color="inherit"
                    sx={{
                      ml: 0.5,
                      display: 'flex',
                      fontFamily: '"Segoe UI Symbol"',
                      fontWeight: 'bold',
                      color: 'black',
                      fontSize: '1.2rem',
                    }}
                  >
                    SOLAR CUBE
                  </Typography>
                </>
              ) : null}
            </Box>
            {isDesktop ? (
              <Avatar
                onClick={navWidthHandler}
                sx={{
                  width: 35,
                  height: 35,
                  bgcolor: '#3a6eff',
                  cursor: 'pointer',
                  transform: webNavOpen ? 'none' : 'scaleX(-1)',
                }}
              >
                <MenuOpenIcon />
              </Avatar>
            ) : null}
          </Box>
          <NavSection
            value={value}
            setValue={setValue}
            setSubValue={setSubValue}
            subValue={subValue}
            webNavOpen={webNavOpen}
            setWebNavOpen={setWebNavOpen}
            onCloseNav={onCloseNav}
            isDesktop={isDesktop}
          />
        </Scrollbar>
      </Box>
      {isDesktop ? null : (
        <>
          <Divider sx={{ borderBottom: '1px solid #000' }} />
          <Box
            sx={{
              p: 1,
              bgcolor: '#F4F6F8',
            }}
          >
            <Typography variant="subtitle2" color="#808080">
              Copyright {new Date().getFullYear()}. QubeSoft.
            </Typography>
            <Typography variant="subtitle2" color="#808080">
              All rights reserved
            </Typography>
          </Box>
        </>
      )}
    </>
  );
  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              // bgcolor: 'background.default',
              borderRightStyle: 'solid',
              borderRightColor: '#9A9797',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { width: NAV_WIDTH_MO },
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
