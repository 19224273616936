import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import PersonRemoveIcon from '@mui/icons-material/PersonRemove';

export default function UserDeleteAnnouncementDetail({ userDeleteAnnouncementDetailClickHandler }) {
  return (
    <List component="nav" sx={{ mt: -1 }}>
      <ListItemButton onClick={userDeleteAnnouncementDetailClickHandler}>
        <ListItemIcon>
          <PersonRemoveIcon />
        </ListItemIcon>
        <ListItemText primary="계정 및 데이터 삭제 안내" />
      </ListItemButton>
    </List>
  );
}
