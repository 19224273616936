import { Box, Breadcrumbs, Container } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import useResponsive from '../../hooks/useResponsive';
import OrderInfoList from '../../components/Order/OrderInfoList';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import Header02 from '../../components/Layout/Header02';
import SubHeader from '../../components/Layout/SubHeader';
import Nav from '../../components/Layout/Nav';
import usePageLogRecord from '../../hooks/usePageLogRecord';
import AuthContext from '../../store/auth-context';

// 주문정보 페이지 컴포넌트
export default function OrderInfoPage() {
  const isDesktop = useResponsive('up', 'lg');
  const isIPadMini = useResponsive('up', 'sm');
  const authCtx = useContext(AuthContext);

  const { id } = useParams(); // userid

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));

  const distance = 0;

  const [userOrderInfo, setUserOrderInfo] = useState([]);
  const [orderDepositorStatus, setOrderDepositorStatus] = useState(false);
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const currentDate = new Date();
  currentDate.setDate(currentDate.getDate() - 7);

  const backendRequest = useBackendV2();

  const fetchGetUserOrderInfo = async () => {
    try {
      const { data } = await backendRequest.sendRequest(`${config.backend.endPoint.orders.list}/${id}`);
      setUserOrderInfo(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchGetUserOrderInfo();
  }, [orderDepositorStatus]);

  useEffect(() => {
    fetchUserStylePreference();
  }, []);

  usePageLogRecord('orderInfos');

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        // plantkey={page.plantkey}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      {isDesktop || isIPadMini ? (
        <Container
          sx={{
            minWidth: '75%',
            marginBottom: '4%',
          }}
        >
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <StyledBreadcrumb
              label="주문 내역"
              icon={<InventoryOutlinedIcon fontSize="small" color="primary" />}
            />
          </Breadcrumbs>
          <Box sx={{ mt: 2 }}>
            <OrderInfoList userOrderInfo={userOrderInfo} setOrderDepositorStatus={setOrderDepositorStatus} />
          </Box>
        </Container>
      ) : (
        <Box>
          <OrderInfoList userOrderInfo={userOrderInfo} setOrderDepositorStatus={setOrderDepositorStatus} />
        </Box>
      )}
    </>
  );
}
