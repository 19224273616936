import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';

export default function LogOutDetail({ logOutDetailClickHandler }) {
  return (
    <List component="nav" sx={{ mt: -1 }}>
      <ListItemButton onClick={logOutDetailClickHandler}>
        <ListItemIcon>
          <LogoutOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="로그아웃" />
      </ListItemButton>
    </List>
  );
}
