import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { useEffect } from 'react';
import {
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  loadCaptchaEnginge,
  validateCaptcha,
} from 'react-simple-captcha';

// 아이디 찾기 보안숫자(CAPTCHA) 입력 컴포넌트
export default function InputCaptha({ setCaptchaAuthentication, captchaInput, cancelJoin, isMobile }) {
  const captchaClick = () => {
    let user_captcha = captchaInput.value;

    if (validateCaptcha(user_captcha) == true) {
      setCaptchaAuthentication(true);
    } else {
      alert('보안숫자가 일치 하지 않습니다.');
      loadCaptchaEnginge(6, 'black', 'white', 'numbers');
    }
  };

  useEffect(() => {
    loadCaptchaEnginge(6, 'black', 'white', 'numbers');
  }, []);

  return (
    <>
      <Box sx={{ width: { xs: '100%', md: 500 } }}>
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Box sx={{ mt: 1 }}>
            <Typography
              display="flex"
              alignItems="center"
              variant="subtitle1"
              sx={{ fontSize: isMobile ? 14 : 16 }}
            >
              보안숫자를 입력하세요.
            </Typography>
          </Box>
        </Box>
        <Paper
          variant="outlined"
          sx={{
            flexDirection: 'column',
            width: '100%',
            border: '2px solid #D8D8D8',
            mt: 1.5,
            mb: 1,
          }}
        >
          <Box sx={{ mt: 1.5, ml: 1.5 }}>
            <LoadCanvasTemplate reloadText="[ 새로고침 ]" reloadColor="black" />
          </Box>
          <hr />
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <TextField
              label="보안숫자를 입력하세요."
              variant="outlined"
              required
              sx={{ mt: 1, backgroundColor: 'white', width: '95%', mb: 2 }}
              onChange={captchaInput.valueChangeHandler}
              onBlur={captchaInput.inputBlurHandler}
              value={captchaInput.value}
            />
          </Box>
        </Paper>
        <Box sx={{ mt: 2.5, mb: 2, width: '100%' }}>
          <Button type="button" variant="contained" onClick={captchaClick} sx={{ width: '100%' }}>
            로봇이 아닙니다.
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={cancelJoin}
            size="large"
            sx={{ width: '100%', mt: 1 }}
          >
            돌아가기
          </Button>
        </Box>
      </Box>
    </>
  );
}
