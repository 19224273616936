import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { useEffect } from 'react';

// 비밀번호 재설정 회원의 비밀번호 재설정 컴포넌트
export default function ResetPwdForm({
  idInput,
  tempNumInput,
  passwordInput,
  passwordConfirmInput,
  cancelJoin,
  isMobile,
}) {
  const navigate = useNavigate();

  const formIsValid = tempNumInput.isValid && passwordInput.isValid && passwordConfirmInput.isValid;

  const updatePwdRequest = useBackendV2();

  const updatePwdHandler = async () => {
    if (!formIsValid) {
      return;
    }

    try {
      const updatePwdRequestConfig = {
        method: 'PUT',
        body: {
          id: idInput.value.trim(),
          tempnum: tempNumInput.value.trim(),
          newpwd: passwordInput.value.trim(),
        },
      };
      const updatePwdResult = await updatePwdRequest.sendRequest(
        config.backend.endPoint.users.tempPwdUpdate,
        updatePwdRequestConfig,
        false
      );

      if (updatePwdResult.data === true) {
        alert('비밀번호 재설정이 완료되었습니다.');
        navigate('/login02');
      } else {
        alert('비밀번호 재설정을 실패했습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발행하였습니다.');
    }
  };

  // 5분 타이머 및 경고창 설정
  useEffect(() => {
    // 타이머 간격을 5분(밀리초로 변환)으로 설정
    const timerInterval = 5 * 60 * 1000;
    // 타이머 식별자를 저장할 변수
    let timerId;

    // 경고창을 표시하고 로그인 화면으로 이동하는 함수
    const showAlertAndNavigate = () => {
      alert('5분이 경과하여 로그인 화면으로 이동합니다.');
      navigate('/login02');
    };

    // 지정된 시간이 경과하면 showAlertAndNavigate 함수를 실행하는 타이머 설정
    timerId = setTimeout(showAlertAndNavigate, timerInterval);

    // 페이지 이동 전에 alert 창이 먼저 나오도록 수정
    const handleBeforeUnload = () => {
      // 타이머 해제 및 경고창 표시
      clearTimeout(timerId);
      showAlertAndNavigate();
    };

    // beforeunload 이벤트에 handleBeforeUnload(반환값) 함수 등록
    window.addEventListener('beforeunload', handleBeforeUnload);

    // 컴포넌트 언마운트 시 타이머 해제 및 이벤트 핸들러 제거
    return () => {
      clearTimeout(timerId);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [navigate]);

  return (
    <>
      <Box sx={{ width: { xs: '100%', md: 500 } }}>
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Box sx={{ mt: 1 }}>
            <Typography variant="subtitle1" sx={{ fontSize: isMobile ? 14 : 16 }}>
              본인 휴대전화로 전송 된 인증번호를 입력하신 다음, 새로운 비밀번호를 재설정 합니다.
            </Typography>
            <Typography variant="body2" sx={{ fontSize: isMobile ? 12 : 14, mt: 0.5 }}>
              (인증번호를 받고 5분 이내에 등록하세요.)
            </Typography>
          </Box>
        </Box>
        <Paper
          variant="outlined"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            border: '2px solid #D8D8D8',
            alignItems: 'center',
            mt: 1.5,
            mb: 1,
          }}
        >
          <TextField
            label="인증번호(6자리)"
            variant="outlined"
            required
            onChange={tempNumInput.valueChangeHandler}
            onBlur={tempNumInput.inputBlurHandler}
            value={tempNumInput.value.replace(/\s/g, '').trim()}
            error={tempNumInput.hasError}
            helperText={tempNumInput.hasError && '6자리 숫자만 가능'}
            autocomplete="nope"
            sx={{ mt: 2, backgroundColor: 'white', width: '95%' }}
          />
          <TextField
            type="password"
            label="비밀번호"
            variant="outlined"
            required
            sx={{ mt: 1, backgroundColor: 'white', width: '95%' }}
            onChange={passwordInput.valueChangeHandler}
            onBlur={passwordInput.inputBlurHandler}
            value={passwordInput.value.replace(/\s/g, '').trim()}
            error={passwordInput.hasError}
            helperText={
              passwordInput.hasError &&
              '최소 8자. 최대 25자. 최소 영문자 1개, 숫자 1개, 특수문자 1개 포함. 대소문자 구분'
            }
            autoComplete="new-password"
          />
          <TextField
            type="password"
            label="비밀번호 확인"
            variant="outlined"
            required
            sx={{ mt: 1, mb: 2, backgroundColor: 'white', width: '95%' }}
            onChange={passwordConfirmInput.valueChangeHandler}
            onBlur={passwordConfirmInput.inputBlurHandler}
            value={passwordConfirmInput.value.replace(/\s/g, '').trim()}
            error={passwordConfirmInput.hasError}
            helperText={passwordConfirmInput.hasError && '비밀번호가 일치하지 않습니다.'}
            autocomplete="new-password"
          />
        </Paper>
        <Box sx={{ mt: 2.5, mb: 2, width: '100%' }}>
          <Button
            type="button"
            variant="contained"
            disabled={!formIsValid}
            onClick={updatePwdHandler}
            size="large"
            sx={{ width: '100%' }}
          >
            완료
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={cancelJoin}
            size="large"
            sx={{ width: '100%', mt: 1 }}
          >
            돌아가기
          </Button>
        </Box>
      </Box>
    </>
  );
}
