import { Grid } from '@mui/material';
import ShopProductCard from './ShopProductCard';

// 상품 리스트 구성 컴포넌트
export default function ProductsList({ products, putInShoppingCart, setPutInShoppingCart }) {
  // const sortedProducts = products.slice().sort((a, b) => {
  //   // pdttype이 다를 경우 pdttype을 기준으로 정렬
  //   if (a.pdttype !== b.pdttype) {
  //     return a.pdttype - b.pdttype;
  //   }
  //   // pdttype이 같은 경우 pdtid를 기준으로 정렬
  //   return a.pdtid - b.pdtid;
  // });

  return (
    <Grid container spacing={3}>
      {products.map((product, index) => (
        <Grid key={index} item xs={12} sm={6} md={3}>
          <ShopProductCard
            product={product}
            putInShoppingCart={putInShoppingCart}
            setPutInShoppingCart={setPutInShoppingCart}
          />
        </Grid>
      ))}
    </Grid>
  );
}
