import useResponsive from '../../../hooks/useResponsive';
import YAllChart from '../../Charts/Statistics/Year/YAllChart';
import YearAllStatisticsTable from './YearAllStatisticsTable';

export default function YearAllStatistics({ data, chartView }) {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <>
      {isDesktop ? (
        <>
          <YAllChart data={data} isDesktop={isDesktop} />
          {data.length === 0 ? null : <YearAllStatisticsTable data={data} isDesktop={isDesktop} />}
        </>
      ) : chartView ? (
        <YAllChart data={data} isDesktop={isDesktop} />
      ) : (
        <YearAllStatisticsTable data={data} isDesktop={isDesktop} />
      )}
    </>
  );
}
