import { Box, Paper, TextField, Typography } from '@mui/material';
import AuthContext from '../../store/auth-context';
import { useContext, useEffect } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';

// 사용자 정보 수정 내용 입력 컴포넌트

export default function UpdateUserCmp({
  idInput,
  passwordInput,
  passwordConfirmInput,
  emailInput,
  nameInput,
  hpInput,
  authInput,
  setOgKey,
}) {
  const authCtx = useContext(AuthContext);

  const fetchUserInfoRequest = useBackendV2();

  const fetchUserInfo = async () => {
    try {
      const { data } = await fetchUserInfoRequest.sendRequest(
        `${config.backend.endPoint.users.info}/${authCtx.currentUser.id}`
      );

      idInput.setValue(data.id);
      emailInput.setValue(data.uemail);
      nameInput.setValue(data.uname);
      hpInput.setValue(data.hp);
      authInput.setValue(data.authgroupnm);
      setOgKey(data.ogkey);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <Paper
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        border: '2px solid #D8D8D8',
        alignItems: 'center',
        mb: 1,
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', width: '95%', gap: '2px' }}>
        <TextField
          label="아이디"
          variant="outlined"
          required
          sx={{ mt: 2 }}
          value={idInput.value.replace(/\s/g, '').trim()}
          disabled
        />
        <TextField
          label="이름"
          variant="outlined"
          required
          sx={{ mt: 1 }}
          onChange={nameInput.valueChangeHandler}
          onBlur={nameInput.inputBlurHandler}
          value={nameInput.value.replace(/\s/g, '').trim()}
          error={nameInput.hasError}
          helperText={nameInput.hasError && '최소 2자. 최대 20자. 한글, 영문만 가능'}
        />
        <TextField
          label="비밀번호"
          variant="outlined"
          type="password"
          sx={{ mt: 1 }}
          onChange={passwordInput.valueChangeHandler}
          onBlur={passwordInput.inputBlurHandler}
          value={passwordInput.value.replace(/\s/g, '').trim()}
          error={passwordInput.value.length > 0 && passwordInput.hasError}
          helperText={
            passwordInput.value.length > 0 &&
            passwordInput.hasError &&
            '최소 8자. 최대 25자. 최소 영문자 1개, 숫자 1개, 특수문자 1개 포함. 대소문자 구분'
          }
        />
        <TextField
          label="비밀번호 확인"
          variant="outlined"
          type="password"
          sx={{ mt: 1 }}
          onChange={passwordConfirmInput.valueChangeHandler}
          onBlur={passwordConfirmInput.inputBlurHandler}
          value={passwordConfirmInput.value.replace(/\s/g, '').trim()}
          error={passwordConfirmInput.hasError}
          helperText={passwordConfirmInput.hasError && '비밀번호가 일치하지 않습니다.'}
        />
        <TextField
          label="이메일"
          variant="outlined"
          sx={{ mt: 1 }}
          onChange={emailInput.valueChangeHandler}
          onBlur={emailInput.inputBlurHandler}
          value={emailInput.value.replace(/\s/g, '').trim()}
          error={emailInput.hasError}
          helperText={emailInput.hasError && '이메일 형식이 올바르지 않습니다.'}
        />
        <TextField
          label="핸드폰"
          variant="outlined"
          required
          sx={{ mt: 1 }}
          onChange={hpInput.valueChangeHandler}
          onBlur={hpInput.inputBlurHandler}
          value={hpInput.value.replace(/\s/g, '').trim()}
          error={hpInput.hasError}
          helperText={hpInput.hasError && '핸드폰 번호 형식이 올바르지 않습니다.'}
        />
        <TextField
          label="가입구분"
          variant="outlined"
          required
          sx={{ mt: 1, mb: 2 }}
          value={authInput.value}
          disabled
        />
      </Box>
    </Paper>
  );
}
