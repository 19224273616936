import { Autocomplete, Box, Button, TextField, Typography } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import useMediaQuery from '@mui/material/useMediaQuery';

// 발전소 및 RTU 등록 발전소 리스트 선택 및 신규 등록 컴포넌트
export default function SelectPlantList({
  selectPlant,
  haveRtuNumber,
  selectPlantChangeHandler,
  openAddPlantModalHandler,
}) {
  const isDesktop = useResponsive('up', 'lg');

  // 모바일 반응형 디자인을 위한 상수 선언
  const isSmallDevice = useMediaQuery('(min-width: 455px) and (max-width: 1199px)');
  const isVerySmallDevice = useMediaQuery('(max-width: 454px)');

  return (
    <>
      {isDesktop ? (
        <>
          <Autocomplete
            disableClearable
            options={selectPlant}
            getOptionLabel={(option) => option.pname}
            onChange={(e, newValue) => {
              if (newValue) {
                const selectedPlant = selectPlant.find((option) => option.plantkey === newValue.plantkey);
                selectPlantChangeHandler(selectedPlant);
              }
            }}
            sx={{ width: '45%' }}
            disabled={!haveRtuNumber}
            renderInput={(params) => <TextField {...params} hiddenLabel size="small" />}
          />
          <Button variant="outlined" onClick={openAddPlantModalHandler} sx={{ ml: 6 }}>
            신규 발전소 등록
          </Button>
        </>
      ) : (
        <>
          <Box
            display="flex"
            alignItems="center"
            flexDirection={isVerySmallDevice ? 'column' : 'row'}
            sx={{ width: '100%' }}
          >
            <Autocomplete
              disableClearable
              options={selectPlant}
              getOptionLabel={(option) => option.pname}
              onChange={(e, newValue) => {
                if (newValue) {
                  const selectedPlant = selectPlant.find((option) => option.plantkey === newValue.plantkey);
                  selectPlantChangeHandler(selectedPlant);
                }
              }}
              disabled={!haveRtuNumber}
              renderInput={(params) => <TextField {...params} hiddenLabel size="small" />}
              sx={{
                width: isVerySmallDevice ? '100%' : isSmallDevice ? '45%' : '100%',
                mb: isVerySmallDevice ? 1 : 0,
              }}
            />
            <Button
              size="medium"
              variant="outlined"
              onClick={openAddPlantModalHandler}
              sx={{
                ml: isVerySmallDevice ? 0 : 6,
                width: isVerySmallDevice ? '100%' : 'auto',
                mt: isVerySmallDevice ? 1 : 0,
              }}
            >
              신규 발전소 등록
            </Button>
          </Box>
        </>
      )}
    </>
  );
}
