import { List, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';

export default function ProductMenu({ value, productDetailClickHandler, webNavOpen }) {
  return (
    <List component="nav" sx={{ backgroundColor: value === 6 ? '#D2E9FF' : null, borderRadius: 1 }}>
      <ListItemButton onClick={productDetailClickHandler}>
        <ListItemIcon>
          <StoreMallDirectoryOutlinedIcon sx={{ color: value === 6 ? '#3A3DFF' : null }} />
        </ListItemIcon>
        {webNavOpen ? (
          <Typography sx={{ color: value === 6 ? '#3A3DFF' : null, fontWeight: value === 6 ? 'bold' : null }}>
            상품&서비스
          </Typography>
        ) : null}
      </ListItemButton>
    </List>
  );
}
