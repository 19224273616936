import MainCard from '../../theme/MainCard';
import { Box, Typography } from '@mui/material';

// 현황판 RTU 마지막 수신 컴포넌트
export default function PlantRtuInverterStateCard_04({ lastReceptionDate, isIpad, isNexthub }) {
  return (
    <>
      <MainCard
        sx={{
          borderRadius: '15px',
          boxShadow: '0px 3px 100px #00000014',
          height: 100,
        }}
      >
        <Typography variant={isIpad || isNexthub ? 'subtitle2' : 'subtitle1'} sx={{ mt: 0.5 }}>
          마지막 통신 시간
        </Typography>
        <Box display="flex" justifyContent="end" sx={{ mt: 1.5 }}>
          <Typography variant={isIpad || isNexthub ? 'subtitle2' : 'subtitle1'}>
            {lastReceptionDate}
          </Typography>
        </Box>
      </MainCard>
    </>
  );
}
