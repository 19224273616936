import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';

export default function CustomerServiceDetail({ openCustomerServiceDetailClickHandler }) {
  return (
    <List component="nav" sx={{ mt: 0 }}>
      <ListItemButton onClick={openCustomerServiceDetailClickHandler}>
        <ListItemIcon>
          <SupportAgentIcon />
        </ListItemIcon>
        <ListItemText primary="고객센터 안내" />
      </ListItemButton>
    </List>
  );
}
