import { Autocomplete, Box, TextField, Typography } from '@mui/material';

// 구독 라이선스 입력 발전소 리스트 검색 컴포넌트
export default function AddSubscribeKeyContent({
  selectPlantState,
  plantState,
  selectPlantStateChangeHandler,
  isDesktop,
}) {
  return (
    <>
      <Box
        sx={{
          display: {
            xs: 'flex',
          },
          my: 2,
          gap: 1,
          mt: isDesktop ? null : 7.5,
        }}
        justifyContent="space-between"
      >
        {selectPlantState.length > 1 ? (
          <Autocomplete
            disableClearable
            id="combo-box-demo"
            options={selectPlantState}
            getOptionLabel={(option) => option.plantname || ''}
            value={
              selectPlantState.find((option) => option.plantkey === plantState.plantkey) ||
              selectPlantState.find(
                (option) => option.plantkey === parseInt(localStorage.getItem('selectPlantkey'), 10)
              ) ||
              selectPlantState[0]
            }
            onChange={(e, newValue) => {
              if (newValue) {
                const selectedPlant = selectPlantState.find(
                  (option) => option.plantkey === newValue.plantkey
                );
                selectPlantStateChangeHandler(selectedPlant);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="발전소" variant="outlined" sx={{ bgcolor: '#fff' }} />
            )}
            sx={{ width: isDesktop ? '45%' : '100%' }}
          />
        ) : (
          <Typography variant="subtitle1">{plantState.plantname}</Typography>
        )}
      </Box>
      <Box></Box>
    </>
  );
}
