import MainCard from '../../theme/MainCard';
import { Box, Avatar, Typography } from '@mui/material';
import Co2Icon from '@mui/icons-material/Co2';
import ForestIcon from '@mui/icons-material/Forest';

// 현황판 온실가스 및 나무 계산 컴포넌트
export default function PlantTCO2Card_02({ currentFormCO2, currentData, isIpad, isNexthub }) {
  return (
    <>
      <MainCard
        sx={{
          // bgcolor: '#0059B2',
          borderRadius: '15px',
          boxShadow: '0px 3px 100px #00000014',
          height: 95,
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Avatar
            variant="rounded"
            sx={{
              bgcolor: currentFormCO2 ? '#E2E6FB' : '#C0F4D2',
              mr: 1,
              width: 40,
              height: 40,
            }}
          >
            {currentFormCO2 ? (
              <Co2Icon fontSize="large" sx={{ color: '#0059B2' }} />
            ) : (
              <ForestIcon sx={{ color: '#41915D' }} />
            )}
          </Avatar>
          <Typography
            variant={isIpad || isNexthub ? 'h4' : 'h3'}
            sx={{ color: currentFormCO2 ? '#0059B2' : '#41915D' }}
          >
            {currentData}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="end" alignItems="center">
          <Typography variant={isIpad || isNexthub ? 'h6' : 'h5'} sx={{ color: '#000' }}>
            {currentFormCO2 ? 'tCO2eq' : '천 그루'}
          </Typography>
        </Box>
      </MainCard>
    </>
  );
}
