import { Box, Button, Typography } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import { useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { convertStringToFormatDateString } from '../../utils/dateUtil';

//
export default function DeliveryProductStatusModal({ orderid, open, onClose }) {
  const [deliveryStatus, setDeliveryStatus] = useState([]);

  const backendRequest = useBackendV2();

  const fetchDeliveryStatus = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.deliverys.deliveryStatus}/${orderid}`
      );

      setDeliveryStatus(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchDeliveryStatus();
  }, []);

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <>
      <CustomModal
        open={open}
        onClose={closeModalHandler}
        sx={{ maxHeight: '95%', width: { xs: '95%', md: 700 } }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <LocalShippingOutlinedIcon />
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>배송 조회</Typography>
        </Box>
        <hr />
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">주문번호: {orderid}</Typography>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            택배사: {deliveryStatus[0]?.dename}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            운송장번호: {deliveryStatus[0]?.denumber}
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            택배 배송일: {convertStringToFormatDateString(deliveryStatus[0]?.regdate)}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
          <Button variant="contained" size="large" onClick={closeModalHandler} sx={{ width: 200 }}>
            확인
          </Button>
        </Box>
      </CustomModal>
    </>
  );
}
