import { Box, Typography, styled } from '@mui/material';
import MainCard from '../../../theme/MainCard';
import { convertFormatStringToYMD, formatTime } from '../../../utils/dateUtil';

// 개별 대시보드 발전소 정보 표시 모바일(DNE) - 발전소 통신시간 컴포넌트
export default function EachPlantInfoMobile_DNE({ basePlantInfo }) {
  const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
    backgroundColor: '#37474f',
  }));

  return (
    <>
      <Typography variant="subtitle1" display="flex" sx={{ p: 1 }}>
        마지막 통신 시간
      </Typography>
      <CardWrapper border={false} content={false} sx={{ p: 1 }}>
        <Box display="flex" alignItems="center" justifyContent="center">
          {basePlantInfo.length === 0 ? null : (
            <>
              <Typography variant="subtitle1" display="flex" justifyContent="center" sx={{ color: '#fff' }}>
                {convertFormatStringToYMD(basePlantInfo[0].rtuinfos[0].localdate)}
                &nbsp;
                {formatTime(basePlantInfo[0].rtuinfos[0].localdate)}
              </Typography>
            </>
          )}
        </Box>
      </CardWrapper>
    </>
  );
}
