import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';

// 전체현황 사용자 메뉴 인버터 컴포넌트
export default function AllInverterDetail({ inverterDetailClickHandler }) {
  return (
    <List component="nav">
      <ListItemButton onClick={inverterDetailClickHandler}>
        <ListItemIcon>
          <AppsIcon />
        </ListItemIcon>
        <ListItemText primary="인버터" />
      </ListItemButton>
    </List>
  );
}
