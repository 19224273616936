import {
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
} from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import TodayIcon from '@mui/icons-material/Today';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';

// 웹 메뉴 통계 컴포넌트
export default function StatisticsWebMenu({ setValue, setSubValue }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const hourClickHandler = () => {
    setValue(2);
    localStorage.setItem('menuValue', 2);
    setSubValue(0);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.statistics, { state: { subValue: 0 } });
  };
  const dayClickHandler = () => {
    setValue(2);
    localStorage.setItem('menuValue', 2);
    setSubValue(1);
    localStorage.setItem('menuSubValue', 1);
    navigate(config.frontend.statistics, { state: { subValue: 1 } });
  };
  const monthClickHandler = () => {
    setValue(2);
    localStorage.setItem('menuValue', 2);
    setSubValue(2);
    localStorage.setItem('menuSubValue', 2);
    navigate(config.frontend.statistics, { state: { subValue: 2 } });
  };
  const yearClickHandler = () => {
    setValue(2);
    localStorage.setItem('menuValue', 2);
    setSubValue(3);
    localStorage.setItem('menuSubValue', 3);
    navigate(config.frontend.statistics, { state: { subValue: 3 } });
  };
  const envDvcClickHandler = () => {
    setValue(2);
    localStorage.setItem('menuValue', 2);
    setSubValue(4);
    localStorage.setItem('menuSubValue', 4);
    navigate(config.frontend.statistics, { state: { subValue: 4 } });
  };
  const comprehensiveAnalysisClickHandler = () => {
    setValue(2);
    localStorage.setItem('menuValue', 2);
    setSubValue(5);
    localStorage.setItem('menuSubValue', 5);
    navigate(config.frontend.statistics, { state: { subValue: 5 } });
  };

  return (
    <>
      <Button
        startIcon={<InsightsIcon fontSize="large" />}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          color: '#fff',
          fontSize: 'large',
          mr: 0.5,
        }}
        onClick={handleOpen}
      >
        통계
      </Button>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack sx={{ p: 1 }}>
          <List component="nav">
            <ListItemButton onClick={hourClickHandler}>
              <ListItemIcon>
                <ScheduleOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="시간" />
            </ListItemButton>
          </List>
          <Divider />
          <List component="nav">
            <ListItemButton onClick={dayClickHandler}>
              <ListItemIcon>
                <TodayIcon />
              </ListItemIcon>
              <ListItemText primary="일간" />
            </ListItemButton>
          </List>
          <Divider />
          <List component="nav">
            <ListItemButton onClick={monthClickHandler}>
              <ListItemIcon>
                <DateRangeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="월간" />
            </ListItemButton>
          </List>
          <Divider />
          <List component="nav">
            <ListItemButton onClick={yearClickHandler}>
              <ListItemIcon>
                <EventRepeatOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="연간" />
            </ListItemButton>
          </List>
          <Divider />
          <List component="nav">
            <ListItemButton onClick={envDvcClickHandler}>
              <ListItemIcon>
                <DataUsageIcon />
              </ListItemIcon>
              <ListItemText primary="환경정보통계" />
            </ListItemButton>
          </List>
          <Divider />
          <List component="nav">
            <ListItemButton onClick={comprehensiveAnalysisClickHandler}>
              <ListItemIcon>
                <MultilineChartIcon />
              </ListItemIcon>
              <ListItemText primary="종합분석통계" />
            </ListItemButton>
          </List>
        </Stack>
      </Popover>
    </>
  );
}
