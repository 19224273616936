import { Box, Button, Typography } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import useResponsive from '../../hooks/useResponsive';

export default function ConnPlantAlarmModal({ open, onClose, cnt }) {
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'lg');

  const moveConnectionPlantClickHandler = () => {
    localStorage.setItem('menuValue', 11);
    localStorage.setItem('menuSubValue', 0);
    navigate(`${config.frontend.connectionPlants}`);
  };

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <>
      <CustomModal
        open={open}
        onClose={closeModalHandler}
        sx={{ maxHeight: '95%', width: { xs: '95%', md: 600 } }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <DatasetLinkedIcon />
          <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.09rem' }}>
            발전소 연계 요청
          </Typography>
        </Box>
        <hr />
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box>발전소 연계 요청 {cnt}건이 있습니다.</Box>
          <br />
        </Box>
        {isDesktop ? (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              px: '20% ',
            }}
          >
            <Button variant="outlined" onClick={moveConnectionPlantClickHandler}>
              발전소 연계 등록 바로가기
            </Button>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                flexDirection: 'column', // 방향을 세로로 설정
                alignItems: 'center', // 가로축 중앙 정렬
                justifyContent: 'center', // 세로축 중앙 정렬
                '& > button': {
                  width: '60%', // 모바일 화면에 맞게 너비 조정
                  mb: 2, // 버튼 사이의 간격
                },
              }}
            >
              <Button variant="outlined" onClick={moveConnectionPlantClickHandler} sx={{ width: '250px' }}>
                발전소 연계 등록 바로가기
              </Button>
            </Box>
          </>
        )}
      </CustomModal>
    </>
  );
}
