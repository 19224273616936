import { Box, Button, TextField, Typography } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import DaumPostcodeEmbed from 'react-daum-postcode';
import HomeIcon from '@mui/icons-material/Home';

// 하드웨어 상품 구매시 배송지 및 구매자 정보 입력 컴포넌트
export default function KakaoFindAddressModal({
  setCheckPaymentStatusModal,
  setOpenDepositorStatusModal,
  checkDepositorStatus,
  userName,
  setUserName,
  phoneNumber,
  setPhoneNumber,
  address,
  setAddress,
  detailAddress,
  setDetailAddress,
  setZip,
  setCartProducts,
  open,
  onClose,
}) {
  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';
    let zonecode = data.zonecode;

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    setAddress(fullAddress);
    setZip(zonecode);
  };

  const inputUserNameHandler = (e) => {
    setUserName(e.target.value);
  };

  const inputPhoneNumberHandler = (e) => {
    setPhoneNumber(e.target.value);
  };

  const inputDetailAddressHandler = (e) => {
    setDetailAddress(e.target.value);
  };

  const paymentPageClickHandler = () => {
    setCheckPaymentStatusModal(true);
    onClose();
  };

  const depositorPaymentPageClickHandler = () => {
    setOpenDepositorStatusModal(true);
    onClose();
  };

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <CustomModal
      open={open}
      onClose={closeModalHandler}
      sx={{ width: { xs: '95%', md: 650 }, maxHeight: '95%' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
        <HomeIcon />
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>배송지 입력</Typography>
      </Box>
      <hr />
      <Box sx={{ mt: 2 }}>
        <TextField
          label="구매자명"
          variant="standard"
          value={userName}
          onChange={inputUserNameHandler}
          sx={{ width: '100%' }}
        />
      </Box>
      <br />
      <Box>
        <TextField
          label="연락처"
          variant="standard"
          value={phoneNumber}
          onChange={inputPhoneNumberHandler}
          sx={{ width: '100%' }}
        />
      </Box>
      <br />
      <Box>
        <TextField label="배송 주소지" variant="standard" value={address} disabled sx={{ width: '100%' }} />
      </Box>
      <DaumPostcodeEmbed onComplete={handleComplete} />
      <br />
      <Box>
        <TextField
          label="상세 주소"
          variant="standard"
          value={detailAddress}
          onChange={inputDetailAddressHandler}
          disabled={address === ''}
          sx={{ width: '100%' }}
        />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button
          variant="contained"
          disabled={userName === '' || phoneNumber === '' || address === '' || detailAddress === ''}
          onClick={checkDepositorStatus === true ? depositorPaymentPageClickHandler : paymentPageClickHandler}
          sx={{ width: 250 }}
        >
          확인
        </Button>
      </Box>
    </CustomModal>
  );
}
