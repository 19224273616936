import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { PiGridFourFill } from 'react-icons/pi';
import { TfiLayoutGrid4Alt } from 'react-icons/tfi';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

// 접속반 웹 화면 컴포넌트
export default function JboxWeb({
  getDevelopPercentColor,
  jBoxsList,
  maxJuncboxchannelLength,
  setEachJboxData,
  setJboxDetail,
}) {
  const tableHeadCells = Array.from({ length: maxJuncboxchannelLength }, (_, index) => (
    <StyledTableCell key={index} align="center" sx={{ minWidth: 90, borderRight: '1px solid #d0d0d0' }}>
      {index + 1}
    </StyledTableCell>
  ));

  const jBoxClickHandler = (column) => {
    setEachJboxData(column);
    setJboxDetail(true);
  };

  return (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', mb: 1 }}>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 0.5 }}>
          <PiGridFourFill style={{ fontSize: '2rem', color: '#F7B969' }} />
          <Typography variant="subtitle2">저 0 ~ 30%</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 0.5 }}>
          <PiGridFourFill style={{ fontSize: '2rem', color: '#73E600' }} />
          <Typography variant="subtitle2">중 31 ~ 70%</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 0.5 }}>
          <PiGridFourFill style={{ fontSize: '2rem', color: '#88BBEE' }} />
          <Typography variant="subtitle2">고 71 ~ 100%</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mr: 0.5 }}>
          <PiGridFourFill style={{ fontSize: '2rem', color: '#FD251F' }} />
          <Typography variant="subtitle2">값이 없음</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <PiGridFourFill style={{ fontSize: '2rem', color: '#D0D0D0' }} />
          <Typography variant="subtitle2">연결 없음</Typography>
        </Box>
      </Box>
      <Card
        variant="outlined"
        sx={{
          // padding: {
          //   xs: 0,
          //   sm: 2,
          // },
          borderRadius: 0,
          boxShadow: '0px 3px 12px #00000014',
        }}
      >
        <StyledTableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" sx={{ minWidth: 210, borderRight: '1px solid #d0d0d0' }}>
                  접속반
                </StyledTableCell>
                {tableHeadCells}
              </TableRow>
            </TableHead>
            <TableBody>
              {jBoxsList === undefined
                ? null
                : jBoxsList.map((column, index) => {
                    const tooltipContent =
                      column.nodata === 1 || column.flg_ev === '-1'
                        ? '발전 중단'
                        : column.nodata === 0 &&
                          (column.juncboxchannel.some((channel) => channel.curt === 0) ||
                            column.juncboxchannel.some((channel) => channel.curnodata === 1))
                        ? '대기 중'
                        : '발전 중';

                    return (
                      <TableRow key={index}>
                        <StyledTableCell
                          align="center"
                          onClick={() => jBoxClickHandler(column)}
                          sx={{
                            borderRight: '1px solid #d0d0d0',
                            cursor: 'pointer',
                            backgroundColor: '#ebebeb',
                          }}
                        >
                          <Card sx={{ borderRadius: 3 }}>
                            <Typography variant="body1" fontWeight="bold">
                              {column.jbname}
                            </Typography>
                          </Card>
                          <Card sx={{ backgroundColor: '#333333', borderRadius: 0, mt: 1 }}>
                            <Typography
                              variant="subtitle2"
                              sx={{
                                display: 'flex',
                                color: '#fff',
                                alignItems: 'center',
                                justifyContent: 'center',
                                p: 0.5,
                              }}
                            >
                              상태정보
                              <Tooltip title={tooltipContent} arrow>
                                <Avatar
                                  sizes="small"
                                  sx={{
                                    width: 13,
                                    height: 13,
                                    ml: 1,
                                    mr: 1,
                                    bgcolor:
                                      column.nodata === 1 || column.flg_ev === '-1'
                                        ? 'red'
                                        : column.nodata === 0 &&
                                          (column.juncboxchannel.some((channel) => channel.curt === 0) ||
                                            column.juncboxchannel.some((channel) => channel.curnodata === 1))
                                        ? 'orange'
                                        : '#73E600',
                                    color:
                                      column.nodata === 1 || column.flg_ev === '-1'
                                        ? 'red'
                                        : column.nodata === 0 &&
                                          (column.juncboxchannel.some((channel) => channel.curt === 0) ||
                                            column.juncboxchannel.some((channel) => channel.curnodata === 1))
                                        ? 'orange'
                                        : '#73E600',
                                  }}
                                >
                                  {''}
                                </Avatar>
                              </Tooltip>
                              / 온도: <span style={{ color: '#6FE1E8', marginLeft: 5 }}>{column.jtemp}</span>
                              &nbsp;℃
                            </Typography>
                          </Card>
                          <Card sx={{ backgroundColor: '#333333', borderRadius: 0, mt: 0.5, p: 0.4 }}>
                            <Typography variant="subtitle2" sx={{ color: '#fff' }}>
                              전압: <span style={{ color: '#6fe877' }}>{column.jvolt}</span> V / 전류:{' '}
                              <span style={{ color: '#6fe877' }}>{column.sumcurt.toFixed(1)}</span> A
                            </Typography>
                          </Card>
                        </StyledTableCell>
                        {column.juncboxchannel.map((channel, channelIndex) => (
                          <StyledTableCell
                            key={channelIndex}
                            align="center"
                            sx={{ borderRight: '1px solid #d0d0d0' }}
                          >
                            {Object.keys(channel).length > 0 && (
                              <Box sx={{ position: 'relative' }}>
                                <Box sx={{ position: 'relative' }}>
                                  <TfiLayoutGrid4Alt
                                    style={{
                                      fontSize: '4rem',
                                      color: getDevelopPercentColor(channel?.developPercent),
                                    }}
                                  />
                                  {channel?.developPercent !== undefined &&
                                  channel.developPercent !== null ? (
                                    <Box
                                      sx={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        transform: 'translate(-50%, -50%)',
                                      }}
                                    >
                                      <Typography variant="h5">
                                        {channel.curt !== undefined ? channel.curt.toFixed(1) : null}
                                      </Typography>
                                    </Box>
                                  ) : null}
                                </Box>
                              </Box>
                            )}
                          </StyledTableCell>
                        ))}
                      </TableRow>
                    );
                  })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Card>
    </>
  );
}
