import { Avatar, Box, ClickAwayListener, Divider, Stack, Tooltip, Typography } from '@mui/material';
import { useState } from 'react';

// 전체 대시보드 리스트 구성 컴포넌트내 인버터 상태 컴포넌트
export default function EntirePlantIvtInfo({
  iac,
  invertername,
  pac,
  serialnum,
  uac,
  nodata,
  ivtNodata,
  generatestatus,
  dlStatus,
  isDesktop,
}) {
  const [plantTooltipOpenMap, setPlantTooltipOpenMap] = useState({});
  const [plantIvtTooltipOpenMap, setPlantIvtTooltipOpenMap] = useState({});
  const [plantStatisticsTooltipOpenMap, setPlantStatisticsTooltipOpenMap] = useState({});

  // 클릭한 통신 아바타의 툴팁 상태를 열기
  const handlePlantAvatarClick = (serialnum) => {
    setPlantTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: true }));
  };
  // 통신 아바타의 툴팁 상태를 닫기
  const handlePlantTooltipClose = (serialnum) => {
    setPlantTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: false }));
  };

  // 클릭한 인버터 아바타의 툴팁 상태를 열기
  const handlePlantIvtAvatarClick = (serialnum) => {
    setPlantIvtTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: true }));
  };
  // 인버터 아바타의 툴팁 상태를 닫기
  const handlePlantIvtTooltipClose = (serialnum) => {
    setPlantIvtTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: false }));
  };

  // 클릭한 통계 아바타의 툴팁 상태를 열기
  const handlePlantStatisticsAvatarClick = (serialnum) => {
    setPlantStatisticsTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: true }));
  };
  // 통계 아바타의 툴팁 상태를 닫기
  const handlePlantStatisticsTooltipClose = (serialnum) => {
    setPlantStatisticsTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: false }));
  };

  return (
    <>
      <Box>
        <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="flex-start">
          <div>
            <Typography variant="subtitle2">{invertername}</Typography>
          </div>
          <Stack sx={{ flexGrow: 1, textAlign: 'right' }} spacing={0}>
            <Typography variant="body2">
              인버터 전력 : <strong>{ivtNodata === 0 ? pac : 0}kW</strong>
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={0} direction="row">
          <Box sx={{ m: 1, display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2">
              <strong>통신</strong>
            </Typography>
            <Box sx={{ ml: 0.8 }}>
              {isDesktop ? (
                <Tooltip title={ivtNodata === 0 ? '통신 중' : '통신 중단'} arrow>
                  <Avatar
                    sizes="small"
                    sx={{
                      width: 15,
                      height: 15,
                      bgcolor: ivtNodata === 0 ? '#64B346' : 'red',
                      color: ivtNodata === 0 ? '#64B346' : 'red',
                    }}
                  >
                    {''}
                  </Avatar>
                </Tooltip>
              ) : (
                <ClickAwayListener onClickAway={() => handlePlantTooltipClose(serialnum)}>
                  <div>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      open={!!plantTooltipOpenMap[serialnum]}
                      onClose={() => handlePlantTooltipClose(serialnum)}
                      title={ivtNodata === 0 ? '통신 중' : '통신 중단'}
                      arrow
                    >
                      <Avatar
                        sizes="small"
                        sx={{
                          width: 15,
                          height: 15,
                          bgcolor: ivtNodata === 0 ? '#64B346' : 'red',
                          color: ivtNodata === 0 ? '#64B346' : 'red',
                        }}
                        onClick={() => handlePlantAvatarClick(serialnum)}
                      >
                        {''}
                      </Avatar>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              )}
            </Box>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2">
              <strong>인버터</strong>
            </Typography>
            <Box sx={{ ml: 0.8 }}>
              {isDesktop ? (
                <Tooltip title={generatestatus === 1 ? '발전 중' : '발전 중단'} arrow>
                  <Avatar
                    sizes="small"
                    sx={{
                      width: 15,
                      height: 15,
                      bgcolor: generatestatus === 1 ? '#64B346' : 'red',
                      color: generatestatus === 1 ? '#64B346' : 'red',
                    }}
                  >
                    {''}
                  </Avatar>
                </Tooltip>
              ) : (
                <ClickAwayListener onClickAway={() => handlePlantIvtTooltipClose(serialnum)}>
                  <div>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      open={!!plantIvtTooltipOpenMap[serialnum]}
                      onClose={() => handlePlantIvtTooltipClose(serialnum)}
                      title={generatestatus === 1 ? '발전 중' : '발전 중단'}
                      arrow
                    >
                      <Avatar
                        sizes="small"
                        sx={{
                          width: 15,
                          height: 15,
                          bgcolor: generatestatus === 1 ? '#64B346' : 'red',
                          color: generatestatus === 1 ? '#64B346' : 'red',
                        }}
                        onClick={() => handlePlantIvtAvatarClick(serialnum)}
                      >
                        {''}
                      </Avatar>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              )}
            </Box>
          </Box>
          <Box sx={{ m: 1, display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2">
              <strong>통계</strong>
            </Typography>
            <Box sx={{ marginLeft: 0.8 }}>
              {isDesktop ? (
                <Tooltip title={dlStatus === 1 ? '적산 중' : '적산 중단'} arrow>
                  <Avatar
                    sizes="small"
                    sx={{
                      width: 15,
                      height: 15,
                      bgcolor: dlStatus === 1 ? '#64B346' : 'red',
                      color: dlStatus === 1 ? '#64B346' : 'red',
                    }}
                  >
                    {''}
                  </Avatar>
                </Tooltip>
              ) : (
                <ClickAwayListener onClickAway={() => handlePlantStatisticsTooltipClose(serialnum)}>
                  <div>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      open={!!plantStatisticsTooltipOpenMap[serialnum]}
                      onClose={() => handlePlantStatisticsTooltipClose(serialnum)}
                      title={dlStatus === 1 ? '적산 중' : '적산 중단'}
                      arrow
                    >
                      <Avatar
                        sizes="small"
                        sx={{
                          width: 15,
                          height: 15,
                          bgcolor: dlStatus === 1 ? '#64B346' : 'red',
                          color: dlStatus === 1 ? '#64B346' : 'red',
                        }}
                        onClick={() => handlePlantStatisticsAvatarClick(serialnum)}
                      >
                        {''}
                      </Avatar>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              )}
            </Box>
          </Box>
        </Stack>
        <Divider sx={{ mt: 0.5, mb: 0.5 }} />
      </Box>
    </>
  );
}
