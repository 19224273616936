import { useCallback, useContext, useState } from 'react';
import config from '../config';
import AuthContext from '../store/auth-context';

// 커스텀 훅으로 백엔드 API 요청을 보내는 데 사용
// 컴포넌트에서 백엔드와의 통신을 쉽게 추상화하고, 상태를 통해 로딩 상태를 관리할 수 있도록 도움
// 토큰이 있는 경우 인증 정보를 헤더에 추가하는 등의 세부 설정을 캡슐화하여 재사용성을 높이고 코드를 단순화

const useBackendV2 = () => {
  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const sendRequest = useCallback(
    async (endPoint, requestConfig, isAuthenticated = true) => {
      setIsLoading(true);

      const result = {
        data: null,
        headers: null,
      };

      const userAgent = window.navigator.userAgent;
      const isMobile = /Mobile/.test(userAgent);

      const defaultHeaders = {};
      if (isAuthenticated) {
        defaultHeaders['Authorization'] = `Bearer ${authCtx.token.accessToken.token}`;
      }
      if (requestConfig?.method.toLowerCase() !== 'get' && requestConfig?.body) {
        defaultHeaders['Content-Type'] = 'application/json';
      }
      const response = await fetch(
        isMobile ? `${config.backend.hostMo}${endPoint}` : `${config.backend.host}${endPoint}`,
        {
          method: requestConfig?.method ? requestConfig.method : 'GET',
          headers: requestConfig?.headers ? { ...defaultHeaders, ...requestConfig.headers } : defaultHeaders,
          body: requestConfig?.body ? JSON.stringify(requestConfig.body) : null,
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          throw new Error('UNAUTHORIZATION');
        } else {
          let errorData;
          try {
            errorData = await response.json();
            console.log(errorData);
          } catch {}
          setIsLoading(false);
          throw new Error(errorData?.exception ?? 'Request failed!');
        }
      }

      const data = await response.json();

      result.data = data;
      result.headers = response.headers;

      setIsLoading(false);

      return result;
    },
    [authCtx]
  );

  return { isLoading, sendRequest };
};

export default useBackendV2;
