import { Box } from '@mui/material';
import AlarmHistoryWeb from './AlarmHistoryWeb';
import AlarmHistoryApp from './AlarmHistoryApp';

export default function AlarmHistory({ isDesktop, isIPadMini, alarmsAlist }) {
  return (
    <Box sx={{ mt: 1 }}>
      {isDesktop ? (
        <AlarmHistoryWeb data={alarmsAlist} />
      ) : (
        <>
          {alarmsAlist.map((d, index) => (
            <AlarmHistoryApp key={index} data={d} />
          ))}
        </>
      )}
    </Box>
  );
}
