import {
  Avatar,
  Box,
  Card,
  Chip,
  Divider,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import AddShoppingCartOutlinedIcon from '@mui/icons-material/AddShoppingCartOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { useState } from 'react';
import PaymentPage from '../../pages/Payment/PaymentPage';
import ShoppingCartModal from './ShoppingCartModal';
import { fNumberWithCommas } from '../../utils/formatNumber';
import MainCard from '../../theme/MainCard';

const StyledProductImg = styled('img')({
  top: 0,
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  position: 'absolute',
});

// 개별 상품 컴포넌트
export default function ShopProductCard({ product, putInShoppingCart, setPutInShoppingCart }) {
  const { pdtid, pdttype, pdtname, pdtimg, price, saleprice, filekey, optionkey, cnt } = product;
  //   https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=SC&id=2

  // id :

  // 1구독
  // 2어댑터
  // 3RTU

  const navigate = useNavigate();

  const [checkPaymentStatusModal, setCheckPaymentStatusModal] = useState(false);
  const [putInShoppingCartModal, setPutInShoppingCartModal] = useState(false);

  const moveProductDetailClickHandler = (pdtid) => {
    navigate(`${config.frontend.productdetails}/${pdtid}`, {
      state: { pdttype: pdttype },
    });
  };

  const paymentProgressClickHandler = () => {
    setCheckPaymentStatusModal(true);
  };
  const closePaymentPageClickHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setCheckPaymentStatusModal(false);
  };

  const openPutInShoppingCartClickHandler = () => {
    setPutInShoppingCart(true);
    setPutInShoppingCartModal(true);
  };
  const closePutInShoppingCartClickHandler = (event, reason) => {
    setPutInShoppingCart(false);
    setPutInShoppingCartModal(false);
  };

  return (
    <>
      <MainCard>
        <Box onClick={() => moveProductDetailClickHandler(pdtid)} sx={{ pt: '100%', position: 'relative' }}>
          {saleprice === 0 ? null : (
            <Chip
              label={<Typography variant="subtitle1">sale</Typography>}
              color="error"
              variant="contained"
              size="small"
              sx={{ zIndex: 9, top: 0, right: 0, position: 'absolute', textTransform: 'uppercase' }}
            />
          )}
          <StyledProductImg
            alt={pdtname}
            src={`https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=SC&id=${product.filekey}`}
          />
          {pdttype === 7 || price === 0 ? null : (
            <Tooltip title="장바구니에 담기" arrow>
              <Avatar
                onClick={(e) => {
                  e.stopPropagation(); // 클릭 이벤트가 부모 요소로 전파되지 않도록 함
                  openPutInShoppingCartClickHandler();
                }}
                sx={{
                  bgcolor: '#3153BE',
                  zIndex: 9,
                  bottom: 38,
                  right: 10,
                  position: 'absolute',
                }}
              >
                <IconButton>
                  <AddShoppingCartOutlinedIcon sx={{ color: '#fff' }} />
                </IconButton>
              </Avatar>
            </Tooltip>
          )}
        </Box>
        <Divider sx={{ mb: 1 }} />
        <Stack spacing={2} sx={{ p: 0 }}>
          <Link
            color="inherit"
            underline="hover"
            display="flex"
            justifyContent="start"
            onClick={() => moveProductDetailClickHandler(pdtid)}
          >
            <Typography variant="subtitle1" sx={{ cursor: 'pointer' }} noWrap>
              {pdtname}
            </Typography>
          </Link>
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            {saleprice === 0 ? (
              pdttype === 7 || price === 0 ? (
                <Typography variant="subtitle1">별도견적</Typography>
              ) : (
                <Typography variant="subtitle1">
                  {pdttype === 3 ? '월 ' : null}
                  {fNumberWithCommas(price)}원
                </Typography>
              )
            ) : (
              <Typography variant="subtitle1">
                <Typography
                  component="span"
                  variant="body1"
                  sx={{
                    color: 'text.disabled',
                    textDecoration: 'line-through',
                  }}
                >
                  {pdttype === 3 ? '월 ' : null}
                  {fNumberWithCommas(price + saleprice)}원
                </Typography>
                &nbsp;{fNumberWithCommas(price)}원
              </Typography>
            )}
            <Box>
              {/* <Tooltip title="결제하기" arrow>
                <IconButton onClick={paymentProgressClickHandler}>
                  <CreditCardOutlinedIcon />
                </IconButton>
              </Tooltip> */}
              {/* <Tooltip title="장바구니에 담기" arrow>
                <Avatar sx={{ bgcolor: '#4A78F9' }}>
                  <IconButton onClick={openPutInShoppingCartClickHandler}>
                    <AddShoppingCartOutlinedIcon sx={{ color: '#fff' }} />
                  </IconButton>
                </Avatar>
              </Tooltip> */}
            </Box>
          </Stack>
        </Stack>
      </MainCard>
      {checkPaymentStatusModal && (
        <PaymentPage
          pdtname={pdtname}
          price={price}
          open={checkPaymentStatusModal}
          onClose={closePaymentPageClickHandler}
        />
      )}
      {putInShoppingCartModal && (
        <ShoppingCartModal
          product={product}
          open={putInShoppingCartModal}
          onClose={closePutInShoppingCartClickHandler}
        />
      )}
    </>
  );
}
