import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MainCard from '../../../theme/MainCard';
import { useEffect, useState } from 'react';
import PlantCapactiyCheckModal from './PlantCapactiyCheckModal';

// 개별 대시보드 인버터 현재 출력 컴포넌트
export default function AppWidgetInverterPower_DNE({ invDailyDevelopment, color = 'primary' }) {
  const [infintyCheck, setInfinityCheck] = useState(false);

  const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
  }));

  // 현재출력 계산
  const totalPac = invDailyDevelopment?.reduce((total, inverter) => {
    const pacValue = parseFloat(inverter.pac) || 0; // pac 값이 유효하지 않으면 0으로 처리
    return total + pacValue;
  }, 0);

  const closeModal = () => {
    setInfinityCheck(false);
  };

  useEffect(() => {
    if (infintyCheck === false && totalPac === Infinity) {
      setInfinityCheck(true);
    }
  }, [totalPac]);

  if (!invDailyDevelopment || invDailyDevelopment.length === 0) {
    return <Typography>출력 값 계산 중</Typography>; // 데이터가 없을 경우 처리
  }

  return (
    <>
      <CardWrapper border={false} content={false}>
        <Box sx={{ p: 2 }}>
          <Grid container direction="column">
            <Grid item display="flex" alignItems="center">
              <Typography
                sx={{
                  fontSize: '1rem',
                  fontWeight: 600,
                  color: 'black',
                  display: 'flex',
                  mt: 1,
                }}
              >
                현재 출력
              </Typography>
              <Typography sx={{ fontSize: '0.75rem', fontWeight: 600, mt: 1.2 }}>
                (kW)
              </Typography>
            </Grid>
            <Grid item>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="flex-end"
                sx={{ width: '100%' }}
              >
                <Typography
                  sx={{
                    fontSize: '3rem',
                    fontWeight: 600,
                    color: 'black',
                    textAlign: 'right',
                    width: '100%',
                  }}
                >
                  {isNaN(totalPac) ? 0 : parseFloat(totalPac.toFixed(1))}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </CardWrapper>
      {/* totalPac이 Infinity일 때 모달 표시 */}
      {infintyCheck && <PlantCapactiyCheckModal open={infintyCheck} onClose={closeModal} />}
    </>
  );
}