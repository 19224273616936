import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Card,
  Collapse,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import JboxChart from '../../../Charts/ConnectionBoard/JboxChart';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BarChartIcon from '@mui/icons-material/BarChart';
import JboxTable from './JboxTable';

// 접속반 모바일 화면 컴포넌트
export default function JboxMobile({ data }) {
  const [expanded, setExpanded] = useState(false);
  const [chartView, setChartView] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleExpandClick = async () => {
    setExpanded(!expanded);
  };

  const chartViewClick = () => {
    setChartView(true);
  };
  const TableViewClick = () => {
    setChartView(false);
  };

  const handleAvatarClick = () => {
    setTooltipOpen(true);
  };
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  const getTooltipContent = () => {
    if (data.nodata === 1 || data.flg_ev === '-1') {
      return '발전 중단';
    } else if (
      data.nodata === 0 &&
      (data.juncboxchannel.some((channel) => channel.curt === 0) ||
        data.juncboxchannel.some((channel) => channel.curnodata === 1))
    ) {
      return '대기 중';
    } else {
      return '발전 중';
    }
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          padding: {
            xs: 0,
            sm: 2,
          },
          borderRadius: '14px',
          boxShadow: '0px 3px 12px #00000014',
          '&:hover': { borderColor: 'blue', borderWidth: '2px' },
          mb: 1,
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          width="100%"
          spacing={2}
        >
          <Stack
            sx={{
              p: 1,
            }}
            flex={1}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Typography variant="subtitle1">{data.jbname}</Typography>
              <Avatar sx={{ width: '25px', height: '25px', bgcolor: '#1981E7' }}>
                <ExpandMoreIcon
                  onClick={handleExpandClick}
                  sx={{
                    color: '#fff',
                    transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                    cursor: 'pointer',
                  }}
                />
              </Avatar>
            </Box>
            <Box display="flex" justifyContent="space-between" sx={{ mt: 1.5 }}>
              <Box display="flex" alignItems="center">
                <Tooltip title={getTooltipContent()} open={tooltipOpen} onClose={handleTooltipClose} arrow>
                  <Avatar
                    sizes="small"
                    onClick={() => handleAvatarClick()}
                    sx={{
                      width: 13,
                      height: 13,
                      ml: 0.5,
                      mr: 1.6,
                      bgcolor:
                        data.nodata === 1 || data.flg_ev === '-1'
                          ? 'red'
                          : data.nodata === 0 &&
                            (data.juncboxchannel.some((channel) => channel.curt === 0) ||
                              data.juncboxchannel.some((channel) => channel.curnodata === 1))
                          ? 'orange'
                          : '#73E600',
                      color:
                        data.nodata === 1 || data.flg_ev === '-1'
                          ? 'red'
                          : data.nodata === 0 &&
                            (data.juncboxchannel.some((channel) => channel.curt === 0) ||
                              data.juncboxchannel.some((channel) => channel.curnodata === 1))
                          ? 'orange'
                          : '#73E600',
                    }}
                  >
                    {''}
                  </Avatar>
                </Tooltip>
                <Typography variant="subtitle2">상태</Typography>
              </Box>
              <Box display="flex">
                <Card
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    borderRadius: '14px',
                    borderColor: '#CECECE',
                    bgcolor: '#000',
                    color: '#fff',
                  }}
                >
                  <Typography variant="subtitle2" sx={{ ml: 1, mr: 1 }}>
                    <span style={{ color: '#6fe877' }}>{data.jvolt}</span> V
                  </Typography>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    borderRadius: '14px',
                    borderColor: '#CECECE',
                    bgcolor: '#000',
                    color: '#fff',
                    ml: 1,
                  }}
                >
                  <Typography variant="subtitle2" sx={{ ml: 1, mr: 1 }}>
                    <span style={{ color: '#6fe877' }}>{data.sumcurt.toFixed(1)}</span> A
                  </Typography>
                </Card>
                <Card
                  variant="outlined"
                  sx={{
                    display: 'flex',
                    borderRadius: '14px',
                    borderColor: '#CECECE',
                    bgcolor: '#000',
                    color: '#fff',
                    ml: 1,
                  }}
                >
                  <Typography variant="subtitle2" sx={{ ml: 1, mr: 1 }}>
                    <span style={{ color: '#6FE1E8' }}>{data.jtemp}</span> ℃
                  </Typography>
                </Card>
              </Box>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Divider sx={{ mb: 1, mt: 1 }} />
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <ButtonGroup
                  variant="outlined"
                  color="inherit"
                  size="small"
                  sx={{ bgcolor: 'white', mt: 1.5 }}
                >
                  <Button
                    onClick={chartViewClick}
                    sx={{
                      borderColor: '#d0d0d0',
                      bgcolor: chartView ? '#0059B2' : '#DDE1EA',
                      color: chartView ? '#fff' : '#000',
                      '&:hover': {
                        bgcolor: chartView ? '#0059B2' : '#DDE1EA',
                      },
                      '&:active, &:focus': {
                        bgcolor: chartView ? '#0059B2' : '#DDE1EA',
                      },
                    }}
                  >
                    <BarChartIcon />
                  </Button>
                  <Button
                    onClick={TableViewClick}
                    sx={{
                      borderColor: '#d0d0d0',
                      bgcolor: chartView ? '#DDE1EA' : '#0059B2',
                      color: chartView ? '#000' : '#fff',
                      '&:hover': {
                        bgcolor: chartView ? '#DDE1EA' : '#0059B2',
                      },
                      '&:active, &:focus': {
                        bgcolor: chartView ? '#DDE1EA' : '#0059B2',
                      },
                    }}
                  >
                    <FormatListBulletedIcon />
                  </Button>
                </ButtonGroup>
              </Box>
              <Box sx={{ mt: 1 }}>
                {chartView ? (
                  <JboxChart data={data.juncboxchannel} />
                ) : (
                  <JboxTable data={data.juncboxchannel} />
                )}
              </Box>
            </Collapse>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}
