import { Box, Grid, Typography } from '@mui/material';
import MainCard from '../../theme/MainCard';
import pannel01 from '../../assets/PlantEletricalSystemImg/pannel01.png';
import pannel02 from '../../assets/PlantEletricalSystemImg/pannel02.png';
import inverter01 from '../../assets/PlantEletricalSystemImg/inverter01.png';
import pannelSvg01 from '../../assets/PlantEletricalSystemImg/pannelSvg01.svg';
import pannelSvg02 from '../../assets/PlantEletricalSystemImg/pannelSvg02.svg';
import inverterSvg01 from '../../assets/PlantEletricalSystemImg/inverterSvg01.svg';
import acbSvg02 from '../../assets/PlantEletricalSystemImg/acbSvg02.svg';
import transtowerSvg02 from '../../assets/PlantEletricalSystemImg/transtowerSvg02.svg';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';

export default function PlantEletricSystemCard({
  invDailyDevelopment,
  acbVcbDailyDevelopment,
  basePlantInfo,
  isIpad,
  isNexthub,
}) {
  // invDailyDevelopment 각 합계를 저장할 변수 초기화
  let totalInputPower = 0;
  let totalOutputPower = 0;
  let totalInputVoltage = 0;
  let totalOutputVoltage = 0;
  let totalInputCurrent = 0;
  let totalOutputCurrent = 0;

  // invDailyDevelopment 배열을 순회하며 각 값들을 누적
  invDailyDevelopment.forEach((item) => {
    totalInputPower += item.pdc;
    totalOutputPower += item.pac;
    totalInputVoltage += item.udc;
    totalOutputVoltage += item.uac;
    totalInputCurrent += item.idc;
    totalOutputCurrent += item.iac;
  });

  // 입력전압, 출력전압 계산
  const avgInputVoltage = totalInputVoltage / invDailyDevelopment.length;
  const avgOutputVoltage = totalOutputVoltage / invDailyDevelopment.length;

  // ACB 및 VCB에 대한 전력의 합계 계산
  const totalAcbPower = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'ACB')
    .reduce((accumulator, item) => accumulator + item.actp * 0.001, 0);

  const totalVcbPower = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'VCB')
    .reduce((accumulator, item) => accumulator + item.actp * 0.001, 0);

  // ACB 및 VCB에 대한 전압의 합계 계산
  const totalAcbVoltage = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'ACB')
    .reduce((accumulator, item) => accumulator + item.ru + item.su + item.tu, 0);

  const totalVcbVoltage = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'VCB')
    .reduce((accumulator, item) => accumulator + item.ru + item.su + item.tu, 0);

  // ACB 및 VCB에 대한 평균 전압 계산
  const averageAcbVoltage =
    totalAcbVoltage / (acbVcbDailyDevelopment.filter((item) => item.acbvcbtype === 'ACB').length * 3);
  const averageVcbVoltage =
    totalVcbVoltage / (acbVcbDailyDevelopment.filter((item) => item.acbvcbtype === 'VCB').length * 3);

  // ACB 및 VCB에 대한 전류의 합계 계산
  const totalAcbCurrent = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'ACB')
    .reduce((accumulator, item) => accumulator + item.ri + item.si + item.ti, 0);

  const totalVcbCurrent = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'VCB')
    .reduce((accumulator, item) => accumulator + item.ri + item.si + item.ti, 0);

  // RTU 수신상태
  const isRtuStatusOne = basePlantInfo.some((plant) => plant.rtuinfos?.some((info) => info.rtustatus === 1));

  // 인버터 발전상태
  const isAnyInverterGenerating = invDailyDevelopment.some((inverter) => inverter.generatestatus === 1);

  // ACB 발전 상태
  const isAllAcbNodataOne = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'ACB')
    .every((item) => item.nodata === '1');

  const isAnyAcbNodataZero = acbVcbDailyDevelopment.some(
    (item) => item.acbvcbtype === 'ACB' && item.nodata === '0'
  );

  // VCB 발전 상태
  const isAllVcbNodataOne = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'VCB')
    .every((item) => item.nodata === '1');

  const isAnyVcbNodataZero = acbVcbDailyDevelopment.some(
    (item) => item.acbvcbtype === 'VCB' && item.nodata === '0'
  );

  return (
    <>
      {acbVcbDailyDevelopment.length === 0 ? (
        <>
          <Box display="flex" alignItems="center">
            <Grid item xs={24}>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <MainCard
                    sx={{
                      borderRadius: '15px',
                      boxShadow: '0px 3px 100px #00000014',
                      height: 210,
                    }}
                  >
                    <Box>
                      <Typography variant="h5">입력</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Box>
                        <Typography variant="h4" sx={{ color: '#FF5722' }}>
                          전력 :{' '}
                          {isRtuStatusOne
                            ? isAnyInverterGenerating
                              ? totalInputPower.toFixed(1)
                              : '--,--'
                            : '--,--'}
                          kW
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mt: 1 }}>
                          전압 :{' '}
                          {isRtuStatusOne
                            ? isAnyInverterGenerating
                              ? avgInputVoltage.toFixed(1)
                              : '--,--'
                            : '--,--'}
                          Vdc
                        </Typography>
                        <Typography variant="subtitle1">
                          전류 :{' '}
                          {isRtuStatusOne
                            ? isAnyInverterGenerating
                              ? totalInputCurrent.toFixed(1)
                              : '--,--'
                            : '--,--'}
                          A
                        </Typography>
                      </Box>
                      <KeyboardDoubleArrowRightIcon sx={{ fontSize: '6.5rem', color: '#FF5722' }} />
                      <img src={pannelSvg01} style={{ width: '110px', height: '120px' }} />
                    </Box>
                  </MainCard>
                </Grid>
                <Grid item lg={6} md={6} sm={6} xs={6}>
                  <MainCard
                    sx={{
                      borderRadius: '15px',
                      boxShadow: '0px 3px 100px #00000014',
                      height: 210,
                    }}
                  >
                    <Box>
                      <Typography variant="h5">출력</Typography>
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Box>
                        <Typography variant="h4" sx={{ color: '#00bc2d' }}>
                          전력 :{' '}
                          {isRtuStatusOne
                            ? isAnyInverterGenerating
                              ? totalOutputPower.toFixed(1)
                              : '--,--'
                            : '--,--'}
                          kW
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mt: 1 }}>
                          전압 :{' '}
                          {isRtuStatusOne
                            ? isAnyInverterGenerating
                              ? avgOutputVoltage.toFixed(1)
                              : '--,--'
                            : '--,--'}
                          Vac
                        </Typography>
                        <Typography variant="subtitle1">
                          전류 :{' '}
                          {isRtuStatusOne
                            ? isAnyInverterGenerating
                              ? totalOutputCurrent.toFixed(1)
                              : '--,--'
                            : '--,--'}
                          A
                        </Typography>
                      </Box>
                      <KeyboardDoubleArrowRightIcon sx={{ fontSize: '6.5rem', color: '#00bc2d' }} />
                      <img src={inverterSvg01} style={{ width: '115px', height: '120px' }} />
                    </Box>
                  </MainCard>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" alignItems="center">
            {/* <Grid item xs={24}>
              <Grid container spacing={2}>
                <Grid item lg={3} md={6} sm={6} xs={6}>
                  <MainCard
                    sx={{
                      // bgcolor: '#0059B2',
                      borderRadius: '15px',
                      boxShadow: '0px 3px 100px #00000014',
                      height: 195,
                      width: '100%',
                    }}
                  >
                    <Box display="flex" alignItems="center">
                      <Typography variant="h6">입력</Typography>
                      <KeyboardDoubleArrowRightIcon sx={{ fontSize: '2.25rem', color: '#FF5722', ml: 1 }} />
                    </Box>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Box>
                        <Typography variant="h6" sx={{ color: '#FF5722' }}>
                          전력{' '}
                          {isRtuStatusOne
                            ? isAnyInverterGenerating
                              ? totalInputPower.toFixed(1)
                              : '--,--'
                            : '--,--'}
                          kW
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mt: 1 }}>
                          전압{' '}
                          {isRtuStatusOne
                            ? isAnyInverterGenerating
                              ? avgInputVoltage.toFixed(1)
                              : '--,--'
                            : '--,--'}
                          Vdc
                        </Typography>
                        <Typography variant="subtitle1">
                          전류{' '}
                          {isRtuStatusOne
                            ? isAnyInverterGenerating
                              ? totalInputCurrent.toFixed(1)
                              : '--,--'
                            : '--,--'}
                          A
                        </Typography>
                      </Box>
                      <img src={pannelSvg01} style={{ width: '100px', height: '120px' }} />
                    </Box>
                  </MainCard>
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={6}>
                  <MainCard
                    sx={{
                      // bgcolor: '#0059B2',
                      borderRadius: '15px',
                      boxShadow: '0px 3px 100px #00000014',
                      height: 195,
                      width: '100%',
                    }}
                  ></MainCard>
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={6}>
                  <MainCard
                    sx={{
                      // bgcolor: '#0059B2',
                      borderRadius: '15px',
                      boxShadow: '0px 3px 100px #00000014',
                      height: 195,
                      width: '100%',
                    }}
                  ></MainCard>
                </Grid>
                <Grid item lg={3} md={6} sm={6} xs={6}>
                  <MainCard
                    sx={{
                      // bgcolor: '#0059B2',
                      borderRadius: '15px',
                      boxShadow: '0px 3px 100px #00000014',
                      height: 195,
                      width: '100%',
                    }}
                  ></MainCard>
                </Grid>
              </Grid>
            </Grid> */}
            <MainCard
              sx={{
                // bgcolor: '#0059B2',
                borderRadius: '15px',
                boxShadow: '0px 3px 100px #00000014',
                height: 210,
                width: '100%',
              }}
            >
              <Box display="flex" alignItems="center">
                <Box sx={{ width: '25%' }}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6">입력</Typography>
                    {isIpad || isNexthub ? null : (
                      <KeyboardDoubleArrowRightIcon sx={{ fontSize: '2.25rem', color: '#FF5722', ml: 1 }} />
                    )}
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Typography variant="h6" sx={{ color: '#FF5722' }}>
                        전력{' '}
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? totalInputPower.toFixed(1)
                            : '--,--'
                          : '--,--'}
                        kW
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        전압{' '}
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? avgInputVoltage.toFixed(1)
                            : '--,--'
                          : '--,--'}
                        Vac
                      </Typography>
                      <Typography variant="subtitle1">
                        전류{' '}
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? totalInputCurrent.toFixed(1)
                            : '--,--'
                          : '--,--'}
                        A
                      </Typography>
                    </Box>
                    {isIpad || isNexthub ? (
                      <KeyboardDoubleArrowRightIcon sx={{ fontSize: '2.5rem', color: '#FF5722', ml: 1 }} />
                    ) : (
                      <img src={pannelSvg02} style={{ width: '125px', height: '125px' }} />
                    )}
                    {/*  <img src={pannelSvg02} style={{ width: '100px', height: '120px' }} /> */}
                  </Box>
                </Box>
                <Box sx={{ width: '25%', ml: isIpad || isNexthub ? 4 : 12 }}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6">출력</Typography>
                    {isIpad || isNexthub ? null : (
                      <KeyboardDoubleArrowRightIcon sx={{ fontSize: '2.25rem', color: '#FF5722', ml: 1 }} />
                    )}
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Typography variant="h6" sx={{ color: '#FF5722' }}>
                        전력{' '}
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? totalOutputPower.toFixed(1)
                            : '--,--'
                          : '--,--'}
                        kW
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        전압{' '}
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? avgOutputVoltage.toFixed(1)
                            : '--,--'
                          : '--,--'}
                        Vdc
                      </Typography>
                      <Typography variant="subtitle1">
                        전류{' '}
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? totalOutputCurrent.toFixed(1)
                            : '--,--'
                          : '--,--'}
                        A
                      </Typography>
                    </Box>
                    {isIpad || isNexthub ? (
                      <KeyboardDoubleArrowRightIcon sx={{ fontSize: '2.5rem', color: '#FF5722', ml: 0 }} />
                    ) : (
                      <img src={inverterSvg01} style={{ width: '125px', height: '125px' }} />
                    )}
                    {/* <img src={inverterSvg01} style={{ width: '100px', height: '120px' }} /> */}
                  </Box>
                </Box>
                <Box sx={{ width: '25%', ml: isIpad || isNexthub ? 4 : 12 }}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6">ACB</Typography>
                    {isIpad || isNexthub ? null : (
                      <KeyboardDoubleArrowRightIcon sx={{ fontSize: '2.25rem', color: 'green', ml: 1 }} />
                    )}
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Typography variant="h6" sx={{ color: 'green' }}>
                        전력{' '}
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? isAllAcbNodataOne
                              ? '--,--'
                              : isAnyAcbNodataZero
                                ? totalAcbPower.toFixed(1)
                                : '--,--'
                            : '--,--'
                          : '--,--'}
                        kW
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        전압{' '}
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? isAllAcbNodataOne
                              ? '--,--'
                              : isAnyAcbNodataZero
                                ? averageAcbVoltage.toFixed(1)
                                : '--,--'
                            : '--,--'
                          : '--,--'}
                        Vac
                      </Typography>
                      <Typography variant="subtitle1">
                        전류{' '}
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? isAllAcbNodataOne
                              ? '--,--'
                              : isAnyAcbNodataZero
                                ? totalAcbCurrent.toFixed(1)
                                : '--,--'
                            : '--,--'
                          : '--,--'}
                        A
                      </Typography>
                    </Box>
                    {isIpad || isNexthub ? (
                      <KeyboardDoubleArrowRightIcon sx={{ fontSize: '2.5rem', color: 'green', ml: 0 }} />
                    ) : (
                      <img src={acbSvg02} style={{ width: '110px', height: '125px', marginLeft: 8 }} />
                    )}
                    {/* <img src={acbSvg02} style={{ width: '90px', height: '120px' }} /> */}
                  </Box>
                </Box>
                <Box sx={{ width: '25%', ml: isIpad || isNexthub ? 4 : 12 }}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h6">VCB</Typography>
                    {isIpad || isNexthub ? null : (
                      <KeyboardDoubleArrowRightIcon sx={{ fontSize: '2.25rem', color: 'green', ml: 1 }} />
                    )}
                  </Box>
                  <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Box>
                      <Typography variant="h6" sx={{ color: 'green' }}>
                        전력{' '}
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? isAllVcbNodataOne
                              ? '--,--'
                              : isAnyVcbNodataZero
                                ? totalVcbPower.toFixed(1)
                                : '--,--'
                            : '--,--'
                          : '--,--'}
                        kW
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mt: 1 }}>
                        전압{' '}
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? isAllVcbNodataOne
                              ? '--,--'
                              : isAnyVcbNodataZero
                                ? averageVcbVoltage.toFixed(1)
                                : '--,--'
                            : '--,--'
                          : '--,--'}
                        Vac
                      </Typography>
                      <Typography variant="subtitle1">
                        전류{' '}
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? isAllVcbNodataOne
                              ? '--,--'
                              : isAnyVcbNodataZero
                                ? totalVcbCurrent.toFixed(1)
                                : '--,--'
                            : '--,--'
                          : '--,--'}
                        A
                      </Typography>
                    </Box>
                    {isIpad || isNexthub ? null : (
                      <img src={transtowerSvg02} style={{ width: '115px', height: '125px' }} />
                    )}
                    {/* <img src={transtowerSvg02} style={{ width: '100px', height: '120px', marginLeft: 5 }} /> */}
                  </Box>
                </Box>
              </Box>
            </MainCard>
          </Box>
        </>
      )}
    </>
  );
}
