import {
  Box,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  tableCellClasses,
} from '@mui/material';
import styled from '@emotion/styled';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

// 발전소 및 RTU 등록 인버터 리스트 컴포넌트
export default function AddPlantInverterTable({ ivtList, setIvtList }) {
  const handleFieldChange = (e, index, field) => {
    const newValue = e.target.value;
    setIvtList((prevList) => {
      const newList = [...prevList];
      newList[index] = { ...newList[index], [field]: newValue };
      return newList;
    });
  };

  const handleCheckboxChange = (e, index) => {
    const isChecked = e.target.checked;
    const updatedList = [...ivtList];
    updatedList[index].nud = isChecked ? 'U' : 'N';
    setIvtList(updatedList);
  };

  const columns = [
    {
      field: 'isSet',
      headerName: '선택',
      width: 100,
      align: 'center',
      headerAlign: 'center',
    },
    { field: 'serialnum', headerName: '인버터 구분번호', width: 200, align: 'center', headerAlign: 'center' },
    {
      field: 'invertername',
      headerName: '인버터 이름',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'ivtcapacity',
      headerName: '인버터 용량(kW)',
      width: 200,
      align: 'center',
      headerAlign: 'center',
    },
    {
      field: 'ivtorder',
      headerName: '순서',
      width: 80,
      align: 'center',
      headerAlign: 'center',
    },
  ];

  return (
    <Box sx={{ mt: 2 }}>
      <StyledTableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.field}
                  align="center"
                  style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                >
                  {column.headerName}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {ivtList.map((row, index) => (
              <TableRow key={index}>
                <TableCell align="center">
                  <Checkbox
                    color="primary"
                    checked={row.nud === 'U'}
                    disabled={row.nud === 'D'}
                    onChange={(e) => handleCheckboxChange(e, index)}
                  />
                </TableCell>
                <TableCell align="center">{row.serialnum}</TableCell>
                <TableCell align="center">
                  <TextField
                    value={row.invertername}
                    onChange={(e) => handleFieldChange(e, index, 'invertername')}
                    disabled={row.nud === 'D' || row.nud === 'N'}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    type="number"
                    value={row.ivtcapacity}
                    onChange={(e) => handleFieldChange(e, index, 'ivtcapacity')}
                    disabled={row.nud === 'D' || row.nud === 'N'}
                  />
                </TableCell>
                <TableCell align="center">
                  <TextField
                    type="number"
                    value={row.ivtorder}
                    onChange={(e) => handleFieldChange(e, index, 'ivtorder')}
                    disabled={row.nud === 'D' || row.nud === 'N'}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Box>
  );
}
