import { Box, Button, Card, Tab, Tabs, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export default function MobileSearchBar({
  tabValue,
  envTabValue,
  subValue,
  dateValue,
  setDateValue,
  setSearchState,
  isDesktop,
  handleTabChange,
  handleEnvTabChange,
}) {
  const today = dayjs();

  const handleSearchStateClick = () => {
    setSearchState(true);
  };

  return (
    <>
      <Card
        sx={{
          width: '100%',
          borderRadius: '13px',
          boxShadow: '0px 3px 12px #00000014',
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: '#dfdfdf',
          }}
        >
          {subValue === 4 ? (
            <>
              <Tabs
                value={envTabValue}
                onChange={handleEnvTabChange}
                textColor="secondary"
                indicatorColor="secondary"
                variant="fullWidth"
                centered
              >
                <Tab value="2" label="10분" />
                <Tab value="1" label="60분" />
              </Tabs>
            </>
          ) : (
            <>
              <Tabs
                value={tabValue}
                onChange={handleTabChange}
                textColor="secondary"
                indicatorColor="secondary"
                variant="fullWidth"
                centered
              >
                <Tab value="all" label="전체" />
                <Tab value="group" label="그룹" />
                <Tab value="inverter" label="인버터" />
              </Tabs>
            </>
          )}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1, mt: 1 }}>
          {subValue === 3 ? (
            <Box></Box>
          ) : (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="subtitle2" sx={{ ml: 1.5 }}>
                  날짜 :
                </Typography>
                <DatePicker
                  value={dayjs(dateValue)}
                  onChange={(newValue) => {
                    setDateValue(newValue.$d);
                    setSearchState(true);
                  }}
                  openTo={
                    subValue === 0 || subValue === 4
                      ? 'day'
                      : subValue === 1
                      ? 'month'
                      : subValue === 2
                      ? 'year'
                      : null
                  }
                  views={
                    subValue === 0 || subValue === 4
                      ? ['year', 'month', 'day']
                      : subValue === 1
                      ? ['year', 'month']
                      : subValue === 2
                      ? ['year']
                      : null
                  }
                  format={
                    subValue === 0 || subValue === 4
                      ? 'YYYY-MM-DD'
                      : subValue === 1
                      ? 'YYYY-MM'
                      : subValue === 2
                      ? 'YYYY'
                      : null
                  }
                  sx={{ width: isDesktop ? '10%' : '140px' }}
                  slotProps={{
                    textField: { size: 'small', variant: 'standard', sx: { ml: 1.5, width: 125 } },
                  }}
                  maxDate={dayjs(today.toDate())}
                />
              </Box>
            </>
          )}
          <Button variant="contained" onClick={handleSearchStateClick} sx={{ mr: 1.5 }}>
            조회
          </Button>
        </Box>
      </Card>
    </>
  );
}
