import { Box, Breadcrumbs, Container } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import AddSubscribeKeyContent from '../../components/Subscribe/AddSubscribeKeyContent';
import { useContext, useEffect, useState } from 'react';
import config from '../../config';
import AuthContext from '../../store/auth-context';
import useBackendV2 from '../../hooks/use-backend-v2';
import SelectRtuToPlant from '../../components/Subscribe/SelectRtuToPlant';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import KeyIcon from '@mui/icons-material/Key';
import Header02 from '../../components/Layout/Header02';
import SubHeader from '../../components/Layout/SubHeader';
import Nav from '../../components/Layout/Nav';
import usePageLogRecord from '../../hooks/usePageLogRecord';

// 구독 라이선스 입력 페이지 컴포넌트
export default function AddSubscribeKeyPage() {
  const isDesktop = useResponsive('up', 'lg');

  const authCtx = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const distance = 0;

  const plantStateConditionInitialState = {
    pagetype: '',
    tabtype: '',
    plantkey: '',
    plantname: '',
  };

  const [plantState, setPlantState] = useState(plantStateConditionInitialState);
  const [selectPlantState, setSelectPlantState] = useState([]);
  const [selectPlantRtu, setSelectPlantRtu] = useState([]);

  const selectPlantStateChangeHandler = (plant) => {
    setPlantState((prev) => {
      return { ...prev, plantkey: plant.plantkey, plantname: plant.plantname };
    });
    localStorage.setItem('selectPlantkey', plant.plantkey);
  };

  const backendRequest = useBackendV2();

  const fetchUserPlantsState = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.userPlantsV2}/${authCtx.currentUser.id}`
      );

      if (data.length === 1) {
        setPlantState((prevPage) => ({
          ...prevPage,
          plantkey: data[0].plantkey,
          plantname: data[0].plantname,
        }));
        setSelectPlantState(data);
      } else {
        setSelectPlantState(data);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchRtuToPlant = async () => {
    if (plantState.plantkey !== '') {
      try {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.plants.subscribe}/${plantState.plantkey}`
        );

        setSelectPlantRtu(data);
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchUserPlantsState();
    fetchUserStylePreference();
  }, []);

  useEffect(() => {
    fetchRtuToPlant();
  }, [plantState.plantkey]);

  useEffect(() => {
    // 로컬 스토리지에서 selectPlantkey 값을 가져옴
    const localStorageSelectPlantkey = localStorage.getItem('selectPlantkey');

    // selectPlantState에서 해당하는 발전소 탐색
    const initialSelectedPlant = selectPlantState.find(
      (option) => option.plantkey === localStorageSelectPlantkey
    );

    // 첫번째 발전소 탐색
    const initialSelectedPlantN = selectPlantState[0];

    // 찾은 발전소를 초기값으로 설정
    if (initialSelectedPlant) {
      selectPlantStateChangeHandler(initialSelectedPlant);
    }
    // 찾은 발전소가 없는 경우 첫번째 발전소를 초기값으로 설정
    else if (initialSelectedPlant === undefined && initialSelectedPlantN !== undefined) {
      selectPlantStateChangeHandler(initialSelectedPlantN);
    }
  }, [selectPlantState]);

  usePageLogRecord('addSubscribeKeys', plantState.plantkey);

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        plantkey={plantState.plantkey}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      {isDesktop ? (
        <Container
          sx={{
            minWidth: '75%',
            marginBottom: '4%',
          }}
        >
          <>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <StyledBreadcrumb label="이용권 등록" icon={<KeyIcon fontSize="small" color="primary" />} />
            </Breadcrumbs>
            <Box sx={{ mt: 2.5 }}>
              <AddSubscribeKeyContent
                selectPlantState={selectPlantState}
                plantState={plantState}
                selectPlantStateChangeHandler={selectPlantStateChangeHandler}
                isDesktop={isDesktop}
              />
              {selectPlantRtu.map((rtu, index) => (
                <SelectRtuToPlant key={index} rtu={rtu} plantkey={plantState.plantkey} />
              ))}
            </Box>
          </>
        </Container>
      ) : (
        <>
          <Box>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'flex',
                },
              }}
            >
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <StyledBreadcrumb label="이용권 등록" icon={<KeyIcon fontSize="small" color="primary" />} />
              </Breadcrumbs>
            </Box>
            <AddSubscribeKeyContent
              selectPlantState={selectPlantState}
              plantState={plantState}
              selectPlantStateChangeHandler={selectPlantStateChangeHandler}
              isDesktop={isDesktop}
            />
            {selectPlantRtu.map((rtu, index) => (
              <SelectRtuToPlant key={index} rtu={rtu} plantkey={plantState.plantkey} />
            ))}
          </Box>
        </>
      )}
    </>
  );
}
