import {
  Box,
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  MenuItem,
  Pagination,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import sol_logo_login from '../../assets/EtcImg/sol_logo_login.png';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useContext, useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import AuthContext from '../../store/auth-context';
import useInterval from '../../hooks/use-interval';
import config from '../../config';
import { fNumberWithCommas } from '../../utils/formatNumber';
import EntirePlantInfoTable from '../../components/Dashboard/EntirePlant/EntirePlantInfoTable';
import { useNavigate } from 'react-router-dom';
import useInput from '../../hooks/use-input';
import { validatePhone } from '../../utils/validationUtil';
import usePageLogRecord from '../../hooks/usePageLogRecord';
import CompanyIntroduction from '../../components/Layout/CompanyIntroduction';
import AccountPopover from '../../components/Header/AccountPopover';
import useResponsive from '../../hooks/useResponsive';

export default function AllStatusPlatePage() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'lg');

  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));

  const [plant, setPlant] = useState([]);

  // 인버터 타입
  const [ivtTypeList, setIvtTypeList] = useState([]);

  const [selectIvtType, setSelectIvtType] = useState('전체선택');
  const [selectSearchOption, setSelectSearchOption] = useState('--SELECT--');
  const [selectRtuType, setSelectRtuType] = useState('전체');
  const [selectNetworkType, setSelectNetworkType] = useState('전체');

  const [optRtuSts, setOptRtuSts] = useState('');
  const [optIvtSts, setOptIvtSts] = useState('');
  const [optExpire, setOptExpire] = useState('0');

  // 전체 발전소
  const [numPlants, setNumPlants] = useState(0);

  // 전체 RTU
  const [numPlantsWithData, setNumPlantsWithData] = useState(0);

  // RTU 통신 이상
  const [numPlantsWithNodata, setNumPlantsWithNodata] = useState(0);

  // 전체 인버터
  const [numRsInverters, setNumRsInverters] = useState(0);

  // 인버터 발전 이상
  const [numRsInvertersWithIvtNodata, setNumRsInvertersWithIvtNodata] = useState(0);

  // 총 페이지 수
  const [lastPage, setLastPage] = useState(1);

  // 현제 페이지
  const [currentPage, setCurrentPage] = useState(1);

  // 발전소 명 검색 텍스트필드
  const plantNameInput = useInput('');

  // 고객 전화번호 검색 텍스트필드
  const phoneInput = useInput((value) => validatePhone(value));

  // RTU번호 검색 텍스트필드
  const RtuNumInput = useInput('');

  // 주소 검색 텍스트필드
  const addressInput = useInput('');

  const backendRequest = useBackendV2();

  const fetchSummaryPlants = async () => {
    const backendRequestConfig = {
      method: 'POST',
      body: {
        id: authCtx.currentUser.id,
        pageSize: 30,
        curPage: 1,
        schOptPname: plantNameInput.value,
        schOptPhone: phoneInput.value,
        schOptRTUType: selectRtuType === '전체' ? '' : selectRtuType,
        schOptIvtType: selectIvtType === '전체선택' ? '' : selectIvtType,
        schOptNetType: selectNetworkType === '전체' ? '' : selectNetworkType,
        schOptDvcBuiltType: selectSearchOption === '--SELECT--' ? '' : selectSearchOption,
        schOptRTUNum: RtuNumInput.value,
        schOptAddress: addressInput.value,
        schOptRtuSts: optRtuSts,
        schOptIvtSts: optIvtSts,
        schOptExpire: Number(optExpire),
        schOptExpireDay: 90,
      },
    };

    const summaryResult = await backendRequest.sendRequest(
      config.backend.endPoint.plants.plantInverterListSummary,
      backendRequestConfig
    );

    const summaryData = summaryResult.data;

    setNumPlants(summaryData.find((item) => item.cname === 'plttotalcnt').cnt);
    setNumPlantsWithData(summaryData.find((item) => item.cname === 'rtutotalcnt').cnt);
    setNumPlantsWithNodata(summaryData.find((item) => item.cname === 'rtustopcnt').cnt);
    setNumRsInverters(summaryData.find((item) => item.cname === 'ivttotalcnt').cnt);
    setNumRsInvertersWithIvtNodata(summaryData.find((item) => item.cname === 'ivtstopcnt').cnt);
  };

  const fetchPlants = async (curPage) => {
    try {
      const backendRequestConfig = {
        method: 'POST',
        body: {
          id: authCtx.currentUser.id,
          pageSize: 30,
          curPage: curPage !== undefined ? curPage : currentPage,
          schOptPname: plantNameInput.value,
          schOptPhone: phoneInput.value,
          schOptRTUType: selectRtuType === '전체' ? '' : selectRtuType,
          schOptIvtType: selectIvtType === '전체선택' ? '' : selectIvtType,
          schOptNetType: selectNetworkType === '전체' ? '' : selectNetworkType,
          schOptDvcBuiltType: selectSearchOption === '--SELECT--' ? '' : selectSearchOption,
          schOptRTUNum: RtuNumInput.value,
          schOptAddress: addressInput.value,
          schOptRtuSts: optRtuSts,
          schOptIvtSts: optIvtSts,
          schOptExpire: Number(optExpire),
          schOptExpireDay: 90,
        },
      };

      const dataResult = await backendRequest.sendRequest(
        config.backend.endPoint.plants.plantInverterListData,
        backendRequestConfig
      );

      const listData = dataResult.data;

      setLastPage(listData.lastPage);
      setPlant(listData.rsList);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchIvtListCode = async () => {
    try {
      const { data } = await backendRequest.sendRequest(`${config.backend.endPoint.codeSubs.IvtListCode}`);

      setIvtTypeList(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchPollingInterval = 120000;

  useInterval(() => {
    if (authCtx.currentUser.role !== '사이트 관리자') {
      fetchPlants();
    }
  }, fetchPollingInterval);

  useEffect(() => {
    if (authCtx.currentUser.role !== '사이트 관리자') {
      fetchPlants();
    } else if (
      authCtx.currentUser.role === '사이트 관리자' &&
      selectRtuType !== '전체' &&
      selectIvtType !== '전체선택' &&
      selectNetworkType !== '전체' &&
      selectSearchOption !== '--SELECT--' &&
      RtuNumInput.value !== '' &&
      addressInput.value !== '' &&
      plantNameInput.value !== '' &&
      phoneInput.value !== '' &&
      optRtuSts !== '' &&
      optIvtSts !== '' &&
      optExpire !== '0'
    ) {
      fetchPlants();
    }
  }, [currentPage]);

  useEffect(() => {
    fetchIvtListCode();
    fetchSummaryPlants();
  }, []);

  const closeModalHandler = () => {
    localStorage.setItem('menuValue', 0);
    localStorage.setItem('menuSubValue', 0);
    navigate('/', { state: { subValue: 0 } });
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSelectIvtChange = (event) => {
    setSelectIvtType(event.target.value);
  };

  const handleSelectSearchOptionChange = (event) => {
    setSelectSearchOption(event.target.value);
  };

  const handleSelectRtuTypeChange = (event) => {
    setSelectRtuType(event.target.value);
  };

  const handleSelectNetworkTypeChange = (event) => {
    setSelectNetworkType(event.target.value);
  };

  const searchClickHandler = () => {
    setCurrentPage(1);

    const curPage = 1;

    if (
      authCtx.currentUser.role === '사이트 관리자' &&
      selectRtuType === '전체' &&
      selectIvtType === '전체선택' &&
      selectNetworkType === '전체' &&
      selectSearchOption === '--SELECT--' &&
      RtuNumInput.value === '' &&
      addressInput.value === '' &&
      plantNameInput.value === '' &&
      phoneInput.value === '' &&
      optRtuSts === '' &&
      optIvtSts === '' &&
      optExpire === '0'
    ) {
      setPlant([]);
      setLastPage(1);
      fetchSummaryPlants();
    } else {
      fetchSummaryPlants();
      fetchPlants(curPage);
    }
  };

  const resetClickHandler = () => {
    setSelectIvtType('전체선택');
    setSelectSearchOption('--SELECT--');
    setSelectRtuType('전체');
    setSelectNetworkType('전체');
    setOptRtuSts('');
    setOptIvtSts('');
    setOptExpire('0');
    plantNameInput.setValue('');
    addressInput.setValue('');
    phoneInput.setValue('');
    RtuNumInput.setValue('');
    phoneInput.setValue('');
  };

  usePageLogRecord('allStatusPlates');

  return (
    <Container
      sx={{
        minWidth: '100%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          width: '100%',
          bgcolor: '#fff',
        }}
      >
        <Box display="flex" alignItems="center" sx={{ mb: 1, mt: 1 }}>
          <img src={sol_logo_login} alt="SOLARCUBE" style={{ width: 45, height: 45 }} />
          <Typography
            variant="h5"
            color="inherit"
            sx={{
              display: 'flex',
              fontFamily: '"Segoe UI Symbol"',
              ml: 1.5,
              fontWeight: 'bold',
            }}
          >
            SOLAR CUBE
          </Typography>
        </Box>
        {authCtx.currentUser.role === '사이트 관리자' ? (
          // <IconButton color="inherit" size="large">
          //   <MenuIcon sx={{ fontSize: '2.3rem' }} />
          // </IconButton>
          <AccountPopover
            value={value}
            setValue={setValue}
            subValue={subValue}
            setSubValue={setSubValue}
            pageNm="allStatus"
          />
        ) : (
          <IconButton onClick={closeModalHandler} size="large">
            <CloseIcon sx={{ fontSize: '2.3rem' }} />
          </IconButton>
        )}
      </Box>
      <hr />
      <Box
        sx={{
          width: '100%',
          p: 0.5,
          background: '#F8F9FE 0% 0% no-repeat padding-box',
        }}
      >
        <Typography variant="h4" sx={{ mb: 1.5 }}>
          전체 현황
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item lg={2} md={6} sm={6} xs={6}>
                <Card
                  sx={{
                    p: 1,
                    borderRadius: '11px',
                    boxShadow: '0px 3px 100px #00000014',
                    border: '1px solid #30b9f9',
                    height: 100,
                  }}
                >
                  <Typography variant="h6" sx={{ color: '#0079f9' }}>
                    전체 발전소
                  </Typography>
                  <Box sx={{ mt: 0 }}>
                    <Typography display="flex" justifyContent="end" variant="h2" sx={{ color: '#0079f9' }}>
                      {fNumberWithCommas(numPlants)}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item lg={2} md={6} sm={6} xs={6}>
                <Card
                  sx={{
                    p: 1,
                    borderRadius: '11px',
                    boxShadow: '0px 3px 100px #00000014',
                    border: '1px solid #30b9f9',
                    height: 100,
                  }}
                >
                  <Typography variant="h6" sx={{ color: '#0079f9' }}>
                    전체 인버터
                  </Typography>
                  <Box sx={{ mt: 0 }}>
                    <Typography display="flex" justifyContent="end" variant="h2" sx={{ color: '#0079f9' }}>
                      {fNumberWithCommas(numRsInverters)}
                    </Typography>
                  </Box>
                </Card>
              </Grid>
              <Grid item lg={2} md={6} sm={6} xs={6}>
                <Card
                  sx={{
                    p: 1,
                    borderRadius: '11px',
                    boxShadow: '0px 3px 100px #00000014',
                    border: '1px solid #fe772d',
                    height: 100,
                  }}
                >
                  <Typography variant="h6" sx={{ color: '#fa772d' }}>
                    RTU 통신중
                  </Typography>
                  <Box sx={{ mt: 0 }}>
                    <Typography display="flex" justifyContent="end" variant="h3" sx={{ color: '#35c55c ' }}>
                      {fNumberWithCommas(numPlantsWithData - numPlantsWithNodata)}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      color="success"
                      value={((numPlantsWithData - numPlantsWithNodata) / numPlantsWithData) * 100}
                      sx={{ height: 10, borderRadius: 5 }}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item lg={2} md={6} sm={6} xs={6}>
                <Card
                  sx={{
                    p: 1,
                    borderRadius: '11px',
                    boxShadow: '0px 3px 100px #00000014',
                    border: '1px solid #fe772d',
                    height: 100,
                  }}
                >
                  <Typography variant="h6" sx={{ color: '#fa772d' }}>
                    RTU 통신중단
                  </Typography>
                  <Box sx={{ mt: 0 }}>
                    <Typography display="flex" justifyContent="end" variant="h3" sx={{ color: '#fa0f00' }}>
                      {fNumberWithCommas(numPlantsWithNodata)}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      color="error"
                      value={(numPlantsWithNodata / numPlants) * 100}
                      sx={{ height: 10, borderRadius: 5 }}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item lg={2} md={6} sm={6} xs={6}>
                <Card
                  sx={{
                    p: 1,
                    borderRadius: '11px',
                    boxShadow: '0px 3px 100px #00000014',
                    border: '1px solid #6bcd29',
                    height: 100,
                  }}
                >
                  <Typography variant="h6" sx={{ color: '#099d29' }}>
                    인버터 발전중
                  </Typography>
                  <Box sx={{ mt: 0 }}>
                    <Typography display="flex" justifyContent="end" variant="h3" sx={{ color: '#35c55c ' }}>
                      {fNumberWithCommas(numRsInverters - numRsInvertersWithIvtNodata)}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      color="success"
                      value={((numRsInverters - numRsInvertersWithIvtNodata) / numRsInverters) * 100}
                      sx={{ height: 10, borderRadius: 5 }}
                    />
                  </Box>
                </Card>
              </Grid>
              <Grid item lg={2} md={6} sm={6} xs={6}>
                <Card
                  sx={{
                    p: 1,
                    borderRadius: '11px',
                    boxShadow: '0px 3px 100px #00000014',
                    border: '1px solid #6bcd29',
                    height: 100,
                  }}
                >
                  <Typography variant="h6" sx={{ color: '#099d29' }}>
                    인버터 발전중단
                  </Typography>
                  <Box sx={{ mt: 0 }}>
                    <Typography display="flex" justifyContent="end" variant="h3" sx={{ color: '#fa0f00' }}>
                      {fNumberWithCommas(numRsInvertersWithIvtNodata)}
                    </Typography>
                    <LinearProgress
                      variant="determinate"
                      color="error"
                      value={(numRsInvertersWithIvtNodata / numRsInverters) * 100}
                      sx={{ height: 10, borderRadius: 5 }}
                    />
                  </Box>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Card
          sx={{
            mt: 2,
            borderRadius: '10px',
            boxShadow: '0px 3px 100px #00000014',
            border: '1px solid #a0a0a0',
            bgcolor: '#edf4ff',
          }}
        >
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12}>
              <Grid container spacing={1} sx={{ p: 1 }}>
                {isDesktop ? (
                  <>
                    <Grid item lg={3} md={6} sm={6} xs={6}>
                      <Typography variant="subtitle1">RTU 수신상태</Typography>
                      <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                        <Box
                          sx={{
                            width: '100%',
                            height: 45,
                            mr: 1.5,
                          }}
                        >
                          <RadioGroup
                            row
                            value={optRtuSts}
                            onChange={(e) => {
                              setOptRtuSts(e.target.value);
                            }}
                            sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
                          >
                            <Card
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '32%',
                                borderRadius: '5px',
                                boxShadow: '0px 3px 100px #00000014',
                                border: '1px solid #a0a0a0',
                              }}
                            >
                              <FormControlLabel value="" control={<Radio />} label="전체" />
                            </Card>
                            <Card
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '32%',
                                borderRadius: '5px',
                                boxShadow: '0px 3px 100px #00000014',
                                border: '1px solid #a0a0a0',
                              }}
                            >
                              <FormControlLabel value="2" control={<Radio />} label="통신중" />
                            </Card>
                            <Card
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '32%',
                                borderRadius: '5px',
                                boxShadow: '0px 3px 100px #00000014',
                                border: '1px solid #a0a0a0',
                              }}
                            >
                              <FormControlLabel value="3" control={<Radio />} label="통신중단" />
                            </Card>
                          </RadioGroup>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={6} sm={6} xs={6}>
                      <Typography variant="subtitle1">발전상태</Typography>
                      <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                        <Box
                          sx={{
                            width: '100%',
                            height: 45,
                            mr: 1.5,
                          }}
                        >
                          <RadioGroup
                            row
                            value={optIvtSts}
                            onChange={(e) => {
                              setOptIvtSts(e.target.value);
                            }}
                            sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
                          >
                            <Card
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '32%',
                                borderRadius: '5px',
                                boxShadow: '0px 3px 100px #00000014',
                                border: '1px solid #a0a0a0',
                              }}
                            >
                              <FormControlLabel value="" control={<Radio />} label="전체" />
                            </Card>
                            <Card
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '32%',
                                borderRadius: '5px',
                                boxShadow: '0px 3px 100px #00000014',
                                border: '1px solid #a0a0a0',
                              }}
                            >
                              <FormControlLabel value="2" control={<Radio />} label="발전중" />
                            </Card>
                            <Card
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '32%',
                                borderRadius: '5px',
                                boxShadow: '0px 3px 100px #00000014',
                                border: '1px solid #a0a0a0',
                              }}
                            >
                              <FormControlLabel value="3" control={<Radio />} label="발전중단" />
                            </Card>
                          </RadioGroup>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={1.5} md={6} sm={6} xs={6}>
                      <Typography variant="subtitle1">인버터 타입</Typography>
                      <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                        <Card
                          sx={{
                            borderRadius: '5px',
                            boxShadow: '0px 3px 100px #00000014',
                            border: '1px solid #a0a0a0',
                            width: '100%',
                            height: 45,
                            mr: 1,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <FormControl fullWidth size="small">
                            <Select
                              value={selectIvtType}
                              onChange={handleSelectIvtChange}
                              sx={{ minWidth: '100%', height: '100%' }}
                            >
                              <MenuItem value={'전체선택'}>전체선택</MenuItem>
                              {ivtTypeList.map((item) => (
                                <MenuItem key={item.optionValue} value={item.optionValue}>
                                  {item.optionName}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Card>
                      </Box>
                    </Grid>
                    <Grid item lg={1.5} md={6} sm={6} xs={6}>
                      <Typography variant="subtitle1">RTU 타입</Typography>
                      <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                        <Card
                          sx={{
                            borderRadius: '5px',
                            boxShadow: '0px 3px 100px #00000014',
                            border: '1px solid #a0a0a0',
                            width: '100%',
                            height: 45,
                            mr: 1,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <FormControl fullWidth size="small">
                            <Select
                              value={selectRtuType}
                              onChange={handleSelectRtuTypeChange}
                              sx={{ minWidth: '100%', height: '100%' }}
                            >
                              <MenuItem value={'전체'}>전체</MenuItem>
                              <MenuItem value={'Q'}>2200E</MenuItem>
                              <MenuItem value={'P'}>2200E Plus</MenuItem>
                              <MenuItem value={'M'}>2200M</MenuItem>
                              <MenuItem value={'N'}>2200N</MenuItem>
                              <MenuItem value={'ETC'}>기타</MenuItem>
                            </Select>
                          </FormControl>
                        </Card>
                      </Box>
                    </Grid>
                    <Grid item lg={1.5} md={6} sm={6} xs={6}>
                      <Typography variant="subtitle1">전송 방식</Typography>
                      <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                        <Card
                          sx={{
                            borderRadius: '5px',
                            boxShadow: '0px 3px 100px #00000014',
                            border: '1px solid #a0a0a0',
                            width: '100%',
                            height: 45,
                            mr: 1,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <FormControl fullWidth size="small">
                            <Select
                              value={selectNetworkType}
                              onChange={handleSelectNetworkTypeChange}
                              sx={{ minWidth: '100%', height: '100%' }}
                            >
                              <MenuItem value={'전체'}>전체</MenuItem>
                              <MenuItem value={'LINE'}>유선</MenuItem>
                              <MenuItem value={'LTE'}>LTE</MenuItem>
                            </Select>
                          </FormControl>
                        </Card>
                      </Box>
                    </Grid>
                    <Grid item lg={1.5} md={6} sm={6} xs={6}>
                      <Typography variant="subtitle1">기타 선택</Typography>
                      <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                        <Card
                          sx={{
                            borderRadius: '5px',
                            boxShadow: '0px 3px 100px #00000014',
                            border: '1px solid #a0a0a0',
                            width: '100%',
                            height: 45,
                            mr: 1,
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          <FormControl fullWidth size="small">
                            <Select
                              value={selectSearchOption}
                              onChange={handleSelectSearchOptionChange}
                              sx={{ minWidth: '100%', height: '100%' }}
                            >
                              <MenuItem value={'--SELECT--'}>--SELECT--</MenuItem>
                              <MenuItem value={'jbox_cnt'}>접속반 설치</MenuItem>
                              <MenuItem value={'pacb_cnt'}>ACB,VCB 설치</MenuItem>
                              <MenuItem value={'stm2_cnt'}>String 설치</MenuItem>
                            </Select>
                          </FormControl>
                        </Card>
                      </Box>
                    </Grid>
                    <Grid item lg={3} md={6} sm={6} xs={6}>
                      <Typography variant="subtitle1">구독 만료 상태</Typography>
                      <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                        <Box
                          sx={{
                            width: '100%',
                            height: 45,
                            mr: 1.5,
                          }}
                        >
                          <RadioGroup
                            row
                            value={optExpire}
                            onChange={(e) => {
                              setOptExpire(e.target.value);
                            }}
                            sx={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}
                          >
                            <Card
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '32%',
                                borderRadius: '5px',
                                boxShadow: '0px 3px 100px #00000014',
                                border: '1px solid #a0a0a0',
                              }}
                            >
                              <FormControlLabel value="0" control={<Radio />} label="전체" />
                            </Card>
                            <Card
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '32%',
                                borderRadius: '5px',
                                boxShadow: '0px 3px 100px #00000014',
                                border: '1px solid #a0a0a0',
                              }}
                            >
                              <FormControlLabel value="1" control={<Radio />} label="만료" />
                            </Card>
                            <Card
                              sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '32%',
                                borderRadius: '5px',
                                boxShadow: '0px 3px 100px #00000014',
                                border: '1px solid #a0a0a0',
                              }}
                            >
                              <FormControlLabel value="2" control={<Radio />} label="만료예정" />
                            </Card>
                          </RadioGroup>
                        </Box>
                      </Box>
                    </Grid>
                  </>
                ) : null}
                <Grid item lg={1.5} md={6} sm={6} xs={6}>
                  <Typography variant="subtitle1">발전소 명</Typography>
                  <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                    <Card
                      sx={{
                        borderRadius: '5px',
                        boxShadow: '0px 3px 100px #00000014',
                        border: '1px solid #a0a0a0',
                        width: '100%',
                        height: 45,
                        mr: 1,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <TextField
                        variant="outlined"
                        onChange={plantNameInput.valueChangeHandler}
                        value={plantNameInput.value}
                        sx={{ width: '100%', height: '100%', mt: -1 }}
                      />
                    </Card>
                  </Box>
                </Grid>
                <Grid item lg={1.5} md={6} sm={6} xs={6}>
                  <Typography variant="subtitle1">사용자 전화번호</Typography>
                  <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                    <Card
                      sx={{
                        borderRadius: '5px',
                        boxShadow: '0px 3px 100px #00000014',
                        border: '1px solid #a0a0a0',
                        width: '100%',
                        height: 45,
                        mr: 1,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <TextField
                        variant="outlined"
                        onChange={phoneInput.valueChangeHandler}
                        value={phoneInput.value}
                        sx={{ width: '100%', height: '100%', mt: -1 }}
                      />
                    </Card>
                  </Box>
                </Grid>
                <Grid item lg={1.5} md={6} sm={6} xs={6}>
                  <Typography variant="subtitle1">RTU 번호</Typography>
                  <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                    <Card
                      sx={{
                        borderRadius: '5px',
                        boxShadow: '0px 3px 100px #00000014',
                        border: '1px solid #a0a0a0',
                        width: '100%',
                        height: 45,
                        mr: 1,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <TextField
                        variant="outlined"
                        onChange={RtuNumInput.valueChangeHandler}
                        value={RtuNumInput.value}
                        sx={{ width: '100%', height: '100%', mt: -1 }}
                      />
                    </Card>
                  </Box>
                </Grid>
                <Grid item lg={1.5} md={6} sm={6} xs={6}>
                  <Typography variant="subtitle1">주소 (지역명)</Typography>
                  <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                    <Card
                      sx={{
                        borderRadius: '5px',
                        boxShadow: '0px 3px 100px #00000014',
                        border: '1px solid #a0a0a0',
                        width: '100%',
                        height: 45,
                        mr: 1,
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <TextField
                        variant="outlined"
                        onChange={addressInput.valueChangeHandler}
                        value={addressInput.value}
                        sx={{ width: '100%', height: '100%', mt: -1 }}
                      />
                    </Card>
                  </Box>
                </Grid>
                <Grid item lg={1.5} md={6} sm={6} xs={6}>
                  <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                    <Button
                      variant="contained"
                      startIcon={<SearchIcon fontSize="large" />}
                      onClick={searchClickHandler}
                      sx={{ width: '100%', height: 60, mr: 1, mt: 1 }}
                    >
                      검색
                    </Button>
                  </Box>
                </Grid>
                <Grid item lg={1.5} md={6} sm={6} xs={6}>
                  <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
                    <Button
                      variant="outlined"
                      startIcon={<RefreshIcon fontSize="large" />}
                      onClick={resetClickHandler}
                      sx={{ width: '100%', height: 60, bgcolor: '#fff', mr: 1, mt: 1 }}
                    >
                      초기화
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Card>
        <Typography variant="body2" sx={{ mt: 2, mb: 1.5 }}>
          ※ 현재 페이지는 2분에 한번씩 리로드 됩니다.
        </Typography>
        <Box>
          <EntirePlantInfoTable plant={plant} />
        </Box>
        <Box display="flex" justifyContent="center" sx={{ width: '100%', mt: 2 }}>
          <Pagination count={lastPage} page={currentPage || 1} onChange={handlePageChange} size="large" />
        </Box>
      </Box>
      {isDesktop ? (
        <Box sx={{ mt: 1.5 }}>
          <CompanyIntroduction setValue={setValue} setSubValue={setSubValue} pageNm="allStatus" />
        </Box>
      ) : null}
    </Container>
  );
}
