import useResponsive from '../../../hooks/useResponsive';
import MInverterChart from '../../Charts/Statistics/Month/MInverterChart';
import MonthInverterStatisticsTable from './MonthInverterStatisticsTable';

export default function MonthInverterStatistics({ data, chartView }) {
  const isDesktop = useResponsive('up', 'lg');

  const groupedData = data.reduce((acc, data) => {
    const groupName = data.groupname;

    if (!acc[groupName]) {
      acc[groupName] = [];
    }

    acc[groupName].push(data);
    return acc;
  }, {});

  if (Object.keys(groupedData).length === 0) {
    return null;
  }

  const maxLength = Math.max(...Object.values(groupedData).map((group) => group.length));
  Object.keys(groupedData).forEach((groupName) => {
    const currentLength = groupedData[groupName].length;
    if (currentLength < maxLength) {
      const additionalItems = Array(maxLength - currentLength).fill(0);
      groupedData[groupName] = additionalItems.concat(groupedData[groupName]);
    }
  });

  Object.keys(groupedData).forEach((groupName) => {
    groupedData[groupName].sort((a, b) => a.flocaldate - b.flocaldate);
  });

  const processData = (groupedData) => {
    for (const groupName in groupedData) {
      const group = groupedData[groupName];
      const startIndex = group.findIndex((item) => item.flocaldate !== undefined); // 'flocaldate'가 있는 첫 번째 인덱스 찾기

      if (startIndex === -1) {
        // 'flocaldate'가 전혀 없는 경우 처리
        delete groupedData[groupName];
        continue;
      }

      const processedGroup = group.slice(startIndex); // 'flocaldate'가 있는 인덱스부터 끝까지의 요소 복사

      processedGroup.forEach((item, index) => {
        const date = new Date(item.flocaldate);
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 월을 2자리 문자열로 만들기
        processedGroup[index].flocaldate = `${year}-${month}`; // 연도와 월을 나타내는 문자열로 변경
      });

      // 0으로 채워진 요소 제거
      const cleanedGroup = processedGroup.filter((item) => item.flocaldate !== '0');

      // flocaldate가 2024-06부터 2024-12까지의 데이터 추가
      while (cleanedGroup.length < 12) {
        const lastDate = cleanedGroup[cleanedGroup.length - 1];
        const nextDate = new Date(lastDate.flocaldate);
        nextDate.setMonth(nextDate.getMonth() + 1);
        const year = nextDate.getFullYear();
        const month = (nextDate.getMonth() + 1).toString().padStart(2, '0');
        cleanedGroup.push({
          groupname: groupName,
          flocaldate: `${year}-${month}`,
          totalyield: null,
          generateHour: null,
          id: null,
        });
      }

      groupedData[groupName] = cleanedGroup;
    }

    return groupedData;
  };

  const cleanedData = processData(groupedData);

  const chartData = Array.from({ length: maxLength }).map((_, index) => {
    const entry = {
      name: cleanedData[Object.keys(cleanedData)[0]][index].flocaldate,
    };
    Object.keys(cleanedData).forEach((groupName) => {
      entry[groupName] = cleanedData[groupName][index].totalyield;
    });

    return entry;
  });

  return (
    <>
      {isDesktop ? (
        <>
          <MInverterChart data={data} groupedData={cleanedData} chartData={chartData} isDesktop={isDesktop} />
          {data.length === 0 ? null : (
            <MonthInverterStatisticsTable
              data={data}
              groupedData={cleanedData}
              chartData={chartData}
              isDesktop={isDesktop}
            />
          )}
        </>
      ) : chartView ? (
        <MInverterChart data={data} groupedData={cleanedData} chartData={chartData} isDesktop={isDesktop} />
      ) : (
        <MonthInverterStatisticsTable
          data={data}
          groupedData={cleanedData}
          chartData={chartData}
          isDesktop={isDesktop}
        />
      )}
    </>
  );
}
