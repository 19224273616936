import { Avatar, Box, Collapse, Divider, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import InvListCard from './InvListCard';
import MainCard from '../../../theme/MainCard';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import UseCustomSort from '../../../hooks/useCustomSort';

// 인버터 리스트 구성 개별 발전소 컴포넌트
export default function InvPlantCard({
  plantkey,
  plantName,
  address,
  nodata,
  ivtNodata,
  subscribeday,
  graceperiodDay,
  isDesktop,
}) {
  const [expanded, setExpanded] = useState(true);
  const [plantInvs, setPlantInvs] = useState(null);
  const [plantRtuStat, setPlantRtuStat] = useState(null);

  const plantInvsRequest = useBackendV2();

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const fetchData = async () => {
    try {
      const invsResponse = await plantInvsRequest.sendRequest(
        `${config.backend.endPoint.inverters.extReal}/${plantkey}`
      );

      const sortedData = UseCustomSort(invsResponse.data, 'invertername');
      setPlantInvs(sortedData);

      const rtuStatResponse = await plantInvsRequest.sendRequest(
        `${config.backend.endPoint.plants.baseInfo}/${plantkey}`
      );
      setPlantRtuStat(rtuStatResponse.data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  let entirePlantIvtInfo;

  if (plantInvs && plantRtuStat !== null) {
    entirePlantIvtInfo = plantInvs.map((d, index) => (
      <InvListCard
        key={index}
        invData={d}
        plantRtuStat={plantRtuStat}
        subscribeday={subscribeday}
        graceperiodDay={graceperiodDay}
        isDesktop={isDesktop}
      />
    ));
  }

  return (
    <>
      <Box sx={{ pb: 0, mb: 2 }}>
        <MainCard
          variant="outlined"
          sx={{
            '&:hover': { borderColor: 'blue', borderWidth: '2px' },
            filter: subscribeday - graceperiodDay > 0 ? 'blur(5px)' : '',
          }}
        >
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            width="100%"
            spacing={2}
          >
            <Stack flex={1}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body1">
                  <strong>{plantName}</strong>
                </Typography>
                <Avatar sx={{ width: '22px', height: '22px', bgcolor: '#1981E7' }}>
                  <ExpandMoreIcon
                    onClick={handleExpandClick}
                    sx={{
                      color: '#fff',
                      transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                      cursor: 'pointer',
                    }}
                  />
                </Avatar>
              </Box>
              <Box sx={{ mt: 1.5 }}>
                <Typography color="primary" variant="subtitle2" display="flex" alignContent="center">
                  <LocationOnIcon />
                  {address}
                </Typography>
              </Box>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <Box>{entirePlantIvtInfo}</Box>
              </Collapse>
            </Stack>
          </Stack>
        </MainCard>
      </Box>
    </>
  );
}
