import React, { useState } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Box, Tab, Tabs, createTheme, ThemeProvider } from '@mui/material';

// 전역 데이터 Props
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// 발전소 연계 페이지 탭 메뉴 컴포넌트
export default function ConnectionPlantTabMenu({ onTabChange }) {
  const [value, setValue] = useState(0);

  // 모바일 화면 구성을 위한 상수 선언
  const isGalaxySmall = useMediaQuery('(min-width: 280px) and (max-width: 369px)');
  const isOtherDevice = useMediaQuery('(min-width: 370px)');

  // 부모 컴포넌트에서 전달받은 핸들러 함수를 호출
  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  // 탭 매뉴 디자인 
  const tabTheme = createTheme({
    // 회색 테마
    palette:
    {
      primary: { main: '#263238' },
      secondary: { main: '#90a4ae' },
      info: { main: '#cfd8dc' },
    },
    // isGalaxySmall 전용 탭 디자인
    components:
    {
      MuiTab:
      {
        styleOverrides:
        {
          root:
          {
            '&.Mui-selected':
            {
              fontSize: isGalaxySmall ? '13.5px' : isOtherDevice ? 'auto' : 'auto',
            },
            '&:not(.Mui-selected)':
            {
              fontSize: isGalaxySmall ? '13.5px' : isOtherDevice ? 'auto' : 'auto',
            },
            maxWidth: isGalaxySmall ? '100%' : isOtherDevice ? 'auto' : 'auto',
          }
        }
      }
    }
  });

  return (
    <ThemeProvider theme={tabTheme}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          textColor="primary"
          indicatorColor="secondary"
          variant="scrollable"
          scrollButtons={isGalaxySmall ? 'auto' : isOtherDevice ? 'auto' : 'auto'}
          allowScrollButtonsMobile
          sx={{
            flexGrow: 1,
            minHeight: '20px',
            '.MuiTab-root': { minHeight: '20px' },
            '.MuiButtonBase-root': { minHeight: '20px' },
            width: isGalaxySmall ? '100%' : isOtherDevice ? 'auto' : 'auto',
          }}
        >
          {['연계 신청받은 발전소', '연계 신청한 발전소'].map((label, index) => (
            <Tab
              key={label}
              label={label}
              {...a11yProps(index)}
              sx={{
                height: isGalaxySmall ? '40px' : isOtherDevice ? 'auto' : 'auto',
                width: isGalaxySmall ? '100%' : isOtherDevice ? 'auto' : 'auto',
                bgcolor: value === index ? 'info.main' : 'transparent',
                borderRadius: value === index ? '4px' : '0',
                fontWeight: value === index ? 'bold' : 'normal',
              }}
            />
          ))}
        </Tabs>
      </Box>
    </ThemeProvider>
  );
}