import { useState } from 'react';
import useResponsive from '../../hooks/useResponsive';
import OrderCancelModal from './OrderCancelModal';
import OrderInfoListCard from './OrderInfoListCard';
import { Card, CircularProgress } from '@mui/material';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

// 주문 내역 조회 리스트
export default function OrderInfoList({ userOrderInfo, setOrderDepositorStatus }) {
  const isDesktop = useResponsive('up', 'lg');

  const cancelOrderInfo = {
    paymentkey: '',
    orderName: '',
    totalAmount: '',
  };

  const [orderCancelData, setOrderCancelData] = useState(cancelOrderInfo);
  const [orderCancelModal, setOrderCancelModal] = useState(false);

  const [expanded, setExpanded] = useState(false);
  const [visibleItems, setVisibleItems] = useState(10);

  // 주문일과 현재 날짜 간의 차이를 계산하는 함수
  const currentDate = new Date();

  const calculateDateDifference = (approveDate) => {
    const orderDate = new Date(approveDate);
    const differenceInTime = currentDate.getTime() - orderDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    return differenceInDays;
  };

  const cancelOrderClickHandler = (paymentkey, orderName, totalAmount) => {
    setOrderCancelData((prevPage) => ({
      ...prevPage,
      paymentkey: paymentkey,
      orderName: orderName,
      totalAmount: totalAmount,
    }));
    setOrderCancelModal(true);
  };

  const closeCancelOrderClickHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setOrderCancelData(null);
    setOrderCancelModal(false);
  };

  // 무한스크롤 체크 로직
  const loadMoreItems = () => {
    if (!expanded) {
      setExpanded(true);
      const newVisibleItems = visibleItems + 5;
      if (newVisibleItems <= userOrderInfo.length) {
        setTimeout(() => {
          setVisibleItems(newVisibleItems);
          setExpanded(false);
        }, 1000);
      } else if (visibleItems < userOrderInfo.length) {
        setTimeout(() => {
          setVisibleItems(userOrderInfo.length);
          setExpanded(false);
        }, 1000);
      } else {
        setExpanded(false);
      }
    }
  };
  const handleExpandClick = () => {
    expanded ? alert('로드 중입니다.') : loadMoreItems();
  };

  const visibleOrderLists = userOrderInfo !== undefined ? userOrderInfo.slice(0, visibleItems) : null;

  return (
    <>
      {visibleOrderLists.map((product, index) => (
        <OrderInfoListCard
          key={index}
          product={product}
          calculateDateDifference={calculateDateDifference}
          cancelOrderClickHandler={cancelOrderClickHandler}
          setOrderDepositorStatus={setOrderDepositorStatus}
          isDesktop={isDesktop}
        />
      ))}
      {userOrderInfo !== undefined ? (
        visibleItems < userOrderInfo.length ? (
          <Card
            variant="outlined"
            sx={{
              p: 1,
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: isDesktop ? 'pointer' : 'cursor',
              height: '40px',
              borderRadius: '7px',
              boxShadow: '0px 3px 24px #00000014',
            }}
            onClick={handleExpandClick}
          >
            {expanded ? <CircularProgress size={30} /> : <KeyboardDoubleArrowDownIcon />}
          </Card>
        ) : null
      ) : null}
      {orderCancelModal && (
        <OrderCancelModal
          open={orderCancelModal}
          onClose={closeCancelOrderClickHandler}
          orderCancelData={orderCancelData}
        />
      )}
    </>
  );
}
