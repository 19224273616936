import { Button, ButtonGroup, Paper, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import RequestTaxBillSearchModal from '../Authentication/RequestTaxBillSearchModal';
import UpdateTaxBillModal from './UpdateTaxBillModal';

export default function UpdateTaxBillForm({
  reqTaxBillCheck,
  businessNoIsNotDuplicate,
  setBusinessNoIsNotDuplicate,
  companyNameInput,
  addressInput,
  addressDetailInput,
  zipCodeInput,
  companyPhoneInput,
  companyFaxInput,
  companyEmailInput,
  businessNoInput,
  representativeNameInput,
  typeOfBusinessInput,
  itemOfBusinessInput,
  businessAreaInput,
  etcInput,
  ogKey,
  setOgKey,
}) {
  const [companyUpdateModal, setCompanyUpdateModal] = useState(false);
  const [companySearchModal, setCompanySearchModal] = useState(false);

  const backendRequest = useBackendV2();

  const fetchCompanyInfo = async () => {
    const searchRadioValue = 'Ogkey';
    const searchKeyword = ogKey;

    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.companies.search}/${searchRadioValue}/${searchKeyword}`
      );

      companyNameInput.setValue(data.companyName);
      addressInput.setValue(data.address);
      addressDetailInput.setValue(data.address2);
      zipCodeInput.setValue(data.zipcode);
      representativeNameInput.setValue(data.ceoName);
      companyPhoneInput.setValue(data.tel);
      companyFaxInput.setValue(data.fax);
      companyEmailInput.setValue(data.email);
      businessNoInput.setValue(data.businessNumber);
      typeOfBusinessInput.setValue(data.businessType);
      itemOfBusinessInput.setValue(data.businessCategory);
      businessAreaInput.setValue(data.businessAreas);
    } catch (error) {
      console.error(error);
      alert('입력하신 사업자 등록 번호 또는 회사 이름을 확인해주세요.');
    }
  };

  const openUpdateModalHandler = () => {
    setCompanyUpdateModal(true);
  };
  const closeUpdateModalHandler = () => {
    setBusinessNoIsNotDuplicate(false);
    setCompanyUpdateModal(false);
  };
  const confirmUpdateModalHandler = () => {
    setCompanyUpdateModal(false);
  };

  const openSearchModalHandler = () => {
    setCompanySearchModal(true);
  };
  const closeSearchModalHandler = () => {
    setCompanySearchModal(false);
  };

  useEffect(() => {
    if (ogKey !== 0) {
      fetchCompanyInfo();
    }
  }, [ogKey]);

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          border: '2px solid #D8D8D8',
          alignItems: 'center',
          mb: 1,
        }}
      >
        <ButtonGroup sx={{ mt: 2, mb: 2, width: '95%', height: '40px' }} size="small">
          <Button type="button" sx={{ width: '50%' }} disabled={ogKey !== 0} onClick={openSearchModalHandler}>
            회사 조회
          </Button>
          <Button type="button" sx={{ width: '50%' }} onClick={openUpdateModalHandler}>
            {ogKey !== 0 ? '회사 수정' : '회사 등록'}
          </Button>
        </ButtonGroup>
        {(companyNameInput.value !== '') & (businessNoInput.value !== '') &&
          companyPhoneInput.value !== '' ? (
          <>
            <TextField
              label="회사 이름"
              variant="outlined"
              sx={{ backgroundColor: 'white', width: '95%' }}
              value={companyNameInput.value ?? ''}
              disabled
            />
            <TextField
              label="사업자 등록 번호"
              variant="outlined"
              sx={{ mt: 1, backgroundColor: 'white', width: '95%' }}
              value={businessNoInput.value ?? ''}
              disabled
            />
            <TextField
              label="대표전화"
              variant="outlined"
              sx={{ mt: 1, mb: 2, backgroundColor: 'white', width: '95%' }}
              value={companyPhoneInput.value ?? ''}
              disabled
            />
          </>
        ) : null}
      </Paper>
      <RequestTaxBillSearchModal
        companyNameInput={companyNameInput}
        businessNoInput={businessNoInput}
        companyPhoneInput={companyPhoneInput}
        setCompanyKey={setOgKey}
        open={companySearchModal}
        onClose={closeSearchModalHandler}
      />
      <UpdateTaxBillModal
        businessNoIsNotDuplicate={businessNoIsNotDuplicate}
        setBusinessNoIsNotDuplicate={setBusinessNoIsNotDuplicate}
        companyNameInput={companyNameInput}
        addressInput={addressInput}
        addressDetailInput={addressDetailInput}
        zipCodeInput={zipCodeInput}
        companyPhoneInput={companyPhoneInput}
        companyFaxInput={companyFaxInput}
        companyEmailInput={companyEmailInput}
        businessNoInput={businessNoInput}
        representativeNameInput={representativeNameInput}
        typeOfBusinessInput={typeOfBusinessInput}
        itemOfBusinessInput={itemOfBusinessInput}
        businessAreaInput={businessAreaInput}
        etcInput={etcInput}
        setCompanyKey={setOgKey}
        companyKey={ogKey}
        open={companyUpdateModal}
        onClose={closeUpdateModalHandler}
        confirm={confirmUpdateModalHandler}
      />
    </>
  );
}
