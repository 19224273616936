import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';

export default function FindIdForm({ nameInput, phoneInput, setResultInfo, cancelJoin, isMobile }) {
  const formIsValid = nameInput.isValid && phoneInput.isValid;

  const backendRequest = useBackendV2();

  const findUserIdHandler = async () => {
    if (!formIsValid) {
      return;
    }

    try {
      const result = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.fineId}/${nameInput.value}/${phoneInput.value}`,
        null,
        false
      );

      if (result.data.length === 0) {
        alert('이름 혹은 휴대전화번호를 다시 확인해주세요.');
      } else {
        setResultInfo(result.data);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발행하였습니다.');
    }
  };

  return (
    <>
      <Box sx={{ width: { xs: '100%', md: 500 } }}>
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Box sx={{ mt: 1 }}>
            <Typography variant="subtitle1" sx={{ fontSize: isMobile ? 14 : 16 }}>
              이름, 휴대전화 번호를 입력 후 다음 버튼을 눌러주세요.
            </Typography>
          </Box>
        </Box>
        <Paper
          variant="outlined"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            border: '2px solid #D8D8D8',
            alignItems: 'center',
            mt: 1.5,
            mb: 1,
          }}
        >
          <TextField
            label="이름"
            variant="outlined"
            required
            sx={{ mt: 2, backgroundColor: 'white', width: '95%' }}
            onChange={nameInput.valueChangeHandler}
            onBlur={nameInput.inputBlurHandler}
            value={nameInput.value}
            error={nameInput.hasError}
            helperText={nameInput.hasError && '최소 2자. 최대 20자. 한글, 영문만 가능'}
          />
          <TextField
            label="휴대전화번호"
            variant="outlined"
            required
            sx={{ mt: 1, backgroundColor: 'white', width: '95%', mb: 2 }}
            onChange={phoneInput.valueChangeHandler}
            onBlur={phoneInput.inputBlurHandler}
            value={phoneInput.value.replace(/\s/g, '').trim()}
            error={phoneInput.hasError}
            helperText={phoneInput.hasError && '핸드폰 번호 형식이 올바르지 않습니다.'}
          />
        </Paper>
        <Box sx={{ mt: 2.5, mb: 2, width: '100%' }}>
          <Button
            type="button"
            variant="contained"
            disabled={!formIsValid}
            onClick={findUserIdHandler}
            size="large"
            sx={{ width: '100%' }}
          >
            다음
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={cancelJoin}
            size="large"
            sx={{ width: '100%', mt: 1 }}
          >
            돌아가기
          </Button>
        </Box>
      </Box>
    </>
  );
}
