import MainCard from '../../theme/MainCard';
import { Box, Avatar, Typography } from '@mui/material';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';

// 현황판 설비용량 컴포넌트
export default function PlantImageCard_02({ capacity, isIpad, isNexthub }) {
  return (
    <>
      <MainCard
        sx={{
          bgcolor: '#0059B2',
          borderRadius: '15px',
          boxShadow: '0px 3px 100px #00000014',
          height: 95,
        }}
      >
        <Box display="flex" alignItems="center">
          <Avatar
            variant="rounded"
            sx={{
              bgcolor: '#E2E6FB',
              mr: 1,
              width: 30,
              height: 30,
            }}
          >
            <FormatListBulletedIcon sx={{ color: '#0059B2' }} />
          </Avatar>
          <Typography variant="h5" sx={{ color: '#fff' }}>
            설비 용량
          </Typography>
        </Box>
        <Box display="flex" justifyContent="end" alignItems="center">
          <Typography variant={isIpad || isNexthub ? 'h5' : 'h4'} sx={{ color: '#fff' }}>
            {capacity ? capacity : 'N/A'}
          </Typography>
          <Typography variant="h6" sx={{ color: '#fff', ml: 0.5 }}>
            kW
          </Typography>
        </Box>
      </MainCard>
    </>
  );
}
