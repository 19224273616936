import { Box, Button, Typography } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import SystemUpdateOutlinedIcon from '@mui/icons-material/SystemUpdateOutlined';
import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import AuthContext from '../../store/auth-context';

export default function NewAppVersionGuideModal({ open, setNewAppDown }) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const { isAndroid, isiOS } = authCtx;

  const androidFunc = () => {
    window.QWebApp.updateFunction();
  };
  const iOSFunc = () => {
    window.webkit.messageHandlers.updateFunction.postMessage('');
  };

  const appUpdateClickHandler = () => {
    if (isAndroid) {
      androidFunc();
    } else if (isiOS) {
      iOSFunc();
    }

    setNewAppDown(false);
  };

  return (
    <CustomModal open={open} sx={{ width: { xs: '95%', md: 700 }, maxHeight: '95%' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
        <SystemUpdateOutlinedIcon />
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>버전 업데이트 안내</Typography>
      </Box>
      <hr />
      <Typography display="flex" justifyContent="center" alignItems="center" variant="h6">
        새로운 버전이 출시되었습니다.
      </Typography>
      <Typography display="flex" justifyContent="center" alignItems="center" variant="h6">
        Store로 이동하여 업데이트 해주세요.
      </Typography>
      <br />
      <Box display="flex" justifyContent="center" alignItems="center">
        <Button variant="contained" onClick={appUpdateClickHandler} sx={{ width: '50%' }}>
          앱 업데이트
        </Button>
      </Box>
    </CustomModal>
  );
}
