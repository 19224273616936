import { Box, Button, Checkbox, Typography } from '@mui/material';
import { useState } from 'react';

// 이용약관, 개인정보처리방침 등 동의 컴포넌트
export default function CheckTerms({
  allAgreed,
  setAllAgreed,
  agreed1,
  setAgreed1,
  agreed2,
  setAgreed2,
  handleAgreeCheckboxChange,
  openServiceUseClickHandler,
  openCollectUserInfoClickHandler,
}) {
  const [allShowTerms, setAllShowTerms] = useState(false);
  const [showTerms1, setShowTerms1] = useState(false);
  const [showTerms2, setShowTerms2] = useState(false);

  const handleAllAgreeCheckboxChange = (event) => {
    const checked = event.target.checked;
    setAllAgreed(checked);
    setAgreed1(checked);
    setAgreed2(checked);
  };

  const handleViewAllTerms = () => {
    setAllShowTerms(!allShowTerms);
  };
  const handleViewTerms1 = () => {
    setShowTerms1(!showTerms1);
  };
  const handleViewTerms2 = () => {
    setShowTerms2(!showTerms2);
  };

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Checkbox checked={allAgreed} onChange={handleAllAgreeCheckboxChange} />
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>전체 동의</Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Checkbox checked={agreed1} onChange={(e) => handleAgreeCheckboxChange(e, 1)} />
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
          <span style={{ color: 'blue' }}>[필수] </span>서비스 이용약관 동의
        </Typography>
        <Button variant="text" size="small" onClick={openServiceUseClickHandler} sx={{ ml: 0 }}>
          [약관 보기]
        </Button>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
        <Checkbox checked={agreed2} onChange={(e) => handleAgreeCheckboxChange(e, 2)} />
        <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
          <span style={{ color: 'gray' }}>[선택] </span>개인정보 처리방침 동의
        </Typography>
        <Button variant="text" size="small" onClick={openCollectUserInfoClickHandler} sx={{ ml: 0 }}>
          [약관 보기]
        </Button>
      </Box>
    </Box>
  );
}
