import { Box, Button, InputAdornment, TextField, Typography } from '@mui/material';
import useInput from '../../../hooks/use-input';
import { validateNumberOnly, validateSubPlantName } from '../../../utils/validationUtil';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';

export default function AddNewSubPlant({ plantkey, setNewSubPlantAdd, newSubPlantAdd }) {
  const subPlantNameInput = useInput((value) => validateSubPlantName(value));
  const subPlantDescInput = useInput();
  const subPlantPanelTypeInput = useInput();
  const subPlantPtcoInput = useInput();
  const subPlantOrientationInput = useInput();
  const subPlantTiltInput = useInput();
  const subPlantAreaInput = useInput((value) => validateNumberOnly(value));

  const formIsValid = subPlantNameInput.isValid;

  const backendRequest = useBackendV2();

  const AddNewSubPlantClickHandler = async () => {
    try {
      const requestConfig = {
        method: 'POST',
        body: {
          plantkey: plantkey,
          spname: subPlantNameInput.value,
          sdesc: subPlantDescInput.value,
          paneltype: subPlantPanelTypeInput.value,
          ptco: subPlantPtcoInput.value,
          orientation: subPlantOrientationInput.value,
          tilt: subPlantTiltInput.value,
          area: subPlantAreaInput.value === '' ? null : subPlantAreaInput.value,
        },
      };
      const result = await backendRequest.sendRequest(
        config.backend.endPoint.plants.addNewSubPlant,
        requestConfig
      );

      if (result.data !== (null || 0)) {
        alert('그룹 발전소 등록이 완료되었습니다.');
        setNewSubPlantAdd(false);
      } else {
        alert('오류가 발생하였습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1, ml: 1 }}>
          <Typography variant="subtitle1">-기본정보</Typography>
          <Typography variant="subtitle2">
            <span style={{ color: 'red' }}>*</span> 는 필수입력 사항입니다.
          </Typography>
        </Box>
        <TextField
          label="서브발전소 명"
          variant="outlined"
          required
          sx={{ width: '100%', mt: 0.5 }}
          onChange={subPlantNameInput.valueChangeHandler}
          onBlur={subPlantNameInput.inputBlurHandler}
          value={subPlantNameInput.value}
          error={subPlantNameInput.hasError}
          helperText={subPlantNameInput.hasError && '최소 2자, 최대 25자.  ,(쉼표) 불가능'}
        />
        <TextField
          label="상세설명"
          variant="outlined"
          multiline
          rows={4}
          sx={{ width: '100%', mt: 1.5 }}
          value={subPlantDescInput.value}
          onChange={subPlantDescInput.valueChangeHandler}
        />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1, ml: 1 }}>
          <Typography variant="subtitle1">-발전기/판넬정보</Typography>
        </Box>
        <TextField
          label="판넬 타입"
          variant="outlined"
          sx={{ width: '100%', mt: 0.5 }}
          value={subPlantPanelTypeInput.value}
          onChange={subPlantPanelTypeInput.valueChangeHandler}
        />
        <TextField
          label="온도 계수"
          variant="outlined"
          sx={{ width: '100%', mt: 1.5 }}
          value={subPlantPtcoInput.value}
          onChange={subPlantPtcoInput.valueChangeHandler}
        />
        <TextField
          label="방위"
          variant="outlined"
          sx={{ width: '100%', mt: 1.5 }}
          value={subPlantOrientationInput.value}
          onChange={subPlantOrientationInput.valueChangeHandler}
          InputProps={{ endAdornment: <InputAdornment position="end">°</InputAdornment> }}
        />
        <TextField
          label="경사"
          variant="outlined"
          sx={{ width: '100%', mt: 1.5 }}
          value={subPlantTiltInput.value}
          onChange={subPlantTiltInput.valueChangeHandler}
          InputProps={{ endAdornment: <InputAdornment position="end">°</InputAdornment> }}
        />
        <TextField
          label="면적"
          variant="outlined"
          sx={{ width: '100%', mt: 1.5 }}
          value={subPlantAreaInput.value}
          onChange={subPlantAreaInput.valueChangeHandler}
          InputProps={{ endAdornment: <InputAdornment position="end">m²</InputAdornment> }}
        />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 2.5 }}>
        <Button
          variant="contained"
          onClick={AddNewSubPlantClickHandler}
          disabled={!formIsValid}
          sx={{ mr: 1, width: '49%' }}
        >
          등록
        </Button>
        <Button variant="outlined" onClick={() => setNewSubPlantAdd(false)} sx={{ width: '49%' }}>
          취소
        </Button>
      </Box>
    </>
  );
}
