import { useEffect, useState } from 'react';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import { convertFormatStringToYMD } from '../../../utils/dateUtil';
import MonthAllStatistics from './MonthAllStatistics';
import MonthGroupStatistics from './MonthGroupStatistics';
import MonthInverterStatistics from './MonthInverterStatistics';

export default function Month({
  page,
  subValue,
  tabValue,
  dateValue,
  searchState,
  setSearchState,
  chartView,
}) {
  const [monthlyDevelopment, setMonthlyDevelopment] = useState([]);

  const monthlyDevelopmentRequest = useBackendV2();

  const fetchMonthlyDevelopmentData = async () => {
    try {
      let tab;
      if (tabValue === 'all') {
        tab = 0;
      } else if (tabValue === 'group') {
        tab = 1;
      } else if (tabValue === 'inverter') {
        tab = 2;
      }

      const queryStringArr = [
        `yieldGroupType=${tab}`,
        `yieldtype=${subValue}`,
        `dateTime=${convertFormatStringToYMD(dateValue)}`,
      ];
      const queryString = queryStringArr.join('&');

      const { data } = await monthlyDevelopmentRequest.sendRequest(
        `${config.backend.endPoint.statistics.plantYield}/${page.plantkey}?${queryString}`
      );

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const existingMonths = data.map((item) => item.flocaldate.split('-')[1]);
      const newData = [];
      for (let month = 1; month <= 12; month++) {
        const paddedMonth = String(month).padStart(2, '0');
        const formattedDate = `${currentYear}-${paddedMonth}`;
        if (!existingMonths.includes(paddedMonth)) {
          newData.push({
            groupname: data[0].groupname,
            flocaldate: formattedDate,
            totalyield: null,
            generateHour: data[0].generateHour,
          });
        }
      }

      const mergedData = [...data, ...newData];

      mergedData.sort((a, b) => {
        const dateA = new Date(a.flocaldate);
        const dateB = new Date(b.flocaldate);
        return dateA - dateB;
      });

      setMonthlyDevelopment(mergedData.map((item, index) => ({ ...item, id: index })));
      setSearchState(false);
    } catch (error) {
      setMonthlyDevelopment([]);
      setSearchState(false);
    }
  };

  useEffect(() => {
    if (searchState && page.plantkey !== '') {
      fetchMonthlyDevelopmentData();
    }
  }, [searchState, page]);

  let componentToShow;

  switch (tabValue) {
    case 'all':
      componentToShow = <MonthAllStatistics data={monthlyDevelopment} chartView={chartView} />;
      break;
    case 'group':
      componentToShow = <MonthGroupStatistics data={monthlyDevelopment} chartView={chartView} />;
      break;
    case 'inverter':
      componentToShow = <MonthInverterStatistics data={monthlyDevelopment} chartView={chartView} />;
      break;
    default:
      componentToShow = null;
      break;
  }

  return <>{componentToShow}</>;
}
