import { Box, Card, Typography, styled } from '@mui/material';
import MainCard from '../../../theme/MainCard';

export default function EachPlantTodayWeatherForecast({ todayWeather }) {
  const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
  }));

  return (
    <>
      <CardWrapper sx={{ height: 218 }}>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          오늘의 날씨
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Box display="flex">
            <Card
              sx={{
                border: '3px solid #FFF',
                width: '50%',
                borderTop: 'none',
                borderBottom: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderRadius: '0',
                background: 'rgba(0, 0, 0, 0.005)',
                bgcolor: '#F6F6F6',
              }}
            >
              <Typography
                sx={{ fontSize: 14, ml: 1 }}
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                최고
              </Typography>
              <Box display="flex" sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 20, mr: 1 }} variant="subtitle2" color="error" gutterBottom>
                  {todayWeather !== '' ? todayWeather.v_heig : null}
                </Typography>
                <Typography sx={{ fontSize: 16, mr: 2 }} variant="subtitle2" gutterBottom>
                  ℃
                </Typography>
              </Box>
            </Card>
            <Card
              sx={{
                border: '3px solid #FFF',
                width: '50%',
                borderTop: 'none',
                borderBottom: 'none',
                borderRight: 'none',
                borderRadius: '0',
                background: 'rgba(0, 0, 0, 0.005)',
                bgcolor: '#F6F6F6',
              }}
            >
              <Typography
                sx={{ fontSize: 14, ml: 1 }}
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                최저
              </Typography>
              <Box display="flex" sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 20, mr: 1 }} variant="subtitle2" color="error" gutterBottom>
                  {todayWeather !== '' ? todayWeather.v_low : null}
                </Typography>
                <Typography sx={{ fontSize: 16, mr: 2 }} variant="subtitle2" gutterBottom>
                  ℃
                </Typography>
              </Box>
            </Card>
          </Box>
          <Box display="flex">
            <Card
              sx={{
                border: '3px solid #FFF',
                width: '50%',
                borderBottom: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderRadius: '0',
                background: 'rgba(0, 0, 0, 0.005)',
                bgcolor: '#F6F6F6',
              }}
            >
              <Typography
                sx={{ fontSize: 14, ml: 1, mt: 1 }}
                color="text.secondary"
                variant="subtitle2"
                gutterBottom
              >
                습도
              </Typography>
              <Box display="flex" sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 20, mr: 1 }} variant="subtitle2" color="error" gutterBottom>
                  {todayWeather !== '' ? todayWeather.v_humidity : null}
                </Typography>
                <Typography sx={{ fontSize: 16, mr: 2 }} variant="subtitle2" gutterBottom>
                  %
                </Typography>
              </Box>
            </Card>
            <Card
              sx={{
                border: '3px solid #FFF',
                width: '50%',
                borderBottom: 'none',
                borderRight: 'none',
                borderRadius: '0',
                background: 'rgba(0, 0, 0, 0.005)',
                bgcolor: '#F6F6F6',
              }}
            >
              <Typography
                sx={{ fontSize: 14, ml: 1, mt: 1 }}
                color="text.secondary"
                variant="subtitle2"
                gutterBottom
              >
                바람
              </Typography>
              <Box display="flex" sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 20, mr: 1 }} variant="subtitle2" color="error" gutterBottom>
                  {todayWeather !== '' ? todayWeather.v_wind : null}
                </Typography>
                <Typography sx={{ fontSize: 16, mr: 2 }} variant="subtitle2" gutterBottom>
                  ㎧
                </Typography>
              </Box>
            </Card>
          </Box>
        </Box>
      </CardWrapper>
    </>
  );
}
