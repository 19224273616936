import { Box, Card, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { convertFormatStringToYMD } from '../../utils/dateUtil';
import config from '../../config';
import useBackendV2 from '../../hooks/use-backend-v2';
import useInterval from '../../hooks/use-interval';

// 현황판 인버터 일일 발전량 차트 컴포넌트
export default function PlantInverterChartCard({ invDailyDevelopment, page }) {
  const dailyDevelopmentState = {
    yieldGroupType: 0,
    yieldtype: 0,
    dateTime: convertFormatStringToYMD(Date.now()),
  };

  const [dailyDevelopment, setDailyDevelopment] = useState([]);

  const backendRequest = useBackendV2();

  const fetchDailyDevelopmentData = async () => {
    try {
      if (page.plantkey !== '') {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.statistics.plantYield}/${page.plantkey}?${new URLSearchParams(
            dailyDevelopmentState
          )}`
        );

        const newData = data.map((item) => ({
          ...item,
          radiation: item.radiation === -999 ? null : item.radiation,
        }));

        const missingData = [];
        for (let i = 5; i <= 20; i++) {
          const existingDataIndex = newData.findIndex((item) => item.year_month_day_hour === i);
          if (existingDataIndex === -1) {
            missingData.push({
              groupname: newData[0].groupname,
              fdate: newData[0].fdate,
              year_month_day_hour: i,
              yield: null,
              totalyield: null,
              fac: null,
              generateHour: null,
              iac: null,
              idc: null,
              pac: null,
              pdc: null,
              radiation: null,
              tco2: null,
              uac: null,
              udc: null,
            });
          }
        }

        const mergedData = [...newData, ...missingData];

        mergedData.sort((a, b) => a.year_month_day_hour - b.year_month_day_hour);

        setDailyDevelopment(mergedData);
      }
    } catch (error) {
      setDailyDevelopment([]);
      // console.error(error);
      // alert('오류가 발생하였습니다.');
    }
  };

  const fetchPollingInterval = 60000;

  useInterval(() => {
    fetchDailyDevelopmentData();
  }, fetchPollingInterval);

  useEffect(() => {
    fetchDailyDevelopmentData();
  }, [page]);

  const formatXAxis = (tickItem) => {
    return `${tickItem}시`;
  };

  const hasRadiationData = dailyDevelopment.some((item) => item.radiation !== null);

  return (
    <>
      <Card sx={{ borderRadius: '15px', boxShadow: '0px 3px 100px #00000014', height: 400 }}>
        <Box sx={{ p: 1 }}>
          <Typography variant="subtitle1">인버터 일일 발전량</Typography>
        </Box>
        <Card
          sx={{
            // border: '1px solid #CECECE',
            borderRight: 'none',
            borderLeft: 'none',
            borderBottom: 'none',
            width: '100%',
            height: '100%',
            borderRadius: 0,
          }}
        >
          <ResponsiveContainer width="100%" height={400}>
            <ComposedChart
              width="100%"
              height="100%"
              data={dailyDevelopment}
              margin={{ top: 60, bottom: 10 }}
            >
              <CartesianGrid stroke="#d1d1d1" vertical={false} />
              <XAxis
                dataKey="year_month_day_hour"
                axisLine={false}
                tickFormatter={formatXAxis}
                tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
              />
              <YAxis
                yAxisId="left"
                axisLine={false}
                tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
                label={{
                  value: 'kWh',
                  offset: 30,
                  angle: 0,
                  position: 'top',
                  fontSize: 14,
                  fontWeight: 'bold',
                  fill: '#000',
                }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                axisLine={false}
                tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
                label={{
                  value: 'W/㎡',
                  offset: 30,
                  angle: 0,
                  position: 'top',
                  fontSize: 14,
                  fontWeight: 'bold',
                  fill: '#000',
                }}
              />
              <Tooltip
                labelFormatter={formatXAxis}
                formatter={(value, name, props) => {
                  if (name === '발전량') {
                    return [
                      <Typography key="tooltip-yield" variant="body1" fontWeight="bold">
                        {`${name} : ${value} kWh`}
                      </Typography>,
                      <Typography key="tooltip-yield-name" variant="body1" fontWeight="bold">
                        발전량
                      </Typography>,
                    ];
                  } else if (name === '경사 일사량') {
                    return [
                      <Typography key="tooltip-radiation" variant="body1" fontWeight="bold">
                        {`${name} : ${value} W/㎡`}
                      </Typography>,
                      <Typography key="tooltip-radiation-name" variant="body1" fontWeight="bold">
                        경사 일사량
                      </Typography>,
                    ];
                  }
                  return [value, name];
                }}
              />
              <Legend
                wrapperStyle={{
                  overflowX: 'auto',
                  width: '100%',
                  whiteSpace: 'nowrap',
                }}
              />
              <Bar
                yAxisId="left"
                name="발전량"
                dataKey="yield"
                fill="#2065D1"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />
              {hasRadiationData && (
                <Line
                  yAxisId="right"
                  name="경사 일사량"
                  type="monotone"
                  dataKey="radiation"
                  stroke="#ff3300"
                  strokeWidth={3}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </Card>
      </Card>
    </>
  );
}
