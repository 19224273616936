import { Box, Button, Card, Divider, MenuItem, Select, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export default function WebAlarmSearchBar({
  setSearchState,
  plantState,
  fromDateValue,
  setFromDateValue,
  toDateValue,
  setToDateValue,
  selectFromDateChangeHandler,
  selectToDateChangeHandler,
  selectPlantInvterChangeHandler,

  combinePlantInv,
}) {
  const today = dayjs();

  const handleSearchStateClick = () => {
    setSearchState(true);
  };

  return (
    <>
      <Card
        sx={{
          p: 1.5,
          borderRadius: '12px',
          boxShadow: '0px 3px 12px #00000014',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Select
              variant="standard"
              value={plantState.selectInverter}
              onChange={selectPlantInvterChangeHandler}
              sx={{ mr: 2, ml: 1 }}
            >
              <MenuItem value={'all'}>전체 인버터</MenuItem>
              {combinePlantInv?.map((item, index) => (
                <MenuItem key={index} value={item.serialnum}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
            <Divider orientation="vertical" flexItem sx={{ border: '1px solid #c0c0c0' }} />
            <DatePicker
              value={dayjs(fromDateValue)}
              onChange={(newValue) => {
                setFromDateValue(newValue.$d);
                selectFromDateChangeHandler(newValue.$d);
                setSearchState(true);
              }}
              openTo="day"
              views={['year', 'month', 'day']}
              format="YYYY-MM-DD"
              slotProps={{
                textField: { size: 'small', variant: 'standard', sx: { ml: 1.5, width: 125 } },
              }}
              maxDate={dayjs(today.toDate())}
            />
            <Typography sx={{ ml: 1.5, fontWeight: 'bold' }}>~</Typography>
            <DatePicker
              value={dayjs(toDateValue)}
              onChange={(newValue) => {
                setToDateValue(newValue.$d);
                selectToDateChangeHandler(newValue.$d);
                setSearchState(true);
              }}
              openTo="day"
              views={['year', 'month', 'day']}
              format="YYYY-MM-DD"
              slotProps={{
                textField: { size: 'small', variant: 'standard', sx: { ml: 1.5, width: 125 } },
              }}
              maxDate={dayjs(today.toDate())}
              minDate={dayjs(fromDateValue)}
            />
          </Box>
          <Button variant="contained" onClick={handleSearchStateClick} sx={{ mr: 2 }}>
            조회
          </Button>
        </Box>
      </Card>
    </>
  );
}
