import {
  Breadcrumbs,
  Box,
  Button,
  Card,
  Container,
  Grid,
  TextField,
  Typography,
  MenuItem,
  useMediaQuery,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SensorOccupiedOutlinedIcon from '@mui/icons-material/SensorOccupiedOutlined';
import { StyledBreadcrumb } from '../../../utils/cssStyles';
import { useEffect, useState, useContext } from 'react';
import useInput from '../../../hooks/use-input';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import useResponsive from '../../../hooks/useResponsive';
import { validateName, validatePhone } from '../../../utils/validationUtil';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../store/auth-context';
import Header02 from '../../Layout/Header02';
import SubHeader from '../../Layout/SubHeader';
import Nav from '../../Layout/Nav';

// 발전소 연계 요청 컴포넌트
export default function RequestConnectionPlant() {
  const authCtx = useContext(AuthContext);
  const loggedId = authCtx?.currentUser?.id;

  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const distance = 0;

  const hpInput = useInput((value) => validatePhone(value));
  const nameInput = useInput((value) => validateName(value));
  const fidInput = useInput((value) => validateName(value));
  const ognameInput = useInput((value) => validateName(value));
  const phoneInput = useInput((value) => validatePhone(value));

  const [checkTargetHp, setCheckTargetHp] = useState(false);
  const [selectPlant, setSelectPlant] = useState('');
  const [selectPlantLists, setSelectPlantLists] = useState([]);
  const [checkedAdmin, setCheckedAdmin] = useState(false);
  const [plantInfo, setPlantInfo] = useState('');
  const [streetInfo, setStreetInfo] = useState('');
  const [isclickCheck, setIsClickCheck] = useState(false);

  const backendRequest = useBackendV2();

  const checkDuplicationHandler = async () => {
    if (hpInput.value === '') {
      alert('전화번호를 입력해주세요.');
      return;
    }

    try {
      const result = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.checkDupl}/${hpInput.value}`
      );

      const isDuplicated = result.data;

      if (isDuplicated === false) {
        setCheckTargetHp(false);
        setIsClickCheck(false);
        alert('대표 전화번호를 확인해주세요.');
      } else {
        setCheckTargetHp(true);
        setIsClickCheck(true);
      }
    } catch (error) {
      console.error(error);
      if (error.message === 'INVALID_USER_PHONE') {
        alert('전화번호 형식을 확인해주세요.');
      } else {
        alert('오류가 발생하였습니다.');
      }
    }
  };

  const checkSearchHandler = async () => {
    if (streetInfo === '') {
      alert('발전소 지역을 입력해주세요.');
      return;
    }
    fetchSelectConnPlant();
  };

  const handleConfirmClick = () => {
    setIsClickCheck(false);
  };

  const fetchUserInfo = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.infoHp}/${hpInput.value}`
      );

      // 이름 마스킹: 가운데 글자(들)을 *로 대체
      const middleIndex = Math.floor(data.uname.length / 2);
      let maskedName = '';

      if (data.uname.length % 2 === 0) {
        // 이름 길이가 짝수인 경우
        maskedName = data.uname.substring(0, middleIndex - 1) + '**' + data.uname.substring(middleIndex + 1);
      } else {
        // 이름 길이가 홀수인 경우
        maskedName = data.uname.substring(0, middleIndex) + '*' + data.uname.substring(middleIndex + 1);
      }

      // 전화번호 마스킹: 가운데 4자리를 *로 대체
      const maskedPhone = data.hp.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2');

      const fid = data.id;
      const ogname = data.ogname;

      fidInput.setValue(fid);
      ognameInput.setValue(ogname);
      nameInput.setValue(maskedName);
      phoneInput.setValue(maskedPhone);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const handleSelectChange = (e) => {
    setSelectPlant(e.target.value);
  };

  const handleCheckChange = (e) => {
    setCheckedAdmin(e.target.checked);
  };

  const handlePlantInfoChange = (e) => {
    setPlantInfo(e.target.value);
  };

  const handleStreetInfoChange = (e) => {
    setStreetInfo(e.target.value);
  };

  const fetchSelectConnPlant = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.findConnPlant}/${hpInput.value}/${streetInfo}`
      );
      setSelectPlantLists(data);
    } catch (error) {
      console.error(error);
      alert('발전소를 불러오지 못하였습니다.');
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    if (checkTargetHp === true) {
      fetchUserInfo();
      setCheckTargetHp(false);
    }
  }, [checkTargetHp]);

  useEffect(() => {
    fetchUserStylePreference();
  }, []);

  const goBackClickHandler = () => {
    navigate(`${config.frontend.connectionPlants}`);
  };

  const connUserRequestAdd = useBackendV2();

  const handleApplyClick = async () => {
    if (!checkedAdmin) {
      if (!isclickCheck) {
        alert('전화번호 확인을 진행해주세요.');
        return;
      }
      if (streetInfo === '') {
        alert('발전소 지역을 조회해주세요.');
        return;
      }
      if (selectPlant === 'any' || selectPlant === null || selectPlant === '') {
        alert('발전소를 선택해주세요.');
        return;
      }
      try {
        const requestConfig = {
          method: 'POST',
          body: {
            id: loggedId,
            plantkey: selectPlant,
            fid: fidInput.value,
            plantinfo: '',
            proctype: '0',
          },
        };
        const { data } = await connUserRequestAdd.sendRequest(
          `${config.backend.endPoint.plants.add}/${fidInput.value}`,
          requestConfig
        );
        alert('등록하였습니다.');
        navigate(`${config.frontend.connectionPlants}`);
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    } else {
      if (plantInfo === '' || plantInfo === null) {
        alert('신청하고자 하는 발전소 정보를 입력해주세요.');
        return;
      }
      try {
        const requestConfig = {
          method: 'POST',
          body: {
            id: loggedId,
            plantkey: 0,
            fid: 'ssadmin',
            plantinfo: plantInfo,
            proctype: '0',
          },
        };
        const { data } = await connUserRequestAdd.sendRequest(
          `${config.backend.endPoint.plants.add}/ssadmin`,
          requestConfig
        );
        alert('등록하였습니다.');
        navigate(`${config.frontend.connectionPlants}`);
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    }
  };

  // 모바일 화면 구성을 위한 상수 선언
  const isGalaxyFold = useMediaQuery('(min-width: 280px) and (max-width: 319px)');
  const isGalaxyS9 = useMediaQuery('(min-width: 320px) and (max-width: 359px)');
  const isGalaxyS8Plus = useMediaQuery('(min-width: 360px) and (max-width: 369px)');
  const isIphoneSe = useMediaQuery('(min-width: 370px) and (max-width: 376px)');
  const isIphone12Pro = useMediaQuery('(min-width: 377px) and (max-width: 390px)');
  const isOtherSmallDevice = useMediaQuery('(min-width: 391px) and (max-width: 408px)');

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      {isDesktop ? (
        <Container
          sx={{
            width: '100%',
            marginBottom: '4%',
          }}
        >
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <StyledBreadcrumb
              label="발전소 연계"
              icon={<SensorOccupiedOutlinedIcon fontSize="small" color="primary" />}
            />
            <StyledBreadcrumb label="신청 작성" />
          </Breadcrumbs>
          <Box>
            <Card
              sx={{
                p: 3,
                mt: 2.5,
                borderRadius: '11px',
                boxShadow: '0px 3px 12px #00000014',
              }}
            >
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box>
                  <Button
                    variant="text"
                    size="large"
                    startIcon={<ArrowBackIcon fontSize="large" />}
                    onClick={goBackClickHandler}
                    sx={{ color: '#000', left: 0 }}
                  >
                    돌아가기
                  </Button>
                </Box>
                <Typography variant="subtitle1">
                  <span style={{ color: 'red' }}>*</span> 는 필수입력 사항입니다.
                </Typography>
              </Box>
              {/* <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', mt: 1.5 }}>
                <Typography variant="subtitle1" sx={{ mr: 3 }}>
                  관리자 연계 신청
                </Typography>
                <Checkbox
                  checked={checkedAdmin}
                  onChange={handleCheckChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Box> */}
              {!checkedAdmin && (
                <>
                  <Box display="flex" alignItems="center" sx={{ mt: 1.5 }}>
                    <Typography variant="subtitle1" sx={{ mr: 6 }}>
                      대표 전화번호*
                    </Typography>
                    <TextField
                      hiddenLabel
                      size="small"
                      variant="outlined"
                      onChange={hpInput.valueChangeHandler}
                      onBlur={hpInput.inputBlurHandler}
                      value={hpInput.value.replace(/\s/g, '').trim()}
                      sx={{ width: '45%' }}
                      placeholder="예: 01012345678 또는 010-1234-5678"
                    />
                    {isclickCheck ? (
                      <Button variant="outlined" onClick={handleConfirmClick} sx={{ ml: 6 }}>
                        확인완료
                      </Button>
                    ) : (
                      <Button variant="outlined" onClick={checkDuplicationHandler} sx={{ ml: 6 }}>
                        확인
                      </Button>
                    )}
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2, ml: 20, width: '45%' }}>
                    {hpInput.value !== '' && phoneInput.value !== '' ? (
                      <Card
                        sx={{
                          p: 3,
                          mt: 2,
                          border: 2,
                          borderColor: '#2065D1',
                          borderRadius: '11px',
                          boxShadow: '0px 3px 12px #00000014',
                        }}
                      >
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                          소속: {ognameInput.value}
                        </Typography>
                        <Typography variant="subtitle1" sx={{ mb: 1 }}>
                          이름: {nameInput.value}
                        </Typography>
                        <Typography variant="subtitle1">전화: {phoneInput.value}</Typography>
                      </Card>
                    ) : null}
                  </Box>
                  <Box display="flex" alignItems="center" sx={{ mt: 3.5 }}>
                    <Typography variant="subtitle1" sx={{ mr: 8 }}>
                      발전소 지역*
                    </Typography>
                    <TextField
                      hiddenLabel
                      size="small"
                      variant="outlined"
                      onChange={handleStreetInfoChange}
                      value={streetInfo}
                      sx={{ width: '45%' }}
                      placeholder="예: 서울특별시 강남구 율현동"
                    />
                    <Button variant="outlined" onClick={checkSearchHandler} sx={{ ml: 6 }}>
                      조회
                    </Button>
                  </Box>
                  <Box display="flex" alignItems="center" sx={{ mt: 3.5 }}>
                    <Typography variant="subtitle1" sx={{ mr: 2 }}>
                      연계 신청 발전소*
                    </Typography>
                    <TextField
                      fullWidth
                      select
                      variant="standard"
                      size="small"
                      sx={{
                        width: '45%',
                        mb: 1,
                        mr: 2,
                        ml: 2,
                      }}
                      value={selectPlant}
                      onChange={handleSelectChange}
                      defaultValue="any"
                    >
                      <MenuItem
                        value="any"
                        sx={{ font: 'normal normal normal 14px/19px Noto Sans', color: '#000000' }}
                      >
                        발전소
                      </MenuItem>
                      {selectPlantLists.map((option) => (
                        <MenuItem
                          key={option.plantkey}
                          value={option.plantkey}
                          sx={{ font: 'normal normal bold 14px/19px Noto Sans', color: '#000000' }}
                        >
                          {option.pname} {' (' + option.street + ') '}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Box>
                </>
              )}
              {checkedAdmin && (
                <Box display="flex" sx={{ mt: 2.5 }}>
                  <Typography variant="subtitle1" sx={{ mr: 3 }}>
                    신청 발전소 정보*
                  </Typography>
                  <TextField
                    hiddenLabel
                    variant="outlined"
                    multiline
                    rows={6}
                    placeholder="발전소명, 발전소 주소, 소유주 전화번호, 안전관리자 전화번호, 신청 내용을 적어주세요."
                    sx={{ width: '65%' }}
                    value={plantInfo}
                    onChange={handlePlantInfoChange}
                  />
                </Box>
              )}

              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                <Button variant="contained" size="large" sx={{ width: '250px' }} onClick={handleApplyClick}>
                  연계 신청
                </Button>
              </Box>
            </Card>
          </Box>
        </Container>
      ) : (
        <Container
          sx={{
            width: '100%',
            marginBottom: '4%',
          }}
        >
          <Breadcrumbs
            separator={<NavigateNextIcon fontSize="small" />}
            sx={{
              '& .MuiBreadcrumbs-ol': {
                flexWrap: isGalaxyFold ? 'nowrap' : 'wrap',
              },
              '& .MuiBreadcrumbs-li': {
                whiteSpace: 'nowrap',
                ...(isGalaxyFold && {
                  '& .MuiBreadcrumbs-separator': {
                    margin: '0 4px',
                  },
                  '& .MuiTypography-root': {
                    fontSize: '12px',
                  },
                }),
              },
            }}
          >
            <StyledBreadcrumb
              label="발전소 연계"
              icon={<SensorOccupiedOutlinedIcon fontSize="small" color="primary" />}
            />
            <StyledBreadcrumb label={isGalaxyFold ? '작성' : '신청 작성'} />
          </Breadcrumbs>
          <Box>
            <Card
              sx={{
                p: isGalaxyFold
                  ? 0.8
                  : isIphoneSe || isGalaxyS8Plus
                  ? 0.9
                  : isGalaxyS9 || isIphone12Pro || isOtherSmallDevice
                  ? 1
                  : 3,
                mt: 2.5,
                borderRadius: '11px',
                boxShadow: '0px 3px 12px #00000014',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  flexDirection:
                    isGalaxyFold || isGalaxyS9 || isGalaxyS8Plus || isIphoneSe ? 'column' : 'row',
                  justifyContent:
                    isGalaxyFold || isGalaxyS9 || isGalaxyS8Plus || isIphoneSe
                      ? 'flex-start'
                      : 'space-between',
                  textAlign:
                    isGalaxyFold || isGalaxyS9 || isGalaxyS8Plus || isIphoneSe ? 'center' : 'inherit',
                  alignItems:
                    isGalaxyFold || isGalaxyS9 || isGalaxyS8Plus || isIphoneSe ? 'flex-start' : 'center',
                }}
              >
                <Box>
                  <Button
                    variant="text"
                    size="large"
                    startIcon={<ArrowBackIcon fontSize="large" />}
                    onClick={goBackClickHandler}
                    sx={{
                      color: '#000',
                      left: 0,
                      mb: isGalaxyFold || isGalaxyS9 || isGalaxyS8Plus || isIphoneSe ? 1 : 0,
                    }}
                  >
                    돌아가기
                  </Button>
                </Box>
                <Typography
                  variant="subtitle1"
                  sx={{
                    mt: isGalaxyFold || isGalaxyS9 || isGalaxyS8Plus || isIphoneSe ? 1 : 0,
                  }}
                >
                  <span style={{ color: 'red' }}>*</span> 는 필수입력 사항입니다.
                </Typography>
              </Box>
              {/* <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'left',
                  alignItems: 'center',
                  mt: isGalaxyFold || isGalaxyS9 || isGalaxyS8Plus || isIphoneSe ? 2.5 : 1.5,
                }}
              >
                <Typography variant="subtitle1" sx={{ mr: 3 }}>
                  관리자 연계 신청
                </Typography>
                <Checkbox
                  checked={checkedAdmin}
                  onChange={handleCheckChange}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              </Box> */}
              {!checkedAdmin && (
                <>
                  <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant="subtitle1">대표 전화번호*</Typography>
                    </Grid>
                    <Grid item xs={isGalaxyFold || isGalaxyS9 ? 12 : 9} sm={9} md={9} lg={9}>
                      <TextField
                        fullWidth
                        hiddenLabel
                        size="small"
                        variant="outlined"
                        onChange={hpInput.valueChangeHandler}
                        onBlur={hpInput.inputBlurHandler}
                        value={hpInput.value.replace(/\s/g, '').trim()}
                        placeholder="예: 01012345678 또는 010-1234-5678"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={isGalaxyFold || isGalaxyS9 ? 12 : 3}
                      sm={3}
                      md={3}
                      lg={3}
                      sx={{
                        justifyContent: isGalaxyFold || isGalaxyS9 ? 'flex-end' : 'initial',
                        display: 'flex',
                      }}
                    >
                      {isclickCheck ? (
                        <Button variant="outlined" onClick={handleConfirmClick}>
                          확인완료
                        </Button>
                      ) : (
                        <Button variant="outlined" onClick={checkDuplicationHandler}>
                          확인
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} direction="column" width="100%">
                    <Grid item xs={0} sm={2} md={3} lg={3}></Grid>
                    {hpInput.value !== '' && phoneInput.value !== '' && (
                      <Grid item xs={12} sm={8} md={6} lg={6}>
                        <Card
                          sx={{
                            p: isGalaxyFold ? 1 : 3,
                            mt: 2.5,
                            border: 2,
                            borderColor: '#2065D1',
                            borderRadius: '11px',
                            boxShadow: '0px 3px 12px #00000014',
                          }}
                        >
                          <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            소속: {ognameInput.value}
                          </Typography>
                          <Typography variant="subtitle1" sx={{ mb: 1 }}>
                            이름: {nameInput.value}
                          </Typography>
                          <Typography variant="subtitle1">전화: {phoneInput.value}</Typography>
                        </Card>
                      </Grid>
                    )}
                    <Grid item xs={0} sm={2} md={3} lg={3}></Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                      <Typography variant="subtitle1">발전소 지역*</Typography>
                    </Grid>
                    <Grid item xs={isGalaxyFold || isGalaxyS9 ? 12 : 9} sm={9} md={9} lg={9}>
                      <TextField
                        fullWidth
                        hiddenLabel
                        size="small"
                        variant="outlined"
                        onChange={handleStreetInfoChange}
                        value={streetInfo}
                        placeholder="예: 서울특별시 강남구 율현동"
                      />
                    </Grid>
                    <Grid
                      item
                      xs={isGalaxyFold || isGalaxyS9 ? 12 : 3}
                      sm={3}
                      md={3}
                      lg={3}
                      sx={{
                        justifyContent: isGalaxyFold || isGalaxyS9 ? 'flex-end' : 'initial',
                        display: 'flex',
                      }}
                    >
                      <Button variant="outlined" onClick={checkSearchHandler}>
                        조회
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid container spacing={2} alignItems="center" sx={{ mt: 2 }}>
                    <Grid
                      item
                      xs={isGalaxyFold || isGalaxyS9 || isGalaxyS8Plus ? 12 : 6}
                      sm={12}
                      md={12}
                      lg={12}
                    >
                      <Typography variant="subtitle1">연계 신청 발전소*</Typography>
                    </Grid>
                    <Grid
                      item
                      xs={isGalaxyFold || isGalaxyS9 || isGalaxyS8Plus ? 12 : 5}
                      sm={9}
                      md={9}
                      lg={9}
                    >
                      <TextField
                        fullWidth
                        select
                        variant="standard"
                        size="small"
                        sx={{
                          mb: 1,
                          mr: isGalaxyFold || isGalaxyS9 || isGalaxyS8Plus ? 0 : 2,
                          ml: isGalaxyFold || isGalaxyS9 || isGalaxyS8Plus ? 0 : 2,
                        }}
                        value={selectPlant}
                        onChange={handleSelectChange}
                        defaultValue="any"
                      >
                        <MenuItem
                          value="any"
                          sx={{ font: 'normal normal normal 14px/19px Noto Sans', color: '#000000' }}
                        >
                          발전소
                        </MenuItem>
                        {selectPlantLists.map((option) => (
                          <MenuItem
                            key={option.plantkey}
                            value={option.plantkey}
                            sx={{ font: 'normal normal bold 14px/19px Noto Sans', color: '#000000' }}
                          >
                            {option.pname} {' (' + option.street + ') '}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </>
              )}
              {checkedAdmin && (
                <Box
                  sx={{
                    mt: 3.5,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                  }}
                >
                  <Typography
                    variant="subtitle1"
                    sx={{
                      mr: 2,
                    }}
                  >
                    신청 발전소 정보*
                  </Typography>
                  <TextField
                    hiddenLabel
                    variant="outlined"
                    multiline
                    rows={6}
                    placeholder="발전소명, 발전소 주소, 소유주 전화번호, "
                    sx={{ mt: 2, width: '100%' }}
                    value={plantInfo}
                    onChange={handlePlantInfoChange}
                  />
                </Box>
              )}
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    width: isGalaxyFold || isGalaxyS9 ? '200px' : '250px',
                    fontSize: isGalaxyFold || isGalaxyS9 ? '0.8rem' : '1rem',
                  }}
                  onClick={handleApplyClick}
                >
                  연계 신청
                </Button>
              </Box>
            </Card>
          </Box>
        </Container>
      )}
    </>
  );
}
