import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import InsightsIcon from '@mui/icons-material/Insights';
import ScheduleOutlinedIcon from '@mui/icons-material/ScheduleOutlined';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import EventRepeatOutlinedIcon from '@mui/icons-material/EventRepeatOutlined';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import MultilineChartIcon from '@mui/icons-material/MultilineChart';
import TodayIcon from '@mui/icons-material/Today';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';

export default function StatisticsMenu({
  setValue,
  value,
  setSubValue,
  subValue,
  statisticsClickHandler,
  statisticsSelect,
  webNavOpen,
  onClose,
}) {
  const navigate = useNavigate();

  const hourClickHandler = () => {
    setValue(2);
    localStorage.setItem('menuValue', 2);
    setSubValue(0);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.statistics, { state: { subValue: 0 } });
    onClose();
  };
  const dayClickHandler = () => {
    setValue(2);
    localStorage.setItem('menuValue', 2);
    setSubValue(1);
    localStorage.setItem('menuSubValue', 1);
    navigate(config.frontend.statistics, { state: { subValue: 1 } });
    onClose();
  };
  const monthClickHandler = () => {
    setValue(2);
    localStorage.setItem('menuValue', 2);
    setSubValue(2);
    localStorage.setItem('menuSubValue', 2);
    navigate(config.frontend.statistics, { state: { subValue: 2 } });
    onClose();
  };
  const yearClickHandler = () => {
    setValue(2);
    localStorage.setItem('menuValue', 2);
    setSubValue(3);
    localStorage.setItem('menuSubValue', 3);
    navigate(config.frontend.statistics, { state: { subValue: 3 } });
    onClose();
  };
  const envDvcClickHandler = () => {
    setValue(2);
    localStorage.setItem('menuValue', 2);
    setSubValue(4);
    localStorage.setItem('menuSubValue', 4);
    navigate(config.frontend.statistics, { state: { subValue: 4 } });
    onClose();
  };
  const comprehensiveAnalysisClickHandler = () => {
    setValue(2);
    localStorage.setItem('menuValue', 2);
    setSubValue(5);
    localStorage.setItem('menuSubValue', 5);
    navigate(config.frontend.statistics, { state: { subValue: 5 } });
    onClose();
  };

  return (
    <List component="nav">
      <ListItemButton onClick={statisticsClickHandler}>
        <ListItemIcon>
          <InsightsIcon />
        </ListItemIcon>
        {webNavOpen ? <ListItemText primary="통계" /> : null}
        {webNavOpen ? statisticsSelect ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>
      <Collapse in={statisticsSelect} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 2 && subValue === 0 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={hourClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <ScheduleOutlinedIcon sx={{ color: value === 2 && subValue === 0 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 2 && subValue === 0 ? '#3A3DFF' : null,
                  fontWeight: value === 2 && subValue === 0 ? 'bold' : null,
                }}
              >
                시간
              </Typography>
            ) : null}
          </ListItemButton>
        </List>
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 2 && subValue === 1 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={dayClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <TodayIcon sx={{ color: value === 2 && subValue === 1 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 2 && subValue === 1 ? '#3A3DFF' : null,
                  fontWeight: value === 2 && subValue === 1 ? 'bold' : null,
                }}
              >
                일간
              </Typography>
            ) : null}
          </ListItemButton>
        </List>
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 2 && subValue === 2 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={monthClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <DateRangeOutlinedIcon sx={{ color: value === 2 && subValue === 2 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 2 && subValue === 2 ? '#3A3DFF' : null,
                  fontWeight: value === 2 && subValue === 2 ? 'bold' : null,
                }}
              >
                월간
              </Typography>
            ) : null}
          </ListItemButton>
        </List>
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 2 && subValue === 3 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={yearClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <EventRepeatOutlinedIcon sx={{ color: value === 2 && subValue === 3 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 2 && subValue === 3 ? '#3A3DFF' : null,
                  fontWeight: value === 2 && subValue === 3 ? 'bold' : null,
                }}
              >
                연간
              </Typography>
            ) : null}
          </ListItemButton>
        </List>
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 2 && subValue === 4 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={envDvcClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <DataUsageIcon sx={{ color: value === 2 && subValue === 4 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 2 && subValue === 4 ? '#3A3DFF' : null,
                  fontWeight: value === 2 && subValue === 4 ? 'bold' : null,
                }}
              >
                환경정보통계
              </Typography>
            ) : null}
          </ListItemButton>
        </List>
        {/* <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 2 && subValue === 5 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={comprehensiveAnalysisClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <MultilineChartIcon sx={{ color: value === 2 && subValue === 5 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 2 && subValue === 5 ? '#3A3DFF' : null,
                  fontWeight: value === 2 && subValue === 5 ? 'bold' : null,
                }}
              >
                종합분석통계
              </Typography>
            ) : null}
          </ListItemButton>
        </List> */}
      </Collapse>
    </List>
  );
}
