import {
  MenuItem,
  TextField,
  Box,
  Card,
  FormControl,
  Button,
  useMediaQuery,
  Divider,
  Tooltip,
} from '@mui/material';
import { useState, useEffect, useContext } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import { useTheme } from '@mui/material/styles';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import IconButton from '@mui/material/IconButton';
import RefreshOutlinedIcon from '@mui/icons-material/RefreshOutlined';
import { styled } from '@mui/material/styles';
import InquiryBoardList from './InquiryBoardList';
import InquiryBoardListMobile from './InquiryBoardListMobile';

const ColorButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#EBFBFF',
  },
}));

/*
통합문의 게시판 최상위 컴포넌트 - 검색 기능 처리
InquiryBoardSearchForm > InquiryBoardList(웹뷰 리스트 페이지),InquiryBoardListMobile(모바일뷰 리스트 페이지)
*/
export default function InquiryBoardSearchForm() {
  // 처리 상태
  const processStatus = [
    { value: '0', label: '접수' },
    { value: '1', label: '처리 중' },
    { value: '2', label: '불가' },
    { value: '3', label: '완료' },
  ];
  // 문의 구분
  const gubun = [
    { value: 'QA01', label: '모니터링 관련 문의' },
    { value: 'QA02', label: '사용자 문의' },
    { value: 'QA03', label: '상품 문의' },
    { value: 'QA04', label: '결제 문의' },
    { value: 'QA05', label: '기타 문의' },
    { value: 'QA06', label: '세금계산서 발행 요청' },
  ];

  // 초기 검색 조건(첫 렌더링시)
  const searchConditonInitialState = {
    requestStatus: 'any',
    gubun: 'any',
  };

  const navigate = useNavigate();

  const [searchRequestStatus, setSearchRequestStatus] = useState(searchConditonInitialState.requestStatus);
  const [searchGubun, setSearchGubun] = useState(searchConditonInitialState.gubun);
  const [searchTitle, setSearchTitle] = useState('');
  const [rows, setRows] = useState([]);
  const [clickCount, setClickCount] = useState(1);
  const [viewMoreBtn, setViewMoreBtn] = useState(true);

  const authCtx = useContext(AuthContext);
  const loggedId = authCtx?.currentUser?.id;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function handleRequestStatusChange(e) {
    setSearchRequestStatus(e.target.value);
  }

  function handleGubunChange(e) {
    setSearchGubun(e.target.value);
  }

  function handleTitleChange(e) {
    setSearchTitle(e.target.value);
  }

  function handleBtnMore() {
    fetchInquiryBoard();
  }
  const inquiryBoardRequest = useBackendV2();

  const fetchInquiryBoard = async () => {
    try {
      const { data } = await inquiryBoardRequest.sendRequest(
        `${config.backend.endPoint.bulletinBoard.inquiryBasic}?processStatusCcd=${searchRequestStatus}&regId=${loggedId}&gubunCcd=${searchGubun}&title=${searchTitle}`
      );
      setClickCount((prevCount) => prevCount + 1);
      let nextData = data.slice(0, 5 * clickCount);
      setRows(nextData);
      if (data.length === nextData.length) {
        setViewMoreBtn(false);
      } else {
        setViewMoreBtn(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다. 인터넷 연결 상태를 확인 후 새로고침 해주세요.');
    }
  };

  useEffect(() => {
    fetchInquiryBoard();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchRequestStatus]);

  useEffect(() => {
    handleSearch();
  }, [searchGubun]);

  function handleSearch() {
    fetchInquiryBoard();
  }

  function handleInit() {
    setSearchRequestStatus(searchConditonInitialState.requestStatus);
    setSearchGubun(searchConditonInitialState.gubun);
    setSearchTitle('');
    setClickCount(1);
  }

  function handleAddMove() {
    navigate(`${config.frontend.inquiryBoardsadd}`);
  }

  // 테블릿 화면을 위한 반응형 상수 선언
  const isTabletSmallest = useMediaQuery('(min-width: 600px) and (max-width: 699px)');
  const isTabletSmaller = useMediaQuery('(min-width: 700px) and (max-width: 799px)');
  const isTabletSmall = useMediaQuery('(min-width: 800px) and (max-width: 899px)');
  const isTabletLarge = useMediaQuery('(min-width: 900px) and (max-width: 999px)');
  const isTabletLarger = useMediaQuery('(min-width: 1000px) and (max-width: 1099px)');
  const isTabletLargest = useMediaQuery('(min-width: 1100px) and (max-width: 1199px)');

  return (
    <>
      {isMobile ? (
        <>
          <Button
            variant="contained"
            sx={{
              mb: 2,
              mt: 2,
              width: '100%',
              height: '52px',
              background: '#0059B2 0% 0% no-repeat padding-box',
              borderRadius: '8px',
              opacity: 1,
            }}
            startIcon={<CreateOutlinedIcon />}
            onClick={handleAddMove}
          >
            글쓰기
          </Button>
          <Box
            component="form"
            sx={{
              mt: 2,
              width: '100%',
              height: '200px',
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              boxShadow: '0px 3px 24px #00000014',
              borderRadius: '16px',
              opacity: 1,
            }}
          >
            <Box>
              <TextField
                fullWidth
                select
                variant="standard"
                size="small"
                sx={{
                  width: '90%',
                  mb: 2,
                  mr: 2,
                  mt: 2,
                  ml: 2,
                }}
                value={searchRequestStatus}
                onChange={handleRequestStatusChange}
              >
                <MenuItem value="any">전체 상태</MenuItem>
                {processStatus.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{ font: 'normal normal normal 14px/19px Noto Sans', color: '#000000' }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box>
              <TextField
                fullWidth
                select
                variant="standard"
                size="small"
                sx={{
                  width: '90%',
                  mb: 2,
                  mr: 2,
                  mt: 2,
                  ml: 2,
                }}
                value={searchGubun}
                onChange={handleGubunChange}
              >
                <MenuItem value="any">전체 문의</MenuItem>
                {gubun.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                    sx={{ font: 'normal normal normal 14px/19px Noto Sans', color: '#000000' }}
                  >
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <TextField
                fullWidth
                label="제목"
                variant="outlined"
                size="small"
                value={searchTitle}
                onChange={handleTitleChange}
                autoComplete="off"
                sx={{
                  width: '90%',
                  mb: 2,
                  mr: 2,
                  mt: 2,
                  ml: 2,
                }}
                InputProps={{
                  endAdornment: (
                    <>
                      <Tooltip title="검색">
                        <IconButton type="button" aria-label="search" onClick={handleSearch}>
                          <SearchOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="새로고침">
                        <IconButton type="button" aria-label="search" onClick={handleInit}>
                          <RefreshOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ),
                }}
              />
            </Box>
          </Box>
        </>
      ) : (
        <Card
          sx={{ p: 1.5, mt: 3, borderRadius: '12px', boxShadow: '0px 3px 12px #00000014', overflowX: 'auto' }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left', minWidth: 800 }}>
            <FormControl sx={{ mr: 2, ml: 1, flexShrink: 0 }}>
              <TextField
                select
                variant="standard"
                size="Normal"
                sx={{
                  font: 'normal normal normal 16px/22px Noto Sans',
                  letterSpacing: '0px',
                  color: '#000000',
                  opacity: '1',
                }}
                value={searchRequestStatus}
                onChange={handleRequestStatusChange}
              >
                <MenuItem value="any">전체 상태</MenuItem>
                {processStatus.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ border: '1px solid #c0c0c0', mr: 1, ml: 1, flexShrink: 0 }}
            />
            <FormControl sx={{ mr: 2, ml: 1, flexShrink: 0 }}>
              <TextField
                select
                variant="standard"
                size="Normal"
                sx={{
                  font: 'normal normal normal 16px/22px Noto Sans',
                  letterSpacing: '0px',
                  color: '#000000',
                  opacity: '1',
                }}
                value={searchGubun}
                onChange={handleGubunChange}
              >
                <MenuItem value="any">전체 문의</MenuItem>
                {gubun.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ border: '1px solid #c0c0c0', mr: 1, ml: 1, flexShrink: 0 }}
            />
            <FormControl sx={{ width: '30%', mr: 2, ml: 1, flexShrink: 0 }}>
              <TextField
                label="제목"
                variant="outlined"
                size="small"
                autoComplete="off"
                value={searchTitle}
                onChange={handleTitleChange}
                InputProps={{
                  endAdornment: (
                    <>
                      <IconButton type="button" aria-label="search" onClick={handleSearch}>
                        <SearchOutlinedIcon />
                      </IconButton>
                    </>
                  ),
                }}
              />
            </FormControl>
            <ColorButton
              variant="contained"
              onClick={handleInit}
              sx={{
                mr: 2,
                ml: 1,
                background: '#E5EEF7 0% 0% no-repeat padding-box',
                color: '#0059B2',
                flexShrink: 0,
              }}
            >
              초기화
            </ColorButton>
            <Button
              variant="contained"
              onClick={handleAddMove}
              sx={{
                mr: 2,
                ml: 1,
                background: '#0059B2 0% 0% no-repeat padding-box',
                color: '#FFFFFF',
                flexShrink: 0,
              }}
            >
              글쓰기
            </Button>
          </Box>
        </Card>
      )}

      {isMobile ? (
        <InquiryBoardListMobile rows={rows} onBtnMore={handleBtnMore} />
      ) : (
        <InquiryBoardList rows={rows} onBtnMore={handleBtnMore} />
      )}
      {viewMoreBtn && (
        <Box display="flex" sx={{ justifyContent: 'center', mt: 5 }}>
          <Button
            variant="contained"
            onClick={handleBtnMore}
            sx={{
              background: '#0059B2 0% 0% no-repeat padding-box',
              color: '#FFFFFF',
            }}
          >
            더보기
          </Button>
        </Box>
      )}
    </>
  );
}
