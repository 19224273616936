import { useTheme } from '@emotion/react';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import SolLogo from '../../assets/EtcImg/sol_logo_login.png';
import LockPersonIcon from '@mui/icons-material/LockPerson';
import LockResetIcon from '@mui/icons-material/LockReset';
import FindPwdForm from '../../components/Authentication/FindPwdForm';
import { useState } from 'react';
import ResetPwdForm from '../../components/Authentication/ResetPwdForm';
import {
  validateId,
  validateName,
  validatePassword,
  validatePhone,
  validateSixDigit,
} from '../../utils/validationUtil';
import useInput from '../../hooks/use-input';
import usePageLogRecord from '../../hooks/usePageLogRecord';

// 비밀번호 재설정 컴포넌트
export default function FindPwd() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [resultInfo, setResultInfo] = useState(false);

  const idInput = useInput((value) => validateId(value));
  const nameInput = useInput((value) => validateName(value));
  const phoneInput = useInput((value) => validatePhone(value));

  const tempNumInput = useInput((value) => validateSixDigit(value));
  const passwordInput = useInput((value) => validatePassword(value));
  const passwordConfirmInput = useInput((value) => passwordInput.value === value);

  const cancelJoin = () => {
    navigate('/login02');
  };

  usePageLogRecord('findPwds');

  return (
    <Container
      sx={{
        minHeight: '600px',
        minWidth: '50%',
        marginBottom: '4%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 4,
        }}
      >
        <img src={SolLogo} alt="SOLARCUBE" style={{ width: 30 }} />
        &nbsp;
        <Typography
          color="inherit"
          sx={{
            display: 'flex',
            fontFamily: '"Segoe UI Symbol"',
            flexGrow: 1,
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          SOLAR CUBE
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ width: { xs: '100%', md: 500 }, mt: isMobile ? 5 : 9 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {!resultInfo ? (
              <LockPersonIcon
                fontSize="large"
                sx={{
                  mr: 1,
                }}
              />
            ) : (
              <LockResetIcon
                fontSize="large"
                sx={{
                  mr: 1,
                }}
              />
            )}
            <Typography variant="h4" fontWeight="bold">
              {!resultInfo ? '비밀번호 재설정' : '비밀번호 재설정'}
            </Typography>
          </Box>
          <hr />
          {!resultInfo ? (
            <FindPwdForm
              idInput={idInput}
              nameInput={nameInput}
              phoneInput={phoneInput}
              setResultInfo={setResultInfo}
              cancelJoin={cancelJoin}
              isMobile={isMobile}
            />
          ) : (
            <ResetPwdForm
              idInput={idInput}
              tempNumInput={tempNumInput}
              passwordInput={passwordInput}
              passwordConfirmInput={passwordConfirmInput}
              cancelJoin={cancelJoin}
              isMobile={isMobile}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
}
