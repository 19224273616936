import useResponsive from '../../../hooks/useResponsive';
import DInverterChart from '../../Charts/Statistics/Day/DInverterChart';
import DayInverterStatisticsTable from './DayInverterStatisticsTable';

export default function DayInverterStatistics({ data, chartView }) {
  const isDesktop = useResponsive('up', 'lg');

  const groupedData = data.reduce((acc, data) => {
    const groupName = data.groupname;

    if (!acc[groupName]) {
      acc[groupName] = [];
    }

    acc[groupName].push(data);
    return acc;
  }, {});

  if (Object.keys(groupedData).length === 0) {
    return null;
  }

  const maxLength = Math.max(...Object.values(groupedData).map((group) => group.length));
  // Object.keys(groupedData).forEach((groupName) => {
  //   const currentLength = groupedData[groupName].length;
  //   if (currentLength < maxLength) {
  //     const additionalItems = Array(maxLength - currentLength).fill(0);
  //     groupedData[groupName] = additionalItems.concat(groupedData[groupName]);
  //   }
  // });

  Object.keys(groupedData).forEach((groupName) => {
    groupedData[groupName].sort((a, b) => a.year_month_day_hour - b.year_month_day_hour);
  });

  const fillMissingHours = (groupedData) => {
    const allHours = Array.from({ length: 31 }, (_, index) => index + 1); // 1부터 31까지의 시간대 배열 생성

    Object.keys(groupedData).forEach((groupName) => {
      const currentGroup = groupedData[groupName];
      const existingHours = currentGroup.map((data) => data.year_month_day_hour); // 현재 그룹의 시간대들을 가져옴

      // 누락된 시간대를 확인하고 데이터를 추가
      allHours.forEach((hour) => {
        if (!existingHours.includes(hour)) {
          // 누락된 시간대에 대한 데이터 추가
          currentGroup.push({
            groupname: groupName,
            fdate: '', // fdate가 빈 값으로 채워져야 할지 여부는 데이터에 따라 다를 수 있다
            year_month_day_hour: hour,
            yield: null, // yield가 누락된 값으로 채워짐을 나타냄
            totalyield: null, // totalyield도 누락된 값으로 채워짐을 나타냄
          });
        }
      });

      // 시간대를 기준으로 데이터 정렬
      currentGroup.sort((a, b) => a.year_month_day_hour - b.year_month_day_hour);
    });

    return groupedData;
  };

  // fillMissingHours 함수를 호출하여 누락된 시간대의 데이터를 추가
  const filledGroupedData = fillMissingHours(groupedData);

  const chartData = Array.from({ length: maxLength }).map((_, index) => {
    const entry = {
      name: filledGroupedData[Object.keys(filledGroupedData)[0]][index].year_month_day_hour,
    };
    Object.keys(filledGroupedData).forEach((groupName) => {
      entry[groupName] = filledGroupedData[groupName][index].yield;
    });

    return entry;
  });

  return (
    <>
      {isDesktop ? (
        <>
          <DInverterChart
            data={data}
            groupedData={filledGroupedData}
            chartData={chartData}
            isDesktop={isDesktop}
          />
          {data.length === 0 ? null : (
            <DayInverterStatisticsTable
              data={data}
              groupedData={filledGroupedData}
              chartData={chartData}
              isDesktop={isDesktop}
            />
          )}
        </>
      ) : chartView ? (
        <DInverterChart
          data={data}
          groupedData={filledGroupedData}
          chartData={chartData}
          isDesktop={isDesktop}
        />
      ) : (
        <DayInverterStatisticsTable
          data={data}
          groupedData={filledGroupedData}
          chartData={chartData}
          isDesktop={isDesktop}
        />
      )}
    </>
  );
}
