import {
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function WebSearchBar({
  tabValue,
  envTabValue,
  subValue,
  dateValue,
  setDateValue,
  plantInverters,
  selectPlantInverters,
  setSelectPlantInverters,
  comprehensiveAnalysis,
  setComprehensiveAnalysis,
  setSearchState,
  setComprehensiveSearchState,
  isDesktop,
  handleTabChange,
  handleEnvTabChange,
}) {
  const today = dayjs();

  const handleSearchStateClick = () => {
    setSearchState(true);

    if (subValue === 5) {
      setComprehensiveSearchState(true);
    }
  };

  const selectHandleChange = (event) => {
    const {
      target: { value },
    } = event;

    setSelectPlantInverters(typeof value === 'string' ? value.split(',') : value);
    setComprehensiveSearchState(true);
  };

  const selectAnalysisHandleChange = (event) => {
    const {
      target: { value },
    } = event;

    if (selectPlantInverters.length === 0) {
      alert('인버터를 선택해주세요.');
    } else {
      // 최대 선택 가능한 항목 개수
      const maxSelection = 4;

      // 현재 선택된 항목의 개수
      const currentSelectionCount = Array.isArray(value) ? value.length : 0;

      if (currentSelectionCount <= maxSelection) {
        setComprehensiveAnalysis(typeof value === 'string' ? value.split(',') : value);

        setComprehensiveSearchState(true);
      } else {
        alert('분석 항목은 최대 4개만 선택할 수 있습니다.');
      }
    }
  };

  return (
    <>
      <Box sx={{ width: '100%' }}>
        <Card
          sx={{
            p: 1.5,
            borderRadius: '11px',
            boxShadow: '0px 3px 12px #00000014',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <FormControl sx={{ ml: 2 }}>
                {subValue === 4 ? (
                  <>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={envTabValue}
                      onChange={handleEnvTabChange}
                    >
                      <FormControlLabel
                        value="2"
                        control={<Radio size="small" />}
                        label={<Typography variant="subtitle2">10분</Typography>}
                      />
                      <FormControlLabel
                        value="1"
                        control={<Radio size="small" />}
                        label={<Typography variant="subtitle2">60분</Typography>}
                        sx={{ ml: 1 }}
                      />
                    </RadioGroup>
                  </>
                ) : subValue === 5 ? null : (
                  <>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                      value={tabValue}
                      onChange={handleTabChange}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio size="small" />}
                        label={<Typography variant="subtitle2">전체</Typography>}
                      />
                      <FormControlLabel
                        value="group"
                        control={<Radio size="small" />}
                        label={<Typography variant="subtitle2">그룹</Typography>}
                        sx={{ ml: 1 }}
                      />
                      <FormControlLabel
                        value="inverter"
                        control={<Radio size="small" />}
                        label={<Typography variant="subtitle2">인버터</Typography>}
                        sx={{ ml: 1 }}
                      />
                    </RadioGroup>
                  </>
                )}
              </FormControl>
              {subValue === 5 ? null : (
                <Divider orientation="vertical" flexItem sx={{ border: '1px solid #c0c0c0' }} />
              )}
              {subValue === 3 ? null : (
                <>
                  <Typography variant="subtitle1" sx={{ ml: subValue === 5 ? 0 : 1 }}>
                    날짜 :
                  </Typography>
                  <DatePicker
                    value={dayjs(dateValue)}
                    onChange={(newValue) => {
                      setDateValue(newValue.$d);
                      setSearchState(true);
                      setComprehensiveSearchState(true);
                    }}
                    openTo={
                      subValue === 0 || subValue === 4
                        ? 'day'
                        : subValue === 1
                        ? 'month'
                        : subValue === 2
                        ? 'year'
                        : null
                    }
                    views={
                      subValue === 0 || subValue === 4
                        ? ['year', 'month', 'day']
                        : subValue === 1
                        ? ['year', 'month']
                        : subValue === 2
                        ? ['year']
                        : null
                    }
                    format={
                      subValue === 0 || subValue === 4 || subValue === 5
                        ? 'YYYY-MM-DD'
                        : subValue === 1
                        ? 'YYYY-MM'
                        : subValue === 2
                        ? 'YYYY'
                        : null
                    }
                    sx={{ width: isDesktop ? '10%' : '140px' }}
                    slotProps={{
                      textField: { size: 'small', variant: 'standard', sx: { ml: 1.5, width: 125 } },
                    }}
                    maxDate={dayjs(today.toDate())}
                  />
                </>
              )}
              {subValue === 5 ? (
                <>
                  <Divider orientation="vertical" flexItem sx={{ border: '1px solid #c0c0c0', ml: 2 }} />
                  <FormControl sx={{ ml: 2, width: 300 }}>
                    <InputLabel size="small">인버터명</InputLabel>
                    <Select
                      multiple
                      value={selectPlantInverters}
                      onChange={selectHandleChange}
                      input={<OutlinedInput label="인버터명" />}
                      renderValue={(selected) =>
                        selected
                          .map((serialnum) => {
                            const selectedInverter = plantInverters.find((d) => d.serialnum === serialnum);
                            return selectedInverter ? selectedInverter.invertername : '';
                          })
                          .join(', ')
                      }
                      MenuProps={MenuProps}
                      size="small"
                    >
                      {plantInverters.map((d, index) => (
                        <MenuItem key={index} value={d.serialnum}>
                          <Checkbox checked={selectPlantInverters.includes(d.serialnum)} />
                          <ListItemText primary={d.invertername} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl sx={{ ml: 2, width: 300 }}>
                    <InputLabel size="small">분석 항목</InputLabel>
                    <Select
                      multiple
                      value={comprehensiveAnalysis}
                      onChange={selectAnalysisHandleChange}
                      input={<OutlinedInput label="분석 항목" />}
                      renderValue={(selected) =>
                        selected
                          .map((value) => {
                            switch (value) {
                              case 'totalyield':
                                return 'Energy';
                              case 'trad':
                                return 'Radiation';
                              case 'pac':
                                return 'AC Power';
                              case 'pdc':
                                return 'DC Power';
                              case 'uac':
                                return 'AC Voltage';
                              case 'udc':
                                return 'DC Voltage';
                              case 'iac':
                                return 'AC Current';
                              case 'idc':
                                return 'DC Current';
                              default:
                                return '';
                            }
                          })
                          .join(', ')
                      }
                      MenuProps={MenuProps}
                      size="small"
                      // disabled={selectPlantInverters.length === 0}
                    >
                      <MenuItem value="totalyield">
                        <Checkbox checked={comprehensiveAnalysis.includes('totalyield')} />
                        <ListItemText primary="Energy" />
                      </MenuItem>
                      <MenuItem value="trad">
                        <Checkbox checked={comprehensiveAnalysis.includes('trad')} />
                        <ListItemText primary="Radiation" />
                      </MenuItem>
                      <MenuItem value="pac">
                        <Checkbox checked={comprehensiveAnalysis.includes('pac')} />
                        <ListItemText primary="AC Power" />
                      </MenuItem>
                      <MenuItem value="pdc">
                        <Checkbox checked={comprehensiveAnalysis.includes('pdc')} />
                        <ListItemText primary="DC Power" />
                      </MenuItem>
                      <MenuItem value="uac">
                        <Checkbox checked={comprehensiveAnalysis.includes('uac')} />
                        <ListItemText primary="AC Voltage" />
                      </MenuItem>
                      <MenuItem value="udc">
                        <Checkbox checked={comprehensiveAnalysis.includes('udc')} />
                        <ListItemText primary="DC Voltage" />
                      </MenuItem>
                      <MenuItem value="iac">
                        <Checkbox checked={comprehensiveAnalysis.includes('iac')} />
                        <ListItemText primary="AC Current" />
                      </MenuItem>
                      <MenuItem value="idc">
                        <Checkbox checked={comprehensiveAnalysis.includes('idc')} />
                        <ListItemText primary="DC Current" />
                      </MenuItem>
                    </Select>
                  </FormControl>
                </>
              ) : null}
            </Box>
            <Button variant="contained" onClick={handleSearchStateClick} sx={{ mr: 2 }}>
              조회
            </Button>
          </Box>
        </Card>
      </Box>
    </>
  );
}
