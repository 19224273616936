import { Avatar, Box, Typography } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import ReportIcon from '@mui/icons-material/Report';

export default function HelpSignalStatusModal({ open, onClose, signalClickedCard, setSignalClickedCard }) {
  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setSignalClickedCard(null);
    onClose();
  };

  return (
    <>
      <CustomModal open={open} onClose={closeModalHandler} sx={{ width: { xs: '95%', md: 650 }, p: 1 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
        </Box>
        <br />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, mt: 1 }}>
          <ReportIcon sx={{ color: 'red' }} />
          {signalClickedCard === 1 ? (
            <>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.09rem' }}>
                RTU 수신상태가 <span style={{ color: 'red' }}>'통신중단'</span>일 경우
              </Typography>
            </>
          ) : signalClickedCard === 2 ? (
            <>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.09rem' }}>
                발전상태가 <span style={{ color: 'red' }}>'발전중단'</span>일 경우
              </Typography>
            </>
          ) : signalClickedCard === 3 ? (
            <>
              <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.09rem' }}>
                RTU 수신 상태가 <span style={{ color: 'red' }}>'통신중단'</span>이고, 발전상태가{' '}
                <span style={{ color: 'red' }}>'발전중단'</span>일 경우
              </Typography>
            </>
          ) : null}
        </Box>
        <hr />
        {signalClickedCard === 1 ? (
          <Box display="flex" justifyContent="center" sx={{ mt: 2, mb: 2 }}>
            <Avatar sx={{ width: 100, height: 100, bgcolor: 'red' }}>통신중단</Avatar>
          </Box>
        ) : signalClickedCard === 2 ? (
          <Box display="flex" justifyContent="center" sx={{ mt: 2, mb: 2 }}>
            <Avatar sx={{ width: 100, height: 100, bgcolor: 'red' }}>발전중단</Avatar>
          </Box>
        ) : signalClickedCard === 3 ? (
          <Box display="flex" justifyContent="space-evenly" sx={{ mt: 2, mb: 2 }}>
            <Avatar sx={{ width: 100, height: 100, bgcolor: 'red' }}>통신중단</Avatar>
            <Avatar sx={{ width: 100, height: 100, bgcolor: 'red' }}>발전중단</Avatar>
          </Box>
        ) : null}
        <hr />
        {signalClickedCard === 1 ? (
          <Box sx={{ p: 1 }}>
            <Typography variant="subtitle1" display="flex" alignContent="center" sx={{ mt: 1, mb: 2 }}>
              <Avatar sx={{ width: 22, height: 22, mr: 1, bgcolor: 'red' }}>
                <Typography variant="subtitle1">1</Typography>
              </Avatar>{' '}
              발전소의 <span style={{ color: 'red' }}>&nbsp;통신사 모뎀(공유기)의 이상유무</span>를 확인해
              주십시오.
            </Typography>
            <hr />
            <Typography variant="subtitle1" display="flex" alignContent="center" sx={{ mt: 2 }}>
              <Avatar sx={{ width: 22, height: 22, mr: 1, bgcolor: 'red' }}>
                <Typography variant="subtitle1">2</Typography>
              </Avatar>{' '}
              발전소의 <span style={{ color: 'red' }}>&nbsp;모니터링 장비의 LAN PORT LAMP</span>를 확인해
              주십시오.
            </Typography>
            <Typography variant="subtitle1" sx={{ ml: 3.6, mb: 2 }}>
              (정상일 경우, LAMP가 점멸 됩니다.)
            </Typography>
            <hr />
            <Typography variant="subtitle1" display="flex" alignContent="center" sx={{ mt: 2 }}>
              <Avatar sx={{ width: 22, height: 22, mr: 1, bgcolor: 'red' }}>
                <Typography variant="subtitle1">3</Typography>
              </Avatar>{' '}
              <span style={{ color: 'darkblue' }}>통신사 모뎀(공유기)</span>에 이상이 없고,
            </Typography>
            <Typography variant="subtitle1" sx={{ ml: 3.6 }}>
              <span style={{ color: 'darkblue' }}>모니터링 장비의 LAN PORT LAMP</span>가 정상일 경우,
            </Typography>
            <Typography variant="subtitle1" sx={{ ml: 3.6 }}>
              <span style={{ color: 'red' }}>고객센터로 연락</span> 주십시오.
            </Typography>
          </Box>
        ) : signalClickedCard === 2 ? (
          <Box sx={{ p: 1 }}>
            <Typography variant="subtitle1" display="flex" alignContent="center" sx={{ mt: 1, mb: 2 }}>
              <Avatar sx={{ width: 22, height: 22, mr: 1, bgcolor: 'red' }}>
                <Typography variant="subtitle1">1</Typography>
              </Avatar>{' '}
              발전소의 <span style={{ color: 'red' }}>&nbsp;인버터 발전 (동작)상태</span>를 확인해 주십시오.
            </Typography>
            <hr />
            <Typography variant="subtitle1" display="flex" alignContent="center" sx={{ mt: 2 }}>
              <Avatar sx={{ width: 22, height: 22, mr: 1, bgcolor: 'red' }}>
                <Typography variant="subtitle1">2</Typography>
              </Avatar>{' '}
              모니터링 장비의{' '}
              <span style={{ color: 'red' }}>&nbsp;485포트 동작상태(Rx, Tx)의 Lamp를 확인</span>해 주십시오.
            </Typography>
            <Typography variant="subtitle1" sx={{ ml: 3.6, mb: 2 }}>
              (QS-2200E 장비)
            </Typography>
            <hr />
            <Typography variant="subtitle1" display="flex" alignContent="center" sx={{ mt: 2 }}>
              <Avatar sx={{ width: 22, height: 22, mr: 1, bgcolor: 'red' }}>
                <Typography variant="subtitle1">3</Typography>
              </Avatar>{' '}
              모니터링 장비 중{' '}
              <span style={{ color: 'red' }}>&nbsp;신호변환기 (485 Converter 혹은 QSR485)의</span>
            </Typography>
            <Typography variant="subtitle1" sx={{ ml: 3.6 }}>
              <span style={{ color: 'red' }}>485포트 동작상태(Rx, Tx)의 Lamp를 확인</span>해 주십시오.
            </Typography>
            <Typography variant="subtitle1" sx={{ ml: 3.6, mb: 2 }}>
              (QS-2200b, QS-2200c 장비)
            </Typography>
            <hr />
            <Typography variant="subtitle1" display="flex" alignContent="center" sx={{ mt: 2 }}>
              <Avatar sx={{ width: 22, height: 22, mr: 1, bgcolor: 'red' }}>
                <Typography variant="subtitle1">4</Typography>
              </Avatar>{' '}
              <span style={{ color: 'darkblue' }}>인버터가 정상발전</span> 중이고,
            </Typography>
            <Typography variant="subtitle1" sx={{ ml: 3.6 }}>
              <span style={{ color: 'darkblue' }}>485포트의 동작상태가 정상</span>일 경우,
            </Typography>
            <Typography variant="subtitle1" sx={{ ml: 3.6 }}>
              <span style={{ color: 'red' }}>고객센터로 연락</span> 주십시오.
            </Typography>
          </Box>
        ) : signalClickedCard === 3 ? (
          <Box sx={{ p: 1 }}>
            <Typography variant="subtitle1" display="flex" alignContent="center" sx={{ mt: 1, mb: 2 }}>
              <Avatar sx={{ width: 22, height: 22, mr: 1, bgcolor: 'red' }}>
                <Typography variant="subtitle1">1</Typography>
              </Avatar>{' '}
              발전소의 <span style={{ color: 'red' }}>&nbsp;통신사 모뎀(공유기)의 이상유무</span>를 확인해
              주십시오.
            </Typography>
            <hr />
            <Typography variant="subtitle1" display="flex" alignContent="center" sx={{ mt: 2 }}>
              <Avatar sx={{ width: 22, height: 22, mr: 1, bgcolor: 'red' }}>
                <Typography variant="subtitle1">2</Typography>
              </Avatar>{' '}
              발전소의 <span style={{ color: 'red' }}>&nbsp;모니터링 장비의 LAN PORT LAMP</span>를 확인해
              주십시오.
            </Typography>
            <Typography variant="subtitle1" sx={{ ml: 3.6, mb: 2 }}>
              (정상일 경우, LAMP가 점멸 됩니다.)
            </Typography>
            <hr />
            <Typography variant="subtitle1" display="flex" alignContent="center" sx={{ mt: 2 }}>
              <Avatar sx={{ width: 22, height: 22, mr: 1, bgcolor: 'red' }}>
                <Typography variant="subtitle1">3</Typography>
              </Avatar>{' '}
              <span style={{ color: 'darkblue' }}>통신사 모뎀(공유기)</span>에 이상이 없고,
            </Typography>
            <Typography variant="subtitle1" sx={{ ml: 3.6 }}>
              <span style={{ color: 'darkblue' }}>모니터링 장비의 LAN PORT LAMP</span>가 정상일 경우,
            </Typography>
            <Typography variant="subtitle1" sx={{ ml: 3.6 }}>
              <span style={{ color: 'red' }}>고객센터로 연락</span> 주십시오.
            </Typography>
          </Box>
        ) : null}
      </CustomModal>
    </>
  );
}
