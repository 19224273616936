import { Box, Breadcrumbs, useMediaQuery } from '@mui/material';
import NoticeBoardSearchForm from '../../components/Bulletinboard/NoticeBoardSearchForm';
import ArchiveSearchForm from '../../components/Bulletinboard/ArchiveSearchForm';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import TabMenu from '../../components/Layout/TabMenu';
import InquiryBoardSearchForm from '../../components/Bulletinboard/InquiryBoardSearchForm';
import { useContext, useEffect, useState } from 'react';
import Header02 from '../../components/Layout/Header02';
import SubHeader from '../../components/Layout/SubHeader';
import Nav from '../../components/Layout/Nav';
import useResponsive from '../../hooks/useResponsive';
import usePageLogRecord from '../../hooks/usePageLogRecord';
import useBackendV2 from '../../hooks/use-backend-v2';
import AuthContext from '../../store/auth-context';
import config from '../../config';

/*
게시판 - 요청게시판 및 공지게시판
subValue: 0 - 요청게시판
subValue: 1 - 공지게시판
subValue: 2 - 자료실
subValue: 3 - 통합문의게시판
BulletinBoardPage > RequestBoardSearchForm(요청게시판 검색), NoticeBoardSearchForm(공지게시판 검색), ArchiveSearchForm(자료실 검색), InquirySearchForm(통합문의게시판 검색)
*/
export default function BulletinboardPage() {
  const isDesktop = useResponsive('up', 'lg');
  const authCtx = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const distance = 0;

  const isMobile = useMediaQuery('(min-width: 280px) and (max-width: 1200px)');

  let componentToShow;

  switch (subValue) {
    // case 0:
    //   componentToShow = <RequestBoardSearchForm />;
    //   break;
    case 0:
      componentToShow = <NoticeBoardSearchForm />;
      break;
    case 1:
      componentToShow = <ArchiveSearchForm />;
      break;
    case 2:
      componentToShow = <InquiryBoardSearchForm />;
      break;
    default:
      componentToShow = null;
      break;
  }

  usePageLogRecord('bulletinBoards');

  const backendRequest = useBackendV2();

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchUserStylePreference();
  }, []);

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        // plantkey={page.plantkey}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'flex',
          },
        }}
      >
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <StyledBreadcrumb label="게시판" icon={<ContentPasteIcon fontSize="small" color="primary" />} />
          <StyledBreadcrumb
            // label={
            //   subValue === 0
            //     ? '요청 게시판'
            //     : subValue === 1
            //     ? '공지 게시판'
            //     : subValue === 2
            //     ? '자료실'
            //     : subValue === 3
            //     ? '통합 문의 게시판'
            //     : null
            // }
            label={
              subValue === 0
                ? '공지 게시판'
                : subValue === 1
                ? '자료실'
                : subValue === 2
                ? '통합 문의 게시판'
                : null
            }
          />
        </Breadcrumbs>
      </Box>
      <Box sx={{ mt: 2.5 }}>
        {isMobile ? null : <TabMenu menu="bulletinboards" subValue={subValue} setSubValue={setSubValue} />}
        {componentToShow}
      </Box>
    </>
  );
}
