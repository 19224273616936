import { useEffect } from 'react';
import config from '../config';
import useBackendV2 from './use-backend-v2';

// 페이지 접근 로그 저장 컴포넌트
export default function usePageLogRecord(pageName, plantkey) {
  const backendRequest = useBackendV2();

  const plantkeys = plantkey === undefined ? '' : plantkey;

  const fetchPageLogRecords = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.logRecords.pageLogRecord}/${pageName}/${plantkeys}`
      );
      if (data !== true) {
        console.error();
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (
      (pageName === 'eachMonitorings' ||
        pageName === 'inverters' ||
        pageName === 'pushSettings' ||
        pageName === 'statistics' ||
        pageName === 'statusPlate' ||
        pageName === 'addSubscribeKeys' ||
        pageName === 'alarms') &&
      (plantkey === undefined || plantkey === '')
    ) {
      return;
    } else {
      fetchPageLogRecords();
    }
  }, [plantkey]);

  return;
}
