import { Box, Button, Typography } from '@mui/material';
import CustomModal from '../../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import OfflinePinOutlinedIcon from '@mui/icons-material/OfflinePinOutlined';

export default function PlantCapactiyCheckModal({ open, onClose }) {
  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <>
      <CustomModal
        open={open}
        onClose={closeModalHandler}
        sx={{ maxHeight: '95%', width: { xs: '95%', md: 500 } }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <OfflinePinOutlinedIcon />
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
            설비 용량 안내
          </Typography>
        </Box>
        <hr />
        <Box display="flex" justifyContent="center">
          <Typography variant="subtitle1" display="flex" alignContent="center" sx={{ mt: 1 }}>
            발전소의 설비 용량이 잘못되었습니다.
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography variant="subtitle1" display="flex" alignContent="center" sx={{ mt: 1 }}>
            발전소 설정에서 설비 용량을 수정 해주세요.
          </Typography>
        </Box>
        <Box>
          <Button onClick={closeModalHandler} variant="outlined" sx={{ width: '100%', mt: 3 }}>
            닫기
          </Button>
        </Box>
      </CustomModal>
    </>
  );
}
