import { useContext, useState } from 'react';
import useBackendV2 from '../../../hooks/use-backend-v2';
import { Box, Button, IconButton, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import JoinForm from '../JoinForm';
import TermsForm from '../TermsForm';
import config from '../../../config';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../../store/auth-context';

// 소셜로그인 연동할 회원 검색 컴포넌트
export default function SearchUser({
  social,
  socialUserInfo,
  onClose,
  joinUserHandler,
  socialJoinUserModal,
  agreed1,
  setAgreed1,
  agreed2,
  setAgreed2,
  allAgreed,
  setAllAgreed,
  handleAgreeCheckboxChange,
  cancelJoin,
}) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  let from = location.state?.from?.pathname || '/';

  const [inputId, setInputId] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const inputIdChangeHandler = (event) => {
    setInputId(event.target.value);
  };
  const inputPasswordChangeHandler = (event) => {
    setInputPassword(event.target.value);
  };
  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const loginRequest = useBackendV2();
  const socialLoginRequest = useBackendV2();

  const loginHandler = async (event) => {
    const loginRequestConfig = {
      method: 'POST',
      body: {
        id: inputId,
        password: inputPassword,
        smtype: social === 'naver' ? 1 : social === 'kakao' ? 2 : '',
        smid: socialUserInfo.id.toString(),
        smname: socialUserInfo.nickname,
        smemail: socialUserInfo.email,
        smhp: socialUserInfo.phone,
      },
    };

    const result = await socialLoginRequest.sendRequest(
      config.backend.endPoint.authentication.smlogin,
      loginRequestConfig,
      false
    );
    authCtx.login(result.data);
    navigate(from, { replace: true });
  };

  return (
    <>
      {socialJoinUserModal ? (
        <>
          <JoinForm
            allAgreed={allAgreed}
            setAllAgreed={setAllAgreed}
            agreed1={agreed1}
            setAgreed1={setAgreed1}
            agreed2={agreed2}
            setAgreed2={setAgreed2}
            handleAgreeCheckboxChange={handleAgreeCheckboxChange}
            cancelJoin={cancelJoin}
          />
        </>
      ) : (
        <>
          <Box>
            기존 회원 인증 및 {social === 'kakao' ? '카카오' : social === 'naver' ? '네이버' : ''} 연동
          </Box>
          <br />
          <Box>아이디와 비밀번호 입력으로 </Box>
          <Box>기존 회원 여부를 확인합니다.</Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', mt: 2 }}>
            <TextField
              variant="standard"
              label="아이디"
              value={inputId}
              onChange={inputIdChangeHandler}
              sx={{ mb: 1.5, backgroundColor: 'white' }}
            />
            <TextField
              type={showPassword ? 'text' : 'password'}
              variant="standard"
              label="비밀번호"
              value={inputPassword}
              onChange={inputPasswordChangeHandler}
              sx={{ backgroundColor: 'white' }}
              InputProps={{
                endAdornment: (
                  <IconButton onClick={toggleShowPassword} edge="end">
                    {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                  </IconButton>
                ),
              }}
            />
            <Box sx={{ mt: 3, width: '100%' }}>
              <Button
                type="button"
                variant="contained"
                size="large"
                sx={{ width: '100%' }}
                onClick={loginHandler}
              >
                인증 및 연동하기
              </Button>
              <Button
                variant="text"
                type="button"
                size="large"
                sx={{
                  width: '100%',
                  mt: 1.5,
                  backgroundColor: 'white',
                  textDecoration: 'underline',
                }}
                onClick={joinUserHandler}
              >
                신규 회원으로 시작하기
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
