import {
  Avatar,
  Box,
  Card,
  ClickAwayListener,
  Collapse,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { convertStringToFormatDateString } from '../../../utils/dateUtil';

// 인버터 계통도 내 인버터 세부정보 표시 컴포넌트
export default function InverterMapCard({ inverterInfo, isDesktop }) {
  const [expanded, setExpanded] = useState(false);
  const [plantIvtTooltipOpenMap, setPlantIvtTooltipOpenMap] = useState({});
  const [plantStatisticsTooltipOpenMap, setPlantStatisticsTooltipOpenMap] = useState({});

  const handleInvExpandClick = () => {
    setExpanded(!expanded);
  };

  // 클릭한 인버터 아바타의 툴팁 상태를 열기
  const handlePlantIvtAvatarClick = (serialnum) => {
    setPlantIvtTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: true }));
  };
  // 인버터 아바타의 툴팁 상태를 닫기
  const handlePlantIvtTooltipClose = (serialnum) => {
    setPlantIvtTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: false }));
  };

  // 클릭한 통계 아바타의 툴팁 상태를 열기
  const handlePlantStatisticsAvatarClick = (serialnum) => {
    setPlantStatisticsTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: true }));
  };
  // 통계 아바타의 툴팁 상태를 닫기
  const handlePlantStatisticsTooltipClose = (serialnum) => {
    setPlantStatisticsTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: false }));
  };

  return (
    <>
      <Box sx={{ pb: 0, mt: 1 }}>
        <Card
          variant="outlined"
          sx={{
            padding: {
              xs: 0,
              sm: 2,
            },
            // borderColor: '#CECECE',
            borderRadius: '14px',
            boxShadow: '0px 3px 12px #00000014',
            '&:hover': { borderColor: 'blue', borderWidth: '2px' },
            width: isDesktop ? '50%' : '100%',
          }}
        >
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            width="100%"
            spacing={2}
          >
            <Stack
              sx={{
                padding: {
                  xs: 1,
                  sm: 0,
                },
              }}
              flex={1}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body2">
                  <strong>{inverterInfo.invertername}</strong>
                </Typography>
                <Avatar sx={{ width: '25px', height: '25px', bgcolor: '#1981E7' }}>
                  <ExpandMoreIcon
                    onClick={handleInvExpandClick}
                    sx={{
                      color: '#fff',
                      transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                      cursor: 'pointer',
                    }}
                  />
                </Avatar>
              </Box>
              {/* <Stack sx={{ flexGrow: 1, textAlign: 'right' }} spacing={0}>
                <Typography variant="body2">
                  발전소 용량 <strong>{inverterInfo.ivtcapacity}kW</strong>
                </Typography>
              </Stack> */}
              <Stack spacing={0} direction="row" display="flex" justifyContent="space-between">
                <Box display="flex">
                  <Box sx={{ m: 1, display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2">
                      <strong>인버터</strong>
                    </Typography>
                    <Box sx={{ ml: 1 }}>
                      {isDesktop ? (
                        <Tooltip title={inverterInfo.generatestatus === 1 ? '발전 중' : '발전 중단'} arrow>
                          <Avatar
                            sizes="small"
                            sx={{
                              width: 15,
                              height: 15,
                              bgcolor: inverterInfo.generatestatus === 1 ? '#64B346' : 'red',
                              color: inverterInfo.generatestatus === 1 ? '#64B346' : 'red',
                            }}
                          >
                            {''}
                          </Avatar>
                        </Tooltip>
                      ) : (
                        <ClickAwayListener
                          onClickAway={() => handlePlantIvtTooltipClose(inverterInfo.serialnum)}
                        >
                          <div>
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              open={!!plantIvtTooltipOpenMap[inverterInfo.serialnum]}
                              onClose={() => handlePlantIvtTooltipClose(inverterInfo.serialnum)}
                              title={inverterInfo.generatestatus === 1 ? '발전 중' : '발전 중단'}
                              arrow
                            >
                              <Avatar
                                sizes="small"
                                sx={{
                                  width: 15,
                                  height: 15,
                                  bgcolor: inverterInfo.generatestatus === 1 ? '#64B346' : 'red',
                                  color: inverterInfo.generatestatus === 1 ? '#64B346' : 'red',
                                }}
                                onClick={() => handlePlantIvtAvatarClick(inverterInfo.serialnum)}
                              >
                                {''}
                              </Avatar>
                            </Tooltip>
                          </div>
                        </ClickAwayListener>
                      )}
                    </Box>
                  </Box>
                  <Box sx={{ m: 1, display: 'flex', alignItems: 'center' }}>
                    <Typography variant="body2">
                      <strong>통계</strong>
                    </Typography>
                    <Box sx={{ ml: 1 }}>
                      {isDesktop ? (
                        <Tooltip title={inverterInfo.dlstatus === 1 ? '적산 중' : '적산 중단'} arrow>
                          <Avatar
                            sizes="small"
                            sx={{
                              width: 15,
                              height: 15,
                              bgcolor: inverterInfo.dlstatus === 1 ? '#64B346' : 'red',
                              color: inverterInfo.dlstatus === 1 ? '#64B346' : 'red',
                            }}
                          >
                            {''}
                          </Avatar>
                        </Tooltip>
                      ) : (
                        <ClickAwayListener
                          onClickAway={() => handlePlantStatisticsTooltipClose(inverterInfo.serialnum)}
                        >
                          <div>
                            <Tooltip
                              PopperProps={{
                                disablePortal: true,
                              }}
                              open={!!plantStatisticsTooltipOpenMap[inverterInfo.serialnum]}
                              onClose={() => handlePlantStatisticsTooltipClose(inverterInfo.serialnum)}
                              title={inverterInfo.dlstatus === 1 ? '적산 중' : '적산 중단'}
                              arrow
                            >
                              <Avatar
                                sizes="small"
                                sx={{
                                  width: 15,
                                  height: 15,
                                  bgcolor: inverterInfo.dlstatus === 1 ? '#64B346' : 'red',
                                  color: inverterInfo.dlstatus === 1 ? '#64B346' : 'red',
                                }}
                                onClick={() => handlePlantStatisticsAvatarClick(inverterInfo.serialnum)}
                              >
                                {''}
                              </Avatar>
                            </Tooltip>
                          </div>
                        </ClickAwayListener>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box sx={{ m: 1 }}>
                  <Typography variant="subtitle2">
                    인버터 용량 : <strong>{inverterInfo.ivtcapacity}kW</strong>
                  </Typography>
                </Box>
                {/* <ExpandMore
                  expand={expanded}
                  onClick={handleInvExpandClick}
                  aria-expanded={expanded}
                  aria-label="show more"
                >
                  <ExpandMoreIcon />
                </ExpandMore> */}
              </Stack>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} color="primary">
                    마지막 통신 시간
                  </Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>
                    {convertStringToFormatDateString(inverterInfo.rdate)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>인버터 정보</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {inverterInfo.serialnum}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>발전 상태</Typography>
                  <Typography
                    variant={isDesktop ? 'subtitle1' : 'subtitle2'}
                    color={inverterInfo.generatestatus === 1 ? 'primary' : 'error'}
                    sx={{ ml: 1 }}
                  >
                    {inverterInfo.generatestatus === 1 ? '발전 중' : '발전 중단'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>일일 발전량</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {inverterInfo.dailyyield === null ? 0 : inverterInfo.dailyyield?.toFixed(1)} kWh
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>총 발전량</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {inverterInfo.totalyield === null ? 0 : inverterInfo.totalyield?.toFixed(1)} kWh
                  </Typography>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} color="primary">
                    입력(DC)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>입력 전력</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {inverterInfo.pdc?.toFixed(1)} kW
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>입력 전압</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {inverterInfo.udc?.toFixed(1)} Vdc
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>입력 전류</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {inverterInfo.idc?.toFixed(1)} Vdc
                  </Typography>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} color="error">
                    출력(AC)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>출력 전력</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end" color="error">
                    {inverterInfo.pac?.toFixed(1)} kW
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>출력 전압</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {inverterInfo.uac?.toFixed(1)} Vac
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>출력 전류</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {inverterInfo.udc?.toFixed(1)} Vac
                  </Typography>
                </Box>
                <Divider sx={{ mt: 2, mb: 1 }} />
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>기타 정보</Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>주파수</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {inverterInfo.fac?.toFixed(1)} Hz
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>지락 전류</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    0 A
                  </Typography>
                </Box>
              </Collapse>
            </Stack>
          </Stack>
        </Card>
      </Box>
    </>
  );
}
