import { AppBar, Box, Slide, Tab, Tabs, Toolbar, Typography, useScrollTrigger } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import AuthContext from '../../store/auth-context';
import { useContext } from 'react';

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger({
    threshold: 50, // 스크롤이 50px 아래로 내려갔을 때 숨기기 시작
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

// 모바일의 경우 Header02 컴포넌트 밑에 호출되어 메뉴 이동을 가능하게 하는 컴포넌트
export default function SubHeader({ value, setValue, subValue, setSubValue, isDesktop }) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  // 현재 페이지의 URL에서 path를 추출
  const currentPath = window.location.pathname;

  const handleTabChange = (e, newValue) => {
    setSubValue(newValue);
    localStorage.setItem('menuSubValue', newValue);

    switch (value) {
      case 0:
        navigate('/', { state: { value: 0, subValue: newValue } });
        break;
      case 1:
        navigate(config.frontend.inverters, { state: { value: 1, subValue: newValue } });
        break;
      case 2:
        navigate(config.frontend.statistics, { state: { value: 2, subValue: newValue } });
        break;
      case 3:
        navigate(config.frontend.alarms, { state: { value: 3, subValue: newValue } });
        break;
      case 4:
        navigate(config.frontend.bulletinboards, { state: { value: 4, subValue: newValue } });
        break;
      case 5:
        navigate(config.frontend.plantsettings, { state: { value: 5, subValue: newValue } });
        break;
      default:
        break;
    }
  };

  const selectedTextByValue = {
    0: '모니터링',
    1: '인버터',
    2: '통계',
    3: '경보',
    4: '게시판',
    5: '발전소 설정',
    6: currentPath === '/products' ? '상품&서비스 목록' : '상품&서비스 설명',
    7: 'PUSH 알람 설정',
    8: '주문 내역',
    9: '발전소 및 RTU 등록',
    10: '이용권 등록',
    11: '발전소 연계',
  };
  const menuItemsByValue = {
    0: [
      { value: 0, label: '전체 현황' },
      { value: 1, label: '발전소' },
    ],
    1:
      authCtx.currentUser.role === '사이트 관리자' || authCtx.currentUser.role === '안전관리자'
        ? [
            { value: 0, label: '목록' },
            { value: 1, label: '계통도' },
            { value: 2, label: '접속반' },
          ]
        : [
            { value: 0, label: '목록' },
            { value: 1, label: '계통도' },
            { value: 2, label: '접속반' },
            { value: 3, label: '장비제어' },
          ],
    2: [
      { value: 0, label: '시간' },
      { value: 1, label: '일간' },
      { value: 2, label: '월간' },
      { value: 3, label: '연간' },
      { value: 4, label: '환경정보통계' },
      // { value: 5, label: '종합분석통계' },
    ],
    3: [
      { value: 0, label: '이력' },
      { value: 1, label: '발생 빈도' },
    ],
    4: [
      // { value: 0, label: '요청 게시판' },
      { value: 0, label: '공지 게시판' },
      { value: 1, label: '자료실' },
      { value: 2, label: '통합 문의 게시판' },
    ],
    5: [
      { value: 0, label: '발전소 정보 수정' },
      { value: 1, label: '알림메시지 항목 설정' },
      { value: 2, label: '발전소 계약 정보' },
      { value: 3, label: '관리업체 정보' },
    ],
  };

  const selectedText = selectedTextByValue[value];
  const currentMenuItems = menuItemsByValue[value];

  return (
    <>
      <HideOnScroll>
        <AppBar
          sx={{
            // backgroundColor: theme.palette.background.default,
            backgroundColor: alpha('#F8F9FE', 0.8),
            justifyContent: 'space-between',
            mt: isDesktop ? 11.9 : 7.6,
            boxShadow: 'none',
          }}
        >
          <Toolbar sx={{ display: { xs: 'flex' } }}>
            {value === 0 ||
            value === 6 ||
            value === 7 ||
            value === 8 ||
            value === 9 ||
            value === 10 ||
            value === 11 ? (
              <Typography
                color="inherit"
                sx={{
                  color: '#000000',
                  fontWeight: 'bold',
                  fontSize: '1.05rem',
                }}
              >
                {selectedText}
              </Typography>
            ) : (
              <Box>
                {value !== 0 &&
                  value !== 6 &&
                  value !== 7 &&
                  value !== 8 &&
                  value !== 9 &&
                  value !== 10 &&
                  value !== 11 && (
                    <Tabs
                      value={subValue}
                      onChange={handleTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                    >
                      {currentMenuItems?.map((item) => (
                        <Tab
                          key={item.value}
                          label={item.label}
                          value={item.value}
                          sx={{
                            minWidth: '60px',
                            // width: '100%',
                            p: '2px',
                          }}
                        />
                      ))}
                    </Tabs>
                  )}
              </Box>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
    </>
  );
}
