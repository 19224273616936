import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import Diversity3OutlinedIcon from '@mui/icons-material/Diversity3Outlined';

export default function ConnectionPlantDetail({ connectionPlantClickHandler }) {
  return (
    <List component="nav">
      <ListItemButton onClick={connectionPlantClickHandler}>
        <ListItemIcon>
          <Diversity3OutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="발전소 연계 등록" />
      </ListItemButton>
    </List>
  );
}
