import {
  Box,
  Divider,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { convertFormatStringToYMD } from '../../utils/dateUtil';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { useEffect, useState } from 'react';

export default function ShopProductCardDetailHavePlantkey({
  plantkey,
  plantName,
  expiredRtu,
  productId,
  setProductId,
  product,
  id,
}) {
  const [productOption, setProductOption] = useState([]);
  const [sharingRtuPlants, setSharingRtuPlants] = useState([]);

  const changeOptionHandler = (e) => {
    setProductId(e.target.value);
  };

  const backendRequest = useBackendV2();

  const fetchGetProductOption = async () => {
    if (productId !== 0) {
      try {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.products.productOption}/${product.optionkey}`
        );

        setProductOption(data);
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    }
  };

  const fetchGetSharingPlant = async (rtu) => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.findSharingRtuPlant}/${rtu.rtun}`
      );

      const plantkeyNumber = parseInt(plantkey.split('|')[0], 10); // |DE 제거한 숫자

      const filteredData = data.filter((item) => item.plantkey !== plantkeyNumber);

      setSharingRtuPlants((prev) => {
        const updated = [...prev];
        filteredData.forEach((item) => {
          if (!updated.some((plant) => plant.plantkey === item.plantkey)) {
            updated.push(item);
          }
        });
        updated.sort((a, b) => a.pname.localeCompare(b.pname, 'ko', { sensitivity: 'base' }));
        return updated;
      });
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    if (product.optionkey !== 9) {
      fetchGetProductOption();
    } else {
      setProductOption([]);
    }
  }, [productId]);

  useEffect(() => {
    expiredRtu.map((rtu) => {
      fetchGetSharingPlant(rtu);
    });
  }, [expiredRtu]);

  return (
    <>
      <Divider sx={{ mt: 1.5 }} />
      {expiredRtu.length !== 0 && plantName !== undefined ? (
        <>
          <Typography variant="subtitle1" sx={{ mt: 1, mb: 1 }}>
            선택 발전소 : {plantName}
          </Typography>
          <Table size="small" sx={{ width: '100%', mt: 1 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: '#f0f0f0',
                    borderRight: '1px solid #d0d0d0',
                    color: 'black',
                    verticalAlign: 'middle',
                  }}
                >
                  RTU번호
                </TableCell>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: '#f0f0f0',
                    borderRight: '1px solid #d0d0d0',
                    color: 'black',
                    verticalAlign: 'middle',
                  }}
                >
                  서비스 만료일
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {expiredRtu.map((item) => (
                <TableRow key={item.rtun}>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: '1px solid #d0d0d0',
                      verticalAlign: 'middle',
                    }}
                  >
                    {item.rtun}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: '1px solid #d0d0d0',
                      verticalAlign: 'middle',
                    }}
                  >
                    {convertFormatStringToYMD(item.enddate)}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Divider />
          <Divider sx={{ mt: 1.5 }} />
          <Typography variant="subtitle1" sx={{ mt: 1.5, mb: 1 }}>
            RTU 공유 발전소 목록
          </Typography>
          <Table size="small" sx={{ width: '100%', mt: 1 }}>
            <TableHead>
              <TableRow>
                <TableCell
                  align="center"
                  sx={{
                    backgroundColor: '#f0f0f0',
                    borderRight: '1px solid #d0d0d0',
                    color: 'black',
                    verticalAlign: 'middle',
                  }}
                >
                  발전소 명
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sharingRtuPlants.map((plant) => (
                <TableRow key={plant.plantkey}>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: '1px solid #d0d0d0',
                      verticalAlign: 'middle',
                    }}
                  >
                    {plant.pname}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Divider />
        </>
      ) : null}
      {productId !== undefined && product.optionkey !== 9 ? (
        <>
          <Box sx={{ mt: 1 }}>
            <Typography variant="subtitle1">옵션</Typography>
            <Select
              variant="standard"
              value={productId}
              onChange={(e) => changeOptionHandler(e)}
              sx={{ width: '100%' }}
            >
              <MenuItem disabled>-- 옵션 선택 --</MenuItem>
              {productOption?.map((option, index) => (
                <MenuItem key={index} value={option.pdtid}>
                  {option.pdtname}
                </MenuItem>
              ))}
            </Select>
          </Box>
        </>
      ) : null}
    </>
  );
}
