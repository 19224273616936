import { Button } from '@mui/material';

export default function MakeDepositStatus({ status, istax, open, moveInquiryBoardAdd }) {
  return (
    <>
      {status === 'WAITING_QUBE' ? (
        <>
          <Button variant="contained" size="small" color="warning" onClick={open} sx={{ mt: 1.5, mr: 1 }}>
            무통장 입금 대기 중
          </Button>
        </>
      ) : status === 'DONEWAIT_QUBE' ? (
        <>
          <Button
            variant="contained"
            size="small"
            onClick={() => alert('무통장 입금 확인 중 입니다.')}
            sx={{ mt: 1.5, mr: 1 }}
          >
            무통장 입금 확인 중
          </Button>
        </>
      ) : status === 'DONE_QUBE' ? (
        <>
          <Button
            variant="contained"
            size="small"
            sx={{ bgcolor: 'green', color: '#fff', mt: 1.5, '&:hover': { bgcolor: 'green' }, mr: 1 }}
          >
            무통장 입금 확인 완료
          </Button>
        </>
      ) : (
        <>
          <Button variant="contained" size="small" color="error" sx={{ mt: 1.5, mr: 1 }}>
            무통장 입금 확인 안됨
          </Button>
        </>
      )}
      {istax === 0 ? (
        <Button
          variant="contained"
          size="small"
          color="secondary"
          onClick={moveInquiryBoardAdd}
          sx={{ color: '#fff', mt: 1.5 }}
        >
          세금계산서 발행 신청
        </Button>
      ) : null}
    </>
  );
}
