import { useEffect, useState } from 'react';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import { convertFormatStringToYMD } from '../../../utils/dateUtil';
import HourAllStatistics from './HourAllStatistics';
import HourGroupStatistics from './HourGroupStatistics';
import HourInverterStatistics from './HourInverterStatistics';

export default function Hour({
  page,
  subValue,
  tabValue,
  dateValue,
  searchState,
  setSearchState,
  chartView,
}) {
  const [hourlyDevelopment, setHourlyDevelopment] = useState([]);

  const hourlyDevelopmentRequest = useBackendV2();

  const fetchHourlyDevelopmentData = async () => {
    try {
      let tab;
      if (tabValue === 'all') {
        tab = 0;
      } else if (tabValue === 'group') {
        tab = 1;
      } else if (tabValue === 'inverter') {
        tab = 2;
      }

      const queryStringArr = [
        `yieldGroupType=${tab}`,
        `yieldtype=${subValue}`,
        `dateTime=${convertFormatStringToYMD(dateValue)}`,
      ];
      const queryString = queryStringArr.join('&');

      const { data } = await hourlyDevelopmentRequest.sendRequest(
        `${config.backend.endPoint.statistics.plantYield}/${page.plantkey}?${queryString}`
      );

      const newData = data.map((item) => ({
        ...item,
        radiation: item.radiation === -999 ? null : item.radiation,
      }));

      const missingData = [];
      for (let i = 5; i <= 20; i++) {
        const existingDataIndex = newData.findIndex((item) => item.year_month_day_hour === i);
        if (existingDataIndex === -1) {
          missingData.push({
            groupname: newData[0].groupname,
            fdate: newData[0].fdate,
            year_month_day_hour: i,
            yield: null,
            totalyield: null,
            fac: null,
            generateHour: null,
            iac: null,
            idc: null,
            pac: null,
            pdc: null,
            radiation: null,
            tco2: null,
            uac: null,
            udc: null,
          });
        }
      }

      const mergedData = [...newData, ...missingData];

      mergedData.sort((a, b) => a.year_month_day_hour - b.year_month_day_hour);

      setHourlyDevelopment(mergedData.map((item, index) => ({ ...item, id: index })));
      setSearchState(false);
    } catch (error) {
      setHourlyDevelopment([]);
      setSearchState(false);
    }
  };

  useEffect(() => {
    if (searchState && page.plantkey !== '') {
      fetchHourlyDevelopmentData();
    }
  }, [searchState, page]);

  let componentToShow;

  switch (tabValue) {
    case 'all':
      componentToShow = <HourAllStatistics data={hourlyDevelopment} chartView={chartView} />;
      break;
    case 'group':
      componentToShow = <HourGroupStatistics data={hourlyDevelopment} chartView={chartView} />;
      break;
    case 'inverter':
      componentToShow = <HourInverterStatistics data={hourlyDevelopment} chartView={chartView} />;
      break;
    default:
      componentToShow = null;
      break;
  }

  return <>{componentToShow}</>;
}
