import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { Chip, Stack, Typography, useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

/*
공지게시판 리스트 컴포넌트(모바일뷰)
NoticeBoardSearchForm > NoticeBoardList(웹뷰 리스트 페이지),NoticeBoardListMobile(모바일뷰 리스트 페이지)
*/
export default function NoticeBoardListMobile({ rows, onBtnMore }) {
  const isSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 320px)');

  const navigate = useNavigate();

  function handleGoDetail(seq) {
    navigate(`${config.frontend.noticeBoardsdetail}/${seq}`);
  }
  return (
    <>
      {rows.length > 0 ? (
        rows.map((row, i) => (
          <Card
            sx={{ width: '100%', mb: 2, mt: 2, borderRadius: '16px' }}
            key={row.seq}
            onClick={() => handleGoDetail(row.seq)}
          >
            <Stack direction="row" sx={{ mb: 2, ml: 1, mt: 2, mr: 1 }} spacing={1}>
              {row.noticetype === '1' ? (
                <Chip
                  label="로그인 후 공지"
                  size="small"
                  sx={{ width: isSmallDevice ? '35%' : '50%' }}
                  variant="outlined"
                />
              ) : row.noticetype === '2' ? (
                <Chip
                  label="로그인 전 공지"
                  size="small"
                  sx={{ width: isSmallDevice ? '35%' : '50%' }}
                  variant="outlined"
                />
              ) : row.noticetype === '3' ? (
                <Chip
                  label="로그인 전,후 공지"
                  size="small"
                  sx={{ width: isSmallDevice ? '35%' : '50%' }}
                  variant="outlined"
                />
              ) : (
                <Chip
                  label="약관 공지"
                  size="small"
                  sx={{ width: isSmallDevice ? '35%' : '50%' }}
                  variant="outlined"
                />
              )}
              <Typography
                sx={{
                  textAlign: 'right',
                  font: 'normal normal normal 12px/26px Noto Sans',
                  letterSpacing: '0px',
                  color: '#8B8C9C',
                  opacity: '1',
                  width: '100%',
                }}
              >
                {`${row.dateViewFrom === null ? '' : row.dateViewFrom.substring(0, 10)} ~ ${row.dateViewTo === null ? '' : row.dateViewTo.substring(0, 10)
                  }`}
              </Typography>
            </Stack>
            <Stack
              sx={{
                ml: 1,
                textAlign: 'left',
                font: 'normal normal 600 15px/25px Noto Sans',
                letterSpacing: '0px',
                color: '#000000',
                opacity: '1',
                mb: 2,
              }}
            >
              {row.title}
            </Stack>
          </Card>
        ))
      ) : (
        <Card sx={{ maxWidth: '100%', mt: 2 }}>
          <CardHeader title="조회된 정보가 없습니다." sx={{ textAlign: 'center' }} />
        </Card>
      )}
    </>
  );
}
