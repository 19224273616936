import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import { convertStringToFormatDateString } from '../../utils/dateUtil';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '13px',
    color: '#000000',
    borderBottom: '1px solid #e0e0e0',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f0f0f0',
  },
  '&:last-child td, &:last-child th': {
    borderBottom: '1px solid #e0e0e0',
  },
}));

/*
공지게시판 리스트 컴포넌트(웹뷰)
NoticeBoardSearchForm > NoticeBoardList(웹뷰 리스트 페이지),NoticeBoardListMobile(모바일뷰 리스트 페이지)
*/
export default function NoticeBoardList({ rows, onBtnMore }) {
  const navigate = useNavigate();

  function handleGoDetail(seq) {
    navigate(`${config.frontend.noticeBoardsdetail}/${seq}`);
  }

  // 게시 시작 & 게시 종료의 시, 분, 초 소거 함수
  function formatDateWithoutTime(dateString) {
    return dateString ? dateString.substring(0, 10) : '';
  }

  const columns = [
    { field: 'title', headerName: '제목', width: 200 },
    { field: 'noticetype', headerName: '공지 유형', width: 200 },
    { field: 'dateViewFrom', headerName: '게시 시작', width: 200 },
    { field: 'dateViewTo', headerName: '게시 종료', width: 200 },
  ];

  return (
    <>
      <Card
        sx={{
          mt: 2,
          borderRadius: 0,
          boxShadow: '0px 3px 12px #00000014',
        }}
      >
        <StyledTableContainer>
          <Table size="small" sx={{ minWidth: 1200 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.field}
                    align="center"
                    style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                  >
                    {column.headerName}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, index) => (
                  <StyledTableRow
                    key={row.seq}
                    onClick={() => handleGoDetail(row.seq)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: '#cfd8dc !important' },
                    }}
                  >
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.field}
                        align={column.field === 'title' ? 'left' : 'center'}
                        style={{
                          minWidth: column.width,
                          backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                          borderRight: '1px solid #d0d0d0',
                        }}
                      >
                        {column.field === 'noticetype' ? (
                          row[column.field] === '1' ? (
                            '로그인 후 공지'
                          ) : row[column.field] === '2' ? (
                            '로그인 전 공지'
                          ) : row[column.field] === '3' ? (
                            '로그인 전,후 공지'
                          ) : (
                            '약관 공지'
                          )
                        ) : column.field === 'dateViewFrom' || column.field === 'dateViewTo' ? (
                          row[column.field] === null ? '' : formatDateWithoutTime(row[column.field])
                        ) : (
                          row[column.field]
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={4} sx={{ textAlign: 'center' }}>
                    조회된 정보가 없습니다.
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Card>
    </>
  );
}
