import { Avatar, Box, Card, Collapse, Divider, IconButton, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { convertStringToFormatDateString } from '../../../utils/dateUtil';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AlarmHistoryApp({ data }) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          padding: {
            xs: 0,
            sm: 2,
          },
          borderRadius: '14px',
          boxShadow: '0px 3px 12px #00000014',
          '&:hover': { borderColor: 'blue', borderWidth: '2px' },
          width: '100%',
          mb: 1,
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          width="100%"
          spacing={2}
        >
          <Stack
            sx={{
              padding: {
                xs: 1,
                sm: 0,
              },
            }}
            flex={1}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle2">{data.invertername}</Typography>
              {/* <Avatar sx={{ width: '22px', height: '22px', mb: 1 }}>
                <ExpandMoreIcon
                  onClick={handleExpandClick}
                  sx={{ color: '#000', transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)' }}
                />
              </Avatar> */}
              <Typography variant="subtitle2">{convertStringToFormatDateString(data.localdate)}</Typography>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'center', gap: 0.1, mt: 1 }}>
              <Card
                sx={{
                  display: 'flex',
                  bgcolor: '#fff',
                  color: '#000',
                  borderRadius: '5px',
                  p: 0.25,
                }}
              >
                <Typography variant={'subtitle2'} >
                  {data.errstatus}
                </Typography>
              </Card>
              <Typography sx={{ fontWeight: 'bold' }}>&nbsp;:&nbsp;</Typography>
              <Card
                sx={{
                  display: 'flex',
                  bgcolor: '#fff',
                  color: '#000',
                  borderRadius: '5px',
                  p: 0.25,
                }}
              >
                <Typography variant={'subtitle2'}>
                  {data.errmsg}
                </Typography>
              </Card>
            </Box>
            <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
              <Card
                sx={{
                  display: 'flex',
                  bgcolor: '#fff',
                  color: '#000',
                  borderRadius: '5px',
                  p: 0.25,
                  mr: 1,
                }}
              >
                <Typography variant="body2" sx={{ mr: 0.5, fontWeight: 'bold' }}>
                  상태 코드 :
                </Typography>
                <Typography variant="subtitle2" color="#F3A200">
                  {data.state}
                </Typography>
              </Card>
              <Card
                sx={{
                  display: 'flex',
                  bgcolor: '#fff',
                  color: '#000',
                  borderRadius: '5px',
                  p: 0.25,
                }}
              >
                <Typography variant="body2" sx={{ mr: 0.5, fontWeight: 'bold' }}>
                  경보 코드 :
                </Typography>
                <Typography variant="subtitle2" color="error">
                  {data.errnum}
                </Typography>
              </Card>
            </Box>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}
