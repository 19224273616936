import { useEffect, useState } from 'react';
import MainCard from '../../theme/MainCard';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { fNumberWithCommas } from '../../utils/formatNumber';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'grey',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

// 상품 상세보기내 다른 상품 선택 컴포넌트
export default function ShopProductCardDetailMoreInfo({ setCount, setExpiredRtu, setProductDetailExplain }) {
  const navigate = useNavigate();

  const [products, setProducts] = useState([]);

  const sortedProducts = products.slice().sort((a, b) => {
    // pdttype이 다를 경우 pdttype을 기준으로 정렬
    if (a.pdttype !== b.pdttype) {
      return a.pdttype - b.pdttype;
    }
    // pdttype이 같은 경우 pdtid를 기준으로 정렬
    return a.pdtid - b.pdtid;
  });

  const backendRequest = useBackendV2();

  const fetchProductList = async () => {
    try {
      const { data } = await backendRequest.sendRequest(config.backend.endPoint.products.product);
      setProducts(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const moveProductDetailClickHandler = (pdtid, pdttype) => {
    setCount(1);
    setExpiredRtu([]);
    setProductDetailExplain(false);
    navigate(`${config.frontend.productdetails}/${pdtid}`, {
      state: { pdttype: pdttype },
    });
  };

  useEffect(() => {
    fetchProductList();
  }, []);

  return (
    <>
      <MainCard sx={{ mt: 3 }}>
        <Typography variant="h6">다른 상품</Typography>
        <StyledTableContainer sx={{ mt: 4 }}>
          <Table size="small">
            <TableBody>
              <TableRow>
                {sortedProducts.map((product, index) => (
                  <StyledTableCell
                    key={index}
                    align="center"
                    onClick={() => {
                      moveProductDetailClickHandler(product.pdtid, product.pdttype);
                    }}
                    sx={{ minWidth: 170, width: 170, borderRight: '1px solid #e0e0e0', cursor: 'pointer' }}
                  >
                    <img
                      src={`https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=SC&id=${product.filekey}`}
                      style={{ width: 100, height: 100, margin: 'auto' }}
                    />
                  </StyledTableCell>
                ))}
              </TableRow>
              <TableRow>
                {sortedProducts.map((product, index) => (
                  <StyledTableCell
                    key={index}
                    onClick={() => {
                      moveProductDetailClickHandler(product.pdtid, product.pdttype);
                    }}
                    sx={{ minWidth: 170, width: 170, borderRight: '1px solid #e0e0e0', cursor: 'pointer' }}
                  >
                    <Typography variant="subtitle1">{product.pdtname}</Typography>
                  </StyledTableCell>
                ))}
              </TableRow>
              <TableRow>
                {sortedProducts.map((product, index) => (
                  <StyledTableCell
                    key={index}
                    onClick={() => {
                      moveProductDetailClickHandler(product.pdtid, product.pdttype);
                    }}
                    sx={{ minWidth: 170, width: 170, borderRight: '1px solid #e0e0e0', cursor: 'pointer' }}
                  >
                    <Typography variant="subtitle1">
                      {product.price === 0
                        ? '별도견적 상품'
                        : product.cnt === 0
                        ? '상품 준비중'
                        : fNumberWithCommas(product.price) + '원'}
                    </Typography>
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </MainCard>
    </>
  );
}
