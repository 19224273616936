import MainCard from '../../theme/MainCard';
import { Box, Avatar, Typography } from '@mui/material';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';

// 현황판 총 발전량 컴포넌트
export default function PlantTotoalDevelopmentCard_02({ totalValue, isIpad, isNexthub }) {
  return (
    <>
      <MainCard
        sx={{
          borderRadius: '15px',
          boxShadow: '0px 3px 100px #00000014',
          height: 309,
        }}
      >
        <Typography variant="h5" sx={{ color: '#000' }}>
          총 발전량
        </Typography>
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: '#E2E6FB',
            mr: 1,
            mt: 1,
            width: 40,
            height: 40,
          }}
        >
          <ElectricBoltIcon sx={{ color: '#0059B2' }} />
        </Avatar>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            mt: isIpad || isNexthub ? 12 : 10,
          }}
        >
          <Typography variant={isIpad || isNexthub ? 'h3' : 'h2'} sx={{ color: '#0059B2' }}>
            {totalValue}
          </Typography>
          <Typography variant={isIpad || isNexthub ? 'h5' : 'h4'} sx={{ mt: 2 }}>
            MWh
          </Typography>
        </Box>
      </MainCard>
    </>
  );
}
