import { Box, Divider, Typography, Stack, IconButton, Popover, alpha, Button, Tooltip } from '@mui/material';
import { useContext, useState } from 'react';
import LogOutDetail from './Detail/LogOutDetail';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import AuthContext from '../../store/auth-context';
import OrderInfoDetail from './Detail/OrderInfoDetail';
import AddPlantDetail from './Detail/AddPlantDetail';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PushSettingDetail from './Detail/PushSettingDetail';
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
import InputSubscribeKeyDetail from './Detail/InputSubscribeKeyDetail';
import ConnectionPlantDetail from './Detail/ConnectionPlantDetail';
import UserDeleteAnnouncementDetail from './Detail/UserDeleteAnnouncementDetail';
import useBackendV2 from '../../hooks/use-backend-v2';
import CustomerServiceDetail from './Detail/CustomerServiceDetail';
import CustomerCallServiceGuideModal from '../Layout/CustomerCallServiceGuideModal';
import AppDetail from './Detail/AppDetail';
import { useCookies } from 'react-cookie';
import { Menu as MenuIcon } from '@mui/icons-material';
import AllInverterDetail from './Detail/AllInverterDetail';
import AllStatisticsDetail from './Detail/AllStatisticsDetail';
import AllAlarmDetail from './Detail/AllAlarmDetail';
import AllBulletinboardDetail from './Detail/AllBulletinboardDetail';
import AllStoreMallDetail from './Detail/AllStoreMallDetail';

// 회원정보 수정등 사용자 설정을 위한 컴포넌트
export default function AccountPopover({
  value,
  setValue,
  subValue,
  setSubValue,
  isDesktop,
  isMobileViewDNE,
  setIsMobileViewDNE,
  pageNm,
}) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const backendRequest = useBackendV2();

  const [open, setOpen] = useState(null);
  const [showOrderInfo, setShowOrderInfo] = useState(false);
  const [callServiceOpen, setCallServiceOpen] = useState(false);

  const [cookies, setCookie, removeCookie] = useCookies(['orderid']);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const inverterDetailClickHandler = () => {
    setValue(1);
    localStorage.setItem('menuValue', 1);
    setSubValue(0);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.inverters, { state: { subValue: 0 } });
  };

  const statisticsDetailClickHandler = () => {
    setValue(2);
    localStorage.setItem('menuValue', 2);
    setSubValue(0);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.statistics, { state: { subValue: 0 } });
  };

  const alarmDetailClickHandler = () => {
    setValue(3);
    localStorage.setItem('menuValue', 3);
    setSubValue(0);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.alarms, { state: { subValue: 0 } });
  };

  const bulletinboardDetailClickHandler = () => {
    setValue(4);
    localStorage.setItem('menuValue', 4);
    setSubValue(2);
    localStorage.setItem('menuSubValue', 2);
    navigate(config.frontend.bulletinboards, { state: { subValue: 2 } });
  };

  const storeMallDetailClickHandler = () => {
    setValue(6);
    localStorage.setItem('menuValue', 6);
    localStorage.removeItem('selectPlantkey');
    setSubValue(0);
    navigate(config.frontend.products, { state: { value: 6, subValue: 0 } });
  };

  const pushSettingClickHandler = () => {
    setValue(7);
    localStorage.setItem('menuValue', 7);
    setSubValue(0);
    navigate(config.frontend.pushsettings, { state: { value: 7, subValue: 0 } });
  };

  const orderInfoClickHandler = () => {
    setValue(8);
    localStorage.setItem('menuValue', 8);
    localStorage.removeItem('selectPlantkey');
    setSubValue(0);
    navigate(`${config.frontend.orderinfos}/${authCtx.currentUser.id}`);
  };

  const addPlantClickHandler = () => {
    setValue(9);
    localStorage.setItem('menuValue', 9);
    localStorage.removeItem('selectPlantkey');
    setSubValue(0);
    navigate(`${config.frontend.addPlants}`);
  };

  const addSubscribeKeyClickHandler = () => {
    setValue(10);
    localStorage.setItem('menuValue', 10);
    setSubValue(0);
    navigate(`${config.frontend.addSubscribeKey}`);
  };

  const connectionPlantClickHandler = () => {
    setValue(11);
    localStorage.setItem('menuValue', 11);
    localStorage.removeItem('selectPlantkey');
    setSubValue(0);
    navigate(`${config.frontend.connectionPlants}`);
  };

  const openShowUpdateUserClickHandler = () => {
    localStorage.removeItem('selectPlantkey');
    navigate('/updateUser');
    handleClose();
  };

  const userDeleteAnnouncementDetailClickHandler = () => {
    localStorage.removeItem('selectPlantkey');
    navigate('/deleteAnnouncement');
  };

  const logOutDetailClickHandler = () => {
    const androidFunc = () => window.QWebApp.pushDeactive('logout');
    const iOSFunc = () => window.webkit.messageHandlers.pushDeactive.postMessage('logout');

    if (authCtx.isAndroid) {
      androidFunc();
    } else if (authCtx.isiOS) {
      iOSFunc();
    }

    localStorage.clear();
    removeCookie('orderid');
    authCtx.logout();
    navigate('/login02');
  };

  const connectNewPlantInfoClickHandler = async () => {
    try {
      const requestConfig = {
        method: 'POST',
        body: {
          userId: authCtx?.currentUser?.id,
        },
      };

      const result = await backendRequest.sendRequest(
        config.backend.endPoint.plants.connectNewPlantInfo,
        requestConfig
      );

      if (result.data === true) {
        alert('전체 발전소 최신화가 완료되었습니다');
      } else {
        alert('전체 발전소 최신화가 이미 되어있습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const openCustomerServiceDetailClickHandler = () => {
    setCallServiceOpen(true);
  };

  const closeModalHandler = () => {
    setCallServiceOpen(false);
  };

  const renderAppDetail = () => {
    let showAppDetail = false;

    if (authCtx.isAndroid) {
      const match = navigator.userAgent.match(/AndroidV(\d+,\d+|\d+)/);
      if (match) {
        const android = match[1];
        const appVer = android.includes(',') ? android.split(',')[1] : null;
        if (appVer && parseInt(appVer) >= 9) {
          showAppDetail = true;
        }
      }
    } else if (authCtx.isiOS) {
      const match = navigator.userAgent.match(/IOSV(\d+,\d+|\d+)/);
      if (match) {
        const ios = match[1];
        const appVer = ios.includes(',') ? ios.split(',')[1] : null;
        if (appVer && parseInt(appVer) >= 4) {
          showAppDetail = true;
        }
      }
    }

    return showAppDetail ? (
      <>
        <AppDetail isMobileViewDNE={isMobileViewDNE} setIsMobileViewDNE={setIsMobileViewDNE} />
        <Divider />
      </>
    ) : null;
  };

  return (
    <>
      <Tooltip title="사용자 메뉴" arrow>
        {pageNm === 'allStatus' ? (
          <IconButton onClick={handleOpen} color="inherit" size="large">
            <MenuIcon sx={{ fontSize: '2.3rem' }} />
          </IconButton>
        ) : (
          <IconButton
            onClick={handleOpen}
            sx={{
              p: 0,
              ...(open && {
                '&:before': {
                  zIndex: 1,
                  content: "''",
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  position: 'absolute',
                  bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
                },
              }),
            }}
          >
            <AccountCircleSharpIcon sx={{ fontSize: isDesktop ? '2.4rem' : '2rem', color: '#fff' }} />
          </IconButton>
        )}
      </Tooltip>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 350,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box display="flex" alignItems="center" sx={{ my: 1.5, px: 1.5 }}>
            <AccountCircleIcon fontSize="large" sx={{ color: '#b0b0b0', mr: 1 }} />
            <Typography variant="subtitle2" noWrap>
              ID: {authCtx?.currentUser?.id}
            </Typography>
          </Box>
          <Box>
            {authCtx?.currentUser?.id === 'ssadmin' ? (
              <Button
                variant="contained"
                size="small"
                onClick={connectNewPlantInfoClickHandler}
                sx={{ mr: 0.5 }}
              >
                발전소 연동
              </Button>
            ) : null}
            <Button variant="outlined" size="small" onClick={openShowUpdateUserClickHandler} sx={{ mr: 1 }}>
              회원정보 수정
            </Button>
          </Box>
        </Box>
        <Divider />
        <Stack sx={{ p: 1 }}>
          {renderAppDetail()}
          {pageNm === 'allStatus' ? (
            <>
              <AllInverterDetail inverterDetailClickHandler={inverterDetailClickHandler} />
              <AllStatisticsDetail statisticsDetailClickHandler={statisticsDetailClickHandler} />
              <AllAlarmDetail alarmDetailClickHandler={alarmDetailClickHandler} />
              <AllBulletinboardDetail bulletinboardDetailClickHandler={bulletinboardDetailClickHandler} />
              {process.env.REACT_APP_STORE_OPEN === 'true' ? (
                <AllStoreMallDetail storeMallDetailClickHandler={storeMallDetailClickHandler} />
              ) : null}
              <Divider />
            </>
          ) : null}
          <PushSettingDetail pushSettingClickHandler={pushSettingClickHandler} />
          {/* <HelpDetail helpDetailClickHandler={helpDetailClickHandler} helpDetailSelect={helpDetailSelect} /> */}
          <AddPlantDetail addPlantClickHandler={addPlantClickHandler} />
          <ConnectionPlantDetail connectionPlantClickHandler={connectionPlantClickHandler} />
          {/* <AddRtuDetail addRtuClickHandler={addRtuClickHandler} /> */}
          <InputSubscribeKeyDetail addSubscribeKeyClickHandler={addSubscribeKeyClickHandler} />
          <OrderInfoDetail showOrderInfo={showOrderInfo} orderInfoClickHandler={orderInfoClickHandler} />
        </Stack>
        <Divider />
        <CustomerServiceDetail
          openCustomerServiceDetailClickHandler={openCustomerServiceDetailClickHandler}
        />
        <UserDeleteAnnouncementDetail
          userDeleteAnnouncementDetailClickHandler={userDeleteAnnouncementDetailClickHandler}
        />
        <LogOutDetail logOutDetailClickHandler={logOutDetailClickHandler} />
      </Popover>
      {callServiceOpen && (
        <CustomerCallServiceGuideModal open={callServiceOpen} onClose={closeModalHandler} />
      )}
    </>
  );
}
