import { useState } from 'react';
import { Button, ButtonGroup, Paper, TextField } from '@mui/material';
import RequestTaxBillCreateModal from './RequestTaxBillCreateModal';
import RequestTaxBillSearchModal from './RequestTaxBillSearchModal';

// 회원 가입 내 세금 계산서 입력 정보 뷰 컴포넌트
export default function RequestTaxBillFrom({
  reqTaxBillCheck,
  businessNoIsNotDuplicate,
  setBusinessNoIsNotDuplicate,
  companyNameInput,
  addressInput,
  addressDetailInput,
  zipCodeInput,
  companyPhoneInput,
  companyFaxInput,
  companyEmailInput,
  businessNoInput,
  representativeNameInput,
  typeOfBusinessInput,
  itemOfBusinessInput,
  businessAreaInput,
  etcInput,
  setCompanyKey,
}) {
  const [companyCreateModal, setCompanyCreateModal] = useState(false);
  const [companySearchModal, setCompanySearchModal] = useState(false);

  const openCreateModalHandler = () => {
    companyNameInput.setValue('');
    addressInput.setValue('');
    addressDetailInput.setValue('');
    zipCodeInput.setValue('');
    companyPhoneInput.setValue('');
    companyFaxInput.setValue('');
    companyEmailInput.setValue('');
    businessNoInput.setValue('');
    representativeNameInput.setValue('');
    typeOfBusinessInput.setValue('');
    itemOfBusinessInput.setValue('');
    businessAreaInput.setValue('');
    etcInput.setValue('');
    setCompanyKey('');
    setCompanyCreateModal(true);
  };
  const closeCreateModalHandler = () => {
    companyNameInput.setValue('');
    addressInput.setValue('');
    addressDetailInput.setValue('');
    zipCodeInput.setValue('');
    companyPhoneInput.setValue('');
    companyFaxInput.setValue('');
    companyEmailInput.setValue('');
    businessNoInput.setValue('');
    representativeNameInput.setValue('');
    typeOfBusinessInput.setValue('');
    itemOfBusinessInput.setValue('');
    businessAreaInput.setValue('');
    etcInput.setValue('');
    setCompanyKey('');
    setBusinessNoIsNotDuplicate(false);
    setCompanyCreateModal(false);
  };
  const confirmCreateModalHandler = () => {
    setCompanyCreateModal(false);
  };

  const openSearchModalHandler = () => {
    setCompanySearchModal(true);
  };
  const closeSearchModalHandler = () => {
    setCompanySearchModal(false);
  };

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          border: '2px solid #D8D8D8',
          alignItems: 'center',
          mb: 1,
        }}
      >
        <ButtonGroup sx={{ mt: 2, width: '95%', height: '40px', mb: 2 }} size="small">
          <Button type="button" sx={{ width: '50%' }} onClick={openSearchModalHandler}>
            회사 조회
          </Button>
          <Button type="button" sx={{ width: '50%' }} onClick={openCreateModalHandler}>
            회사 등록
          </Button>
        </ButtonGroup>
        {companyNameInput.value !== '' && businessNoInput.value !== '' && companyPhoneInput.value !== '' ? (
          <>
            <TextField
              label="회사 이름"
              variant="outlined"
              sx={{ mt: 0.5, backgroundColor: 'white', width: '95%' }}
              value={companyNameInput.value ?? ''}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              label="사업자 등록 번호"
              variant="outlined"
              sx={{ mt: 1, backgroundColor: 'white', width: '95%' }}
              value={businessNoInput.value ?? ''}
              InputProps={{
                readOnly: true,
              }}
            />
            <TextField
              label="대표전화"
              variant="outlined"
              sx={{ mt: 1, mb: 2, backgroundColor: 'white', width: '95%' }}
              value={companyPhoneInput.value ?? ''}
              InputProps={{
                readOnly: true,
              }}
            />
          </>
        ) : null}
      </Paper>
      {companyCreateModal && (
        <RequestTaxBillCreateModal
          businessNoIsNotDuplicate={businessNoIsNotDuplicate}
          setBusinessNoIsNotDuplicate={setBusinessNoIsNotDuplicate}
          companyNameInput={companyNameInput}
          addressInput={addressInput}
          addressDetailInput={addressDetailInput}
          zipCodeInput={zipCodeInput}
          companyPhoneInput={companyPhoneInput}
          companyFaxInput={companyFaxInput}
          companyEmailInput={companyEmailInput}
          businessNoInput={businessNoInput}
          representativeNameInput={representativeNameInput}
          typeOfBusinessInput={typeOfBusinessInput}
          itemOfBusinessInput={itemOfBusinessInput}
          businessAreaInput={businessAreaInput}
          etcInput={etcInput}
          setCompanyKey={setCompanyKey}
          open={companyCreateModal}
          onClose={closeCreateModalHandler}
          confirm={confirmCreateModalHandler}
        />
      )}
      {companySearchModal && (
        <RequestTaxBillSearchModal
          companyNameInput={companyNameInput}
          businessNoInput={businessNoInput}
          companyPhoneInput={companyPhoneInput}
          setCompanyKey={setCompanyKey}
          open={companySearchModal}
          onClose={closeSearchModalHandler}
        />
      )}
    </>
  );
}
