import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'grey',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

// 접속반 테이블 컴포넌트
export default function JboxTable({ data }) {
  const columns = [
    { field: 'chid', headerName: '채널', width: 100 },
    { field: 'curt', headerName: '전류(A)', width: 100 },
    { field: 'developPercent', headerName: '출력(%)', width: 100 },
  ];

  return (
    <>
      <StyledTableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.field}
                  align="center"
                  style={{ minWidth: column.width, backgroundColor: '#2065D1', borderRight: '1px solid #d0d0d0' }}
                >
                  {column.headerName}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => {
              if (!Object.keys(row).length) {
                return null;
              }

              return (
                <TableRow key={index}>
                  {columns.map((column, columnIndex) => (
                    <TableCell
                      key={column.field}
                      align="center"
                      style={{
                        borderRight: columnIndex === columns.length - 1 ? 'none' : '1px solid #d0d0d0',
                        backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                      }}
                    >
                      {column.field === 'curt' || column.field === 'developPercent'
                        ? Number(row[column.field]).toFixed(1)
                        : column.field === 'chid'
                          ? 'CH ' + row[column.field]
                          : row[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
}
