import { useContext, useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import BaseLayout from './BaseLayout';

// 로그인된 사용자에게는 보호된 컨텐츠를 제공하고, 로그인되지 않은 사용자에게는 로그인 페이지로 리다이렉션하는 역할을 하는 컴포넌트
export default function PrivateOutlet() {
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  useEffect(() => {
    // isAndroid와 isiOS는 authCtx에서 가져온다.
    const { isAndroid, isiOS, currentUser } = authCtx;

    // Android인 경우
    const androidFunc = () => window.QWebApp.pushSetting(currentUser.id);

    // iOS인 경우
    const iOSFunc = () => window.webkit.messageHandlers.pushSetting.postMessage(currentUser.id);

    // 동적으로 함수 호출
    if (isAndroid) {
      androidFunc();
    }

    if (isiOS) {
      iOSFunc();
    }
  }, [authCtx]);

  return authCtx.isLoggedIn ? <BaseLayout /> : <Navigate to="/login02" state={{ from: location }} replace />;
}
