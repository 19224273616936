import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';

export default function BulletinboardMenu({
  value,
  setValue,
  subValue,
  setSubValue,
  bulletinboardClickHandler,
  bulletinboardSelect,
  webNavOpen,
  onClose,
}) {
  const navigate = useNavigate();

  // const listClickHandler = () => {
  //   setValue(4);
  //   localStorage.setItem('menuValue', 4);
  //   setSubValue(0);
  //   localStorage.setItem('menuSubValue', 0);
  //   localStorage.removeItem('selectPlantkey');
  //   navigate(config.frontend.bulletinboards, { state: { subValue: 0 } });
  //   onClose();
  // };

  const noticeClickHandler = () => {
    setValue(4);
    localStorage.setItem('menuValue', 4);
    setSubValue(0);
    localStorage.setItem('menuSubValue', 0);
    localStorage.removeItem('selectPlantkey');
    navigate(config.frontend.bulletinboards, { state: { subValue: 0 } });
    onClose();
  };

  const archiveClickHandler = () => {
    setValue(4);
    localStorage.setItem('menuValue', 4);
    setSubValue(1);
    localStorage.setItem('menuSubValue', 1);
    localStorage.removeItem('selectPlantkey');
    navigate(config.frontend.bulletinboards, { state: { subValue: 1 } });
    onClose();
  };

  const inquiryClickHandler = () => {
    setValue(4);
    localStorage.setItem('menuValue', 4);
    setSubValue(2);
    localStorage.setItem('menuSubValue', 2);
    navigate(config.frontend.bulletinboards, { state: { subValue: 2 } });
    onClose();
  };
  return (
    <List component="nav">
      <ListItemButton onClick={bulletinboardClickHandler}>
        <ListItemIcon>
          <ContentPasteIcon />
        </ListItemIcon>
        {webNavOpen ? <ListItemText primary="게시판" /> : null}
        {webNavOpen ? bulletinboardSelect ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>
      <Collapse in={bulletinboardSelect} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 4 && subValue === 0 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={noticeClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <ContentPasteGoIcon sx={{ color: value === 4 && subValue === 0 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 4 && subValue === 0 ? '#3A3DFF' : null,
                  fontWeight: value === 4 && subValue === 0 ? 'bold' : null,
                }}
              >
                공지 게시판
              </Typography>
            ) : null}
          </ListItemButton>
        </List>
        {/* <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 4 && subValue === 0 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={listClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <ContentPasteSearchIcon sx={{ color: value === 4 && subValue === 0 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 4 && subValue === 0 ? '#3A3DFF' : null,
                  fontWeight: value === 4 && subValue === 0 ? 'bold' : null,
                }}
              >
                요청 게시판
              </Typography>
            ) : null}
          </ListItemButton>
        </List> */}
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 4 && subValue === 1 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={archiveClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <FileCopyIcon sx={{ color: value === 4 && subValue === 1 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 4 && subValue === 1 ? '#3A3DFF' : null,
                  fontWeight: value === 4 && subValue === 1 ? 'bold' : null,
                }}
              >
                자료실
              </Typography>
            ) : null}
          </ListItemButton>
        </List>
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 4 && subValue === 2 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={inquiryClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <QuestionAnswerOutlinedIcon sx={{ color: value === 4 && subValue === 2 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 4 && subValue === 2 ? '#3A3DFF' : null,
                  fontWeight: value === 4 && subValue === 2 ? 'bold' : null,
                }}
              >
                통합 문의 게시판
              </Typography>
            ) : null}
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}
