import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  MenuItem,
} from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import { useLocation, useNavigate } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../store/auth-context';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme } from '@mui/material/styles';
import Header02 from '../Layout/Header02';
import SubHeader from '../Layout/SubHeader';
import Nav from '../Layout/Nav';
import useResponsive from '../../hooks/useResponsive';

/*
통합문의 등록 컴포넌트
*/
export default function InquiryBoardAdd() {
  const QA = useLocation();

  const isDesktop = useResponsive('up', 'lg');
  const authCtx = useContext(AuthContext);
  const loggedId = authCtx?.currentUser?.id;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  // 문의 구분
  const gubun = [
    { value: 'QA01', label: '모니터링 관련 문의' },
    { value: 'QA02', label: '사용자 문의' },
    { value: 'QA03', label: '상품 문의' },
    { value: 'QA04', label: '결제 문의' },
    { value: 'QA05', label: '기타 문의' },
    { value: 'QA06', label: '세금계산서 발행 요청' },
  ];

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));

  const distance = 0;

  const [gubunCcd, setGubunCcd] = useState('any');
  const [title, setTitle] = useState('');
  const [questionContent, setQuestionContent] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileKey, setFileKey] = useState(0);
  const [fileNm, setFileNm] = useState('');
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  function handleGoList() {
    navigate(`${config.frontend.bulletinboards}`, { state: { value: 4, subValue: 3 } });
  }

  function handleGubunCcdChange(e) {
    setGubunCcd(e.target.value);
  }

  function handleTitleChange(e) {
    setTitle(e.target.value);
  }

  function handleQuestionContentChange(e) {
    setQuestionContent(e.target.value);
  }

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const backendRequest = useBackendV2();

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file first!');
      return;
    }

    const formData = new FormData();

    formData.append('file', selectedFile);
    formData.append('Filekey', fileKey === null || fileKey === 0 ? 0 : fileKey);
    formData.append('FileType', 2);
    formData.append('Category', 'board|inquiryFile');
    try {
      const fileRequest = {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${authCtx.token.accessToken.token}`,
          Accept: 'multipart/form-data',
        },
        body: formData,
      };

      const fileResponse = await fetch(
        `${config.backend.host}${config.backend.endPoint.files.file}`,
        fileRequest
      );

      const data = await fileResponse.json();
      if (data.filekey === -1) {
        setIsFileUpload(false);
        setFileKey(-1);
        setFileNm('');
        // fileKey = -1;
      } else {
        setIsFileUpload(true);
        setFileKey(data.filekey);
        setFileNm(formData.get('file').name);
        // fileKey = data.filekey;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleAdd = async () => {
    if (gubunCcd === 'any' || gubunCcd === null) {
      alert('문의구분을 선택하세요');
      return;
    }
    if (title === '' || title === null) {
      alert('제목을 입력하세요');
      return;
    }
    if (questionContent === '' || questionContent === null) {
      alert('문의 내용을 입력하세요.');
      return;
    }

    if (isFileUpload) {
      try {
        const requestConfig = {
          method: 'POST',
          body: {
            gubunCcd: gubunCcd,
            title: title,
            questionContent: questionContent,
            regId: loggedId,
            udtId: loggedId,
            fileKey: fileKey === -1 ? 0 : fileKey,
            fileNm: fileNm,
          },
        };
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.bulletinBoard.inquiryAdd}/${loggedId}`,
          requestConfig
        );
        setSelectedFile(null);
        alert('등록하였습니다.');
        navigate(`${config.frontend.bulletinboards}`, { state: { value: 4, subValue: 3 } });
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    } else {
      try {
        const requestConfig = {
          method: 'POST',
          body: {
            gubunCcd: gubunCcd,
            title: title,
            questionContent: questionContent,
            regId: loggedId,
            udtId: loggedId,
            fileKey: 0,
            fileNm: '',
          },
        };
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.bulletinBoard.inquiryAdd}/${loggedId}`,
          requestConfig
        );
        setSelectedFile(null);
        alert('등록하였습니다.');
        navigate(`${config.frontend.bulletinboards}`, { state: { value: 4, subValue: 3 } });
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  // 모바일 반응형을 위한 상수 선언
  const isSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 513px)');
  const isMoreSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 385px)');

  useEffect(() => {
    if (QA.state !== null && QA.state === 6) {
      setGubunCcd('QA06');
    }
  }, [QA]);

  useEffect(() => {
    fetchUserStylePreference();
  }, []);

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      {isMobile ? (
        <>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isSmallDevice ? 'column' : null,
            }}
          >
            <Grid
              item
              xs={isSmallDevice ? 12 : 1}
              sx={{
                display: 'flex',
                alignItems: isSmallDevice ? 'left' : 'center',
                justifyContent: isSmallDevice ? 'flex-start' : 'flex-end',
              }}
            >
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'flex',
                  },
                }}
              >
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                  <StyledBreadcrumb
                    label={isMoreSmallDevice ? '통합문의 게시판' : '게시판'}
                    icon={<ContentPasteIcon fontSize="small" color="primary" />}
                    galaxyFoldFontSize={isMoreSmallDevice ? '14px' : '15px'}
                  />
                  <StyledBreadcrumb
                    label={isMoreSmallDevice ? '작성 내용' : '통합문의 게시판'}
                    galaxyFoldFontSize={isMoreSmallDevice ? '14px' : '15px'}
                  />
                  {isMoreSmallDevice ? null : <StyledBreadcrumb label="작성 내용" />}
                </Breadcrumbs>
              </Box>
            </Grid>
            <Grid
              item
              xs={isSmallDevice ? 12 : 1}
              sx={{
                display: 'flex',
                alignItems: isSmallDevice ? 'right' : 'center',
                justifyContent: isSmallDevice ? 'flex-end' : 'flex-start',
              }}
            >
              <Button
                onClick={handleGoList}
                endIcon={<ArrowForwardIcon />}
                sx={{
                  ml: 1,
                  mr: 2,
                  color: '#000',
                  textTransform: 'none',
                  mt: isSmallDevice ? 2 : 0,
                }}
              >
                <Typography variant="subtitle1">돌아가기</Typography>
              </Button>
            </Grid>
          </Grid>
          <Box
            component="form"
            sx={{
              mt: isSmallDevice ? 0 : 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'left',
              width: '100%',
            }}
          >
            <>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                    mb: 1,
                  }}
                >
                  문의 구분 *
                </Typography>
                <TextField
                  select
                  variant="outlined"
                  size="Normal"
                  sx={{ width: '200px' }}
                  name="gubunCcd"
                  value={gubunCcd}
                  onChange={handleGubunCcdChange}
                >
                  <MenuItem value="any">전체</MenuItem>
                  {gubun.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  제목 *
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  sx={{ mt: 1, width: '100%' }}
                  name="title"
                  value={title}
                  onChange={handleTitleChange}
                  autoComplete="off"
                  placeholder="제목을 입력해주세요. ＊"
                />
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  문의 *
                </Typography>
                <TextField
                  label="문의"
                  variant="outlined"
                  required
                  multiline
                  rows="10"
                  sx={{ mt: 1, width: '100%', whiteSpace: 'pre-wrap' }}
                  onChange={handleQuestionContentChange}
                  name="questionContent"
                  value={questionContent}
                  autoComplete="off"
                />
                {gubunCcd === 'QA06' ? (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="subtitle2" sx={{ color: 'red' }}>
                      ※ 세금 계산서 발행 요청시
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: 'red' }}>
                      발전소이름, 사업자등록번호, 대표자 이름, 이메일, 전화번호, 업태, 업종, 주소를 꼭 입력해
                      주세요!
                    </Typography>
                  </Box>
                ) : null}
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                    mb: 1,
                  }}
                >
                  파일 업로드
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                  <TextField fullWidth type="file" onChange={handleFileChange} sx={{ mb: 2 }} />
                  <Button variant="contained" color="primary" onClick={handleUpload} disabled={!selectedFile}>
                    {!selectedFile ? '업로드 가능' : '업로드 완료'}
                  </Button>
                </Box>
              </CardContent>
              <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleGoList}
                  sx={{
                    mr: 4,
                    background: '#E5EEF7 0% 0% no-repeat padding-box',
                    color: '#0059B2',
                  }}
                >
                  목록
                </Button>
                <Button
                  variant="contained"
                  onClick={handleAdd}
                  sx={{
                    mr: 0,
                    background: '#0059B2 0% 0% no-repeat padding-box',
                    color: '#FFFFFF',
                  }}
                >
                  등록
                </Button>
              </Box>
            </>
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <StyledBreadcrumb label="게시판" icon={<ContentPasteIcon fontSize="small" color="primary" />} />
              <StyledBreadcrumb label="통합문의 게시판" />
              <StyledBreadcrumb label="등록" />
            </Breadcrumbs>
            <Button
              onClick={handleGoList}
              endIcon={<ArrowForwardIcon />}
              sx={{ textTransform: 'none', ml: 1, mr: 2, color: '#000' }}
            >
              <Typography variant="subtitle1">돌아가기</Typography>
            </Button>
          </Box>
          <Box
            component="form"
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'left',
              width: '100%',
            }}
          >
            <>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                    mb: 1,
                  }}
                >
                  문의 구분 *
                </Typography>
                <TextField
                  select
                  variant="outlined"
                  size="Normal"
                  sx={{ width: '200px' }}
                  name="gubunCcd"
                  value={gubunCcd}
                  onChange={handleGubunCcdChange}
                >
                  <MenuItem value="any">전체</MenuItem>
                  {gubun.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  제목 *
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  sx={{ mt: 1, width: '100%' }}
                  name="title"
                  value={title}
                  onChange={handleTitleChange}
                  autoComplete="off"
                  placeholder="제목을 입력해주세요. ＊"
                />
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  문의 *
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  multiline
                  rows="10"
                  sx={{ mt: 1, width: '100%', whiteSpace: 'pre-wrap' }}
                  onChange={handleQuestionContentChange}
                  name="questionContent"
                  value={
                    questionContent === null || questionContent.length === 0
                      ? ''
                      : questionContent.replace(/<br\s\/>/g, '\n')
                  }
                />
                {gubunCcd === 'QA06' ? (
                  <Box sx={{ mt: 1 }}>
                    <Typography variant="subtitle1" sx={{ color: 'red' }}>
                      ※ 세금 계산서 발행 요청시 발전소이름, 사업자등록번호, 대표자 이름, 이메일, 전화번호,
                      업태, 업종, 주소를 꼭 입력해 주세요!
                    </Typography>
                  </Box>
                ) : null}
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                    mb: 1,
                  }}
                >
                  파일 업로드
                </Typography>
                <Grid container alignItems="center" spacing={2}>
                  <Grid item xs>
                    <TextField fullWidth type="file" onChange={handleFileChange} />
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpload}
                      disabled={!selectedFile}
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        height: '100%',
                        width: '120px',
                      }}
                    >
                      <Typography sx={{ textAlign: 'center', whiteSpace: 'pre-line', lineHeight: '1.5em' }}>
                        {!selectedFile ? '업로드\n가능' : '업로드\n완료'}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </>
            <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
              <Button
                variant="contained"
                onClick={handleGoList}
                sx={{
                  mr: 4,
                  background: '#E5EEF7 0% 0% no-repeat padding-box',
                  color: '#0059B2',
                }}
              >
                목록
              </Button>
              <Button
                variant="contained"
                onClick={handleAdd}
                sx={{
                  mr: 0,
                  background: '#0059B2 0% 0% no-repeat padding-box',
                  color: '#FFFFFF',
                }}
              >
                등록
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
