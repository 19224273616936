import { Button } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import loginImage from '../../../assets/KakaoImg/kakao_login_large00.png';
import useBackendV2 from '../../../hooks/use-backend-v2';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../../store/auth-context';
import SocialLoginModal from './SocialLoginModal';
import config from '../../../config';

// 카카오 소셜 로그인 컴포넌트
export default function KakaoLogin({ id, isLoginK, setIsLoginK }) {
  const { Kakao } = window;

  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  const { isAndroid, isiOS } = authCtx;

  const loginRequest = useBackendV2();
  const checkSocialIdRequest = useBackendV2();
  const socialLoginRequest = useBackendV2();
  const backendRequest = useBackendV2();

  let from = location.state?.from?.pathname || '/';

  const kakaoUserInfo = {
    account: '',
    id: '',
    email: '',
    nickname: '',
    phone: '',
  };

  const [showModal, setShowModal] = useState(false);
  const [kakaoAccessToken, setKakaoAccessTokent] = useState();
  const [socialUserInfo, setSocialUserInfo] = useState(kakaoUserInfo);

  const rest_api_key = process.env.REACT_APP_KAKAO_LOGIN_REST_API_KEY;

  const redirect_uri =
    isAndroid || isiOS
      ? process.env.REACT_APP_KAKAO_LOGIN_MOBILE_REDIRECT_URI
      : process.env.REACT_APP_KAKAO_LOGIN_WEB_REDIRECT_URI;

  const openModalHandler = () => {
    setShowModal(true);
  };
  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    localStorage.clear();
    setShowModal(false);
  };

  const fetchGetKakaoAccessToken = async () => {
    try {
      const requestConfig = {
        method: 'POST',
        body: {
          clientId: rest_api_key,
          redirectUri: redirect_uri,
        },
      };
      const result = await backendRequest.sendRequest(
        `${config.backend.endPoint.authentication.kakaoAccessToken}/${code}`,
        requestConfig,
        false
      );

      setKakaoAccessTokent(result.data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchGetKakaoUserInfo = async () => {
    try {
      const requestConfig = {
        method: 'POST',
      };
      const result = await backendRequest.sendRequest(
        `${config.backend.endPoint.authentication.kakaoUserInfo}/${kakaoAccessToken.kakaoAccessToken}`,
        requestConfig,
        false
      );

      setSocialUserInfo((prevPage) => ({
        ...prevPage,
        account: result.data.kakao_account,
        id: result.data.id,
        email: result.data.kakao_account.email,
        nickname: result.data.kakao_account.profile.nickname,
        phone: null,
      }));
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchKakaoSocialLogin = async () => {
    try {
      const queryStringArr = [`id=${id}`, `smid=${socialUserInfo.id}`, `smtype=${2}`];
      const queryString = queryStringArr.join('&');
      const checkResult = await checkSocialIdRequest.sendRequest(
        `${config.backend.endPoint.authentication.smcheck}?${queryString}`,
        null,
        false
      );
      if (checkResult.data === true) {
        const loginRequestConfig = {
          method: 'POST',
          body: {
            smtype: 2,
            smid: socialUserInfo.id.toString(),
          },
        };
        const result = await socialLoginRequest.sendRequest(
          config.backend.endPoint.authentication.smlogin,
          loginRequestConfig,
          false
        );
        authCtx.login(result.data);
        navigate(from, { replace: true });
        localStorage.removeItem('smid');
      } else {
        setShowModal(true);
        localStorage.removeItem('smid');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  // 1. 카카오 코드 발급
  const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${rest_api_key}&redirect_uri=${redirect_uri}&response_type=code`;

  const code = new URL(window.location.href).searchParams.get('code');

  // 2. 카카오 코드를 이용한 토큰 발급
  useEffect(() => {
    if (code !== null) {
      fetchGetKakaoAccessToken();
    }
  }, [code]);

  // 3. 카카오 토큰을 이용한 유저 정보 발급
  useEffect(() => {
    if (kakaoAccessToken !== undefined) {
      fetchGetKakaoUserInfo();
    }
  }, [kakaoAccessToken]);

  // 4. 카카오 유저 정보로 로그인 진행
  useEffect(() => {
    if (socialUserInfo.account !== '') {
      fetchKakaoSocialLogin();
    }
  }, [socialUserInfo]);

  const kakaoLogin = async () => {
    window.location.href = kakaoURL;
  };

  return (
    <>
      <Button
        sx={{
          width: '100%',
          // height: '50px',
          height: '55px',
          backgroundImage: `url(${loginImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'contain',
          backgroundColor: '#FEE500',
          border: '2px solid #FEE500',
          borderRadius: '12px',
          '&:hover': { backgroundColor: '#FEE500', border: '2px solid #FEE500' },
        }}
        onClick={kakaoLogin}
      />
      {showModal && (
        <SocialLoginModal
          social="kakao"
          socialUserInfo={socialUserInfo}
          open={showModal}
          onClose={closeModalHandler}
        />
      )}
    </>
  );
}
