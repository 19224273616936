import { Box, Avatar, Typography, Card } from '@mui/material';
import Co2Icon from '@mui/icons-material/Co2';
import ForestIcon from '@mui/icons-material/Forest';
import { useEffect, useState } from 'react';

// 개별 발전소 온실가스 및 나무 계산 컴포넌트
export default function WebWidgetCo2Info({ roundedTotalGhgReduction, roundedTotalPineTree }) {
  const [currentData, setCurrentData] = useState(roundedTotalGhgReduction);
  const [currentFormCO2, setCurrentFormCO2] = useState(true);

  useEffect(() => {
    setCurrentData(() => (currentFormCO2 ? roundedTotalGhgReduction : roundedTotalPineTree));
  }, [currentFormCO2, roundedTotalGhgReduction, roundedTotalPineTree]);

  useEffect(() => {
    const dataInterval = setInterval(() => {
      setCurrentFormCO2((prevForm) => !prevForm);
    }, 3500);

    // 컴포넌트가 마운트 해제되면 타이머 클리어
    return () => {
      clearInterval(dataInterval);
    };
  }, []);

  return (
    <Card
      sx={{
        // bgcolor: '#0059B2',
        borderRadius: '10px',
        boxShadow: '5px 6px 12px 5px #00000014',
        p: 1,
        height: 80,
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: 1.2 }}>
        <Avatar
          variant="rounded"
          sx={{
            bgcolor: currentFormCO2 ? '#E2E6FB' : '#C0F4D2',
            width: 40,
            height: 40,
            ml: 0.5,
          }}
        >
          {currentFormCO2 ? (
            <Co2Icon fontSize="large" sx={{ color: '#0059B2' }} />
          ) : (
            <ForestIcon sx={{ color: '#41915D' }} />
          )}
        </Avatar>
        <Typography variant="h4" sx={{ color: currentFormCO2 ? '#0059B2' : '#41915D', mr: 0.2 }}>
          {currentData}
          <span style={{ color: 'black', fontSize: 16, marginLeft: 3 }}>
            {currentFormCO2 ? 'tCO2eq' : '천 그루'}
          </span>
        </Typography>
      </Box>
    </Card>
  );
}
