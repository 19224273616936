import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import UpdateUserCmp from './UpdateUserCmp';
import { useContext, useEffect, useState } from 'react';
import ViewTermsModal from '../Terms/ViewTermsModal';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import AuthContext from '../../store/auth-context';
import useInput from '../../hooks/use-input';
import {
  validateBusinessNo,
  validateEmail,
  validateFax,
  validateId,
  validateName,
  validateOfficePhone,
  validatePassword,
  validatePhone,
} from '../../utils/validationUtil';
import { useNavigate } from 'react-router-dom';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import UpdateTaxBillForm from './UpdateTaxBillForm';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';

// 사용자 정보 수정 컴포넌트
export default function UpdateUser() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [termsModal, setTermsModal] = useState(false);
  const [termsContent, setTermsContent] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [ogKey, setOgKey] = useState(0);
  const [businessNoIsNotDuplicate, setBusinessNoIsNotDuplicate] = useState(false);
  const [reqTaxBillCheck, setReqTaxBillCheck] = useState(false);

  const idInput = useInput((value) => validateId(value));
  const passwordInput = useInput((value) => validatePassword(value));
  const passwordConfirmInput = useInput((value) => passwordInput.value === value);
  const emailInput = useInput((value) => validateEmail(value));
  const nameInput = useInput((value) => validateName(value));
  const hpInput = useInput((value) => validatePhone(value));
  const authInput = useInput((value) => value !== '');

  const formIsValid =
    nameInput.isValid &&
    (passwordInput.value.length === 0 || (passwordInput.value.length > 0 && passwordInput.isValid)) &&
    passwordConfirmInput.isValid &&
    hpInput.isValid;

  // 전문기업명
  const companyNameInput = useInput((value) => value.length > 0);
  // 주소
  const addressInput = useInput();
  // 상세주소 address2
  const addressDetailInput = useInput();
  // 우편번호
  const zipCodeInput = useInput();
  // 회사 대표 전화번호
  const companyPhoneInput = useInput((value) => validateOfficePhone(value));
  // 회사 팩스 번호
  const companyFaxInput = useInput((value) => validateFax(value));
  // 회사 대표 이메일
  const companyEmailInput = useInput((value) => validateEmail(value));
  // 사업자 등록 번호
  const businessNoInput = useInput((value) => validateBusinessNo(value));
  // 대표자 명
  const representativeNameInput = useInput((value) => validateName(value));
  // 업태
  const typeOfBusinessInput = useInput();
  // 종목
  const itemOfBusinessInput = useInput();
  // 관리업체 사업영역
  const businessAreaInput = useInput();
  // 기타
  const etcInput = useInput();

  const fetchTermsRequest = useBackendV2();
  const saveUserRequst = useBackendV2();

  const saveUser = async () => {
    setIsLoading(true);

    const saveUserRequestConfig = {
      method: 'PUT',
      body: {
        uname: nameInput.value.trim(),
        password: passwordInput.value.trim(),
        phone: hpInput.value.trim(),
        email: emailInput.value.trim(),
        ogKey: ogKey,
      },
    };
    const saveUserResult = await saveUserRequst.sendRequest(
      `${config.backend.endPoint.users.update}/${authCtx.currentUser.id}`,
      saveUserRequestConfig
    );

    if (saveUserRequst.error) {
      console.error(saveUserRequst.error);
      alert('오류가 발생했습니다.');
      setIsLoading(false);
      return;
    }

    if (!saveUserResult.data.succeeded) {
      alert('수정에 실패하였습니다.');
      setIsLoading(false);
      return;
    }

    alert('수정이 완료되었습니다.');
    setIsLoading(false);
    // onClose();
    navigate('/', { state: { subValue: 0 } });
  };

  const reqTaxBillCheckChangeHandler = () => {
    setReqTaxBillCheck(!reqTaxBillCheck);
  };

  const closeModalHandler = (event, reason) => {
    localStorage.setItem('menuValue', 0);
    localStorage.setItem('menuSubValue', 0);
    navigate('/', { state: { subValue: 0 } });
  };

  const closeTermsModalClickHandler = () => {
    setTermsModal(false);
  };

  const openServiceUseClickHandler = async () => {
    try {
      const { data } = await fetchTermsRequest.sendRequest(`${config.backend.endPoint.terms.getContent}/1`);
      setTermsContent(data.contents);
      setTermsModal(true);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
    // setTermsKey(1);
  };
  const openCollectUserInfoClickHandler = async () => {
    try {
      const { data } = await fetchTermsRequest.sendRequest(`${config.backend.endPoint.terms.getContent}/2`);
      setTermsContent(data.contents);
      setTermsModal(true);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
    // setTermsKey(2);
  };

  useEffect(() => {
    if (ogKey === 0) {
      setReqTaxBillCheck(false);
    } else {
      setReqTaxBillCheck(true);
    }
  }, [ogKey]);

  return (
    <>
      <Container
        sx={{
          minHeight: '600px',
          minWidth: '50%',
          marginBottom: '4%',
        }}
      >
        {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 4,
          }}
        >
          <img src={SolLogo} alt="SOLARCUBE" style={{ width: 30 }} />
          &nbsp;
          <Typography
            color="inherit"
            sx={{
              display: 'flex',
              fontFamily: '"Segoe UI Symbol"',
              flexGrow: 1,
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            SOLAR CUBE
          </Typography>
        </Box> */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ width: { xs: '100%', md: 500 } }}>
            <Box
              sx={{
                display: 'flex',
                // justifyContent: 'end',
                alignItems: 'center',
                mt: isMobile ? 3 : 5,
              }}
            >
              <KeyboardBackspaceIcon
                onClick={closeModalHandler}
                fontSize="large"
                sx={{ cursor: 'pointer' }}
              />
            </Box>
            <Box
              sx={{
                // mt: isMobile ? 5 : 9,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <ManageAccountsOutlinedIcon
                fontSize="large"
                sx={{
                  mr: 1,
                }}
              />
              <Typography variant="h4" fontWeight="bold">
                회원 정보 수정
              </Typography>
            </Box>
            <Box display="flex" justifyContent="end" sx={{ mt: 0.5 }}>
              <Typography variant="h6" sx={{ mr: 0.5, color: 'red' }}>
                *
              </Typography>
              <Typography variant="body2">는 필수 입력 사항입니다.</Typography>
            </Box>
            <hr />
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <UpdateUserCmp
                idInput={idInput}
                passwordInput={passwordInput}
                passwordConfirmInput={passwordConfirmInput}
                emailInput={emailInput}
                nameInput={nameInput}
                hpInput={hpInput}
                authInput={authInput}
                setOgKey={setOgKey}
              />
              <FormControlLabel
                control={<Checkbox checked={reqTaxBillCheck} onChange={reqTaxBillCheckChangeHandler} />}
                label={<Typography variant="subtitle1">기업 회원 등록</Typography>}
              />
              {reqTaxBillCheck && (
                <UpdateTaxBillForm
                  reqTaxBillCheck={reqTaxBillCheck}
                  businessNoIsNotDuplicate={businessNoIsNotDuplicate}
                  setBusinessNoIsNotDuplicate={setBusinessNoIsNotDuplicate}
                  companyNameInput={companyNameInput}
                  addressInput={addressInput}
                  addressDetailInput={addressDetailInput}
                  zipCodeInput={zipCodeInput}
                  companyPhoneInput={companyPhoneInput}
                  companyFaxInput={companyFaxInput}
                  companyEmailInput={companyEmailInput}
                  businessNoInput={businessNoInput}
                  representativeNameInput={representativeNameInput}
                  typeOfBusinessInput={typeOfBusinessInput}
                  itemOfBusinessInput={itemOfBusinessInput}
                  businessAreaInput={businessAreaInput}
                  etcInput={etcInput}
                  ogKey={ogKey}
                  setOgKey={setOgKey}
                />
              )}
              <Box sx={{ mt: 1, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>서비스 이용약관</Typography>
                <Button variant="text" size="small" onClick={openServiceUseClickHandler} sx={{ ml: 0 }}>
                  [자세히 보기]
                </Button>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>개인정보처리방침</Typography>
                <Button variant="text" size="small" onClick={openCollectUserInfoClickHandler} sx={{ ml: 0 }}>
                  [자세히 보기]
                </Button>
              </Box>
              <Box sx={{ mt: 2, width: '100%' }}>
                <Button
                  variant="contained"
                  disabled={!formIsValid || isLoading}
                  onClick={saveUser}
                  sx={{ width: '100%' }}
                >
                  저장
                </Button>
                <Button
                  type="button"
                  variant="outlined"
                  onClick={closeModalHandler}
                  sx={{ width: '100%', mt: 1, mb: 4, bgcolor: '#fff' }}
                >
                  닫기
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
      <ViewTermsModal
        open={termsModal}
        onClose={closeTermsModalClickHandler}
        setTermsContent={setTermsContent}
        termsContent={termsContent}
      />
    </>
  );
}
