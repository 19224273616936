import { Box, Card, Grid, Typography } from '@mui/material';
import EachPlantInfoWeb from './EachPlant/EachPlantInfoWeb';
import WebWidgetEachSummary from './EachPlant/WebWidgetEachSummary';
import WebWidgetEachDevelopment from './EachPlant/WebWidgetEachDevelopment';
import WebWidgetTimeOfDevelopment from './EachPlant/WebWidgetTimeOfDevelopment';
import WebWidgetMonthSummary from './EachPlant/WebWidgetMonthSummary';
import WebWidgetCo2Info from './EachPlant/WebWidgetCo2Info';
import WebWidgetTodayWeatherInfo from './EachPlant/WebWidgetTodayWeatherInfo';
import { useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import WebWidgetRtuStatus from './EachPlant/WebWidgetRtuStatus';
import WebWidgetInverterStatus from './EachPlant/WebWidgetInverterStatus';
import WebWidgetLastDevelopmentTime from './EachPlant/WebWidgetLastDevelopmentTime';
import { convertStringToFormatDateString } from '../../utils/dateUtil';
import WebWidgetPlantEletricSystem from './EachPlant/WebWidgetPlantEletricSystem';
import WebWidgetWeatherObserveInfo from './EachPlant/WebWidgetWeatherObserveInfo';
import WebWidgetInverterChart from './EachPlant/WebWidgetInverterChart';
import WebWidgetInverterInfoTable from './EachPlant/WebWidgetInverterInfoTable';
import WebWidgetAcbVcbInfoTable from './EachPlant/WebWidgetAcbVcbInfoTable';
import WebWidgetTodayRecTable from './EachPlant/WebWidgetTodayRecTable';
import WebWidgetTodaySmpTable from './EachPlant/WebWidgetTodaySmpTable';
import ReportIcon from '@mui/icons-material/Report';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

// 개별 발전소 웹 뷰 컴포넌트
export default function EachPlantWebView({
  subscribeday,
  graceperiodDay,
  page,
  nowTime,
  todayEnvData,
  basePlantInfo,
  invDailyDevelopment,
  roundedTotalGhgReduction,
  roundedTotalPineTree,
  roundedTotalDailyYield,
  sumTotalYield,
  roundedTotalDailyDevelopmentTime,
  roundedTotalMonthyYield,
  acbVcbDailyDevelopment,
  dailyDevelopment,
  todayRec,
  todaySmp,
  setHelpSignalStatusModal,
  setSignalClickedCard,
}) {
  const [todayWeather, setTodayWeather] = useState('');

  const weatherObservationRequest = useBackendV2();

  const fetchWeatherObservation = async () => {
    try {
      const weatherObservationRequestConfig = {
        method: 'POST',
        body: {
          address: page.plantAddress,
        },
      };
      const weatherObservationResult = await weatherObservationRequest.sendRequest(
        config.backend.endPoint.envs.weather,
        weatherObservationRequestConfig
      );

      if (weatherObservationResult.data !== null) {
        setTodayWeather(weatherObservationResult.data);
      } else {
        alert('오류가 발생하였습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchWeatherObservation();
  }, [page]);

  const handleCardClick = (cardIndex) => {
    setHelpSignalStatusModal(true);
    setSignalClickedCard(cardIndex);
  };

  return (
    <>
      <Box sx={{ filter: subscribeday - graceperiodDay > 0 ? 'blur(10px)' : '', mt: 2 }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <Grid container spacing={1}>
              <Grid item lg={12}>
                <Box display="flex" alignItems="center" sx={{ mb: 1 }}>
                  <ReportIcon color="error" />
                  <Typography variant="subtitle2" display="flex" alignItems="center">
                    신호<span style={{ color: 'red' }}>'중단'</span>상태일 때
                    <span style={{ color: 'blue' }}>&nbsp;꼭&nbsp;</span> 확인하세요!
                  </Typography>
                  <Card
                    onClick={() => handleCardClick(1)}
                    sx={{
                      p: 0.6,
                      ml: 1.3,
                      mr: 0.5,
                      border: '0.2px solid #B0B0B0',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <Typography variant="subtitle2" display="flex" alignItems="center">
                      1. RTU 상태가&nbsp;<span style={{ color: 'red' }}>'통신중단'</span>일 경우{' '}
                      <KeyboardArrowRightIcon />
                    </Typography>
                  </Card>
                  <Card
                    onClick={() => handleCardClick(2)}
                    sx={{
                      p: 0.6,
                      ml: 0.5,
                      mr: 0.5,
                      border: '0.2px solid #B0B0B0',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <Typography variant="subtitle2" display="flex" alignItems="center">
                      2. 발전 상태가&nbsp;<span style={{ color: 'red' }}>'발전중단'</span>일 경우{' '}
                      <KeyboardArrowRightIcon />
                    </Typography>
                  </Card>
                  <Card
                    onClick={() => handleCardClick(3)}
                    sx={{
                      p: 0.6,
                      ml: 0.5,
                      border: '0.2px solid #B0B0B0',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                  >
                    <Typography variant="subtitle2" display="flex" alignItems="center">
                      3. RTU 상태가&nbsp;<span style={{ color: 'red' }}>'통신중단'</span>이고, 발전상태가{' '}
                      &nbsp;<span style={{ color: 'red' }}>'발전중단'</span>일 경우 <KeyboardArrowRightIcon />
                    </Typography>
                  </Card>
                </Box>
              </Grid>
              <Grid item lg={2.25} md={6} sm={6} xs={6}>
                <EachPlantInfoWeb page={page} nowTime={nowTime} basePlantInfo={basePlantInfo} />
              </Grid>
              <Grid item lg={1.875} md={6} sm={6} xs={6}>
                <WebWidgetEachDevelopment total={sumTotalYield} color="info" />
              </Grid>
              <Grid item lg={1.875} md={6} sm={6} xs={6}>
                <WebWidgetMonthSummary total={roundedTotalMonthyYield} color="info" />
              </Grid>
              <Grid item lg={1.875} md={6} sm={6} xs={6}>
                <WebWidgetEachSummary total={roundedTotalDailyYield} color="warning" />
              </Grid>
              <Grid item lg={1.875} md={6} sm={6} xs={6}>
                <WebWidgetTimeOfDevelopment total={roundedTotalDailyDevelopmentTime} color="info" />
              </Grid>
              <Grid item lg={2.25} md={6} sm={6} xs={6}>
                <Grid container spacing={1.5}>
                  <Grid item sm={6} xs={12} md={3} lg={12}>
                    <WebWidgetCo2Info
                      roundedTotalGhgReduction={roundedTotalGhgReduction}
                      roundedTotalPineTree={roundedTotalPineTree}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12} md={3} lg={12}>
                    <WebWidgetTodayWeatherInfo todayWeather={todayWeather} page={page} />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={2.25} md={6} sm={6} xs={6}>
                <Grid container spacing={1.5}>
                  <Grid item xs={12}>
                    <Grid container spacing={1.5}>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <WebWidgetRtuStatus basePlantInfo={basePlantInfo} />
                      </Grid>
                      <Grid item lg={6} md={6} sm={6} xs={6}>
                        <WebWidgetInverterStatus
                          basePlantInfo={basePlantInfo}
                          invDailyDevelopment={invDailyDevelopment}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm={6} xs={12} md={3} lg={12}>
                    <WebWidgetLastDevelopmentTime
                      lastReceptionDate={convertStringToFormatDateString(
                        basePlantInfo[0]?.rtuinfos[0].localdate
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={acbVcbDailyDevelopment.length === 0 ? 7.5 : 9.75}
                md={acbVcbDailyDevelopment.length === 0 ? 7.5 : 9.75}
                sm={acbVcbDailyDevelopment.length === 0 ? 7.5 : 9.75}
                xs={acbVcbDailyDevelopment.length === 0 ? 7.5 : 9.75}
              >
                <WebWidgetPlantEletricSystem
                  invDailyDevelopment={invDailyDevelopment}
                  acbVcbDailyDevelopment={acbVcbDailyDevelopment}
                  basePlantInfo={basePlantInfo}
                />
              </Grid>
              {acbVcbDailyDevelopment.length === 0 ? (
                <Grid item lg={2.25} md={6} sm={6} xs={6}>
                  <WebWidgetWeatherObserveInfo page={page} />
                </Grid>
              ) : null}
              {acbVcbDailyDevelopment.length === 0 ? (
                <>
                  <Grid item lg={6} md={6} sm={6} xs={6} sx={{ mt: 1 }}>
                    <WebWidgetInverterChart page={page} />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} sx={{ mt: 1 }}>
                    <WebWidgetInverterInfoTable
                      basePlantInfo={basePlantInfo}
                      invDailyDevelopment={invDailyDevelopment}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  {/* <Grid item lg={2.5} md={6} sm={6} xs={6} sx={{ mt: 1.5 }}>
                    <WebWidgetInverterChart invDailyDevelopment={invDailyDevelopment} page={page} />
                  </Grid> */}
                  <Grid item lg={4.875} md={6} sm={6} xs={6} sx={{ mt: 1.5 }}>
                    <WebWidgetInverterInfoTable
                      basePlantInfo={basePlantInfo}
                      invDailyDevelopment={invDailyDevelopment}
                    />
                  </Grid>
                  <Grid item lg={4.875} md={6} sm={6} xs={6} sx={{ mt: 1.5 }}>
                    <WebWidgetAcbVcbInfoTable
                      basePlantInfo={basePlantInfo}
                      invDailyDevelopment={invDailyDevelopment}
                      acbVcbDailyDevelopment={acbVcbDailyDevelopment}
                    />
                  </Grid>
                  <Grid item lg={2.25} md={6} sm={6} xs={6} sx={{ mt: 1.5 }}>
                    <WebWidgetWeatherObserveInfo
                      page={page}
                      acbVcbDailyDevelopment={acbVcbDailyDevelopment}
                    />
                  </Grid>
                </>
              )}
              {acbVcbDailyDevelopment.length === 0 ? (
                <>
                  <Grid item lg={6} md={6} sm={6} xs={6} sx={{ mt: 1 }}>
                    <WebWidgetTodayRecTable todayRec={todayRec} address={page.plantAddress} />
                  </Grid>
                  <Grid item lg={6} md={6} sm={6} xs={6} sx={{ mt: 1 }}>
                    <WebWidgetTodaySmpTable todaySmp={todaySmp} address={page.plantAddress} />
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item lg={4.875} md={6} sm={6} xs={6} sx={{ mt: 1.5 }}>
                    <WebWidgetInverterChart invDailyDevelopment={invDailyDevelopment} page={page} />
                  </Grid>
                  <Grid item lg={3.5625} md={6} sm={6} xs={6} sx={{ mt: 1 }}>
                    <WebWidgetTodayRecTable todayRec={todayRec} address={page.plantAddress} />
                  </Grid>
                  <Grid item lg={3.5625} md={6} sm={6} xs={6} sx={{ mt: 1 }}>
                    <WebWidgetTodaySmpTable todaySmp={todaySmp} address={page.plantAddress} />
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
