import {
  Box,
  Button,
  ButtonGroup,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  IconButton,
  Typography,
} from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useState } from 'react';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import AuthContext from '../../store/auth-context';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { fNumberWithCommas } from '../../utils/formatNumber';
import { useCookies } from 'react-cookie';
import useResponsive from '../../hooks/useResponsive';
import ShopProductCardDetailHavePlantkey from './ShopProductCardDetailHavePlantkey';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';

// 장바구니 추가 모달 컴포넌트
export default function ShoppingCartModal({ product, open, onClose }) {
  const { pdtid, pdttype, pdtname, pdtimg, price, saleprice, filekey, cnt, delprice } = product;
  const authCtx = useContext(AuthContext);
  const isDesktop = useResponsive('up', 'lg');

  const [count, setCount] = useState(1);
  const [cookies, setCookie, removeCookie] = useCookies(['orderid']);
  const [saveRtun, setSaveRtun] = useState([]);

  const [productId, setProductId] = useState(pdtid);
  const [productName, setProductName] = useState(pdtname);
  const [productFilekey, setProductFilekey] = useState(filekey);
  const [productPrice, setProductPrice] = useState(price);
  const [productSalePrice, setProductSalePrice] = useState(saleprice);
  const [productCnt, setProductCnt] = useState(cnt);

  const removeCountClickHandler = () => {
    if (count === 0) {
      alert('개수는 0개 미만으로 내려갈 수 없습니다.');
    } else {
      setCount(count - 1);
    }
  };
  const addCountClickHandler = () => {
    setCount(count + 1);
  };

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  const backendRequest = useBackendV2();

  const putInShoppingCartClickHandler = async () => {
    if (count > 0) {
      try {
        const putInShoppingCartRequestConfig = {
          method: 'POST',
          body: [
            {
              userid: authCtx?.currentUser?.id,
              pdtid: productId.toString(),
              cnt: count,
              price: productPrice * count,
              rtunumber: saveRtun.length === 0 ? '' : JSON.stringify(saveRtun),
              orderid: cookies.orderid || '',
            },
          ],
        };
        const putInShoppingCartResult = await backendRequest.sendRequest(
          config.backend.endPoint.carts.basic,
          putInShoppingCartRequestConfig
        );
        if (putInShoppingCartResult.data !== null) {
          setCookie('orderid', putInShoppingCartResult.data, { maxAge: 24 * 60 * 60 });
          alert('장바구니에 담았습니다.');
          onClose();
        } else {
          alert('오류가 발생하였습니다.');
          onClose();
        }
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
        onClose();
      }
    } else {
      alert('0개 이하는 장바구니 담기가 불가능 합니다.');
    }
  };

  const fetchProductDetail = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.products.detail}/${productId}`
      );
      // setProduct(data);
      setProductName(data.pdtname);
      setProductFilekey(data.filekey);
      setProductPrice(data.price);
      setProductSalePrice(data.saleprice);
      setProductCnt(data.cnt);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    if (product !== undefined) {
      setProductId(pdtid);
    }
  }, [product]);

  useEffect(() => {
    fetchProductDetail();
  }, [productId]);

  return (
    <CustomModal
      open={open}
      onClose={closeModalHandler}
      sx={{ width: { xs: '95%', md: 700 }, maxHeight: '95%' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
        <AddShoppingCartIcon />
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>장바구니 담기</Typography>
      </Box>
      <hr />
      {isDesktop ? (
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <CardMedia
            component="img"
            image={`https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=SC&id=${productFilekey}`}
            alt={productName}
            sx={{ width: '50%', height: '50%' }}
          />
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent>
              <Typography component="div" variant="h5" display="flex" sx={{ mb: 1 }}>
                {productName}
                {productSalePrice === 0 ? null : (
                  <Chip label="sale" color="error" size="small" sx={{ ml: 0.5 }} />
                )}
              </Typography>
              {productSalePrice === 0 ? (
                <>
                  <Typography variant="h5">
                    {pdttype === 3 ? <span style={{ fontSize: '90%' }}>월 </span> : null}
                    {fNumberWithCommas(productPrice)}원
                  </Typography>
                </>
              ) : (
                <Typography variant="h5">
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{
                      color: 'text.disabled',
                      textDecoration: 'line-through',
                    }}
                  >
                    {pdttype === 3 ? <span style={{ fontSize: '90%' }}>월 </span> : null}
                    {fNumberWithCommas(productPrice + productSalePrice)}원
                  </Typography>
                  &nbsp;{fNumberWithCommas(productPrice)}원
                </Typography>
              )}
              {product.pdttype === 1 || product.pdttype === 3 || product.pdttype === 4 ? (
                product.price <= 50000 ? (
                  <Typography variant="subtitle2" sx={{ mt: 0.5, color: 'text.secondary' }}>
                    {delprice === 0
                      ? '배송 금액 : 무료'
                      : '배송 금액 : ' + fNumberWithCommas(delprice) + '원'}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" sx={{ mt: 0.5, color: 'text.secondary' }}>
                    배송 금액 : 무료
                  </Typography>
                )
              ) : null}
              <Typography variant="subtitle2" sx={{ mt: 0.5, color: 'text.secondary' }}>
                부가세 별도
              </Typography>
              <ShopProductCardDetailHavePlantkey
                expiredRtu={[]}
                product={product}
                productId={productId}
                setProductId={setProductId}
              />
            </CardContent>
            {productCnt === 0 || productPrice === 0 ? null : (
              <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
                <IconButton onClick={removeCountClickHandler} aria-label="previous">
                  <IndeterminateCheckBoxOutlinedIcon fontSize="large" />
                </IconButton>
                <Typography variant="h5">{count}</Typography>
                <IconButton onClick={addCountClickHandler} aria-label="next">
                  <AddBoxOutlinedIcon fontSize="large" />
                </IconButton>
              </Box>
            )}
            {productCnt === 0 ? (
              <>
                <Box sx={{ display: 'flex', pl: 2, mt: 2 }}>
                  <ProductionQuantityLimitsOutlinedIcon fontSize="large" />
                  <Typography variant="h5" sx={{ ml: 1 }}>
                    상품 준비중
                  </Typography>
                </Box>
              </>
            ) : productPrice === 0 ? (
              <Typography variant="h6">별도견적 상품 문의 : 1566-6403</Typography>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    pl: 1,
                    px: '6%',
                    mt: 3,
                  }}
                >
                  <Button
                    type="button"
                    variant="contained"
                    onClick={putInShoppingCartClickHandler}
                    sx={{ width: '45%' }}
                  >
                    담기
                  </Button>
                  <Box sx={{ width: '6%' }} />
                  <Button type="button" variant="outlined" sx={{ width: '45%' }} onClick={closeModalHandler}>
                    닫기
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <CardMedia
              component="img"
              image={`https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=SC&id=${productFilekey}`}
              alt={productName}
              sx={{ width: '65%', height: '65%' }}
            />
          </Box>
          <Box sx={{ mt: 2 }}>
            <CardContent>
              <Divider sx={{ mb: 1 }} />
              <Typography component="div" variant="h6" display="flex" sx={{ mb: 0.5 }}>
                {productName}
                {productSalePrice === 0 ? null : (
                  <Chip label="sale" color="error" size="small" sx={{ ml: 0.5 }} />
                )}
              </Typography>
              {productSalePrice === 0 ? (
                <Typography variant="h5" sx={{ mb: 1 }}>
                  <span style={{ fontSize: '95%' }}>월 </span>
                  {fNumberWithCommas(productPrice)}원
                </Typography>
              ) : (
                <Typography variant="h5" sx={{ mb: 1 }}>
                  <Typography
                    component="span"
                    variant="body1"
                    sx={{
                      color: 'text.disabled',
                      textDecoration: 'line-through',
                    }}
                  >
                    <span style={{ fontSize: '85%' }}>월 </span>
                    {fNumberWithCommas(productPrice + productSalePrice)}원
                  </Typography>
                  &nbsp;{fNumberWithCommas(productPrice)}원
                </Typography>
              )}
              {product.pdttype === 1 || product.pdttype === 3 || product.pdttype === 4 ? (
                product.price <= 50000 ? (
                  <Typography variant="subtitle2" sx={{ mt: 0.5, color: 'text.secondary' }}>
                    {delprice === 0
                      ? '배송 금액 : 무료'
                      : '배송 금액 : ' + fNumberWithCommas(delprice) + '원'}
                  </Typography>
                ) : (
                  <Typography variant="subtitle2" sx={{ mt: 0.5, color: 'text.secondary' }}>
                    배송 금액 : 무료
                  </Typography>
                )
              ) : null}
              <Typography variant="subtitle2" sx={{ mt: 0.5, color: 'text.secondary' }}>
                부가세 별도
              </Typography>
              <ShopProductCardDetailHavePlantkey
                expiredRtu={[]}
                product={product}
                productId={productId}
                setProductId={setProductId}
              />
            </CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', pl: 1 }}>
              {productCnt === 0 || productPrice === 0 ? null : (
                <>
                  <IconButton onClick={removeCountClickHandler} aria-label="previous">
                    <IndeterminateCheckBoxOutlinedIcon fontSize="large" />
                  </IconButton>
                  <Typography variant="h5">{count}</Typography>
                  <IconButton onClick={addCountClickHandler} aria-label="next">
                    <AddBoxOutlinedIcon fontSize="large" />
                  </IconButton>
                </>
              )}
            </Box>
            {productCnt === 0 ? (
              <>
                <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 1 }}>
                  <ProductionQuantityLimitsOutlinedIcon fontSize="large" />
                  <Typography variant="h5">상품 준비중</Typography>
                </Box>
              </>
            ) : productPrice === 0 ? (
              <>
                <Typography variant="h6">별도견적 상품 문의</Typography>
                <Typography variant="h6">1566-6403</Typography>
              </>
            ) : (
              <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                <ButtonGroup>
                  <Button onClick={putInShoppingCartClickHandler} variant="contained" sx={{ width: 100 }}>
                    담기
                  </Button>
                  <Button onClick={closeModalHandler} variant="outlined" sx={{ width: 100 }}>
                    닫기
                  </Button>
                </ButtonGroup>
              </Box>
            )}
          </Box>
        </>
      )}
    </CustomModal>
  );
}
