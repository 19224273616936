import useResponsive from '../../../hooks/useResponsive';
import MGroupChart from '../../Charts/Statistics/Month/MGroupChart';
import MonthGroupStatisticsTable from './MonthGroupStatisticsTable';

export default function MonthGroupStatistics({ data, chartView }) {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <>
      {isDesktop ? (
        <>
          <MGroupChart data={data} isDesktop={isDesktop} />
          {data.length === 0 ? null : <MonthGroupStatisticsTable data={data} isDesktop={isDesktop} />}
        </>
      ) : chartView ? (
        <MGroupChart data={data} isDesktop={isDesktop} />
      ) : (
        <MonthGroupStatisticsTable data={data} isDesktop={isDesktop} />
      )}
    </>
  );
}
