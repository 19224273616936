import { Box, Button } from '@mui/material';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import { useNavigate } from 'react-router-dom';
import InverterWebMenu from './Menu/InverterWebMenu';
import StatisticsWebMenu from './Menu/StatisticsWebMenu';
import AlarmWebMenu from './Menu/AlarmWebMenu';
import BulletinboardWebMenu from './Menu/BulletinboardWebMenu';
import config from '../../config';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';

// 웹 헤더 좌측 메뉴 컴포넌트
export default function HeaderLeftMenu({ value, setValue, setSubValue, subValue }) {
  const navigate = useNavigate();

  const moveMonitoring = () => {
    navigate('/', { state: { subValue: 0 } });
    // localStorage.removeItem('selectPlantkey');
  };

  const monitoringClickHandler = () => {
    // setMonitoringSelect(!monitoringSelect);
    setValue(0);
    localStorage.setItem('menuValue', 0);
    moveMonitoring();
  };

  const productDetailClickHandler = () => {
    setValue(6);
    localStorage.setItem('menuValue', 6);
    localStorage.removeItem('selectPlantkey');
    setSubValue(0);
    navigate(config.frontend.products, { state: { value: 6, subValue: 0 } });
  };

  return (
    <Box display="flex" alignItems="center" sx={{ ml: 2 }}>
      <Button
        startIcon={<ConnectedTvIcon fontSize="large" />}
        sx={{
          color: '#fff',
          fontSize: 'large',
          mr: 0.5,
        }}
        onClick={monitoringClickHandler}
      >
        모니터링
      </Button>
      <InverterWebMenu setValue={setValue} setSubValue={setSubValue} />
      <StatisticsWebMenu setValue={setValue} setSubValue={setSubValue} />
      <AlarmWebMenu setValue={setValue} setSubValue={setSubValue} />
      <BulletinboardWebMenu setValue={setValue} setSubValue={setSubValue} />
      {process.env.REACT_APP_STORE_OPEN === 'true' ? (
        <Button
          startIcon={<StoreMallDirectoryOutlinedIcon fontSize="large" />}
          sx={{
            color: '#fff',
            fontSize: 'large',
            mr: 0,
          }}
          onClick={productDetailClickHandler}
        >
          상품&서비스
        </Button>
      ) : null}
    </Box>
  );
}
