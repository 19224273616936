import {
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Select,
  TextField,
} from '@mui/material';
import AppWidgetSummary from './EntirePlant/AppWidgetSummary';
import useResponsive from '../../hooks/useResponsive';
import EntirePlantInfoCard from './EntirePlant/EntirePlantInfoCard';
import { useContext, useEffect, useState } from 'react';
import AppWidgetRtuError from './EntirePlant/AppWidgetRtuError';
import AppWidgetInverter from './EntirePlant/AppWidgetInverter';
import AppWidgetInverterError from './EntirePlant/AppWidgetInverterError';
import { useLocation } from 'react-router-dom';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import AuthContext from '../../store/auth-context';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import useInterval from '../../hooks/use-interval';
import SendIcon from '@mui/icons-material/Send';
import EntirePlantNodataInfoCard from './EntirePlant/EntirePlantNodataInfoCard';
import EntirePlantIvtNoGenerateInfoCard from './EntirePlant/EntirePlantIvtNoGenerateInfoCard';
import usePageLogRecord from '../../hooks/usePageLogRecord';

// 전체 대시보드 구성 컴포넌트
export default function EntirePlant({ setPage, page }) {
  const location = useLocation();
  const isDesktop = useResponsive('up', 'lg');
  const authCtx = useContext(AuthContext);

  // 발전소 검색용
  const [selectPlant, setSelectPlant] = useState([]);
  const [selectedPlants, setSelectedPlants] = useState([]);

  // 리스트 노출용
  const [plant, setPlant] = useState([]);

  const [nodataPlant, setNodataPlant] = useState([]);
  const [invNodataPlant, setInvNodataPlant] = useState([]);
  const [subscribedPlant, setSubscribedPlant] = useState([]);

  const [expanded, setExpanded] = useState(false);
  const [hideExpanded, setHideExpanded] = useState(false);

  const [widgetClick, setWidgetClick] = useState('plant');

  // 총 페이지 수
  const [lastPage, setLastPage] = useState(1);
  // 현제 페이지
  const [currentPage, setCurrentPage] = useState(1);
  // 검색 타입
  const [searchtype, setSearchtype] = useState(0);
  // 모바일 리스트 갯수 설정
  const [visibleItems, setVisibleItems] = useState(5);

  // 전체 발전소 수
  const [numPlants, setNumPlants] = useState(0);
  // 전체 발전소의 용량 합계
  const [totalCapacity, setTotalCapacity] = useState(0);

  // RTU 이상
  const [numRsRtuWithNodata, setNumRsRtuWithNodata] = useState(0);

  // 전체 인버터
  const [numRsInverters, setNumRsInverters] = useState(0);
  // 인버터 발전 이상
  const [numRsInvertersWithIvtNodata, setNumRsInvertersWithIvtNodata] = useState(0);

  const selectPlantChangeHandler = (selectedPlant) => {
    setPage({ ...page, plantkey: selectedPlant.plantkey, plantname: selectedPlant.plantname });
    setHideExpanded(true);
  };

  // 무한스크롤 체크 로직
  const loadMoreItems = () => {
    if (!expanded) {
      setExpanded(true);
      const newVisibleItems = visibleItems + 5;
      if (newVisibleItems <= plant.length) {
        setTimeout(() => {
          setVisibleItems(newVisibleItems);
          setExpanded(false);
        }, 1000);
      } else if (visibleItems < plant.length) {
        setTimeout(() => {
          setVisibleItems(plant.length);
          setExpanded(false);
        }, 1000);
      } else {
        setExpanded(false);
      }
    }
  };
  const handleExpandClick = () => {
    expanded ? alert('로드 중입니다.') : loadMoreItems();
  };

  // 통신이상 버튼 클릭 핸들러
  const handleNodataButtonClick = async () => {
    // 기존에 선택된 발전소 정보를 초기화
    setPage((prevPage) => ({
      ...prevPage,
      plantkey: '',
      plantname: '',
      plantNodata: 'nodata',
      plantInvNodata: '',
    }));

    try {
      const { data } = await userPlantsRequest.sendRequest(
        `${config.backend.endPoint.plants.allInfoMUser}/${
          authCtx.currentUser.id
        }/${0}/${10000}?searchtype=${1}`
      );
      setNodataPlant(data.datalist);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }

    setWidgetClick('RTU');
    setHideExpanded(true);
  };

  const handleInvNodataButtonClick = async () => {
    setPage((prevPage) => ({
      ...prevPage,
      plantkey: '',
      plantname: '',
      plantNodata: '',
      plantInvNodata: 'invnodata',
    }));

    try {
      const { data } = await userPlantsRequest.sendRequest(
        `${config.backend.endPoint.plants.allInfoMUser}/${
          authCtx.currentUser.id
        }/${0}/${10000}?searchtype=${2}`
      );
      setInvNodataPlant(data.datalist);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }

    setWidgetClick('IVT');
    setHideExpanded(true);
  };

  const viewAllClickHandler = () => {
    setPage((prevPage) => ({
      ...prevPage,
      pagetype: 'entire',
      plantkey: '',
      plantname: '',
      plantNodata: '',
      plantInvNodata: '',
      subscribeday: '',
    }));
    setVisibleItems(5);
    setHideExpanded(false);
    setWidgetClick('plant');
    localStorage.removeItem('selectPlantkey');
    localStorage.removeItem('paymentGuide');
  };

  const viewSubscribedPlantClickHandler = () => {
    setPage((prevPage) => ({
      ...prevPage,
      pagetype: 'entire',
      plantkey: '',
      plantname: '',
      plantNodata: '',
      plantInvNodata: '',
      subscribeday: 'true',
    }));

    const subscribedPlants = selectedPlants.filter(
      (item) => item.subscribeday > -90 || item.subscribeday >= 0
    );

    setWidgetClick('subscribe');
    setSubscribedPlant(subscribedPlants);

    setHideExpanded(true);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const userPlantsRequest = useBackendV2();

  const fetchUserPlants = async () => {
    try {
      const { data } = await userPlantsRequest.sendRequest(
        `${config.backend.endPoint.plants.userPlantsV2}/${authCtx.currentUser.id}`
      );
      setSelectPlant(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchPlants = async () => {
    try {
      const pageNumber = isDesktop ? currentPage - 1 : 0;
      const pageSize = isDesktop ? 5 : 50000;

      const { data } = await userPlantsRequest.sendRequest(
        `${config.backend.endPoint.plants.allInfoMUser}/${authCtx.currentUser.id}/${pageNumber}/${pageSize}?searchtype=${searchtype}`
      );

      setNumPlants(data.plantTotalNumber);
      setTotalCapacity(data.totalCapacitor);

      setNumRsRtuWithNodata(data.errRTUNumber);

      setNumRsInverters(data.inverterNumber);
      setNumRsInvertersWithIvtNodata(data.errInverterNumber);

      setPlant(data.datalist);
      setLastPage(Math.ceil(data.plantTotalNumber / pageSize));
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchSelectedPlants = async () => {
    try {
      const { data } = await userPlantsRequest.sendRequest(
        `${config.backend.endPoint.plants.userPlantsV2}/${authCtx.currentUser.id}`
      );

      setSelectedPlants(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  // 전체 발전소 현황 리스트
  const visiblePlants = plant !== undefined ? plant?.slice(0, visibleItems) : null;

  let entirePlantInfoCard;

  if (plant != null) {
    if (page.plantkey) {
      const selectedPlant = selectedPlants?.find((d) => d.plantkey === page.plantkey);
      entirePlantInfoCard = selectedPlant ? (
        <EntirePlantInfoCard
          key={selectedPlant.plantkey}
          plantkey={selectedPlant.plantkey}
          plantName={selectedPlant.plantname}
          address={selectedPlant.street}
          subscribeday={selectedPlant.subscribeday}
          setPage={setPage}
          page={page}
        />
      ) : null;
    } else if (page.plantNodata === 'nodata') {
      entirePlantInfoCard = nodataPlant.map((d) => (
        <EntirePlantNodataInfoCard
          key={d.plantkey}
          plantkey={d.plantkey}
          plantName={d.plantname}
          address={d.address}
          nodata={d.nodata}
          capacity={d.capacity}
          rsInverters={d.rsInverters}
          subscribeday={d.subscribeday}
          setPage={setPage}
          page={page}
        />
      ));
    } else if (page.plantInvNodata === 'invnodata') {
      entirePlantInfoCard = invNodataPlant.map((d) => (
        <EntirePlantIvtNoGenerateInfoCard
          key={d.plantkey}
          plantkey={d.plantkey}
          plantName={d.plantname}
          address={d.address}
          nodata={d.nodata}
          capacity={d.capacity}
          rsInverters={d.rsInverters}
          subscribeday={d.subscribeday}
          setPage={setPage}
          page={page}
        />
      ));
    } else if (page.subscribeday === 'true') {
      entirePlantInfoCard = subscribedPlant.map((d) => (
        <EntirePlantInfoCard
          key={d.plantkey}
          plantkey={d.plantkey}
          plantName={d.plantname}
          address={d.street}
          subscribeday={d.subscribeday}
          setPage={setPage}
          page={page}
        />
      ));
    } else {
      entirePlantInfoCard = visiblePlants.map((d) => (
        <EntirePlantInfoCard
          key={d.plantkey}
          plantkey={d.plantkey}
          plantName={d.plantname}
          address={d.address}
          subscribeday={d.subscribeday}
          setPage={setPage}
          page={page}
        />
      ));
    }
  }

  const fetchPollingInterval = 60000;

  useInterval(() => {
    if (authCtx.currentUser.role !== '사이트 관리자') {
      fetchUserPlants();
      fetchPlants();
    }
  }, fetchPollingInterval);

  // 여러 발전소 정보 api 호출
  useEffect(() => {
    if (authCtx.currentUser.role !== '사이트 관리자') {
      (async () => {
        await fetchUserPlants();
        await fetchPlants();
        await fetchSelectedPlants();
      })();
    }
  }, []);

  // 페이징에 따른 발전소 리스트
  useEffect(() => {
    if (authCtx.currentUser.role !== '사이트 관리자') {
      fetchPlants();
    }
  }, [currentPage]);

  // 화면 최상단 이동버튼 감지
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  usePageLogRecord('entireMonitorings');

  return (
    <>
      {/* 웹 전체현황 검색란 */}
      <Box
        sx={{
          p: '20px 4px',
          display: {
            xs: 'none',
            sm: 'flex',
          },
          gap: 1.5,
          alignItems: 'center',
        }}
      >
        {numPlants > 11 ? (
          <Autocomplete
            disableClearable
            id="combo-box-demo"
            options={selectPlant}
            getOptionLabel={(option) => option.plantname || ''}
            value={page.plantkey ? selectPlant.find((option) => option.plantkey === page.plantkey) : null}
            onChange={(e, newValue) => {
              if (newValue) {
                const selectedPlant = selectPlant.find((option) => option.plantkey === newValue.plantkey);
                selectPlantChangeHandler(selectedPlant);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="발전소" variant="outlined" sx={{ bgcolor: '#fff' }} />
            )}
            sx={{ width: '45%' }}
          />
        ) : (
          <FormControl sx={{ width: '45%', bgcolor: '#fff' }}>
            <InputLabel>발전소</InputLabel>
            <Select
              label="발전소"
              value={page.plantkey || ''}
              onChange={(e) => {
                const selectedPlant = selectPlant.find((option) => option.plantkey === e.target.value);
                selectPlantChangeHandler(selectedPlant);
              }}
            >
              {selectPlant.map((option) => (
                <MenuItem key={option.plantkey} value={option.plantkey}>
                  {option.plantname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Box sx={{ flexGrow: 1 }} />
        <Box display="flex" justifyContent="end" alignItems="center" sx={{ mt: 0 }}>
          <Button
            onClick={viewSubscribedPlantClickHandler}
            variant="text"
            size="large"
            endIcon={<SendIcon color="primary" />}
            sx={{ color: 'black' }}
          >
            이용기간 만료(예정) 발전소 보기
          </Button>
        </Box>
      </Box>
      {/* 모바일 전체현황 검색란 */}
      <Box
        sx={{
          display: {
            sm: 'none',
          },
          my: 2,
          gap: 1,
        }}
      >
        {numPlants > 11 ? (
          <Autocomplete
            disableClearable
            id="combo-box-demo"
            options={selectPlant}
            getOptionLabel={(option) => option.plantname || ''}
            value={page.plantkey ? selectPlant.find((option) => option.plantkey === page.plantkey) : null}
            onChange={(e, newValue) => {
              if (newValue) {
                const selectedPlant = selectPlant.find((option) => option.plantkey === newValue.plantkey);
                selectPlantChangeHandler(selectedPlant);
              }
            }}
            renderInput={(params) => (
              <TextField {...params} label="발전소" variant="outlined" sx={{ bgcolor: '#fff' }} />
            )}
            sx={{ width: '100%' }}
          />
        ) : (
          <FormControl sx={{ width: '100%', bgcolor: '#fff' }}>
            <InputLabel>발전소</InputLabel>
            <Select
              label="발전소"
              value={page.plantkey || ''}
              onChange={(e) => {
                const selectedPlant = selectPlant.find((option) => option.plantkey === e.target.value);
                selectPlantChangeHandler(selectedPlant);
              }}
            >
              {selectPlant.map((option) => (
                <MenuItem key={option.plantkey} value={option.plantkey}>
                  {option.plantname}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        <Box display="flex" justifyContent="end" alignItems="center" sx={{ mt: 1, borderRadius: '24px' }}>
          <Button
            onClick={viewSubscribedPlantClickHandler}
            endIcon={<SendIcon color="primary" />}
            sx={{ color: 'black' }}
          >
            이용기간 만료(예정) 발전소 보기
          </Button>
        </Box>
      </Box>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Grid container spacing={isDesktop ? 1.5 : 0.7}>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <AppWidgetSummary
                total={numPlants}
                viewAllClickHandler={viewAllClickHandler}
                widgetClick={widgetClick}
                setWidgetClick={setWidgetClick}
                color="info"
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <AppWidgetInverter total={numRsInverters} totalCapacity={totalCapacity} color="success" />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <AppWidgetRtuError
                plant={plant}
                rtuErr={numRsRtuWithNodata}
                handleNodataButtonClick={handleNodataButtonClick}
                widgetClick={widgetClick}
                setWidgetClick={setWidgetClick}
                color="warning"
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <AppWidgetInverterError
                invErr={numRsInvertersWithIvtNodata}
                handleInvNodataButtonClick={handleInvNodataButtonClick}
                widgetClick={widgetClick}
                setWidgetClick={setWidgetClick}
                color="warning"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Box sx={{ mt: 2 }}>
        {entirePlantInfoCard}
        {plant !== undefined ? (
          isDesktop ? (
            widgetClick === 'plant' ? (
              <Pagination
                count={lastPage}
                page={currentPage || 1}
                onChange={handlePageChange}
                size="large"
                sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
              />
            ) : null
          ) : !hideExpanded && visibleItems < plant?.length ? (
            <Card
              variant="outlined"
              sx={{
                p: 1,
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: isDesktop ? 'pointer' : 'cursor',
                height: '40px',
                borderRadius: '7px',
                boxShadow: '0px 3px 24px #00000014',
              }}
              onClick={handleExpandClick}
            >
              {expanded ? <CircularProgress size={30} /> : <KeyboardDoubleArrowDownIcon />}
            </Card>
          ) : null
        ) : null}
      </Box>
    </>
  );
}
