import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: '14px',
    borderBottom: '1px solid #CECECE',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

// 현황판 ACB VCB별 상세정보 컴포넌트
export default function PlantAcbVcbInfoCard({ basePlantInfo, invDailyDevelopment, acbVcbDailyDevelopment }) {
  const columns = [
    { field: 'acbname', headerName: 'ACB, VCB명', width: 100 },
    { field: 'actp', headerName: '유효전력(kW)', width: 100 },
    { field: 'nodata', headerName: '발전 상태', width: 90 },
  ];

  const processACBVCBData = (data) => {
    const vcbIndex = data.findIndex((item) => item.acbvcbtype === 'VCB');

    if (vcbIndex !== -1) {
      const vcbItem = data.splice(vcbIndex, 1)[0];
      data.unshift(vcbItem);
    }

    return data;
  };

  const processedData = processACBVCBData(acbVcbDailyDevelopment);

  // RTU 수신상태
  const isRtuStatusOne = basePlantInfo.some((plant) => plant.rtuinfos?.some((info) => info.rtustatus === 1));

  // 인버터 발전상태
  const isAnyInverterGenerating = invDailyDevelopment.some((inverter) => inverter.generatestatus === 1);

  return (
    <>
      <Card sx={{ borderRadius: '15px', boxShadow: '0px 3px 100px #00000014', height: 400 }}>
        <Box sx={{ p: 1 }}>
          <Typography variant="subtitle1">ACB, VCB 상세 정보</Typography>
        </Box>
        <Card
          sx={{
            border: 'none',
            width: '100%',
            height: '90%',
            borderRadius: 0,
            overflowY: 'auto',
          }}
        >
          <StyledTableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledTableCell key={column.field} align="center" style={{ minWidth: column.width }}>
                      {column.headerName}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {processedData.map((row, index) => (
                  <TableRow key={row.serialnum} index={index}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        align="center"
                        style={{
                          minWidth: column.width,
                          backgroundColor: '#fff',
                        }}
                      >
                        {column.field === 'actp' ? (
                          (row[column.field] * 0.001).toFixed(1)
                        ) : column.field === 'nodata' ? (
                          <Box display="flex" justifyContent="center">
                            <Tooltip
                              title={
                                isRtuStatusOne && isAnyInverterGenerating && row[column.field] === '0'
                                  ? '발전 중'
                                  : '발전 중단'
                              }
                              arrow
                            >
                              <Avatar
                                sizes="small"
                                sx={{
                                  width: 18,
                                  height: 18,
                                  bgcolor:
                                    isRtuStatusOne && isAnyInverterGenerating && row[column.field] === '0'
                                      ? '#64B346'
                                      : 'red',
                                  color:
                                    isRtuStatusOne && isAnyInverterGenerating && row[column.field] === '0'
                                      ? '#64B346'
                                      : 'red',
                                }}
                              >
                                {''}
                              </Avatar>
                            </Tooltip>
                          </Box>
                        ) : row[column.field] ? (
                          row[column.field].toLocaleString()
                        ) : (
                          0
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Card>
      </Card>
    </>
  );
}
