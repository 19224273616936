import MainCard from '../../theme/MainCard';
import { Button, Box, Typography } from '@mui/material';

// 현황판 인버터 발전표시 컴포넌트
export default function PlantRtuInverterStateCard_02({
  basePlantInfo,
  invDailyDevelopment,
  isIpad,
  isNexthub,
}) {
  return (
    <>
      <MainCard
        sx={{
          borderRadius: '15px',
          boxShadow: '0px 3px 100px #00000014',
          height: 102,
        }}
      >
        <Box textAlign="center">
          <Typography variant={isIpad || isNexthub ? 'subtitle2' : 'subtitle1'}>
            {isIpad || isNexthub ? '발전' : '발전 상태'}
          </Typography>
          <Button
            variant="contained"
            size="small"
            sx={{
              mt: 1.5,
              cursor: 'default',
              width: '100%',
              backgroundColor: basePlantInfo.some((plant) =>
                plant.rtuinfos?.some((info) => info.rtustatus === 1)
              )
                ? invDailyDevelopment.some((inverter) => inverter.generatestatus === 1)
                  ? '#22C58B'
                  : '#FF525F'
                : '#FF525F',
            }}
          >
            {basePlantInfo.some((plant) => plant.rtuinfos?.some((info) => info.rtustatus === 1))
              ? invDailyDevelopment.some((inverter) => inverter.generatestatus === 1)
                ? '발전 중'
                : '발전 중단'
              : '발전 중단'}
          </Button>
        </Box>
      </MainCard>
    </>
  );
}
