import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  ButtonGroup,
  Card,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import Hour from '../../components/Statistics/Hour/Hour';
import Day from '../../components/Statistics/Day/Day';
import Month from '../../components/Statistics/Month/Month';
import Year from '../../components/Statistics/Year/Year';
import useBackendV2 from '../../hooks/use-backend-v2';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../store/auth-context';
import config from '../../config';
import useResponsive from '../../hooks/useResponsive';
import MobileSearchBar from '../../components/Statistics/MobileSearchBar';
import WebSearchBar from '../../components/Statistics/WebSearchBar';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BarChartIcon from '@mui/icons-material/BarChart';
import EnvStat from '../../components/Statistics/EnvStat/EnvStat';
import { convertFormatStringToYMD } from '../../utils/dateUtil';
import { RiFileExcel2Fill } from 'react-icons/ri';
import { BiSolidFilePdf } from 'react-icons/bi';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import InsightsIcon from '@mui/icons-material/Insights';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import TabMenu from '../../components/Layout/TabMenu';
import ComprehensiveAnalysis from '../../components/Statistics/ComprehensiveAnalysis/ComprehensiveAnalysis';
import Header02 from '../../components/Layout/Header02';
import SubHeader from '../../components/Layout/SubHeader';
import Nav from '../../components/Layout/Nav';
import SubscriptionGuideModal from '../../components/Dashboard/SubscriptionGuideModal';
import usePageLogRecord from '../../hooks/usePageLogRecord';

export default function StatisticsPage() {
  // const subValue = location.state?.subValue ?? 0;
  const authCtx = useContext(AuthContext);
  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const distance = 0;

  const pageConditionInitialState = {
    pagetype: '',
    tabtype: '',
    envtabtype: '',
    plantkey: '',
    plantname: '',
  };

  const [page, setPage] = useState(pageConditionInitialState);
  const [selectPlant, setSelectPlant] = useState([]);
  const [tabValue, setTabValue] = useState('all');
  const [envTabValue, setEnvTabValue] = useState('1');
  const [dateValue, setDateValue] = useState(Date);
  const [chartView, setChartView] = useState(true);
  const [searchState, setSearchState] = useState(true);
  const [comprehensiveSearchState, setComprehensiveSearchState] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [plantInverters, setPlantInverters] = useState([]);
  const [selectPlantInverters, setSelectPlantInverters] = useState([]);
  const [comprehensiveAnalysis, setComprehensiveAnalysis] = useState([]);
  const [processedAnalysisData, setProcessedAnalysisData] = useState([]);

  const [subscribeday, setSubscribeday] = useState(null);
  const [graceperiodDay, setGraceperiodDay] = useState(null);
  const [openSubscriptionGuideModal, setOpenSubscriptionGuideModal] = useState(false);

  const selectPlantChangeHandler = (plant) => {
    setPage((prev) => {
      return { ...prev, plantkey: plant.plantkey, plantname: plant.plantname };
    });
    setSearchState(true);
    setSelectPlantInverters([]);
    setComprehensiveAnalysis([]);
    setPlantInverters([]);
    setSelectPlantInverters([]);
    setComprehensiveAnalysis([]);
    setProcessedAnalysisData([]);
    localStorage.setItem('selectPlantkey', plant.plantkey);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleEnvTabChange = (event, newValue) => {
    setEnvTabValue(newValue);
  };

  const userPlantsRequest = useBackendV2();
  const backendRequest = useBackendV2();

  const fetchUserPlants = async () => {
    try {
      const { data } = await userPlantsRequest.sendRequest(
        `${config.backend.endPoint.plants.userPlantsV2}/${authCtx.currentUser.id}`
      );

      if (data.length === 1) {
        setPage((prevPage) => ({
          ...prevPage,
          plantkey: data[0].plantkey,
          plantname: data[0].plantname,
        }));
        setSelectPlant(data);
      } else {
        setSelectPlant(data);
      }
    } catch (error) {
      console.error(error);
      alert('통계 데이터를 불러오는 중 오류가 발생하였습니다. 인터넷 연결 상태를 확인 후 새로고침 해주세요.');
    }
  };

  const fetchPlantInverters = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.inverters.extBase}/${page.plantkey}`
      );

      setPlantInverters(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchSubscriptionPeriod = async () => {
    if (page.plantkey === '') {
      return;
    } else {
      try {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.plants.subscribe}/${page.plantkey}`
        );

        const filteredData = data.filter((item) => item.subscribeday > -90);

        if (filteredData.length > 0) {
          const maxSubdcribedayItem = filteredData.reduce((maxItem, currentItem) => {
            return currentItem.subscribeday > maxItem.subscribeday ? currentItem : maxItem;
          });

          setSubscribeday(maxSubdcribedayItem.subscribeday);
          setGraceperiodDay(data[0]?.graceperiod);
        } else {
          setSubscribeday(null);
          setGraceperiodDay(null);
        }
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const exportExcelClickHandler = async () => {
    try {
      if (subValue === 0 || subValue === 1 || subValue === 2 || subValue === 3) {
        setIsDownloading(true);
        let tab;
        if (tabValue === 'all') {
          tab = 0;
        } else if (tabValue === 'group') {
          tab = 1;
        } else if (tabValue === 'inverter') {
          tab = 2;
        }
        const queryStringArr = [
          `yieldGroupType=${tab}`,
          `yieldtype=${subValue}`,
          `dateTime=${convertFormatStringToYMD(dateValue)}`,
          `downloadType=${0}`,
        ];
        const queryString = queryStringArr.join('&');
        const url =
          authCtx.isAndroid || authCtx.isiOS
            ? `${config.backend.hostMo}${config.backend.endPoint.statistics.plantYieldDown}/${page.plantkey}?${queryString}`
            : `${config.backend.host}${config.backend.endPoint.statistics.plantYieldDown}/${page.plantkey}?${queryString}`;
        const download = document.createElement('a');
        download.href = url;
        download.setAttribute('download', '');
        download.setAttribute('type', 'application/json');
        download.click();
      }
      // subValue === 4
      else if (subValue === 4) {
        setIsDownloading(true);

        const queryStringArr = [
          `envGroup=${1}`,
          `envRange=${0}`,
          `dateTime=${convertFormatStringToYMD(dateValue)}`,
          `downloadType=${0}`,
        ];
        const queryString = queryStringArr.join('&');

        const url =
          authCtx.isAndroid || authCtx.isiOS
            ? `${config.backend.hostMo}${config.backend.endPoint.envs.down}/${page.plantkey}?${queryString}`
            : `${config.backend.host}${config.backend.endPoint.envs.down}/${page.plantkey}?${queryString}`;

        const download = document.createElement('a');
        download.href = url;
        download.setAttribute('download', '');
        download.setAttribute('type', 'application/json');
        download.click();
      }
      // subValue === 5
      else if (subValue === 5) {
        setIsDownloading(true);

        const queryStringArr = [
          `YieldGroupType=${4}`,
          `YieldType=${0}`,
          `dateTime=${convertFormatStringToYMD(dateValue)}`,
          `downloadType=${0}`,
        ];
        const queryString = queryStringArr.join('&');

        const url =
          authCtx.isAndroid || authCtx.isiOS
            ? `${config.backend.hostMo}${config.backend.endPoint.statistics.muxAnalysisDown}/${page.plantkey}?${queryString}`
            : `${config.backend.host}${config.backend.endPoint.statistics.muxAnalysisDown}/${page.plantkey}?${queryString}`;

        const download = document.createElement('a');
        download.href = url;
        download.setAttribute('download', '');
        download.setAttribute('type', 'application/json');
        download.click();
      }
    } catch (error) {
      console.error(error);
      alert('문서 다운로드 중 오류가 발생하였습니다. 인터넷 연결 상태를 확인 후 다시 시도 해주세요.');
    }
  };

  const exportPDFClickHandler = async () => {
    setIsDownloading(true);

    let tab;
    if (tabValue === 'all') {
      tab = 0;
    } else if (tabValue === 'group') {
      tab = 1;
    } else if (tabValue === 'inverter') {
      tab = 2;
    }

    const queryStringArr = [
      `yieldGroupType=${tab}`,
      `yieldtype=${subValue}`,
      `dateTime=${convertFormatStringToYMD(dateValue)}`,
      `downloadType=${1}`,
    ];
    const queryString = queryStringArr.join('&');

    const url =
      authCtx.isAndroid || authCtx.isiOS
        ? `${config.backend.hostMo}${config.backend.endPoint.statistics.plantYieldDown}/${page.plantkey}?${queryString}`
        : `${config.backend.host}${config.backend.endPoint.statistics.plantYieldDown}/${page.plantkey}?${queryString}`;

    const download = document.createElement('a');
    download.href = url;
    download.setAttribute('download', '');
    download.setAttribute('type', 'application/json');
    download.click();
  };

  const chartViewClick = () => {
    setChartView(true);
  };
  const TableViewClick = () => {
    setChartView(false);
  };

  // 전체 발전소
  const numPlants = Array.isArray(selectPlant) ? selectPlant.length : 0;

  useEffect(() => {
    fetchUserPlants();
    fetchUserStylePreference();
  }, []);

  useEffect(() => {
    if (page.plantkey !== '') {
      fetchPlantInverters();
      setSelectPlantInverters([]);
      fetchSubscriptionPeriod();
    }
  }, [page.plantkey]);

  useEffect(() => {
    // 초기 렌더링 시에 호출될 로직
    const localStorageSelectPlantkey = localStorage.getItem('selectPlantkey');

    // selectPlantState에서 해당하는 발전소 탐색
    const initialSelectedPlant = selectPlant.find((option) => option.plantkey === localStorageSelectPlantkey);

    // 첫번째 발전소 탐색
    const initialSelectedPlantN = selectPlant[0];

    // 찾은 발전소를 초기값으로 설정
    if (initialSelectedPlant) {
      selectPlantChangeHandler(initialSelectedPlant);

      setPage((prev) => ({
        ...prev,
        plantkey: initialSelectedPlant.plantkey,
        plantname: initialSelectedPlant.plantname,
      }));
    }
    // 찾은 발전소가 없는 경우 첫번째 발전소를 초기값으로 설정
    else if (initialSelectedPlant === undefined && initialSelectedPlantN !== undefined) {
      selectPlantChangeHandler(initialSelectedPlantN);

      setPage((prev) => ({
        ...prev,
        plantkey: initialSelectedPlantN?.plantkey,
        plantname: initialSelectedPlantN?.plantname,
      }));
    }
  }, [selectPlant]);

  useEffect(() => {
    if (subValue === 0) {
      setPage((prevPage) => ({ ...prevPage, pagetype: 'hour' }));
      setSearchState(true);
    } else if (subValue === 1) {
      setPage((prevPage) => ({ ...prevPage, pagetype: 'day' }));
      setSearchState(true);
    } else if (subValue === 2) {
      setPage((prevPage) => ({ ...prevPage, pagetype: 'month' }));
      setSearchState(true);
    } else if (subValue === 3) {
      setPage((prevPage) => ({ ...prevPage, pagetype: 'year' }));
      setSearchState(true);
    } else if (subValue === 4) {
      setPage((prevPage) => ({ ...prevPage, pagetype: 'envStat' }));
      setSearchState(true);
    } else if (subValue === 5) {
      setPage((prevPage) => ({ ...prevPage, pagetype: 'comprehensiveAnalysis' }));
      setSearchState(true);
    }

    if (tabValue === 'all') {
      setPage((prevPage) => ({ ...prevPage, tabtype: 'all' }));
    } else if (tabValue === 'group') {
      setPage((prevPage) => ({ ...prevPage, tabtype: 'group' }));
    } else if (tabValue === 'inverter') {
      setPage((prevPage) => ({ ...prevPage, tabtype: 'inverter' }));
    }

    if (envTabValue === 1) {
      setPage((prevPage) => ({ ...prevPage, envtabtype: 1 }));
    } else if (envTabValue === 2) {
      setChartView((prevPage) => ({ ...prevPage, envtabtype: 2 }));
    }
  }, [subValue, tabValue, envTabValue]);

  let componentToShow;

  switch (subValue) {
    case 0:
      componentToShow = (
        <Hour
          page={page}
          subValue={subValue}
          tabValue={tabValue}
          dateValue={dateValue}
          searchState={searchState}
          setSearchState={setSearchState}
          chartView={chartView}
        />
      );
      break;
    case 1:
      componentToShow = (
        <Day
          page={page}
          subValue={subValue}
          tabValue={tabValue}
          dateValue={dateValue}
          searchState={searchState}
          setSearchState={setSearchState}
          chartView={chartView}
        />
      );
      break;
    case 2:
      componentToShow = (
        <Month
          page={page}
          subValue={subValue}
          tabValue={tabValue}
          dateValue={dateValue}
          searchState={searchState}
          setSearchState={setSearchState}
          chartView={chartView}
        />
      );
      break;
    case 3:
      componentToShow = (
        <Year
          page={page}
          subValue={subValue}
          tabValue={tabValue}
          dateValue={dateValue}
          searchState={searchState}
          setSearchState={setSearchState}
          chartView={chartView}
        />
      );
      break;
    case 4:
      componentToShow = (
        <EnvStat
          page={page}
          subValue={subValue}
          envTabValue={envTabValue}
          dateValue={dateValue}
          searchState={searchState}
          setSearchState={setSearchState}
          chartView={chartView}
        />
      );
      break;
    case 5:
      componentToShow = (
        <ComprehensiveAnalysis
          page={page}
          subValue={subValue}
          dateValue={dateValue}
          searchState={searchState}
          setSearchState={setSearchState}
          plantInverters={plantInverters}
          selectPlantInverters={selectPlantInverters}
          comprehensiveAnalysis={comprehensiveAnalysis}
          setComprehensiveAnalysis={setComprehensiveAnalysis}
          comprehensiveSearchState={comprehensiveSearchState}
          setComprehensiveSearchState={setComprehensiveSearchState}
          processedAnalysisData={processedAnalysisData}
          setProcessedAnalysisData={setProcessedAnalysisData}
        />
      );
      break;
    default:
      componentToShow = null;
      break;
  }

  // 만료 발전소 상품 및 구독 라이선스 입력 페이지 안내 모달
  const openSubscriptionGuideModalHandler = () => {
    setOpenSubscriptionGuideModal(true);
  };
  const closeSubscriptionGuideModalHandler = () => {
    setOpenSubscriptionGuideModal(false);
  };

  usePageLogRecord('statistics', page.plantkey);

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        plantkey={page.plantkey}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'flex',
          },
        }}
      >
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <StyledBreadcrumb label="통계" icon={<InsightsIcon fontSize="small" color="primary" />} />
          <StyledBreadcrumb
            label={
              subValue === 0
                ? '시간'
                : subValue === 1
                ? '일간'
                : subValue === 2
                ? '월간'
                : subValue === 3
                ? '연간'
                : subValue === 4
                ? '환경정보통계'
                : subValue === 5
                ? '종합분석통계'
                : null
            }
          />
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          my: 2,
          gap: 1,
          mt: isDesktop ? 2.5 : 7.5,
        }}
        justifyContent="space-between"
      >
        {selectPlant.length > 1 ? (
          <Box display="flex" alignItems="center" sx={{ width: '100%' }}>
            {numPlants > 11 ? (
              <Autocomplete
                disableClearable
                id="combo-box-demo"
                options={selectPlant}
                getOptionLabel={(option) => option.plantname || ''}
                value={
                  selectPlant.find((option) => option.plantkey === page.plantkey) ||
                  selectPlant.find(
                    (option) => option.plantkey === parseInt(localStorage.getItem('selectPlantkey'), 10)
                  ) ||
                  selectPlant[0]
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    const selectedPlant = selectPlant.find((option) => option.plantkey === newValue.plantkey);
                    selectPlantChangeHandler(selectedPlant);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="발전소" variant="outlined" sx={{ bgcolor: '#fff' }} />
                )}
                sx={{ width: isDesktop ? '45%' : '100%' }}
              />
            ) : (
              <FormControl sx={{ width: isDesktop ? '45%' : '100%', bgcolor: '#fff' }}>
                <InputLabel>발전소</InputLabel>
                <Select
                  label="발전소"
                  value={
                    selectPlant.find((option) => option.plantkey === page.plantkey)?.plantkey ||
                    selectPlant.find(
                      (option) => option.plantkey === parseInt(localStorage.getItem('selectPlantkey'), 10)
                    )?.plantkey ||
                    selectPlant[0]?.plantkey
                  }
                  onChange={(e) => {
                    const selectedPlant = selectPlant.find((option) => option.plantkey === e.target.value);
                    selectPlantChangeHandler(selectedPlant);
                  }}
                >
                  {selectPlant.map((option) => (
                    <MenuItem key={option.plantkey} value={option.plantkey}>
                      {option.plantname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {subscribeday !== null && subscribeday > -90 ? (
              subscribeday < 0 ? (
                <Chip
                  label={`서비스 만료 D${subscribeday}`}
                  color="error"
                  variant="outlined"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1, bgcolor: '#fff' }}
                />
              ) : (
                <Chip
                  label="서비스 만료"
                  color="error"
                  variant="contained"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1 }}
                />
              )
            ) : null}
          </Box>
        ) : (
          <>
            <Card sx={{ border: '0.15px solid #D8D8D8', p: 1.5, width: isDesktop ? '45%' : '100%' }}>
              <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                {page.plantname}
              </Typography>
            </Card>
            {subscribeday !== null && subscribeday > -90 ? (
              subscribeday < 0 ? (
                <Chip
                  label={`서비스 만료 D${subscribeday}`}
                  color="error"
                  variant="outlined"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1, bgcolor: '#fff' }}
                />
              ) : (
                <Chip
                  label="서비스 만료"
                  color="error"
                  variant="contained"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1 }}
                />
              )
            ) : null}
          </>
        )}
        {isDesktop && subValue !== 5 ? (
          <ButtonGroup
            variant="outlined"
            color="inherit"
            size={isDesktop ? 'medium' : 'small'}
            sx={{ bgcolor: 'white' }}
          >
            <Button
              onClick={exportExcelClickHandler}
              sx={{ justifyContent: 'space-evenly', borderColor: '#d0d0d0' }}
            >
              <img
                src="https://img.icons8.com/color/48/null/microsoft-excel-2019--v1.png"
                style={{ maxWidth: '40%' }}
              />
              EXCEL
            </Button>
            {/* <Button
              onClick={exportPDFClickHandler}
              sx={{ justifyContent: 'space-evenly', borderColor: '#d0d0d0' }}
            >
              <img src="https://img.icons8.com/color/48/null/pdf-2--v1.png" style={{ maxWidth: '45%' }} />
              PDF
            </Button> */}
          </ButtonGroup>
        ) : null}
      </Box>
      {isDesktop ? (
        <>
          <TabMenu menu="statistics" subValue={subValue} setSubValue={setSubValue} />
          <WebSearchBar
            tabValue={tabValue}
            envTabValue={envTabValue}
            subValue={subValue}
            dateValue={dateValue}
            setDateValue={setDateValue}
            plantInverters={plantInverters}
            selectPlantInverters={selectPlantInverters}
            setSelectPlantInverters={setSelectPlantInverters}
            comprehensiveAnalysis={comprehensiveAnalysis}
            setComprehensiveAnalysis={setComprehensiveAnalysis}
            setSearchState={setSearchState}
            setComprehensiveSearchState={setComprehensiveSearchState}
            isDesktop={isDesktop}
            handleTabChange={handleTabChange}
            handleEnvTabChange={handleEnvTabChange}
          />
        </>
      ) : (
        <>
          <MobileSearchBar
            tabValue={tabValue}
            envTabValue={envTabValue}
            subValue={subValue}
            dateValue={dateValue}
            setDateValue={setDateValue}
            setSearchState={setSearchState}
            isDesktop={isDesktop}
            handleTabChange={handleTabChange}
            handleEnvTabChange={handleEnvTabChange}
          />
          <Divider sx={{ mt: 1, border: '1px solid #d0d0d0' }} />
          <Box display="flex" justifyContent="space-between">
            <Box>
              <ButtonGroup
                variant="outlined"
                color="inherit"
                size={isDesktop ? 'medium' : 'small'}
                sx={{ bgcolor: 'white', mt: 1.5 }}
              >
                <Button
                  onClick={exportExcelClickHandler}
                  sx={{ justifyContent: 'space-evenly', borderColor: '#d0d0d0' }}
                >
                  <RiFileExcel2Fill style={{ fontSize: '1.6rem', color: 'green', marginRight: 2 }} />
                  EXCEL
                </Button>
                {/* <Button sx={{ justifyContent: 'space-evenly', borderColor: '#d0d0d0' }}>
                  <BiSolidFilePdf style={{ fontSize: '1.9rem', color: 'red', marginRight: 2 }} />
                  PDF
                </Button> */}
              </ButtonGroup>
            </Box>
            <Box>
              <ButtonGroup variant="outlined" color="inherit" size="small" sx={{ bgcolor: 'white', mt: 1.5 }}>
                <Button
                  onClick={chartViewClick}
                  sx={{
                    borderColor: '#d0d0d0',
                    bgcolor: chartView ? '#0059B2' : '#DDE1EA',
                    color: chartView ? '#fff' : '#000',
                    '&:hover': {
                      bgcolor: chartView ? '#0059B2' : '#DDE1EA',
                    },
                    '&:active, &:focus': {
                      bgcolor: chartView ? '#0059B2' : '#DDE1EA',
                    },
                  }}
                >
                  <BarChartIcon sx={{ fontSize: '1.6rem' }} />
                </Button>
                <Button
                  onClick={TableViewClick}
                  sx={{
                    borderColor: '#d0d0d0',
                    bgcolor: chartView ? '#DDE1EA' : '#0059B2',
                    color: chartView ? '#000' : '#fff',
                    '&:hover': {
                      bgcolor: chartView ? '#DDE1EA' : '#0059B2',
                    },
                    '&:active, &:focus': {
                      bgcolor: chartView ? '#DDE1EA' : '#0059B2',
                    },
                  }}
                >
                  <FormatListBulletedIcon sx={{ fontSize: '1.6rem' }} />
                </Button>
              </ButtonGroup>
            </Box>
          </Box>
        </>
      )}
      <Box sx={{ mt: isDesktop ? 1 : 0, filter: subscribeday - graceperiodDay > 0 ? 'blur(5px)' : '' }}>
        {componentToShow}
      </Box>
      {openSubscriptionGuideModal && (
        <SubscriptionGuideModal
          plantkey={page.plantkey}
          plantName={page.plantname}
          open={openSubscriptionGuideModal}
          onClose={closeSubscriptionGuideModalHandler}
        />
      )}
    </>
  );
}
