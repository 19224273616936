import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { convertStringToFormatDateString } from '../../utils/dateUtil';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'grey',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

// 아이디 찾기를 통해 등록된 아이디 보여주는 컴포넌트
export default function FindIdList({ data, cancelJoin, moveFindPwdHandler, isMobile }) {
  const columns = [
    { field: 'id', headerName: '아이디', width: 100 },
    { field: 'uname', headerName: '이름', width: 100 },
    { field: 'regdate', headerName: '가입일', width: 100 },
  ];

  return (
    <>
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1" sx={{ fontSize: isMobile ? 14 : 16 }}>
          등록된 아이디는 다음과 같습니다.
        </Typography>
        <Typography variant="body2" sx={{ fontSize: isMobile ? 12 : 14, mt: 0.5 }}>
          (비밀번호 재설정을 원하시면 비밀번호 재설정 버튼을 눌러주세요.)
        </Typography>
      </Box>
      <Card
        sx={{
          mt: 2,
          borderRight: 1,
          borderLeft: 1,
          borderTop: 1,
          borderBottom: 1,
          borderColor: '#d0d0d0',
        }}
      >
        <StyledTableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.field}
                    align="center"
                    style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                  >
                    {column.headerName}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index} index={index}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.field}
                      align="center"
                      sx={{
                        minWidth: column.width,
                        backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                        borderRight: '1px solid #d0d0d0',
                      }}
                    >
                      {column.field === 'regdate'
                        ? convertStringToFormatDateString(row[column.field])
                        : row[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Card>
      <Box sx={{ mt: 2.5, mb: 2, width: '100%' }}>
        <Button
          type="button"
          variant="contained"
          onClick={cancelJoin}
          size="large"
          sx={{ width: '100%', mt: 1 }}
        >
          확인
        </Button>
        <Button
          type="button"
          variant="outlined"
          onClick={moveFindPwdHandler}
          size="large"
          sx={{ width: '100%', mt: 1 }}
        >
          비밀번호 재설정
        </Button>
      </Box>
    </>
  );
}
