import { Box, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import PlantSettingModal from '../../Setting/PlantSetting/PlantSettingModal';
import WeatherInfoModal from '../WeatherInfoModal';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import { useNavigate } from 'react-router-dom';
import EachPlantStateInfo from './EachPlantStateInfo';
import EachPlantTodayWeatherForecast from './EachPlantTodayWeatherForecast';
import EachPlantWeatherObserveInfoWeb from './EachPlantWeatherObserveInfoWeb';
import EachPlantInfoMobile from './EachPlantInfoMobile';
import AppWidgetEachSummary from './AppWidgetEachSummary';
import AppWidgetEachDevelopment from './AppWidgetEachDevelopment';
import AppWidgetTimeOfDevelopment from './AppWidgetTimeOfDevelopment';
import AppWidgetMonthSummary from './AppWidgetMonthSummary';
import EachPlantTodayObservationWidget from './EachPlantTodayObservationWidget';

// 개별 대시보드 발전소 정보 표시 모바일 컴포넌트
export default function EachPlantInfoCardMobile({
  page,
  nowTime,
  roundedTotalDailyYield,
  sumTotalYield,
  roundedTotalDailyDevelopmentTime,
  roundedTotalMonthyYield,
  todayEnvData,
  basePlantInfo,
  invDailyDevelopment,
  roundedTotalGhgReduction,
  roundedTotalPineTree,
  isDesktop,
}) {
  const navigate = useNavigate();

  const [todayWeather, setTodayWeather] = useState('');
  const [openPlantsetting, setOpenPlantSetting] = useState(false);
  const [openWeatherInfoModal, setOpenWeatherInfoModal] = useState(false);
  const [subscribeday, setSubscribeday] = useState(null);

  const backendRequest = useBackendV2();

  const fetchWeatherObservation = async () => {
    try {
      const weatherObservationRequestConfig = {
        method: 'POST',
        body: {
          address: page.plantAddress,
        },
      };
      const weatherObservationResult = await backendRequest.sendRequest(
        config.backend.endPoint.envs.weather,
        weatherObservationRequestConfig
      );

      if (weatherObservationResult.data !== null) {
        setTodayWeather(weatherObservationResult.data);
      } else {
        alert('오류가 발생하였습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchSubscriptionPeriod = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.subscribe}/${page.plantkey}`
      );

      const filteredData = data.filter((item) => item.subscribeday > -90);

      if (filteredData.length > 0) {
        const maxSubdcribedayItem = filteredData.reduce((maxItem, currentItem) => {
          return currentItem.subscribeday > maxItem.subscribeday ? currentItem : maxItem;
        });
        setSubscribeday(maxSubdcribedayItem.subscribeday);
      } else {
        setSubscribeday(null);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchWeatherObservation();

    if (page.plantkey !== '') {
      fetchSubscriptionPeriod();
    }
  }, [page]);

  const showPlantSettingHandler = (event) => {
    setOpenPlantSetting(true);
  };
  const closePlantSettingHandler = () => {
    setOpenPlantSetting(false);
  };

  const showWeatherInfoModalHandler = () => {
    setOpenWeatherInfoModal(true);
  };
  const closeWeatherInfoModalHandler = () => {
    setOpenWeatherInfoModal(false);
  };
  const openSubscriptionGuideModalHandler = () => {
    localStorage.setItem('menuValue', 6);
    localStorage.setItem('menuSubValue', 0);
    navigate(`${config.frontend.productdetails}/1`, {
      state: { plantkey: page.plantkey, plantName: page.plantName },
    });
  };

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <EachPlantTodayObservationWidget
          page={page}
          nowTime={nowTime}
          roundedTotalGhgReduction={roundedTotalGhgReduction}
          roundedTotalPineTree={roundedTotalPineTree}
          todayWeather={todayWeather}
          setTodayWeather={setTodayWeather}
        />
        <Grid item xs={12}>
          <Grid container spacing={0.5}>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <AppWidgetEachDevelopment total={sumTotalYield} color="info" />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <AppWidgetMonthSummary total={roundedTotalMonthyYield} color="success" />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <AppWidgetEachSummary
                total={roundedTotalDailyYield}
                price={25972}
                isDesktop={isDesktop}
                color="warning"
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <AppWidgetTimeOfDevelopment total={roundedTotalDailyDevelopmentTime} color="success" />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={0} sx={{ mb: 2, mt: 1 }}>
        <Grid item xs={12}>
          <Grid container spacing={1}>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
              <EachPlantStateInfo
                page={page}
                basePlantInfo={basePlantInfo}
                invDailyDevelopment={invDailyDevelopment}
              />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
              <EachPlantInfoMobile basePlantInfo={basePlantInfo} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
              <EachPlantTodayWeatherForecast todayWeather={todayWeather} />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 2 }}>
              <EachPlantWeatherObserveInfoWeb todayEnvData={todayEnvData} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <PlantSettingModal
        plantkey={page.plantkey}
        plantName={page.plantName}
        open={openPlantsetting}
        onClose={closePlantSettingHandler}
      />
      <WeatherInfoModal
        open={openWeatherInfoModal}
        onClose={closeWeatherInfoModalHandler}
        page={page}
        nowTime={nowTime}
      />
    </>
  );
}
