import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';

export default function SettingSubPlant({ data, updateSubplantClickHandler }) {
  return (
    <>
      <Card
        variant="outlined"
        sx={{
          mt: 1.5,
          padding: {
            xs: 0,
            sm: 1.5,
          },
          borderColor: '#AEAEAE',
          borderRadius: '8px',
          boxShadow: '0px 3px 1px #00000014',
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          width="100%"
          spacing={2}
        >
          <Stack
            sx={{
              padding: {
                xs: 1,
                sm: 0,
              },
            }}
            flex={1}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1">{data.spname}</Typography>
              <Button
                variant="outlined"
                size="small"
                onClick={() => updateSubplantClickHandler(data.subplantkey)}
              >
                수정
              </Button>
            </Box>
            <Divider sx={{ mb: 1, mt: 1 }} />
            <Box
              sx={{
                // mt: 2,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="subtitle2">판넬 타입</Typography>
              <Typography variant="subtitle1">{data.paneltype}</Typography>
            </Box>
            <Box
              sx={{
                mt: 1,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="subtitle2">온도 계수</Typography>
              <Typography variant="subtitle1">{data.ptco}</Typography>
            </Box>
            <Box
              sx={{
                mt: 1,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="subtitle2">방위</Typography>
              <Typography variant="subtitle1">{data.orientation}°</Typography>
            </Box>
            <Box
              sx={{
                mt: 1,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="subtitle2">경사</Typography>
              <Typography variant="subtitle1">{data.tilt}°</Typography>
            </Box>
            <Box
              sx={{
                mt: 1,
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <Typography variant="subtitle2">면적</Typography>
              <Typography variant="subtitle1">{data.area}m²</Typography>
            </Box>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}
