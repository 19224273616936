import ConnectBoardNot from './ConnectionBoard/ConnectBoardNot';
import ConnectBoardJbox from './ConnectionBoard/ConnectBoardJbox';
import ConnectBoardString from './ConnectionBoard/ConnectBoardString';
import { Box } from '@mui/material';

// 스트링 및 접속반 구성 컴포넌트
export default function ConnectBoard({
  plantState,
  jboxDetail,
  setJboxDetail,
  subscribeday,
  graceperiodDay,
}) {
  return (
    <Box sx={{ filter: subscribeday - graceperiodDay > 0 ? 'blur(5px)' : '' }}>
      {plantState.jboxcnt !== (null || '' || 0) ? (
        <ConnectBoardJbox
          plantkey={plantState.plantkey}
          jboxDetail={jboxDetail}
          setJboxDetail={setJboxDetail}
          subscribeday={subscribeday}
        />
      ) : plantState.stringcnt !== (null || '' || 0) ? (
        <ConnectBoardString plantkey={plantState.plantkey} subscribeday={subscribeday} />
      ) : (
        <ConnectBoardNot />
      )}
    </Box>
  );
}
