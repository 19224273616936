import { Box, Button, TextField, Typography } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import { fNumberWithCommas } from '../../utils/formatNumber';
import useBackendV2 from '../../hooks/use-backend-v2';
import { useContext, useEffect } from 'react';
import AuthContext from '../../store/auth-context';
import config from '../../config';
import useInput from '../../hooks/use-input';
import { validateName } from '../../utils/validationUtil';

// 무통장 입금 확인 요청 안내 모달 컴포넌트
export default function BanktransferQuestionModal({
  orderid,
  orderName,
  totalAmount,
  setOrderDepositorStatus,
  open,
  onClose,
}) {
  const authCtx = useContext(AuthContext);

  const nameInput = useInput((value) => validateName(value));
  const phoneInput = useInput('');

  const backendRequest = useBackendV2();

  const fetchGetDepositor = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.payments.getDepositor}/${orderid}`
      );

      nameInput.setValue(data[0]?.depositorName);
      phoneInput.setValue(data[0]?.depositorPhoneNumber);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const confirmBankTransfer = async () => {
    try {
      const requestConfig = {
        method: 'POST',
        body: {
          USerId: authCtx?.currentUser?.id,
          OrderId: orderid,
          OrderName: orderName,
          Amount: totalAmount,
          UserName: nameInput.value === '' ? null : nameInput.value,
        },
      };

      const result = await backendRequest.sendRequest(
        config.backend.endPoint.payments.requestConfirmBankTransferAsync,
        requestConfig
      );

      if (result.data === true) {
        alert('무통장 입금 확인 요청이 완료되었습니다.');
        setOrderDepositorStatus(true);
        closeModalHandler();
      } else {
        alert('진행중 오류가 발생하였습니다.');
        closeModalHandler();
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const formatPhoneNumber = (phone) => {
    if (!phone) return phone;
    return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  };

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  useEffect(() => {
    fetchGetDepositor();
  }, []);

  return (
    <>
      <CustomModal
        open={open}
        onClose={closeModalHandler}
        sx={{ width: { xs: '95%', md: 550 }, maxHeight: '95%' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <LocalAtmIcon fontSize="large" />
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>무통장 입금 확인</Typography>
        </Box>
        <hr />
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">주문번호 : {orderid}</Typography>
          <Typography variant="subtitle1">상품명 : {orderName}</Typography>
          <Typography variant="subtitle1">입금 금액 : {fNumberWithCommas(totalAmount)}원</Typography>
          <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
            <Typography variant="subtitle1">입금자 명 : </Typography>
            <TextField
              variant="outlined"
              size="small"
              required
              sx={{ backgroundColor: 'white', ml: 1 }}
              onChange={nameInput.valueChangeHandler}
              onBlur={nameInput.inputBlurHandler}
              value={nameInput.value}
            />
          </Box>
          <Typography variant="subtitle2" sx={{ color: 'red', mt: 0.4 }}>
            *신청자와 입금자가 다른경우 입금자 명을 수정해주세요.
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1 }}>
            입금자 연락처 : {formatPhoneNumber(phoneInput.value)}
          </Typography>
        </Box>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& > button': {
              width: '70%',
              mb: 1,
            },
          }}
        >
          <Button variant="contained" onClick={confirmBankTransfer}>
            입금 확인 요청
          </Button>
          <Button variant="outlined" onClick={closeModalHandler}>
            닫기
          </Button>
        </Box>
      </CustomModal>
    </>
  );
}
