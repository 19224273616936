import { List, ListItemButton, ListItemIcon, Typography } from '@mui/material';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { useState } from 'react';
import StatusPlatePage from '../../../pages/StatusPlate/StatusPlatePage';

// 메뉴 - 현황판 컴포넌트
export default function StatusPlateMenu({ webNavOpen }) {
  const [showStatusPlateModal, setShowStatusPlateModal] = useState(false);

  const openShowStatusPlateClickHandler = () => {
    setShowStatusPlateModal(true);
  };
  const closeShowStatusPlateClickHandler = () => {
    setShowStatusPlateModal(false);
  };

  return (
    <>
      <List component="nav">
        <ListItemButton onClick={openShowStatusPlateClickHandler}>
          <ListItemIcon>
            <AssignmentOutlinedIcon />
          </ListItemIcon>
          {webNavOpen ? <Typography>현황판</Typography> : null}
        </ListItemButton>
      </List>
      <StatusPlatePage open={showStatusPlateModal} onClose={closeShowStatusPlateClickHandler} />
    </>
  );
}
