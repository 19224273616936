import { Box, Breadcrumbs, Button, Container } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../store/auth-context';
import { useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import SensorOccupiedOutlinedIcon from '@mui/icons-material/SensorOccupiedOutlined';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import ConnectionPlant from '../../components/Plant/ConnectionPlantPageComponent/ConnectionPlant';
import ConnectionPlantTabMenu from '../../components/Plant/ConnectionPlantPageComponent/ConnectionPlantTabMenu';
import Header02 from '../../components/Layout/Header02';
import SubHeader from '../../components/Layout/SubHeader';
import Nav from '../../components/Layout/Nav';
import usePageLogRecord from '../../hooks/usePageLogRecord';

// 발전소 연계 등록 페이지 컴포넌트
export default function ConnectionPlantPage() {
  const isDesktop = useResponsive('up', 'lg');

  const authCtx = useContext(AuthContext);

  const loggedId = authCtx?.currentUser?.id;

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const distance = 0;

  const [rows, setRows] = useState([]);

  // 탭 상태
  const [tabValue, setTabValue] = useState('received');

  const backendRequest = useBackendV2();

  // 발전소 연계 정보 탭 매뉴
  const fetchConnectionPlant = async (connStatus) => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.connPlant}?id=${loggedId}&conn=${connStatus}`
      );
      setRows(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchConnectionPlant(tabValue);
  }, [tabValue]);

  useEffect(() => {
    fetchUserStylePreference();
  }, []);

  // 탭 상태 업데이트
  const handleTabChange = (tabIndex) => {
    const connStatus = tabIndex === 0 ? 'received' : 'applied';
    setTabValue(connStatus);
  };

  const moveRequestConnectionPlantClickHandler = () => {
    navigate(`${config.frontend.connectionPlantsRequest}`);
  };

  // 모바일 및 태블릿 화면 구성을 위한 상수 선언
  const isGalaxySmall = useMediaQuery('(min-width: 280px) and (max-width: 369px)');
  const isMobile = useMediaQuery('(min-width: 370px) and (max-width: 699px)');
  const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1199px)');

  usePageLogRecord('connectionPlants');

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        // plantkey={page.plantkey}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      {isDesktop || isTablet ? (
        <Container
          sx={{
            width: '100%',
            marginBottom: '4%',
          }}
        >
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <StyledBreadcrumb
              label="발전소 연계"
              icon={<SensorOccupiedOutlinedIcon fontSize="small" color="primary" />}
            />
            <StyledBreadcrumb label="목록" />
          </Breadcrumbs>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 2 }}>
            <ConnectionPlantTabMenu value={tabValue} onTabChange={handleTabChange} />
            <Button variant="contained" onClick={moveRequestConnectionPlantClickHandler}>
              연계 신청
            </Button>
          </Box>
          <ConnectionPlant rows={rows} tabValue={tabValue} />
        </Container>
      ) : isMobile ? (
        <Container
          sx={{
            width: '100%',
            marginBottom: '4%',
          }}
        >
          <Box sx={{ display: 'flex', justifyContent: 'right', alignItems: 'center', mt: 2 }}>
            {/* <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <StyledBreadcrumb
                label="발전소 연계"
                icon={<SensorOccupiedOutlinedIcon fontSize="small" color="primary" />}
              />
              <StyledBreadcrumb label="목록" />
            </Breadcrumbs> */}
            <Button size="medium" variant="contained" onClick={moveRequestConnectionPlantClickHandler}>
              연계 신청
            </Button>
          </Box>
          <Box sx={{ mt: 3, mb: 3 }}>
            <ConnectionPlantTabMenu value={tabValue} onTabChange={handleTabChange} />
          </Box>
          <ConnectionPlant rows={rows} tabValue={tabValue} />
        </Container>
      ) : isGalaxySmall ? (
        <Container
          sx={{
            width: '100%',
            marginBottom: '4%',
          }}
        >
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
              },
            }}
          >
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <StyledBreadcrumb
                label="발전소 연계"
                icon={<SensorOccupiedOutlinedIcon fontSize="small" color="primary" />}
              />
              <StyledBreadcrumb label="목록" />
            </Breadcrumbs>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 2 }}>
            <Button
              sx={{ width: '100%' }}
              variant="contained"
              onClick={moveRequestConnectionPlantClickHandler}
            >
              연계 신청
            </Button>
            <Box sx={{ mt: 3 }}>
              <ConnectionPlantTabMenu value={tabValue} onTabChange={handleTabChange} />
            </Box>
          </Box>
          <ConnectionPlant rows={rows} tabValue={tabValue} />
        </Container>
      ) : null}
    </>
  );
}
