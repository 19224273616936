import { Box, Grid } from '@mui/material';
import { useState } from 'react';
import PlantSettingModal from '../../Setting/PlantSetting/PlantSettingModal';
import WeatherInfoModal from '../WeatherInfoModal';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import { useNavigate } from 'react-router-dom';
import EachPlantStateInfo_DNE from './EachPlantStateInfo_DNE';
import EachPlantInfoMobile_DNE from './EachPlantInfoMobile_DNE';
import AppWidgetEachSummary_DNE from './AppWidgetEachSummary_DNE';
import AppWidgetEachDevelopment_DNE from './AppWidgetEachDevelopment_DNE';
import AppWidgetInverterPower_DNE from './AppWidgetInverterPower_DNE';
import AppWidgetMonthSummary_DNE from './AppWidgetMonthSummary_DNE';

// 개별 대시보드 발전소 정보 표시 모바일 컴포넌트
export default function EachPlantInfoCardMobile_DNE({
  page,
  nowTime,
  roundedTotalDailyYield,
  sumTotalYield,
  roundedTotalDailyDevelopmentTime,
  roundedTotalMonthyYield,
  basePlantInfo,
  invDailyDevelopment,
  isDesktop,
}) {
  const navigate = useNavigate();

  const [openPlantsetting, setOpenPlantSetting] = useState(false);
  const [openWeatherInfoModal, setOpenWeatherInfoModal] = useState(false);
  const [subscribeday, setSubscribeday] = useState(null);

  const backendRequest = useBackendV2();

  const fetchSubscriptionPeriod = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.subscribe}/${page.plantkey}`
      );

      const filteredData = data.filter((item) => item.subscribeday > -90);

      if (filteredData.length > 0) {
        const maxSubdcribedayItem = filteredData.reduce((maxItem, currentItem) => {
          return currentItem.subscribeday > maxItem.subscribeday ? currentItem : maxItem;
        });
        setSubscribeday(maxSubdcribedayItem.subscribeday);
      } else {
        setSubscribeday(null);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const showPlantSettingHandler = (event) => {
    setOpenPlantSetting(true);
  };
  const closePlantSettingHandler = () => {
    setOpenPlantSetting(false);
  };

  const showWeatherInfoModalHandler = () => {
    setOpenWeatherInfoModal(true);
  };
  const closeWeatherInfoModalHandler = () => {
    setOpenWeatherInfoModal(false);
  };
  const openSubscriptionGuideModalHandler = () => {
    localStorage.setItem('menuValue', 6);
    localStorage.setItem('menuSubValue', 0);
    navigate(`${config.frontend.productdetails}/1`, {
      state: { plantkey: page.plantkey, plantName: page.plantName },
    });
  };

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Grid container spacing={0.5}>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: 1, mt: 1 }} >
              <EachPlantInfoMobile_DNE basePlantInfo={basePlantInfo} />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <AppWidgetEachSummary_DNE
                total={roundedTotalDailyYield}
                price={25972}
                isDesktop={isDesktop}
                color="warning"
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <AppWidgetInverterPower_DNE invDailyDevelopment={invDailyDevelopment} color="success" />
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mb: 1, mt: 1 }} >
              <EachPlantStateInfo_DNE
                page={page}
                basePlantInfo={basePlantInfo}
                invDailyDevelopment={invDailyDevelopment}
              />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <AppWidgetEachDevelopment_DNE total={sumTotalYield} color="info" />
            </Grid>
            <Grid item lg={3} md={6} sm={6} xs={6}>
              <AppWidgetMonthSummary_DNE total={roundedTotalMonthyYield} color="success" />
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <PlantSettingModal
        plantkey={page.plantkey}
        plantName={page.plantName}
        open={openPlantsetting}
        onClose={closePlantSettingHandler}
      />
      <WeatherInfoModal
        open={openWeatherInfoModal}
        onClose={closeWeatherInfoModalHandler}
        page={page}
        nowTime={nowTime}
      />
    </>
  );
}
