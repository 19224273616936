import { useState } from 'react';
import { Modal, CircularProgress, Box } from '@mui/material';
import config from '../../config';

// DNE Template 적용 컴포넌트
export default function UpdateDneTemplate() {
  const [isLoading, setIsLoading] = useState(false); // 로딩 상태

  return (
    <>
      {/* 로딩 중일 때 모달 창을 통해 CircularProgress 표시 */}
      <Modal
        open={isLoading} // isLoading이 true일 때 모달 열림
        aria-labelledby="loading-modal"
        aria-describedby="loading-modal-description"
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <CircularProgress />
        </Box>
      </Modal>
    </>
  );
}

// saveStyle 함수 분리
export const saveStyle = async (isDNE, userId, backendRequest) => {
  const styleValue = isDNE ? 'DNE' : 'DEFAULT'; // DNE 또는 기본 스타일 설정

  const saveDneTemplateRequstConfig = {
    method: 'PUT',
    body: {
      style: styleValue.trim(), // Switch에 따라 전달되는 스타일 값
    },
  };

  try {
    // 백엔드에 요청 전송
    const saveStyleResult = await backendRequest.sendRequest(
      `${config.backend.endPoint.users.styleUpdate}/${userId}/${styleValue}`,
      saveDneTemplateRequstConfig
    );

    // 저장 실패 시 처리
    if (!saveStyleResult.data.succeeded) {
      alert('수정에 실패하였습니다.');
    } else {
      alert('수정이 완료되었습니다.');
    }
  } catch (error) {
    console.error('오류 발생:', error);
    alert('오류가 발생했습니다.');
  }
};
