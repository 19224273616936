import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Grid, Typography, useMediaQuery } from '@mui/material';
import MainCard from '../../../theme/MainCard';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import { fNumberWithCommas } from '../../../utils/formatNumber';

// 전체 대시보드 인버터 발전 이상 표시 컴포넌트
export default function AppWidgetInverterError({
  invErr,
  handleInvNodataButtonClick,
  widgetClick,
  setWidgetClick,
  color = 'primary',
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <MainCard
        sx={{
          bgcolor: '#0059B2',
          borderRadius: '15px',
          boxShadow: '5px 6px 12px 5px #00000014',
          bgcolor: widgetClick === 'IVT' ? theme.palette[color].lighter : '#fff',
        }}
      >
        <Box>
          <Grid container direction="column">
            <Grid item>
              <Box display="flex" alignItems="center">
                <Avatar
                  variant="rounded"
                  onClick={handleInvNodataButtonClick}
                  sx={{
                    ...theme.typography.commonAvatar,
                    ...theme.typography.largeAvatar,
                    // backgroundColor: theme.palette[color].darker,
                    bgcolor: widgetClick === 'IVT' ? '#F3E2E0' : '#E2E6FB',
                    width: isMobile ? 30 : 40,
                    height: isMobile ? 30 : 40,
                    mr: 1,
                    cursor: 'pointer',
                  }}
                >
                  <AssignmentLateIcon sx={{ color: widgetClick === 'IVT' ? '#D55744' : '#0059B2' }} />
                </Avatar>
                <Typography
                  variant={isMobile ? 'subtitle1' : 'h5'}
                  sx={{
                    color: widgetClick === 'IVT' ? '#fff' : '#000',
                    display: 'flex',
                    // justifyContent: isMobile ? 'end' : 'start',
                  }}
                >
                  인버터 이상
                </Typography>
              </Box>
            </Grid>
            <Grid item sx={{ mt: 1 }}>
              <Typography
                display="flex"
                justifyContent="end"
                variant="h2"
                sx={{ color: widgetClick === 'IVT' ? '#fff' : '#000' }}
              >
                {fNumberWithCommas(invErr)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </MainCard>
    </>
  );
}
