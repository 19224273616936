import { Menu as MenuIcon } from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { AppBar, Box, IconButton, Stack, Toolbar } from '@mui/material';
import PropTypes from 'prop-types';
import AccountPopover from '../Header/AccountPopover';
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import RefreshIcon from '@mui/icons-material/Refresh';
import AuthContext from '../../store/auth-context';
import HeaderRightMenu from '../Header/HeaderRightMenu';
import HeaderLeftMenu from '../Header/HeaderLeftMenu';
import HeaderLogo from '../Header/HeaderLogo';

// 화면 최상단 헤더 컴포넌트
export default function Header02({
  onOpenNav,
  value,
  setValue,
  subValue,
  setSubValue,
  plantkey,
  distance,
  webNavOpen,
  isDesktop,
  isMobileViewDNE, // DNE 템플릿 상태 관리 prop
  setIsMobileViewDNE, // DNE 템플릿 상태 관리 setter prop
}) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const organLogoImgKey = authCtx?.currentUser?.ogLogoImgKey;

  const NAV_WIDTH = webNavOpen ? '100%' : 95;
  const HEADER_MOBILE = 60;
  const HEADER_DESKTOP = 75;

  const StyledRoot = styled(AppBar)(({ theme }) => ({
    // ...bgBlur({
    //   // color: isDesktop ? theme.palette.background.default : '#2f56d6 ',
    //   // color: theme.palette.background.default,
    //   color: '#116AFF',
    // }),
    background: 'linear-gradient(90deg, rgba(92, 130, 255, 1) 0%, rgba(157, 92, 255, 1) 100%)',
    boxShadow: 'none',
    [theme.breakpoints.up('lg')]: {
      width: `calc(100% - ${NAV_WIDTH + 1}px)`,
    },
  }));

  const StyledToolbar = styled(Toolbar)(({ theme }) => {
    return {
      minHeight: HEADER_MOBILE,
      [theme.breakpoints.up('lg')]: {
        minHeight: HEADER_DESKTOP,
        // padding: theme.spacing(0, 25),
        marginLeft: '5.5%',
        marginRight: '5%',
      },
    };
  });

  Header02.propTypes = {
    onOpenNav: PropTypes.func,
  };

  const moveHomeHandler = () => {
    setValue(0);
    setSubValue(0);
    localStorage.setItem('menuValue', 0);
    // localStorage.removeItem('selectPlantkey');
    navigate('/', { state: { subValue: 0 } });
  };

  const appRefreshClickHandler = () => {
    window.location.reload();
  };

  useEffect(() => {
    // distance가 0일 때 다시 원래 높이로 수정
    if (distance === 0) {
      StyledToolbar.minHeight = HEADER_MOBILE;
    }
  }, [distance]);

  return (
    <>
      <StyledRoot>
        <StyledToolbar>
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
              display: { lg: 'none' },
            }}
          >
            <MenuIcon sx={{ fontSize: '1.8rem', color: '#fff' }} />
          </IconButton>
          <Box
            onClick={moveHomeHandler}
            display="flex"
            sx={{ display: 'inline-flex', alignItems: 'center', cursor: isDesktop ? 'pointer' : 'cursor' }}
          >
            <HeaderLogo plantkey={plantkey} organLogoImgKey={organLogoImgKey} isDesktop={isDesktop} />
          </Box>
          {isDesktop ? (
            <>
              <HeaderLeftMenu
                value={value}
                setValue={setValue}
                subValue={subValue}
                setSubValue={setSubValue}
                isDesktop={isDesktop}
              />
            </>
          ) : null}
          <Box sx={{ flexGrow: 1 }} />
          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            {isDesktop ? (
              <>
                <HeaderRightMenu />
              </>
            ) : (
              <>
                <IconButton onClick={appRefreshClickHandler}>
                  <RefreshIcon sx={{ fontSize: '1.8rem', color: '#fff' }} />
                </IconButton>
              </>
            )}
            <AccountPopover
              value={value}
              setValue={setValue}
              subValue={subValue}
              setSubValue={setSubValue}
              isDesktop={isDesktop}
              isMobileViewDNE={isMobileViewDNE}
              setIsMobileViewDNE={setIsMobileViewDNE}
            />
          </Stack>
        </StyledToolbar>
      </StyledRoot>
    </>
  );
}
