import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: '14px',
    borderBottom: '1px solid #CECECE',
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

// 개별 발전소 웹뷰 인버터별 상세정보 테이블 컴포넌트
export default function WebWidgetInverterInfoTable({ basePlantInfo, invDailyDevelopment, plantkey }) {
  const navigate = useNavigate();

  const columns = [
    { field: 'invertername', headerName: '인버터명', width: 100 },
    { field: 'pac', headerName: '출력 전력(kW)', width: 100 },
    { field: 'dailyyield', headerName: '일일 발전량(kWh)', width: 100 },
    { field: 'generatestatus', headerName: '발전 상태', width: 90 },
  ];

  // RTU 수신상태
  const isRtuStatusOne = basePlantInfo.some((plant) => plant.rtuinfos?.some((info) => info.rtustatus === 1));

  const inverterListClickHandler = () => {
    localStorage.setItem('menuValue', 1);
    localStorage.setItem('menuSubValue', 0);

    if (plantkey !== undefined) {
      localStorage.setItem('selectPlantkey', plantkey);
    }

    navigate(config.frontend.inverters, { state: { subValue: 0 } });
  };

  return (
    <>
      <Card
        sx={{
          borderRadius: '15px',
          boxShadow: '5px 6px 12px 5px #00000014',
          height: 285,
          border: '0.2px solid #B0B0B0',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ p: 1, bgcolor: '#F1F6F9' }}
        >
          <Typography variant="subtitle1" sx={{ ml: 1 }}>
            인버터 상세 정보
          </Typography>
          <Avatar
            variant="square"
            onClick={inverterListClickHandler}
            sx={{ width: '25px', height: '25px', bgcolor: '#1981E7', mr: 1, cursor: 'pointer' }}
          >
            <ArrowForwardIosIcon fontSize="small" />
          </Avatar>
        </Box>
        <Card
          sx={{
            border: 'none',
            width: '100%',
            height: '90%',
            borderRadius: 0,
            overflowY: 'auto',
          }}
        >
          <StyledTableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledTableCell key={column.field} align="center" style={{ minWidth: column.width }}>
                      {column.headerName}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {invDailyDevelopment.map((row, index) => (
                  <TableRow key={row.serialnum} index={index}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        align="center"
                        style={{
                          minWidth: column.width,
                          backgroundColor: '#fff',
                        }}
                      >
                        {column.field === 'pac' || column.field === 'dailyyield' ? (
                          isRtuStatusOne && row.generatestatus === 1 ? (
                            row[column.field] === null ? (
                              '--,--'
                            ) : (
                              row[column.field].toFixed(1)
                            )
                          ) : (
                            '--,--'
                          )
                        ) : column.field === 'generatestatus' ? (
                          <Box display="flex" justifyContent="center">
                            <Tooltip title={row[column.field] === 1 ? '발전 중' : '발전 중단'} arrow>
                              <Avatar
                                sizes="small"
                                sx={{
                                  width: 18,
                                  height: 18,
                                  bgcolor: row[column.field] === 1 ? '#64B346' : 'red',
                                  color: row[column.field] === 1 ? '#64B346' : 'red',
                                }}
                              >
                                {''}
                              </Avatar>
                            </Tooltip>
                          </Box>
                        ) : row[column.field] ? (
                          row[column.field].toLocaleString()
                        ) : (
                          0
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Card>
      </Card>
    </>
  );
}
