import { Box, Button, ButtonGroup, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { convertFormatStringToYMD } from '../../../utils/dateUtil';
import { fNumberWithCommas } from '../../../utils/formatNumber';
import { useEffect, useState } from 'react';

// 개별 발전소 웹뷰 오늘의 REC 데이터 테이블
export default function WebWidgetTodayRecTable({ todayRec, address }) {
  const [selectPlace, setSelectPlace] = useState(false);

  useEffect(() => {
    if (address?.substring(0, 5).includes('제주')) {
      setSelectPlace(true);
    } else {
      setSelectPlace(false);
    }
  }, []);

  return (
    <>
      <Card
        sx={{
          borderRadius: '15px',
          boxShadow: '5px 6px 12px 5px #00000014',
          height: 289,
          p: 0,
          bgcolor: '#F1F6F9',
          border: '0.2px solid #B0B0B0',
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="subtitle1" sx={{ color: '#000', ml: 0.5, mt: 1, mb: 1.5 }}>
            오늘의 REC
          </Typography>
          <Typography variant="subtitle2" sx={{ color: '#505050', mr: 0.5, mt: 1, mb: 1.5 }}>
            매주 화, 목요일 10:00 ~ 16:00 개장
          </Typography>
        </Box>
        <CardContent sx={{ bgcolor: '#fff' }}>
          <Box display="flex" justifyContent="space-between" sx={{ mt: -0.5 }}>
            <Box>
              <Typography variant="subtitle2" color="inherit">
                거래일:{' '}
                {todayRec.length === 0
                  ? null
                  : selectPlace
                  ? convertFormatStringToYMD(todayRec[1].regdate)
                  : convertFormatStringToYMD(todayRec[0].regdate)}
              </Typography>
            </Box>
            <ButtonGroup sx={{ mr: -1.25 }}>
              <Button
                onClick={() => setSelectPlace(false)}
                variant={selectPlace ? 'outlined' : 'contained'}
                size="small"
              >
                육지
              </Button>
              <Button
                onClick={() => setSelectPlace(true)}
                variant={selectPlace ? 'contained' : 'outlined'}
                size="small"
              >
                제주
              </Button>
            </ButtonGroup>
          </Box>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Grid container spacing={0}>
                  <Grid item lg={5.75} md={5.75} sm={5.75} xs={5.75}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" color="inherit">
                        거래량
                      </Typography>
                      <Typography variant="subtitle1" color="inherit">
                        {todayRec.length === 0
                          ? null
                          : selectPlace
                          ? fNumberWithCommas(todayRec[1].dealcount)
                          : fNumberWithCommas(todayRec[0].dealcount)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                    <Box display="flex" justifyContent="center">
                      <Typography color="#e0e0e0">|</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={5.75} md={5.75} sm={5.75} xs={5.75}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" color="inherit">
                        평균가
                      </Typography>
                      <Typography variant="subtitle1" color="inherit">
                        {todayRec.length === 0
                          ? null
                          : selectPlace
                          ? fNumberWithCommas(todayRec[1].avgprice)
                          : fNumberWithCommas(todayRec[0].avgprice)}
                        원
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
              <Grid container direction="column">
                <Grid container spacing={0}>
                  <Grid item lg={5.75} md={5.75} sm={5.75} xs={5.75}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" color="inherit">
                        최고가
                      </Typography>
                      <Typography variant="subtitle1" color="inherit">
                        {todayRec.length === 0
                          ? null
                          : selectPlace
                          ? fNumberWithCommas(todayRec[1].maxprice)
                          : fNumberWithCommas(todayRec[0].maxprice)}
                        원
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                    <Box display="flex" justifyContent="center">
                      <Typography color="#e0e0e0">|</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={5.75} md={5.75} sm={5.75} xs={5.75}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" color="inherit">
                        최저가
                      </Typography>
                      <Typography variant="subtitle1" color="inherit">
                        {todayRec.length === 0
                          ? null
                          : selectPlace
                          ? fNumberWithCommas(todayRec[1].minprice)
                          : fNumberWithCommas(todayRec[0].minprice)}
                        원
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
              <Grid container direction="column">
                <Grid container spacing={0}>
                  <Grid item lg={5.75} md={5.75} sm={5.75} xs={5.75}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" color="inherit">
                        종가
                      </Typography>
                      <Typography variant="subtitle1" color="inherit">
                        {todayRec.length === 0
                          ? null
                          : selectPlace
                          ? fNumberWithCommas(todayRec[1].lastprice)
                          : fNumberWithCommas(todayRec[0].lastprice)}
                        원
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={0.5} md={0.5} sm={0.5} xs={0.5}>
                    <Box display="flex" justifyContent="center">
                      <Typography color="#e0e0e0">|</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
