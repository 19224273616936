import { Box, Button, Breadcrumbs, Grid, Typography, Stack, Chip, useMediaQuery } from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import { useParams, useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { useState, useEffect, useContext } from 'react';
import Divider from '@mui/material/Divider';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme } from '@mui/material/styles';
import Header02 from '../Layout/Header02';
import SubHeader from '../Layout/SubHeader';
import Nav from '../Layout/Nav';
import useResponsive from '../../hooks/useResponsive';
import AuthContext from '../../store/auth-context';

/*
공지게시판 상세 컴포넌트
*/
export default function NoticeBoardDetail() {
  // 모바일 화면 정의를 위한 훅
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useResponsive('up', 'lg');
  const authCtx = useContext(AuthContext);

  const { seq } = useParams();

  const navigate = useNavigate();

  const backendRequest = useBackendV2();

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));

  const distance = 0;

  const [row, setRow] = useState([]);
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const fetchNoticeBoardDetail = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.bulletinBoard.noticeDetail}/${seq}`
      );
      setRow(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  function handleGoList() {
    navigate(config.frontend.bulletinboards, { state: { value: 4, subValue: 1 } });
  }

  useEffect(() => {
    fetchNoticeBoardDetail();
    fetchUserStylePreference();
  }, []);

  // 모바일 반응형을 위한 상수 선언
  const isSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 513px)');
  const isMoreSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 385px)');

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      {isMobile ? (
        <>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isSmallDevice ? 'column' : null,
            }}
          >
            <Grid
              item
              xs={isSmallDevice ? 12 : 1}
              sx={{
                display: 'flex',
                alignItems: isSmallDevice ? 'left' : 'center',
                justifyContent: isSmallDevice ? 'flex-start' : 'flex-end',
              }}
            >
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <StyledBreadcrumb
                  label={isMoreSmallDevice ? '공지 게시판' : '게시판'}
                  icon={<ContentPasteIcon fontSize="small" color="primary" />}
                  galaxyFoldFontSize={isMoreSmallDevice ? '14px' : '15px'}
                />
                <StyledBreadcrumb
                  label={isMoreSmallDevice ? '공지 내용' : '공지 게시판'}
                  galaxyFoldFontSize={isMoreSmallDevice ? '14px' : '15px'}
                />
                {isMoreSmallDevice ? null : <StyledBreadcrumb label="공지 내용" />}
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              xs={isSmallDevice ? 12 : 1}
              sx={{
                display: 'flex',
                alignItems: isSmallDevice ? 'right' : 'center',
                justifyContent: isSmallDevice ? 'flex-end' : 'flex-start',
              }}
            >
              <Button
                onClick={handleGoList}
                endIcon={<ArrowForwardIcon />}
                sx={{
                  ml: 1,
                  mr: 2,
                  color: '#000',
                  textTransform: 'none',
                  mt: isSmallDevice ? 2 : 0,
                }}
              >
                <Typography variant="subtitle1">돌아가기</Typography>
              </Button>
            </Grid>
          </Grid>
          <Box
            component="form"
            sx={{
              mt: isSmallDevice ? 0 : 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'left',
              width: '100%',
            }}
          >
            <Card sx={{ width: '100%', borderRadius: '16px' }}>
              {row.length === 0 ? null : (
                <Box>
                  <Stack direction="row" sx={{ mb: 2, ml: 1, mt: 2, mr: 1 }} spacing={1}>
                    {row.noticetype === '1' ? (
                      <Chip label="로그인 후 공지" size="small" sx={{ width: '110px' }} variant="outlined" />
                    ) : row.noticetype === '2' ? (
                      <Chip label="로그인 전 공지" size="small" sx={{ width: '110px' }} variant="outlined" />
                    ) : row.noticetype === '3' ? (
                      <Chip
                        label="로그인 전,후 공지"
                        size="small"
                        sx={{ width: '110px' }}
                        variant="outlined"
                      />
                    ) : (
                      <Chip label="약관 공지" size="small" sx={{ width: '110px' }} variant="outlined" />
                    )}
                    <Typography
                      sx={{
                        textAlign: 'right',
                        font: 'normal normal normal 12px/26px Noto Sans',
                        letterSpacing: '0px',
                        color: '#8B8C9C',
                        opacity: '1',
                        width: '220px',
                      }}
                    >
                      {`${row.dateViewFrom === null ? '' : row.dateViewFrom.substring(0, 10)} ~ ${
                        row.dateViewTo === null ? '' : row.dateViewTo.substring(0, 10)
                      }`}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      ml: 1,
                      textAlign: 'left',
                      font: 'normal normal 600 15px/25px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 2,
                    }}
                  >
                    {row.title}
                  </Stack>
                  <Divider variant="middle" />
                  <Stack
                    dangerouslySetInnerHTML={{ __html: row.contents }}
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal normal 14px/24px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      ml: 2,
                      mr: 2,
                    }}
                  />
                </Box>
              )}
            </Card>
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <StyledBreadcrumb label="게시판" icon={<ContentPasteIcon fontSize="small" color="primary" />} />
              <StyledBreadcrumb label="공지 게시판" />
              <StyledBreadcrumb label="공지 내용" />
            </Breadcrumbs>
            <Button
              onClick={handleGoList}
              endIcon={<ArrowForwardIcon />}
              sx={{ textTransform: 'none', ml: 1, mr: 2, color: '#000' }}
            >
              <Typography variant="subtitle1">돌아가기</Typography>
            </Button>
          </Box>
          <Box
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'left',
              width: '100%',
            }}
          >
            <Card sx={{ width: '100%', borderRadius: '16px' }}>
              {row.length === 0 ? null : (
                <Box>
                  <Stack direction="row" sx={{ mb: 2, ml: 1, mt: 2, mr: 1 }} spacing={1}>
                    {row.noticetype === '1' ? (
                      <Chip label="로그인 후 공지" size="small" sx={{ width: '110px' }} variant="outlined" />
                    ) : row.noticetype === '2' ? (
                      <Chip label="로그인 전 공지" size="small" sx={{ width: '110px' }} variant="outlined" />
                    ) : row.noticetype === '3' ? (
                      <Chip
                        label="로그인 전,후 공지"
                        size="small"
                        sx={{ width: '110px' }}
                        variant="outlined"
                      />
                    ) : (
                      <Chip label="약관 공지" size="small" sx={{ width: '110px' }} variant="outlined" />
                    )}
                    <Typography
                      sx={{
                        textAlign: 'right',
                        font: 'normal normal normal 12px/26px Noto Sans',
                        letterSpacing: '0px',
                        color: '#8B8C9C',
                        opacity: '1',
                        width: '220px',
                      }}
                    >
                      {`${row.dateViewFrom === null ? '' : row.dateViewFrom.substring(0, 10)} ~ ${
                        row.dateViewTo === null ? '' : row.dateViewTo.substring(0, 10)
                      }`}
                    </Typography>
                  </Stack>
                  <Stack
                    sx={{
                      ml: 1,
                      textAlign: 'left',
                      font: 'normal normal 600 15px/25px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 2,
                    }}
                  >
                    {row.title}
                  </Stack>
                  <Divider variant="middle" />
                  <Stack
                    dangerouslySetInnerHTML={{ __html: row.contents }}
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal normal 14px/24px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      ml: 2,
                      mr: 2,
                    }}
                  />
                </Box>
              )}
            </Card>
          </Box>
        </>
      )}
    </>
  );
}
