import { useEffect, useState } from 'react';
import useBackendV2 from '../../../hooks/use-backend-v2';
import TransMap from './TransMap';
import VcbMap from './VcbMap';
import config from '../../../config';
import useResponsive from '../../../hooks/useResponsive';
import useInterval from '../../../hooks/use-interval';
import UseCustomSort from '../../../hooks/useCustomSort';

// 인버터 계통도 내 ACB, VCB 컴포넌트
export default function AcbVcbMap({ plantState }) {
  const isDesktop = useResponsive('up', 'lg');

  const [acbVcbInfo, setAcbVcbInfo] = useState([]);

  const getAcbVcbInfoRequest = useBackendV2();

  const fetchGetAcbVcbInfo = async () => {
    try {
      const { data } = await getAcbVcbInfoRequest.sendRequest(
        `${config.backend.endPoint.acbvcbs.extReal}/${plantState.plantkey}`
      );

      // 가져온 데이터를 'acbname' 키를 기준으로 정렬
      const sortedData = UseCustomSort(data, 'acbname');

      setAcbVcbInfo(sortedData);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchPollingInterval = 60000;

  useInterval(() => {
    fetchGetAcbVcbInfo();
  }, fetchPollingInterval);

  useEffect(() => {
    if (plantState.plantkey !== '') {
      fetchGetAcbVcbInfo();
    }
  }, [plantState]);

  // ACB와 VCB를 분리
  const acbListInfo = acbVcbInfo.filter((item) => item.acbvcbtype === 'ACB');
  const vcbListInfo = acbVcbInfo.filter((item) => item.acbvcbtype === 'VCB');

  return (
    <>
      {acbVcbInfo.length === 0 ? null : (
        <>
          <VcbMap isDesktop={isDesktop} vcbListInfo={vcbListInfo} />
          <TransMap isDesktop={isDesktop} acbListInfo={acbListInfo} />
        </>
      )}
    </>
  );
}
