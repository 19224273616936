import { List } from '@mui/material';
import { useState } from 'react';
import AlarmReceiveDetail from './AlarmReceiveDetail';

// 사용자 메뉴 앱 설정 컴포넌트
export default function AppDetail({ isMobileViewDNE, setIsMobileViewDNE }) {
  const [alarmReceiveYn, setAlarmReceiveYn] = useState(false);

  const alarmReceiveClickHandler = () => {
    setAlarmReceiveYn(!alarmReceiveYn);
  };

  return (
    <List component="nav">
      <AlarmReceiveDetail
        alarmReceiveClickHandler={alarmReceiveClickHandler}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
    </List>
  );
}
