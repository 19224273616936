import { Box } from '@mui/material';
import CustomModal from '../../components/UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import { useContext, useEffect, useRef } from 'react';
import { ANONYMOUS, loadPaymentWidget } from '@tosspayments/payment-widget-sdk';
import AuthContext from '../../store/auth-context';
import config from '../../config';
import useBackendV2 from '../../hooks/use-backend-v2';

// 토스 결제 모달 컴포넌트
export default function PaymentPage({
  plantkey,
  orderid,
  pdtname,
  price,
  userName,
  phoneNumber,
  address,
  detailAddress,
  zip,
  open,
  onClose,
}) {
  const authCtx = useContext(AuthContext);

  const paymentWidgetRef = useRef(null);
  const paymentMethodsWidgetRef = useRef(null);
  const agreementWidgetRef = useRef(null);

  const backendRequest = useBackendV2();

  useEffect(() => {
    (async () => {
      const paymentWidget = await loadPaymentWidget(process.env.REACT_APP_TOSSPAYMENTS_CLIENT_KEY, orderid);

      if (paymentWidgetRef.current == null) {
        paymentWidgetRef.current = paymentWidget;
      }

      /**
       * 결제창을 렌더링합니다.
       * @docs https://docs.tosspayments.com/reference/widget-sdk#renderpaymentmethods%EC%84%A0%ED%83%9D%EC%9E%90-%EA%B2%B0%EC%A0%9C-%EA%B8%88%EC%95%A1
       */
      const paymentMethodsWidget = paymentWidgetRef.current.renderPaymentMethods(
        '#payment-method',
        { value: price, currency: 'KRW', country: 'KR' },
        { variantKey: 'DEFAULT' }
      );

      /**
       * 약관을 렌더링합니다.
       * @docs https://docs.tosspayments.com/reference/widget-sdk#renderagreement%EC%84%A0%ED%83%9D%EC%9E%90-%EC%98%B5%EC%85%98
       */
      agreementWidgetRef.current = paymentWidgetRef.current.renderAgreement('#agreement', {
        variantKey: 'DEFAULT',
      });

      paymentMethodsWidgetRef.current = paymentMethodsWidget;
    })();
  }, []);

  const closeModalHandler = async (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }

    const removeAllRequestConfig = {
      method: 'DELETE',
    };

    const result = await backendRequest.sendRequest(
      `${config.backend.endPoint.carts.allDel}/${orderid}`,
      removeAllRequestConfig
    );

    if (result.data === true) {
      onClose();
    } else {
      window.location.reload();
      onClose();
    }
  };

  return (
    <>
      <CustomModal open={open} onClose={closeModalHandler} sx={{ width: '100%', height: '100%', p: 0 }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon onClick={closeModalHandler} fontSize="large" sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ mt: 3 }}>
          <div className="wrapper w-100">
            <div className="max-w-540 w-100">
              <div id="payment-method" className="w-100" />
              <div id="agreement" className="w-100" />
              <div className="btn-wrapper w-100">
                <button
                  className="btn primary w-100"
                  onClick={async () => {
                    const paymentWidget = paymentWidgetRef.current;

                    localStorage.setItem('pdtname', pdtname);
                    localStorage.setItem('price', price);
                    localStorage.setItem('userName', userName);
                    localStorage.setItem('phoneNumber', phoneNumber);
                    localStorage.setItem('address', address);
                    localStorage.setItem('detailAddress', detailAddress);
                    localStorage.setItem('zip', zip);
                    localStorage.setItem('plantkey', plantkey !== undefined ? plantkey : null);

                    try {
                      /**
                       * 결제 요청
                       * @docs https://docs.tosspayments.com/reference/widget-sdk#requestpayment%EA%B2%B0%EC%A0%9C-%EC%A0%95%EB%B3%B4
                       */

                      await paymentWidget?.requestPayment({
                        orderId: orderid,
                        orderName: pdtname,
                        customerName: authCtx?.currentUser?.name,
                        successUrl: window.location.origin + '/paymentPageSuccess' + window.location.search,
                        failUrl: window.location.origin + '/paymentPageFail' + window.location.search,
                      });
                    } catch (error) {
                      // TODO: 에러 처리
                    }
                  }}
                >
                  결제하기
                </button>
              </div>
            </div>
          </div>
        </Box>
      </CustomModal>
    </>
  );
}
