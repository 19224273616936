import MainCard from '../../theme/MainCard';
import { Card, Box, Typography } from '@mui/material';

// 현황판 오늘의 날씨 컴포넌트
export default function PlantWeatherCard_02({ temp, tempHigh, tempLow, humidity, wind, weatherIconMap }) {
  return (
    <>
      <MainCard
        sx={{
          // bgcolor: '#0059B2',
          borderRadius: '15px',
          boxShadow: '0px 3px 100px #00000014',
          height: 195,
        }}
      >
        <Typography variant="h6" sx={{ color: '#000' }}>
          오늘의 날씨
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="end">
          <Typography variant="subtitle2" sx={{ mr: 1 }}>
            온도 {temp}°C
          </Typography>
          <Box>{weatherIconMap}</Box>
        </Box>
        <Box display="flex">
          <Card
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              border: '1px solid #CECECE',
              bgcolor: '#F9F9F9 ',
              width: '100%',
              borderBottom: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0',
            }}
          >
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              최고
            </Typography>
            <Typography variant="subtitle1" textAlign="end" sx={{ mr: 1 }}>
              {tempHigh}℃
            </Typography>
          </Card>
          <Card
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              border: '1px solid #CECECE',
              bgcolor: '#F9F9F9 ',
              width: '100%',
              borderBottom: 'none',
              borderRadius: '0',
            }}
          >
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              최저
            </Typography>
            <Typography variant="subtitle1" textAlign="end" sx={{ mr: 1 }}>
              {tempLow}℃
            </Typography>
          </Card>
        </Box>
        <Box display="flex">
          <Card
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              border: '1px solid #CECECE',
              bgcolor: '#F9F9F9 ',
              width: '100%',
              borderBottom: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0',
            }}
          >
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              습도
            </Typography>
            <Typography variant="subtitle1" textAlign="end" sx={{ mr: 1 }}>
              {humidity}%
            </Typography>
          </Card>
          <Card
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              border: '1px solid #CECECE',
              bgcolor: '#F9F9F9 ',
              width: '100%',
              borderBottom: 'none',
              borderRadius: '0',
            }}
          >
            <Typography variant="subtitle1" sx={{ ml: 1 }}>
              바람
            </Typography>
            <Typography variant="subtitle1" textAlign="end" sx={{ mr: 1 }}>
              {wind}㎧
            </Typography>
          </Card>
        </Box>
      </MainCard>
    </>
  );
}
