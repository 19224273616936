import { Avatar, Grid } from '@mui/material';
import trans from '../../../assets/SystematicMapImg/trans.gif';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TransMapCard from './TransMapCard';
import ACB01 from '../../../assets/SystematicMapImg/ACB01.svg';

// 인버터 계통도 내 ACB 상태 표시 컴포넌트
export default function TransMap({ isDesktop, acbListInfo }) {
  // const [expanded, setExpanded] = useState(isDesktop === true ? true : false);
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let AcbCard;

  if (acbListInfo.length !== 0 && expanded === true) {
    AcbCard = acbListInfo.map((d, index) => (
      <TransMapCard key={index} acbListInfo={d} isDesktop={isDesktop} />
    ));
  }

  return (
    <>
      <Grid item lg={3} sx={{ display: 'flex', mt: 1 }}>
        <img src={ACB01} alt="trans" />
        {/* <Avatar
          onClick={handleExpandClick}
          sx={{
            alignItems: 'center',
            mt: 6,
            ml: 1,
            width: 24,
            height: 24,
            cursor: isDesktop ? 'pointer' : 'cursor',
          }}
        >
          {expanded === false ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </Avatar> */}
      </Grid>
      {expanded === false ? null : AcbCard}
    </>
  );
}
