import { useEffect, useState } from 'react';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import useInterval from '../../../hooks/use-interval';
import { Box, Card, Typography } from '@mui/material';

// 개별 발전소 웹뷰 기상관측 정보 컴포넌트
export default function WebWidgetWeatherObserveInfo({ page, acbVcbDailyDevelopment }) {
  const envDataState = {
    envGroup: 0,
    envRange: 0,
  };

  const [todayEnvData, setTodayEnvData] = useState([]);

  const backendRequest = useBackendV2();

  const fetchTodayEnvData = async () => {
    try {
      if (page.plantkey !== '') {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.envs.basic}/${page.plantkey}?${new URLSearchParams(envDataState)}`
        );

        setTodayEnvData(data);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchPollingInterval = 60000;

  useInterval(() => {
    fetchTodayEnvData();
  }, fetchPollingInterval);

  useEffect(() => {
    fetchTodayEnvData();
  }, [page]);

  return (
    <>
      <Card
        sx={{
          borderColor: '#fff',
          borderRadius: '16px',
          boxShadow: '5px 6px 12px 5px #00000014',
          position: 'relative',
          height: acbVcbDailyDevelopment === undefined ? 210 : 285,
          bgcolor: '#F1F6F9',
          border: '0.2px solid #B0B0B0',
        }}
      >
        <Typography
          variant="subtitle2"
          sx={{ color: '#000', mt: acbVcbDailyDevelopment === undefined ? 1.5 : 1, ml: 2 }}
        >
          기상 관측 정보
        </Typography>
        <Box display="flex" sx={{ mt: 1.5 }}>
          <Card
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              border: '1px solid #CECECE',
              bgcolor: '#F6F6F6 ',
              width: '100%',
              borderBottom: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0',
              p: 1,
            }}
          >
            <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
              경사 일사량
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="end"
              color="error"
              sx={{ fontSize: 17, mr: 0.5, mt: acbVcbDailyDevelopment === undefined ? 1.5 : 5.5 }}
            >
              {todayEnvData.length === 0 || todayEnvData === undefined || todayEnvData[0].trad < 0
                ? null
                : todayEnvData[0].trad}
              <span style={{ color: 'black', fontSize: 14, marginLeft: 2.5 }}>W/㎡</span>
            </Typography>
          </Card>
          <Card
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              border: '1px solid #CECECE',
              bgcolor: '#F6F6F6 ',
              width: '100%',
              borderBottom: 'none',
              borderRadius: '0',
              p: 1,
            }}
          >
            <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
              수평 일사량
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="end"
              color="error"
              sx={{ fontSize: 17, mr: 0.5, mt: acbVcbDailyDevelopment === undefined ? 1.5 : 5.5 }}
            >
              {todayEnvData.length === 0 || todayEnvData === undefined || todayEnvData[0].trad < 0
                ? null
                : todayEnvData[0].srad}
              <span style={{ color: 'black', fontSize: 14, marginLeft: 2.5 }}>W/㎡</span>
            </Typography>
          </Card>
        </Box>
        <Box display="flex">
          <Card
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              border: '1px solid #CECECE',
              bgcolor: '#F6F6F6 ',
              width: '100%',
              borderBottom: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0',
              p: 1,
            }}
          >
            <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
              판넬 온도
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="end"
              color="error"
              sx={{ fontSize: 17, mr: 0.5, mt: acbVcbDailyDevelopment === undefined ? 1.5 : 5.5 }}
            >
              {todayEnvData.length === 0 || todayEnvData === undefined || todayEnvData[0].trad < 0
                ? null
                : todayEnvData[0].otemp}
              <span style={{ color: 'black', fontSize: 14, marginLeft: 2.5 }}>℃</span>
            </Typography>
          </Card>
          <Card
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              border: '1px solid #CECECE',
              bgcolor: '#F6F6F6 ',
              width: '100%',
              borderBottom: 'none',
              borderRadius: '0',
              p: 1,
            }}
          >
            <Typography variant="subtitle2" sx={{ ml: 0.5 }}>
              외기 온도
            </Typography>
            <Typography
              variant="subtitle2"
              textAlign="end"
              color="error"
              sx={{ fontSize: 17, mr: 0.5, mt: acbVcbDailyDevelopment === undefined ? 1.5 : 5.5 }}
            >
              {todayEnvData.length === 0 || todayEnvData === undefined || todayEnvData[0].trad < 0
                ? null
                : todayEnvData[0].ptemp}
              <span style={{ color: 'black', fontSize: 14, marginLeft: 2.5 }}>℃</span>
            </Typography>
          </Card>
        </Box>
      </Card>
    </>
  );
}
