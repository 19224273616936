import { Typography } from '@mui/material';
import qubelogo from '../../assets/EtcImg/sol_logo_login.png';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { useEffect, useState } from 'react';

export default function HeaderLogo({ plantkey, organLogoImgKey, isDesktop }) {
  const [basePlantInfo, setBasePlantInfo] = useState([]);

  const backendRequest = useBackendV2();

  const fetchBasePlantInfoData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.baseInfo}/${plantkey}`
      );
      setBasePlantInfo(data);
    } catch (error) {
      setBasePlantInfo([]);
    }
  };

  useEffect(() => {
    if (plantkey !== null && plantkey !== undefined && plantkey !== '') {
      fetchBasePlantInfoData();
    }
  }, [plantkey]);

  return (
    <>
      {basePlantInfo.length > 0 && basePlantInfo[0].logoimgkey ? (
        <img
          src={`https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=${process.env.REACT_APP_DOMAIN}&id=${basePlantInfo[0].logoimgkey}`}
          alt="Plant Logo"
          style={{ width: isDesktop ? 200 : 180, backgroundColor: '#F8F9FE' }}
        />
      ) : (
        <>
          <img src={qubelogo} alt="SOLARCUBE" style={{ width: isDesktop ? 42.5 : 35 }} />
          <Typography
            color="inherit"
            sx={{
              ml: 0.6,
              display: 'flex',
              fontFamily: '"Segoe UI Symbol"',
              fontWeight: 'bold',
              color: '#fff',
              fontSize: isDesktop ? '1.45rem' : '1.15rem',
              cursor: isDesktop ? 'pointer' : 'cursor',
            }}
          >
            {process.env.REACT_APP_DOMAIN === 'SC' ? 'SOLAR CUBE' : 'SOLAR CUBE II'}
          </Typography>
        </>
      )}
    </>
  );
}
