import {
  Autocomplete,
  Box,
  Button,
  Card,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import useResponsive from '../../hooks/useResponsive';
import { useContext, useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import AuthContext from '../../store/auth-context';
import config from '../../config';
import { convertFormatStringToYMD } from '../../utils/dateUtil';
import useInterval from '../../hooks/use-interval';
import SendIcon from '@mui/icons-material/Send';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import PlantSettingModal from '../Setting/PlantSetting/PlantSettingModal';
import { useCookies } from 'react-cookie';
import RegularPaymentCheckModal from '../Layout/RegularPaymentCheckModal';
import SubscriptionGuideModal from './SubscriptionGuideModal';
import EachPlantMobileView from './EachPlantMobileView';
import EachPlantWebView from './EachPlantWebView';
import EachPlantMobileView_DNE from './EachPlantMobileView_DNE';
import HelpSignalStatusModal from './HelpSignalStatusModal';
import usePageLogRecord from '../../hooks/usePageLogRecord';
import UseCustomSort from '../../hooks/useCustomSort';
import VideocamIcon from '@mui/icons-material/Videocam';

// 개별 대시보드 구성 컴포넌트
export default function EachPlant({
  setPage,
  page,
  setPlantControll,
  plantControll,
  plant,
  isMobileViewDNE,
}) {
  const location = useLocation();
  const authCtx = useContext(AuthContext);
  const isDesktop = useResponsive('up', 'lg');

  const { isAndroid, isiOS } = authCtx;

  const [cookies, setCookie, removeCookie] = useCookies(['subscriptionExpiration']);

  const [nowTime, setNowTime] = useState(Date.now());

  const pollingInterval = 1000; // unit: milliseconds
  useInterval(() => {
    setNowTime(Date.now());
  }, pollingInterval);

  const envDataState = {
    envGroup: 0,
    envRange: 0,
    dateTime: convertFormatStringToYMD(nowTime),
  };
  const dailyDevelopmentState = {
    yieldGroupType: 0,
    yieldtype: 0,
    dateTime: convertFormatStringToYMD(nowTime),
  };

  const [selectPlant, setSelectPlant] = useState([]);
  const [todayEnvData, setTodayEnvData] = useState([]);
  const [dailyDevelopment, setDailyDevelopment] = useState([]);
  const [basePlantInfo, setBasePlantInfo] = useState([]);
  const [invDailyDevelopment, setInvDailyDevelopment] = useState([]);
  const [acbVcbDailyDevelopment, setAcbVcbDailyDevelopment] = useState([]);
  const [todaySmp, setTodaySmp] = useState([]);
  const [todayRec, setTodayRec] = useState([]);

  const [openPlantsetting, setOpenPlantSetting] = useState(false);
  const [showPaymantGuideModal, setShowPaymantGuideModal] = useState(false);
  const [checkToShowPaymentGuideModal, setCheckToShowPaymentGuideModal] = useState(false);
  const [openSubscriptionGuideModal, setOpenSubscriptionGuideModal] = useState(false);
  const [helpSignalStatusModal, setHelpSignalStatusModal] = useState(false);
  const [haveCctv, setHaveCctv] = useState(false);
  const [showCCTVIcon, setShowCCTVIcon] = useState(false);

  const [signalClickedCard, setSignalClickedCard] = useState(null);
  const [subscribeday, setSubscribeday] = useState(null);
  const [graceperiodDay, setGraceperiodDay] = useState(null);
  const [checkSubscriptionDay, setCheckSubscriptionDay] = useState();

  const backendRequest = useBackendV2();

  const fetchUserPlants = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.userPlantsV2}/${authCtx.currentUser.id}`
      );
      setSelectPlant(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchTodayEnvData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.envs.basic}/${page.plantkey}?${new URLSearchParams(envDataState)}`
      );

      setTodayEnvData(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchDailyDevelopmentData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.statistics.plantYield}/${page.plantkey}?${new URLSearchParams(
          dailyDevelopmentState
        )}`
      );

      const newData = data.map((item) => ({
        ...item,
        radiation: item.radiation === -999 ? null : item.radiation,
      }));

      const missingData = [];
      for (let i = 5; i <= 20; i++) {
        const existingDataIndex = newData.findIndex((item) => item.year_month_day_hour === i);
        if (existingDataIndex === -1) {
          missingData.push({
            groupname: newData[0].groupname,
            fdate: newData[0].fdate,
            year_month_day_hour: i,
            yield: null,
            totalyield: null,
            fac: null,
            generateHour: null,
            iac: null,
            idc: null,
            pac: null,
            pdc: null,
            radiation: null,
            tco2: null,
            uac: null,
            udc: null,
          });
        }
      }

      const mergedData = [...newData, ...missingData];

      mergedData.sort((a, b) => a.year_month_day_hour - b.year_month_day_hour);

      setDailyDevelopment(mergedData);
    } catch (error) {
      setDailyDevelopment([]);
      // console.error(error);
      // alert('오류가 발생하였습니다.');
    }
  };

  const fetchDailyInvDevelopmentData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.inverters.extReal}/${page.plantkey}`
      );

      const sortedData = UseCustomSort(data, 'invertername');

      setInvDailyDevelopment(sortedData);
    } catch (error) {
      setInvDailyDevelopment([]);
    }
  };

  const fetchBasePlantInfoData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.baseInfo}/${page.plantkey}`
      );
      setBasePlantInfo(data);
    } catch (error) {
      setBasePlantInfo([]);
    }
  };

  const fetchAcbVcbDailyDevelopmentData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.acbvcbs.extReal}/${page.plantkey}`
      );
      setAcbVcbDailyDevelopment(data);
    } catch (error) {
      setAcbVcbDailyDevelopment([]);
    }
  };

  const fetchTodaySmpRecData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(`${config.backend.endPoint.smprecs.last}`);

      const { recs, smps } = data;
      setTodayRec(recs);
      setTodaySmp(smps);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchSubscriptionPeriod = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.subscribe}/${page.plantkey}`
      );

      const filteredData = data.filter((item) => item.subscribeday > -90);

      if (filteredData.length > 0) {
        const maxSubdcribedayItem = filteredData.reduce((maxItem, currentItem) => {
          return currentItem.subscribeday > maxItem.subscribeday ? currentItem : maxItem;
        });

        setSubscribeday(maxSubdcribedayItem.subscribeday);
        setGraceperiodDay(filteredData[0]?.graceperiod);
      } else {
        setSubscribeday(null);
        setGraceperiodDay(null);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  // 결제 안내를 위한 fetch
  const fetchPlants = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.allInfo}/${authCtx.currentUser.id}`
      );

      const hasSubscriptionOver90 = data.some((item) => item.subscribeday > -90);

      if (hasSubscriptionOver90) {
        const hasSubscriptionDay = Math.max(...data.map((item) => item.subscribeday));

        setCheckSubscriptionDay(hasSubscriptionDay);
        setShowPaymantGuideModal(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchHaveCctv = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.cctvs.haveCCTV}/${page.plantkey}`
      );

      if (data === 0) {
        setHaveCctv(false);
      } else {
        setHaveCctv(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const moveEntirePlantHandler = () => {
    setPage((prevPage) => ({
      ...prevPage,
      pagetype: 'entire',
      plantkey: '',
      plantname: '',
      plantNodata: '',
      plantInvNodata: '',
      subscribeday: '',
    }));
    localStorage.removeItem('selectPlantkey');
    localStorage.setItem('menuSubValue', 0);
  };

  const moveCctvHandler = () => {
    const { isAndroid, isiOS } = authCtx;

    const androidFunc = () => window.QWebApp.viewCCTV(page.plantkey);
    const iosFunc = () => window.webkit.messageHandlers.viewCCTV.postMessage(page.plantkey);

    if (isAndroid) {
      androidFunc();
    } else if (isiOS) {
      iosFunc();
    }
  };

  // 결제 안내 모달 닫기
  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }

    const expirationDate = new Date();

    // 쿠키 유효기간 설정
    if (checkToShowPaymentGuideModal && checkSubscriptionDay > -90 && checkSubscriptionDay < -7) {
      expirationDate.setDate(expirationDate.getDate() + 7);

      setCookie('subscriptionExpiration', expirationDate.toISOString(), {
        path: '/',
        expires: expirationDate,
      });
    } else if (checkToShowPaymentGuideModal && checkSubscriptionDay >= -7) {
      expirationDate.setDate(expirationDate.getDate() + 1);

      setCookie('subscriptionExpiration', expirationDate.toISOString(), {
        path: '/',
        expires: expirationDate,
      });
    }

    setShowPaymantGuideModal(false);
  };

  const selectPlantChangeHandler = (plant) => {
    setPage((prev) => {
      return { ...prev, plantkey: plant.plantkey, plantname: plant.plantname, plantAddress: plant.street };
    });
    localStorage.setItem('selectPlantkey', plant.plantkey);
  };

  // 전체 발전소
  const numPlants = Array.isArray(selectPlant) ? selectPlant.length : 0;

  // 총발전량 계산
  const sumTotalYield = invDailyDevelopment.reduce((total, inverter) => {
    return total + inverter.totalyield;
  }, 0);

  // 온실가스 감축량 총합
  const totalGhgReduction = invDailyDevelopment.reduce((total, inverter) => {
    return total + (inverter.ghgReduction || 0);
  }, 0);

  const roundedTotalGhgReduction = parseFloat(totalGhgReduction.toFixed(1));

  // 나무 계산 총합
  const totalPineTree = invDailyDevelopment.reduce((total, inverter) => {
    return total + (inverter.pineTree || 0);
  }, 0);

  const roundedTotalPineTree = parseFloat(totalPineTree.toFixed(1));

  // 일일발전량 계산
  const totalDailyYield = invDailyDevelopment.reduce((total, inverter) => {
    return total + (inverter.dailyyield || 0);
  }, 0);

  const roundedTotalDailyYield = parseFloat(totalDailyYield.toFixed(1));

  // 월발전량 계산
  const totalMonthyYield = invDailyDevelopment.reduce((total, inverter) => {
    return total + (inverter.monthyield || 0);
  }, 0);

  const roundedTotalMonthyYield = parseFloat(totalMonthyYield.toFixed(1));

  // 현재출력 계산
  const totalPac = invDailyDevelopment.reduce((total, inverter) => {
    return total + (inverter.pac || 0);
  }, 0);

  const roundedTotalPac = parseFloat(totalPac.toFixed(1));

  // 발전시간 계산
  const totalDailyDevelopmentTime =
    basePlantInfo.length !== 0 ? roundedTotalDailyYield / basePlantInfo[0].capacity : 0;

  const roundedTotalDailyDevelopmentTime = parseFloat(totalDailyDevelopmentTime.toFixed(1));

  // 만료 발전소 상품 및 구독 라이선스 입력 페이지 안내 모달
  const openSubscriptionGuideModalHandler = () => {
    setOpenSubscriptionGuideModal(true);
  };
  const closeSubscriptionGuideModalHandler = () => {
    setOpenSubscriptionGuideModal(false);
  };

  const showPlantSettingHandler = () => {
    setOpenPlantSetting(true);
  };
  const closePlantSettingHandler = () => {
    setOpenPlantSetting(false);
  };

  const closeHelpStatusGuideModalHandler = () => {
    setHelpSignalStatusModal(false);
    setSignalClickedCard(null);
  };

  const fetchPollingInterval = 60000;

  useInterval(() => {
    fetchTodayEnvData();
    fetchDailyDevelopmentData();
    fetchDailyInvDevelopmentData();
    fetchBasePlantInfoData();
    fetchAcbVcbDailyDevelopmentData();
    fetchTodaySmpRecData();
  }, fetchPollingInterval);

  useEffect(() => {
    fetchUserPlants();
    fetchHaveCctv();
  }, []);

  useEffect(() => {
    fetchTodayEnvData();
    fetchDailyDevelopmentData();
    fetchDailyInvDevelopmentData();
    fetchBasePlantInfoData();
    fetchAcbVcbDailyDevelopmentData();
    fetchTodaySmpRecData();
    fetchSubscriptionPeriod();
    fetchHaveCctv();
  }, [page]);

  useEffect(() => {
    if (subscribeday !== null && subscribeday >= -90) {
      const subscriptionExpiration = cookies.subscriptionExpiration;

      // subscriptionExpiration 쿠키가 없거나 현재 날짜가 subscriptionExpiration 이후인 경우에만 fetchPlants 실행
      if (!subscriptionExpiration || new Date() > new Date(subscriptionExpiration)) {
        fetchPlants();
      }
    }
  }, [subscribeday]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    if (haveCctv) {
      if (isAndroid) {
        const match = navigator.userAgent.match(/AndroidV(\d+,\d+|\d+)/);
        if (match) {
          const android = match[1];
          const appVer = android.includes(',') ? android.split(',')[1] : null;
          if (appVer && parseInt(appVer) >= 9) {
            setShowCCTVIcon(true);
          }
        }
      } else if (isiOS) {
        const match = navigator.userAgent.match(/IOSV(\d+,\d+|\d+)/);
        if (match) {
          const ios = match[1];
          const appVer = ios.includes(',') ? ios.split(',')[1] : null;
          if (appVer && parseInt(appVer) >= 16) {
            setShowCCTVIcon(true);
          }
        }
      }
    }
  }, [haveCctv]);

  usePageLogRecord('eachMonitorings', page.plantkey);

  return (
    <>
      {plantControll ? (
        <>
          <Box
            sx={{
              display: {
                xs: 'flex',
              },
              alignItems: 'center',
              mt: 2.5,
            }}
          >
            {numPlants > 11 ? (
              <Autocomplete
                disableClearable
                id="combo-box-demo"
                options={selectPlant}
                getOptionLabel={(option) => option.plantname || ''}
                value={selectPlant.find((option) => option.plantkey === page.plantkey) || ''}
                onChange={(e, newValue) => {
                  if (newValue) {
                    const selectedPlant = selectPlant.find((option) => option.plantkey === newValue.plantkey);
                    selectPlantChangeHandler(selectedPlant);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="발전소" variant="outlined" sx={{ bgcolor: '#fff' }} />
                )}
                sx={{ width: isDesktop ? '45%' : '100%' }}
              />
            ) : (
              <FormControl sx={{ width: isDesktop ? '45%' : '100%', bgcolor: '#fff' }}>
                <InputLabel>발전소</InputLabel>
                <Select
                  label="발전소"
                  value={page.plantkey || ''}
                  onChange={(e) => {
                    const selectedPlant = selectPlant.find((option) => option.plantkey === e.target.value);
                    selectPlantChangeHandler(selectedPlant);
                  }}
                >
                  {selectPlant.map((option) => (
                    <MenuItem key={option.plantkey} value={option.plantkey}>
                      {option.plantname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {isDesktop ? (
              <Button
                type="button"
                variant="text"
                startIcon={<SettingsOutlinedIcon color="primary" />}
                onClick={showPlantSettingHandler}
                size="large"
                sx={{ color: 'black' }}
              >
                발전소 설정
              </Button>
            ) : null}
            {subscribeday !== null && subscribeday > -90 ? (
              subscribeday < 0 ? (
                <Chip
                  label={`서비스 만료 D${subscribeday}`}
                  color="error"
                  variant="outlined"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1, bgcolor: '#fff' }}
                />
              ) : (
                <Chip
                  label="서비스 만료"
                  color="error"
                  variant="contained"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1 }}
                />
              )
            ) : null}
            <Box sx={{ flexGrow: 1 }} />
            {isDesktop ? (
              <Button
                type="button"
                variant="text"
                size="large"
                onClick={moveEntirePlantHandler}
                endIcon={<SendIcon color="primary" />}
                sx={{ fontSize: '1.0rem', color: 'black' }}
              >
                전체 현황
              </Button>
            ) : null}
          </Box>
          {isDesktop ? null : (
            <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
              <Button
                type="button"
                variant="text"
                startIcon={<SettingsOutlinedIcon color="primary" />}
                onClick={showPlantSettingHandler}
                sx={{ color: 'black' }}
              >
                발전소 설정
              </Button>
              <Box>
                {showCCTVIcon ? (
                  <Button
                    type="button"
                    variant="text"
                    onClick={moveCctvHandler}
                    endIcon={<VideocamIcon color="primary" />}
                    sx={{ color: 'black' }}
                  >
                    CCTV
                  </Button>
                ) : null}
                <Button
                  type="button"
                  variant="text"
                  onClick={moveEntirePlantHandler}
                  endIcon={<SendIcon color="primary" />}
                  sx={{ color: 'black' }}
                >
                  전체현황
                </Button>
              </Box>
            </Box>
          )}
        </>
      ) : (
        <>
          <Box
            sx={{
              display: {
                xs: 'flex',
              },
              alignItems: 'center',
            }}
          >
            <Card sx={{ border: '0.15px solid #D8D8D8', p: 1.5, mt: 1.5, width: isDesktop ? '45%' : '100%' }}>
              <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                {page.plantname}
              </Typography>
            </Card>
            {isDesktop ? (
              <Tooltip title="발전소 설정">
                <IconButton aria-label="delete" size="large" onClick={showPlantSettingHandler}>
                  <SettingsOutlinedIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            ) : null}
            {subscribeday !== null && subscribeday > -90 ? (
              subscribeday < 0 ? (
                <Chip
                  label={`서비스 만료 D${subscribeday}`}
                  color="error"
                  variant="outlined"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1, bgcolor: '#fff' }}
                />
              ) : (
                <Chip
                  label="서비스 만료"
                  color="error"
                  variant="contained"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1 }}
                />
              )
            ) : null}
            <Box sx={{ flexGrow: 1 }} />
          </Box>
          {isDesktop ? null : (
            <Box display="flex" justifyContent="space-between" sx={{ mt: 1 }}>
              <Button
                type="button"
                variant="text"
                startIcon={<SettingsOutlinedIcon color="primary" />}
                onClick={showPlantSettingHandler}
                sx={{ color: 'black' }}
              >
                발전소 설정
              </Button>
              <Box>
                {showCCTVIcon ? (
                  <Button
                    type="button"
                    variant="text"
                    onClick={moveCctvHandler}
                    endIcon={<VideocamIcon color="primary" />}
                    sx={{ color: 'black' }}
                  >
                    CCTV
                  </Button>
                ) : null}
              </Box>
            </Box>
          )}
        </>
      )}
      {isDesktop ? (
        <EachPlantWebView
          subscribeday={subscribeday}
          graceperiodDay={graceperiodDay}
          page={page}
          nowTime={nowTime}
          todayEnvData={todayEnvData}
          basePlantInfo={basePlantInfo}
          invDailyDevelopment={invDailyDevelopment}
          roundedTotalGhgReduction={roundedTotalGhgReduction}
          roundedTotalPineTree={roundedTotalPineTree}
          roundedTotalDailyYield={roundedTotalDailyYield}
          sumTotalYield={sumTotalYield}
          roundedTotalDailyDevelopmentTime={roundedTotalDailyDevelopmentTime}
          roundedTotalMonthyYield={roundedTotalMonthyYield}
          acbVcbDailyDevelopment={acbVcbDailyDevelopment}
          dailyDevelopment={dailyDevelopment}
          todayRec={todayRec}
          todaySmp={todaySmp}
          setHelpSignalStatusModal={setHelpSignalStatusModal}
          setSignalClickedCard={setSignalClickedCard}
        />
      ) : (
        <>
          {/* 기존 EachPlantMobileView 대신 isMobileViewDNE에 따라 조건부 렌더링 */}
          {isMobileViewDNE ? (
            <EachPlantMobileView_DNE
              subscribeday={subscribeday}
              graceperiodDay={graceperiodDay}
              page={page}
              nowTime={nowTime}
              todayEnvData={todayEnvData}
              basePlantInfo={basePlantInfo}
              invDailyDevelopment={invDailyDevelopment}
              roundedTotalGhgReduction={roundedTotalGhgReduction}
              roundedTotalPineTree={roundedTotalPineTree}
              roundedTotalDailyYield={roundedTotalDailyYield}
              sumTotalYield={sumTotalYield}
              roundedTotalDailyDevelopmentTime={roundedTotalDailyDevelopmentTime}
              roundedTotalMonthyYield={roundedTotalMonthyYield}
              acbVcbDailyDevelopment={acbVcbDailyDevelopment}
              dailyDevelopment={dailyDevelopment}
              todayRec={todayRec}
              todaySmp={todaySmp}
              isDesktop={isDesktop}
            />
          ) : (
            <EachPlantMobileView
              subscribeday={subscribeday}
              graceperiodDay={graceperiodDay}
              page={page}
              nowTime={nowTime}
              todayEnvData={todayEnvData}
              basePlantInfo={basePlantInfo}
              invDailyDevelopment={invDailyDevelopment}
              roundedTotalGhgReduction={roundedTotalGhgReduction}
              roundedTotalPineTree={roundedTotalPineTree}
              roundedTotalDailyYield={roundedTotalDailyYield}
              sumTotalYield={sumTotalYield}
              roundedTotalDailyDevelopmentTime={roundedTotalDailyDevelopmentTime}
              roundedTotalMonthyYield={roundedTotalMonthyYield}
              acbVcbDailyDevelopment={acbVcbDailyDevelopment}
              dailyDevelopment={dailyDevelopment}
              todayRec={todayRec}
              todaySmp={todaySmp}
              isDesktop={isDesktop}
            />
          )}
        </>
      )}
      {openPlantsetting && (
        <PlantSettingModal
          plantkey={page.plantkey}
          plantName={page.plantname}
          open={openPlantsetting}
          onClose={closePlantSettingHandler}
        />
      )}
      {openSubscriptionGuideModal && (
        <SubscriptionGuideModal
          plantkey={page.plantkey}
          plantName={page.plantname}
          open={openSubscriptionGuideModal}
          onClose={closeSubscriptionGuideModalHandler}
        />
      )}
      {showPaymantGuideModal && (
        <RegularPaymentCheckModal
          open={showPaymantGuideModal}
          onClose={closeModalHandler}
          checkSubscriptionDay={checkSubscriptionDay}
          subscribeday={subscribeday}
          checkToShowPaymentGuideModal={checkToShowPaymentGuideModal}
          setCheckToShowPaymentGuideModal={setCheckToShowPaymentGuideModal}
        />
      )}
      {helpSignalStatusModal && (
        <HelpSignalStatusModal
          open={helpSignalStatusModal}
          onClose={closeHelpStatusGuideModalHandler}
          signalClickedCard={signalClickedCard}
          setSignalClickedCard={setSignalClickedCard}
        />
      )}
    </>
  );
}
