import { useEffect, useState } from 'react';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import { convertFormatStringToYMD } from '../../../utils/dateUtil';
import EnvStatHours from './EnvStatHours';
import EnvStatMinutes from './EnvStatMinutes';

// 통계 - 환경정보통계 컴포넌트
export default function EnvStat({
  page,
  subValue,
  envTabValue,
  dateValue,
  searchState,
  setSearchState,
  chartView,
}) {
  const [envStatDevelopment, setEnvStatDevelopment] = useState([]);
  const [envsetUse, setEnvsetUse] = useState(false);
  const [envFirstChar, setEnvFirstChar] = useState(false);
  const [envSecondChar, setEnvSecondChar] = useState(false);

  const backendRequest = useBackendV2();

  const fetchEncStatDevelopmentData = async () => {
    try {
      const queryStringArr = [
        `envGroup=${envTabValue}`,
        `envRange=0`,
        `dateTime=${convertFormatStringToYMD(dateValue)}`,
      ];

      const queryString = queryStringArr.join('&');

      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.envs.basic}/${page.plantkey}?${queryString}`
      );
      setEnvStatDevelopment(data.map((item, index) => ({ ...item, id: index })));
      setSearchState(false);
    } catch (error) {
      setEnvStatDevelopment([]);
      setSearchState(false);
    }
  };

  const fetchPlantInfo = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.baseInfo}/${page.plantkey}`
      );

      const envset = data[0]?.envset;

      if (envset) {
        const firstChar = envset.charAt(0);
        const secondChar = envset.charAt(1);

        if (firstChar === '1' || secondChar === '1') {
          setEnvsetUse(true);

          if (firstChar === '1') {
            setEnvFirstChar(true);
          }
          if (secondChar === '1') {
            setEnvSecondChar(true);
          }
        } else {
          setEnvsetUse(false);
        }
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    if (searchState && page.plantkey !== '') {
      fetchEncStatDevelopmentData();
      fetchPlantInfo();
    }
  }, [searchState, page]);

  let componentToShow;

  switch (envTabValue) {
    case '1':
      componentToShow = (
        <EnvStatHours
          data={envStatDevelopment}
          envFirstChar={envFirstChar}
          envSecondChar={envSecondChar}
          envsetUse={envsetUse}
          chartView={chartView}
        />
      );
      break;
    case '2':
      componentToShow = (
        <EnvStatMinutes
          data={envStatDevelopment}
          envFirstChar={envFirstChar}
          envSecondChar={envSecondChar}
          envsetUse={envsetUse}
          chartView={chartView}
        />
      );
      break;
    default:
      componentToShow = null;
      break;
  }

  return <>{componentToShow}</>;
}
