import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SpeedIcon from '@mui/icons-material/Speed';
import BusinessIcon from '@mui/icons-material/Business';
import FormatAlignJustifyIcon from '@mui/icons-material/FormatAlignJustify';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import { useNavigate } from 'react-router-dom';

export default function MonitoringMenu({
  value,
  setValue,
  setSubValue,
  monitoringClickHandler,
  monitoringSelect,
  webNavOpen,
  onClose,
}) {
  const navigate = useNavigate();

  const entireClickHandler = () => {
    setValue(0);
    setSubValue(0);
    navigate('/', { state: { subValue: 0 } });
    onClose();
  };
  const eachClickHandler = () => {
    setValue(0);
    setSubValue(1);
    navigate('/', { state: { subValue: 1 } });
    onClose();
  };

  return (
    <List component="nav" sx={{ backgroundColor: value === 0 ? '#D2E9FF' : null, borderRadius: 1 }}>
      <ListItemButton onClick={monitoringClickHandler}>
        <ListItemIcon>
          <ConnectedTvIcon sx={{ color: value === 0 ? '#3A3DFF' : null }} />
        </ListItemIcon>
        {webNavOpen ? (
          <Typography sx={{ color: value === 0 ? '#3A3DFF' : null, fontWeight: value === 0 ? 'bold' : null }}>
            모니터링
          </Typography>
        ) : null}
        {/* {monitoringSelect ? <ExpandLess /> : <ExpandMore />} */}
      </ListItemButton>
      {/* <Collapse in={monitoringSelect} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton onClick={entireClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <FormatAlignJustifyIcon />
            </ListItemIcon>
            <ListItemText primary="전체현황" />
          </ListItemButton>
        </List>
        <List component="div" disablePadding>
          <ListItemButton onClick={eachClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <BusinessIcon />
            </ListItemIcon>
            <ListItemText primary="발전소" />
          </ListItemButton>
        </List>
      </Collapse> */}
    </List>
  );
}
