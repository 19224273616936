import { Box, Button, TextField, Typography } from '@mui/material';
import CustomModal from '../../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import EditAttributesOutlinedIcon from '@mui/icons-material/EditAttributesOutlined';
import { useEffect, useState } from 'react';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import useInput from '../../../hooks/use-input';
import { validatePassword } from '../../../utils/validationUtil';

export default function InverterDeviceControlModal({
  open,
  onClose,
  plantkey,
  selectInvSerialNum,
  selectInvName,
  selectType,
}) {
  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  const passwordInput = useInput((value) => validatePassword(value));

  const backendRequest = useBackendV2();

  const [ivtDvcInfo, setIvtDvcInfo] = useState([]);

  const fetchIvtControlInfo = async () => {
    if (plantkey !== '') {
      try {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.inverters.ivtControlInfo}/${plantkey}/${selectInvSerialNum}/${selectType}`
        );

        setIvtDvcInfo(data);
      } catch (error) {
        setIvtDvcInfo([]);
      }
    }
  };

  useEffect(() => {
    fetchIvtControlInfo();
  }, []);

  const controlClickHandler = async () => {
    const userConfirmed = window.confirm('정말로 장비 제어를 실행하시겠습니까?');

    if (!userConfirmed) {
      return;
    }

    try {
      const contorlRequestConfig = {
        method: 'PUT',
        body: {
          Plantkey: plantkey,
          Password: passwordInput.value.trim(),
          Rtun: parseInt(ivtDvcInfo.rtun),
          DvcType: ivtDvcInfo.dvcType,
          IndexNum: parseInt(ivtDvcInfo.indexNum),
          Ivtname: ivtDvcInfo.ivtname,
          ContAdd: parseInt(ivtDvcInfo.contAdd),
          ContName: ivtDvcInfo.contName,
          UiType: parseInt(ivtDvcInfo.uiType),
        },
      };

      const result = await backendRequest.sendRequest(
        config.backend.endPoint.acbvcbs.acbVcbIvtControl,
        contorlRequestConfig
      );

      if (result.data.success === true || result.data.success === 'true') {
        alert('제어를 완료했습니다.');
        onClose();
      } else {
        alert('제어에 실패했습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발행하였습니다.');
    }
  };

  return (
    <CustomModal
      open={open}
      onClose={closeModalHandler}
      sx={{ width: { xs: '95%', md: 600 }, maxHeight: '95%', p: 3.5 }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
        <EditAttributesOutlinedIcon fontSize="large" />
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>명령 실행</Typography>
      </Box>
      <hr />
      <Box sx={{ p: 2 }}>
        <Typography variant="subtitle1">장비 타입 : 인버터</Typography>
        <Typography variant="subtitle1" sx={{ mt: 1 }}>
          장비 이름 : {selectInvName}
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 1 }}>
          장비 정보 : {selectInvSerialNum}
        </Typography>
        <Typography variant="subtitle1" sx={{ mt: 1 }}>
          제어항목 :{' '}
          {ivtDvcInfo.length === 0 ? (
            <span style={{ color: 'red', fontSize: '1.1rem' }}>내용이 존재하지 않습니다.</span>
          ) : (
            ivtDvcInfo.contName
          )}
        </Typography>
        <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
          <Typography variant="subtitle1">비밀번호 : </Typography>
          <TextField
            type="password"
            variant="outlined"
            size="small"
            disabled={ivtDvcInfo.length === 0 ? true : false}
            onChange={passwordInput.valueChangeHandler}
            onBlur={passwordInput.inputBlurHandler}
            value={passwordInput.value.replace(/\s/g, '').trim()}
            error={passwordInput.hasError}
            sx={{ ml: 0.8 }}
          />
          <Button
            variant="contained"
            onClick={controlClickHandler}
            disabled={ivtDvcInfo.length === 0 ? true : false}
            sx={{ ml: 1.5 }}
          >
            제어 실행
          </Button>
        </Box>
      </Box>
    </CustomModal>
  );
}
