// 다양한 유효성 검사(Validation) 함수를 제공

// 3자리 마다 콤마를 찍는다.
export function toNumberWithComma(value) {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

// 콤마를 삭제하고 문자 형태로 반환한다.
export function toRemoveComma(value) {
  return value.replace(/,/g, '');
}

const ID_MIN_LENGTH = 3;
const ID_MAX_LENGTH = 20;
const ID_REGEX = `^[a-zA-Z\\d]{${ID_MIN_LENGTH},${ID_MAX_LENGTH}}$`;

export const validateId = (value) => new RegExp(ID_REGEX, 'g').test(value);

const NAME_MIN_LENGTH = 2;
const NAME_MAX_LENGTH = 50;
const NAME_REGEX = `^[a-zA-Zㄱ-ㅎ가-힣0-9]{${NAME_MIN_LENGTH},${NAME_MAX_LENGTH}}$`;

export const validateName = (value) => new RegExp(NAME_REGEX, 'g').test(value);

const PASSWORD_MIN_LENGTH = 8;
const PASSWORD_MAX_LENGTH = 25;
const PASSWORD_REGEX = `^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{${PASSWORD_MIN_LENGTH},${PASSWORD_MAX_LENGTH}}$`;

export const validatePassword = (value) => new RegExp(PASSWORD_REGEX, 'g').test(value);

const PHONE_REGEX = /^(01[0-9]{1,3}[-]?[0-9]{4}[-]?[0-9]{4}|01[0-9]{1,3}[0-9]{4}[0-9]{4})$/;

export const validatePhone = (value) => new RegExp(PHONE_REGEX, 'g').test(value);

const EMAIL_REGEX =
  '(?:[a-z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-z0-9!#$%&\'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\\])';

export const validateEmail = (value) => value.length === 0 || new RegExp(EMAIL_REGEX, 'i').test(value);

const SIX_DIGIT_REGEX = /^\d{6}$/;

export const validateSixDigit = (value) => SIX_DIGIT_REGEX.test(value);

const OFFICEPHONE_REGEX = '^\\d{2,4}-?\\d{4}-?\\d{4}$';

export const validateOfficePhone = (value) => new RegExp(OFFICEPHONE_REGEX, 'g').test(value);

const BUSINESS_NUMBER_REGEX = '^\\d{3}-?\\d{2}-?\\d{5}$';

export const validateBusinessNo = (value) => new RegExp(BUSINESS_NUMBER_REGEX, 'g').test(value);

const FAX_REGEX = '^\\d{2,4}-?\\d{3,4}-?\\d{4}$';

export const validateFax = (value) => new RegExp(FAX_REGEX, 'g').test(value);

const NUMBER_ONLY_REGEX = /^\d+$/;

export const validateNumberOnly = (value) => NUMBER_ONLY_REGEX.test(value);

const SUBPLANTNAME_MIN_LENGTH = 2;
const SUBPLANTNAME_MAX_LENGTH = 25;
const SUBPLANTNAME_REGEX = `^[a-zA-Zㄱ-ㅎ가-힣\\d\\s~!@#\\$%\\^&\\*\\(\\)_\\+\\-=\\[\\]\\{\\};:'"\\\\|<>\\./\\?]{${SUBPLANTNAME_MIN_LENGTH},${SUBPLANTNAME_MAX_LENGTH}}$`;

export const validateSubPlantName = (value) => new RegExp(SUBPLANTNAME_REGEX, 'g').test(value);
