import useResponsive from '../../../hooks/useResponsive';
import YInverterChart from '../../Charts/Statistics/Year/YInverterChart';
import YearInverterStatisticsTable from './YearInverterStatisticsTable';

export default function YearInverterStatistics({ data, chartView }) {
  const isDesktop = useResponsive('up', 'lg');

  const groupedData = data.reduce((acc, data) => {
    const groupName = data.groupname;

    if (!acc[groupName]) {
      acc[groupName] = [];
    }

    acc[groupName].push(data);
    return acc;
  }, {});

  if (Object.keys(groupedData).length === 0) {
    return null;
  }

  const maxLength = Math.max(...Object.values(groupedData).map((group) => group.length));
  Object.keys(groupedData).forEach((groupName) => {
    const currentLength = groupedData[groupName].length;
    if (currentLength < maxLength) {
      const additionalItems = Array(maxLength - currentLength).fill(0);
      groupedData[groupName] = additionalItems.concat(groupedData[groupName]);
    }
  });

  Object.keys(groupedData).forEach((groupName) => {
    groupedData[groupName].sort((a, b) => a.flocaldate - b.flocaldate);
  });

  const chartData = Array.from({ length: maxLength }).map((_, index) => {
    const entry = {
      name: groupedData[Object.keys(groupedData)[0]][index].flocaldate,
    };
    Object.keys(groupedData).forEach((groupName) => {
      entry[groupName] = groupedData[groupName][index].totalyield;
    });

    return entry;
  });

  return (
    <>
      {isDesktop ? (
        <>
          <YInverterChart data={data} groupedData={groupedData} chartData={chartData} isDesktop={isDesktop} />
          {data.length === 0 ? null : (
            <YearInverterStatisticsTable
              data={data}
              groupedData={groupedData}
              chartData={chartData}
              isDesktop={isDesktop}
            />
          )}
        </>
      ) : chartView ? (
        <YInverterChart data={data} groupedData={groupedData} chartData={chartData} isDesktop={isDesktop} />
      ) : (
        <YearInverterStatisticsTable
          data={data}
          groupedData={groupedData}
          chartData={chartData}
          isDesktop={isDesktop}
        />
      )}
    </>
  );
}
