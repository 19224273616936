import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import { Link } from 'react-router-dom';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: '13px',
    color: '#000000',
    borderBottom: '1px solid #e0e0e0',
  },
  // 테이블 내용 줄바꿈 방지
  '&:nth-of-type(4)': {
    width: '10%',
    minWidth: '80px',
    whiteSpace: 'nowrap',
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f0f0f0',
  },
  '&:last-child td, &:last-child th': {
    borderBottom: '1px solid #e0e0e0',
  },
}));

/*
자료실 리스트 컴포넌트(웹뷰)
ArchiveSearchForm > ArchiveList(웹뷰 리스트 페이지),ArchiveListMobile(모바일뷰 리스트 페이지)
*/
export default function ArchiveList({ rows, onBtnMore }) {
  const columns = [
    { field: 'title', headerName: '제목', width: '30%' },
    { field: 'content', headerName: '설명', width: '40%' },
    { field: 'transdate', headerName: '작성일', width: '15%' },
    { field: 'download', headerName: '다운로드', width: '15%' },
  ];

  return (
    <>
      <Card
        sx={{
          mt: 2,
          borderRadius: 0,
          boxShadow: '0px 3px 12px #00000014',
        }}
      >
        <StyledTableContainer>
          <Table size="small" sx={{ minWidth: 1500 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.field}
                    align="center"
                    style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                  >
                    {column.headerName}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, index) => (
                  <StyledTableRow
                    key={row.seq}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: '#cfd8dc !important' },
                    }}
                  >
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.field}
                        align={column.field === 'title' || column.field === 'content' ? 'left' : 'center'}
                        style={{
                          minWidth: column.width,
                          backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                          borderRight: '1px solid #d0d0d0',
                        }}
                      >
                        {column.field === 'transdate' ? (
                          row[column.field] === null ? (
                            ''
                          ) : (
                            row[column.field].substring(0, 10)
                          )
                        ) : column.field === 'download' ? (
                          <Link to={`https://filedeliver.sensorcube.co.kr/S${row.fileServerSuffix}`}>
                            <FileDownloadOutlinedIcon fontSize="small" sx={{ color: 'black' }} />
                          </Link>
                        ) : (
                          row[column.field]
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={4} sx={{ textAlign: 'center' }}>
                    조회된 정보가 없습니다.
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Card>
    </>
  );
}
