import {
  Box,
  Button,
  Checkbox,
  CssBaseline,
  FormControlLabel,
  GlobalStyles,
  IconButton,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import config from '../../config';
import useBackendV2 from '../../hooks/use-backend-v2';
import AuthContext from '../../store/auth-context';
import solarPannel from '../../assets/EtcImg/solar_pannel.jpg';
import KakaoLogin from '../../components/Authentication/SocialLogin/KakaoLogin';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useTheme } from '@emotion/react';
import { useCookies } from 'react-cookie';
import NoticePopup from '../../components/Authentication/NoticePopup';
import logo_solarcube from '../../assets/EtcImg/logo_solarcube.svg';
import logo_solarcube2 from '../../assets/EtcImg/logo_solarcube2.svg';
import NewAppVersionGuideModal from '../../components/Authentication/NewAppVersionGuideModal';
import web_qr_code from '../../assets/EtcImg/WEB_QR_CODE.svg';
import web_qr_code_sc from '../../assets/EtcImg/WEB_QR_CODE_SC.svg';
import SafetyManagerApplyModal from '../../components/Authentication/SafetyManagerApplyModal';
import PullToRefresh from '../../components/Layout/PullToRefresh';
import CallIcon from '@mui/icons-material/Call';

// 로그인 컴포넌트
export default function Login02() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  let from = location.state?.from?.pathname || '/';

  const [cookies, setCookie, removeCookie] = useCookies(['rememberText', 'rememberPwdText', 'temporaryPW']);

  const [inputId, setInputId] = useState('');
  const [inputPassword, setInputPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isRemember, setIsRemember] = useState(false);

  const [takeAppVer, setTakeAppVer] = useState([]);
  const [newAppDown, setNewAppDown] = useState(false);

  const [isLoginK, setIsLoginK] = useState(false);
  const [isLoginN, setIsLoginN] = useState(false);

  const [applyModalOpen, setApplyModalOpen] = useState(false);

  const [noticeType, setNoticeType] = useState('2');

  const [distance, setDistance] = useState();

  const backendRequest = useBackendV2();

  let now = new Date();
  let after1m = new Date();
  let after3min = new Date();

  const inputIdChangeHandler = (event) => {
    let value = event.target.value;
    value = value.replace(/\s/g, '');
    setInputId(value.trim());
  };
  const inputPasswordChangeHandler = (event) => {
    let value = event.target.value;
    value = value.replace(/\s/g, '');
    setInputPassword(value.trim());
  };
  const toggleShowPassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleOnChange = (e) => {
    setIsRemember(e.target.checked);
  };

  const fetchTakeAppVer = async () => {
    try {
      const requestConfig = {
        method: 'GET',
      };

      const isAuthenticated = false;

      let device;

      if (authCtx.isAndroid) {
        device = 'android';
      } else if (authCtx.isiOS) {
        device = 'ios';
      }

      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.codeSubs.appVer}/${device}`,
        requestConfig,
        isAuthenticated
      );
      setTakeAppVer(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    if (inputId.length === 0 || inputPassword.length === 0) {
      alert('아이디 또는 비밀번호를 입력해주세요.');
      return;
    }

    try {
      setNoticeType('2');
      after1m.setMinutes(now.getMinutes() + 365 * 24 * 60 * 60 * 1000);
      after3min.setMinutes(now.getMinutes() + 1);

      setCookie('temporaryPW', inputPassword, { path: '/', expires: after3min });

      if (isRemember) {
        setCookie('rememberText', inputId, { path: '/', expires: after1m });
        setCookie('rememberPwdText', inputPassword, { path: '/', expires: after1m });
      } else {
        removeCookie('rememberText');
        removeCookie('rememberPwdText');
        removeCookie('temporaryPW');
      }

      const requestConfig = {
        method: 'POST',
        body: {
          id: inputId.toString(),
          password: inputPassword.toString(),
          smtype: 0,
        },
      };
      const result = await backendRequest.sendRequest(
        config.backend.endPoint.authentication.smlogin,
        requestConfig,
        false
      );
      authCtx.login(result.data);

      if (inputId.toString() === 'ssadmin') {
        navigate('/allStatusPlate', { replace: true });
      } else {
        navigate(from, { replace: true, state: { value: 0, subValue: 0 } });
      }
    } catch (error) {
      console.error(error);
      if (error.message === 'INVALID_CREDENTIAL' || error.message === 'INVALID_LOGIN_INFO') {
        alert('아이디 또는 비밀번호가 일치하지 않습니다.');
      } else if (error.message === 'INVALID_USER_USE') {
        alert('미사용으로 등록된 아이디입니다.');
      } else {
        alert('오류가 발생하였습니다. 아이디 또는 비밀번호를 확인해주세요.');
      }
    }
  };

  const handleUserManualClick = () => {
    if (authCtx.isAndroid) {
      window.QWebApp.locationHref('https://www.solarcube.co.kr/UserManuals/SrMain/SM010.aspx');
    } else if (authCtx.isiOS) {
      window.webkit.messageHandlers.locationHref.postMessage(
        'https://www.solarcube.co.kr/UserManuals/SrMain/SM010.aspx'
      );
    } else {
      window.open('https://www.solarcube.co.kr/UserManuals/SrMain/SM010.aspx', '_blank');
    }
  };

  const moveJoinHandler = () => {
    navigate('/join');
  };

  const moveFindIdHandler = () => {
    navigate('/findId');
  };

  const moveFindPwdHandler = () => {
    navigate('/findPwd');
  };

  // 안전관리자 계정 신청 모달 열기
  const openApplyModalHandler = () => {
    setApplyModalOpen(true);
  };

  // 안전관리자 계정 신청 모달 닫기
  const closeApplyModalHandler = () => {
    setApplyModalOpen(false);
  };

  useEffect(() => {
    setNoticeType('2');
    if (cookies.rememberText !== undefined && cookies.rememberPwdText !== undefined) {
      setInputId(cookies.rememberText);
      setInputPassword(cookies.rememberPwdText);
      setIsRemember(true);
    }

    if (authCtx.isiOS || authCtx.isAndroid) {
      fetchTakeAppVer();
    }
  }, []);

  useEffect(() => {
    if (authCtx.isAndroid) {
      const match = navigator.userAgent.match(/AndroidV(\d+,\d+|\d+)/);
      if (match) {
        const android = match[1];

        let FuncVer;
        let AppVer;

        if (android.includes(',')) {
          const parts = android.split(',');
          FuncVer = parts[0];
          AppVer = parts[1];
        } else {
          FuncVer = android;
          AppVer = null; // version이 없는 경우
        }

        if (AppVer && AppVer >= takeAppVer.min && AppVer < takeAppVer.current) {
          setNewAppDown(true);
        }
      }
    } else if (authCtx.isiOS) {
      const match = navigator.userAgent.match(/IoSV(\d+,\d+|\d+)/);
      if (match) {
        const ios = match[1];

        let FuncVer;
        let AppVer;

        if (ios.includes(',')) {
          const parts = ios.split(',');
          FuncVer = parts[0];
          AppVer = parts[1];
        } else {
          FuncVer = ios;
          AppVer = null; // version이 없는 경우
        }

        if (AppVer && AppVer >= takeAppVer.min && AppVer < takeAppVer.current) {
          setNewAppDown(true);
        }
      }
    }
  }, [takeAppVer]);

  // 새로고침이 필요한 경우 처리
  const handlePullToRefresh = () => {
    window.location.reload();
  };

  return (
    <>
      <PullToRefresh onRefresh={handlePullToRefresh} distance={distance} setDistance={setDistance} />
      <CssBaseline />
      <GlobalStyles
        styles={{
          ':root': {
            '--Collapsed-breakpoint': '769px',
            '--Cover-width': '40vw',
            '--Form-maxWidth': '700px',
            '--Transition-duration': '0.4s',
          },
        }}
      />
      <Box
        component="form"
        onSubmit={submitHandler}
        sx={(theme) => ({
          width: 'clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)',
          transition: 'width var(--Transition-duration)',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          position: 'relative',
          zIndex: 1,
          display: 'flex',
          justifyContent: 'flex-end',
          backdropFilter: 'blur(4px)',
          // backgroundColor: 'rgba(255 255 255 / 0.6)', 백그라운드 사진 속성 부여
          backgroundColor: '#F8F9FE',
        })}
      >
        <Box
          sx={{
            width: 'clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)',
            display: 'flex',
            flexDirection: 'column',
            minHeight: '100vh',
            maxWidth: '100%',
            px: 2,
          }}
        >
          {isMobile ? (
            <Box
              component="header"
              sx={{
                py: 3,
                display: 'flex',
                alignItems: 'center',
              }}
            ></Box>
          ) : (
            <Box
              component="header"
              sx={{
                py: 3,
                display: 'flex',
                alignItems: 'center',
              }}
            ></Box>
          )}

          <Box
            component="main"
            sx={{
              my: 'auto',
              py: 0,
              pb: 6,
              display: 'flex',
              flexDirection: 'column',
              gap: isMobile ? 1 : 2,
              width: 500,
              maxWidth: '100%',
              mx: 'auto',
              borderRadius: 'sm',
              '& form': {
                display: 'flex',
                flexDirection: 'column',
                gap: 2,
              },
            }}
          >
            {isMobile ? (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    pb: 0.5,
                  }}
                >
                  {process.env.REACT_APP_DOMAIN === 'SC' ? (
                    <img src={logo_solarcube} alt="SOLARCUBE" style={{ width: '55%', height: '100%' }} />
                  ) : (
                    <img src={logo_solarcube2} alt="SOLARCUBE2" style={{ width: '55%', height: '100%' }} />
                  )}
                </Box>
                {process.env.REACT_APP_DOMAIN === 'SC' ? null : (
                  <Box>
                    <Typography variant="subtitle2" sx={{ color: 'red' }}>
                      기존 DNE 회원은 로그인이 가능합니다.
                    </Typography>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  {process.env.REACT_APP_DOMAIN === 'SC' ? (
                    <img src={logo_solarcube} alt="SOLARCUBE" style={{ width: '60%', height: '100%' }} />
                  ) : (
                    <img src={logo_solarcube2} alt="SOLARCUBE2" style={{ width: '60%', height: '100%' }} />
                  )}
                </Box>
                {process.env.REACT_APP_DOMAIN === 'SC' ? null : (
                  <Box>
                    <Typography variant="subtitle2" sx={{ color: 'red' }}>
                      기존 DNE 회원은 로그인이 가능합니다.
                    </Typography>
                  </Box>
                )}
              </>
            )}
            <Box sx={{ mt: 0.5 }}>
              <TextField
                variant="outlined"
                label="아이디"
                name="Txt_1"
                value={inputId}
                onChange={inputIdChangeHandler}
                sx={{ mb: 0, backgroundColor: 'white', width: '100%', borderRadius: '6%' }}
              />
            </Box>
            <Box>
              <TextField
                type={showPassword ? 'text' : 'password'}
                variant="outlined"
                label="비밀번호"
                name="Txt_2"
                value={inputPassword}
                onChange={inputPasswordChangeHandler}
                sx={{ mb: 0.5, backgroundColor: 'white', width: '100%', borderRadius: '6%' }}
                InputProps={{
                  endAdornment: (
                    <IconButton onClick={toggleShowPassword} edge="end">
                      {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                    </IconButton>
                  ),
                }}
              />
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <FormControlLabel
                control={<Checkbox checked={isRemember} onChange={handleOnChange} />}
                label={
                  <span style={{ fontSize: '14px', fontWeight: 'bold', marginLeft: -9 }}>
                    아이디/비밀번호 저장
                  </span>
                }
              />
              <Typography
                onClick={handleUserManualClick}
                sx={{ fontSize: '14px', fontWeight: 'bold', textDecoration: 'underline', cursor: 'pointer' }}
              >
                RTU 사용자 설명서
              </Typography>
            </Box>
            <Button
              type="submit"
              variant="contained"
              disabled={backendRequest.backendRequestIsLoading}
              size="large"
              sx={{ width: '100%', height: '46px', borderRadius: '9px' }}
            >
              로그인
            </Button>
            <Button
              variant="outlined"
              size="large"
              onClick={openApplyModalHandler}
              sx={{ width: '100%', height: '45px', borderRadius: '9px', mt: -0.6, bgcolor: '#fff' }}
            >
              안전관리자 계정 신청
            </Button>
            <Box sx={{ display: 'flex', justifyContent: 'center', mb: 0.5, mt: 1 }}>
              <Box display="flex">
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: 'center',
                    cursor: 'pointer',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                  onClick={moveFindIdHandler}
                >
                  아이디 찾기
                </Typography>
                <Typography sx={{ ml: 0.5, mr: 0.5, fontSize: '14px', fontWeight: 'bold' }}>|</Typography>
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: 'center',
                    cursor: 'pointer',
                    fontSize: '14px',
                    fontWeight: 'bold',
                  }}
                  onClick={moveFindPwdHandler}
                >
                  비밀번호 재설정
                </Typography>
                {process.env.REACT_APP_DOMAIN === 'SC' ? (
                  <>
                    <Typography sx={{ ml: 0.5, mr: 0.5, fontSize: '14px', fontWeight: 'bold' }}>|</Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        textAlign: 'center',
                        cursor: 'pointer',
                        fontSize: '14px',
                        fontWeight: 'bold',
                        // textDecoration: 'underline',
                      }}
                      onClick={moveJoinHandler}
                    >
                      회원가입
                    </Typography>
                  </>
                ) : null}
              </Box>
            </Box>
            {authCtx.isiOS &&
            (navigator.userAgent.toLowerCase().indexOf('solarcubeii_iosv3') !== -1 ||
              navigator.userAgent.toLowerCase().indexOf('solarcube_iosv3') !== -1) ? null : (
              <>
                {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                  <Box sx={{ flexGrow: 1, mr: 2 }}>
                    <hr style={{ borderTop: '1px solid #999' }} />
                  </Box>
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: 'center',
                      fontSize: '15px',
                      fontWeight: 'bold',
                      textDecoration: 'none',
                    }}
                  >
                    OR
                  </Typography>
                  <Box sx={{ flexGrow: 1, ml: 2 }}>
                    <hr style={{ borderTop: '1px solid #999' }} />
                  </Box>
                </Box> */}

                {isMobile ? (
                  <Typography variant="h5" sx={{ textAlign: 'center' }}>
                    <a href="tel:1566-6403" style={{ textDecoration: 'none', color: 'inherit' }}>
                      문의전화: 1566-6403
                    </a>
                  </Typography>
                ) : (
                  <Typography variant="h5" sx={{ textAlign: 'center' }}>
                    문의전화: 1566-6403
                  </Typography>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 0.5 }}>
                  {authCtx.isAndroid || authCtx.isiOS ? null : (
                    <Box>
                      <img
                        src={process.env.REACT_APP_DOMAIN === 'SC' ? web_qr_code_sc : web_qr_code}
                        style={{ width: 135 }}
                      />
                    </Box>
                  )}
                  <Box
                    sx={{
                      width: '100%',
                      ml: authCtx.isAndroid || authCtx.isiOS ? null : 0,
                      mr: authCtx.isAndroid || authCtx.isiOS ? null : 0,
                    }}
                  >
                    {/* ios 제외 */}
                    <Box sx={{ width: '100%' }}>
                      <KakaoLogin id={inputId} isLoginK={isLoginK} setIsLoginK={setIsLoginK} />
                    </Box>
                    {/* <Box sx={{ width: '100%', mt: 1.2 }}>
                  <NaverLogin id={inputId} isLoginN={isLoginN} setIsLoginN={setIsLoginN} />
                </Box> */}
                  </Box>
                  {authCtx.isAndroid || authCtx.isiOS ? null : (
                    <Box>
                      <img
                        src={process.env.REACT_APP_DOMAIN === 'SC' ? web_qr_code_sc : web_qr_code}
                        style={{ width: 135 }}
                      />
                    </Box>
                  )}
                </Box>
              </>
            )}
          </Box>
          <Box component="footer" sx={{ py: 1 }}>
            <Typography textAlign="center">
              <span style={{ fontWeight: 'bold' }}>㈜큐브소프트</span> 경기도 오산시 외삼미로 15번길 54-2
            </Typography>
            <Typography textAlign="center">
              Copyright {new Date().getFullYear()}. QubeSoft. All rights reserved
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: '100%',
          position: 'fixed',
          right: 0,
          top: 0,
          bottom: 0,
          left: 'clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))',
          transition:
            'background-image var(--Transition-duration), left var(--Transition-duration) !important',
          transitionDelay: 'calc(var(--Transition-duration) + 0.1s)',
          backgroundColor: 'background.level1',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${solarPannel})`,
        })}
      />
      <Box>
        <NoticePopup noticeType={noticeType} />
      </Box>
      {newAppDown && <NewAppVersionGuideModal open={newAppDown} setNewAppDown={setNewAppDown} />}
      {applyModalOpen && <SafetyManagerApplyModal open={applyModalOpen} onClose={closeApplyModalHandler} />}
    </>
  );
}
