import { Box, Grid, Tooltip, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import PlantSettingModal from '../../Setting/PlantSetting/PlantSettingModal';
import config from '../../../config';
import useBackendV2 from '../../../hooks/use-backend-v2';

// 개별 대시보드 발전소 정보 표시 모바일(DNE) - 발전소 상태 표시 컴포넌트
export default function EachPlantStateInfo_DNE({ page, basePlantInfo, invDailyDevelopment }) {
  const [openPlantsetting, setOpenPlantSetting] = useState(false);
  const [subscribeday, setSubscribeday] = useState(null);

  const closePlantSettingHandler = () => {
    setOpenPlantSetting(false);
  };

  const backendRequest = useBackendV2();

  const fetchSubscriptionPeriod = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.subscribe}/${page.plantkey}`
      );

      const filteredData = data.filter((item) => item.subscribeday > -90);

      if (filteredData.length > 0) {
        const maxSubdcribedayItem = filteredData.reduce((maxItem, currentItem) => {
          return currentItem.subscribeday > maxItem.subscribeday ? currentItem : maxItem;
        });

        setSubscribeday(maxSubdcribedayItem.subscribeday);
      } else {
        setSubscribeday(null);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    if (page.plantkey !== '') {
      fetchSubscriptionPeriod();
    }
  }, [page]);

  // 통신 중지된 RTU 개수
  const rtuStatusZeroCount = basePlantInfo[0]?.rtuinfos.filter((rtu) => rtu.rtustatus === 0).length;

  // 전체 RTU 개수
  const totalRtuCount = basePlantInfo[0]?.rtuinfos.length || 0;

  // RTU 상태 텍스트와 색상 결정
  let rtuStatusText = '';
  let rtuStatusColor = '';

  if (totalRtuCount === 1) {
    rtuStatusText = rtuStatusZeroCount === 0 ? '통신중' : '통신 중단';
    rtuStatusColor = rtuStatusZeroCount === 0 ? 'green' : 'red';
  } else if (totalRtuCount > 1) {
    if (rtuStatusZeroCount === 0) {
      rtuStatusText = '통신중';
      rtuStatusColor = 'green';
    } else if (rtuStatusZeroCount === totalRtuCount) {
      rtuStatusText = '통신 중단';
      rtuStatusColor = 'red';
    } else {
      rtuStatusText = '부분 통신';
      rtuStatusColor = 'yellow';
    }
  }

  // 발전 중단된 인버터 개수
  const generateStatusZeroCount = invDailyDevelopment.filter(
    (inverter) => inverter.generatestatus === 0
  ).length;

  // 전체 인버터 개수
  const totalInverterCount = invDailyDevelopment.length || 0;

  // RTU 상태 텍스트와 색상 결정
  let inverterStatusText = '';
  let inverterStatusColor = '';

  if (totalInverterCount === 1) {
    inverterStatusText = generateStatusZeroCount === 0 ? '발전중' : '발전 중단';
    inverterStatusColor = generateStatusZeroCount === 0 ? 'green' : 'red';
  } else if (totalInverterCount > 1) {
    if (generateStatusZeroCount === 0) {
      inverterStatusText = '발전중';
      inverterStatusColor = 'green';
    } else if (generateStatusZeroCount === totalInverterCount) {
      inverterStatusText = '발전 중단';
      inverterStatusColor = 'red';
    } else {
      inverterStatusText = '부분 발전';
      inverterStatusColor = 'yellow';
    }
  }

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={0.5}>
          <Grid item lg={3} md={6} sm={6} xs={6}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mr={2} ml={2}>
              <Typography variant="subtitle2">RTU 상태</Typography>
              <Typography variant="subtitle2" sx={{ color: rtuStatusColor }}>
                {rtuStatusText}
              </Typography>{' '}
              {/* RTU 상태 표시 */}
            </Box>
            <hr />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={6}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mr={2} ml={2}>
              <Typography variant="subtitle2">총 인버터</Typography>
              <Typography variant="subtitle2">
                <Tooltip title="전체 인버터 수" arrow>
                  <span>{invDailyDevelopment.length}</span>
                </Tooltip>
              </Typography>
            </Box>
            <hr />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={6}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mr={2} ml={2}>
              <Typography variant="subtitle2">발전 상태</Typography>
              <Typography variant="subtitle2" sx={{ color: inverterStatusColor }}>
                {inverterStatusText}
              </Typography>{' '}
              {/* 인버터 상태 표시 */}
            </Box>
            <hr />
          </Grid>
          <Grid item lg={3} md={6} sm={6} xs={6}>
            <Box display="flex" alignItems="center" justifyContent="space-between" mr={2} ml={2}>
              <Typography variant="subtitle2">발전 중단</Typography>
              <Typography variant="subtitle2" sx={{ color: 'red' }}>
                <Tooltip title="발전 중지 인버터 수" arrow>
                  <span>{generateStatusZeroCount}</span>
                </Tooltip>
              </Typography>
            </Box>
            <hr />
          </Grid>
        </Grid>
      </Grid>
      <PlantSettingModal
        plantkey={page.plantkey}
        plantName={page.plantName}
        open={openPlantsetting}
        onClose={closePlantSettingHandler}
      />
    </>
  );
}
