import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';

export default function AllAlarmDetail({ alarmDetailClickHandler }) {
  return (
    <List component="nav">
      <ListItemButton onClick={alarmDetailClickHandler}>
        <ListItemIcon>
          <NotificationsActiveIcon />
        </ListItemIcon>
        <ListItemText primary="경보" />
      </ListItemButton>
    </List>
  );
}
