import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import { useParams, useNavigate } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../store/auth-context';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme } from '@mui/material/styles';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { Link } from 'react-router-dom';
import Header02 from '../Layout/Header02';
import SubHeader from '../Layout/SubHeader';
import Nav from '../Layout/Nav';
import useResponsive from '../../hooks/useResponsive';

/*
통합문의게시판 상세 및 수정 컴포넌트
*/
export default function InquiryBoardDetail() {
  const { seq } = useParams();

  const isDesktop = useResponsive('up', 'lg');
  const authCtx = useContext(AuthContext);
  const id = authCtx?.currentUser?.id;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));

  const distance = 0;

  const [row, setRow] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [fileKey, setFileKey] = useState(0);
  const [fileNm, setFileNm] = useState('');
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const backendRequest = useBackendV2();

  const fetchInquiryBoardDetail = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.bulletinBoard.inquiryDetail}/${seq}`
      );
      setRow(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchInquiryBoardDetail();
    fetchUserStylePreference();
  }, []);

  const processStatus = [
    { value: '0', label: '접수' },
    { value: '1', label: '처리 중' },
    { value: '2', label: '불가' },
    { value: '3', label: '완료' },
  ];

  const gubun = [
    { value: 'QA01', label: '모니터링 관련 문의' },
    { value: 'QA02', label: '사용자 문의' },
    { value: 'QA03', label: '상품 문의' },
    { value: 'QA04', label: '결제 문의' },
    { value: 'QA05', label: '기타 문의' },
    { value: 'QA06', label: '세금계산서 발행 요청' },
  ];

  function handleGoList() {
    navigate(config.frontend.bulletinboards, { state: { value: 4, subValue: 3 } });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setRow((prev) => {
      return {
        ...row,
        [name]: value,
      };
    });
  }
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    if (!selectedFile) {
      alert('Please select a file first!');
      return;
    }

    const formData = new FormData();

    formData.append('file', selectedFile);
    formData.append('Filekey', row.fileKey === null || row.fileKey === 0 ? 0 : row.fileKey);
    formData.append('FileType', 2);
    formData.append('Category', 'board|inquiryFile');
    try {
      const fileRequest = {
        method: 'POST',
        mode: 'cors',
        headers: {
          Authorization: `Bearer ${authCtx.token.accessToken.token}`,
          Accept: 'multipart/form-data',
        },
        body: formData,
      };

      const fileResponse = await fetch(
        `${config.backend.host}${config.backend.endPoint.files.file}`,
        fileRequest
      );

      const data = await fileResponse.json();
      if (data.filekey === -1) {
        setIsFileUpload(false);
        setFileKey(-1);
        setFileNm('');
      } else {
        setIsFileUpload(true);
        setFileKey(data.filekey);
        setFileNm(formData.get('file').name);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const inquiryBoardRequestUpdate = useBackendV2();

  const handleUpdate = async () => {
    if (row.title === '') {
      alert('제목을 입력하세요');
      return;
    }
    if (row.questionContent === '') {
      alert('문의내용을 입력하세요');
      return;
    }
    if (row.gubunCcd === 'any') {
      alert('문의 구분을 선택해주세요');
      return;
    }

    if (isFileUpload) {
      try {
        const requestConfig = {
          method: 'PUT',
          body: {
            gubunCcd: row.gubunCcd,
            title: row.title,
            questionContent: row.questionContent,
            udtId: id,
            fileKey: fileKey === -1 ? 0 : fileKey,
            fileNm: fileNm,
          },
        };
        const { data } = await inquiryBoardRequestUpdate.sendRequest(
          `${config.backend.endPoint.bulletinBoard.inquiryBasic}/${seq}/${id}`,
          requestConfig
        );
        alert('수정하였습니다.');
        navigate(config.frontend.bulletinboards, { state: { value: 4, subValue: 3 } });
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    } else if (row.fileNm !== null || row.fileNm !== '') {
      try {
        const requestConfig = {
          method: 'PUT',
          body: {
            gubunCcd: row.gubunCcd,
            title: row.title,
            questionContent: row.questionContent,
            udtId: id,
            fileKey: row.fileKey,
            fileNm: row.fileNm,
          },
        };
        const { data } = await inquiryBoardRequestUpdate.sendRequest(
          `${config.backend.endPoint.bulletinBoard.inquiryBasic}/${seq}/${id}`,
          requestConfig
        );
        alert('수정하였습니다.');
        navigate(config.frontend.bulletinboards, { state: { value: 4, subValue: 3 } });
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    } else {
      try {
        const requestConfig = {
          method: 'PUT',
          body: {
            gubunCcd: row.gubunCcd,
            title: row.title,
            questionContent: row.questionContent,
            udtId: id,
            fileKey: 0,
            fileNm: '',
          },
        };
        const { data } = await inquiryBoardRequestUpdate.sendRequest(
          `${config.backend.endPoint.bulletinBoard.inquiryBasic}/${seq}/${id}`,
          requestConfig
        );
        alert('수정하였습니다.');
        navigate(config.frontend.bulletinboards, { state: { value: 4, subValue: 3 } });
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    }
  };

  // 모바일 반응형을 위한 상수 선언
  const isSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 513px)');
  const isMoreSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 385px)');

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      {isMobile ? (
        <>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isSmallDevice ? 'column' : null,
            }}
          >
            <Grid
              item
              xs={isSmallDevice ? 12 : 1}
              sx={{
                display: 'flex',
                alignItems: isSmallDevice ? 'left' : 'center',
                justifyContent: isSmallDevice ? 'flex-start' : 'flex-end',
              }}
            >
              <Box
                sx={{
                  display: {
                    xs: 'none',
                    sm: 'flex',
                  },
                }}
              >
                <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                  <StyledBreadcrumb
                    label={isMoreSmallDevice ? '통합문의 게시판' : '게시판'}
                    icon={<ContentPasteIcon fontSize="small" color="primary" />}
                    galaxyFoldFontSize={isMoreSmallDevice ? '14px' : '15px'}
                  />
                  <StyledBreadcrumb
                    label={isMoreSmallDevice ? '작성 내용' : '통합문의 게시판'}
                    galaxyFoldFontSize={isMoreSmallDevice ? '14px' : '15px'}
                  />
                  {isMoreSmallDevice ? null : <StyledBreadcrumb label="작성 내용" />}
                </Breadcrumbs>
              </Box>
            </Grid>
            <Grid
              item
              xs={isSmallDevice ? 12 : 1}
              sx={{
                display: 'flex',
                alignItems: isSmallDevice ? 'right' : 'center',
                justifyContent: isSmallDevice ? 'flex-end' : 'flex-start',
              }}
            >
              <Button
                onClick={handleGoList}
                endIcon={<ArrowForwardIcon />}
                sx={{
                  ml: 1,
                  mr: 2,
                  color: '#000',
                  textTransform: 'none',
                  mt: isSmallDevice ? 2 : 0,
                }}
              >
                <Typography variant="subtitle1">돌아가기</Typography>
              </Button>
            </Grid>
          </Grid>
          <Box
            component="form"
            sx={{
              mt: isSmallDevice ? 0 : 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'left',
              width: '100%',
            }}
          >
            {row.length === 0 ? null : (
              <>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    문의 구분
                  </Typography>
                  <TextField
                    select
                    variant="outlined"
                    size="Normal"
                    sx={{ width: '200px' }}
                    name="gubunCcd"
                    value={row.gubunCcd}
                    onChange={handleChange}
                  >
                    <MenuItem value="any">전체</MenuItem>
                    {gubun.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    제목 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    sx={{ mt: 1, width: '100%' }}
                    name="title"
                    value={row.title}
                    onChange={handleChange}
                    autoComplete="off"
                    placeholder="제목을 입력해주세요. ＊"
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    문의 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    multiline
                    rows="10"
                    sx={{ mt: 1, width: '100%', whiteSpace: 'pre-wrap' }}
                    onChange={handleChange}
                    name="questionContent"
                    value={
                      row.questionContent === null || row.questionContent.length === 0
                        ? ''
                        : row.questionContent.replace(/<br\s\/>/g, '\n')
                    }
                  />
                  {row.gubunCcd === 'QA06' ? (
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="subtitle2" sx={{ color: 'red' }}>
                        ※ 세금 계산서 발행 요청시
                      </Typography>
                      <Typography variant="subtitle2" sx={{ color: 'red' }}>
                        발전소이름, 사업자등록번호, 대표자 이름, 이메일, 전화번호, 업태, 업종, 주소를 꼭
                        입력해 주세요!
                      </Typography>
                    </Box>
                  ) : null}
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    답변
                  </Typography>
                  <TextField
                    variant="outlined"
                    multiline
                    rows="10"
                    sx={{ mt: 1, width: '100%', whiteSpace: 'pre-wrap' }}
                    onChange={handleChange}
                    name="answerContent"
                    value={
                      row.answerContent === null || row.answerContent.length === 0
                        ? ''
                        : row.answerContent.replace(/<br\s\/>/g, '\n')
                    }
                    disabled
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    처리 상태
                  </Typography>
                  <TextField
                    select
                    variant="outlined"
                    size="Normal"
                    sx={{ width: '200px' }}
                    name="processStatusCcd"
                    value={row.processStatusCcd}
                    onChange={handleChange}
                    disabled
                  >
                    <MenuItem value="any">전체</MenuItem>
                    {processStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    파일 업로드
                  </Typography>
                  <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }}>
                    <TextField fullWidth type="file" onChange={handleFileChange} sx={{ mb: 2 }} />
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleUpload}
                      disabled={!selectedFile}
                    >
                      {!selectedFile ? '업로드 가능' : '업로드 완료'}
                    </Button>
                  </Box>
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    파일 :{' '}
                    {row.fileNm === null || row.fileNm === '' ? (
                      '없음'
                    ) : isFileUpload ? (
                      '업로드 완료'
                    ) : (
                      <Link
                        to={`https://filedeliver.sensorcube.co.kr/S/File/GetFile?domain=${process.env.REACT_APP_DOMAIN}&id=${row.fileKey}`}
                        // target="_blank"
                      >
                        <AttachmentIcon />
                        {row.fileNm}
                      </Link>
                    )}
                  </Typography>
                </CardContent>
                <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    onClick={handleGoList}
                    sx={{
                      mr: 4,
                      background: '#E5EEF7 0% 0% no-repeat padding-box',
                      color: '#0059B2',
                    }}
                  >
                    목록
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleUpdate}
                    sx={{
                      mr: 0,
                      background: '#0059B2 0% 0% no-repeat padding-box',
                      color: '#FFFFFF',
                    }}
                  >
                    등록
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <StyledBreadcrumb label="게시판" icon={<ContentPasteIcon fontSize="small" color="primary" />} />
              <StyledBreadcrumb label="통합문의 게시판" />
              <StyledBreadcrumb label="작성 내용" />
            </Breadcrumbs>
            <Button
              onClick={handleGoList}
              endIcon={<ArrowForwardIcon />}
              sx={{ textTransform: 'none', ml: 1, mr: 2, color: '#000' }}
            >
              <Typography variant="subtitle1">돌아가기</Typography>
            </Button>
          </Box>
          <Box
            component="form"
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'left',
              width: '100%',
            }}
          >
            {row.length === 0 ? null : (
              <>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    문의 구분
                  </Typography>
                  <TextField
                    select
                    variant="outlined"
                    size="Normal"
                    sx={{ width: '200px' }}
                    name="gubunCcd"
                    value={row.gubunCcd}
                    onChange={handleChange}
                  >
                    <MenuItem value="any">전체</MenuItem>
                    {gubun.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    제목 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    sx={{ mt: 1, width: '100%' }}
                    name="title"
                    value={row.title}
                    onChange={handleChange}
                    autoComplete="off"
                    placeholder="제목을 입력해주세요. ＊"
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    문의 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    multiline
                    rows="10"
                    sx={{ mt: 1, width: '100%', whiteSpace: 'pre-wrap' }}
                    onChange={handleChange}
                    name="questionContent"
                    value={
                      row.questionContent === null || row.questionContent.length === 0
                        ? ''
                        : row.questionContent.replace(/<br\s\/>/g, '\n')
                    }
                  />
                  {row.gubunCcd === 'QA06' ? (
                    <Box sx={{ mt: 1 }}>
                      <Typography variant="subtitle2" sx={{ color: 'red' }}>
                        ※ 세금 계산서 발행 요청시 발전소이름, 사업자등록번호, 대표자 이름, 이메일, 전화번호,
                        업태, 업종, 주소를 꼭 입력해 주세요!
                      </Typography>
                    </Box>
                  ) : null}
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    답변
                  </Typography>
                  <TextField
                    variant="outlined"
                    multiline
                    rows="10"
                    sx={{ mt: 1, width: '100%', whiteSpace: 'pre-wrap' }}
                    onChange={handleChange}
                    name="answerContent"
                    value={
                      row.answerContent === null || row.answerContent.length === 0
                        ? ''
                        : row.answerContent.replace(/<br\s\/>/g, '\n')
                    }
                    disabled
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    처리 상태
                  </Typography>
                  <TextField
                    select
                    variant="outlined"
                    size="Normal"
                    sx={{ width: '200px' }}
                    name="processStatusCcd"
                    value={row.processStatusCcd}
                    onChange={handleChange}
                    disabled
                  >
                    <MenuItem value="any">전체</MenuItem>
                    {processStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    파일 업로드
                  </Typography>
                  <Grid container alignItems="center" spacing={2}>
                    <Grid item xs>
                      <TextField fullWidth type="file" onChange={handleFileChange} />
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleUpload}
                        disabled={!selectedFile}
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '100%',
                          width: '120px',
                        }}
                      >
                        <Typography sx={{ textAlign: 'center', whiteSpace: 'pre-line', lineHeight: '1.5em' }}>
                          {!selectedFile ? '업로드\n가능' : '업로드\n완료'}
                        </Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    파일 :{' '}
                    {row.fileNm === null || row.fileNm === '' ? (
                      '없음'
                    ) : isFileUpload ? (
                      '업로드 완료'
                    ) : (
                      <Link
                        to={`https://filedeliver.sensorcube.co.kr/S/File/GetFile?domain=${process.env.REACT_APP_DOMAIN}&id=${row.fileKey}`}
                        // target="_blank"
                      >
                        <AttachmentIcon />
                        {row.fileNm}
                      </Link>
                    )}
                  </Typography>
                </CardContent>
              </>
            )}
            <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
              <Button
                variant="contained"
                onClick={handleGoList}
                sx={{
                  mr: 4,
                  background: '#E5EEF7 0% 0% no-repeat padding-box',
                  color: '#0059B2',
                }}
              >
                목록
              </Button>
              <Button
                variant="contained"
                onClick={handleUpdate}
                sx={{
                  mr: 0,
                  background: '#0059B2 0% 0% no-repeat padding-box',
                  color: '#FFFFFF',
                }}
              >
                등록
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
