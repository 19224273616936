import { Box, Button, Card, MenuItem, Select, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

export default function AppAlarmSearchBar({
  setSearchState,
  plantState,
  fromDateValue,
  setFromDateValue,
  toDateValue,
  setToDateValue,
  selectFromDateChangeHandler,
  selectToDateChangeHandler,
  selectPlantInvterChangeHandler,
  combinePlantInv,
}) {
  const today = dayjs();

  const handleSearchStateClick = () => {
    setSearchState(true);
  };

  return (
    <>
      <Card
        sx={{
          p: 1.5,
          borderRadius: 2,
          boxShadow: '0px 3px 12px #00000014',
        }}
      >
        <Box sx={{ alignItems: 'center' }}>
          <Select
            variant="standard"
            value={plantState.selectInverter}
            onChange={selectPlantInvterChangeHandler}
            sx={{ width: '100%' }}
          >
            <MenuItem value={'all'}>전체 인버터</MenuItem>
            {combinePlantInv?.map((item, index) => (
              <MenuItem key={index} value={item.serialnum}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
          <Box sx={{ display: 'flex', mt: 2 }}>
            <DatePicker
              value={dayjs(fromDateValue)}
              onChange={(newValue) => {
                setFromDateValue(newValue.$d);
                selectFromDateChangeHandler(newValue.$d);
                setSearchState(true);
              }}
              openTo="day"
              views={['year', 'month', 'day']}
              format="YYYY-MM-DD"
              slotProps={{
                textField: { size: 'small', variant: 'standard', sx: { ml: 1.5, width: 125 } },
              }}
              maxDate={dayjs(today.toDate())}
            />
            <Typography sx={{ ml: 1, fontWeight: 'bold' }}>~</Typography>
            <DatePicker
              value={dayjs(toDateValue)}
              onChange={(newValue) => {
                setToDateValue(newValue.$d);
                selectToDateChangeHandler(newValue.$d);
                setSearchState(true);
              }}
              openTo="day"
              views={['year', 'month', 'day']}
              format="YYYY-MM-DD"
              slotProps={{
                textField: { size: 'small', variant: 'standard', sx: { ml: 1.5, width: 125 } },
              }}
              maxDate={dayjs(today.toDate())}
              minDate={dayjs(fromDateValue)}
            />
          </Box>
          <hr />
          <Button variant="contained" onClick={handleSearchStateClick} sx={{ width: '100%' }}>
            조회
          </Button>
        </Box>
      </Card>
    </>
  );
}
