import { useEffect, useState } from 'react';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, TextField, Typography } from '@mui/material';
import DaumPostcodeEmbed from 'react-daum-postcode';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import AddLocationOutlinedIcon from '@mui/icons-material/AddLocationOutlined';
import EditLocationOutlinedIcon from '@mui/icons-material/EditLocationOutlined';

// 기업 회원 등록 요청 수정 모달 컴포넌트
export default function UpdateTaxBillModal({
  businessNoIsNotDuplicate,
  setBusinessNoIsNotDuplicate,
  companyNameInput,
  addressInput,
  addressDetailInput,
  zipCodeInput,
  companyPhoneInput,
  companyFaxInput,
  companyEmailInput,
  businessNoInput,
  representativeNameInput,
  typeOfBusinessInput,
  itemOfBusinessInput,
  businessAreaInput,
  etcInput,
  setCompanyKey,
  companyKey,
  open,
  onClose,
  confirm,
}) {
  const formIsValid =
    businessNoIsNotDuplicate &&
    companyNameInput.isValid &&
    representativeNameInput.isValid &&
    addressInput.isValid &&
    companyPhoneInput.isValid;

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';
    let zonecode = data.zonecode;

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    addressInput.setValue(fullAddress);
    zipCodeInput.setValue(zonecode);
  };

  const resetInputHandler = () => {
    businessNoInput.setValue('');
    setBusinessNoIsNotDuplicate(false);
  };

  const checkBusinessNoDuplicationRequest = useBackendV2();

  const checkBusinessNoDuplicationHandler = async () => {
    if (businessNoInput.value === '') {
      alert('사업자 등록 번호를 입력해주세요.');
      return;
    }

    const searchRadioValue = 'BusinessNumber';

    try {
      const { data } = await checkBusinessNoDuplicationRequest.sendRequest(
        `${config.backend.endPoint.companies.search}/${searchRadioValue}/${businessNoInput.value}`
      );

      if (data === null || data === '') {
        setBusinessNoIsNotDuplicate(true);
        return;
      }

      setBusinessNoIsNotDuplicate(false);
      alert('이미 등록된 회사입니다. 회사 조회를 이용해주세요.');
    } catch (error) {
      // console.error(error);
      // alert('오류가 발생하였습니다.');
      setBusinessNoIsNotDuplicate(true);
    }
  };

  const backendRequest = useBackendV2();

  const clickCreateCompanyHandler = async () => {
    try {
      const newRequestConfig = {
        method: 'POST',
        body: {
          companyName: companyNameInput.value,
          address: addressInput.value,
          address2: addressDetailInput.value,
          zipcode: zipCodeInput.value,
          tel: companyPhoneInput.value,
          fax: companyFaxInput.value,
          businessNumber: businessNoInput.value,
          ceoName: representativeNameInput.value,
          email: companyEmailInput.value,
          businessType: typeOfBusinessInput.value,
          businessCategory: itemOfBusinessInput.value,
          businessAreas: businessAreaInput.value,
        },
      };
      const createResult = await backendRequest.sendRequest(
        config.backend.endPoint.companies.create,
        newRequestConfig,
        false
      );

      setCompanyKey(createResult.data);

      confirm();
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const clickUpdateCompanyHandler = async () => {
    try {
      const newRequestConfig = {
        method: 'PUT',
        body: {
          companyName: companyNameInput.value,
          address: addressInput.value,
          address2: addressDetailInput.value ?? '',
          zipcode: zipCodeInput.value,
          tel: companyPhoneInput.value,
          fax: companyFaxInput.value,
          businessNumber: businessNoInput.value,
          ceoName: representativeNameInput.value,
          email: companyEmailInput.value,
          businessType: typeOfBusinessInput.value,
          businessCategory: itemOfBusinessInput.value,
          businessAreas: businessAreaInput.value,
        },
      };
      const updateResult = await backendRequest.sendRequest(
        `${config.backend.endPoint.companies.update}/${companyKey}`,
        newRequestConfig
      );

      if (updateResult.data === 0) {
        alert('수정이 완료되었습니다.');
        confirm();
      } else {
        alert('오류가 발생하였습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }

    onClose();
  };

  useEffect(() => {
    if (companyKey === 0) {
      setBusinessNoIsNotDuplicate(false);
    } else {
      setBusinessNoIsNotDuplicate(true);
    }
  }, [companyKey]);

  return (
    <CustomModal
      open={open}
      onClose={closeModalHandler}
      sx={{ maxHeight: '95%', width: { xs: '95%', md: 600 } }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 1 }}>
        {companyKey === 0 ? (
          <AddLocationOutlinedIcon sx={{ mr: 1 }} />
        ) : (
          <EditLocationOutlinedIcon sx={{ mr: 1 }} />
        )}
        <Typography variant="h6">{companyKey === 0 ? '회사 등록' : '회사 수정'}</Typography>
      </Box>
      <Box display="flex" justifyContent="end">
        <Typography variant="h6" sx={{ mr: 0.5, color: 'red' }}>
          *
        </Typography>
        <Typography variant="body2">는 필수 입력 사항입니다.</Typography>
      </Box>
      <TextField
        label="회사 이름"
        variant="outlined"
        required
        sx={{ width: '100%', mt: 1.5 }}
        onChange={companyNameInput.valueChangeHandler}
        onBlur={companyNameInput.inputBlurHandler}
        value={companyNameInput.value}
        error={companyNameInput.hasError}
        helperText={companyNameInput.hasError && '최소 1자 입력'}
      />
      <TextField
        label="주소"
        variant="outlined"
        required
        sx={{ width: '100%', mt: 1.5 }}
        value={addressInput.value}
        onChange={addressInput.valueChangeHandler}
        disabled
      />
      <DaumPostcodeEmbed onComplete={handleComplete} />
      <TextField
        label="주소 상세"
        sx={{ width: '100%', mt: 1.5 }}
        value={addressDetailInput.value}
        onChange={addressDetailInput.valueChangeHandler}
      />
      <TextField
        label="우편번호"
        disabled
        sx={{ width: '100%', mt: 1.5 }}
        value={zipCodeInput.value}
        onChange={zipCodeInput.valueChangeHandler}
      />
      <TextField
        label="대표자명"
        required
        sx={{ width: '100%', mt: 1.5 }}
        value={representativeNameInput.value}
        onChange={representativeNameInput.valueChangeHandler}
        onBlur={representativeNameInput.inputBlurHandler}
        error={representativeNameInput.hasError}
        helperText={representativeNameInput.hasError && '최소 2자. 최대 20자. 한글, 영문만 가능'}
      />
      <TextField
        label="대표전화"
        required
        sx={{ width: '100%', mt: 1.5 }}
        value={companyPhoneInput.value}
        onChange={companyPhoneInput.valueChangeHandler}
        onBlur={companyPhoneInput.inputBlurHandler}
        error={companyPhoneInput.hasError}
        helperText={companyPhoneInput.hasError && '대표전화 형식이 잘못되었습니다.'}
      />
      <TextField
        label="팩스번호"
        sx={{ width: '100%', mt: 1.5 }}
        value={companyFaxInput.value}
        onChange={companyFaxInput.valueChangeHandler}
        onBlur={companyFaxInput.inputBlurHandler}
        error={companyFaxInput.hasError}
        helperText={companyFaxInput.hasError && '팩스번호 형식이 잘못되었습니다.'}
      />
      <TextField
        label="대표이메일"
        sx={{ width: '100%', mt: 1.5 }}
        value={companyEmailInput.value}
        onChange={companyEmailInput.valueChangeHandler}
        onBlur={companyEmailInput.inputBlurHandler}
        error={companyEmailInput.hasError}
        helperText={companyEmailInput.hasError && '이메일 형식이 잘못되었습니다.'}
      />
      <TextField
        label="사업자 등록 번호"
        required
        sx={{ width: '100%', mt: 1.5 }}
        value={businessNoInput.value}
        onChange={businessNoInput.valueChangeHandler}
        onBlur={businessNoInput.inputBlurHandler}
        error={businessNoInput.hasError}
        helperText={businessNoInput.hasError && '사업자 등록 번호 형식이 잘못되었습니다.'}
        disabled={businessNoIsNotDuplicate}
      />
      {businessNoIsNotDuplicate ? (
        <Button
          type="button"
          sx={{ width: '100%', mt: 1 }}
          onClick={resetInputHandler}
          variant="outlined"
          color="success"
        >
          재설정
        </Button>
      ) : (
        <Button
          type="button"
          sx={{ width: '100%', mt: 1 }}
          onClick={checkBusinessNoDuplicationHandler}
          variant="contained"
        >
          중복 확인
        </Button>
      )}
      <TextField
        label="업태"
        sx={{ width: '100%', mt: 1.5 }}
        value={typeOfBusinessInput.value}
        onChange={typeOfBusinessInput.valueChangeHandler}
      />
      <TextField
        label="종목"
        sx={{ width: '100%', mt: 1.5 }}
        value={itemOfBusinessInput.value}
        onChange={itemOfBusinessInput.valueChangeHandler}
      />
      <TextField
        label="관리업체 사업영역"
        multiline
        rows="2"
        sx={{ mt: 1, width: '100%' }}
        value={businessAreaInput.value}
        onChange={businessAreaInput.valueChangeHandler}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 1,
          px: '6%',
        }}
      >
        <Button
          type="button"
          variant="contained"
          disabled={!formIsValid || representativeNameInput.value === null}
          onClick={companyKey === 0 ? clickCreateCompanyHandler : clickUpdateCompanyHandler}
          sx={{ width: '45%' }}
        >
          등록
        </Button>
        <Box sx={{ width: '6%' }} />
        <Button type="button" variant="outlined" sx={{ width: '45%' }} onClick={closeModalHandler}>
          취소
        </Button>
      </Box>
    </CustomModal>
  );
}
