import config from '.';
import StatisticsPage from '../pages/Statistics/StatisticsPage';
import InverterPage from '../pages/Inverter/InverterPage';
import BulletinboardPage from '../pages/Bulletinboard/BulletinboardPage';
import AlarmPage from '../pages/Alarm/AlarmPage';
import userRole from './userRole';
import PaymentPage from '../pages/Payment/PaymentPage';
import ProductsPage from '../pages/Products/ProductsPage';
import PushSettingPage from '../pages/Setting/PushSettingPage';
import AddPlantPage from '../pages/Plant/AddPlantPage';
import AddSubscribeKeyPage from '../pages/Subscribe/AddSubscribeKeyPage';
import ConnectionPlantPage from '../pages/Plant/ConnectionPlantPage';

// 각 메뉴에 대한 설정을 정의

const menuConfigs = [
  {
    isRouteOnly: true,
    name: '통계',
    path: config.frontend.statistics,
    roles: [
      userRole.superAdmin,
      userRole.admin,
      userRole.user,
      userRole.safetyManager,
      userRole.specialCompanyManager,
      userRole.inverterDisitributionManager,
    ],
    navigateOptions: undefined,
    component: <StatisticsPage />,
  },
  {
    isRouteOnly: true,
    name: '인버터',
    path: config.frontend.inverters,
    roles: [
      userRole.superAdmin,
      userRole.admin,
      userRole.user,
      userRole.safetyManager,
      userRole.specialCompanyManager,
      userRole.inverterDisitributionManager,
    ],
    navigateOptions: undefined,
    component: <InverterPage />,
  },
  {
    isRouteOnly: true,
    name: '게시판',
    path: config.frontend.bulletinboards,
    roles: [
      userRole.superAdmin,
      userRole.admin,
      userRole.user,
      userRole.safetyManager,
      userRole.specialCompanyManager,
      userRole.inverterDisitributionManager,
    ],
    navigateOptions: undefined,
    component: <BulletinboardPage />,
  },
  {
    isRouteOnly: true,
    name: '경보',
    path: config.frontend.alarms,
    roles: [
      userRole.superAdmin,
      userRole.admin,
      userRole.user,
      userRole.safetyManager,
      userRole.specialCompanyManager,
      userRole.inverterDisitributionManager,
    ],
    navigateOptions: undefined,
    component: <AlarmPage />,
  },
  {
    isRouteOnly: true,
    name: '결제',
    path: config.frontend.payments,
    roles: [
      userRole.superAdmin,
      userRole.admin,
      userRole.user,
      userRole.safetyManager,
      userRole.specialCompanyManager,
      userRole.inverterDisitributionManager,
    ],
    navigateOptions: undefined,
    component: <PaymentPage />,
  },
  {
    isRouteOnly: true,
    name: '상품',
    path: config.frontend.products,
    roles: [
      userRole.superAdmin,
      userRole.admin,
      userRole.user,
      userRole.safetyManager,
      userRole.specialCompanyManager,
      userRole.inverterDisitributionManager,
    ],
    navigateOptions: undefined,
    component: <ProductsPage />,
  },
  {
    isRouteOnly: true,
    name: 'PUSH 알람 설정',
    path: config.frontend.pushsettings,
    roles: [
      userRole.superAdmin,
      userRole.admin,
      userRole.user,
      userRole.safetyManager,
      userRole.specialCompanyManager,
      userRole.inverterDisitributionManager,
    ],
    navigateOptions: undefined,
    component: <PushSettingPage />,
  },
  {
    isRouteOnly: true,
    name: '발전소 및 RTU 등록',
    path: config.frontend.addPlants,
    roles: [
      userRole.superAdmin,
      userRole.admin,
      userRole.user,
      userRole.safetyManager,
      userRole.specialCompanyManager,
      userRole.inverterDisitributionManager,
    ],
    navigateOptions: undefined,
    component: <AddPlantPage />,
  },
  {
    isRouteOnly: true,
    name: '이용권 등록',
    path: config.frontend.addSubscribeKey,
    roles: [
      userRole.superAdmin,
      userRole.admin,
      userRole.user,
      userRole.safetyManager,
      userRole.specialCompanyManager,
      userRole.inverterDisitributionManager,
    ],
    navigateOptions: undefined,
    component: <AddSubscribeKeyPage />,
  },
  {
    isRouteOnly: true,
    name: '발전소 연계',
    path: config.frontend.connectionPlants,
    roles: [
      userRole.superAdmin,
      userRole.admin,
      userRole.user,
      userRole.safetyManager,
      userRole.specialCompanyManager,
      userRole.inverterDisitributionManager,
    ],
    navigateOptions: undefined,
    component: <ConnectionPlantPage />,
  },
];

export default menuConfigs;
