import { Avatar, Box, Card, CardContent, Typography, styled, useMediaQuery } from '@mui/material';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  Rectangle,
} from 'recharts';
import useResponsive from '../../../hooks/useResponsive';
import MainCard from '../../../theme/MainCard';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';

// 개별 대시보드 일일발전량 차트 컴포넌트
export default function DailyDevelopmentChart({ dailyDevelopment }) {
  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'lg');
  const isIpad = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const isNexthub = useMediaQuery('(min-width: 1025px) and (max-width: 1366px)');

  const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
  }));

  const formatXAxis = (tickItem) => {
    return `${tickItem}H`;
  };

  const statisticsClickHandler = () => {
    localStorage.setItem('menuValue', 2);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.statistics, { state: { subValue: 0 } });
  };

  const hasRadiationData = dailyDevelopment.some((item) => item.radiation !== null);

  return (
    <>
      <MainCard content={false} sx={{ height: 350, overflowY: 'auto' }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography color="primary" fontWeight="bold" fontSize="0.89rem" sx={{ mb: 1, mt: 1 }}>
              일일 발전량
            </Typography>
            <Avatar
              variant="square"
              onClick={statisticsClickHandler}
              sx={{ width: '25px', height: '25px', bgcolor: '#1981E7', cursor: 'pointer' }}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </Avatar>
          </Box>
          <ResponsiveContainer width="100%" height={350}>
            <ComposedChart
              width="100%"
              height="100%"
              data={dailyDevelopment}
              margin={{ top: 60, bottom: 10 }}
            >
              <CartesianGrid stroke="#d1d1d1" vertical={false} />
              <XAxis
                dataKey="year_month_day_hour"
                axisLine={false}
                tickFormatter={formatXAxis}
                tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
              />
              <YAxis
                yAxisId="left"
                axisLine={false}
                tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
                label={{
                  value: 'kWh',
                  offset: 30,
                  angle: 0,
                  position: 'top',
                  fontSize: 14,
                  fontWeight: 'bold',
                  fill: '#000',
                }}
              />
              <YAxis
                yAxisId="right"
                orientation="right"
                axisLine={false}
                tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
                label={{
                  value: 'W/㎡',
                  offset: 30,
                  angle: 0,
                  position: 'top',
                  fontSize: 14,
                  fontWeight: 'bold',
                  fill: '#000',
                }}
              />
              <Tooltip
                labelFormatter={formatXAxis}
                formatter={(value, name, props) => {
                  if (name === '발전량') {
                    return [
                      <Typography key="tooltip-yield" variant="body1" fontWeight="bold">
                        {`${name} : ${value} kWh`}
                      </Typography>,
                      <Typography key="tooltip-yield-name" variant="body1" fontWeight="bold">
                        발전량
                      </Typography>,
                    ];
                  } else if (name === '경사 일사량') {
                    return [
                      <Typography key="tooltip-radiation" variant="body1" fontWeight="bold">
                        {`${name} : ${value} W/㎡`}
                      </Typography>,
                      <Typography key="tooltip-radiation-name" variant="body1" fontWeight="bold">
                        경사 일사량
                      </Typography>,
                    ];
                  }
                  return [value, name];
                }}
              />
              <Legend
                wrapperStyle={{
                  overflowX: 'auto',
                  width: '100%',
                  whiteSpace: 'nowrap',
                }}
              />
              <Bar
                yAxisId="left"
                name="발전량"
                dataKey="yield"
                fill="#2065D1"
                activeBar={<Rectangle fill="pink" stroke="blue" />}
              />
              {hasRadiationData && (
                <Line
                  yAxisId="right"
                  name="경사 일사량"
                  type="monotone"
                  dataKey="radiation"
                  stroke="#ff3300"
                  strokeWidth={3}
                />
              )}
            </ComposedChart>
          </ResponsiveContainer>
        </CardContent>
      </MainCard>
    </>
  );
}
