import { Box, Container, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PersonRemoveOutlinedIcon from '@mui/icons-material/PersonRemoveOutlined';
import usePageLogRecord from '../../hooks/usePageLogRecord';

export default function UserDeleteAnnouncementPage() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { isAndroid, isiOS } = authCtx;

  const movePageClickHandler = () => {
    const url = 'https://dne.qs2200.co.kr/SrUserHelp/SUH200.aspx?tkey=7';

    window.open(url, '_blank');

    if (isAndroid) {
      window.QWebApp.locationHref(url);
    } else if (isiOS) {
      window.webkit.messageHandlers.locationHref.postMessage(url);
    } else {
      window.open(url, '_blank');
    }
  };

  const closeModalHandler = (event, reason) => {
    localStorage.setItem('menuValue', 0);
    localStorage.setItem('menuSubValue', 0);
    navigate('/', { state: { subValue: 0 } });
  };

  usePageLogRecord('deleteAnnouncements');

  return (
    <>
      <Container
        sx={{
          minHeight: '600px',
          minWidth: '50%',
          marginBottom: '4%',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box sx={{ width: { xs: '100%', md: 500 } }}>
            <Box
              sx={{
                display: 'flex',
                // justifyContent: 'end',
                alignItems: 'center',
                mt: isMobile ? 3 : 5,
              }}
            >
              <KeyboardBackspaceIcon
                onClick={closeModalHandler}
                fontSize="large"
                sx={{ cursor: 'pointer' }}
              />
            </Box>
            <Box
              sx={{
                // mt: isMobile ? 5 : 9,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <PersonRemoveOutlinedIcon
                fontSize="large"
                sx={{
                  mr: 1,
                }}
              />
              <Typography variant="h4" fontWeight="bold">
                계정 및 데이터 삭제 안내
              </Typography>
            </Box>
            <hr />
            <Box
              sx={{
                mt: 2,
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
              }}
            >
              <Paper
                variant="outlined"
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                  border: '2px solid #D8D8D8',
                  alignItems: 'center',
                  mb: 1,
                  p: 1,
                }}
              >
                <Typography variant="subtitle2" sx={{ mb: 1 }}>
                  사용자 계정의 삭제 혹은 서비스 탈퇴의 경우,
                  <br />
                  아래 내용에 따라 명기된 주소에 메일을 전송해 주시면 담당자 확인 후 처리해 드리도록
                  하겠습니다.
                </Typography>
              </Paper>
            </Box>
            <hr />
            <Paper
              variant="outlined"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '100%',
                border: '2px solid #D8D8D8',
                mb: 1,
                p: 1,
              }}
            >
              <Typography variant="h6" sx={{ mb: 2 }}>
                ● 다음과 같은 내용으로 메일을 발송합니다.
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1, ml: isMobile ? 2 : 4 }}>
                ○ 메일 제목
              </Typography>
              <Typography variant="subtitle2" sx={{ mb: 3, ml: isMobile ? 4 : 8 }}>
                ■ [솔라큐브 II] 사용자 계정의 삭제를 요청합니다.
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1, ml: isMobile ? 2 : 4 }}>
                ○ 본문 내용 필수 포함 사항
              </Typography>
              <Typography variant="subtitle2" sx={{ mb: 1, ml: isMobile ? 4 : 8 }}>
                ■ 로그인하여 사용하는 "계정 ID"
              </Typography>
              <Typography variant="subtitle2" sx={{ mb: 1, ml: isMobile ? 4 : 8 }}>
                ■ 통화 가능한 "연락처"
              </Typography>
              <Typography variant="subtitle2" sx={{ mb: 3, ml: isMobile ? 4 : 8 }}>
                ■ 등록된 "발전소의 사업자 등록증"
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1, ml: isMobile ? 2 : 4 }}>
                ○ 대상 메일 주소
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 3, ml: isMobile ? 4 : 8 }}>
                ■ infinitywing@qubesoft.kr
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1, ml: isMobile ? 2 : 4 }}>
                ○ 해당 업무 처리 담당자 정보
              </Typography>
              <Typography variant="subtitle2" sx={{ mb: 1, ml: isMobile ? 4 : 8 }}>
                ■ 책임자 : 김홍익 과장
              </Typography>
              <Typography variant="subtitle2" sx={{ mb: 1, ml: isMobile ? 4 : 8 }}>
                ■ 담당부서 : 개인정보 보호팀
              </Typography>
              <Typography variant="subtitle2" sx={{ mb: 5, ml: isMobile ? 4 : 8 }}>
                ■ E-mail : infinitywing@qubesoft.kr
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                ● 개인정보의 처리 방침에 대한 세부 설명
              </Typography>
              <Typography
                variant="subtitle"
                onClick={movePageClickHandler}
                sx={{ mb: 1, ml: isMobile ? 2 : 4, cursor: 'pointer', color: 'blue' }}
              >
                [큐브소프트 개인정보 처리 방침]
              </Typography>
            </Paper>
          </Box>
        </Box>
      </Container>
    </>
  );
}
