import { Card, useMediaQuery, Typography, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

// 종합분석통계 차트 컴포넌트
export default function ComprehensiveAnalysisChart({
  data,
  selectPlantInverters,
  analysisDatalength,
  comprehensiveAnalysis,
  comprehensiveSearchState,
  setComprehensiveSearchState,
  isDesktop,
}) {
  const isIpad = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const isNexthub = useMediaQuery('(min-width: 1025px) and (max-width: 1366px)');

  const formatXAxis = (tickItem) => {
    return `${tickItem}시`;
  };

  const [yAxes, setYAxes] = useState([]);

  useEffect(() => {
    if (comprehensiveSearchState === true) {
      const generateYAxes = () => {
        const yAxisMap = {};
        const generatedYAxes = [];
        let orientation = 'left'; // 초기 방향을 왼쪽으로 설정

        comprehensiveAnalysis.forEach((item) => {
          let yAxisId, labelValue;

          // 아이템에 따라 yAxisId를 결정합니다.
          if (item === 'pac' || item === 'pdc') {
            yAxisId = 'P';
            labelValue = 'kW';
          } else if (item === 'uac' || item === 'udc') {
            yAxisId = 'U';
            labelValue = 'V';
          } else if (item === 'iac' || item === 'idc') {
            yAxisId = 'I';
            labelValue = 'A';
          } else if (item === 'totalyield') {
            yAxisId = 'Total';
            labelValue = 'kWh';
          } else if (item === 'trad') {
            yAxisId = 'Trad';
            labelValue = 'W/㎡';
          }

          // 만약 yAxisId가 아직 존재하지 않으면 새 항목을 생성합니다.
          if (!yAxisMap[yAxisId]) {
            yAxisMap[yAxisId] = { id: yAxisId, orientation, labelValue };
          }

          // 다음 반복을 위해 방향을 토글합니다.
          orientation = orientation === 'left' ? 'right' : 'left';
        });

        // 생성된 yAxis 항목을 배열에 추가합니다.
        for (const key in yAxisMap) {
          generatedYAxes.push(yAxisMap[key]);
        }

        setYAxes(generatedYAxes);
      };

      generateYAxes();
    }
  }, [comprehensiveAnalysis, comprehensiveSearchState]);

  return (
    <>
      <Card sx={{ mt: 1, borderRadius: '14px', boxShadow: '0px 3px 12px #00000014' }}>
        <ResponsiveContainer width="100%" height={isDesktop ? 650 : isIpad ? 550 : isNexthub ? 550 : 400}>
          <ComposedChart width="100%" height="100%" data={data} margin={{ top: 60, bottom: 10 }}>
            {analysisDatalength !== 0 ? (
              <>
                <CartesianGrid stroke="#d1d1d1" vertical={false} />
                <XAxis
                  dataKey="fhour"
                  axisLine={false}
                  tickFormatter={formatXAxis}
                  tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
                />
                {/* 생성된 YAxis를 반영 */}
                {yAxes.map((axis) => (
                  <YAxis
                    key={axis.id}
                    yAxisId={axis.id}
                    orientation={axis.orientation}
                    tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
                    label={{
                      value: axis.labelValue,
                      offset: 30,
                      angle: 0,
                      position: 'top',
                      fontSize: 14,
                      fontWeight: 'bold',
                      fill: '#000',
                    }}
                  />
                ))}
                {comprehensiveAnalysis.includes('totalyield') && (
                  <>
                    {data.length !== 0 && (
                      <>
                        {Object.keys(data[0]).includes('totalyield0') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield0"
                            type="monotone"
                            dataKey="totalyield0"
                            stroke="#ff0000"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield1') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield1"
                            type="monotone"
                            dataKey="totalyield1"
                            stroke="#00b300"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield2') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield2"
                            type="monotone"
                            dataKey="totalyield2"
                            stroke="#0000FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield3') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield3"
                            type="monotone"
                            dataKey="totalyield3"
                            stroke="#ffbf00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield4') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield4"
                            type="monotone"
                            dataKey="totalyield4"
                            stroke="#28b5c2"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield5') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield5"
                            type="monotone"
                            dataKey="totalyield5"
                            stroke="#FF00FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield6') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield6"
                            type="monotone"
                            dataKey="totalyield6"
                            stroke="#FF7F00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield7') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield7"
                            type="monotone"
                            dataKey="totalyield7"
                            stroke="#008040"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield8') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield8"
                            type="monotone"
                            dataKey="totalyield8"
                            stroke="#7F00FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield9') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield9"
                            type="monotone"
                            dataKey="totalyield9"
                            stroke="#86b300"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield10') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield10"
                            type="monotone"
                            dataKey="totalyield10"
                            stroke="#007FFF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield11') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield11"
                            type="monotone"
                            dataKey="totalyield11"
                            stroke="#FF007F"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield12') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield12"
                            type="monotone"
                            dataKey="totalyield12"
                            stroke="#FF5500"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield13') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield13"
                            type="monotone"
                            dataKey="totalyield13"
                            stroke="#009933"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield14') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield14"
                            type="monotone"
                            dataKey="totalyield14"
                            stroke="#5500FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield15') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield15"
                            type="monotone"
                            dataKey="totalyield15"
                            stroke="#e69900"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield16') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield16"
                            type="monotone"
                            dataKey="totalyield16"
                            stroke="#0055FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield17') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield17"
                            type="monotone"
                            dataKey="totalyield17"
                            stroke="#FF0055"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield18') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield18"
                            type="monotone"
                            dataKey="totalyield18"
                            stroke="#7F7F00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('totalyield19') && (
                          <Line
                            yAxisId="Total"
                            name="TotalYield19"
                            type="monotone"
                            dataKey="totalyield19"
                            stroke="#007F7F"
                            strokeWidth={1}
                          />
                        )}
                      </>
                    )}
                  </>
                )}

                {comprehensiveAnalysis.includes('trad') && (
                  <>
                    {data.length !== 0 && (
                      <>
                        {Object.keys(data[0]).includes('trad') && (
                          <Line
                            yAxisId="Trad"
                            name="RADITION"
                            type="monotone"
                            dataKey="trad"
                            stroke="#c5b000"
                            strokeWidth={1}
                          />
                        )}
                      </>
                    )}
                  </>
                )}

                {comprehensiveAnalysis.includes('pac') && (
                  <>
                    {data.length !== 0 && (
                      <>
                        {Object.keys(data[0]).includes('pac0') && (
                          <Line
                            yAxisId="P"
                            name="PAC0"
                            type="monotone"
                            dataKey="pac0"
                            stroke="#958623"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac1') && (
                          <Line
                            yAxisId="P"
                            name="PAC1"
                            type="monotone"
                            dataKey="pac1"
                            stroke="#58aab7"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac2') && (
                          <Line
                            yAxisId="P"
                            name="PAC2"
                            type="monotone"
                            dataKey="pac2"
                            stroke="#ff8000"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac3') && (
                          <Line
                            yAxisId="P"
                            name="PAC3"
                            type="monotone"
                            dataKey="pac3"
                            stroke="#2cb300"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac4') && (
                          <Line
                            yAxisId="P"
                            name="PAC4"
                            type="monotone"
                            dataKey="pac4"
                            stroke="#8700b3"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac5') && (
                          <Line
                            yAxisId="P"
                            name="PAC5"
                            type="monotone"
                            dataKey="pac5"
                            stroke="#ee6d73"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac6') && (
                          <Line
                            yAxisId="P"
                            name="PAC6"
                            type="monotone"
                            dataKey="pac6"
                            stroke="#894400"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac7') && (
                          <Line
                            yAxisId="P"
                            name="PAC7"
                            type="monotone"
                            dataKey="pac7"
                            stroke="#008080"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac8') && (
                          <Line
                            yAxisId="P"
                            name="PAC8"
                            type="monotone"
                            dataKey="pac8"
                            stroke="#6b00d8"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac9') && (
                          <Line
                            yAxisId="P"
                            name="PAC9"
                            type="monotone"
                            dataKey="pac9"
                            stroke="#0086b3"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac10') && (
                          <Line
                            yAxisId="P"
                            name="PAC10"
                            type="monotone"
                            dataKey="pac10"
                            stroke="#004d52"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac11') && (
                          <Line
                            yAxisId="P"
                            name="PAC11"
                            type="monotone"
                            dataKey="pac11"
                            stroke="#FF007F"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac12') && (
                          <Line
                            yAxisId="P"
                            name="PAC12"
                            type="monotone"
                            dataKey="pac12"
                            stroke="#FF5500"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac13') && (
                          <Line
                            yAxisId="P"
                            name="PAC13"
                            type="monotone"
                            dataKey="pac13"
                            stroke="#009933"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac14') && (
                          <Line
                            yAxisId="P"
                            name="PAC14"
                            type="monotone"
                            dataKey="pac14"
                            stroke="#5500FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac15') && (
                          <Line
                            yAxisId="P"
                            name="PAC15"
                            type="monotone"
                            dataKey="pac15"
                            stroke="#e69900"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac16') && (
                          <Line
                            yAxisId="P"
                            name="PAC16"
                            type="monotone"
                            dataKey="pac16"
                            stroke="#0055FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac17') && (
                          <Line
                            yAxisId="P"
                            name="PAC17"
                            type="monotone"
                            dataKey="pac17"
                            stroke="#FF0055"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac18') && (
                          <Line
                            yAxisId="P"
                            name="PAC18"
                            type="monotone"
                            dataKey="pac18"
                            stroke="#7F7F00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pac19') && (
                          <Line
                            yAxisId="P"
                            name="PAC19"
                            type="monotone"
                            dataKey="pac19"
                            stroke="#007F7F"
                            strokeWidth={1}
                          />
                        )}
                      </>
                    )}
                  </>
                )}

                {comprehensiveAnalysis.includes('pdc') && (
                  <>
                    {data.length !== 0 && (
                      <>
                        {Object.keys(data[0]).includes('pdc0') && (
                          <Line
                            yAxisId="P"
                            name="PDC0"
                            type="monotone"
                            dataKey="pdc0"
                            stroke="#007F7F"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc1') && (
                          <Line
                            yAxisId="P"
                            name="PDC1"
                            type="monotone"
                            dataKey="pdc1"
                            stroke="#7F7F00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc2') && (
                          <Line
                            yAxisId="P"
                            name="PDC2"
                            type="monotone"
                            dataKey="pdc2"
                            stroke="#FF0055"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc3') && (
                          <Line
                            yAxisId="P"
                            name="PDC3"
                            type="monotone"
                            dataKey="pdc3"
                            stroke="#0055FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc4') && (
                          <Line
                            yAxisId="P"
                            name="PDC4"
                            type="monotone"
                            dataKey="pdc4"
                            stroke="#e69900"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc5') && (
                          <Line
                            yAxisId="P"
                            name="PDC5"
                            type="monotone"
                            dataKey="pdc5"
                            stroke="#5500FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc6') && (
                          <Line
                            yAxisId="P"
                            name="PDC6"
                            type="monotone"
                            dataKey="pdc6"
                            stroke="#009933"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc7') && (
                          <Line
                            yAxisId="P"
                            name="PDC7"
                            type="monotone"
                            dataKey="pdc7"
                            stroke="#FF5500"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc8') && (
                          <Line
                            yAxisId="P"
                            name="PDC8"
                            type="monotone"
                            dataKey="pdc8"
                            stroke="#FF007F"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc9') && (
                          <Line
                            yAxisId="P"
                            name="PDC9"
                            type="monotone"
                            dataKey="pdc9"
                            stroke="#007FFF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc10') && (
                          <Line
                            yAxisId="P"
                            name="PDC10"
                            type="monotone"
                            dataKey="pdc10"
                            stroke="#86b300"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc11') && (
                          <Line
                            yAxisId="P"
                            name="PDC11"
                            type="monotone"
                            dataKey="pdc11"
                            stroke="#7F00FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc12') && (
                          <Line
                            yAxisId="P"
                            name="PDC12"
                            type="monotone"
                            dataKey="pdc12"
                            stroke="#008040"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc13') && (
                          <Line
                            yAxisId="P"
                            name="PDC13"
                            type="monotone"
                            dataKey="pdc13"
                            stroke="#FF7F00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc14') && (
                          <Line
                            yAxisId="P"
                            name="PDC14"
                            type="monotone"
                            dataKey="pdc14"
                            stroke="#FF00FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc15') && (
                          <Line
                            yAxisId="P"
                            name="PDC15"
                            type="monotone"
                            dataKey="pdc15"
                            stroke="#28b5c2"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc16') && (
                          <Line
                            yAxisId="P"
                            name="PDC16"
                            type="monotone"
                            dataKey="pdc16"
                            stroke="#ffbf00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc17') && (
                          <Line
                            yAxisId="P"
                            name="PDC17"
                            type="monotone"
                            dataKey="pdc17"
                            stroke="#0000FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc18') && (
                          <Line
                            yAxisId="P"
                            name="PDC18"
                            type="monotone"
                            dataKey="pdc18"
                            stroke="#00b300"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('pdc19') && (
                          <Line
                            yAxisId="P"
                            name="PDC19"
                            type="monotone"
                            dataKey="pdc19"
                            stroke="#ff0000"
                            strokeWidth={1}
                          />
                        )}
                      </>
                    )}
                  </>
                )}

                {comprehensiveAnalysis.includes('uac') && (
                  <>
                    {data.length !== 0 && (
                      <>
                        {Object.keys(data[0]).includes('uac0') && (
                          <Line
                            yAxisId="U"
                            name="UAC0"
                            type="monotone"
                            dataKey="uac0"
                            stroke="#007FFF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac1') && (
                          <Line
                            yAxisId="U"
                            name="UAC1"
                            type="monotone"
                            dataKey="uac1"
                            stroke="#FF007F"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac2') && (
                          <Line
                            yAxisId="U"
                            name="UAC2"
                            type="monotone"
                            dataKey="uac2"
                            stroke="#FF5500"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac3') && (
                          <Line
                            yAxisId="U"
                            name="UAC3"
                            type="monotone"
                            dataKey="uac3"
                            stroke="#009933"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac4') && (
                          <Line
                            yAxisId="U"
                            name="UAC4"
                            type="monotone"
                            dataKey="uac4"
                            stroke="#5500FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac5') && (
                          <Line
                            yAxisId="U"
                            name="UAC5"
                            type="monotone"
                            dataKey="uac5"
                            stroke="#e69900"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac6') && (
                          <Line
                            yAxisId="U"
                            name="UAC6"
                            type="monotone"
                            dataKey="uac6"
                            stroke="#0055FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac7') && (
                          <Line
                            yAxisId="U"
                            name="UAC7"
                            type="monotone"
                            dataKey="uac7"
                            stroke="#FF0055"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac8') && (
                          <Line
                            yAxisId="U"
                            name="UAC8"
                            type="monotone"
                            dataKey="uac8"
                            stroke="#7F7F00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac9') && (
                          <Line
                            yAxisId="U"
                            name="UAC9"
                            type="monotone"
                            dataKey="uac9"
                            stroke="#007F7F"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac10') && (
                          <Line
                            yAxisId="U"
                            name="UAC10"
                            type="monotone"
                            dataKey="uac10"
                            stroke="#ff0000"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac11') && (
                          <Line
                            yAxisId="U"
                            name="UAC11"
                            type="monotone"
                            dataKey="uac11"
                            stroke="#00b300"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac12') && (
                          <Line
                            yAxisId="U"
                            name="UAC12"
                            type="monotone"
                            dataKey="uac12"
                            stroke="#0000FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac13') && (
                          <Line
                            yAxisId="U"
                            name="UAC13"
                            type="monotone"
                            dataKey="uac13"
                            stroke="#ffbf00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac14') && (
                          <Line
                            yAxisId="U"
                            name="UAC14"
                            type="monotone"
                            dataKey="uac14"
                            stroke="#28b5c2"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac15') && (
                          <Line
                            yAxisId="U"
                            name="UAC15"
                            type="monotone"
                            dataKey="uac15"
                            stroke="#FF00FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac16') && (
                          <Line
                            yAxisId="U"
                            name="UAC16"
                            type="monotone"
                            dataKey="uac16"
                            stroke="#FF7F00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac17') && (
                          <Line
                            yAxisId="U"
                            name="UAC17"
                            type="monotone"
                            dataKey="uac17"
                            stroke="#008040"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac18') && (
                          <Line
                            yAxisId="U"
                            name="UAC18"
                            type="monotone"
                            dataKey="uac18"
                            stroke="#7F00FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('uac19') && (
                          <Line
                            yAxisId="U"
                            name="UAC19"
                            type="monotone"
                            dataKey="uac19"
                            stroke="#86b300"
                            strokeWidth={1}
                          />
                        )}
                      </>
                    )}
                  </>
                )}

                {comprehensiveAnalysis.includes('udc') && (
                  <>
                    {data.length !== 0 && (
                      <>
                        {Object.keys(data[0]).includes('udc0') && (
                          <Line
                            yAxisId="U"
                            name="UDC0"
                            type="monotone"
                            dataKey="udc0"
                            stroke="#86b300"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc1') && (
                          <Line
                            yAxisId="U"
                            name="UDC1"
                            type="monotone"
                            dataKey="udc1"
                            stroke="#7F00FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc2') && (
                          <Line
                            yAxisId="U"
                            name="UDC2"
                            type="monotone"
                            dataKey="udc2"
                            stroke="#008040"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc3') && (
                          <Line
                            yAxisId="U"
                            name="UDC3"
                            type="monotone"
                            dataKey="udc3"
                            stroke="#FF7F00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc4') && (
                          <Line
                            yAxisId="U"
                            name="UDC4"
                            type="monotone"
                            dataKey="udc4"
                            stroke="#FF00FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc5') && (
                          <Line
                            yAxisId="U"
                            name="UDC5"
                            type="monotone"
                            dataKey="udc5"
                            stroke="#28b5c2"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc6') && (
                          <Line
                            yAxisId="U"
                            name="UDC6"
                            type="monotone"
                            dataKey="udc6"
                            stroke="#ffbf00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc7') && (
                          <Line
                            yAxisId="U"
                            name="UDC7"
                            type="monotone"
                            dataKey="udc7"
                            stroke="#0000FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc8') && (
                          <Line
                            yAxisId="U"
                            name="UDC8"
                            type="monotone"
                            dataKey="udc8"
                            stroke="#00b300"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc9') && (
                          <Line
                            yAxisId="U"
                            name="UDC9"
                            type="monotone"
                            dataKey="udc9"
                            stroke="#ff0000"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc10') && (
                          <Line
                            yAxisId="U"
                            name="UDC10"
                            type="monotone"
                            dataKey="udc10"
                            stroke="#007F7F"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc11') && (
                          <Line
                            yAxisId="U"
                            name="UDC11"
                            type="monotone"
                            dataKey="udc11"
                            stroke="#7F7F00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc12') && (
                          <Line
                            yAxisId="U"
                            name="UDC12"
                            type="monotone"
                            dataKey="udc12"
                            stroke="#FF0055"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc13') && (
                          <Line
                            yAxisId="U"
                            name="UDC13"
                            type="monotone"
                            dataKey="udc13"
                            stroke="#0055FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc14') && (
                          <Line
                            yAxisId="U"
                            name="UDC14"
                            type="monotone"
                            dataKey="udc14"
                            stroke="#e69900"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc15') && (
                          <Line
                            yAxisId="U"
                            name="UDC15"
                            type="monotone"
                            dataKey="udc15"
                            stroke="#5500FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('dc16') && (
                          <Line
                            yAxisId="U"
                            name="UDC16"
                            type="monotone"
                            dataKey="udc16"
                            stroke="#009933"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc17') && (
                          <Line
                            yAxisId="U"
                            name="UDC17"
                            type="monotone"
                            dataKey="udc17"
                            stroke="#FF5500"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc18') && (
                          <Line
                            yAxisId="U"
                            name="UDC18"
                            type="monotone"
                            dataKey="udc18"
                            stroke="#FF007F"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('udc19') && (
                          <Line
                            yAxisId="U"
                            name="UDC19"
                            type="monotone"
                            dataKey="udc19"
                            stroke="#007FFF"
                            strokeWidth={1}
                          />
                        )}
                      </>
                    )}
                  </>
                )}

                {comprehensiveAnalysis.includes('iac') && (
                  <>
                    {data.length !== 0 && (
                      <>
                        {Object.keys(data[0]).includes('iac0') && (
                          <Line
                            yAxisId="I"
                            name="IAC0"
                            type="monotone"
                            dataKey="iac0"
                            stroke="#004d52"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac1') && (
                          <Line
                            yAxisId="I"
                            name="IAC1"
                            type="monotone"
                            dataKey="iac1"
                            stroke="#0086b3"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac2') && (
                          <Line
                            yAxisId="I"
                            name="IAC2"
                            type="monotone"
                            dataKey="iac2"
                            stroke="#6b00d8"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac3') && (
                          <Line
                            yAxisId="I"
                            name="IAC3"
                            type="monotone"
                            dataKey="iac3"
                            stroke="#008080"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac4') && (
                          <Line
                            yAxisId="I"
                            name="IAC4"
                            type="monotone"
                            dataKey="iac4"
                            stroke="#894400"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac5') && (
                          <Line
                            yAxisId="I"
                            name="IAC5"
                            type="monotone"
                            dataKey="iac5"
                            stroke="#ee6d73"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac6') && (
                          <Line
                            yAxisId="I"
                            name="IAC6"
                            type="monotone"
                            dataKey="iac6"
                            stroke="#8700b3"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac7') && (
                          <Line
                            yAxisId="I"
                            name="IAC7"
                            type="monotone"
                            dataKey="iac7"
                            stroke="#2cb300"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac8') && (
                          <Line
                            yAxisId="I"
                            name="IAC8"
                            type="monotone"
                            dataKey="iac8"
                            stroke="#ff8000"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac9') && (
                          <Line
                            yAxisId="I"
                            name="IAC9"
                            type="monotone"
                            dataKey="iac9"
                            stroke="#58aab7"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac10') && (
                          <Line
                            yAxisId="I"
                            name="IAC10"
                            type="monotone"
                            dataKey="iac10"
                            stroke="#958623"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac11') && (
                          <Line
                            yAxisId="I"
                            name="IAC11"
                            type="monotone"
                            dataKey="iac11"
                            stroke="#007F7F"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac12') && (
                          <Line
                            yAxisId="I"
                            name="IAC12"
                            type="monotone"
                            dataKey="iac12"
                            stroke="#7F7F00"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac13') && (
                          <Line
                            yAxisId="I"
                            name="IAC13"
                            type="monotone"
                            dataKey="iac13"
                            stroke="#FF0055"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac14') && (
                          <Line
                            yAxisId="I"
                            name="IAC14"
                            type="monotone"
                            dataKey="iac14"
                            stroke="#0055FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac15') && (
                          <Line
                            yAxisId="I"
                            name="IAC15"
                            type="monotone"
                            dataKey="iac15"
                            stroke="#e69900"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac16') && (
                          <Line
                            yAxisId="I"
                            name="IAC16"
                            type="monotone"
                            dataKey="iac16"
                            stroke="#5500FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac17') && (
                          <Line
                            yAxisId="I"
                            name="IAC17"
                            type="monotone"
                            dataKey="iac17"
                            stroke="#009933"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac18') && (
                          <Line
                            yAxisId="I"
                            name="IAC18"
                            type="monotone"
                            dataKey="iac18"
                            stroke="#FF5500"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('iac19') && (
                          <Line
                            yAxisId="I"
                            name="IAC19"
                            type="monotone"
                            dataKey="iac19"
                            stroke="#FF007F"
                            strokeWidth={1}
                          />
                        )}
                      </>
                    )}
                  </>
                )}

                {comprehensiveAnalysis.includes('idc') && (
                  <>
                    {data.length !== 0 && (
                      <>
                        {Object.keys(data[0]).includes('idc0') && (
                          <Line
                            yAxisId="I"
                            name="IDC0"
                            type="monotone"
                            dataKey="idc0"
                            stroke="#2cb300"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc1') && (
                          <Line
                            yAxisId="I"
                            name="IDC1"
                            type="monotone"
                            dataKey="idc1"
                            stroke="#8700b3"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc2') && (
                          <Line
                            yAxisId="I"
                            name="IDC2"
                            type="monotone"
                            dataKey="idc2"
                            stroke="#894400"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc3') && (
                          <Line
                            yAxisId="I"
                            name="IDC3"
                            type="monotone"
                            dataKey="idc3"
                            stroke="#6b00d8"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc4') && (
                          <Line
                            yAxisId="I"
                            name="IDC4"
                            type="monotone"
                            dataKey="idc4"
                            stroke="#008080"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc5') && (
                          <Line
                            yAxisId="I"
                            name="IDC5"
                            type="monotone"
                            dataKey="idc5"
                            stroke="#958623"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc6') && (
                          <Line
                            yAxisId="I"
                            name="IDC6"
                            type="monotone"
                            dataKey="idc6"
                            stroke="#58aab7"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc7') && (
                          <Line
                            yAxisId="I"
                            name="IDC7"
                            type="monotone"
                            dataKey="idc7"
                            stroke="#004d52"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc8') && (
                          <Line
                            yAxisId="I"
                            name="IDC8"
                            type="monotone"
                            dataKey="idc8"
                            stroke="#6b00d8"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc9') && (
                          <Line
                            yAxisId="I"
                            name="IDC9"
                            type="monotone"
                            dataKey="idc9"
                            stroke="#0086b3"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc10') && (
                          <Line
                            yAxisId="I"
                            name="IDC10"
                            type="monotone"
                            dataKey="idc10"
                            stroke="#FF5500"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc11') && (
                          <Line
                            yAxisId="I"
                            name="IDC11"
                            type="monotone"
                            dataKey="idc11"
                            stroke="#FF007F"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc12') && (
                          <Line
                            yAxisId="I"
                            name="IDC12"
                            type="monotone"
                            dataKey="idc12"
                            stroke="#009933"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc13') && (
                          <Line
                            yAxisId="I"
                            name="IDC13"
                            type="monotone"
                            dataKey="idc13"
                            stroke="#FF0055"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc14') && (
                          <Line
                            yAxisId="I"
                            name="IDC14"
                            type="monotone"
                            dataKey="idc14"
                            stroke="#e69900"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc15') && (
                          <Line
                            yAxisId="I"
                            name="IDC15"
                            type="monotone"
                            dataKey="idc15"
                            stroke="#0055FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc16') && (
                          <Line
                            yAxisId="I"
                            name="IDC16"
                            type="monotone"
                            dataKey="idc16"
                            stroke="#5500FF"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc17') && (
                          <Line
                            yAxisId="I"
                            name="IDC17"
                            type="monotone"
                            dataKey="idc17"
                            stroke="#007F7F"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc18') && (
                          <Line
                            yAxisId="I"
                            name="IDC18"
                            type="monotone"
                            dataKey="idc18"
                            stroke="#FF0055"
                            strokeWidth={1}
                          />
                        )}
                        {Object.keys(data[0]).includes('idc19') && (
                          <Line
                            yAxisId="I"
                            name="IDC19"
                            type="monotone"
                            dataKey="idc19"
                            stroke="#7F7F00"
                            strokeWidth={1}
                          />
                        )}
                      </>
                    )}
                  </>
                )}

                <Legend
                  wrapperStyle={{
                    overflowX: 'auto',
                    width: '100%',
                    whiteSpace: 'nowrap',
                  }}
                />
                <Tooltip
                  wrapperStyle={{ maxHeight: '100%' }}
                  labelFormatter={formatXAxis}
                  formatter={(value, name, props) => {
                    if (name.includes('TotalYield')) {
                      return [
                        <Typography key="tooltip-otemp" variant="body1" fontWeight="bold">
                          {`${name} : ${value.toLocaleString()} kWh`}
                        </Typography>,
                      ];
                    } else if (name.includes('RADITION')) {
                      return [
                        <Typography key="tooltip-otemp" variant="body1" fontWeight="bold">
                          {`${name} : ${value.toLocaleString()} W/㎡`}
                        </Typography>,
                      ];
                    } else if (name.includes('PAC')) {
                      return [
                        <Typography key="tooltip-otemp" variant="body1" fontWeight="bold">
                          {`${name} : ${value.toLocaleString()} kW`}
                        </Typography>,
                      ];
                    } else if (name.includes('PDC')) {
                      return [
                        <Typography key="tooltip-otemp" variant="body1" fontWeight="bold">
                          {`${name} : ${value.toLocaleString()} kW`}
                        </Typography>,
                      ];
                    } else if (name.includes('IAC')) {
                      return [
                        <Typography key="tooltip-otemp" variant="body1" fontWeight="bold">
                          {`${name} : ${value.toLocaleString()} A`}
                        </Typography>,
                      ];
                    } else if (name.includes('IDC')) {
                      return [
                        <Typography key="tooltip-otemp" variant="body1" fontWeight="bold">
                          {`${name} : ${value.toLocaleString()} A`}
                        </Typography>,
                      ];
                    } else if (name.includes('UAC')) {
                      return [
                        <Typography key="tooltip-otemp" variant="body1" fontWeight="bold">
                          {`${name} : ${value.toLocaleString()} V`}
                        </Typography>,
                      ];
                    } else if (name.includes('UDC')) {
                      return [
                        <Typography key="tooltip-otemp" variant="body1" fontWeight="bold">
                          {`${name} : ${value.toLocaleString()} V`}
                        </Typography>,
                      ];
                    }
                  }}
                />
              </>
            ) : null}
          </ComposedChart>
        </ResponsiveContainer>
      </Card>
    </>
  );
}
