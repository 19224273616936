import { Box, Button, Typography } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import DatasetLinkedIcon from '@mui/icons-material/DatasetLinked';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import useResponsive from '../../hooks/useResponsive';

// 사용자가 발전소 0개인 경우 신규 발전소 등록 혹은 연계 안내 모달
export default function PlantNumberGuidanceModal({ open, onClose }) {
  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'lg');

  const moveAddPlantClickHandler = () => {
    localStorage.setItem('menuValue', 9);
    localStorage.setItem('menuSubValue', 0);
    navigate(`${config.frontend.addPlants}`);
  };

  const moveConnectionPlantClickHandler = () => {
    localStorage.setItem('menuValue', 11);
    localStorage.setItem('menuSubValue', 0);
    navigate(`${config.frontend.connectionPlants}`);
  };

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <>
      <CustomModal
        open={open}
        onClose={closeModalHandler}
        sx={{ maxHeight: '95%', width: { xs: '95%', md: 600 } }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <DatasetLinkedIcon />
          <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.09rem' }}>
            신규 발전소 등록 및 기존 발전소 연계
          </Typography>
        </Box>
        <hr />
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <Box>고객님과 연결된 발전소가 존재하지 않습니다.</Box>
          <br />
          <Box>서비스를 계속 이용하시려면 신규 발전소를 등록 버튼을 누르시거나,</Box>
          <br />
          <Box>등록된 발전소가 있다면 등록된 발전소 연계 버튼을 누르세요.</Box>
        </Box>
        {isDesktop ? (
          <Box
            sx={{
              mt: 3,
              display: 'flex',
              justifyContent: 'space-between',
              px: '20% ',
            }}
          >
            <Button variant="contained" onClick={moveAddPlantClickHandler}>
              신규 발전소 등록
            </Button>
            <Button variant="outlined" onClick={moveConnectionPlantClickHandler}>
              기존 발전소 연계
            </Button>
          </Box>
        ) : (
          <>
            <Box
              sx={{
                mt: 3,
                display: 'flex',
                flexDirection: 'column', // 방향을 세로로 설정
                alignItems: 'center', // 가로축 중앙 정렬
                justifyContent: 'center', // 세로축 중앙 정렬
                '& > button': {
                  width: '60%', // 모바일 화면에 맞게 너비 조정
                  mb: 2, // 버튼 사이의 간격
                },
              }}
            >
              <Button variant="contained" onClick={moveAddPlantClickHandler} sx={{ width: '250px' }}>
                신규 발전소 등록
              </Button>
              <Button variant="outlined" onClick={moveConnectionPlantClickHandler} sx={{ width: '250px' }}>
                기존 발전소 연계
              </Button>
            </Box>
          </>
        )}
      </CustomModal>
    </>
  );
}
