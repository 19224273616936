import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';

export default function AlarmMenu({
  value,
  setValue,
  subValue,
  setSubValue,
  alarmClickHandler,
  setAlarmSelect,
  alarmSelect,
  webNavOpen,
  onClose,
}) {
  const navigate = useNavigate();

  const alarmHistoryClickHandler = () => {
    setValue(3);
    localStorage.setItem('menuValue', 3);
    setSubValue(0);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.alarms, { state: { subValue: 0 } });
    onClose();
  };
  const alarmCodeAnalysisClickHandler = () => {
    setValue(3);
    localStorage.setItem('menuValue', 3);
    setSubValue(1);
    localStorage.setItem('menuSubValue', 1);
    navigate(config.frontend.alarms, { state: { subValue: 1 } });
    onClose();
  };

  return (
    <List component="nav">
      <ListItemButton onClick={alarmClickHandler}>
        <ListItemIcon>
          <ReportGmailerrorredIcon />
        </ListItemIcon>
        {webNavOpen ? <ListItemText primary="경보" /> : null}
        {webNavOpen ? alarmSelect ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>
      <Collapse in={alarmSelect} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 3 && subValue === 0 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={alarmHistoryClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListAltOutlinedIcon sx={{ color: value === 3 && subValue === 0 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 3 && subValue === 0 ? '#3A3DFF' : null,
                  fontWeight: value === 3 && subValue === 0 ? 'bold' : null,
                }}
              >
                이력
              </Typography>
            ) : null}
          </ListItemButton>
        </List>
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 3 && subValue === 1 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={alarmCodeAnalysisClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <HistoryEduOutlinedIcon sx={{ color: value === 3 && subValue === 1 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 3 && subValue === 1 ? '#3A3DFF' : null,
                  fontWeight: value === 3 && subValue === 1 ? 'bold' : null,
                }}
              >
                발생 빈도
              </Typography>
            ) : null}
          </ListItemButton>
        </List>
      </Collapse>
    </List>
  );
}
