import useResponsive from '../../../hooks/useResponsive';
import { useEffect, useState } from 'react';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import StringWeb from './String/StringWeb';
import StringMobile from './String/StringMobile';
import useInterval from '../../../hooks/use-interval';

// 스트링 감시 컴포넌트
export default function ConnectBoardString({ plantkey }) {
  const isDesktop = useResponsive('up', 'lg');

  const [stringsList, setStringsList] = useState();
  const [eachStringsData, setEachStringsData] = useState();

  const stringsRequest = useBackendV2();

  const fetchStrings = async () => {
    try {
      const { data } = await stringsRequest.sendRequest(
        `${config.backend.endPoint.strings.real}/${plantkey}`
      );
      setStringsList(data);

      if (eachStringsData) {
        const changedChannels = data.find((item) => item.stringname === eachStringsData.stringname)?.channels;

        if (changedChannels) {
          setEachStringsData((prevData) => ({
            ...prevData,
            channels: changedChannels,
          }));
        }
      } else {
        setEachStringsData(data[0]);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchPollingInterval = 60000;

  useInterval(() => {
    fetchStrings();
  }, fetchPollingInterval);

  useEffect(() => {
    if (plantkey !== '') {
      fetchStrings();
    }
  }, [plantkey]);

  return (
    <>
      {isDesktop ? (
        <StringWeb
          stringsList={stringsList}
          eachStringsData={eachStringsData}
          setEachStringsData={setEachStringsData}
        />
      ) : stringsList ? (
        stringsList.map((column, index) => <StringMobile key={index} data={column} />)
      ) : null}
    </>
  );
}
