import { Grid } from '@mui/material';
import InverterMap from './SystematicMap/InverterMap';
import AcbVcbMap from './SystematicMap/AcbVcbMap';
import logo2 from '../../assets/SystematicMapImg/logo2.png';
import lbs1 from '../../assets/SystematicMapImg/LBS01.svg';
import mof1 from '../../assets/SystematicMapImg/MOF01.svg';

// 인버터 계통도 구성 컴포넌트
export default function SystematicMap({ plantState, subscribeday, graceperiodDay }) {
  return (
    <>
      <Grid container spacing={3} sx={{ filter: subscribeday - graceperiodDay > 0 ? 'blur(5px)' : '' }}>
        <Grid item xs={12} sm={12} md={12}>
          <Grid item lg={3}>
            <img src={logo2} alt="logo2" />
          </Grid>
          <Grid item lg={3} sx={{ mt: 1 }}>
            <img src={lbs1} alt="lbs" />
          </Grid>
          <Grid item lg={3} sx={{ mt: 1 }}>
            <img src={mof1} alt="mof" />
          </Grid>
          <AcbVcbMap plantState={plantState} />
          <InverterMap plantState={plantState} />
        </Grid>
      </Grid>
    </>
  );
}
