import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import '../../../css/App.css';
import { useEffect, useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

export default function YearAllStatisticsTable({ data, isDesktop }) {
  const columns = [
    { field: 'groupname', headerName: '발전소명', width: 150 },
    { field: 'flocaldate', headerName: '연(year)', width: 100 },
    { field: 'totalyield', headerName: '발전량(kWh)', width: 200 },
    { field: 'generateHour', headerName: '발전시간(연평균)(h)', width: 100 },
  ];

  const mobileColumns = [
    { field: 'flocaldate', headerName: '연(year)', width: 100 },
    { field: 'totalyield', headerName: '발전량(kWh)', width: 200 },
    { field: 'generateHour', headerName: '발전시간(연평균)(h)', width: 100 },
  ];

  const [total, setTotal] = useState({
    totalyield: 0,
    generateYear: 0,
  });

  const calculateTotal = () => {
    let totalyieldTotal = 0;
    let generateYearTotal = 0;
    let validGenerateYearCount = 0;

    data.forEach((row) => {
      totalyieldTotal += row.totalyield ?? 0;
      if (row.totalyield !== null) {
        generateYearTotal += row.generateHour ?? 0;
        validGenerateYearCount += 1;
      }
    });

    setTotal({
      totalyield: totalyieldTotal,
      generateYear: validGenerateYearCount > 0 ? generateYearTotal / validGenerateYearCount : 0,
    });
  };

  useEffect(() => {
    calculateTotal();
  }, [data]);

  return (
    <Card
      sx={{
        mt: 1,
        borderRadius: 0,
        boxShadow: '0px 3px 12px #00000014',
      }}
    >
      <StyledTableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {isDesktop
                ? columns.map((column) => (
                    <StyledTableCell
                      key={column.field}
                      align="center"
                      style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                      className={column.headerName === '발전소명' ? 'sticky' : ''}
                    >
                      {column.headerName}
                    </StyledTableCell>
                  ))
                : mobileColumns.map((column) => (
                    <StyledTableCell
                      key={column.field}
                      align="center"
                      style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                      className={column.headerName === '발전소명' ? 'sticky' : ''}
                    >
                      {column.headerName}
                    </StyledTableCell>
                  ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.id} index={index}>
                {isDesktop
                  ? columns.map((column) => (
                      <TableCell
                        key={column.field}
                        align="center"
                        style={{
                          minWidth: column.width,
                          backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                          borderRight: '1px solid #d0d0d0',
                        }}
                        className={column.headerName === '발전소명' ? 'sticky' : ''}
                      >
                        {column.field === 'flocaldate'
                          ? row.flocaldate
                          : column.field === 'totalyield' && row[column.field]
                          ? row[column.field].toLocaleString()
                          : row[column.field]}
                      </TableCell>
                    ))
                  : mobileColumns.map((column) => (
                      <TableCell
                        key={column.field}
                        align="center"
                        style={{
                          minWidth: column.width,
                          backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                          borderRight: '1px solid #d0d0d0',
                        }}
                      >
                        {column.field === 'flocaldate'
                          ? row.flocaldate
                          : column.field === 'totalyield' && row[column.field]
                          ? row[column.field].toLocaleString()
                          : row[column.field]}
                      </TableCell>
                    ))}
              </TableRow>
            ))}
            <TableRow>
              {isDesktop
                ? columns.map((column, columnIndex) => (
                    <TableCell
                      key={column.field}
                      align="center"
                      style={{
                        minWidth: column.width,
                        borderRight: '1px solid #d0d0d0',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        backgroundColor: '#1769aa',
                        color: 'white',
                      }}
                      className={columnIndex === 0 ? 'sticky' : ''}
                    >
                      {columnIndex === 0
                        ? '합계'
                        : column.field === 'totalyield'
                        ? total.totalyield.toLocaleString()
                        : column.field === 'generateHour'
                        ? total.generateYear.toFixed(2)
                        : ''}
                    </TableCell>
                  ))
                : mobileColumns.map((column, columnIndex) => (
                    <TableCell
                      key={column.field}
                      align="center"
                      style={{
                        minWidth: column.width,
                        borderRight: '1px solid #d0d0d0',
                        fontSize: '16px',
                        fontWeight: 'bold',
                        backgroundColor: '#1769aa',
                        color: 'white',
                      }}
                    >
                      {columnIndex === 0
                        ? '합계'
                        : column.field === 'totalyield'
                        ? total.totalyield.toLocaleString()
                        : column.field === 'generateHour'
                        ? total.generateYear.toFixed(2)
                        : ''}
                    </TableCell>
                  ))}
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Card>
  );
}
