import { Box, Button, ButtonGroup, CardContent, Divider, Grid, Typography } from '@mui/material';
import MainCard from '../../../theme/MainCard';
import { convertFormatStringToYMD } from '../../../utils/dateUtil';
import { useEffect, useState } from 'react';
import { fShortenNumber } from '../../../utils/formatNumber';

// 개별 대시보드 SMP 상태 컴포넌트
export default function TodaySmpTable({ todaySmp, address }) {
  const [selectPlace, setSelectPlace] = useState(false);

  useEffect(() => {
    if (address?.substring(0, 5).includes('제주')) {
      setSelectPlace(true);
    } else {
      setSelectPlace(false);
    }
  }, []);

  return (
    <>
      <MainCard content={false} sx={{ mt: 1 }}>
        <CardContent>
          <Typography color="primary" fontWeight="bold" fontSize="0.89rem" sx={{ mb: 1, mb: 1 }}>
            오늘의 SMP
          </Typography>
          <Box display="flex" justifyContent="space-between" sx={{ mt: 0 }}>
            <Box>
              <Typography variant="subtitle2" color="inherit">
                거래일:{' '}
                {todaySmp.length === 0
                  ? null
                  : selectPlace
                  ? convertFormatStringToYMD(todaySmp[1].regdate)
                  : convertFormatStringToYMD(todaySmp[0].regdate)}
              </Typography>
            </Box>
            <ButtonGroup>
              <Button
                onClick={() => setSelectPlace(false)}
                variant={selectPlace ? 'outlined' : 'contained'}
                size="small"
              >
                육지
              </Button>
              <Button
                onClick={() => setSelectPlace(true)}
                variant={selectPlace ? 'contained' : 'outlined'}
                size="small"
              >
                제주
              </Button>
            </ButtonGroup>
          </Box>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
              <Grid container direction="column">
                <Grid item>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="subtitle1" color="inherit">
                        가중평균
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                          <Typography variant="subtitle1" color="inherit">
                            {todaySmp.length === 0
                              ? null
                              : selectPlace
                              ? fShortenNumber(todaySmp[1].avgval)
                              : fShortenNumber(todaySmp[0].avgval)}
                            원
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
              <Grid container direction="column">
                <Grid item>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="subtitle1" color="inherit">
                        최고가
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                          <Typography variant="subtitle1" color="inherit">
                            {todaySmp.length === 0
                              ? null
                              : selectPlace
                              ? fShortenNumber(todaySmp[1].maxval)
                              : fShortenNumber(todaySmp[0].maxval)}
                            원
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
              <Grid container direction="column">
                <Grid item>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="subtitle1" color="inherit">
                        최저가
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                          <Typography variant="subtitle1" color="inherit">
                            {todaySmp.length === 0
                              ? null
                              : selectPlace
                              ? fShortenNumber(todaySmp[1].minval)
                              : fShortenNumber(todaySmp[0].minval)}
                            원
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
            </Grid>
          </Grid>
        </CardContent>
      </MainCard>
    </>
  );
}
