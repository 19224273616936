import {
  Box,
  Button,
  CardMedia,
  Divider,
  FormControl,
  FormLabel,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useRef, useState } from 'react';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import useInput from '../../../hooks/use-input';
import DaumPostcodeEmbed from 'react-daum-postcode';
import { validateName } from '../../../utils/validationUtil';
import qubePannel from '../../../assets/EtcImg/qubePannel.png';
import AuthContext from '../../../store/auth-context';
import SettingSubPlant from './SettingSubPlant';
import AddNewSubPlant from './AddNewSubPlant';
import UpdateSubPlantInfo from './UpdateSubPlantInfo';
import { convertFormatStringToYMD } from '../../../utils/dateUtil';

export default function SettingPlant({ plantkey }) {
  const authCtx = useContext(AuthContext);

  const imageInput = useRef();

  const [plantInfo, setPlantInfo] = useState([]);
  const [subPlantList, setSubPlantList] = useState([]);
  const [selectSubPlantInfo, setSelectSubPlantInfo] = useState([]);
  const [plantRtu, setPlantRtu] = useState([]);

  const [modifyState, setModifyState] = useState(false);
  const [plantImage, setPlantImage] = useState({ image_file: '' });
  const [usePlantImgResult, setUsePlantImgResult] = useState(true);

  const [newSubPlantAdd, setNewSubPlantAdd] = useState(false);
  const [updateSubPlant, setUpdateSubPlant] = useState(false);
  const [updateSubPlantKey, setUpdateSubPlantKey] = useState();

  const plantNameInput = useInput((value) => value !== '');
  const plantOwnerInput = useInput((value) => validateName(value));
  const addressInput = useInput();
  const address2Input = useInput();
  const zipcodeInput = useInput();
  const capacityInput = useInput((value) => value !== '');

  const formIsValid =
    plantNameInput.isValid && plantOwnerInput.isValid && capacityInput.isValid && addressInput.isValid;

  const backendRequest = useBackendV2();

  const fetchPlantInfo = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.baseInfo}/${plantkey}`
      );
      setPlantInfo(data[0]);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchSubPlantList = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.findSubPlantList}/${plantkey}`
      );

      setSubPlantList(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchRtuToPlant = async () => {
    if (plantkey !== '') {
      try {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.plants.subscribe}/${plantkey}`
        );

        setPlantRtu(data);
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    }
  };

  const fetchSubPlantInfo = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.findSubPlantInfo}/${plantkey}/${updateSubPlantKey}`
      );

      setSelectSubPlantInfo(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const modifyStateClickHandler = (plantname, plantowner, address, address2, zipcode, capacity) => {
    plantNameInput.setValue(plantname);
    plantOwnerInput.setValue(plantowner);
    addressInput.setValue(address);
    address2Input.setValue(address2);
    zipcodeInput.setValue(zipcode);
    capacityInput.setValue(capacity);

    setModifyState(true);
    setNewSubPlantAdd(false);
  };

  const updateSubplantClickHandler = (subplantkey) => {
    setUpdateSubPlant(true);
    setUpdateSubPlantKey(subplantkey);
  };

  const saveStateClickHandler = async () => {
    let imgkey;

    if (plantImage.image_file !== '') {
      const formData = new FormData();
      formData.append('formFile', plantImage.image_file);
      formData.append('Filekey', plantInfo.imgkey === null ? 0 : plantInfo.imgkey);
      formData.append('FileType', 1);
      formData.append('Category', 'plant|plantpic');

      try {
        const fileRequest = {
          method: 'POST',
          mode: 'cors',
          headers: {
            Authorization: `Bearer ${authCtx.token.accessToken.token}`,
            Accept: 'multipart/form-data',
          },
          body: formData,
        };

        const fileResponse = await fetch(
          `${config.backend.host}${config.backend.endPoint.files.imageFile}`,
          fileRequest
        );

        const data = await fileResponse.json();

        if (data.filekey === -1) {
          setUsePlantImgResult(false);
          imgkey = -1;
        } else {
          imgkey = data.filekey;
        }
      } catch (error) {
        console.error(error);
      }
    }
    if (usePlantImgResult === true) {
      try {
        const requestConfig = {
          method: 'PUT',
          body: {
            Plantkey: plantkey,
            Plantname: plantNameInput.value,
            Owner: plantOwnerInput.value === null ? '' : plantOwnerInput.value,
            Capacity: capacityInput.value,
            Address: addressInput.value,
            Address2: address2Input.value === null ? '' : address2Input.value,
            zipcode: zipcodeInput.value,
            imgkey: imgkey === -1 ? null : imgkey,
          },
        };
        const result = await backendRequest.sendRequest(
          config.backend.endPoint.plants.updatePlant,
          requestConfig
        );

        if (result.data !== (null || 0)) {
          alert('발전소 정보 수정이 완료되었습니다.');
          setModifyState(false);
          setPlantImage({
            image_file: '',
          });
        } else {
          alert('오류가 발생하였습니다.');
        }
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    }
  };
  const cancelSaveStateClickHandler = () => {
    setModifyState(false);
    setPlantImage({
      image_file: '',
    });
  };

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';
    let zonecode = data.zonecode;

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    addressInput.setValue(fullAddress);
    zipcodeInput.setValue(zonecode);
  };

  const updatePlantImage = () => {
    imageInput.current.click();
  };

  const handleChangeFile = (e) => {
    e.preventDefault();

    setPlantImage({
      image_file: e.target.files[0],
    });
  };

  useEffect(() => {
    if (updateSubPlant === true) {
      fetchSubPlantInfo();
    }

    fetchPlantInfo();
    fetchSubPlantList();
    fetchRtuToPlant();
  }, [modifyState, newSubPlantAdd, updateSubPlant]);

  let subPlantListCard;

  if (subPlantList.length !== 0) {
    subPlantListCard = subPlantList.map((d, index) => (
      <SettingSubPlant key={index} data={d} updateSubplantClickHandler={updateSubplantClickHandler} />
    ));
  }

  return (
    <>
      <Box>
        {modifyState === false ? (
          <>
            <Box display="flex" justifyContent="end">
              <Button
                variant="outlined"
                onClick={() =>
                  modifyStateClickHandler(
                    plantInfo.plantname,
                    plantInfo.plantowner,
                    plantInfo.address,
                    plantInfo.address2,
                    plantInfo.zipcode,
                    plantInfo.capacity
                  )
                }
              >
                수정
              </Button>
            </Box>
            <Typography variant="h6" sx={{ mt: 0 }}>
              {plantInfo.plantname}
            </Typography>
            <Divider sx={{ mt: 1 }} />
            {plantRtu.map((rtu, index) => (
              <Box key={index} sx={{ mt: 0.5 }}>
                <Typography variant="subtitle2">RTU번호 : {rtu.rtun}</Typography>
                <Typography variant="subtitle2">
                  서비스 만료일 : {convertFormatStringToYMD(rtu.enddate)}
                </Typography>
                <Divider sx={{ mt: 0.5 }} />
              </Box>
            ))}
            <Box sx={{ mt: 1 }}>
              <Typography variant="subtitle1" color="text.secondary">
                소유주 : {plantInfo.plantowner === '' ? '' : plantInfo.plantowner}
              </Typography>
              <Typography variant="subtitle1" color="text.secondary">
                설비 용량 : {plantInfo.capacity === '' ? 0 : plantInfo.capacity} kW
              </Typography>
              <FormControl component="fieldset" variant="standard" sx={{ mt: 2 }}>
                <FormLabel component="legend" sx={{ mb: 0.5 }}>
                  <Typography variant="subtitle1" color="text.secondary">
                    발전소 이미지
                  </Typography>
                </FormLabel>
                {plantInfo.imgkey !== null && plantInfo.imgkey !== '' && plantInfo.imgkey !== undefined ? (
                  <>
                    <CardMedia
                      component="img"
                      image={`https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=${process.env.REACT_APP_DOMAIN}&id=${plantInfo.imgkey}`}
                      alt="plantImg"
                      sx={{ width: '100%', height: '100%' }}
                    />
                  </>
                ) : (
                  <>
                    <CardMedia
                      component="img"
                      image={qubePannel}
                      alt="defaultImg"
                      sx={{ width: '100%', height: '100%' }}
                    />
                  </>
                )}
              </FormControl>
              <Typography variant="subtitle1" color="text.secondary" sx={{ mt: 1 }}>
                주소 : {plantInfo.address === '' ? '등록된 주소가 없습니다.' : plantInfo.address}
              </Typography>
              {plantInfo.address2 !== (null || '') ? (
                <>
                  <Typography variant="subtitle1" color="text.secondary">
                    상세 주소 : {plantInfo.address2}
                  </Typography>
                </>
              ) : null}
              <Typography variant="subtitle1" color="text.secondary">
                우편번호 : {plantInfo.zipcode === '' ? '등록된 주소가 없습니다.' : plantInfo.zipcode}
              </Typography>
            </Box>
            <Divider sx={{ mt: 1.5 }} />
            <Box sx={{ mt: 2 }}>
              {newSubPlantAdd ? (
                <>
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
                    <Typography variant="subtitle1">그룹 발전소 추가</Typography>
                    <Button variant="outlined" size="small" onClick={() => setNewSubPlantAdd(false)}>
                      리스트
                    </Button>
                  </Box>
                  <AddNewSubPlant
                    plantkey={plantkey}
                    setNewSubPlantAdd={setNewSubPlantAdd}
                    newSubPlantAdd={newSubPlantAdd}
                  />
                </>
              ) : updateSubPlant && updateSubPlantKey !== undefined ? (
                <>
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
                    <Typography variant="subtitle1">그룹 발전소 수정</Typography>
                    <Button
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        setUpdateSubPlant(false);
                        setUpdateSubPlantKey();
                      }}
                    >
                      리스트
                    </Button>
                  </Box>
                  <UpdateSubPlantInfo
                    plantkey={plantkey}
                    selectSubPlantInfo={selectSubPlantInfo}
                    setUpdateSubPlant={setUpdateSubPlant}
                    setUpdateSubPlantKey={setUpdateSubPlantKey}
                  />
                </>
              ) : (
                <>
                  <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
                    <Typography variant="subtitle1">그룹 발전소 목록</Typography>
                    <Button variant="outlined" size="small" onClick={() => setNewSubPlantAdd(true)}>
                      그룹 발전소 추가
                    </Button>
                  </Box>
                  {subPlantList.length !== 0 ? subPlantListCard : null}
                </>
              )}
            </Box>
          </>
        ) : (
          <>
            <Box>
              <TextField
                label="발전소 명"
                variant="standard"
                required
                sx={{ width: '100%' }}
                value={plantNameInput.value}
                onChange={plantNameInput.valueChangeHandler}
                onBlur={plantNameInput.inputBlurHandler}
                error={plantNameInput.hasError}
                helperText={plantNameInput.hasError && '최소 1자 입력'}
              />
              <TextField
                label="소유주"
                variant="standard"
                sx={{ width: '100%', mt: 2 }}
                value={plantOwnerInput.value}
                onChange={plantOwnerInput.valueChangeHandler}
                onBlur={plantOwnerInput.inputBlurHandler}
                error={plantOwnerInput.hasError}
                helperText={plantOwnerInput.hasError && '최소 2자. 최대 20자. 한글, 영문만 가능'}
              />
              <Box sx={{ mt: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 1 }}>
                  <Typography sx={{ fontSize: '0.85rem' }}>발전소 이미지</Typography>
                  <Button variant="outlined" size="small" onClick={updatePlantImage}>
                    이미지 수정
                  </Button>
                </Box>
                <FormControl component="fieldset" variant="standard">
                  {plantImage.image_file !== '' ? (
                    <>
                      <CardMedia component="img" image={URL.createObjectURL(plantImage.image_file)} />
                    </>
                  ) : (
                    <>
                      {plantInfo.imgkey !== null &&
                      plantInfo.imgkey !== '' &&
                      plantInfo.imgkey !== undefined ? (
                        <>
                          <CardMedia
                            component="img"
                            image={`https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=${process.env.REACT_APP_DOMAIN}&id=${plantInfo.imgkey}`}
                            alt="plantImg"
                          />
                        </>
                      ) : (
                        <>
                          <CardMedia
                            component="img"
                            image={qubePannel}
                            alt="defaultImg"
                            sx={{ width: '100%', height: '100%' }}
                          />
                        </>
                      )}
                    </>
                  )}
                  <input
                    type="file"
                    style={{ display: 'none' }}
                    accept="image/*"
                    ref={imageInput}
                    onChange={handleChangeFile}
                  />
                </FormControl>
              </Box>
              <TextField
                label="주소"
                variant="standard"
                required
                sx={{ width: '100%', mt: 2 }}
                value={addressInput.value}
                onChange={addressInput.valueChangeHandler}
                disabled
              />
              <DaumPostcodeEmbed onComplete={handleComplete} />
              <TextField
                label="상세 주소"
                variant="standard"
                sx={{ width: '100%', mt: 2 }}
                value={address2Input.value}
                onChange={address2Input.valueChangeHandler}
              />
              <TextField
                label="우편번호"
                variant="standard"
                disabled
                sx={{ width: '100%', mt: 2 }}
                value={zipcodeInput.value}
                onChange={zipcodeInput.valueChangeHandler}
              />
              <TextField
                label="설비 용량"
                variant="standard"
                InputProps={{
                  endAdornment: <InputAdornment position="end">kW</InputAdornment>,
                }}
                required
                sx={{ width: '100%', mt: 2 }}
                value={capacityInput.value}
                onChange={capacityInput.valueChangeHandler}
                onBlur={capacityInput.inputBlurHandler}
                error={capacityInput.hasError}
                helperText={capacityInput.hasError && '최소 1자 입력'}
              />
            </Box>
            <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
              <Button
                variant="contained"
                disabled={!formIsValid}
                onClick={saveStateClickHandler}
                sx={{ width: '100%' }}
              >
                저장
              </Button>
            </Box>
            <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
              <Button variant="outlined" onClick={cancelSaveStateClickHandler} sx={{ width: '100%' }}>
                취소
              </Button>
            </Box>
          </>
        )}
      </Box>
    </>
  );
}
