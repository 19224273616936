import { Avatar, Grid } from '@mui/material';
import { useState } from 'react';
import vcb from '../../../assets/SystematicMapImg/vcb.gif';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import VcbMapCard from './VcbMapCard';
import vcb01 from '../../../assets/SystematicMapImg/VCB01.svg';

// 인버터 계통도 내 VCB 상태 표시 컴포넌트
export default function VcbMap({ isDesktop, vcbListInfo }) {
  // const [expanded, setExpanded] = useState(isDesktop === true ? true : false);
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  let VcbCard;

  if (vcbListInfo.length !== 0 && expanded === true) {
    VcbCard = vcbListInfo.map((d, index) => <VcbMapCard key={index} vcbListInfo={d} isDesktop={isDesktop} />);
  }

  return (
    <>
      <Grid item lg={3} sx={{ display: 'flex', mt: 1 }}>
        <img src={vcb01} alt="vcb" />
        {/* <Avatar
          onClick={handleExpandClick}
          sx={{
            alignItems: 'center',
            mt: 10,
            ml: 1,
            width: 24,
            height: 24,
            cursor: isDesktop ? 'pointer' : 'cursor',
          }}
        >
          {expanded === false ? <ExpandMoreIcon /> : <ExpandLessIcon />}
        </Avatar> */}
      </Grid>
      {expanded === false ? null : VcbCard}
    </>
  );
}
