import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  CardMedia,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import config from '../../config';
import { useContext, useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import useResponsive from '../../hooks/useResponsive';
import { fNumberWithCommas } from '../../utils/formatNumber';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import AddBoxOutlinedIcon from '@mui/icons-material/AddBoxOutlined';
import IndeterminateCheckBoxOutlinedIcon from '@mui/icons-material/IndeterminateCheckBoxOutlined';
import ProductCartWidget from './ProductCartWidget';
import { useCookies } from 'react-cookie';
import AuthContext from '../../store/auth-context';
import PaymentPage from '../../pages/Payment/PaymentPage';
import ShopProductCardDetailHavePlantkey from './ShopProductCardDetailHavePlantkey';
import KakaoFindAddressModal from './KakaoFindAddressModal';
import MainCard from '../../theme/MainCard';
import ShopProductCardDetailMoreInfo from './ShopProductCardDetailMoreInfo';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import ProductionQuantityLimitsOutlinedIcon from '@mui/icons-material/ProductionQuantityLimitsOutlined';
import Header02 from '../Layout/Header02';
import SubHeader from '../Layout/SubHeader';
import Nav from '../Layout/Nav';
import DepositorPaymentPage from '../../pages/Payment/DepositorPaymentPage';
import ShopProductCardDetailExplain from './ShopProductCardDetailExplain';
import PaymentRtunStatusModal from './PaymentRtunStatusModal';
import usePageLogRecord from '../../hooks/usePageLogRecord';

// 상품 상세보기 컴포넌트
export default function ShopProductCardDetail() {
  const { id } = useParams(); // pdtid
  const location = useLocation();

  const plantkey = location.state?.plantkey;
  const plantName = location.state?.plantName;

  const navigate = useNavigate();
  const isDesktop = useResponsive('up', 'lg');
  const authCtx = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));

  const distance = 0;

  const [cartProducts, setCartProducts] = useState([]);

  const [product, setProduct] = useState();
  const [expiredRtu, setExpiredRtu] = useState([]);
  const [saveRtun, setSaveRtun] = useState([]);
  const [productDetailList, setProductDetailList] = useState([]);
  const [orderStatusRTUN, setOrderStatusRTUN] = useState([]);

  const [count, setCount] = useState(1);
  const [productId, setProductId] = useState();

  const [putInShoppingCart, setPutInShoppingCart] = useState(false);
  const [depositorValue, setDepositorValue] = useState(false);

  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [zip, setZip] = useState('');

  const [checkPaymentStatusModal, setCheckPaymentStatusModal] = useState(false);
  const [kakaoFindAddressModal, setKakaoFindAddressModal] = useState(false);

  const [openDepositorStatusModal, setOpenDepositorStatusModal] = useState(false);
  const [openPaymentRtunStatusGuideModal, setOpenPaymentRtunStatusGuideModal] = useState(false);

  const [confirmPayButton, setConfirmPayButton] = useState(false);

  const [productDetailExplain, setProductDetailExplain] = useState(false);
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const [cookies, setCookie] = useCookies(['orderid']);

  const backendRequest = useBackendV2();

  const fetchProductDetail = async () => {
    try {
      const { data } = await backendRequest.sendRequest(`${config.backend.endPoint.products.detail}/${id}`);
      setProduct(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchSubscriptionPeriod = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.subscribe}/${plantkey}`
      );

      const filteredData = data.filter((item) => item.subscribeday > -90);

      setExpiredRtu(filteredData);
      setCount(filteredData.length);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchProductDetailComplain = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.products.detailList}/${id}`
      );
      setProductDetailList(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchOrderStatusByRTUN = () => {
    try {
      expiredRtu.forEach(async (rtu) => {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.carts.orderStatusByRTUN}/${rtu.rtun}`
        );

        // 데이터가 배열이 아닌 경우 배열로 변환
        const responseData = Array.isArray(data) ? data : [data];
        // 상태에 있는 데이터에서 중복된 rtun이 있는지 확인
        setOrderStatusRTUN((prevStatus) => {
          // 기존 데이터의 rtun 값을 Set으로 추출
          const existingRtuns = new Set(prevStatus.map((item) => item.rtun));
          // 중복되지 않는 데이터만 필터링
          const newData = responseData.filter((item) => !existingRtuns.has(item.rtun));
          // 기존 상태와 새 데이터를 병합
          return [...prevStatus, ...newData];
        });
      });
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const removeCountClickHandler = () => {
    if (count === 0) {
      alert('개수는 0개 미만으로 내려갈 수 없습니다.');
    } else {
      setCount(count - 1);
    }
  };
  const addCountClickHandler = () => {
    setCount(count + 1);
  };

  const putInShoppingCartClickHandler = async () => {
    if (count > 0) {
      try {
        const putInShoppingCartRequestConfig = {
          method: 'POST',
          body: [
            {
              userid: authCtx?.currentUser?.id,
              pdtid:
                id === '1' && expiredRtu.length !== 0 && plantName !== undefined
                  ? productId
                  : product.pdtid.toString(),
              cnt: count,
              price: product.price * count,
              RTUNs: saveRtun.length === 0 ? null : saveRtun,
              orderid: cookies.orderid || '',
            },
          ],
        };
        const putInShoppingCartResult = await backendRequest.sendRequest(
          config.backend.endPoint.carts.basic,
          putInShoppingCartRequestConfig
        );
        if (putInShoppingCartResult.data !== null) {
          setCookie('orderid', putInShoppingCartResult.data, { maxAge: 24 * 60 * 60 });
          alert('장바구니에 담았습니다.');
          navigate(config.frontend.products);
        } else {
          alert('오류가 발생하였습니다.');
        }
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    } else {
      alert('0개 이하는 장바구니 담기가 불가능 합니다.');
    }
  };

  const paymentDepostiorClickHandler = async () => {
    if (count > 0) {
      try {
        const removeAllRequestConfig = {
          method: 'DELETE',
        };

        const result = await backendRequest.sendRequest(
          `${config.backend.endPoint.carts.allDel}/${cookies.orderid}`,
          removeAllRequestConfig
        );

        if (result.data === true) {
          const putInShoppingCartRequestConfig = {
            method: 'POST',
            body: [
              {
                userid: authCtx?.currentUser?.id,
                pdtid: product.pdtid.toString(),
                cnt: count,
                price: product.price * count,
                RTUNs: saveRtun.length === 0 ? null : saveRtun,
                orderid: cookies.orderid || '',
              },
            ],
          };

          const putInShoppingCartResult = await backendRequest.sendRequest(
            config.backend.endPoint.carts.basic,
            putInShoppingCartRequestConfig
          );

          if (putInShoppingCartResult.data !== null) {
            setCookie('orderid', putInShoppingCartResult.data, { maxAge: 24 * 60 * 60 });
            if (product.pdttype === 1 || product.pdttype === 3 || product.pdttype === 4) {
              setKakaoFindAddressModal(true);
            } else {
              setOpenDepositorStatusModal(true);
            }
          } else {
            alert('오류가 발생하였습니다.');
          }
        } else {
          alert('오류가 발생하였습니다.');
        }
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    } else {
      alert('상품 수량을 선택해주세요.');
    }
  };

  const buyProductClickHandler = async () => {
    if (count > 0) {
      try {
        const removeAllRequestConfig = {
          method: 'DELETE',
        };

        const result = await backendRequest.sendRequest(
          `${config.backend.endPoint.carts.allDel}/${cookies.orderid}`,
          removeAllRequestConfig
        );

        if (result.data === true) {
          const putInShoppingCartRequestConfig = {
            method: 'POST',
            body: [
              {
                userid: authCtx?.currentUser?.id,
                pdtid: product.pdtid.toString(),
                cnt: count,
                price: product.price * count,
                RTUNs: saveRtun.length === 0 ? null : saveRtun,
                orderid: cookies.orderid || '',
              },
            ],
          };

          const putInShoppingCartResult = await backendRequest.sendRequest(
            config.backend.endPoint.carts.basic,
            putInShoppingCartRequestConfig
          );

          if (putInShoppingCartResult.data !== null) {
            setCookie('orderid', putInShoppingCartResult.data, { maxAge: 24 * 60 * 60 });
            if (product.pdttype === 1 || product.pdttype === 3 || product.pdttype === 4) {
              setKakaoFindAddressModal(true);
            } else {
              setCheckPaymentStatusModal(true);
            }
          } else {
            alert('오류가 발생하였습니다.');
          }
        } else {
          alert('오류가 발생하였습니다.');
        }
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    } else {
      alert('상품 수량을 선택해주세요.');
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const closePaymentPageClickHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setCheckPaymentStatusModal(false);
  };

  const closeKakaoFindAddressModalClickHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setKakaoFindAddressModal(false);
  };

  const closePaymentDepositorClickHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setDepositorValue(false);
    setOpenDepositorStatusModal(false);
  };

  const closePaymentRtunStatusGuideModalClickHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setOpenPaymentRtunStatusGuideModal(false);
  };

  const moveProductListClickHandler = () => {
    navigate(config.frontend.products);
  };

  useEffect(() => {
    if (plantkey !== undefined) {
      fetchSubscriptionPeriod();
    }
    fetchProductDetail();
    fetchProductDetailComplain();
  }, [id]);

  useEffect(() => {
    if (expiredRtu.length !== 0) {
      const rtunArray = expiredRtu.map((item) => item.rtun);

      setSaveRtun(rtunArray);

      fetchOrderStatusByRTUN();
    }
  }, [expiredRtu]);

  useEffect(() => {
    if (product !== undefined) {
      setProductId(product.pdtid);
    }
  }, [product]);

  useEffect(() => {
    if (productId !== undefined) {
      const newId = productId;

      navigate(`${config.frontend.productdetails}/${newId}`, {
        state: { plantkey: plantkey, plantName: plantName },
      });
    }
  }, [productId]);

  useEffect(() => {
    if (orderStatusRTUN.length !== 0) {
      const hasValidStatus = orderStatusRTUN.some(
        (order) => order.status === 'WAITING' || order.status === 'WAITING_QUBE'
      );

      if (hasValidStatus === true) {
        setConfirmPayButton(false);
      } else {
        setConfirmPayButton(true);
      }

      setOpenPaymentRtunStatusGuideModal(hasValidStatus);
    } else {
      setOpenPaymentRtunStatusGuideModal(false);
      setConfirmPayButton(true);
    }
  }, [orderStatusRTUN]);

  useEffect(() => {
    fetchUserStylePreference();
  }, []);

  usePageLogRecord('productDetails');

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      {product !== undefined ? (
        <>
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
              },
            }}
          >
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <StyledBreadcrumb
                label="상품&서비스"
                icon={<StoreMallDirectoryOutlinedIcon fontSize="small" color="primary" />}
              />
              <StyledBreadcrumb label="상품&서비스 설명" />
            </Breadcrumbs>
          </Box>
          <MainCard sx={{ mt: 2.5 }}>
            <Button
              variant="text"
              size="large"
              onClick={moveProductListClickHandler}
              startIcon={<KeyboardBackspaceIcon />}
              sx={{ color: '#000' }}
            >
              상품&서비스 목록 이동
            </Button>
            <Grid item xs={24} sx={{ mt: 2, p: 2 }}>
              <Grid item xs={12}>
                <Grid container spacing={isDesktop ? 11 : 6}>
                  <Grid item xs={12} lg={3} md={6} sm={6} sx={{ mt: 1 }}>
                    <CardMedia
                      component="img"
                      image={`https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=SC&id=${product.filekey}`}
                    />
                  </Grid>
                  <Grid item xs={12} lg={5.5} md={6} sm={6} sx={{ mt: 1 }}>
                    <Box display="flex">
                      <Typography variant="h4">{product.pdtname}</Typography>
                      {product.saleprice === 0 ? null : (
                        <Chip label="sale" color="error" size="small" sx={{ ml: 0.5 }} />
                      )}
                    </Box>
                    <Divider sx={{ mt: 1, mb: 1.5 }} />
                    <Box sx={{ mt: 1 }}>
                      {product.pdttype === 7 || product.price === 0 ? (
                        <>
                          <Typography variant="h5">별도견적 상품입니다.</Typography>
                        </>
                      ) : product.saleprice === 0 ? (
                        <Typography variant="h4">
                          {product.pdttype === 3 ? <span style={{ fontSize: '85%' }}>월 </span> : null}{' '}
                          {fNumberWithCommas(product.price)}원
                        </Typography>
                      ) : (
                        <Typography variant="h4">
                          {fNumberWithCommas(product.price)}원&nbsp;
                          <Typography
                            component="span"
                            variant="h6"
                            sx={{
                              color: 'text.disabled',
                              textDecoration: 'line-through',
                            }}
                          >
                            {fNumberWithCommas(product.price + product.saleprice)}원
                          </Typography>
                        </Typography>
                      )}
                    </Box>
                    <Box>
                      {product.pdttype === 1 || product.pdttype === 3 || product.pdttype === 4 ? (
                        product.price <= 50000 ? (
                          <Typography variant="subtitle2" sx={{ mt: 0.5, color: 'text.secondary' }}>
                            {product.delprice === 0
                              ? '배송 금액 : 무료'
                              : '배송 금액 : ' + fNumberWithCommas(product.delprice) + '원'}
                          </Typography>
                        ) : (
                          <Typography variant="subtitle2" sx={{ mt: 0.5, color: 'text.secondary' }}>
                            배송 금액 : 무료
                          </Typography>
                        )
                      ) : null}
                      <Typography variant="subtitle2" sx={{ mt: 0.5, color: 'text.secondary' }}>
                        부가세 별도
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle1" sx={{ color: '#707070' }}>
                        {product.summary}
                      </Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Typography variant="subtitle2">{product.pdtdesc}</Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      {/* {(id === '1' || id === '5' || id === '6') &&
                      expiredRtu.length !== 0 &&
                      plantName !== undefined ? (
                        <ShopProductCardDetailHavePlantkey
                          plantName={plantName}
                          expiredRtu={expiredRtu}
                          productId={productId}
                          setProductId={setProductId}
                        />
                      ) : null} */}
                      {product.optionkey !== 9 && (
                        <ShopProductCardDetailHavePlantkey
                          plantkey={plantkey}
                          plantName={plantName}
                          expiredRtu={expiredRtu}
                          productId={productId}
                          setProductId={setProductId}
                          product={product}
                          id={id}
                        />
                      )}
                      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2.5 }}>
                        {product.cnt === 0 || product.price === 0 || product.pdttype === 7 ? null : (
                          <>
                            <Typography variant="subtitle1" sx={{ mr: 4 }}>
                              수량
                            </Typography>
                            <Card sx={{ display: 'flex', alignItems: 'center', bgcolor: '#f6f6f6' }}>
                              <IconButton
                                onClick={removeCountClickHandler}
                                color="primary"
                                disabled={
                                  (id === '1' || id === '5' || id === '6' || id === '15') &&
                                  expiredRtu.length !== 0 &&
                                  expiredRtu.length !== 0 &&
                                  plantName !== undefined
                                }
                              >
                                <IndeterminateCheckBoxOutlinedIcon />
                              </IconButton>
                              <Divider orientation="vertical" flexItem />
                              <Typography variant="h5" sx={{ ml: 2, mr: 2 }}>
                                {count}
                              </Typography>
                              <Divider orientation="vertical" flexItem />
                              <IconButton
                                onClick={addCountClickHandler}
                                color="primary"
                                disabled={
                                  (id === '1' || id === '5' || id === '6' || id === '15') &&
                                  expiredRtu.length !== 0 &&
                                  expiredRtu.length !== 0 &&
                                  plantName !== undefined
                                }
                              >
                                <AddBoxOutlinedIcon />
                              </IconButton>
                            </Card>
                          </>
                        )}
                      </Box>
                    </Box>
                    {expiredRtu.length !== 0 ? (
                      <>
                        <FormControl sx={{ mt: 4 }}>
                          <FormLabel>
                            <Typography variant="subtitle1">결제 방법</Typography>
                          </FormLabel>
                          <RadioGroup
                            row
                            value={depositorValue}
                            onChange={(e) => {
                              setDepositorValue(e.target.value);
                            }}
                          >
                            <FormControlLabel value="false" control={<Radio />} label="일반 결제" />
                            <FormControlLabel value="true" control={<Radio />} label="무통장 입금" />
                          </RadioGroup>
                        </FormControl>
                      </>
                    ) : null}
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12} md={12} lg={12}>
                        {product.pdttype === 7 || product.price === 0 ? (
                          <>
                            {isDesktop ? (
                              <Typography variant="h6">별도견적 상품 문의 : 1566-6403</Typography>
                            ) : (
                              <>
                                <Typography variant="h6">별도견적 상품 문의</Typography>
                                <Typography variant="h6">1566-6403</Typography>
                              </>
                            )}
                          </>
                        ) : product.cnt === 0 ? (
                          <>
                            <Box display="flex" alignItems="center" sx={{ mt: 1.5 }}>
                              <ProductionQuantityLimitsOutlinedIcon fontSize="large" />
                              <Typography variant="h5" sx={{ ml: 1 }}>
                                상품 준비중
                              </Typography>
                            </Box>
                          </>
                        ) : product.pdttype === 7 ? null : (
                          <>
                            <Box sx={{ mt: 2 }}>
                              <Typography variant="subtitle1">
                                <span style={{ color: 'red' }}>※ 무통장입금</span>보다는{' '}
                                <span style={{ color: 'red' }}>퀵계좌 이체</span> (일반결제)가 더 편리합니다.
                              </Typography>
                              <Typography variant="subtitle1">
                                (<span style={{ color: 'red' }}>지출증빙</span>용 현금 영수증 발행이 즉시
                                가능합니다.)
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: isDesktop ? 'flex' : '',
                                justifyContent: isDesktop ? 'start' : 'center',
                                alignItems: 'center',
                                mt: 3,
                              }}
                            >
                              {product.pdttype === 0 ? null : (
                                <Button
                                  size="large"
                                  type="button"
                                  variant="outlined"
                                  onClick={putInShoppingCartClickHandler}
                                  sx={{ width: isDesktop ? '45%' : '100%' }}
                                >
                                  장바구니
                                </Button>
                              )}
                              {expiredRtu.length !== 0 && plantName !== undefined ? (
                                <>
                                  <Box sx={{ width: product.pdttype === 0 ? 0 : '6%' }} />
                                  {depositorValue === 'true' ? (
                                    <Button
                                      size="large"
                                      type="button"
                                      variant="contained"
                                      disabled={confirmPayButton ? false : true}
                                      sx={{ width: isDesktop ? '45%' : '100%', mt: isDesktop ? 0 : 1 }}
                                      onClick={paymentDepostiorClickHandler}
                                    >
                                      무통장 입금
                                    </Button>
                                  ) : (
                                    <Button
                                      size="large"
                                      type="button"
                                      variant="contained"
                                      disabled={confirmPayButton ? false : true}
                                      sx={{ width: isDesktop ? '45%' : '100%', mt: isDesktop ? 0 : 1 }}
                                      onClick={buyProductClickHandler}
                                    >
                                      구매하기
                                    </Button>
                                  )}
                                </>
                              ) : null}
                            </Box>
                          </>
                        )}
                        {productDetailList.length > 1 ? (
                          <Box sx={{ mt: 2.5 }}>
                            {productDetailExplain ? (
                              <Button
                                variant="contained"
                                onClick={() => setProductDetailExplain(false)}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  width: isDesktop ? '40%' : '100%',
                                }}
                              >
                                상품 상세 접기
                              </Button>
                            ) : (
                              <Button
                                variant="contained"
                                onClick={() => setProductDetailExplain(true)}
                                sx={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  width: isDesktop ? '40%' : '100%',
                                }}
                              >
                                상품 상세 더보기
                              </Button>
                            )}
                          </Box>
                        ) : null}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {productDetailExplain && (
                <Box sx={{ mt: 3 }}>
                  <hr />
                  <ShopProductCardDetailExplain productDetailList={productDetailList} />
                </Box>
              )}
            </Grid>
          </MainCard>
          <ProductCartWidget
            putInShoppingCart={putInShoppingCart}
            setPutInShoppingCart={setPutInShoppingCart}
          />
        </>
      ) : null}
      <ShopProductCardDetailMoreInfo
        setCount={setCount}
        setExpiredRtu={setExpiredRtu}
        setProductDetailExplain={setProductDetailExplain}
      />
      {kakaoFindAddressModal && (
        <KakaoFindAddressModal
          setCheckPaymentStatusModal={setCheckPaymentStatusModal}
          setOpenDepositorStatusModal={setOpenDepositorStatusModal}
          checkDepositorStatus={depositorValue === 'true' ? true : false}
          userName={userName}
          setUserName={setUserName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          address={address}
          setAddress={setAddress}
          detailAddress={detailAddress}
          setDetailAddress={setDetailAddress}
          setZip={setZip}
          open={kakaoFindAddressModal}
          onClose={closeKakaoFindAddressModalClickHandler}
        />
      )}
      {checkPaymentStatusModal && (
        <PaymentPage
          plantkey={plantkey}
          orderid={cookies.orderid}
          pdtname={count > 1 ? `${product.pdtname} ${count}개` : product.pdtname}
          price={count > 1 ? (product.price + product.vatprice) * count : product.price + product.vatprice}
          userName={userName}
          phoneNumber={phoneNumber}
          address={address}
          detailAddress={detailAddress}
          zip={zip}
          open={checkPaymentStatusModal}
          onClose={closePaymentPageClickHandler}
        />
      )}
      {openDepositorStatusModal && (
        <DepositorPaymentPage
          plantkey={plantkey}
          orderid={cookies.orderid}
          pdtname={count > 1 ? `${product.pdtname} ${count}개` : product.pdtname}
          price={count > 1 ? (product.price + product.vatprice) * count : product.price + product.vatprice}
          userName={userName}
          phoneNumber={phoneNumber}
          address={address}
          detailAddress={detailAddress}
          zip={zip}
          setCartProducts={setCartProducts}
          open={openDepositorStatusModal}
          onClose={closePaymentDepositorClickHandler}
        />
      )}
      {openPaymentRtunStatusGuideModal && (
        <PaymentRtunStatusModal
          plantName={plantName}
          orderStatusRTUN={orderStatusRTUN}
          open={openPaymentRtunStatusGuideModal}
          onClose={closePaymentRtunStatusGuideModalClickHandler}
        />
      )}
    </>
  );
}
