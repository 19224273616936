import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import config from '../../../config';

const StyledTableCell = styled(TableCell)(({ theme, proctype }) => {
  const statusColor = getStatusColor(proctype);
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2065D1',
      color: theme.palette.common.white,
      fontSize: '13px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '13px',
      color: statusColor.color,
      fontWeight: statusColor.fontWeight,
      borderBottom: '1px solid #e0e0e0',
    },
  };
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f0f0f0',
  },
  '&:last-child td, &:last-child th': {
    borderBottom: '1px solid #e0e0e0',
  },
}));

// 처리 상태 색상을 위한 훅
const getStatusColor = (proctype) => {
  switch (proctype) {
    case '신청':
      return {
        color: '#ffab00',
        fontWeight: 'bold',
      };
    case '완료':
      return {
        color: '#43a047',
        fontWeight: 'bold',
      };
    case '거부':
      return {
        color: '#f44336',
        fontWeight: 'bold',
      };
    case '취소':
      return {
        color: '#607d8b',
        fontWeight: 'bold',
      };
    default:
      return {
        color: '#000000',
        fontWeight: 'normal',
      };
  }
};

/*
통합문의게시판 리스트 컴포넌트(웹뷰)
ConnectionPlant > ConnectionPlant(웹뷰 리스트 페이지),ConnectionPlantMobile(모바일뷰 리스트 페이지)
*/
export default function ConnectionPlant({ rows, tabValue }) {
  // 반응형 테이블을 위한 상수 선언
  const isDesktop = useMediaQuery('(min-width: 1200px)');
  const isTablet = useMediaQuery('(min-width: 700px) and (max-width: 1199px)');
  const isMobile = useMediaQuery('(min-width: 630px) and (max-width: 699px)');
  const isSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 629px)');

  // 조건에 따라 열 너비 조절
  const columnWidths = {
    name: isMobile || isSmallDevice ? '18%' : '18%',
    ogname: isMobile || isSmallDevice ? '18%' : '18%',
    hp: isMobile || isSmallDevice ? '14%' : '14%',
    pname: isMobile || isSmallDevice ? '34%' : '34%',
    proctype: isMobile || isSmallDevice ? '12%' : '12%',
  };

  const navigate = useNavigate();

  function handleGoDetail(ukey) {
    navigate(`${config.frontend.connectionPlantsAccept}/${tabValue}/${ukey}`);
  }

  const renderTable = (filteredRows) => (
    <StyledTableContainer>
      <Table size="small" sx={{ minWidth: 800 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell align="center">ID</StyledTableCell>
            <StyledTableCell align="center">신청자</StyledTableCell>
            <StyledTableCell align="center">소속</StyledTableCell>
            <StyledTableCell align="center">전화번호</StyledTableCell>
            <StyledTableCell align="center">발전소</StyledTableCell>
            <StyledTableCell align="center">상태</StyledTableCell>
            <StyledTableCell align="center">신청날짜</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {filteredRows.length > 0 ? (
            filteredRows.map((row) => (
              <StyledTableRow
                key={row.ukey}
                onClick={() => handleGoDetail(row.ukey)}
                sx={{
                  cursor: 'pointer',
                  '&:hover': { backgroundColor: '#cfd8dc !important' },
                }}
              >
                <StyledTableCell align="center">{row.id}</StyledTableCell>
                <StyledTableCell align="center">{row.uname}</StyledTableCell>
                <StyledTableCell align="center">{row.ogname || ''}</StyledTableCell>
                <StyledTableCell align="center">{row.hp || ''}</StyledTableCell>
                <StyledTableCell>{row.pname || ''}</StyledTableCell>
                <StyledTableCell align="center" proctype={row.proctype}>
                  {row.proctype}
                </StyledTableCell>
                <StyledTableCell align="center">{row.regdate.substring(0, 10)}</StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={7} sx={{ textAlign: 'center' }}>
                조회된 정보가 없습니다.
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );

  return (
    <Card
      sx={{
        mt: 2,
        borderRadius: 0,
        boxShadow: '0px 3px 12px #00000014',
      }}
    >
      {tabValue === 'received'
        ? renderTable(rows.filter((row) => row.proctype !== '취소'))
        : renderTable(rows)}
    </Card>
  );
}
