import { Box, Button, FormControl, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import { TbTruckReturn } from 'react-icons/tb';
import { fNumberWithCommas } from '../../utils/formatNumber';
import { useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';

export default function OrderCancelModal({ open, onClose, orderCancelData }) {
  const [cancelReason, setCancelReason] = useState('');

  const backendRequest = useBackendV2();

  const cancelReasonChangeHandler = (event, newValue) => {
    setCancelReason(newValue);
  };

  const cancelOrderClickHandler = async () => {
    try {
      const cancelOrderRequestConfig = {
        method: 'DELETE',
      };

      const result = await backendRequest.sendRequest(
        `${config.backend.endPoint.payments.basic}/${orderCancelData.paymentkey}/${cancelReason}/${orderCancelData.totalAmount}`,
        cancelOrderRequestConfig
      );

      if (result.data === true) {
        alert('취소가 완료되었습니다.');
        onClose();
      } else {
        alert('오류가 발생하였습니다.');
        onClose();
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
      onClose();
    }
  };

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <CustomModal
      open={open}
      onClose={closeModalHandler}
      sx={{ width: { xs: '95%', md: 650 }, maxHeight: '95%' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
        <TbTruckReturn size="27" />
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>주문 취소</Typography>
      </Box>
      <hr />
      <Typography variant="h5">{orderCancelData.orderName}</Typography>
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        취소금액 : {fNumberWithCommas(orderCancelData.totalAmount)}원
      </Typography>
      <Typography variant="subtitle1" sx={{ mt: 2 }}>
        취소사유
      </Typography>
      <FormControl>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={cancelReason}
          onChange={cancelReasonChangeHandler}
        >
          <FormControlLabel
            value="단순변심"
            control={<Radio size="small" />}
            label={<Typography variant="subtitle2">단순변심</Typography>}
          />
          <FormControlLabel
            value="상품불량"
            control={<Radio size="small" />}
            label={<Typography variant="subtitle2">상품불량</Typography>}
          />
          <FormControlLabel
            value="배송지연"
            control={<Radio size="small" />}
            label={<Typography variant="subtitle2">배송지연</Typography>}
          />
          <FormControlLabel
            value="상품정보와 상이"
            control={<Radio size="small" />}
            label={<Typography variant="subtitle2">상품정보와 상이</Typography>}
          />
          <FormControlLabel
            value="기타 사유"
            control={<Radio size="small" />}
            label={<Typography variant="subtitle2">기타 사유</Typography>}
          />
        </RadioGroup>
      </FormControl>
      <hr />
      <Box sx={{ mt: 2 }}></Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
        <Button onClick={cancelOrderClickHandler} variant="contained" sx={{ width: 200 }}>
          확인
        </Button>
      </Box>
    </CustomModal>
  );
}
