import { Card, Typography, useMediaQuery } from '@mui/material';
import {
  Bar,
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  Rectangle,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export default function HAllChart({ data, isDesktop }) {
  const isIpad = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const isNexthub = useMediaQuery('(min-width: 1025px) and (max-width: 1366px)');

  const formatXAxis = (tickItem) => {
    return `${tickItem}시`;
  };

  const hasRadiationData = data.some((item) => item.radiation !== null);

  return (
    <>
      <Card sx={{ mt: 1, borderRadius: '14px', boxShadow: '0px 3px 12px #00000014' }}>
        <ResponsiveContainer width="100%" height={isDesktop ? 650 : isIpad ? 550 : isNexthub ? 550 : 400}>
          <ComposedChart width="100%" height="100%" data={data} margin={{ top: 60, bottom: 10 }}>
            <CartesianGrid stroke="#d1d1d1" vertical={false} />
            <XAxis
              dataKey="year_month_day_hour"
              axisLine={false}
              tickFormatter={formatXAxis}
              tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
            />
            <YAxis
              yAxisId="left"
              axisLine={false}
              tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
              label={{
                value: 'kWh',
                offset: 30,
                angle: 0,
                position: 'top',
                fontSize: 14,
                fontWeight: 'bold',
                fill: '#000',
              }}
            />
            <YAxis
              yAxisId="right"
              orientation="right"
              axisLine={false}
              tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
              label={{
                value: 'W/㎡',
                offset: 30,
                angle: 0,
                position: 'top',
                fontSize: 14,
                fontWeight: 'bold',
                fill: '#000',
              }}
            />
            <Tooltip
              labelFormatter={formatXAxis}
              formatter={(value, name, props) => {
                if (name === '발전량') {
                  return [
                    <Typography key="tooltip-yield" variant="body1" fontWeight="bold">
                      {`${name} : ${value.toLocaleString()} kWh`}
                    </Typography>,
                  ];
                } else if (name === '경사 일사량') {
                  return [
                    <Typography key="tooltip-radiation" variant="body1" fontWeight="bold">
                      {`${name} :  ${value.toLocaleString()} W/㎡`}
                    </Typography>,
                  ];
                }
                return [value, name];
              }}
            />
            <Legend
              wrapperStyle={{
                overflowX: 'auto',
                width: '100%',
                whiteSpace: 'nowrap',
              }}
            />
            <Bar
              yAxisId="left"
              name="발전량"
              dataKey="yield"
              fill="#2065D1"
              activeBar={<Rectangle fill="pink" stroke="blue" />}
            />
            {hasRadiationData && (
              <Line
                yAxisId="right"
                name="경사 일사량"
                type="monotone"
                dataKey="radiation"
                stroke="#ff3300"
                strokeWidth={3}
              />
            )}
          </ComposedChart>
        </ResponsiveContainer>
      </Card>
    </>
  );
}
