import { Box, Button, Container, Divider, Grid, Paper, Typography } from '@mui/material';
import logo from '../../assets/EtcImg/SOLARCUBE.png';
import ViewTermsModal from '../Terms/ViewTermsModal';
import { useContext, useState } from 'react';
import config from '../../config';
import useBackendV2 from '../../hooks/use-backend-v2';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../store/auth-context';

// 웹 화면 회사 소개 및 안내 컴포넌트
export default function CompanyIntroduction({ setValue, setSubValue, pageNm }) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const { isAndroid, isiOS } = authCtx;

  const [termsModal, setTermsModal] = useState(false);
  const [termsContent, setTermsContent] = useState();

  const androidFunc = () => {
    window.QWebApp.locationHref('http://qubesoft.co.kr/main/Index.aspx');
  };
  const iOSFunc = () => {
    window.webkit.messageHandlers.locationHref.postMessage('http://qubesoft.co.kr/main/Index.aspx');
  };

  const handleCompanyIntroductionClick = () => {
    if (isAndroid) {
      androidFunc();
    } else if (isiOS) {
      iOSFunc();
    } else {
      window.open('http://qubesoft.co.kr/main/Index.aspx', '_blank');
    }
  };

  const closeTermsModalClickHandler = () => {
    setTermsModal(false);
  };

  const noticeClickHandler = () => {
    setValue(4);
    localStorage.setItem('menuValue', 4);
    setSubValue(1);
    localStorage.setItem('menuSubValue', 1);
    navigate(config.frontend.bulletinboards, { state: { subValue: 1 } });
  };

  const fetchTermsRequest = useBackendV2();

  const openServiceUseClickHandler = async () => {
    try {
      const { data } = await fetchTermsRequest.sendRequest(
        `${config.backend.endPoint.terms.getRecentContent}/1`
      );
      setTermsContent(data.contents);
      setTermsModal(true);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };
  const openCollectUserInfoClickHandler = async () => {
    try {
      const { data } = await fetchTermsRequest.sendRequest(
        `${config.backend.endPoint.terms.getRecentContent}/2`
      );
      setTermsContent(data.contents);
      setTermsModal(true);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const openIntraNetClickHandler = async () => {
    try {
      const storedData = localStorage.getItem('token');

      if (storedData) {
        const parsedData = JSON.parse(storedData);
        const refreshToken = parsedData.refreshToken;
        const url = `${process.env.REACT_APP_QUBESOFT_INTRANET_URL}?refreshToken=${JSON.stringify(
          parsedData
        )}&ID=${authCtx.currentUser.id}`;

        window.open(url, '_blank');

        if (isAndroid) {
          window.QWebApp.locationHref(url);
        } else if (isiOS) {
          window.webkit.messageHandlers.locationHref.postMessage(url);
        } else {
          window.open(url, '_blank');
        }
      } else {
        console.error('토큰 데이터가 로컬 스토리지에 존재하지 않습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const openOldSiteClickHandler = async () => {
    const url = `${process.env.REACT_APP_OLD_SOLARCUBE_URL}`;

    window.open(url, '_blank');
  };

  return (
    <Box>
      <Divider sx={{ borderBottom: '2px solid #c9c9c9' }} />
      <Container
        sx={{
          minWidth: pageNm === 'allStatus' ? '100%' : '89.5%',
        }}
      >
        <Box>
          <Box>
            <Grid container>
              <Grid item xs={8}>
                <Box sx={{ p: 2 }}>
                  <img src={logo} alt="SOLARCUBE" style={{ width: 100 }} />
                  <Typography variant="subtitle2" sx={{ fontSize: '12px', pt: 1.5 }}>
                    근무시간 : 09:00~18:00(평일) ※ 휴게시간 : 11:40~13:00
                    <br />
                    토요일/일요일/공휴일은 휴무입니다.
                    <br />
                    문의전화 : 1566-6403, 팩스 : 02-6053-6415
                    <br />
                    <br />
                    (주)큐브소프트 (대표이사 : 박영규) 경기도 오산시 외삼미로15번길 54-2, 큐브빌딩 (외삼미동)
                    <br />
                    사업자등록번호 : 661-81-00629 / 통신판매번호 : 2024-경기오산-0113
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={4}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-end',
                }}
              >
                <Box sx={{ p: 2 }}>
                  <Button
                    onClick={handleCompanyIntroductionClick}
                    variant="outlined"
                    sx={{
                      fontSize: '11px',
                      p: '8px',
                      m: '4px',
                      borderColor: '#626262',
                      color: '#626262',
                      bgcolor: '#fff',
                    }}
                  >
                    회사소개
                  </Button>
                  <Button
                    onClick={noticeClickHandler}
                    variant="outlined"
                    sx={{
                      fontSize: '11px',
                      p: '8px',
                      m: '4px',
                      borderColor: '#626262',
                      color: '#626262',
                      bgcolor: '#fff',
                    }}
                  >
                    공지사항
                  </Button>
                  <Button
                    onClick={openServiceUseClickHandler}
                    variant="outlined"
                    sx={{
                      fontSize: '11px',
                      p: '8px',
                      m: '4px',
                      borderColor: '#626262',
                      color: '#626262',
                      bgcolor: '#fff',
                    }}
                  >
                    서비스이용약관
                  </Button>
                  <Button
                    onClick={openCollectUserInfoClickHandler}
                    variant="outlined"
                    sx={{
                      fontSize: '11px',
                      p: '8px',
                      m: '4px',
                      borderColor: '#626262',
                      color: '#626262',
                      bgcolor: '#fff',
                    }}
                  >
                    개인정보처리방침
                  </Button>
                  {authCtx.currentUser.role === 'SUPERADMIN' || '사이트 관리자' || '전문기업관리자' ? (
                    <Button
                      onClick={openIntraNetClickHandler}
                      variant="outlined"
                      sx={{
                        fontSize: '11px',
                        p: '8px',
                        m: '4px',
                        borderColor: '#626262',
                        color: '#626262',
                        bgcolor: '#fff',
                      }}
                    >
                      인트라넷
                    </Button>
                  ) : null}
                  {process.env.REACT_APP_DOMAIN === 'SC' ? (
                    <Button
                      onClick={openOldSiteClickHandler}
                      variant="outlined"
                      sx={{
                        fontSize: '11px',
                        p: '8px',
                        m: '4px',
                        borderColor: '#626262',
                        color: '#626262',
                        bgcolor: '#fff',
                      }}
                    >
                      구 사이트 이동
                    </Button>
                  ) : null}
                </Box>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: '12px', justifyContent: 'flex-end', p: 3, pt: 5 }}
                >
                  Copyright {new Date().getFullYear()}. QubeSoft. All rights reserved
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
      <ViewTermsModal
        open={termsModal}
        onClose={closeTermsModalClickHandler}
        setTermsContent={setTermsContent}
        termsContent={termsContent}
      />
    </Box>
  );
}
