import { useEffect, useState } from 'react';
import MainCard from '../../theme/MainCard';
import { Card, Box, Typography } from '@mui/material';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import useInterval from '../../hooks/use-interval';

// 현황판 기상관측 정보 컴포넌트
export default function PlantWeatherObserveInfoCard_02({ page, tempHigh, tempLow, humidity, wind }) {
  const envDataState = {
    envGroup: 0,
    envRange: 0,
  };

  const [todayEnvData, setTodayEnvData] = useState([]);

  const backendRequest = useBackendV2();

  const fetchTodayEnvData = async () => {
    try {
      if (page.plantkey !== '') {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.envs.basic}/${page.plantkey}?${new URLSearchParams(envDataState)}`
        );

        setTodayEnvData(data);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchPollingInterval = 60000;

  useInterval(() => {
    fetchTodayEnvData();
  }, fetchPollingInterval);

  useEffect(() => {
    fetchTodayEnvData();
  }, [page]);

  return (
    <>
      <Card
        sx={{
          // bgcolor: '#0059B2',
          borderRadius: '15px',
          boxShadow: '0px 3px 100px #00000014',
          height: 210,
        }}
      >
        <Typography variant="h6" sx={{ color: '#000', mt: 3, ml: 2 }}>
          기상 관측 정보
        </Typography>
        <Box display="flex" sx={{ mt: 2.5 }}>
          <Card
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              border: '1px solid #CECECE',
              bgcolor: '#373737 ',
              width: '100%',
              borderBottom: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0',
            }}
          >
            <Typography variant="subtitle2" sx={{ color: '#fff', ml: 0.5 }}>
              경사 일사량
            </Typography>
            <Typography variant="subtitle2" textAlign="end" sx={{ color: '#FCAF92', mr: 0.5, mt: 1.5 }}>
              {todayEnvData.length === 0 || todayEnvData === undefined || todayEnvData[0].trad < 0
                ? null
                : todayEnvData[0].trad}
              W/㎡
            </Typography>
          </Card>
          <Card
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              border: '1px solid #CECECE',
              bgcolor: '#373737 ',
              width: '100%',
              borderBottom: 'none',
              borderRadius: '0',
            }}
          >
            <Typography variant="subtitle2" sx={{ color: '#fff', ml: 0.5 }}>
              수평 일사량
            </Typography>
            <Typography variant="subtitle2" textAlign="end" sx={{ color: '#FCAF92', mr: 0.5, mt: 1.5 }}>
              {todayEnvData.length === 0 || todayEnvData === undefined || todayEnvData[0].trad < 0
                ? null
                : todayEnvData[0].srad}
              W/㎡
            </Typography>
          </Card>
        </Box>
        <Box display="flex">
          <Card
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              border: '1px solid #CECECE',
              bgcolor: '#373737 ',
              width: '100%',
              borderBottom: 'none',
              borderLeft: 'none',
              borderRight: 'none',
              borderRadius: '0',
            }}
          >
            <Typography variant="subtitle2" sx={{ color: '#fff', ml: 0.5 }}>
              판넬 온도
            </Typography>
            <Typography variant="subtitle2" textAlign="end" sx={{ color: '#FCAF92', mr: 0.5, mt: 1.5 }}>
              {todayEnvData.length === 0 || todayEnvData === undefined || todayEnvData[0].trad < 0
                ? null
                : todayEnvData[0].otemp}
              ℃
            </Typography>
          </Card>
          <Card
            sx={{
              // display: 'flex',
              // justifyContent: 'space-between',
              border: '1px solid #CECECE',
              bgcolor: '#373737 ',
              width: '100%',
              borderBottom: 'none',
              borderRadius: '0',
            }}
          >
            <Typography variant="subtitle2" sx={{ color: '#fff', ml: 0.5 }}>
              외기 온도
            </Typography>
            <Typography variant="subtitle2" textAlign="end" sx={{ color: '#FCAF92', mr: 0.5, mt: 1.5 }}>
              {todayEnvData.length === 0 || todayEnvData === undefined || todayEnvData[0].trad < 0
                ? null
                : todayEnvData[0].ptemp}
              ℃
            </Typography>
          </Card>
        </Box>
      </Card>
    </>
  );
}
