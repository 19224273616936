import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  Grid,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import JboxTable from './JboxTable';
import JboxChart from '../../../Charts/ConnectionBoard/JboxChart';
import SendIcon from '@mui/icons-material/Send';

// 접속반 웹 상세화면 컴포넌트
export default function JboxWebDetail({ jBoxsList, eachJboxData, setEachJboxData, setJboxDetail }) {
  const jBoxListClickHandler = () => {
    setJboxDetail(false);
  };

  const handleSelectChange = (event) => {
    const selectedSerialNum = event.target.value;
    const selectedJboxData = jBoxsList.find((item) => item.serialnum === selectedSerialNum);

    // 선택한 jbname에 해당하는 데이터로 eachJboxData를 업데이트
    if (selectedJboxData) {
      setEachJboxData(selectedJboxData);
    }
  };

  const tooltipContent =
    eachJboxData.nodata === 1 || eachJboxData.flg_ev === '-1'
      ? '발전 중단'
      : eachJboxData.nodata === 0 &&
        (eachJboxData.juncboxchannel.some((channel) => channel.curt === 0) ||
          eachJboxData.juncboxchannel.some((channel) => channel.curnodata === 1))
      ? '대기 중'
      : '발전 중';

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          padding: {
            xs: 0,
            sm: 2,
          },
          borderRadius: '14px',
          boxShadow: '0px 3px 12px #00000014',
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Select
              variant="standard"
              value={eachJboxData.serialnum}
              onChange={handleSelectChange}
              sx={{ mr: 1 }}
            >
              {jBoxsList?.map((item, index) => (
                <MenuItem key={index} value={item.serialnum}>
                  {item.jbname}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="subtitle1">상세 정보</Typography>
          </Box>
          <Button
            type="button"
            variant="text"
            size="large"
            onClick={jBoxListClickHandler}
            endIcon={<SendIcon color="primary" />}
            sx={{ fontSize: '1.0rem', color: 'black' }}
          >
            접속반 목록
          </Button>
        </Box>
        <br />
        <Box>
          <Grid item xs={24}>
            <Grid item xs={12}>
              <Grid container spacing={0.5}>
                <Grid item lg={4} md={4} sm={4} xs={4}>
                  <Grid item lg={12} md={12} sm={12} xs={12}>
                    <Card variant="outlined" sx={{ borderColor: '#CECECE', borderRadius: 2, mr: 3 }}>
                      <Box display="flex">
                        <Card
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: 1.5,
                            width: '50%',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                            <ArrowRightIcon />
                            전압 : &nbsp;
                          </Typography>
                          <Typography variant="subtitle1">{eachJboxData.jvolt} V</Typography>
                        </Card>
                        <Card
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: 1.5,
                            width: '50%',
                          }}
                        >
                          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                            <ArrowRightIcon />
                            전류 : &nbsp;
                          </Typography>
                          <Typography variant="subtitle1">{eachJboxData.sumcurt.toFixed(1)} A</Typography>
                        </Card>
                      </Box>
                      <Box display="flex">
                        <Card
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: 1.5,
                            width: '50%',
                          }}
                        >
                          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                            <ArrowRightIcon />
                            온도 : &nbsp;
                          </Typography>
                          <Typography variant="subtitle1">
                            <span style={{ color: '#251FFD' }}>{eachJboxData.jtemp}</span> ℃
                          </Typography>
                        </Card>
                        <Card
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            p: 1.5,
                            width: '50%',
                            alignItems: 'center',
                          }}
                        >
                          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
                            <ArrowRightIcon />
                            상태 : &nbsp;
                          </Typography>
                          <Tooltip title={tooltipContent} arrow>
                            <Avatar
                              sizes="small"
                              sx={{
                                width: 13,
                                height: 13,
                                ml: 1,
                                bgcolor:
                                  eachJboxData.nodata === 1 || eachJboxData.flg_ev === '-1'
                                    ? 'red'
                                    : eachJboxData.nodata === 0 &&
                                      (eachJboxData.juncboxchannel.some((channel) => channel.curt === 0) ||
                                        eachJboxData.juncboxchannel.some(
                                          (channel) => channel.curnodata === 1
                                        ))
                                    ? 'orange'
                                    : '#73E600',
                                color:
                                  eachJboxData.nodata === 1 || eachJboxData.flg_ev === '-1'
                                    ? 'red'
                                    : eachJboxData.nodata === 0 &&
                                      (eachJboxData.juncboxchannel.some((channel) => channel.curt === 0) ||
                                        eachJboxData.juncboxchannel.some(
                                          (channel) => channel.curnodata === 1
                                        ))
                                    ? 'orange'
                                    : '#73E600',
                              }}
                            >
                              {''}
                            </Avatar>
                          </Tooltip>
                        </Card>
                      </Box>
                    </Card>
                  </Grid>
                  <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 3 }}>
                    <Card variant="outlined" sx={{ borderRadius: 0, mr: 3 }}>
                      <JboxTable data={eachJboxData.juncboxchannel} />
                    </Card>
                  </Grid>
                </Grid>
                <Grid item lg={8} md={8} sm={8} xs={8}>
                  <Card variant="outlined" sx={{ borderColor: '#CECECE', borderRadius: 2 }}>
                    <JboxChart data={eachJboxData.juncboxchannel} />
                  </Card>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Card>
    </>
  );
}
