import { useState } from 'react';
import useInput from '../../hooks/use-input';
import { Box, Button, Checkbox, FormControlLabel, Typography } from '@mui/material';
import JoinInput from './JoinInput';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import ViewTermsModal from '../Terms/ViewTermsModal';
import CheckTerms from './CheckTerms';
import {
  validateBusinessNo,
  validateEmail,
  validateFax,
  validateId,
  validateName,
  validateOfficePhone,
  validatePassword,
  validatePhone,
} from '../../utils/validationUtil';
import { useNavigate } from 'react-router-dom';
import RequestTaxBillFrom from './RequestTaxBillForm';

// 회원가입 구성 컴포넌트
export default function JoinForm({
  social,
  socialUserInfo,
  allAgreed,
  setAllAgreed,
  agreed1,
  setAgreed1,
  agreed2,
  setAgreed2,
  handleAgreeCheckboxChange,
  cancelJoin,
}) {
  const navigate = useNavigate();

  const [idIsNotDuplicate, setIdIsNotDuplicate] = useState(false);
  const [selectedCarrier, setSelectedCarrier] = useState('');

  const [termsModal, setTermsModal] = useState(false);
  const [termsContent, setTermsContent] = useState();

  const [businessNoIsNotDuplicate, setBusinessNoIsNotDuplicate] = useState(false);
  const [reqTaxBillCheck, setReqTaxBillCheck] = useState(false);

  const [companyKey, setCompanyKey] = useState(null);

  const idInput = useInput((value) => validateId(value));
  const nameInput = useInput((value) => validateName(value));
  const passwordInput = useInput((value) => validatePassword(value));
  const passwordConfirmInput = useInput((value) => passwordInput.value === value);
  const phoneInput = useInput((value) => validatePhone(value));
  const emailInput = useInput((value) => validateEmail(value));

  const formIsValid =
    idIsNotDuplicate &&
    idInput.isValid &&
    nameInput.isValid &&
    passwordInput.isValid &&
    passwordConfirmInput.isValid &&
    phoneInput.isValid &&
    selectedCarrier &&
    agreed1;

  // 전문기업명
  const companyNameInput = useInput((value) => value.length > 0);
  // 주소
  const addressInput = useInput();
  // 상세주소 address2
  const addressDetailInput = useInput();
  // 우편번호
  const zipCodeInput = useInput();
  // 회사 대표 전화번호
  const companyPhoneInput = useInput((value) => validateOfficePhone(value));
  // 회사 팩스 번호
  const companyFaxInput = useInput((value) => validateFax(value));
  // 회사 대표 이메일
  const companyEmailInput = useInput((value) => validateEmail(value));
  // 사업자 등록 번호
  const businessNoInput = useInput((value) => validateBusinessNo(value));
  // 대표자 명
  const representativeNameInput = useInput((value) => validateName(value));
  // 업태
  const typeOfBusinessInput = useInput();
  // 종목
  const itemOfBusinessInput = useInput();
  // 관리업체 사업영역
  const businessAreaInput = useInput();
  // 기타
  const etcInput = useInput();

  const businessFormIsValid =
    companyNameInput.isValid &&
    companyPhoneInput.isValid &&
    businessNoInput.isValid &&
    representativeNameInput.isValid;

  const joinRequest = useBackendV2();
  const joinTermsRequest = useBackendV2();
  const fetchTermsRequest = useBackendV2();

  const joinHandler = async () => {
    if (!formIsValid) {
      return;
    }

    try {
      const newRequestConfig = {
        method: 'POST',
        body: {
          id: idInput.value.trim(),
          pwd: passwordInput.value.trim(),
          uname: nameInput.value.trim(),
          hp: phoneInput.value.trim(),
          uemail: emailInput.value.trim(),
          groupauthcd: selectedCarrier,
          ogkey: companyKey,
          smtype: social === 'naver' ? 1 : social === 'kakao' ? 2 : null,
          smid: socialUserInfo === undefined ? null : socialUserInfo.id.toString(),
          smname: socialUserInfo === undefined ? null : socialUserInfo.nickname,
          smemail: socialUserInfo === undefined ? null : socialUserInfo.email,
          smhp: socialUserInfo === undefined ? null : socialUserInfo.phone,
        },
      };
      const saveResult = await joinRequest.sendRequest(
        config.backend.endPoint.users.newSM,
        newRequestConfig,
        false
      );

      const rqTermDtos = [];

      if (agreed1) {
        rqTermDtos.push({
          id: idInput.value.trim(),
          termskey: 3,
          confirmdate: null,
          isAgree: 1,
        });
      }
      if (agreed2) {
        rqTermDtos.push({
          id: idInput.value.trim(),
          termskey: 4,
          confirmdate: null,
          isAgree: 1,
        });
      }

      const newTermsRequestConfig = {
        method: 'POST',
        body: rqTermDtos,
      };
      const saveTermsResult = await joinTermsRequest.sendRequest(
        config.backend.endPoint.terms.new,
        newTermsRequestConfig,
        false
      );

      if (saveResult.data === 1 && saveTermsResult.data === true) {
        alert('가입이 완료되었습니다.');
        navigate('/');
      } else {
        alert('가입에 실패하였습니다.');
      }
    } catch (error) {
      console.error(error);
      if (
        error.message === 'INVALID_USER_ID' ||
        error.message === 'INVALID_USER_PASSWORD' ||
        error.message === 'INVALID_USER_NAME' ||
        error.message === 'INVALID_USER_PHONE' ||
        error.message === 'INVALID_USER_EMAIL'
      ) {
        alert('회원정보 형식이 올바르지 않습니다.');
      } else {
        alert('오류가 발생하였습니다.');
      }
    }
  };

  const openServiceUseClickHandler = async () => {
    try {
      const { data } = await fetchTermsRequest.sendRequest(
        `${config.backend.endPoint.terms.getContent}/1`, // termstype=1 이 서비스 이용약관 2가 개인정보 처리방침 termskey 는 자동증가값 where 판단은 termstype isUse
        null,
        false
      );
      setTermsContent(data.contents);
      setTermsModal(true);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };
  const openCollectUserInfoClickHandler = async () => {
    try {
      const { data } = await fetchTermsRequest.sendRequest(
        `${config.backend.endPoint.terms.getContent}/2`,
        null,
        false
      );
      setTermsContent(data.contents);
      setTermsModal(true);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const closeTermsModalClickHandler = () => {
    setTermsModal(false);
  };

  const reqTaxBillCheckChangeHandler = () => {
    setReqTaxBillCheck(!reqTaxBillCheck);
  };

  return (
    <>
      <Box sx={{ width: { xs: '100%', md: 500 } }}>
        <Box
          component="form"
          sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <JoinInput
            idIsNotDuplicate={idIsNotDuplicate}
            setIdIsNotDuplicate={setIdIsNotDuplicate}
            idInput={idInput}
            passwordInput={passwordInput}
            passwordConfirmInput={passwordConfirmInput}
            emailInput={emailInput}
            nameInput={nameInput}
            phoneInput={phoneInput}
            selectedCarrier={selectedCarrier}
            setSelectedCarrier={setSelectedCarrier}
          />
          <FormControlLabel
            control={<Checkbox checked={reqTaxBillCheck} onChange={reqTaxBillCheckChangeHandler} />}
            label={<Typography variant="subtitle1">기업 회원 등록</Typography>}
          />
          {reqTaxBillCheck && (
            <RequestTaxBillFrom
              reqTaxBillCheck={reqTaxBillCheck}
              businessNoIsNotDuplicate={businessNoIsNotDuplicate}
              setBusinessNoIsNotDuplicate={setBusinessNoIsNotDuplicate}
              companyNameInput={companyNameInput}
              addressInput={addressInput}
              addressDetailInput={addressDetailInput}
              zipCodeInput={zipCodeInput}
              companyPhoneInput={companyPhoneInput}
              companyFaxInput={companyFaxInput}
              companyEmailInput={companyEmailInput}
              businessNoInput={businessNoInput}
              representativeNameInput={representativeNameInput}
              typeOfBusinessInput={typeOfBusinessInput}
              itemOfBusinessInput={itemOfBusinessInput}
              businessAreaInput={businessAreaInput}
              etcInput={etcInput}
              setCompanyKey={setCompanyKey}
            />
          )}
          <CheckTerms
            allAgreed={allAgreed}
            setAllAgreed={setAllAgreed}
            agreed1={agreed1}
            setAgreed1={setAgreed1}
            agreed2={agreed2}
            setAgreed2={setAgreed2}
            handleAgreeCheckboxChange={handleAgreeCheckboxChange}
            openServiceUseClickHandler={openServiceUseClickHandler}
            openCollectUserInfoClickHandler={openCollectUserInfoClickHandler}
          />
          {process.env.REACT_APP_DOMAIN === 'SC' ? null : (
            <>
              <Typography variant="subtitle1" sx={{ color: 'red', mt: 1 }}>
                기존 DNE 회원은 신규 가입 없이
              </Typography>
              <Typography variant="subtitle1" sx={{ color: 'red' }}>
                기존 아이디, 비밀번호로 로그인 가능합니다.
              </Typography>
            </>
          )}
          <Box sx={{ mt: 2.5, mb: 2, width: '100%' }}>
            <Button
              type="button"
              variant="contained"
              disabled={!formIsValid || (reqTaxBillCheck && !businessFormIsValid)}
              onClick={joinHandler}
              size="large"
              sx={{ width: '100%' }}
            >
              회원가입
            </Button>
            <Button
              type="button"
              variant="outlined"
              onClick={cancelJoin}
              size="large"
              sx={{ width: '100%', mt: 1 }}
            >
              돌아가기
            </Button>
          </Box>
        </Box>
      </Box>
      <ViewTermsModal
        open={termsModal}
        onClose={closeTermsModalClickHandler}
        setTermsContent={setTermsContent}
        termsContent={termsContent}
      />
    </>
  );
}
