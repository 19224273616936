import {
  Avatar,
  Box,
  CardContent,
  ClickAwayListener,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import MainCard from '../../../theme/MainCard';
import { useState } from 'react';
import config from '../../../config';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// 개별 대시보드 인버터 상태 컴포넌트
export default function TodayInvertersTable({ invDailyDevelopment, isDesktop }) {
  const navigate = useNavigate();

  const [plantIvtTooltipOpenMap, setPlantIvtTooltipOpenMap] = useState({});

  // 클릭한 인버터 아바타의 툴팁 상태를 열기
  const handlePlantIvtAvatarClick = (serialnum) => {
    setPlantIvtTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: true }));
  };
  // 인버터 아바타의 툴팁 상태를 닫기
  const handlePlantIvtTooltipClose = (serialnum) => {
    setPlantIvtTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: false }));
  };

  const inverterListClickHandler = () => {
    localStorage.setItem('menuValue', 1);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.inverters, { state: { subValue: 0 } });
  };

  return (
    <>
      <MainCard content={false} sx={{ height: 350, overflowY: 'auto' }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography color="primary" fontWeight="bold" fontSize="0.89rem" sx={{ mb: 1 }}>
              인버터
            </Typography>
            <Avatar
              variant="square"
              onClick={inverterListClickHandler}
              sx={{ width: '25px', height: '25px', bgcolor: '#1981E7', cursor: 'pointer' }}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </Avatar>
          </Box>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
              {invDailyDevelopment.map((data, index) => (
                <div key={index}>
                  <Grid container direction="column">
                    <Grid item>
                      <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                          <Typography variant="subtitle1" color="inherit">
                            {data.invertername}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                              <Typography variant="subtitle1" color="inherit">
                                {data.dailyyield === null ? 0 : data.dailyyield.toFixed(2)} kWh
                              </Typography>
                            </Grid>
                            <Grid item>
                              {isDesktop ? (
                                <Tooltip title={data.generatestatus === 1 ? '발전 중' : '발전 중단'} arrow>
                                  <Avatar
                                    sizes="small"
                                    sx={{
                                      width: 15,
                                      height: 15,
                                      ml: 1,
                                      bgcolor: data.generatestatus === 1 ? '#64B346' : 'red',
                                      color: data.generatestatus === 1 ? '#64B346' : 'red',
                                    }}
                                  >
                                    {''}
                                  </Avatar>
                                </Tooltip>
                              ) : (
                                <ClickAwayListener
                                  onClickAway={() => handlePlantIvtTooltipClose(data.serialnum)}
                                >
                                  <div>
                                    <Tooltip
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      open={!!plantIvtTooltipOpenMap[data.serialnum]}
                                      onClose={() => handlePlantIvtTooltipClose(data.serialnum)}
                                      title={data.generatestatus === 1 ? '발전 중' : '발전 중단'}
                                      arrow
                                    >
                                      <Avatar
                                        sizes="small"
                                        sx={{
                                          width: 15,
                                          height: 15,
                                          ml: 1,
                                          bgcolor: data.generatestatus === 1 ? '#64B346' : 'red',
                                          color: data.generatestatus === 1 ? '#64B346' : 'red',
                                        }}
                                        onClick={() => handlePlantIvtAvatarClick(data.serialnum)}
                                      >
                                        {''}
                                      </Avatar>
                                    </Tooltip>
                                  </div>
                                </ClickAwayListener>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </div>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </MainCard>
    </>
  );
}
