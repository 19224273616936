import { Box, Button, Card, Chip, Drawer, Grid, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';
import {
  PiCloudDuotone,
  PiCloudFogDuotone,
  PiCloudLightningDuotone,
  PiCloudMoonDuotone,
  PiCloudRainDuotone,
  PiCloudSnowDuotone,
  PiCloudSunDuotone,
  PiMoonStarsDuotone,
  PiSunDuotone,
} from 'react-icons/pi';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import { useEffect, useState } from 'react';
import AppWidgetEachDevelopment from '../EachPlant/AppWidgetEachDevelopment';
import AppWidgetMonthSummary from '../EachPlant/AppWidgetMonthSummary';
import AppWidgetEachSummary from '../EachPlant/AppWidgetEachSummary';
import AppWidgetTimeOfDevelopment from '../EachPlant/AppWidgetTimeOfDevelopment';
import useResponsive from '../../../hooks/useResponsive';
import SendIcon from '@mui/icons-material/Send';
import EachPlantInfoMobile from '../EachPlant/EachPlantInfoMobile';
import MainCard from '../../../theme/MainCard';
import WebWidgetInverterInfoTable from '../EachPlant/WebWidgetInverterInfoTable';
import WebWidgetInverterChart from '../EachPlant/WebWidgetInverterChart';
import WebWidgetTodayRecTable from '../EachPlant/WebWidgetTodayRecTable';
import WebWidgetTodaySmpTable from '../EachPlant/WebWidgetTodaySmpTable';
import WebWidgetAcbVcbInfoTable from '../EachPlant/WebWidgetAcbVcbInfoTable';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import UseCustomSort from '../../../hooks/useCustomSort';

const weatherIconMap = {
  '001-1': <PiSunDuotone style={{ fontSize: '2.8rem', color: 'red', marginRight: 3 }} />,
  '001-2': <PiMoonStarsDuotone style={{ fontSize: '2.8rem', color: '#D5C450', marginRight: 3 }} />,
  '002-1': <PiCloudSunDuotone style={{ fontSize: '2.8rem', color: '#999999', marginRight: 3 }} />,
  '002-2': <PiCloudMoonDuotone style={{ fontSize: '2.8rem', color: '#999999', marginRight: 3 }} />,
  '003': <PiCloudDuotone style={{ fontSize: '2.8rem', color: '#999999', marginRight: 3 }} />,
  '004': <PiCloudRainDuotone style={{ fontSize: '2.8rem', color: '#8490e1', marginRight: 3 }} />,
  '005': <PiCloudFogDuotone style={{ fontSize: '2.8rem', color: '#999999', marginRight: 3 }} />,
  '006': <PiCloudSnowDuotone style={{ fontSize: '2.8rem', color: '#9B9CA2', marginRight: 3 }} />,
  '007': <PiCloudLightningDuotone style={{ fontSize: '2.8rem', color: '#D3A612', marginRight: 3 }} />,
};

export default function EntirePlantDetaile({
  page,
  plantkey,
  plantName,
  address,
  subscribeday,
  rsInverters,
  todayWeather,
  moveEachPlantHandler,
  openSubscriptionGuideModalHandler,
  showWeatherInfoModalHandler,
  showPlantSettingHandler,
  drawerOpen,
  handleDrawerClose,
}) {
  const isDesktop = useResponsive('up', 'lg');

  const [basePlantInfo, setBasePlantInfo] = useState([]);
  const [invDailyDevelopment, setInvDailyDevelopment] = useState([]);
  const [acbVcbDailyDevelopment, setAcbVcbDailyDevelopment] = useState([]);
  const [todaySmp, setTodaySmp] = useState([]);
  const [todayRec, setTodayRec] = useState([]);
  const [graceperiodDay, setGraceperiodDay] = useState(null);

  const backendRequest = useBackendV2();

  const fetchBasePlantInfoData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.baseInfo}/${plantkey}`
      );
      setBasePlantInfo(data);
    } catch (error) {
      setBasePlantInfo([]);
    }
  };

  const fetchDailyInvDevelopmentData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.inverters.extReal}/${plantkey}`
      );

      const sortedData = UseCustomSort(data, 'invertername');

      setInvDailyDevelopment(sortedData);
    } catch (error) {
      setInvDailyDevelopment([]);
    }
  };

  const fetchAcbVcbDailyDevelopmentData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.acbvcbs.extReal}/${plantkey}`
      );
      setAcbVcbDailyDevelopment(data);
    } catch (error) {
      setAcbVcbDailyDevelopment([]);
    }
  };

  const fetchTodaySmpRecData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(`${config.backend.endPoint.smprecs.last}`);

      const { recs, smps } = data;

      setTodayRec(recs);
      setTodaySmp(smps);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchSubscriptionPeriod = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.subscribe}/${plantkey}`
      );

      const filteredData = data.filter((item) => item.subscribeday > -90);

      if (filteredData.length > 0) {
        setGraceperiodDay(filteredData[0]?.graceperiod);
      } else {
        setGraceperiodDay(null);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  // 총발전량 계산
  const sumTotalYield = invDailyDevelopment.reduce((total, inverter) => {
    return total + inverter.totalyield;
  }, 0);

  // 일일발전량 계산
  const totalDailyYield = invDailyDevelopment.reduce((total, inverter) => {
    return total + (inverter.dailyyield || 0);
  }, 0);

  const roundedTotalDailyYield = parseFloat(totalDailyYield.toFixed(1));

  // 월발전량 계산
  const totalMonthyYield = invDailyDevelopment.reduce((total, inverter) => {
    return total + (inverter.monthyield || 0);
  }, 0);

  const roundedTotalMonthyYield = parseFloat(totalMonthyYield.toFixed(1));

  // 현재출력 계산
  const totalPac = invDailyDevelopment.reduce((total, inverter) => {
    return total + (inverter.pac || 0);
  }, 0);

  // 발전시간 계산
  const totalDailyDevelopmentTime =
    basePlantInfo.length !== 0 ? roundedTotalDailyYield / basePlantInfo[0].capacity : 0;

  const roundedTotalDailyDevelopmentTime = parseFloat(totalDailyDevelopmentTime.toFixed(1));

  useEffect(() => {
    fetchBasePlantInfoData();
    fetchDailyInvDevelopmentData();
    fetchAcbVcbDailyDevelopmentData();
    fetchTodaySmpRecData();
    fetchSubscriptionPeriod();
  }, []);

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerClose}>
      <Box sx={{ width: 850, p: 2, bgcolor: '#fff' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center">
            <EastIcon onClick={handleDrawerClose} fontSize="large" sx={{ cursor: 'pointer', mr: 1.5 }} />
            <Box>
              <Typography variant="h5">
                {subscribeday > -90 ? (
                  subscribeday < 0 ? (
                    <Chip
                      label={`서비스 만료 D${subscribeday}`}
                      color="error"
                      variant="outlined"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation(); // 클릭 이벤트가 부모 요소로 전파되지 않도록 함
                        openSubscriptionGuideModalHandler();
                      }}
                      sx={{ mr: 0.5, mb: 0.5 }}
                    />
                  ) : (
                    <Chip
                      label="서비스 만료"
                      color="error"
                      variant="contained"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation(); // 클릭 이벤트가 부모 요소로 전파되지 않도록 함
                        openSubscriptionGuideModalHandler();
                      }}
                      sx={{ mr: 0.5, mb: 0.5 }}
                    />
                  )
                ) : null}
                {plantName}
                <SettingsOutlinedIcon
                  sx={{
                    ml: 0.5,
                    color: '#808080',
                    cursor: isDesktop ? 'pointer' : 'cursor',
                    fontSize: '1.65rem',
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // 클릭 이벤트가 부모 요소로 전파되지 않도록 함
                    showPlantSettingHandler();
                  }}
                />
              </Typography>
              <Typography color="primary" variant="subtitle1" alignItems="center" sx={{ mt: 0 }}>
                {address}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              mr: 1,
            }}
          >
            <Box onClick={showWeatherInfoModalHandler} sx={{ cursor: 'pointer' }}>
              {todayWeather !== '' && weatherIconMap[todayWeather.v_img]}
            </Box>
            <Typography color="primary" variant="h6" sx={{ ml: 0.5 }}>
              {todayWeather !== '' ? todayWeather.v_temp : null}°C
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ bgcolor: '#F6F9FC', p: 2 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            onClick={moveEachPlantHandler}
            variant="outlined"
            endIcon={<SendIcon />}
            sx={{ bgcolor: '#fff' }}
          >
            상세보기
          </Button>
          <Typography variant="h6" sx={{ filter: subscribeday - graceperiodDay > 0 ? 'blur(10px)' : '' }}>
            현재 출력 : {parseFloat(totalPac?.toFixed(1))}
            <span style={{ fontSize: '90%' }}>kW</span>
          </Typography>
        </Box>
        <Box sx={{ mt: 1.5, filter: subscribeday - graceperiodDay > 0 ? 'blur(10px)' : '' }}>
          <Grid item xs={12}>
            <Grid container spacing={1.5}>
              <Grid item lg={3} md={6} sm={6} xs={6}>
                <AppWidgetEachDevelopment total={sumTotalYield} color="info" />
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={6}>
                <AppWidgetMonthSummary total={roundedTotalMonthyYield} color="success" />
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={6}>
                <AppWidgetEachSummary
                  total={roundedTotalDailyYield}
                  price={25972}
                  isDesktop={isDesktop}
                  color="warning"
                />
              </Grid>
              <Grid item lg={3} md={6} sm={6} xs={6}>
                <AppWidgetTimeOfDevelopment total={roundedTotalDailyDevelopmentTime} color="success" />
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 2, filter: subscribeday - graceperiodDay > 0 ? 'blur(10px)' : '' }}>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <MainCard
                  border={false}
                  content={false}
                  sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography variant="subtitle1" sx={{ ml: 1 }}>
                    RTU 상태
                  </Typography>
                  <Card
                    sx={{
                      borderRadius: '7px',
                      boxShadow: '0px 3px 100px #00000014',
                      width: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      p: 1,
                      backgroundColor: basePlantInfo?.some((plant) =>
                        plant.rtuinfos?.some((info) => info.rtustatus === 1)
                      )
                        ? '#4F8C14'
                        : '#FF1F30',
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ color: '#fff' }}>
                      {basePlantInfo?.some((plant) => plant.rtuinfos?.some((info) => info.rtustatus === 1))
                        ? '통신 중'
                        : '통신 중단'}
                    </Typography>
                  </Card>
                </MainCard>
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <MainCard
                  border={false}
                  content={false}
                  sx={{ p: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
                >
                  <Typography variant="subtitle1" sx={{ ml: 1 }}>
                    발전 상태
                  </Typography>
                  <Card
                    sx={{
                      borderRadius: '7px',
                      boxShadow: '0px 3px 100px #00000014',
                      width: '50%',
                      display: 'flex',
                      justifyContent: 'center',
                      p: 1,
                      backgroundColor: basePlantInfo?.some((plant) =>
                        plant.rtuinfos?.some((info) => info.rtustatus === 1)
                      )
                        ? invDailyDevelopment.some((inverter) => inverter.generatestatus === 1)
                          ? '#4F8C14'
                          : '#FF1F30'
                        : '#FF1F30',
                    }}
                  >
                    <Typography variant="subtitle2" sx={{ color: '#fff' }}>
                      {basePlantInfo?.some((plant) => plant.rtuinfos?.some((info) => info.rtustatus === 1))
                        ? invDailyDevelopment.some((inverter) => inverter.generatestatus === 1)
                          ? '발전 중'
                          : '발전 중단'
                        : '발전 중단'}
                    </Typography>
                  </Card>
                </MainCard>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box sx={{ mt: 1.5, filter: subscribeday - graceperiodDay > 0 ? 'blur(10px)' : '' }}>
          <EachPlantInfoMobile basePlantInfo={basePlantInfo} />
        </Box>
        <Box sx={{ mt: 3, filter: subscribeday - graceperiodDay > 0 ? 'blur(10px)' : '' }}>
          <WebWidgetInverterChart page={page} plantkey={plantkey} />
          <Box sx={{ mt: 2 }} />
          <WebWidgetInverterInfoTable
            basePlantInfo={basePlantInfo}
            invDailyDevelopment={invDailyDevelopment}
            plantkey={plantkey}
          />
          {acbVcbDailyDevelopment.length > 0 ? (
            <>
              <Box sx={{ mt: 2 }} />
              <WebWidgetAcbVcbInfoTable
                basePlantInfo={basePlantInfo}
                invDailyDevelopment={invDailyDevelopment}
                acbVcbDailyDevelopment={acbVcbDailyDevelopment}
                plantkey={plantkey}
              />
            </>
          ) : null}
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Grid container spacing={0.5}>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <WebWidgetTodayRecTable todayRec={todayRec} address={address} />
              </Grid>
              <Grid item lg={6} md={6} sm={6} xs={6}>
                <WebWidgetTodaySmpTable todaySmp={todaySmp} address={address} />
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Drawer>
  );
}
