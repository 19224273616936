import useResponsive from '../../../hooks/useResponsive';
import YGroupChart from '../../Charts/Statistics/Year/YGroupChart';
import YearGroupStatisticsTable from './YearGroupStatisticsTable';

export default function YearGroupStatistics({ data, chartView }) {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <>
      {isDesktop ? (
        <>
          <YGroupChart data={data} isDesktop={isDesktop} />
          {data.length === 0 ? null : <YearGroupStatisticsTable data={data} isDesktop={isDesktop} />}
        </>
      ) : chartView ? (
        <YGroupChart data={data} isDesktop={isDesktop} />
      ) : (
        <YearGroupStatisticsTable data={data} isDesktop={isDesktop} />
      )}
    </>
  );
}
