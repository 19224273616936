import { Avatar, Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import MainCard from '../../../theme/MainCard';
import { BsDatabaseCheck } from 'react-icons/bs';
// import ChargingStationIcon from '@mui/icons-material/ChargingStation';

// 전체 대시보드 통합 설비용량 (전체 인버터X) 표시 컴포넌트
export default function AppWidgetInverter({ total, totalCapacity, color = 'primary' }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  // totalCapacity를 숫자로 변환
  const totalCapacityNumber = Number(totalCapacity);
  const displayCapacity =
    totalCapacityNumber > 10000 ? (totalCapacityNumber / 1000).toFixed(1) : totalCapacityNumber.toFixed(1);

  const capacityUnit = totalCapacity > 10000 ? 'MW' : 'kW';

  return (
    <MainCard
      sx={{
        bgcolor: '#0059B2',
        borderRadius: '15px',
        boxShadow: '5px 6px 12px 5px #00000014',
        // backgroundColor: theme.palette[color].lighter,
        bgcolor: '#fff',
      }}
    >
      <Box>
        <Grid container direction="column">
          <Grid item>
            <Box display="flex" alignItems="center">
              <Avatar
                variant="rounded"
                sx={{
                  ...theme.typography.commonAvatar,
                  ...theme.typography.largeAvatar,
                  // backgroundColor: theme.palette[color].darker,
                  bgcolor: '#E2E6FB',
                  width: isMobile ? 30 : 40,
                  height: isMobile ? 30 : 40,
                  mr: 1,
                }}
              >
                {/* <ChargingStationIcon sx={{ color: '#0059B2', fontSize: isMobile ? '1.5rem' : '2rem' }} /> */}
                <BsDatabaseCheck style={{ color: '#0059B2', fontSize: isMobile ? '1.5rem' : '1.75rem' }} />
              </Avatar>
              <Typography
                variant={isMobile ? 'subtitle1' : 'h5'}
                sx={{
                  color: theme.palette.secondary[200],
                  display: 'flex',
                  // justifyContent: isMobile ? 'end' : 'start',
                }}
              >
                전체 용량
              </Typography>
            </Box>
          </Grid>
          <Grid item sx={{ mt: 1, display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
            <Typography variant="h2">{displayCapacity}</Typography>
            <Typography variant="h5" sx={{ ml: isMobile ? 0.4 : 1, mt: isMobile ? 1.4 : 2.2 }}>
              {capacityUnit}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </MainCard>
  );
}
