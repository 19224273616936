import { Box } from '@mui/material';
import useBackendV2 from '../../../hooks/use-backend-v2';
import { convertFormatStringToYMD } from '../../../utils/dateUtil';
import config from '../../../config';
import { useEffect } from 'react';
import ComprehensiveAnalysisChart from '../../Charts/Statistics/ComprehensiveAnalysis/ComprehensiveAnalysisChart';
import useResponsive from '../../../hooks/useResponsive';
import ComprehensiveAnalysisTable from './ComprehensiveAnalysisTable';

// 종합분석통계 컴포넌트
// Energy=totalyield, Radition=trad, AC Power=pac, DC Power=pdc, AC Voltage=uac, DC Voltage=udc, AC Current=iac, DC Current=idc
export default function ComprehensiveAnalysis({
  page,
  dateValue,
  plantInverters,
  selectPlantInverters,
  comprehensiveAnalysis,
  setComprehensiveAnalysis,
  comprehensiveSearchState,
  setComprehensiveSearchState,
  processedAnalysisData,
  setProcessedAnalysisData,
}) {
  const isDesktop = useResponsive('up', 'lg');

  const backendRequest = useBackendV2();

  const fetchPlantComprehensiveAnalysis = async () => {
    try {
      const comprehensiveAnalysisRequestConfig = {
        method: 'POST',
        body: selectPlantInverters,
      };
      const formattedDate = convertFormatStringToYMD(dateValue);
      const result = await backendRequest.sendRequest(
        `${config.backend.endPoint.statistics.muxAnalysis}/${page.plantkey}?dateTime=${formattedDate}`,
        comprehensiveAnalysisRequestConfig
      );

      // null 값을 가진 속성 삭제된 필터링된 데이터 생성
      const filteredData = result.data.filter((item) => {
        for (let key in item) {
          if (item[key] === null) {
            delete item[key];
          }
        }
        return true;
      });

      // 분석 항목(comprehensiveAnalysis)에 선택된 필터링된 데이터 생성
      const processedFilteredData = filteredData.map((item) => {
        const lowerCaseItem = Object.keys(item).reduce((acc, key) => {
          acc[key.toLowerCase()] = item[key];
          return acc;
        }, {});

        const filteredItem = Object.keys(lowerCaseItem)
          .filter((key) => {
            const included = comprehensiveAnalysis.includes(key.toLowerCase());
            const totalyieldIncluded =
              comprehensiveAnalysis.includes('totalyield') && key.toLowerCase().startsWith('totalyield');
            const tradIncluded =
              comprehensiveAnalysis.includes('trad') && key.toLowerCase().startsWith('trad');
            const pacIncluded = comprehensiveAnalysis.includes('pac') && key.toLowerCase().startsWith('pac');
            const pdcIncluded = comprehensiveAnalysis.includes('pdc') && key.toLowerCase().startsWith('pdc');
            const uacIncluded = comprehensiveAnalysis.includes('uac') && key.toLowerCase().startsWith('uac');
            const udcIncluded = comprehensiveAnalysis.includes('udc') && key.toLowerCase().startsWith('udc');
            const iacIncluded = comprehensiveAnalysis.includes('iac') && key.toLowerCase().startsWith('iac');
            const idcIncluded = comprehensiveAnalysis.includes('idc') && key.toLowerCase().startsWith('idc');
            return (
              included ||
              key === 'fdate' ||
              key === 'fhour' ||
              totalyieldIncluded ||
              tradIncluded ||
              pacIncluded ||
              pdcIncluded ||
              uacIncluded ||
              udcIncluded ||
              iacIncluded ||
              idcIncluded
            );
          })
          .reduce((acc, key) => {
            acc[key] = lowerCaseItem[key];
            return acc;
          }, {});

        return filteredItem;
      });

      setProcessedAnalysisData(processedFilteredData);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    if (selectPlantInverters.length !== 0 && comprehensiveSearchState === true) {
      fetchPlantComprehensiveAnalysis();
      setComprehensiveSearchState(false);
    }
  }, [selectPlantInverters, comprehensiveAnalysis, comprehensiveSearchState]);
  useEffect(() => {
    if (selectPlantInverters.length === 0) {
      setComprehensiveAnalysis([]);
      setProcessedAnalysisData([]);
    }
  }, [selectPlantInverters]);

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <ComprehensiveAnalysisChart
          data={processedAnalysisData}
          selectPlantInverters={selectPlantInverters}
          analysisDatalength={comprehensiveAnalysis.length}
          comprehensiveAnalysis={comprehensiveAnalysis}
          comprehensiveSearchState={comprehensiveSearchState}
          setComprehensiveSearchState={setComprehensiveSearchState}
          isDesktop={isDesktop}
        />
        <ComprehensiveAnalysisTable
          data={processedAnalysisData}
          selectPlantInverters={selectPlantInverters}
          plantInverters={plantInverters}
          analysisDatalength={comprehensiveAnalysis.length}
          comprehensiveAnalysis={comprehensiveAnalysis}
          comprehensiveSearchState={comprehensiveSearchState}
          setComprehensiveSearchState={setComprehensiveSearchState}
          isDesktop={isDesktop}
        />
      </Box>
    </>
  );
}
