// 백엔드 및 프론트엔드 엔드포인트에 대한 URL 구성을 정의

const backendAuthentication = '/authentication';
const backendUsers = '/users';
const backendTerms = '/terms';
const backendPlant = '/plants';
const backendEnv = '/envs';
const backendStatistics = '/statistics';
const backendInvters = '/inverters';
const backendAcbVcbs = '/acbvcbs';
const backendAlarms = '/alarms';
const backendBulletinBoard = '/bulletinboards';
const backendJunctionBoxs = '/junctionboxs';
const backendSmpRecs = '/smprec';
const backendStrings = '/strings';
const backendProducts = '/products';
const backendCart = '/cart';
const backendPayments = '/payments';
const backendOrders = '/orders';
const backendErrCode = '/errcode';
const backendNoticePopup = '/noticepopup';
const backendCompany = '/companies';
const backendDelivery = '/deliverys';
const backendFile = '/files';
const backendCodeSub = '/codesubs';
const backendLogRecord = '/logRecords';
const backendCctv = '/cctvs';

const frontendUsers = '/users';
const frontendInverter = '/inverters';
const frontendAlarm = '/alarms';
const frontendBulletinboard = '/bulletinboards';
const frontendStatistics = '/statistics';
const frontendPayments = '/payments';
const frontendProducts = '/products';
const frontendPushSettings = '/pushsettings';
const fromtendOrders = '/orders';
const frontendAddPlants = '/addplants';
const frontendAddSubscribeKeys = '/addsubscribekeys';
const frontendConnectionPlants = '/connectionplants';

const config = {
  backend: {
    host: process.env.REACT_APP_BACKEND_API_URL,
    hostMo: process.env.REACT_APP_MOBILE_BACKEND_API_URL,
    endPoint: {
      authentication: {
        login: `${backendAuthentication}/login`,
        smlogin: `${backendAuthentication}/SMlogin`,
        smcheck: `${backendAuthentication}/SMcheck`,
        refresh: `${backendAuthentication}/refresh`,
        revoke: `${backendAuthentication}/revoke`,
        kakaoAccessToken: `${backendAuthentication}/KakaoAccessToken`,
        kakaoUserInfo: `${backendAuthentication}/KakaoUserInfo`,
      },
      users: {
        basic: backendUsers,
        checkDuplication: `${backendUsers}/checkDuplication`,
        info: `${backendUsers}/id`,
        update: `${backendUsers}/up`,
        new: `${backendUsers}/new`,
        newSM: `${backendUsers}/new/SM`,
        tempNumIssuance: `${backendUsers}/up/tempNumIssuance`,
        tempPwdUpdate: `${backendUsers}/up/tempPwdUpdate`,
        fineId: `${backendUsers}/findId`,
        checkDupl: `${backendUsers}/checkDupl`,
        infoHp: `${backendUsers}/hp`,
        styleUpdate: `${backendUsers}/styleUpdate`,
        safetyManager: `${backendUsers}/apply/SafetyManager`,
        styleGet: `${backendUsers}/styleGet`,
      },
      terms: {
        basic: backendTerms,
        getContent: `${backendTerms}/termsKey`,
        new: `${backendTerms}/new`,
        getRecentContent: `${backendTerms}/termsRecent`,
        getImportingTermsById: `${backendTerms}/importingTermsById`,
        agreedTermsById: `${backendTerms}/agreedTermsById`,
      },
      plants: {
        basic: backendPlant,
        userPlants: `${backendPlant}/user`,
        userPlantsV2: `${backendPlant}/V2/user`,
        allInfo: `${backendPlant}/allInfo/user`,
        allInfoMUser: `${backendPlant}/allInfo/MUser`,
        baseInfo: `${backendPlant}/BaseInfo`,
        subscribe: `${backendPlant}/Subscribe`,
        findRtunPlant: `${backendPlant}/FindRtunPlant`,
        manageCompanyInfo: `${backendPlant}/ManageCompanyInfo`,
        findRtunMac: `${backendPlant}/FindRtunMac`,
        findPlantUser: `${backendPlant}/FindPlantUser`,
        addNewPlant: `${backendPlant}/new`,
        connPlant: `${backendPlant}/ConnPlant`,
        findConnPlant: `${backendPlant}/Request/find`,
        add: `${backendPlant}/RequestConn/add`,
        detail: `${backendPlant}/accept`,
        approve: `${backendPlant}/ConnPlant/approve`,
        deny: `${backendPlant}/ConnPlant/deny`,
        findPlantImg: `${backendPlant}/FindPlantImg`,
        updatePlant: `${backendPlant}/up`,
        cancel: `${backendPlant}/ConnPlant/cancel`,
        count: `${backendPlant}/ConnPlant/count`,
        findSubPlantList: `${backendPlant}/FindSubPlantList`,
        findSubPlantInfo: `${backendPlant}/FindSubPlantInfo`,
        addNewSubPlant: `${backendPlant}/AddNewSubPlant`,
        updateSubPlant: `${backendPlant}/UpdateSubPlant`,
        connectNewPlantInfo: `${backendPlant}/ConnectNewPlantInfo`,
        plantUserList: `${backendPlant}/plantUserList`,
        findSharingRtuPlant: `${backendPlant}/FindSharingRtuPlant`,
        plantInverterList: `${backendPlant}/Intranet/allInfo/PlantInverterList`,
        plantInverterListV2: `${backendPlant}/Intranet/allInfo/PlantInverterListV2`,
        plantInverterListSummary: `${backendPlant}/Intranet/allInfo/PlantInverterList/Summary`,
        plantInverterListData: `${backendPlant}/Intranet/allInfo/PlantInverterList/Data`,
      },
      envs: {
        basic: backendEnv,
        weather: `${backendEnv}/Weather`,
        down: `${backendEnv}/Down`,
      },
      statistics: {
        basic: backendStatistics,
        plantYield: `${backendStatistics}/plantYield`,
        plantYieldDown: `${backendStatistics}/plantYieldDown`,
        muxAnalysis: `${backendStatistics}/MuxAnalysis`,
        muxAnalysisDown: `${backendStatistics}/MuxAnalysisDown`,
      },
      inverters: {
        basic: backendInvters,
        extBase: `${backendInvters}/ExtBase`,
        extReal: `${backendInvters}/ExtReal`,
        ivtByRTU: `${backendInvters}/IvtByRTU`,
        ivtCUD: `${backendInvters}/CUD`,
        ivtControlInfo: `${backendInvters}/IvtControlInfo`,
      },
      acbvcbs: {
        basic: backendAcbVcbs,
        extBase: `${backendAcbVcbs}/ExtBase`,
        extReal: `${backendAcbVcbs}/ExtReal`,
        acbVcbControlInfo: `${backendAcbVcbs}/AcbVcbControlInfo`,
        acbVcbIvtFindIndexNum: `${backendAcbVcbs}/AcbVcbIvtDvcFindIndexNum`,
        acbVcbIvtDvcLogSearch: `${backendAcbVcbs}/AcbVcbIvtDvcLogSearch`,
        acbVcbIvtDvcListCount: `${backendAcbVcbs}/AcbVcbIvtDvcListCount`,
        acbVcbIvtDvcList: `${backendAcbVcbs}/AcbVcbIvtDvcList`,
        acbVcbIvtControl: `${backendAcbVcbs}/AcbVcbIvtControl`,
      },
      alarms: {
        basic: backendAlarms,
        aList: `${backendAlarms}/AList`,
        bList: `${backendAlarms}/BList`,
      },
      bulletinBoard: {
        basic: `${backendBulletinBoard}/request`,
        detail: `${backendBulletinBoard}/request/detail`,
        add: `${backendBulletinBoard}/request/add`,
        noticeBasic: `${backendBulletinBoard}/notice`,
        noticeDetail: `${backendBulletinBoard}/notice/detail`,
        noticeAdd: `${backendBulletinBoard}/notice/add`,
        archiveBasic: `${backendBulletinBoard}/archive`,
        inquiryBasic: `${backendBulletinBoard}/inquiry`,
        inquiryDetail: `${backendBulletinBoard}/inquiry/detail`,
        inquiryAdd: `${backendBulletinBoard}/inquiry/add`,
      },
      junctionboxs: {
        basic: backendJunctionBoxs,
        real: `${backendJunctionBoxs}/Real`,
      },
      smprecs: {
        basic: backendSmpRecs,
        real: `${backendSmpRecs}/Real`,
        last: `${backendSmpRecs}/Last`,
      },
      strings: {
        basic: backendStrings,
        real: `${backendStrings}/Real`,
      },
      products: {
        basic: backendProducts,
        product: `${backendProducts}/Product`,
        detail: `${backendProducts}/Product/Detail`,
        new: `${backendProducts}/Product/new`,
        productOption: `${backendProducts}/ProductOption`,
        detailList: `${backendProducts}/Product/DetailList`,
      },
      carts: {
        basic: backendCart,
        keyList: `${backendCart}/KeyList`,
        key: `${backendCart}/Key`,
        keyReg: `${backendCart}/KeyReg`,
        allDel: `${backendCart}/AllDel`,
        orderStatusByRTUN: `${backendCart}/OrderStatusByRTUN`,
      },
      payments: {
        basic: backendPayments,
        confirm: `${backendPayments}/Confirm`,
        orderid: `${backendPayments}/orderid`,
        payment: `${backendPayments}/payment`,
        virtual: `${backendPayments}/virtual`,
        transaction: `${backendPayments}/transaction`,
        settlement: `${backendPayments}/settlement`,
        requestBankTransfer: `${backendPayments}/requestBankTransfer`,
        requestConfirmBankTransferAsync: `${backendPayments}/requestConfirmBankTransferAsync`,
        getDepositor: `${backendPayments}/getDepositor`,
      },
      orders: {
        basic: backendOrders,
        orderInfo: `${backendOrders}/orderInfo`,
        list: `${backendOrders}/list`,
        listDetail: `${backendOrders}/listDetail`,
        receipt: `${backendOrders}/Receipt`,
      },
      errcodes: {
        basic: backendErrCode,
        code: `${backendErrCode}/Code`,
        yield: `${backendErrCode}/Yield`,
      },
      noticePopup: {
        basic: backendNoticePopup,
      },
      companies: {
        basic: backendCompany,
        search: `${backendCompany}/search`,
        create: `${backendCompany}/create`,
        update: `${backendCompany}/up`,
      },
      deliverys: {
        basic: backendDelivery,
        deliveryStatus: `${backendDelivery}/deliveryStatus`,
      },
      files: {
        basic: backendFile,
        imageFile: `${backendFile}/imageFile`,
        file: `${backendFile}/file`,
      },
      codeSubs: {
        basic: backendCodeSub,
        appVer: `${backendCodeSub}/AppVer`,
        IvtListCode: `${backendCodeSub}/IvtListCode`,
      },
      logRecords: {
        basic: backendLogRecord,
        pageLogRecord: `${backendLogRecord}/Page`,
      },
      cctvs: {
        basic: backendCctv,
        haveCCTV: `${backendCctv}/HaveCCTV`,
      },
    },
  },
  frontend: {
    users: frontendUsers,
    inverters: frontendInverter,
    alarms: frontendAlarm,
    bulletinboards: frontendBulletinboard,
    statistics: frontendStatistics,
    payments: frontendPayments,
    products: frontendProducts,
    productdetails: `${frontendProducts}/detail`,
    orderinfos: `${fromtendOrders}/info`,
    pushsettings: frontendPushSettings,
    requestBoardsdetail: `${frontendBulletinboard}/request/detail`,
    requestBoardsadd: `${frontendBulletinboard}/request/add`,
    // requestBoards: `${frontendBulletinboard}/request`,
    noticeBoards: `${frontendBulletinboard}/notice`,
    noticeBoardsdetail: `${frontendBulletinboard}/notice/detail`,
    // userMod: `${frontendUsers}/:key`,
    // audit: '/audittrail',
    archive: `${frontendBulletinboard}/archive`,
    addPlants: frontendAddPlants,
    addSubscribeKey: frontendAddSubscribeKeys,
    connectionPlants: frontendConnectionPlants,
    connectionPlantsRequest: `${frontendConnectionPlants}/request`,
    connectionPlantsAccept: `${frontendConnectionPlants}/accept`,
    inquiryBoards: `${frontendBulletinboard}/inquiry`,
    inquiryBoardsdetail: `${frontendBulletinboard}/inquiry/detail`,
    inquiryBoardsadd: `${frontendBulletinboard}/inquiry/add`,
  },
};

export default config;
