import { Box, Divider, Typography } from '@mui/material';
import useBackendV2 from '../../../hooks/use-backend-v2';
import { useEffect, useState } from 'react';
import config from '../../../config';

export default function ManagementCompanyInfo({ plantkey }) {
  const [managementCompany, setManagementCompany] = useState([]);

  const backendRequest = useBackendV2();

  const fetchManagementCompanyInfo = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.manageCompanyInfo}/${plantkey}`
      );

      setManagementCompany(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchManagementCompanyInfo();
  }, []);

  return (
    <>
      {managementCompany.length === 0 ? (
        <Typography variant="h6">등록된 관리업체가 존재하지 않습니다.</Typography>
      ) : (
        <Box>
          {managementCompany.map((company, index) => (
            <Box key={index}>
              <Box sx={{ mt: 1 }}>
                <Typography variant="h6">{company.ogname}</Typography>
                <Box sx={{ mt: 1 }}>
                  <Typography variant="subtitle1" color="text.secondary">
                    주소 : {company.address === '' ? '등록된 주소가 없습니다.' : company.address}
                  </Typography>
                  <Typography variant="subtitle1" color="text.secondary">
                    연락처 : {company.tel === '' ? '등록된 연락처가 없습니다.' : company.tel}
                  </Typography>
                </Box>
                <Box sx={{ mt: 1 }}>
                  <Typography variant="subtitle2">담당자명 : {company.uname}</Typography>
                  <Typography variant="subtitle2">업무 : {company.authgroupnm}</Typography>
                </Box>
              </Box>
              <Divider sx={{ bgcolor: '#000', mt: 1 }} />
            </Box>
          ))}
          {/* <Typography>{managementCompany.ogname}</Typography> */}
        </Box>
      )}
    </>
  );
}
