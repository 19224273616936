import { Box, Button } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';

// 이용약관, 개인정보처리방침 내용 안내 모달 컴포넌트
export default function ViewTermsModal({ open, onClose, setTermsContent, termsContent }) {
  const closeTermsModalHandler = (event, reason) => {
    setTermsContent();
    onClose();
  };

  return (
    <CustomModal
      open={open}
      onClose={closeTermsModalHandler}
      sx={{ width: { xs: '95%', md: 600 }, maxHeight: '95%' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon onClick={closeTermsModalHandler} sx={{ cursor: 'pointer' }} />
      </Box>
      <Box
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          width: '100%',
        }}
      >
        <Box dangerouslySetInnerHTML={{ __html: termsContent }}></Box>
        <Button
          type="button"
          variant="outlined"
          onClick={closeTermsModalHandler}
          sx={{ width: '100%', mt: 1 }}
        >
          닫기
        </Button>
      </Box>
    </CustomModal>
  );
}
