import { Box, List } from '@mui/material';
import MonitoringMenu from '../Header/Menu/MonitoringMenu';
import { useState } from 'react';
import InverterMenu from '../Header/Menu/InverterMenu';
import StatisticsMenu from '../Header/Menu/StatisticsMenu';
import AlarmMenu from '../Header/Menu/AlarmMenu';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import BulletinboardMenu from '../Header/Menu/BulletinboardMenu';
import StatusPlateMenu from '../Header/Menu/StatusPlateMenu';
import ProductMenu from '../Header/Menu/ProductMenu';

// 사이드 네비게이션 메뉴 컴포넌트
export default function NavSection({
  value,
  setValue,
  setSubValue,
  subValue,
  webNavOpen,
  setWebNavOpen,
  onCloseNav,
  isDesktop,
  ...other
}) {
  const navigate = useNavigate();

  const moveMonitoring = () => {
    navigate('/', { state: { subValue: 0 } });
    onCloseNav();
  };

  const [monitoringSelect, setMonitoringSelect] = useState(false);
  const [inverterSelect, setInverterSelect] = useState(false);
  const [statisticsSelect, setStatisticsSelect] = useState(false);
  const [alarmSelect, setAlarmSelect] = useState(false);
  const [bulletinboardSelect, setBulletinboardSelect] = useState(false);

  const monitoringClickHandler = () => {
    // setMonitoringSelect(!monitoringSelect);
    setValue(0);
    localStorage.setItem('menuValue', 0);
    // localStorage.removeItem('selectPlantkey');
    moveMonitoring();
  };
  const inverterClickHandler = () => {
    setInverterSelect(!inverterSelect);
  };
  const statisticsClickHandler = () => {
    setStatisticsSelect(!statisticsSelect);
  };
  const alarmClickHandler = () => {
    setAlarmSelect(!alarmSelect);
    // setValue(3);
    // localStorage.setItem('menuValue', 3);
    // moveAlarm();
  };
  const bulletinboardClickHandler = () => {
    setBulletinboardSelect(!bulletinboardSelect);
    // setValue(4);
    // localStorage.setItem('menuValue', 4);
    // moveBulletinboard();
  };
  const productDetailClickHandler = () => {
    setValue(6);
    localStorage.setItem('menuValue', 6);
    localStorage.removeItem('selectPlantkey');
    setSubValue(0);
    navigate(config.frontend.products, { state: { value: 6, subValue: 0 } });
  };

  return (
    <>
      <Box {...other}>
        <List disablePadding sx={{ p: 1, overflowX: 'hidden' }}>
          <MonitoringMenu
            value={value}
            setValue={setValue}
            setSubValue={setSubValue}
            monitoringClickHandler={monitoringClickHandler}
            setMonitoringSelect={setMonitoringSelect}
            monitoringSelect={monitoringSelect}
            webNavOpen={webNavOpen}
            onClose={onCloseNav}
          />
          <InverterMenu
            value={value}
            setValue={setValue}
            subValue={subValue}
            setSubValue={setSubValue}
            inverterClickHandler={inverterClickHandler}
            setInverterSelect={setInverterSelect}
            inverterSelect={inverterSelect}
            webNavOpen={webNavOpen}
            onClose={onCloseNav}
          />
          <StatisticsMenu
            value={value}
            setValue={setValue}
            subValue={subValue}
            setSubValue={setSubValue}
            statisticsClickHandler={statisticsClickHandler}
            setStatisticsSelect={setStatisticsSelect}
            statisticsSelect={statisticsSelect}
            webNavOpen={webNavOpen}
            onClose={onCloseNav}
          />
          <AlarmMenu
            value={value}
            setValue={setValue}
            subValue={subValue}
            setSubValue={setSubValue}
            alarmClickHandler={alarmClickHandler}
            setAlarmSelect={setAlarmSelect}
            alarmSelect={alarmSelect}
            webNavOpen={webNavOpen}
            onClose={onCloseNav}
          />
          <BulletinboardMenu
            value={value}
            setValue={setValue}
            subValue={subValue}
            setSubValue={setSubValue}
            bulletinboardClickHandler={bulletinboardClickHandler}
            bulletinboardSelect={bulletinboardSelect}
            webNavOpen={webNavOpen}
            onClose={onCloseNav}
          />
          {isDesktop ? <StatusPlateMenu webNavOpen={webNavOpen} /> : null}
          {/* <PlantSettingMenu
            setValue={setValue}
            setSubValue={setSubValue}
            plantSettingClickHandler={plantSettingClickHandler}
            setPlantSettingSelect={setPlantSettingSelect}
            plantSettingSelect={plantSettingSelect}
            onClose={onCloseNav}
          /> */}
          {process.env.REACT_APP_STORE_OPEN === 'true' ? (
            <ProductMenu
              value={value}
              productDetailClickHandler={productDetailClickHandler}
              webNavOpen={webNavOpen}
            />
          ) : null}
        </List>
      </Box>
    </>
  );
}
