import {
  Autocomplete,
  Box,
  Breadcrumbs,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import useBackendV2 from '../../hooks/use-backend-v2';
import { useContext, useEffect, useState } from 'react';
import config from '../../config';
import AuthContext from '../../store/auth-context';
import WebPushSetting from '../../components/Setting/PushSetting/WebPushSetting';
import MobilePushSetting from '../../components/Setting/PushSetting/MobilePushSetting';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import Header02 from '../../components/Layout/Header02';
import SubHeader from '../../components/Layout/SubHeader';
import Nav from '../../components/Layout/Nav';
import usePageLogRecord from '../../hooks/usePageLogRecord';

export default function PushSettingPage() {
  const isDesktop = useResponsive('up', 'lg');
  const authCtx = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const distance = 0;

  const pageConditionInitialState = {
    plantkey: '',
    plantname: '',
  };

  const [plant, setPlant] = useState(pageConditionInitialState);
  const [selectPlant, setSelectPlant] = useState([]);
  const [errCodes, setErrCodes] = useState([]);
  const [checkCode, setCheckCode] = useState([]);
  const [yieldCheck, setYieldCheck] = useState();
  const [yieldCheckYN, setYieldCheckYN] = useState(false);

  const selectPlantChangeHandler = (plant) => {
    setPlant((prev) => {
      return { ...prev, plantkey: plant.plantkey, plantname: plant.plantname };
    });
    localStorage.setItem('selectPlantkey', plant.plantkey);
  };

  const backendRequest = useBackendV2();

  const fetchUserPlants = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.userPlantsV2}/${authCtx.currentUser.id}`
      );

      if (data.length === 1) {
        setPlant((prevPage) => ({
          ...prevPage,
          plantkey: data[0].plantkey,
          plantname: data[0].plantname,
        }));
        setSelectPlant(data);
      } else {
        setSelectPlant(data);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchPlantErrCodes = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.errcodes.code}/${plant.plantkey}/${authCtx.currentUser.id}`
      );

      setErrCodes(data.map((item) => ({ ...item, id: item.errNUM + item.errStatus })));
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchPlantYield = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.errcodes.yield}/${plant.plantkey}/${authCtx.currentUser.id}`
      );
      setYieldCheck(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const saveCheckInfoClickHandler = async () => {
    try {
      const saveCheckCodeRequestConfig = {
        method: 'POST',
        body: checkCode,
      };
      const saveCheckCodeResult = await backendRequest.sendRequest(
        `${config.backend.endPoint.errcodes.code}/${plant.plantkey}/${authCtx.currentUser.id}`,
        saveCheckCodeRequestConfig
      );

      const saveYieldCheckRequestConfig = {
        method: 'POST',
      };
      const saveYieldCheckResult = await backendRequest.sendRequest(
        `${config.backend.endPoint.errcodes.yield}/${plant.plantkey}/${authCtx.currentUser.id}/${
          yieldCheckYN ? '1' : '0'
        }`,
        saveYieldCheckRequestConfig
      );

      if (saveCheckCodeResult.data !== null && saveYieldCheckResult.data !== null) {
        alert('성공적으로 저장했습니다.');
      } else {
        alert('오류가 발생하였습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchUserPlants();
    fetchUserStylePreference();
  }, []);

  useEffect(() => {
    if (plant.plantkey !== '') {
      fetchPlantErrCodes();
      fetchPlantYield();
    }
  }, [plant]);

  useEffect(() => {
    // 초기 렌더링 시에 호출될 로직
    const localStorageSelectPlantkey = localStorage.getItem('selectPlantkey');

    // selectPlantState에서 해당하는 발전소 탐색
    const initialSelectedPlant = selectPlant.find((option) => option.plantkey === localStorageSelectPlantkey);

    // 첫번째 발전소 탐색
    const initialSelectedPlantN = selectPlant[0];

    // 찾은 발전소를 초기값으로 설정
    if (initialSelectedPlant) {
      selectPlantChangeHandler(initialSelectedPlant);

      setPlant((prev) => ({
        ...prev,
        plantkey: initialSelectedPlant.plantkey,
        plantname: initialSelectedPlant.plantname,
      }));
    }
    // 찾은 발전소가 없는 경우 첫번째 발전소를 초기값으로 설정
    else if (initialSelectedPlant === undefined && initialSelectedPlantN !== undefined) {
      selectPlantChangeHandler(initialSelectedPlantN);

      setPlant((prev) => ({
        ...prev,
        plantkey: initialSelectedPlantN?.plantkey,
        plantname: initialSelectedPlantN?.plantname,
      }));
    }
  }, [selectPlant]);

  useEffect(() => {
    // errCodes가 변경될 때마다 checkCode 업데이트
    const updatedCheckCode = errCodes
      .filter((row) => row.isSet === '1')
      .map(({ ivtAcb, torder, iType, errNUM, isSet }) => ({ ivtAcb, torder, iType, errNUM, isSet }));
    setCheckCode(updatedCheckCode);
  }, [errCodes]);

  useEffect(() => {
    // yieldCheck 상태에 따른 yieldCheckYN 업데이트
    if (yieldCheck !== undefined) {
      if (yieldCheck === null || yieldCheck.ispush === '0') {
        setYieldCheckYN(false);
      } else if (yieldCheck.ispush === '1') {
        setYieldCheckYN(true);
      }
    }
  }, [yieldCheck]);

  // 전체 발전소
  const numPlants = Array.isArray(selectPlant) ? selectPlant.length : 0;

  usePageLogRecord('pushSettings', plant.plantkey);

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        plantkey={plant.plantkey}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'flex',
          },
        }}
      >
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <StyledBreadcrumb
            label="PUSH 알람 설정"
            icon={<AppSettingsAltIcon fontSize="small" color="primary" />}
          />
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: {
            xs: 'flex',
          },
          my: 2,
          gap: 1,
          mt: isDesktop ? 2.5 : 7.5,
        }}
        justifyContent="space-between"
      >
        {selectPlant.length > 1 ? (
          <>
            {numPlants > 11 ? (
              <Autocomplete
                disableClearable
                id="combo-box-demo"
                options={selectPlant}
                getOptionLabel={(option) => option.plantname || ''}
                value={
                  selectPlant.find((option) => option.plantkey === plant.plantkey) ||
                  selectPlant.find(
                    (option) => option.plantkey === parseInt(localStorage.getItem('selectPlantkey'), 10)
                  ) ||
                  selectPlant[0]
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    const selectedPlant = selectPlant.find((option) => option.plantkey === newValue.plantkey);
                    selectPlantChangeHandler(selectedPlant);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="발전소" variant="outlined" sx={{ bgcolor: '#fff' }} />
                )}
                sx={{ width: isDesktop ? '45%' : '100%' }}
              />
            ) : (
              <FormControl sx={{ width: isDesktop ? '45%' : '100%', bgcolor: '#fff' }}>
                <InputLabel>발전소</InputLabel>
                <Select
                  label="발전소"
                  value={
                    selectPlant.find((option) => option.plantkey === plant.plantkey)?.plantkey ||
                    selectPlant.find(
                      (option) => option.plantkey === parseInt(localStorage.getItem('selectPlantkey'), 10)
                    )?.plantkey ||
                    selectPlant[0]?.plantkey
                  }
                  onChange={(e) => {
                    const selectedPlant = selectPlant.find((option) => option.plantkey === e.target.value);
                    selectPlantChangeHandler(selectedPlant);
                  }}
                >
                  {selectPlant.map((option) => (
                    <MenuItem key={option.plantkey} value={option.plantkey}>
                      {option.plantname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </>
        ) : (
          <Typography variant="subtitle1">{plant.plantname}</Typography>
        )}
      </Box>
      {isDesktop ? (
        <WebPushSetting
          errCodes={errCodes}
          setErrCodes={setErrCodes}
          yieldCheckYN={yieldCheckYN}
          setYieldCheckYN={setYieldCheckYN}
          saveCheckInfoClickHandler={saveCheckInfoClickHandler}
        />
      ) : (
        <MobilePushSetting
          errCodes={errCodes}
          setErrCodes={setErrCodes}
          yieldCheckYN={yieldCheckYN}
          setYieldCheckYN={setYieldCheckYN}
          saveCheckInfoClickHandler={saveCheckInfoClickHandler}
        />
      )}
    </>
  );
}
