import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import DomainAddIcon from '@mui/icons-material/DomainAdd';

// 사용자 메뉴 발전소 및 RTU 등록 컴포넌트
export default function AddPlantDetail({ addPlantClickHandler }) {
  return (
    <List component="nav">
      <ListItemButton onClick={addPlantClickHandler}>
        <ListItemIcon>
          <DomainAddIcon />
        </ListItemIcon>
        <ListItemText primary="발전소 및 RTU 등록" />
      </ListItemButton>
    </List>
  );
}
