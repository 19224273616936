import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';

export default function LoginMng() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');
  const pwd = searchParams.get('pwd');
  const page = searchParams.get('page');

  let from = page !== null ? '/' + page : '/';

  const loginRequest = useBackendV2();

  const submitHandler = async () => {
    if (id === null || pwd === null) {
      alert('오류가 발생했습니다.');
      navigate('/login02');
    }

    try {
      const requestConfig = {
        method: 'POST',
        body: {
          id: id,
          password: pwd,
          smtype: 0,
        },
      };

      const result = await loginRequest.sendRequest(
        config.backend.endPoint.authentication.smlogin,
        requestConfig,
        false
      );
      authCtx.login(result.data);
      navigate(from, { replace: true, state: { value: 0, subValue: 0 } });
    } catch (error) {
      console.error(error);
      if (error.message === 'INVALID_CREDENTIAL' || error.message === 'INVALID_LOGIN_INFO') {
        alert('아이디 또는 비밀번호가 일치하지 않습니다.');
        navigate('/login02');
      } else if (error.message === 'INVALID_USER_USE') {
        alert('미사용으로 등록된 아이디입니다.');
        navigate('/login02');
      } else {
        alert('오류가 발생하였습니다.');
        navigate('/login02');
      }
    }
  };

  useEffect(() => {
    if (id !== null && pwd !== null) {
      submitHandler();
    } else {
      alert('오류가 발생했습니다.');
      navigate('/login02');
    }
  }, []);

  return;
}
