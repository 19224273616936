import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';

// 사용자 메뉴 push 설정정보 컴포넌트
export default function PushSettingDetail({ pushSettingClickHandler }) {
  return (
    <List component="nav">
      <ListItemButton onClick={pushSettingClickHandler}>
        <ListItemIcon>
          <AppSettingsAltIcon />
        </ListItemIcon>
        <ListItemText primary="PUSH 알람 설정" />
      </ListItemButton>
    </List>
  );
}
