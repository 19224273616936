import { Box, Typography } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CallIcon from '@mui/icons-material/Call';
import FaxIcon from '@mui/icons-material/Fax';
import useResponsive from '../../hooks/useResponsive';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

export default function CustomerCallServiceGuideModal({ open, onClose }) {
  const isDesktop = useResponsive('up', 'lg');

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <>
      <CustomModal
        open={open}
        onClose={closeModalHandler}
        sx={{ maxHeight: '95%', width: { xs: '95%', md: 550 } }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <SupportAgentIcon style={{ fontSize: '34px' }} />
          <Typography variant="h5">고객센터 안내</Typography>
        </Box>
        <hr />
        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
          <CallIcon sx={{ mr: 0.5 }} />
          <Typography variant="h6">문의전화:</Typography>
          {isDesktop ? (
            <Typography variant="h6" sx={{ ml: 0.5 }}>
              1566-6403
            </Typography>
          ) : (
            <Typography variant="h6" sx={{ ml: 0.5 }}>
              <a href="tel:1566-6403" style={{ textDecoration: 'none', color: 'inherit' }}>
                1566-6403
              </a>
            </Typography>
          )}
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
          <FaxIcon sx={{ mr: 0.5 }} />
          <Typography variant="h6">팩스:</Typography>
          <Typography variant="h6" sx={{ ml: 0.5 }}>
            02-6053-6415
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: 2, mb: 2 }}>
          <ForwardToInboxIcon sx={{ mr: 1 }} />
          <Typography variant="h6">이메일:</Typography>
          <Typography variant="h6" sx={{ ml: 0.5 }}>
            am@qubesoft.kr
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
          <CallIcon sx={{ mr: 0.5 }} />
          <Typography variant="h6">카코인버터 A/S 문의:</Typography>
          {isDesktop ? (
            <Typography variant="h6" sx={{ ml: 0.5 }}>
              070-4070-1004
            </Typography>
          ) : (
            <Typography variant="h6" sx={{ ml: 0.5 }}>
              <a href="tel:070-4070-1004" style={{ textDecoration: 'none', color: 'inherit' }}>
                070-4070-1004
              </a>
            </Typography>
          )}
        </Box>
        <hr />
        <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
          <Typography variant="subtitle1">경기도 오산시 외삼미로15번길 54-2, 큐브빌딩 (외삼미동)</Typography>
        </Box>
        <Box display="flex" alignItems="center" sx={{ mt: 0 }}>
          <Typography variant="subtitle2">(구주소: 경기도 오산시 외삼미동 459-111)</Typography>
        </Box>
      </CustomModal>
    </>
  );
}
