import { Box, Grid, Typography, styled } from '@mui/material';
import MainCard from '../../../theme/MainCard';
import { useEffect, useState } from 'react';
import PlantCapactiyCheckModal from './PlantCapactiyCheckModal';

// 개별발전소 발전시간 웹 뷰 컴포넌트
export default function WebWidgetTimeOfDevelopment({ total, color = 'primary' }) {
  const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette[color].lighter,
  }));

  const [infintyCheck, setInfinityCheck] = useState(false);

  const closeModal = () => {
    setInfinityCheck(false);
  };

  useEffect(() => {
    if (infintyCheck === false && total === Infinity) {
      setInfinityCheck(true);
    }
  }, [total]);

  return (
    <>
      <CardWrapper border={false} content={false} sx={{ height: 230 }}>
        <Box sx={{ p: 2 }}>
          <Grid container direction="column">
            <Grid item>
              <Typography variant="h5" sx={{ color: '#fff' }}>
                발전 시간
              </Typography>
            </Grid>
            <br />
            <br />
            <Grid item sx={{ mt: 2 }}>
              <Typography display="flex" justifyContent="end" variant="h2" sx={{ color: '#fff' }}>
                {total}
              </Typography>
            </Grid>
            <Grid item>
              <Typography display="flex" justifyContent="end" variant="h4" sx={{ color: '#fff' }}>
                hr
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </CardWrapper>
      {infintyCheck && <PlantCapactiyCheckModal open={infintyCheck} onClose={closeModal} />}
    </>
  );
}
