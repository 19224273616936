import { Avatar, Box, Card, Chip, Collapse, Divider, Stack, Tooltip, Typography } from '@mui/material';
import {
  PiCloudDuotone,
  PiCloudFogDuotone,
  PiCloudLightningDuotone,
  PiCloudMoonDuotone,
  PiCloudRainDuotone,
  PiCloudSnowDuotone,
  PiCloudSunDuotone,
  PiMoonStarsDuotone,
  PiSunDuotone,
} from 'react-icons/pi';
import useResponsive from '../../../hooks/useResponsive';
import { useContext, useEffect, useState } from 'react';
import PlantSettingModal from '../../Setting/PlantSetting/PlantSettingModal';
import SubscriptionGuideModal from '../SubscriptionGuideModal';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import EntirePlantIvtInfo from './EntirePlantIvtInfo';
import EntirePlantDetaile from './EntirePlantDetail';
import UseCustomSort from '../../../hooks/useCustomSort';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import AuthContext from '../../../store/auth-context';

const weatherIconMap = {
  '001-1': <PiSunDuotone style={{ fontSize: '2rem', color: 'red', marginRight: 3 }} />,
  '001-2': <PiMoonStarsDuotone style={{ fontSize: '2rem', color: '#D5C450', marginRight: 3 }} />,
  '002-1': <PiCloudSunDuotone style={{ fontSize: '2rem', color: '#999999', marginRight: 3 }} />,
  '002-2': <PiCloudMoonDuotone style={{ fontSize: '2rem', color: '#999999', marginRight: 3 }} />,
  '003': <PiCloudDuotone style={{ fontSize: '2rem', color: '#999999', marginRight: 3 }} />,
  '004': <PiCloudRainDuotone style={{ fontSize: '2rem', color: '#8490e1', marginRight: 3 }} />,
  '005': <PiCloudFogDuotone style={{ fontSize: '2rem', color: '#999999', marginRight: 3 }} />,
  '006': <PiCloudSnowDuotone style={{ fontSize: '2rem', color: '#9B9CA2', marginRight: 3 }} />,
  '007': <PiCloudLightningDuotone style={{ fontSize: '2rem', color: '#D3A612', marginRight: 3 }} />,
};

// 전체 대시보드 인버터 이상 리스트 구성 컴포넌트
export default function EntirePlantIvtNoGenerateInfoCard({
  plantkey,
  plantName,
  address,
  nodata,
  capacity,
  rsInverters,
  subscribeday,
  setPage,
  page,
}) {
  const isDesktop = useResponsive('up', 'lg');
  const authCtx = useContext(AuthContext);

  const [todayWeather, setTodayWeather] = useState('');

  const [expanded, setExpanded] = useState(true);

  const [openPlantsetting, setOpenPlantSetting] = useState(false);
  const [openWeatherInfoModal, setOpenWeatherInfoModal] = useState(false);
  const [openSubscriptionGuideModal, setOpenSubscriptionGuideModal] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [haveCctv, setHaveCctv] = useState(false);

  const backendRequest = useBackendV2();

  const fetchWeatherObservation = async () => {
    try {
      const weatherObservationRequestConfig = {
        method: 'POST',
        body: {
          address: address,
        },
      };
      const weatherObservationResult = await backendRequest.sendRequest(
        config.backend.endPoint.envs.weather,
        weatherObservationRequestConfig
      );

      if (weatherObservationResult.data !== null) {
        setTodayWeather(weatherObservationResult.data);
      } else {
        alert('오류가 발생하였습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchHaveCctv = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.cctvs.haveCCTV}/${plantkey}`
      );

      if (data === 0) {
        setHaveCctv(false);
      } else {
        setHaveCctv(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const moveEachPlantHandler = () => {
    setPage((prevPage) => ({
      ...prevPage,
      pagetype: 'each',
      plantkey: plantkey,
      plantName: plantName,
      plantAddress: address,
    }));
    localStorage.setItem('selectPlantkey', plantkey);
  };

  const showPlantSettingHandler = () => {
    setOpenPlantSetting(true);
  };
  const closePlantSettingHandler = () => {
    setOpenPlantSetting(false);
  };

  const showWeatherInfoModalHandler = () => {
    setOpenWeatherInfoModal(true);
  };
  const closeWeatherInfoModalHandler = () => {
    setOpenWeatherInfoModal(false);
  };

  // 만료 발전소 상품 및 구독 라이선스 입력 페이지 안내 모달
  const openSubscriptionGuideModalHandler = () => {
    setOpenSubscriptionGuideModal(true);
  };
  const closeSubscriptionGuideModalHandler = () => {
    setOpenSubscriptionGuideModal(false);
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // 현재출력
  const totalPac = rsInverters.reduce((accumulator, inverter) => {
    // generatestatus가 0이 아닌 경우에만 pac를 더합니다.
    if (inverter.generatestatus !== 0) {
      accumulator += inverter.pac;
    }
    return accumulator;
  }, 0);

  const totalPacKw = totalPac / 1000;

  const sortedRsInvertersData = UseCustomSort(rsInverters, 'invertername');

  let entirePlantIvtInfo;

  if (sortedRsInvertersData != null) {
    entirePlantIvtInfo = sortedRsInvertersData
      .filter((d) => d.ivtNodata === 1 || d.generatestatus === 0) // ivtNodata가 1과 generatestatus가 0인 것들만 필터링
      .map((d) => (
        <EntirePlantIvtInfo
          key={d.serialnum}
          iac={d.iac}
          invertername={d.invertername}
          pac={d.pac}
          serialnum={d.serialnum}
          uac={d.uac}
          nodata={nodata}
          ivtNodata={d.ivtNodata}
          generatestatus={d.generatestatus}
          dlStatus={d.dlStatus}
          isDesktop={isDesktop}
        />
      ));
  }

  useEffect(() => {
    fetchWeatherObservation();
    fetchHaveCctv();
  }, []);

  const renderCCTVIcon = () => {
    let showCCTVIcon = false;

    if (authCtx.isAndroid) {
      const match = navigator.userAgent.match(/AndroidV(\d+,\d+|\d+)/);
      if (match) {
        const android = match[1];
        const appVer = android.includes(',') ? android.split(',')[1] : null;
        if (appVer && parseInt(appVer) >= 9) {
          showCCTVIcon = true;
        }
      }
    } else if (authCtx.isiOS) {
      const match = navigator.userAgent.match(/IOSV(\d+,\d+|\d+)/);
      if (match) {
        const ios = match[1];
        const appVer = ios.includes(',') ? ios.split(',')[1] : null;
        if (appVer && parseInt(appVer) >= 16) {
          showCCTVIcon = true;
        }
      }
    }

    return showCCTVIcon ? (
      <Tooltip title="CCTV" arrow>
        <VideocamOutlinedIcon sx={{ mb: 0.15 }} />
      </Tooltip>
    ) : null;
  };
  return (
    <>
      <Box sx={{ pb: 0, mb: 1.5 }}>
        <Card
          variant="outlined"
          onClick={isDesktop ? handleDrawerOpen : moveEachPlantHandler}
          sx={{
            padding: {
              xs: 0.5,
              sm: 2,
            },
            borderRadius: '14px',
            boxShadow: '0px 3px 12px #00000014',
            '&:hover': { borderColor: 'blue', borderWidth: '2px' },
          }}
        >
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            width="100%"
            spacing={2}
          >
            <Stack
              sx={{
                padding: {
                  xs: 1,
                  sm: 0,
                },
              }}
              flex={1}
            >
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <div>
                  <Box display="flex">
                    <Typography
                      variant="subtitle1"
                      sx={{ cursor: isDesktop ? 'pointer' : 'cursor', alignContent: 'center' }}
                    >
                      {subscribeday > -90 ? (
                        subscribeday <= 0 ? (
                          <Chip
                            label={`서비스 만료 D${subscribeday}`}
                            color="error"
                            variant="outlined"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation(); // 클릭 이벤트가 부모 요소로 전파되지 않도록 함
                              openSubscriptionGuideModalHandler();
                            }}
                            sx={{ mr: 0.5, mb: 0.5 }}
                          />
                        ) : (
                          <Chip
                            label="서비스 만료"
                            color="error"
                            variant="contained"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation(); // 클릭 이벤트가 부모 요소로 전파되지 않도록 함
                              openSubscriptionGuideModalHandler();
                            }}
                            sx={{ mr: 0.5, mb: 0.5 }}
                          />
                        )
                      ) : null}
                      <span onClick={isDesktop ? handleDrawerOpen : moveEachPlantHandler}>{plantName}</span>
                    </Typography>
                    {haveCctv && renderCCTVIcon()}
                  </Box>
                  <Typography
                    color="primary"
                    variant="subtitle2"
                    display="flex"
                    alignItems="center"
                    sx={{ mt: 1 }}
                  >
                    <LocationOnIcon />
                    {address}
                  </Typography>
                </div>
                <Stack sx={{ flexGrow: 1, textAlign: 'right' }} spacing={0}>
                  <Box display="flex" justifyContent="end">
                    <Avatar sx={{ width: '22px', height: '22px', mb: 1, bgcolor: '#1981E7' }}>
                      <ExpandMoreIcon
                        onClick={(e) => {
                          e.stopPropagation(); // 클릭 이벤트가 부모 요소로 전파되지 않도록 함
                          handleExpandClick();
                        }}
                        sx={{
                          color: '#fff',
                          cursor: 'pointer',
                          transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                        }}
                      />
                    </Avatar>
                  </Box>
                  <Typography variant="body2">
                    현재 출력 :
                    <strong style={{ marginLeft: 2 }}>
                      {rsInverters.some((inverter) => inverter.ivtNodata === 0) ? totalPacKw.toFixed(1) : 0}kW
                    </strong>
                  </Typography>
                  {expanded ? (
                    <>
                      <Typography variant="body2">
                        설비 용량 :
                        <strong style={{ marginLeft: 2 }}>
                          {rsInverters.some((inverter) => inverter.ivtNodata === 0) ? capacity : 0}kW
                        </strong>
                      </Typography>
                    </>
                  ) : null}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                      mb: 1,
                    }}
                  >
                    {todayWeather !== '' && weatherIconMap[todayWeather.v_img]}
                    <Typography variant="subtitle2">
                      {todayWeather !== '' ? todayWeather.v_temp : null}°C
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
              <Stack spacing={0} direction="row">
                <SettingsOutlinedIcon
                  sx={{
                    color: '#808080',
                    cursor: isDesktop ? 'pointer' : 'cursor',
                    marginLeft: 'auto',
                    mt: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // 클릭 이벤트가 부모 요소로 전파되지 않도록 함
                    showPlantSettingHandler();
                  }}
                />
              </Stack>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <Box>{entirePlantIvtInfo}</Box>
              </Collapse>
            </Stack>
          </Stack>
        </Card>
      </Box>
      <EntirePlantDetaile
        page={page}
        plantkey={plantkey}
        plantName={plantName}
        address={address}
        subscribeday={subscribeday}
        rsInverters={rsInverters}
        todayWeather={todayWeather}
        moveEachPlantHandler={moveEachPlantHandler}
        openSubscriptionGuideModalHandler={openSubscriptionGuideModalHandler}
        showWeatherInfoModalHandler={showWeatherInfoModalHandler}
        showPlantSettingHandler={showPlantSettingHandler}
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      {openPlantsetting && (
        <PlantSettingModal
          plantkey={plantkey}
          plantName={plantName}
          open={openPlantsetting}
          onClose={closePlantSettingHandler}
        />
      )}
      {openSubscriptionGuideModal && (
        <SubscriptionGuideModal
          plantkey={plantkey}
          plantName={plantName}
          open={openSubscriptionGuideModal}
          onClose={closeSubscriptionGuideModalHandler}
        />
      )}
    </>
  );
}
