import { Button } from '@mui/material';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { useContext } from 'react';
import AuthContext from '../../store/auth-context';

export default function OrderReceiptButton({ orderid, method }) {
  const authCtx = useContext(AuthContext);

  const { isAndroid, isiOS } = authCtx;

  const backendRequest = useBackendV2();

  const openOrderReceiptButtonClickHandler = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.orders.receipt}/${orderid}`
      );

      if (isAndroid) {
        window.QWebApp.locationHref(data.receiptUrl);
      } else if (isiOS) {
        window.webkit.messageHandlers.locationHref.postMessage(data.receiptUrl);
      } else {
        window.open(data.receiptUrl, '_blank');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  return (
    <>
      {method === '계좌이체' ? (
        <>
          <Button
            variant="contained"
            size="small"
            onClick={openOrderReceiptButtonClickHandler}
            sx={{ mt: 1.5, bgcolor: 'navy', '&:hover': { bgcolor: 'navy' } }}
          >
            현금 영수증 확인
          </Button>
        </>
      ) : method === '카드' || '간편결제' ? (
        <>
          <Button
            variant="contained"
            size="small"
            onClick={openOrderReceiptButtonClickHandler}
            sx={{ mt: 1.5, bgcolor: 'purple', '&:hover': { bgcolor: 'purple' } }}
          >
            매출전표 확인
          </Button>
        </>
      ) : null}
    </>
  );
}
