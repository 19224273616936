import useResponsive from '../../../hooks/useResponsive';
import HGroupChart from '../../Charts/Statistics/Hour/HGroupChart';
import HourGroupStatisticsTable from './HourGroupStatisticsTable';

export default function HourGroupStatistics({ data, chartView }) {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <>
      {isDesktop ? (
        <>
          <HGroupChart data={data} isDesktop={isDesktop} />
          {data.length === 0 ? null : <HourGroupStatisticsTable data={data} isDesktop={isDesktop} />}
        </>
      ) : chartView ? (
        <HGroupChart data={data} isDesktop={isDesktop} />
      ) : (
        <HourGroupStatisticsTable data={data} isDesktop={isDesktop} />
      )}
    </>
  );
}
