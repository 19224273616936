import { BottomNavigation, BottomNavigationAction, Box, Paper, useTheme } from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import AppsIcon from '@mui/icons-material/Apps';
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import SpeedIcon from '@mui/icons-material/Speed';
import ConnectedTvIcon from '@mui/icons-material/ConnectedTv';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

// 모바일의 경우 화면 최하단에 위치한 메뉴 이동을 가능하게 하는 푸터 메뉴 컴포넌트
export default function Footer({ value, setValue, subValue, setSubValue }) {
  const navigate = useNavigate();
  const theme = useTheme();

  const moveDashboard = () => {
    setSubValue(0);
    localStorage.setItem('menuValue', 0);
    // localStorage.removeItem('selectPlantkey');
    navigate('/', { state: { subValue: 0 } });
  };
  const moveInverter = () => {
    setSubValue(0);
    localStorage.setItem('menuValue', 1);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.inverters, { state: { subValue: 0 } });
  };
  const moveStatistics = () => {
    setSubValue(0);
    localStorage.setItem('menuValue', 2);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.statistics, { state: { subValue: 0 } });
  };
  const moveAlarm = () => {
    setSubValue(0);
    localStorage.setItem('menuValue', 3);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.alarms, { state: { subValue: 0 } });
  };
  const moveBulletinboard = () => {
    setSubValue(0);
    localStorage.setItem('menuValue', 4);
    localStorage.setItem('menuSubValue', 0);
    localStorage.removeItem('selectPlantkey');
    navigate(config.frontend.bulletinboards, { state: { subValue: 0 } });
  };
  return (
    <>
      <Box>
        <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}>
          <BottomNavigation
            showLabels
            value={value}
            onChange={(event, newValue) => {
              setValue(newValue);
            }}
            sx={{
              paddingBottom: 0,
              borderColor: '#E1E1E1',
              // borderColor: theme.palette.background.default,
              borderWidth: '1px',
              borderStyle: 'solid',
              display: 'flex',
              // backgroundColor: '#ECEFF2',
              backgroundColor: theme.palette.background.default,
            }}
          >
            <BottomNavigationAction
              label="모니터링"
              icon={
                <ConnectedTvIcon
                // sx={{ color: value === 0 ? '#23a23a' : '#fff' }}
                />
              }
              onClick={moveDashboard}
              sx={{ left: 22 }}
            />
            <BottomNavigationAction
              label="인버터"
              icon={<AppsIcon />}
              onClick={moveInverter}
              sx={{ left: 10 }}
            />
            <BottomNavigationAction label="통계" icon={<InsightsIcon />} onClick={moveStatistics} sx={{}} />
            <BottomNavigationAction
              label="경보"
              icon={<ReportGmailerrorredIcon />}
              onClick={moveAlarm}
              sx={{ right: 10 }}
            />
            <BottomNavigationAction
              label="게시판"
              icon={<DensityMediumIcon />}
              onClick={moveBulletinboard}
              sx={{ right: 22 }}
            />
          </BottomNavigation>
        </Paper>
      </Box>
    </>
  );
}
