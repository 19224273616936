import { useContext, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import AuthContext from './store/auth-context';
import PrivateOutlet from './components/Layout/PrivateOutlet';
import NotFound from './components/Error/NotFound';
import Join from './pages/Authentication/Join';
import DashboardPage from './pages/Dashboard/DashboardPage';
import menuConfigs from './config/menu';
import Login02 from './pages/Authentication/Login02';
import FindPwd from './pages/Authentication/FindPwd';
import LaunchScreen from './components/Layout/LaunchScreen';
import RequestBoardDetail from './components/Bulletinboard/RequestBoardDetail';
import RequestBoardAdd from './components/Bulletinboard/RequestBoardAdd';
import NoticeBoardDetail from './components/Bulletinboard/NoticeBoardDetail';
import PaymentPageSuccess from './pages/Payment/PaymentPageSuccess';
import PaymentPageFail from './pages/Payment/PaymentPageFail';
import ShopProductCardDetail from './components/Products/ShopProductCardDetail';
import UpdateUser from './components/User/UpdateUser';
import OrderInfoPage from './pages/Order/OrderInfoPage';
import FineId from './pages/Authentication/FindId';
import ConnectionPlantPage from './pages/Plant/ConnectionPlantPage';
import RequestConnectionPlant from './components/Plant/ConnectionPlantPageComponent/RequestConnectionPlant';
import AcceptConnectionPlant from './components/Plant/ConnectionPlantPageComponent/AcceptConnectionPlant';
import UserDeleteAnnouncementPage from './pages/User/UserDeleteAnnouncementPage';
import InquiryBoardDetail from './components/Bulletinboard/InquiryBoardDetail';
import InquiryBoardAdd from './components/Bulletinboard/InquiryBoardAdd';
import LoginMng from './pages/Authentication/LoginMng';
import AllStatusPlatePage from './pages/StatusPlate/AllStatusPlatePage';
import StatusPlatePage from './pages/StatusPlate/StatusPlatePage';

// React 애플리케이션의 전반적인 구조를 구성하고 라우팅을 설정하며 인증을 관리하고 로딩 상태를 처리하는 중심 컴포넌트
export default function App() {
  const authCtx = useContext(AuthContext);

  const loggedInUserRole = authCtx?.currentUser?.role;

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  return (
    <div>
      {loading ? (
        <LaunchScreen />
      ) : (
        <Routes>
          <Route path="/login02" element={<Login02 />} />
          <Route path="/loginMng" element={<LoginMng />} />
          <Route path="/join" element={<Join />} />
          <Route path="/updateUser" element={<UpdateUser />} />
          <Route path="/findPwd" element={<FindPwd />} />
          <Route path="/findId" element={<FineId />} />
          <Route path="/paymentPageSuccess" element={<PaymentPageSuccess />} />
          <Route path="/paymentPageFail" element={<PaymentPageFail />} />
          <Route path="/deleteAnnouncement" element={<UserDeleteAnnouncementPage />} />
          <Route path="/allStatusPlate" element={<AllStatusPlatePage />} />
          <Route path="/statusPlatePage" element={<StatusPlatePage />} />
          <Route path="/" element={<PrivateOutlet />}>
            <Route path="" element={<DashboardPage />} />
            {menuConfigs.map((mc, index) => (
              <Route
                key={index}
                path={mc.path}
                element={mc.roles.includes(loggedInUserRole) ? mc.component : <Navigate to="/" />}
              />
            ))}
            <Route path="/bulletinboards/request/detail/:seq" element={<RequestBoardDetail />} />
            <Route path="/bulletinboards/request/add" element={<RequestBoardAdd />} />
            <Route path="/bulletinboards/notice/detail/:seq" element={<NoticeBoardDetail />} />
            <Route path="/bulletinboards/inquiry/detail/:seq" element={<InquiryBoardDetail />} />
            <Route path="/bulletinboards/inquiry/add" element={<InquiryBoardAdd />} />
            <Route path="/products/detail/:id" element={<ShopProductCardDetail />} />
            <Route path="/orders/info/:id" element={<OrderInfoPage />} />
            <Route path="/connectionplants/accept" element={<ConnectionPlantPage />} />
            <Route path="/connectionplants/request" element={<RequestConnectionPlant />} />
            <Route path="/connectionplants/accept/:radioValue/:ukey" element={<AcceptConnectionPlant />} />
          </Route>
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      )}
    </div>
  );
}
