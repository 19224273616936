import { Box, Button, ButtonGroup, Card, CardContent, Divider, Grid, Typography } from '@mui/material';
import { convertFormatStringToYMD } from '../../../utils/dateUtil';
import { fShortenNumber } from '../../../utils/formatNumber';
import { useEffect, useState } from 'react';

// 개별 발전소 웹뷰 오늘의 SMP 데이터 테이블
export default function WebWidgetTodaySmpTable({ todaySmp, address }) {
  const [selectPlace, setSelectPlace] = useState(false);

  useEffect(() => {
    if (address?.substring(0, 5).includes('제주')) {
      setSelectPlace(true);
    } else {
      setSelectPlace(false);
    }
  }, []);

  return (
    <>
      <Card
        sx={{
          borderRadius: '15px',
          boxShadow: '5px 6px 12px 5px #00000014',
          height: 289,
          p: 0,
          bgcolor: '#F1F6F9',
          border: '0.2px solid #B0B0B0',
        }}
      >
        <Typography variant="subtitle1" sx={{ color: '#000', ml: 0.5, mt: 1, mb: 1.5 }}>
          오늘의 SMP
        </Typography>
        <CardContent sx={{ bgcolor: '#fff' }}>
          <Box display="flex" justifyContent="space-between" sx={{ mt: -0.5 }}>
            <Box>
              <Typography variant="subtitle2" color="inherit">
                거래일:{' '}
                {todaySmp.length === 0
                  ? null
                  : selectPlace
                  ? convertFormatStringToYMD(todaySmp[1].regdate)
                  : convertFormatStringToYMD(todaySmp[0].regdate)}
              </Typography>
            </Box>
            <ButtonGroup sx={{ mr: -1.25 }}>
              <Button
                onClick={() => setSelectPlace(false)}
                variant={selectPlace ? 'outlined' : 'contained'}
                size="small"
              >
                육지
              </Button>
              <Button
                onClick={() => setSelectPlace(true)}
                variant={selectPlace ? 'contained' : 'outlined'}
                size="small"
              >
                제주
              </Button>
            </ButtonGroup>
          </Box>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
              <Grid container direction="column">
                <Grid item>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="subtitle1" color="inherit">
                        가중평균
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                          <Typography variant="subtitle1" color="inherit">
                            {todaySmp.length === 0
                              ? null
                              : selectPlace
                              ? fShortenNumber(todaySmp[1].avgval)
                              : fShortenNumber(todaySmp[0].avgval)}
                            원
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
              <Grid container direction="column">
                <Grid item>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="subtitle1" color="inherit">
                        최고가
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                          <Typography variant="subtitle1" color="inherit">
                            {todaySmp.length === 0
                              ? null
                              : selectPlace
                              ? fShortenNumber(todaySmp[1].maxval)
                              : fShortenNumber(todaySmp[0].maxval)}
                            원
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
              <Grid container direction="column">
                <Grid item>
                  <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item>
                      <Typography variant="subtitle1" color="inherit">
                        최저가
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                          <Typography variant="subtitle1" color="inherit">
                            {todaySmp.length === 0
                              ? null
                              : selectPlace
                              ? fShortenNumber(todaySmp[1].minval)
                              : fShortenNumber(todaySmp[0].minval)}
                            원
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}
