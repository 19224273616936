import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import '../../../css/App.css';
import { useEffect, useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

export default function MonthAllStatisticsTable({ data, isDesktop }) {
  const columns = [
    { field: 'groupname', headerName: '발전소명', width: 150 },
    { field: 'flocaldate', headerName: '월(month)', width: 60 },
    { field: 'totalyield', headerName: '발전량(kWh)', width: 200 },
    { field: 'generateHour', headerName: '발전시간(월평균)(h)', width: 100 },
  ];

  const moblieColumns = [
    { field: 'flocaldate', headerName: '월(month)', width: 60 },
    { field: 'totalyield', headerName: '발전량(kWh)', width: 200 },
    { field: 'generateHour', headerName: '발전시간(월평균)(h)', width: 100 },
  ];

  const [total, setTotal] = useState({
    totalyield: 0,
    generateMonth: 0,
  });

  const calculateTotal = () => {
    let totalyieldTotal = 0;
    let generateMonthTotal = 0;
    let validGenerateMonthCount = 0;

    data.forEach((row) => {
      totalyieldTotal += row.totalyield ?? 0;
      if (row.totalyield !== null) {
        generateMonthTotal += row.generateHour ?? 0;
        validGenerateMonthCount += 1;
      }
    });

    setTotal({
      totalyield: totalyieldTotal,
      generateMonth: validGenerateMonthCount > 0 ? generateMonthTotal / validGenerateMonthCount : 0,
    });
  };

  useEffect(() => {
    calculateTotal();
  }, [data]);

  return (
    <>
      <Card
        sx={{
          mt: 1,
          borderRadius: 0,
          boxShadow: '0px 3px 12px #00000014',
        }}
      >
        <StyledTableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {isDesktop
                  ? columns.map((column) => (
                      <StyledTableCell
                        key={column.field}
                        align="center"
                        style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                        className={column.headerName === '발전소명' ? 'sticky' : ''}
                      >
                        {column.headerName}
                      </StyledTableCell>
                    ))
                  : moblieColumns.map((column) => (
                      <StyledTableCell
                        key={column.field}
                        align="center"
                        style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                      >
                        {column.headerName}
                      </StyledTableCell>
                    ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row.id} index={index}>
                  {isDesktop
                    ? columns.map((column) => (
                        <TableCell
                          key={column.field}
                          align="center"
                          style={{
                            minWidth: column.width,
                            backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                            borderRight: '1px solid #d0d0d0',
                          }}
                          className={column.headerName === '발전소명' ? 'sticky' : ''}
                        >
                          {column.field === 'flocaldate'
                            ? row.flocaldate.split('-')[1]
                            : row[column.field] !== null && row[column.field] !== undefined
                            ? row.totalyield === null && column.field === 'generateHour'
                              ? '--'
                              : row[column.field] !== 0
                              ? column.field === 'totalyield'
                                ? row[column.field].toLocaleString()
                                : row[column.field]
                              : '0'
                            : '--'}
                        </TableCell>
                      ))
                    : moblieColumns.map((column) => (
                        <TableCell
                          key={column.field}
                          align="center"
                          style={{
                            minWidth: column.width,
                            backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                            borderRight: '1px solid #d0d0d0',
                          }}
                        >
                          {column.field === 'flocaldate'
                            ? row.flocaldate.split('-')[1]
                            : row[column.field] !== null && row[column.field] !== undefined
                            ? row.totalyield === null && column.field === 'generateHour'
                              ? '--'
                              : row[column.field] !== 0
                              ? column.field === 'totalyield'
                                ? row[column.field].toLocaleString()
                                : row[column.field]
                              : '0'
                            : '--'}
                        </TableCell>
                      ))}
                </TableRow>
              ))}
              <TableRow>
                {isDesktop
                  ? columns.map((column, columnIndex) => (
                      <TableCell
                        key={column.field}
                        align="center"
                        style={{
                          minWidth: column.width,
                          borderRight: '1px solid #d0d0d0',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          backgroundColor: '#1769aa',
                          color: 'white',
                        }}
                        className={columnIndex === 0 ? 'sticky' : ''}
                      >
                        {columnIndex === 0
                          ? '합계'
                          : column.field === 'totalyield'
                          ? total.totalyield.toLocaleString()
                          : column.field === 'generateHour'
                          ? total.generateMonth.toFixed(2)
                          : ''}
                      </TableCell>
                    ))
                  : moblieColumns.map((column, columnIndex) => (
                      <TableCell
                        key={column.field}
                        align="center"
                        style={{
                          minWidth: column.width,
                          borderRight: '1px solid #d0d0d0',
                          fontSize: '16px',
                          fontWeight: 'bold',
                          backgroundColor: '#1769aa',
                          color: 'white',
                        }}
                        className={columnIndex === 0 ? 'sticky' : ''}
                      >
                        {columnIndex === 0
                          ? '합계'
                          : column.field === 'totalyield'
                          ? total.totalyield.toLocaleString()
                          : column.field === 'generateHour'
                          ? total.generateMonth.toFixed(2)
                          : ''}
                      </TableCell>
                    ))}
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Card>
    </>
  );
}
