import { Box, Button, Grid, TextField } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import useResponsive from '../../../hooks/useResponsive';
import useMediaQuery from '@mui/material/useMediaQuery';

// 발전소 및 RTU 등록 RTU 번호 입력 컴포넌트
export default function SearchRtuNumber({ RtuNumberInput, haveRtuNumber, findRtuNumberClickHandler }) {
  const isDesktop = useResponsive('up', 'lg');

  // 모바일 반응형 디자인을 위한 상수 선언
  const isSmallDevice = useMediaQuery('(min-width: 455px) and (max-width: 1199px)');
  const isVerySmallDevice = useMediaQuery('(max-width: 454px)');

  return (
    <>
      {isDesktop ? (
        <>
          <TextField
            hiddenLabel
            size="small"
            variant="outlined"
            onChange={RtuNumberInput.valueChangeHandler}
            onBlur={RtuNumberInput.inputBlurHandler}
            value={RtuNumberInput.value.replace(/\s/g, '').trim()}
            sx={{ width: '45%' }}
          />
          {haveRtuNumber && <CheckCircleOutlineIcon sx={{ ml: 1, color: 'green' }} />}
          <Button
            variant="outlined"
            onClick={findRtuNumberClickHandler}
            sx={{ ml: haveRtuNumber === true ? 2 : 6 }}
          >
            번호 확인
          </Button>
        </>
      ) : (
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={isVerySmallDevice ? 10 : isSmallDevice ? 5.5 : 10}>
            <TextField
              hiddenLabel
              size="small"
              variant="outlined"
              onChange={RtuNumberInput.valueChangeHandler}
              onBlur={RtuNumberInput.inputBlurHandler}
              value={RtuNumberInput.value.replace(/\s/g, '').trim()}
              sx={{
                width: '100%',
              }}
            />
          </Grid>
          <Grid item xs={isVerySmallDevice ? 2 : isSmallDevice ? 0 : 2}>
            {haveRtuNumber ? (
              <CheckCircleOutlineIcon
                sx={{
                  fontSize: 20,
                  color: 'green',
                  alignItems: 'center',
                  textAlign: 'center',
                }}
              />
            ) : (
              <Box sx={{ ml: 2.6 }} />
            )}
          </Grid>
          <Grid item xs={isVerySmallDevice ? 12 : 0}>
            <Button
              size="medium"
              variant="outlined"
              onClick={findRtuNumberClickHandler}
              sx={{
                width: '100%',
              }}
            >
              번호 확인
            </Button>
          </Grid>
        </Grid>
      )}
    </>
  );
}
