import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

export default function AlarmCodeAnalysisWeb({ data }) {
  const columns = [
    { field: 'invertername', headerName: '인버터명', width: 200 },
    { field: 'errnum', headerName: '경보 코드', width: 200 },
    { field: 'errstatus', headerName: '경보 상태', width: 200 },
    { field: 'errmsg', headerName: '경보 내역', width: 200 },
    { field: 'cnt', headerName: '경보 발생 횟수', width: 200 },
  ];

  return (
    <>
      <Card
        sx={{
          mt: 2,
          borderRadius: 0,
          boxShadow: '0px 3px 12px #00000014',
        }}
      >
        <StyledTableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.field}
                    align="center"
                    style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                  >
                    {column.headerName}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={index} index={index}>
                  {columns.map((column) => (
                    <TableCell
                      key={column.field}
                      align="center"
                      style={{
                        minWidth: column.width,
                        backgroundColor:
                          row.invertername === '합계' ? '#FFC107' : index % 2 === 0 ? '#fff' : '#f0f0f0',
                        fontWeight: row.invertername === '합계' ? 'bold' : 'normal',
                        borderRight: '1px solid #d0d0d0',
                      }}
                    >
                      {row[column.field]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Card>
    </>
  );
}
