import useResponsive from '../../../hooks/useResponsive';
import DAllChart from '../../Charts/Statistics/Day/DAllChart';
import DayAllStatisticsTable from './DayAllStatisticsTable';

export default function DayAllStatistics({ data, chartView }) {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <>
      {isDesktop ? (
        <>
          <DAllChart data={data} isDesktop={isDesktop} />
          {data.length === 0 ? null : <DayAllStatisticsTable data={data} isDesktop={isDesktop} />}
        </>
      ) : chartView ? (
        <DAllChart data={data} isDesktop={isDesktop} />
      ) : (
        <DayAllStatisticsTable data={data} isDesktop={isDesktop} />
      )}
    </>
  );
}
