// 데이터 배열을 특정 키를 기준으로 정렬하는 함수
const useCustomSort = (data, key) => {
  return data?.sort((a, b) => {
    // 비교할 두 값을 가져옴
    const nameA = a[key];
    const nameB = b[key];

    // 가져온 값들을 숫자로 변환
    const numA = parseInt(nameA);
    const numB = parseInt(nameB);

    // 두 값이 모두 숫자인 경우
    if (!isNaN(numA) && !isNaN(numB)) {
      return numA - numB;
    }
    // 첫 번째 값만 숫자인 경우 숫자가 아닌 값은 숫자보다 작은 것으로 간주하여 앞으로 배치
    else if (!isNaN(numA)) {
      return -1;
    }
    // 두 번째 값만 숫자인 경우 숫자가 아닌 값은 숫자보다 작은 것으로 간주하여 뒤로 배치
    else if (!isNaN(numB)) {
      return 1;
    }
    // 둘 다 숫자가 아닌 경우 문자열을 비교하여 정렬
    else {
      return nameA.localeCompare(nameB);
    }
  });
};

export default useCustomSort;
