import MainCard from '../../theme/MainCard';
import { Box, Avatar, Typography } from '@mui/material';
import TodayIcon from '@mui/icons-material/Today';

// 현황판 오늘날짜 컴포넌트
export default function PlantImageCard_03({ nowDateValue, isIpad, isNexthub }) {
  return (
    <>
      <MainCard
        sx={{
          // bgcolor: '#0059B2',
          borderRadius: '15px',
          boxShadow: '0px 3px 100px #00000014',
          height: 95,
        }}
      >
        <Box display="flex" alignItems="center">
          <Avatar
            variant="rounded"
            sx={{
              bgcolor: '#E2E6FB',
              mr: 1,
              width: 30,
              height: 30,
            }}
          >
            <TodayIcon sx={{ color: '#0059B2' }} />
          </Avatar>
          <Typography variant="h5" sx={{ color: '#000' }}>
            오늘 날짜
          </Typography>
        </Box>
        <Box display="flex" justifyContent="end" alignItems="center">
          <Typography variant={isIpad || isNexthub ? 'h5' : 'h4'} sx={{ color: '#000' }}>
            {nowDateValue}
          </Typography>
        </Box>
      </MainCard>
    </>
  );
}
