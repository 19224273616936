import { Box, Modal } from '@mui/material';

export default function CustomModal({ children, open, onClose, sx }) {
  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          borderRadius: '8px',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: { xs: '95%', md: 500 },
          maxHeight: '100%',
          bgcolor: 'background.paper',
          p: 4,
          overflowX: 'hidden',
          overflowY: 'auto',
          ...sx,
        }}
      >
        {children}
      </Box>
    </Modal>
  );
}
