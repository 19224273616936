import { Box } from '@mui/material';
import { useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import AcbVcbDeviceControl from './DeviceControl/AcbVcbDeviceControl';
import InverterDeviceControl from './DeviceControl/InverterDeviceControl';
import UseCustomSort from '../../hooks/useCustomSort';
import config from '../../config';
import useInterval from '../../hooks/use-interval';

// 장비제어 컴포넌트
export default function DeviceControl({ plantState, subscribeday, graceperiodDay }) {
  const [acbVcbInfo, setAcbVcbInfo] = useState([]);
  const [inverterInfo, setInverterInfo] = useState([]);

  const backendRequest = useBackendV2();

  const fetchGetAcbVcbInfo = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.acbvcbs.extReal}/${plantState.plantkey}`
      );

      // 가져온 데이터를 'acbname' 키를 기준으로 정렬
      const sortedData = UseCustomSort(data, 'acbname');

      setAcbVcbInfo(sortedData);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchGetInverterInfo = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.inverters.extReal}/${plantState.plantkey}`
      );

      const sortedData = UseCustomSort(data, 'invertername');

      setInverterInfo(sortedData);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    if (plantState.plantkey !== '') {
      fetchGetAcbVcbInfo();
      fetchGetInverterInfo();
    }
  }, [plantState]);

  const fetchPollingInterval = 60000;

  useInterval(() => {
    if (plantState.plantkey !== '') {
      fetchGetAcbVcbInfo();
      fetchGetInverterInfo();
    }
  }, fetchPollingInterval);

  return (
    <Box sx={{ filter: subscribeday - graceperiodDay > 0 ? 'blur(5px)' : '', mt: 2 }}>
      {acbVcbInfo.length === 0 ? null : (
        <Box sx={{ mb: 2 }}>
          <AcbVcbDeviceControl
            acbVcbInfo={acbVcbInfo}
            plantkey={plantState.plantkey}
            pname={plantState.plantname}
          />
        </Box>
      )}
      <InverterDeviceControl
        inverterInfo={inverterInfo}
        plantkey={plantState.plantkey}
        pname={plantState.plantname}
      />
    </Box>
  );
}
