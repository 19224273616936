import { Box, Grid, Typography, styled } from '@mui/material';
import MainCard from '../../../theme/MainCard';

// 개별발전소 일일발전량 웹 뷰 컴포넌트
export default function WebWidgetEachSummary({ total, color = 'primary' }) {
  const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette[color].lighter,
  }));

  return (
    <CardWrapper border={false} content={false} sx={{ height: 230 }}>
      <Box sx={{ p: 2 }}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h5" sx={{ color: '#fff' }}>
              일일 발전량
            </Typography>
          </Grid>
          <br />
          <br />
          <Grid item sx={{ mt: 2 }}>
            <Typography display="flex" justifyContent="end" variant="h2" sx={{ color: '#fff' }}>
              {total}
            </Typography>
          </Grid>
          <Grid item>
            <Typography display="flex" justifyContent="end" variant="h4" sx={{ color: '#fff' }}>
              kWh
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </CardWrapper>
  );
}
