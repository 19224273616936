import { Box, Card, Typography, styled } from '@mui/material';
import MainCard from '../../../theme/MainCard';

// 개별 대시보드 발전소 정보 표시 웹 - 기상 관측 정보 컴포넌트
export default function EachPlantWeatherObserveInfoWeb({ todayEnvData }) {
  const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
  }));

  return (
    <>
      <CardWrapper sx={{ height: 218 }}>
        <Typography variant="subtitle2" color="primary" gutterBottom>
          기상 관측 정보
        </Typography>
        <Box sx={{ mt: 2 }}>
          <Box display="flex">
            <Card
              sx={{
                border: '3px solid #FFF',
                width: '50%',
                borderTop: 'none',
                borderBottom: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderRadius: '0',
                background: 'rgba(0, 0, 0, 0.005)',
                bgcolor: '#F6F6F6',
              }}
            >
              <Typography
                sx={{ fontSize: 14, ml: 1 }}
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                경사 일사량
              </Typography>
              <Box display="flex" sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 20, mr: 1 }} variant="subtitle2" color="error" gutterBottom>
                  {todayEnvData.length === 0 || todayEnvData === undefined || todayEnvData[0].trad < 0
                    ? null
                    : todayEnvData[0].trad}
                </Typography>
                <Typography sx={{ fontSize: 16, mr: 2 }} variant="subtitle2" gutterBottom>
                  W/㎡
                </Typography>
              </Box>
            </Card>
            <Card
              sx={{
                border: '3px solid #FFF',
                width: '50%',
                borderTop: 'none',
                borderBottom: 'none',
                borderRight: 'none',
                borderRadius: '0',
                background: 'rgba(0, 0, 0, 0.005)',
                bgcolor: '#F6F6F6',
              }}
            >
              <Typography
                sx={{ fontSize: 14, ml: 1 }}
                variant="subtitle2"
                color="text.secondary"
                gutterBottom
              >
                수평 일사량
              </Typography>
              <Box display="flex" sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 20, mr: 1 }} variant="subtitle2" color="error" gutterBottom>
                  {todayEnvData.length === 0 || todayEnvData === undefined || todayEnvData[0].trad < 0
                    ? null
                    : todayEnvData[0].srad}
                </Typography>
                <Typography sx={{ fontSize: 16, mr: 2 }} variant="subtitle2" gutterBottom>
                  W/㎡
                </Typography>
              </Box>
            </Card>
          </Box>
          <Box display="flex">
            <Card
              sx={{
                border: '3px solid #FFF',
                width: '50%',
                borderBottom: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderRadius: '0',
                background: 'rgba(0, 0, 0, 0.005)',
                bgcolor: '#F6F6F6',
              }}
            >
              <Typography
                sx={{ fontSize: 14, ml: 1, mt: 1 }}
                color="text.secondary"
                variant="subtitle2"
                gutterBottom
              >
                판넬 온도
              </Typography>
              <Box display="flex" sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 20, mr: 1 }} variant="subtitle2" color="error" gutterBottom>
                  {todayEnvData.length === 0 || todayEnvData === undefined || todayEnvData[0].trad < 0
                    ? null
                    : todayEnvData[0].otemp}
                </Typography>
                <Typography sx={{ fontSize: 16, mr: 2 }} variant="subtitle2" gutterBottom>
                  ℃
                </Typography>
              </Box>
            </Card>
            <Card
              sx={{
                border: '3px solid #FFF',
                width: '50%',
                borderBottom: 'none',
                borderRight: 'none',
                borderRadius: '0',
                background: 'rgba(0, 0, 0, 0.005)',
                bgcolor: '#F6F6F6',
              }}
            >
              <Typography
                sx={{ fontSize: 14, ml: 1, mt: 1 }}
                color="text.secondary"
                variant="subtitle2"
                gutterBottom
              >
                외기 온도
              </Typography>
              <Box display="flex" sx={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography sx={{ fontSize: 20, mr: 1 }} variant="subtitle2" color="error" gutterBottom>
                  {todayEnvData.length === 0 || todayEnvData === undefined || todayEnvData[0].trad < 0
                    ? null
                    : todayEnvData[0].ptemp}
                </Typography>
                <Typography sx={{ fontSize: 16, mr: 2 }} variant="subtitle2" gutterBottom>
                  ℃
                </Typography>
              </Box>
            </Card>
          </Box>
        </Box>
      </CardWrapper>
    </>
  );
}
