import { Box, IconButton, Tooltip } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PlagiarismIcon from '@mui/icons-material/Plagiarism';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../store/auth-context';
import { useContext } from 'react';

// 웹 헤더 우측 메뉴 컴포넌트
export default function HeaderRightMenu() {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const openShowStatusAllPlateClickHandler = () => {
    localStorage.removeItem('selectPlantkey');
    navigate('/allStatusPlate');
  };

  const openShowStatusPlateClickHandler = () => {
    navigate('/statusPlatePage');
  };

  return (
    <>
      {authCtx?.currentUser.role === '소유주' ? null : (
        <Box display="flex" alignItems="center" sx={{ mr: 0 }}>
          <Tooltip title="전체현황" arrow>
            <IconButton
              onClick={openShowStatusAllPlateClickHandler}
              sx={{
                p: 0,
                mr: 1,
              }}
            >
              <PlagiarismIcon fontSize="large" sx={{ color: '#fff' }} />
            </IconButton>
          </Tooltip>
        </Box>
      )}
      <Box display="flex" alignItems="center" sx={{ mr: 1 }}>
        <Tooltip title="현황판" arrow>
          <IconButton
            onClick={openShowStatusPlateClickHandler}
            sx={{
              p: 0,
              mr: 1,
            }}
          >
            <AssignmentIcon fontSize="large" sx={{ color: '#fff' }} />
          </IconButton>
        </Tooltip>
      </Box>
    </>
  );
}
