import { Box, Card, Divider, Typography } from '@mui/material';
import qubePannel from '../../../assets/EtcImg/qubePannel.png';
import { convertFormatStringToYMD, formatTime } from '../../../utils/dateUtil';

// 개별 대시보드 발전소 정보 표시 웹 - 발전소 설비용량 및 수신시간 컴포넌트
export default function EachPlantInfoWeb({ page, nowTime, basePlantInfo }) {
  return (
    <>
      <Card
        sx={{
          display: 'flex',
          borderColor: '#fff',
          borderRadius: '16px',
          boxShadow: '5px 6px 12px 5px #00000014',
          position: 'relative',
          height: 230,
        }}
      >
        <img
          src={
            basePlantInfo[0]?.imgkey !== null &&
            basePlantInfo[0]?.imgkey !== '' &&
            basePlantInfo[0]?.imgkey !== undefined
              ? `https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=${process.env.REACT_APP_DOMAIN}&id=${basePlantInfo[0].imgkey}`
              : qubePannel
          }
          style={{ width: '100%', height: '100%' }}
        />
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            height: '100%',
            background: 'rgba(0, 0, 0, 0.5)', // 음영 스타일
            padding: 2,
          }}
        >
          <Typography variant="subtitle2" sx={{ color: 'white', fontSize: '1rem' }}>
            설비 용량
          </Typography>
          <Box display="flex">
            <Typography variant="subtitle2" sx={{ color: 'gold', fontSize: '1.25rem', mr: 0.5 }}>
              {basePlantInfo.length === 0 ? null : basePlantInfo[0].capacity}
            </Typography>
            <Typography variant="subtitle2" sx={{ color: 'white', mt: 0.8 }}>
              kW
            </Typography>
          </Box>
          <Divider sx={{ mt: 1, mb: 1, borderBottom: '1px solid #c9c9c9' }} />
          <Typography variant="subtitle2" sx={{ color: 'white', mr: 0.5 }}>
            오늘 날짜
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'white' }}>
            {convertFormatStringToYMD(nowTime)}
          </Typography>
          <Divider sx={{ mt: 1, mb: 1, borderBottom: '1px solid #c9c9c9' }} />
          <Typography variant="subtitle2" sx={{ color: 'white', mr: 0.5 }}>
            현재 시간
          </Typography>
          <Typography variant="subtitle2" sx={{ color: 'white' }}>
            {formatTime(nowTime)}
          </Typography>
        </Box>
      </Card>
    </>
  );
}
