import { Box, Button, CardMedia, Checkbox, FormControlLabel, Typography } from '@mui/material';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import CustomModal from '../UI/CustomModal';
import useResponsive from '../../hooks/useResponsive';

// 서비스 이용 기간 만료 안내 모달 컴포넌트
export default function RegularPaymentCheckModal({
  open,
  onClose,
  checkSubscriptionDay,
  subscribeday,
  checkToShowPaymentGuideModal,
  setCheckToShowPaymentGuideModal,
  movePaymentPageClickHandler,
}) {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <>
      <CustomModal open={open} onClose={onClose} sx={{ width: { xs: '95%', md: 600 }, p: 1 }}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <NotificationImportantIcon />
          <Typography align="center" variant="h6">
            모니터링 서비스 이용 안내
          </Typography>
        </Box>
        <hr />
        <Box
          sx={{
            mt: 2,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
          }}
        >
          {subscribeday > 0 ? (
            <Box>
              <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>
                모니터링 서비스 이용 기간이 만료되었습니다.
              </Typography>
            </Box>
          ) : (
            <Box>
              <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>
                모니터링 서비스 이용 기간이 만료될 예정입니다.
              </Typography>
            </Box>
          )}
          <br />
          <CardMedia
            component="img"
            image={
              process.env.REACT_APP_DOMAIN === 'SC'
                ? `https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=SC&id=448`
                : `https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=SC&id=443`
            }
          />
          <Button
            type="button"
            variant="contained"
            onClick={onClose}
            size="large"
            sx={{ width: '50%', mt: 1 }}
          >
            확인
          </Button>
        </Box>
        <hr />
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <FormControlLabel
            control={
              <Checkbox onChange={() => setCheckToShowPaymentGuideModal(!checkToShowPaymentGuideModal)} />
            }
            label={
              checkSubscriptionDay > -90
                ? checkSubscriptionDay >= -7
                  ? '오늘 하루 더이상 보지 않기'
                  : '일주일 동안 보지 않기'
                : null
            }
          />
        </Box>
      </CustomModal>
      {/* <PaymentPage open={checkPaymentStatus} onClose={closePaymentPageClickHandler} /> */}
    </>
  );
}
