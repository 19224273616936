import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import MainCard from '../../../theme/MainCard';

// DNE 스타일 개별 대시보드 총발전량 컴포넌트
export default function AppWidgetEachDevelopment_DNE({ total, color = 'primary' }) {

  const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette[color].lighter,
  }));

  return (
    <CardWrapper border={false} content={false}>
      <Box sx={{ p: 2 }}>
        <Grid container direction="column">
          <Grid item display="flex" alignItems="center">
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 600,
                color: '#fff',
                display: 'flex',
                mt: 1,
              }}
            >
              총 발전량
            </Typography>
            <Typography sx={{ fontSize: '0.75rem', fontWeight: 600, color: '#fff', mt: 1.2 }}>
              (MWh)
            </Typography>
          </Grid>
          <Grid item>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              sx={{ width: '100%' }}
            >
              <Typography
                sx={{
                  fontSize: '2rem',
                  fontWeight: 600,
                  color: '#fff',
                  textAlign: 'right',
                  width: '100%',
                }}
              >
                {(total * 0.001).toFixed(1)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </CardWrapper>
  );
}
