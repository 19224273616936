import { useState } from 'react';
import { Box, Button, Card, Checkbox, Divider, FormControlLabel } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';

export default function WebPushSetting({
  errCodes,
  setErrCodes,
  yieldCheckYN,
  setYieldCheckYN,
  saveCheckInfoClickHandler,
}) {
  // columns의 세부 기능 텍스트를 한글로 변환
  const localeText = {
    columnMenuSortAsc: '오름차순 정렬',
    columnMenuSortDesc: '내림차순 정렬',
    columnMenuUnsort: '정렬 초기화',
    columnMenuFilter: '필터',
    columnMenuHideColumn: '해당 열 숨기기',
    columnMenuManageColumns: '표 관리',
    filterPanelColumns: '선택한 열',
    filterPanelOperator: '검색 방법',
    filterPanelInputLabel: '입력 값',
    filterPanelInputPlaceholder: '입력 값',
    filterOperatorContains: '포함',
    filterOperatorEquals: '같음',
    filterOperatorStartsWith: '시작하는',
    filterOperatorEndsWith: '끝나는',
    filterOperatorIsEmpty: '비어있음',
    filterOperatorIsNotEmpty: '비어있지 않음',
    filterOperatorIsAnyOf: '다음 중 하나',
    columnsPanelTextFieldLabel: '열 제목 검색',
    columnsPanelTextFieldPlaceholder: '열 제목 입력',
    columnsPanelHideAllButton: '모두 숨기기',
    columnsPanelShowAllButton: '모두 표시',
    footerRowSelected: (count) => `${count}행 선택됨`,
    footerTotalRows: '전체 행',
    footerTotalVisibleRows: (visibleCount, totalCount) => `${totalCount}행 중 ${visibleCount}행 표시 중`,
  };

  // const [checkCode, setCheckCode] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const selectAllHandler = () => {
    // 전체 선택 또는 해제 상태를 토글
    setSelectAll((prev) => !prev);

    // 모든 항목의 isSet 값을 변경
    const updatedCheckCode = errCodes.map((row) => ({ ...row, isSet: selectAll ? '0' : '1' }));
    setErrCodes(updatedCheckCode);
  };

  const checkboxClickHandler = (row, value) => {
    // 기존의 isSet 값이 '1'이면 '0', '0'이면 '1'로 바꾸기
    const updatedIsSet = value === '1' ? '0' : '1';

    // 현재 상태의 복사본을 만들어서 변경된 값을 적용
    const updatedCheckCode = [...errCodes];
    const rowIndex = updatedCheckCode.findIndex((item) => item.id === row.id);
    if (rowIndex !== -1) {
      updatedCheckCode[rowIndex] = { ...row, isSet: updatedIsSet };
      setErrCodes(updatedCheckCode);
    }
  };

  const checkYieldYN = () => {
    setYieldCheckYN(!yieldCheckYN);
  };

  const columns = [
    {
      field: 'isSet',
      headerName: '선택',
      flex: 0.1,
      resizable: true,
      renderHeader: () => (
        <span>
          &nbsp;&nbsp;<b>선택</b>
        </span>
      ),
      renderCell: (params) => (
        <Checkbox
          checked={params.value === '1'}
          onChange={() => checkboxClickHandler(params.row, params.value)}
        />
      ),
    },
    // 컬럼을 움직일 수 없음 -> "@mui/x-data-grid": "^7.3.2"으로 업데이트 필요
    { field: 'ivtAcb', headerName: '장비 타입', flex: 0.115, resizable: true },
    { field: 'iType', headerName: '인버터 타입', flex: 0.129, resizable: true },
    { field: 'errNUM', headerName: '경보 코드', flex: 0.115, resizable: true },
    { field: 'errStatus', headerName: '경보 상태', flex: 0.2, resizable: true },
    { field: 'errMsg', headerName: '전송 메시지', flex: 0.35, resizable: true },
  ];

  return (
    <>
      <Card variant="outlined" sx={{ mt: 1, borderRadius: '14px', boxShadow: '0px 3px 12px #00000014' }}>
        <Box display="flex" alignContent="center" justifyContent="space-between" sx={{ p: 2 }}>
          <Box display="flex">
            <FormControlLabel
              control={<Checkbox onClick={selectAllHandler} />}
              label={selectAll ? '전체 해제' : '전체 선택'}
              labelPlacement="start"
            />
            <Divider orientation="vertical" flexItem sx={{ ml: 2 }} />
            <FormControlLabel
              control={<Checkbox onClick={checkYieldYN} checked={yieldCheckYN} />}
              label="일일 발전량 전송"
              labelPlacement="start"
            />
          </Box>
          <Button variant="contained" onClick={saveCheckInfoClickHandler}>
            저장
          </Button>
        </Box>
        <DataGrid
          rows={errCodes}
          columns={columns}
          pagination
          pageSize={10}
          localeText={localeText}
          slotProps={{
            pagination: {
              labelRowsPerPage: '페이지 당 행 수',
              labelDisplayedRows: ({ from, to, count }) => `${from}–${to} / 총 ${count}개`,
            },
          }}
        />
      </Card>
    </>
  );
}
