import { Avatar, Box, Divider, Popover, Typography, styled, useTheme } from '@mui/material';
import { useState } from 'react';
import {
  PiCloudDuotone,
  PiCloudFogDuotone,
  PiCloudLightningDuotone,
  PiCloudMoonDuotone,
  PiCloudRainDuotone,
  PiCloudSnowDuotone,
  PiCloudSunDuotone,
  PiMoonStarsDuotone,
  PiSunDuotone,
} from 'react-icons/pi';
import PlaceIcon from '@mui/icons-material/Place';
import Co2Icon from '@mui/icons-material/Co2';
import ForestIcon from '@mui/icons-material/Forest';
import { fNumberWithCommas } from '../../../utils/formatNumber';
import WeatherInfoModal from '../WeatherInfoModal';
import CloseIcon from '@mui/icons-material/Close';

const StyledRoot = styled('div')(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(30),
  height: theme.spacing(8),
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  paddingTop: theme.spacing(1.25),
  paddingBottom: theme.spacing(1.25),
  boxShadow: theme.customShadows.z20,
  backgroundColor: '#FAFBFE',
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 6.5,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 6.5,
  borderTopRightRadius: Number(theme.shape.borderRadius) * 6.5,
  borderBottomRightRadius: Number(theme.shape.borderRadius) * 6.5,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 1, boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', border: '2px solid #e0e0e0' },
  opacity: 0.4,
}));

const weatherIconMap = {
  '001-1': <PiSunDuotone style={{ fontSize: '2.8rem', color: 'red' }} />,
  '001-2': <PiMoonStarsDuotone style={{ fontSize: '2.8rem', color: '#D5C450' }} />,
  '002-1': <PiCloudSunDuotone style={{ fontSize: '2.8rem', color: '#999999' }} />,
  '002-2': <PiCloudMoonDuotone style={{ fontSize: '2.8rem', color: '#999999' }} />,
  '003': <PiCloudDuotone style={{ fontSize: '2.8rem', color: '#999999' }} />,
  '004': <PiCloudRainDuotone style={{ fontSize: '2.8rem', color: '#8490e1' }} />,
  '005': <PiCloudFogDuotone style={{ fontSize: '2.8rem', color: '#999999' }} />,
  '006': <PiCloudSnowDuotone style={{ fontSize: '2.8rem', color: '#9B9CA2' }} />,
  '007': <PiCloudLightningDuotone style={{ fontSize: '2.8rem', color: '#D3A612' }} />,
};

// 모바일 개별발전소 기상, 주소 및 탄소 나무 저감량 표시 컴포넌트
export default function EachPlantTodayObservationWidget({
  page,
  nowTime,
  roundedTotalGhgReduction,
  roundedTotalPineTree,
  todayWeather,
}) {
  const theme = useTheme();
  const [open, setOpen] = useState(null);

  const [openWeatherInfoModal, setOpenWeatherInfoModal] = useState(false);

  const colorInfo = 'info';
  const colorSuccess = 'success';

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const showWeatherInfoModalHandler = () => {
    setOpenWeatherInfoModal(true);
  };
  const closeWeatherInfoModalHandler = () => {
    setOpenWeatherInfoModal(false);
  };

  return (
    <>
      <StyledRoot onClick={handleOpen}>
        {todayWeather !== '' && weatherIconMap[todayWeather.v_img]}
      </StyledRoot>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            mt: 1.5,
            ml: 0.75,
            width: 400,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.4)',
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ mt: 0.5 }}>
          <Box display="flex" justifyContent="space-between">
            <Box display="flex">
              <Box onClick={showWeatherInfoModalHandler}>
                {todayWeather !== '' && weatherIconMap[todayWeather.v_img]}
              </Box>
              <Box sx={{ ml: 1, mt: 1 }}>
                <Typography color="#000" variant="h4">
                  {todayWeather !== '' ? todayWeather.v_temp : null}°C
                </Typography>
              </Box>
            </Box>
            <Box>
              <CloseIcon onClick={() => setOpen(null)} />
            </Box>
          </Box>
          <Box>
            <Typography variant="subtitle2" display="flex" alignItems="center" color="#0059B2">
              <PlaceIcon sx={{ color: 'blue' }} />
              {page.plantAddress}
            </Typography>
          </Box>
        </Box>
        <Divider sx={{ mt: 0.5 }} />
        <Box sx={{ mt: 2, mb: 1 }}>
          <Box display="flex" alignItems="center" alignContent="center">
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.largeAvatar,
                backgroundColor: theme.palette[colorInfo].darker,
                color: '#fff',
                width: 35,
                height: 35,
              }}
            >
              <Co2Icon fontSize="large" />
            </Avatar>
            <Typography variant="h4" sx={{ color: '#2b39b5', ml: 1 }}>
              {roundedTotalGhgReduction}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: '#000',
                ml: 0.5,
              }}
            >
              tCO2eq
            </Typography>
          </Box>
          <Divider sx={{ mt: 1, mb: 1 }} />
          <Box display="flex" alignItems="center" alignContent="center">
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.largeAvatar,
                backgroundColor: theme.palette[colorSuccess].darker,
                color: '#fff',
                width: 35,
                height: 35,
              }}
            >
              <ForestIcon />
            </Avatar>
            <Typography variant="h4" sx={{ color: '#246b18', ml: 1 }}>
              {fNumberWithCommas(roundedTotalPineTree * 1000)}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: '#000',
                ml: 0.5,
              }}
            >
              그루
            </Typography>
          </Box>
        </Box>
      </Popover>
      {openWeatherInfoModal && (
        <WeatherInfoModal
          open={openWeatherInfoModal}
          onClose={closeWeatherInfoModalHandler}
          page={page}
          nowTime={nowTime}
        />
      )}
    </>
  );
}
