import { Card, Typography, useMediaQuery } from '@mui/material';
import { schemeDark2 } from 'd3-scale-chromatic';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

export default function MInverterChart({ data, groupedData, chartData, isDesktop }) {
  const isIpad = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const isNexthub = useMediaQuery('(min-width: 1025px) and (max-width: 1366px)');

  const darkColors = schemeDark2;

  const formatXAxis = (tickItem) => {
    const month = tickItem.split('-')[1];
    return `${parseInt(month, 10)}월`; // "01" -> 1, "02" -> 2, 형식으로 수정
  };

  if (!chartData || !groupedData) {
    return null;
  }

  return (
    <Card sx={{ mt: 1, borderRadius: '14px', boxShadow: '0px 3px 12px #00000014' }}>
      <ResponsiveContainer width="100%" height={isDesktop ? 650 : isIpad ? 550 : isNexthub ? 550 : 400}>
        <ComposedChart width="100%" height="100%" data={chartData} margin={{ top: 60, bottom: 10 }}>
          <CartesianGrid stroke="#d1d1d1" vertical={false} />
          <XAxis
            dataKey="name"
            axisLine={false}
            tickFormatter={formatXAxis}
            tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
          />
          <YAxis
            tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
            label={{
              value: 'kWh',
              offset: 30,
              angle: 0,
              position: 'top',
              fontSize: 14,
              fontWeight: 'bold',
              fill: '#000',
            }}
          />
          <YAxis yAxisId="right" orientation="right" axisLine={false} />
          <Tooltip
            labelFormatter={formatXAxis}
            formatter={(value, name, props) => {
              if (name) {
                return [
                  <Typography key="tooltip-yield" variant="body1" fontWeight="bold">
                    {`${name} : ${value.toLocaleString()} kWh`}
                  </Typography>,
                ];
              }
              return [value, name];
            }}
          />
          <Legend
            wrapperStyle={{
              overflowX: 'auto',
              width: '100%',
              whiteSpace: 'nowrap',
            }}
          />
          {Object.keys(groupedData).map((groupName, index) => (
            <Line
              key={index}
              type="monotone"
              dataKey={groupName}
              name={groupName}
              stroke={darkColors[index % darkColors.length]}
            />
          ))}
        </ComposedChart>
      </ResponsiveContainer>
    </Card>
  );
}
