import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import { useEffect, useState } from 'react';
import useResponsive from '../../../hooks/useResponsive';
import JboxWeb from './JunctionBox/JboxWeb';
import JboxMobile from './JunctionBox/JboxMobile';
import JboxWebDetail from './JunctionBox/JboxWebDetail';
import useInterval from '../../../hooks/use-interval';

function getDevelopPercentColor(developPercent) {
  if (developPercent >= 1 && developPercent <= 30) {
    return '#F7B969';
  } else if (developPercent > 30 && developPercent <= 70) {
    return '#73E600';
  } else if (developPercent > 70 && developPercent <= 100) {
    return '#88BBEE';
  } else if (developPercent === 0 || null || undefined) {
    return '#FD251F';
  } else {
    return '#D0D0D0';
  }
}

// 접속반 컴포넌트
export default function ConnectBoardJbox({ plantkey, jboxDetail, setJboxDetail }) {
  const isDesktop = useResponsive('up', 'lg');

  const [jBoxsList, setJBoxsList] = useState();
  const [maxJuncboxchannelLength, setMaxJuncboxchannelLength] = useState(0);
  const [eachJboxData, setEachJboxData] = useState();

  const junctionBoxsRequest = useBackendV2();

  const fetchJunctionBoxs = async () => {
    try {
      const { data } = await junctionBoxsRequest.sendRequest(
        `${config.backend.endPoint.junctionboxs.real}/${plantkey}`
      );

      // jbname을 기준으로 배열 정렬
      const sortedData = data.sort((a, b) => a.jbname.localeCompare(b.jbname));

      // 차후 추가되는 값
      const additionalValue = { juncboxchannel: [] };

      // 가장 긴 juncboxchannel 배열의 길이 계산
      const maxLength = Math.max(...sortedData.map((item) => item.juncboxchannel.length));
      setMaxJuncboxchannelLength(maxLength);

      // 각 배열의 길이를 maxLength에 맞게 맞춰주고 추가되는 값 추가
      const adjustedData = sortedData.map((item) => {
        // 현재 채널의 길이와 부족한 길이 계산
        const missingLength = maxLength - item.juncboxchannel.length;

        // 각 채널에 developPercent 추가
        const adjustedChannels = item.juncboxchannel.map((channel) => {
          const developPercent =
            Math.round((channel.curt / (item.maxcurt / item.juncboxchannel.length)) * 10000) / 100;
          return { ...channel, developPercent };
        });

        // 부족한 길이만큼 빈 객체로 채워진 배열 추가
        const filledChannels = [...adjustedChannels, ...Array(missingLength).fill({})];
        return { ...item, juncboxchannel: filledChannels };
      });

      // 추가되는 값 추가
      adjustedData.forEach((item) => item.juncboxchannel.push(...additionalValue.juncboxchannel));

      setJBoxsList(adjustedData);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchPollingInterval = 60000;

  useInterval(() => {
    fetchJunctionBoxs();
  }, fetchPollingInterval);

  useEffect(() => {
    if (plantkey !== '') {
      fetchJunctionBoxs();
    }
  }, [plantkey]);

  useEffect(() => {
    // jBoxsList가 변경될 때 eachJboxData 업데이트
    const updateEachJboxData = () => {
      if (jBoxsList && eachJboxData) {
        const updatedData = jBoxsList.find((item) => item.jbname === eachJboxData.jbname);
        if (updatedData) {
          setEachJboxData((prevEachJboxData) => ({
            ...prevEachJboxData,
            ...updatedData,
          }));
        }
      }
    };

    // jBoxsList가 변경될 때 updateEachJboxData 함수 호출
    updateEachJboxData();
  }, [jBoxsList]);

  return (
    <>
      {isDesktop ? (
        !jboxDetail ? (
          <JboxWeb
            getDevelopPercentColor={getDevelopPercentColor}
            jBoxsList={jBoxsList}
            maxJuncboxchannelLength={maxJuncboxchannelLength}
            setEachJboxData={setEachJboxData}
            setJboxDetail={setJboxDetail}
          />
        ) : (
          <JboxWebDetail
            jBoxsList={jBoxsList}
            eachJboxData={eachJboxData}
            setEachJboxData={setEachJboxData}
            setJboxDetail={setJboxDetail}
          />
        )
      ) : jBoxsList ? (
        jBoxsList.map((column, index) => <JboxMobile key={index} data={column} />)
      ) : null}
    </>
  );
}
