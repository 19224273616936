import { Box, Card, Typography, useMediaQuery } from '@mui/material';
import {
  CartesianGrid,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';

export default function EHourChart({ data, envsetUse, isDesktop }) {
  const isIpad = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const isNexthub = useMediaQuery('(min-width: 1025px) and (max-width: 1366px)');

  const formatXAxis = (tickItem) => {
    const dateObject = new Date(tickItem);
    const formattedTime = dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

    return formattedTime;
  };

  const hasInvalidData = data.length === 0 || data.some((item) => item.trad < 0);

  return (
    <>
      {envsetUse === true ? (
        hasInvalidData ? (
          <Card
            variant="outlined"
            sx={{
              p: 1,
              mt: 1,
              borderRadius: '14px',
              boxShadow: '0px 3px 12px #00000014',
              height: 400,
            }}
          >
            {isDesktop ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3, mt: 16.5 }}>
                <DesktopAccessDisabledIcon sx={{ fontSize: 100 }} />
                <Box>
                  <Typography variant="h5">환경장비 고장</Typography>
                  <Typography variant="h5">문의 전화 : 1566-6403</Typography>
                </Box>
              </Box>
            ) : (
              <>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 11 }}>
                  <DesktopAccessDisabledIcon sx={{ fontSize: 100 }} />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                  <Typography variant="h5">환경장비 고장</Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Typography variant="h5">문의 전화 : 1566-6403</Typography>
                </Box>
              </>
            )}
          </Card>
        ) : (
          <Card sx={{ mt: 1, borderRadius: '14px', boxShadow: '0px 3px 12px #00000014' }}>
            <ResponsiveContainer width="100%" height={isDesktop ? 650 : isIpad ? 550 : isNexthub ? 550 : 400}>
              <ComposedChart width="100%" height="100%" data={data} margin={{ top: 60, bottom: 10 }}>
                <CartesianGrid stroke="#d1d1d1" vertical={false} />
                <XAxis
                  dataKey="localdate"
                  axisLine={false}
                  tickFormatter={formatXAxis}
                  tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
                />
                <YAxis
                  yAxisId="left"
                  axisLine={false}
                  tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
                  label={{
                    value: 'W/㎡',
                    offset: 30,
                    angle: 0,
                    position: 'top',
                    fontSize: 14,
                    fontWeight: 'bold',
                    fill: '#000',
                  }}
                />
                <YAxis
                  yAxisId="right"
                  orientation="right"
                  axisLine={false}
                  tick={{ fontSize: 14, fontWeight: 'bold', fill: '#000' }}
                  label={{
                    value: '℃',
                    offset: 30,
                    angle: 0,
                    position: 'top',
                    fontSize: 14,
                    fontWeight: 'bold',
                    fill: '#000',
                  }}
                />
                <Tooltip
                  labelFormatter={formatXAxis}
                  formatter={(value, name, props) => {
                    if (name === '판넬 온도') {
                      return [
                        <Typography key="tooltip-otemp" variant="body1" fontWeight="bold">
                          {`${name} : ${value.toLocaleString()} ℃`}
                        </Typography>,
                      ];
                    } else if (name === '외기 온도') {
                      return [
                        <Typography key="tooltip-ptemp" variant="body1" fontWeight="bold">
                          {`${name} :  ${value.toLocaleString()} ℃`}
                        </Typography>,
                      ];
                    } else if (name === '경사 일사량') {
                      return [
                        <Typography key="tooltip-trad" variant="body1" fontWeight="bold">
                          {`${name} :  ${value.toLocaleString()} W/㎡`}
                        </Typography>,
                      ];
                    } else if (name === '수평 일사량') {
                      return [
                        <Typography key="tooltip-srad" variant="body1" fontWeight="bold">
                          {`${name} :  ${value.toLocaleString()} W/㎡`}
                        </Typography>,
                      ];
                    }
                    return [value, name];
                  }}
                />
                <Legend
                  wrapperStyle={{
                    overflowX: 'auto',
                    width: '100%',
                    whiteSpace: 'nowrap',
                  }}
                />
                <Line
                  yAxisId="left"
                  name="경사 일사량"
                  type="monotone"
                  dataKey="trad"
                  stroke="#ff3300"
                  strokeWidth={1}
                />
                <Line
                  yAxisId="left"
                  name="수평 일사량"
                  type="monotone"
                  dataKey="srad"
                  stroke="#77CB2E"
                  strokeWidth={1}
                />
                <Line
                  yAxisId="right"
                  name="판넬 온도"
                  type="monotone"
                  dataKey="otemp"
                  stroke="#2065D1"
                  strokeWidth={1}
                />
                <Line
                  yAxisId="right"
                  name="외기 온도"
                  type="monotone"
                  dataKey="ptemp"
                  stroke="#c6cb2e "
                  strokeWidth={1}
                />
              </ComposedChart>
            </ResponsiveContainer>
          </Card>
        )
      ) : (
        <Card
          variant="outlined"
          sx={{
            p: 1,
            mt: 1,
            borderRadius: '14px',
            boxShadow: '0px 3px 12px #00000014',
            height: 400,
          }}
        >
          {isDesktop ? (
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3, mt: 16.5 }}>
              <DesktopAccessDisabledIcon sx={{ fontSize: 100 }} />
              <Box>
                <Typography variant="h5">환경장비 없음</Typography>
                <Typography variant="h5">문의 전화 : 1566-6403</Typography>
              </Box>
            </Box>
          ) : (
            <>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 11 }}>
                <DesktopAccessDisabledIcon sx={{ fontSize: 100 }} />
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                <Typography variant="h5">환경장비 없음</Typography>
              </Box>
              <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant="h5">문의 전화 : 1566-6403</Typography>
              </Box>
            </>
          )}
        </Card>
      )}
    </>
  );
}
