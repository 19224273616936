import { Box, Button, ButtonGroup, CardContent, Divider, Grid, Typography } from '@mui/material';
import MainCard from '../../../theme/MainCard';
import { convertFormatStringToYMD } from '../../../utils/dateUtil';
import { fNumberWithCommas } from '../../../utils/formatNumber';
import { useEffect, useState } from 'react';

// 개별 대시보드 REC 상태 컴포넌트
export default function TodayRecTable({ todayRec, address }) {
  const [selectPlace, setSelectPlace] = useState(false);

  useEffect(() => {
    if (address?.substring(0, 5).includes('제주')) {
      setSelectPlace(true);
    } else {
      setSelectPlace(false);
    }
  }, []);

  return (
    <>
      <MainCard content={false} sx={{ mt: 1 }}>
        <CardContent>
          <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mb: 1 }}>
            <Typography color="primary" fontWeight="bold" fontSize="0.89rem">
              오늘의 REC
            </Typography>
            <Typography fontWeight="bold" fontSize="0.79rem" sx={{ color: '#707070' }}>
              ※ 매주 화, 목 10:00~16:00 개장
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" sx={{ mt: 0 }}>
            <Box>
              <Typography variant="subtitle2" color="inherit">
                거래일:{' '}
                {todayRec.length === 0
                  ? null
                  : selectPlace
                  ? convertFormatStringToYMD(todayRec[1].regdate)
                  : convertFormatStringToYMD(todayRec[0].regdate)}
              </Typography>
            </Box>
            <ButtonGroup>
              <Button
                onClick={() => setSelectPlace(false)}
                variant={selectPlace ? 'outlined' : 'contained'}
                size="small"
              >
                육지
              </Button>
              <Button
                onClick={() => setSelectPlace(true)}
                variant={selectPlace ? 'contained' : 'outlined'}
                size="small"
              >
                제주
              </Button>
            </ButtonGroup>
          </Box>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
              <Grid item xs={12} sx={{ mt: 1 }}>
                <Grid container spacing={0}>
                  <Grid item lg={5.875} md={5.875} sm={5.875} xs={5.875}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" color="inherit">
                        거래량
                      </Typography>
                      <Typography variant="subtitle1" color="inherit">
                        {todayRec.length === 0
                          ? null
                          : selectPlace
                          ? fNumberWithCommas(todayRec[1].dealcount)
                          : fNumberWithCommas(todayRec[0].dealcount)}
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={0.25} md={0.25} sm={0.25} xs={0.25}>
                    <Box display="flex" justifyContent="center">
                      <Typography color="#e0e0e0">|</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={5.875} md={5.875} sm={5.875} xs={5.875}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" color="inherit">
                        평균가
                      </Typography>
                      <Typography variant="subtitle1" color="inherit">
                        {todayRec.length === 0
                          ? null
                          : selectPlace
                          ? fNumberWithCommas(todayRec[1].avgprice)
                          : fNumberWithCommas(todayRec[0].avgprice)}
                        원
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
              <Grid container direction="column">
                <Grid container spacing={0}>
                  <Grid item lg={5.875} md={5.875} sm={5.875} xs={5.875}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" color="inherit">
                        최고가
                      </Typography>
                      <Typography variant="subtitle1" color="inherit">
                        {todayRec.length === 0
                          ? null
                          : selectPlace
                          ? fNumberWithCommas(todayRec[1].maxprice)
                          : fNumberWithCommas(todayRec[0].maxprice)}
                        원
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={0.25} md={0.25} sm={0.25} xs={0.25}>
                    <Box display="flex" justifyContent="center">
                      <Typography color="#e0e0e0">|</Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={5.875} md={5.875} sm={5.875} xs={5.875}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" color="inherit">
                        최저가
                      </Typography>
                      <Typography variant="subtitle1" color="inherit">
                        {todayRec.length === 0
                          ? null
                          : selectPlace
                          ? fNumberWithCommas(todayRec[1].minprice)
                          : fNumberWithCommas(todayRec[0].minprice)}
                        원
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
              <Grid container direction="column">
                <Grid container spacing={0}>
                  <Grid item lg={5.875} md={5.875} sm={5.875} xs={5.875}>
                    <Box display="flex" justifyContent="space-between">
                      <Typography variant="subtitle1" color="inherit">
                        종가
                      </Typography>
                      <Typography variant="subtitle1" color="inherit">
                        {todayRec.length === 0
                          ? null
                          : selectPlace
                          ? fNumberWithCommas(todayRec[1].lastprice)
                          : fNumberWithCommas(todayRec[0].lastprice)}
                        원
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item lg={0.25} md={0.25} sm={0.25} xs={0.25}>
                    <Box display="flex" justifyContent="center">
                      <Typography color="#e0e0e0">|</Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Divider sx={{ my: 1 }} />
            </Grid>
          </Grid>
        </CardContent>
      </MainCard>
    </>
  );
}
