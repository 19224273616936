import { Box, Card, Typography } from '@mui/material';

// 개별 발전소 웹뷰 마지막 수신시간 컴포넌트
export default function WebWidgetLastDevelopmentTime({ lastReceptionDate }) {
  return (
    <Card
      sx={{
        // bgcolor: '#0059B2',
        borderRadius: '15px',
        boxShadow: '5px 6px 12px 5px #00000014',
        height: 85,
        p: 1,
      }}
    >
      <Box display="flex" sx={{ mt: 0.5 }}>
        <Typography variant="subtitle1">마지막 통신 시간</Typography>
      </Box>
      <Box display="flex" justifyContent="end" sx={{ mt: 1.1 }}>
        <Typography variant="h6">{lastReceptionDate}</Typography>
      </Box>
    </Card>
  );
}
