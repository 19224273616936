import { useNavigate } from 'react-router-dom';
import CustomModal from '../UI/CustomModal';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import { Box, Button, ButtonGroup, Checkbox, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';

// 이용 약관 동의 모달 컴포넌트
export default function PersonalTermsConfirmModal({ open, onClose, authCtx, personalTermsData }) {
  const navigate = useNavigate();

  const [agreed1, setAgreed1] = useState(false);
  const [agreed2, setAgreed2] = useState(false);
  const [allAgreed, setAllAgreed] = useState(false);

  const [termsContent1, setTermsContent1] = useState();
  const [termsContent2, setTermsContent2] = useState();

  const backendRequest = useBackendV2();

  const fetchServiceTerms = async () => {
    try {
      const serviceUseResult = await backendRequest.sendRequest(
        `${config.backend.endPoint.terms.getContent}/1`,
        null,
        false
      );

      const collectUserInfoResult = await backendRequest.sendRequest(
        `${config.backend.endPoint.terms.getContent}/2`,
        null,
        false
      );

      setTermsContent1(serviceUseResult.data.contents);
      setTermsContent2(collectUserInfoResult.data.contents);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const handleAgreeCheckboxChange = (event, checkboxNumber) => {
    if (checkboxNumber === 1) {
      setAgreed1(event.target.checked);
    } else if (checkboxNumber === 2) {
      setAgreed2(event.target.checked);
    }
  };

  const handleAllAgreeCheckboxChange = (event) => {
    const checked = event.target.checked;
    setAllAgreed(checked);
    setAgreed1(checked);
    setAgreed2(checked);
  };

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  const handleDisagree = () => {
    const firstConfirm = window.confirm(
      '해당 약관에 동의하지 않을 경우, 서비스를 이용하실 수 없습니다. 계속하시겠습니까?'
    );

    if (firstConfirm) {
      const secondConfirm = window.confirm('약관 미동의로 인해 로그아웃 처리됩니다.');

      if (secondConfirm) {
        const androidFunc = () => window.QWebApp.pushDeactive('logout');
        const iOSFunc = () => window.webkit.messageHandlers.pushDeactive.postMessage('logout');

        if (authCtx.isAndroid) {
          androidFunc();
        } else if (authCtx.isiOS) {
          iOSFunc();
        }

        localStorage.clear();
        authCtx.logout();
        navigate('/login02');
      }
    }
  };

  const handleAgree = async () => {
    if (!agreed1 && !agreed2) {
      alert('서비스 이용약관과 개인정보 처리방침을 동의해 주세요.');
    } else if (!agreed1) {
      alert('서비스 이용약관을 동의해 주세요.');
    } else if (!agreed2) {
      alert('개인정보 처리방침을 동의해 주세요.');
    } else {
      try {
        const rqAgreeTermsDtos = [];

        personalTermsData.forEach((term) => {
          if (term.isEssential === '1') {
            rqAgreeTermsDtos.push({
              id: authCtx.currentUser.id,
              termskey: term.termskey,
              isAgree: '1',
            });
          }
        });

        const agreeResultConfig = {
          method: 'POST',
          body: rqAgreeTermsDtos,
        };

        const saveTermsResult = await backendRequest.sendRequest(
          config.backend.endPoint.terms.agreedTermsById,
          agreeResultConfig
        );

        if (saveTermsResult.data === true) {
          alert('약관 동의가 완료되었습니다.');
          onClose();
        }
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    }
  };

  useEffect(() => {
    fetchServiceTerms();
  }, []);

  useEffect(() => {
    if (agreed1 && agreed2) {
      setAllAgreed(true);
    } else {
      setAllAgreed(false);
    }
  }, [agreed1, agreed2]);

  return (
    <>
      <CustomModal
        open={open}
        onClose={closeModalHandler}
        sx={{ maxHeight: '95%', width: { xs: '95%', md: 600 }, p: 3 }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <CheckCircleOutlinedIcon />
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>이용약관 동의</Typography>
        </Box>
        <hr />
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
          <Checkbox checked={allAgreed} onChange={handleAllAgreeCheckboxChange} />
          <Typography variant="subtitle1">전체 동의하기</Typography>
        </Box>
        <Box>
          <Typography variant="subtitle2">
            태양광 모니터링 서비스 이용약관, 개인정보 수집 및 이용에 관한 동의를 포함합니다.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 3 }}>
          <Checkbox checked={agreed1} onChange={(e) => handleAgreeCheckboxChange(e, 1)} />
          <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
            <span style={{ color: 'blue' }}>[필수] </span>서비스 이용약관 동의
          </Typography>
        </Box>
        <Box
          sx={{
            maxHeight: '200px',
            overflowY: 'auto',
            border: '1px solid #ccc',
            padding: '5px',
          }}
          dangerouslySetInnerHTML={{ __html: termsContent2 }}
        ></Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', mt: 3 }}>
          <Checkbox checked={agreed2} onChange={(e) => handleAgreeCheckboxChange(e, 2)} />
          <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>
            <span style={{ color: 'blue' }}>[필수] </span>개인정보 처리방침 동의
          </Typography>
        </Box>
        <Box
          sx={{
            maxHeight: '200px',
            overflowY: 'auto',
            border: '1px solid #ccc',
            padding: '5px',
          }}
          dangerouslySetInnerHTML={{ __html: termsContent1 }}
        ></Box>
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: '100%', mt: 4 }}>
          <ButtonGroup sx={{ width: '100%' }}>
            <Button variant="contained" onClick={handleAgree} sx={{ width: '50%' }}>
              동의
            </Button>
            <Button variant="outlined" onClick={handleDisagree} sx={{ width: '50%' }}>
              미동의
            </Button>
          </ButtonGroup>
        </Box>
      </CustomModal>
    </>
  );
}
