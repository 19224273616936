import { useEffect, useState } from 'react';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import { convertFormatStringToYMD } from '../../../utils/dateUtil';
import DayAllStatistics from './DayAllStatistics';
import DayGroupStatistics from './DayGroupStatistics';
import DayInverterStatistics from './DayInverterStatistics';

export default function Day({ page, subValue, tabValue, dateValue, searchState, setSearchState, chartView }) {
  const [dailyDevelopment, setDailyDevelopment] = useState([]);

  const DailyDevelopmentRequest = useBackendV2();

  const fetchDailyDevelopmentData = async () => {
    try {
      let tab;
      if (tabValue === 'all') {
        tab = 0;
      } else if (tabValue === 'group') {
        tab = 1;
      } else if (tabValue === 'inverter') {
        tab = 2;
      }

      const queryStringArr = [
        `yieldGroupType=${tab}`,
        `yieldtype=${subValue}`,
        `dateTime=${convertFormatStringToYMD(dateValue)}`,
      ];
      const queryString = queryStringArr.join('&');

      const { data } = await DailyDevelopmentRequest.sendRequest(
        `${config.backend.endPoint.statistics.plantYield}/${page.plantkey}?${queryString}`
      );

      const newData = [];
      for (let i = 1; i <= 31; i++) {
        const existingDataIndex = data.findIndex((item) => item.year_month_day_hour === i);
        if (existingDataIndex === -1) {
          newData.push({
            groupname: data[0].groupname,
            fdate: data[0].fdate,
            year_month_day_hour: i,
            yield: null,
            totalyield: null,
            fac: null,
            generateHour: data[0].generateHour,
            iac: null,
            idc: null,
            pac: null,
            pdc: null,
            radiation: null,
            tco2: null,
            uac: null,
            udc: null,
          });
        }
      }

      const mergedData = [...data, ...newData];

      mergedData.sort((a, b) => a.year_month_day_hour - b.year_month_day_hour);

      setDailyDevelopment(mergedData.map((item, index) => ({ ...item, id: index })));
      setSearchState(false);
    } catch (error) {
      setDailyDevelopment([]);
      setSearchState(false);
    }
  };

  useEffect(() => {
    if (searchState && page.plantkey !== '') {
      fetchDailyDevelopmentData();
    }
  }, [searchState, page]);

  let componentToShow;

  switch (tabValue) {
    case 'all':
      componentToShow = <DayAllStatistics data={dailyDevelopment} chartView={chartView} />;
      break;
    case 'group':
      componentToShow = <DayGroupStatistics data={dailyDevelopment} chartView={chartView} />;
      break;
    case 'inverter':
      componentToShow = <DayInverterStatistics data={dailyDevelopment} chartView={chartView} />;
      break;
    default:
      componentToShow = null;
      break;
  }

  return <>{componentToShow}</>;
}
