import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import KeyIcon from '@mui/icons-material/Key';

export default function InputSubscribeKeyDetail({ addSubscribeKeyClickHandler }) {
  return (
    <List component="nav">
      <ListItemButton onClick={addSubscribeKeyClickHandler}>
        <ListItemIcon>
          <KeyIcon />
        </ListItemIcon>
        <ListItemText primary="이용권 등록" />
      </ListItemButton>
    </List>
  );
}
