import {
  Avatar,
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  styled,
  tableCellClasses,
} from '@mui/material';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontSize: '14px',
    borderBottom: '1px solid #CECECE',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

// 현황판 인버터별 상세정보
export default function PlantInverterInfoTableCard({ basePlantInfo, invDailyDevelopment }) {
  const columns = [
    { field: 'invertername', headerName: '인버터명', width: 100 },
    { field: 'pac', headerName: '출력 전력(kW)', width: 100 },
    { field: 'dailyyield', headerName: '일일 발전량(kWh)', width: 100 },
    { field: 'generatestatus', headerName: '발전 상태', width: 90 },
  ];

  // RTU 수신상태
  const isRtuStatusOne = basePlantInfo.some((plant) => plant.rtuinfos?.some((info) => info.rtustatus === 1));

  return (
    <>
      <Card sx={{ borderRadius: '15px', boxShadow: '0px 3px 100px #00000014', height: 400 }}>
        <Box sx={{ p: 1 }}>
          <Typography variant="subtitle1">인버터 상세 정보</Typography>
        </Box>
        <Card
          sx={{
            border: 'none',
            width: '100%',
            height: '90%',
            borderRadius: 0,
            overflowY: 'auto',
          }}
        >
          <StyledTableContainer>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <StyledTableCell key={column.field} align="center" style={{ minWidth: column.width }}>
                      {column.headerName}
                    </StyledTableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {invDailyDevelopment.map((row, index) => (
                  <TableRow key={row.serialnum} index={index}>
                    {columns.map((column) => (
                      <TableCell
                        key={column.field}
                        align="center"
                        style={{
                          minWidth: column.width,
                          backgroundColor: '#fff',
                        }}
                      >
                        {column.field === 'pac' || column.field === 'dailyyield' ? (
                          isRtuStatusOne && row.generatestatus === 1 ? (
                            row[column.field] === null ? (
                              '--,--'
                            ) : (
                              row[column.field].toFixed(1)
                            )
                          ) : (
                            '--,--'
                          )
                        ) : column.field === 'generatestatus' ? (
                          <Box display="flex" justifyContent="center">
                            <Tooltip title={row[column.field] === 1 ? '발전 중' : '발전 중단'} arrow>
                              <Avatar
                                sizes="small"
                                sx={{
                                  width: 18,
                                  height: 18,
                                  bgcolor: row[column.field] === 1 ? '#64B346' : 'red',
                                  color: row[column.field] === 1 ? '#64B346' : 'red',
                                }}
                              >
                                {''}
                              </Avatar>
                            </Tooltip>
                          </Box>
                        ) : row[column.field] ? (
                          row[column.field].toLocaleString()
                        ) : (
                          0
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </StyledTableContainer>
        </Card>
      </Card>
    </>
  );
}
