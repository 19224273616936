import { Avatar, Container } from '@mui/material';
import { Outlet, useNavigate } from 'react-router-dom';
import Footer from './Footer';
import { useState, useEffect, useContext } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import useResponsive from '../../hooks/useResponsive';
import CompanyIntroduction from './CompanyIntroduction';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import PullToRefresh from './PullToRefresh';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import AuthContext from '../../store/auth-context';
import PersonalTermsConfirmModal from './PersonalTermsConfirmModal';

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 14,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// 기본적인 페이지 레이아웃 및 UI 요소들을 정의하고, 앱의 주요 구조를 제공하는 컴포넌트
export default function BaseLayout() {
  const authCtx = useContext(AuthContext);
  const isDesktop = useResponsive('up', 'lg');
  const theme = useTheme();
  const navigate = useNavigate();

  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));
  const [scrollToTopVisible, setScrollToTopVisible] = useState(false);
  const [distance, setDistance] = useState();

  const [openPersonalTermsModal, setOpenPersonalTermsModal] = useState(false);
  const [personalTermsData, setPersonalTermsData] = useState();

  const closePlantSettingHandler = () => {
    setOpenPersonalTermsModal(false);
  };

  const handleScroll = () => {
    if (window.scrollY > 550) {
      setScrollToTopVisible(true);
    } else {
      setScrollToTopVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // 새로고침이 필요한 경우 처리
  const handlePullToRefresh = () => {
    window.location.reload();
  };

  const backendRequest = useBackendV2();

  // 약관 동의 항목 검사
  const fetchImportingTerms = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.terms.getImportingTermsById}/${authCtx.currentUser.id}`
      );

      const allAgreed = data.every((term) => term.isagree === '1');

      if (data.length !== 0 && !allAgreed) {
        setOpenPersonalTermsModal(true);
        setPersonalTermsData(data);
      } else {
        setOpenPersonalTermsModal(false);
        setPersonalTermsData();
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  // 사용자 이름, 연락처, 이메일 미입력 정보 검사
  const fetchGetUserReqInfo = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.info}/${authCtx.currentUser.id}`
      );

      if (data.uname === '' || data.uname === null) {
        alert('회원정보중 이름이 미입력 되어있어, 회원정보 수정 페이지로 이동합니다.');
        navigate('/updateUser');
      } else if (data.hp === '' || data.hp === null) {
        alert('회원정보중 핸드폰이 미입력 되어있어, 회원정보 수정 페이지로 이동합니다.');
        navigate('/updateUser');
      } else if (
        data.hp === '01000000000' ||
        data.hp === '01011111111' ||
        data.hp === '01011111234' ||
        data.hp === '01012341234' ||
        data.hp === '01012345678' ||
        data.hp === '01099999999' ||
        data.hp === '010-0000-0000' ||
        data.hp === '010-1111-1111' ||
        data.hp === '010-1111-1234' ||
        data.hp === '010-1234-1234' ||
        data.hp === '010-1234-5678' ||
        data.hp === '010-9999-9999'
      ) {
        alert('회원정보중 핸드폰의 정보가 정확하지 않아, 회원정보 수정 페이지로 이동합니다.');
        navigate('/updateUser');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    setValue(parseInt(localStorage.getItem('menuValue')));
    setSubValue(parseInt(localStorage.getItem('menuSubValue')));
  }, [localStorage.getItem('menuValue'), localStorage.getItem('menuSubValue')]);

  useEffect(() => {
    fetchImportingTerms();
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    if (openPersonalTermsModal === false) {
      fetchGetUserReqInfo();
    }
  }, [openPersonalTermsModal]);

  return (
    <>
      <StyledRoot>
        <PullToRefresh onRefresh={handlePullToRefresh} distance={distance} setDistance={setDistance} />
        <Main>
          <Container
            sx={{
              minHeight: '600px',
              minWidth: '90%',
              marginBottom: '3%',
              mt: isDesktop ? -1 : 6,
            }}
          >
            {/*  react-router-dom 라이브러리에서 제공하는 컴포넌트 */}
            {/* 라우터가 매칭한 현재 라우트에 해당하는 컴포넌트를 렌더링하는 역할 */}
            <Outlet />
          </Container>
          {isDesktop ? <CompanyIntroduction setValue={setValue} setSubValue={setSubValue} /> : null}
          {scrollToTopVisible && (
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                backgroundColor: '#3e53d6',
                color: theme.palette.secondary,
                borderRadius: '100%',
                width: '50px',
                height: '50px',
                position: 'fixed',
                bottom: 60,
                right: isDesktop ? 9 : 1,
                zIndex: 1000,
                cursor: isDesktop ? 'pointer' : 'cursor',
              }}
              aria-controls="menu-earning-card"
              aria-haspopup="true"
              onClick={scrollToTop}
            >
              <ArrowUpwardOutlinedIcon fontSize="medium" />
            </Avatar>
          )}
        </Main>
        {isDesktop ? null : (
          <Footer value={value} setValue={setValue} subValue={subValue} setSubValue={setSubValue} />
        )}
        {openPersonalTermsModal && (
          <PersonalTermsConfirmModal
            open={openPersonalTermsModal}
            onClose={closePlantSettingHandler}
            personalTermsData={personalTermsData}
            authCtx={authCtx}
          />
        )}
      </StyledRoot>
    </>
  );
}
