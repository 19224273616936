import { Box, Button, Divider, Typography } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import config from '../../config';
import { useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import { convertFormatStringToYMD } from '../../utils/dateUtil';
import { useNavigate } from 'react-router-dom';

// 구독 라이선스 만료 항목 안내 모달
export default function SubscriptionGuideModal({ plantkey, plantName, open, onClose }) {
  const navigate = useNavigate();

  const [plantRtu, setPlantRtu] = useState([]);
  const [basePlantInfo, setBasePlantInfo] = useState({});

  const nodata = basePlantInfo?.rtuinfos?.some((info) => info.rtustatus === 1) ? 0 : 1;

  const backendRequest = useBackendV2();

  const fetchRtuToPlant = async () => {
    if (plantkey !== '') {
      try {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.plants.subscribe}/${plantkey}`
        );

        const filteredData = data.filter((item) => item.subscribeday >= -90);

        setPlantRtu(filteredData);
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    }
  };

  const fetchBasePlantInfoData = async () => {
    if (plantkey !== '') {
      try {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.plants.baseInfo}/${plantkey}`
        );
        setBasePlantInfo(data[0]);
      } catch (error) {
        setBasePlantInfo([]);
      }
    }
  };

  const moveProudctPageClickHandler = () => {
    localStorage.setItem('menuValue', 6);
    localStorage.setItem('menuSubValue', 0);
    navigate(`${config.frontend.productdetails}/1`, { state: { plantkey: plantkey, plantName: plantName } });
  };

  const moveSubscriptionInputPageClickHandler = () => {
    localStorage.setItem('menuValue', 10);
    localStorage.setItem('menuSubValue', 0);
    localStorage.setItem('selectPlantkey', plantkey);
    navigate(`${config.frontend.addSubscribeKey}`);
  };

  useEffect(() => {
    fetchRtuToPlant();
    fetchBasePlantInfoData();
  }, [plantkey]);

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <>
      <CustomModal
        open={open}
        onClose={closeModalHandler}
        sx={{ maxHeight: '95%', width: { xs: '95%', md: 600 } }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <PlaylistAddCheckIcon />
          <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.09rem' }}>
            {plantName} 이용권 안내
          </Typography>
        </Box>
        {nodata === 1 ? (
          <>
            <Box display="flex" justifyContent="end" sx={{ mt: 0.5 }}>
              <Typography variant="subtitle2" color="error">
                * 통신중단인 RTU가 있으니 확인 바랍니다.
              </Typography>
            </Box>
          </>
        ) : null}
        <hr />
        <Box>
          {plantRtu.map((rtu, index) => (
            <Box key={index} sx={{ mt: 1 }}>
              <Typography variant="subtitle1">RTU번호: {rtu.rtun}</Typography>
              <Typography variant="subtitle1">
                {new Date(rtu.enddate) < new Date()
                  ? '서비스 만료일 : ' + convertFormatStringToYMD(rtu.enddate)
                  : '서비스 만료 예정일 : ' + convertFormatStringToYMD(rtu.enddate)}
              </Typography>
              {plantRtu[0].graceperiod - plantRtu[0].subscribeday < 0 ? (
                <>
                  <Typography variant="h6" color="red" sx={{ mt: 1 }}>
                    ※ 서비스 이용이 불가 합니다.
                  </Typography>
                  <Typography variant="subtitle2" color="red">
                    (이용권 구매시 서비스 이용이 가능합니다.)
                  </Typography>
                </>
              ) : (
                <>
                  <Typography variant="h6" color="red" sx={{ mt: 1 }}>
                    ※ {plantRtu[0].graceperiod - plantRtu[0].subscribeday}일 후 서비스 이용이 불가 합니다.
                  </Typography>
                  <Typography variant="subtitle1" color="red" sx={{ ml: 2.5 }}>
                    (이용권 구매 후 이용이 가능합니다.)
                  </Typography>
                </>
              )}
              <Divider sx={{ mt: 1 }} />
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            mt: 3,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            '& > button': {
              width: '70%',
              mb: 2,
            },
          }}
        >
          <Button variant="contained" onClick={moveProudctPageClickHandler}>
            이용권 구매
          </Button>
          <Button variant="outlined" onClick={moveSubscriptionInputPageClickHandler}>
            구매한 이용권 입력
          </Button>
        </Box>
      </CustomModal>
    </>
  );
}
