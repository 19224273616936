import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import '../../../css/App.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

export default function EnvStatMinutesTable({ data, envFirstChar, envSecondChar, isDesktop }) {
  let columns;

  if (!envFirstChar && envSecondChar) {
    columns = [
      { field: 'localhour', headerName: '시간', width: 100 },
      { field: 'srad', headerName: '수평 일사량 (W/㎡)', width: 200 },
      { field: 'ptemp', headerName: '외기 온도 (℃)', width: 200 },
    ];
  } else {
    columns = [
      { field: 'localhour', headerName: '시간', width: 100 },
      { field: 'trad', headerName: '경사 일사량(W/㎡)', width: 200 },
      { field: 'srad', headerName: '수평 일사량 (W/㎡)', width: 200 },
      { field: 'otemp', headerName: '판넬 온도 (℃)', width: 200 },
      { field: 'ptemp', headerName: '외기 온도 (℃)', width: 200 },
    ];
  }
  const formatTime = (timeString) => {
    const dateObject = new Date(timeString);
    return dateObject.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  };

  return (
    <Card
      sx={{
        mt: 1,
        borderRadius: 0,
        boxShadow: '0px 3px 12px #00000014',
      }}
    >
      <StyledTableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.field}
                  align="center"
                  style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                  className={column.headerName === '발전소명' ? 'sticky' : ''}
                >
                  {column.headerName}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow key={row.id} index={index}>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    align="center"
                    style={{
                      minWidth: column.width,
                      backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                      borderRight: '1px solid #d0d0d0',
                    }}
                    className={column.headerName === '시간' ? 'sticky' : ''}
                  >
                    {column.field === 'localdate'
                      ? formatTime(row[column.field])
                      : row[column.field]
                      ? row[column.field].toLocaleString()
                      : 0}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Card>
  );
}
