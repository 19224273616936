import { styled } from '@mui/material/styles';
import { Button, Typography, Container, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorImg from '../../assets/Illustrations/404 Error-rafiki.svg';
import SolLogo from '../../assets/EtcImg/sol_logo_login.png';

// NotFound 컴포넌트
export default function NotFound() {
  const navigate = useNavigate();

  const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '90vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(0, 0),
  }));

  return (
    <>
      <Container
        sx={{
          minHeight: '600px',
          minWidth: '100%',
          marginBottom: '4%',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            mt: 4,
          }}
        >
          <img src={SolLogo} alt="SOLARCUBE" style={{ width: 30 }} />
          &nbsp;
          <Typography
            color="inherit"
            sx={{
              display: 'flex',
              fontFamily: '"Segoe UI Symbol"',
              flexGrow: 1,
              fontWeight: 'bold',
              color: 'black',
            }}
          >
            SOLAR CUBE
          </Typography>
        </Box>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h5" fontWeight="bold" paragraph>
            Sorry, page not found!
          </Typography>
          <Typography sx={{ color: 'text.secondary' }}>
            Sorry, we couldn't find the page you're looking for. Perhaps you've mistyped the URL? Be sure to
            check your spelling.
          </Typography>
          <Box component="img" src={ErrorImg} sx={{ height: 300, mx: 'auto', my: { xs: 4, sm: 10 } }} />
          <Button onClick={() => navigate('/')} size="large" variant="contained">
            Go to Home
          </Button>
        </StyledContent>
      </Container>
    </>
  );
}
