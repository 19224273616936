import { Box, Breadcrumbs, Container } from '@mui/material';
import ProductsList from '../../components/Products/ProductsList';
import useResponsive from '../../hooks/useResponsive';
import ProductCartWidget from '../../components/Products/ProductCartWidget';
import { useContext, useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import Header02 from '../../components/Layout/Header02';
import SubHeader from '../../components/Layout/SubHeader';
import Nav from '../../components/Layout/Nav';
import usePageLogRecord from '../../hooks/usePageLogRecord';
import AuthContext from '../../store/auth-context';

// 상품 페이지 컴포넌트
export default function ProductsPage() {
  const isDesktop = useResponsive('up', 'lg');
  const authCtx = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const distance = 0;

  const [products, setProducts] = useState([]);
  const [putInShoppingCart, setPutInShoppingCart] = useState(false);

  const backendRequest = useBackendV2();

  const fetchProductList = async () => {
    try {
      const { data } = await backendRequest.sendRequest(config.backend.endPoint.products.product);
      setProducts(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchProductList();
    fetchUserStylePreference();
  }, []);

  usePageLogRecord('products');

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      {isDesktop ? (
        <>
          <Box
            sx={{
              display: {
                xs: 'none',
                sm: 'flex',
              },
            }}
          >
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <StyledBreadcrumb
                label="상품&서비스"
                icon={<StoreMallDirectoryOutlinedIcon fontSize="small" color="primary" />}
              />
              <StyledBreadcrumb label="상품&서비스 목록" />
            </Breadcrumbs>
          </Box>
          <Container
            sx={{
              minWidth: '75%',
              marginBottom: '4%',
            }}
          >
            <Box sx={{ mt: 2.5 }}>
              <ProductsList
                products={products}
                putInShoppingCart={putInShoppingCart}
                setPutInShoppingCart={setPutInShoppingCart}
              />
              <ProductCartWidget
                putInShoppingCart={putInShoppingCart}
                setPutInShoppingCart={setPutInShoppingCart}
              />
            </Box>
          </Container>
        </>
      ) : (
        <>
          <Box>
            <ProductsList
              products={products}
              putInShoppingCart={putInShoppingCart}
              setPutInShoppingCart={setPutInShoppingCart}
            />
            <ProductCartWidget
              putInShoppingCart={putInShoppingCart}
              setPutInShoppingCart={setPutInShoppingCart}
            />
          </Box>
        </>
      )}
    </>
  );
}
