import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';

export default function AllStoreMallDetail({ storeMallDetailClickHandler }) {
  return (
    <List component="nav">
      <ListItemButton onClick={storeMallDetailClickHandler}>
        <ListItemIcon>
          <StoreMallDirectoryOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="상품&서비스" />
      </ListItemButton>
    </List>
  );
}
