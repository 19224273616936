import { Avatar, Box, Button, ClickAwayListener, Divider, Stack, Tooltip, Typography } from '@mui/material';
import InvListModal from './InvListModal';
import { useState } from 'react';
import WifiTetheringIcon from '@mui/icons-material/WifiTethering';
import WifiTetheringOffIcon from '@mui/icons-material/WifiTetheringOff';
import AdsClickIcon from '@mui/icons-material/AdsClick';

// 인버터 리스트 개별 발전소 내 인버터 상태 표시 컴포넌트
export default function InvPlantCard({ invData, plantRtuStat, subscribeday, graceperiodDay, isDesktop }) {
  const [openInvListModal, setOpenInvListModal] = useState(false);
  const [plantIvtTooltipOpenMap, setPlantIvtTooltipOpenMap] = useState({});
  const [plantStatisticsTooltipOpenMap, setPlantStatisticsTooltipOpenMap] = useState({});

  const showInvListModalHandler = () => {
    setOpenInvListModal(true);
  };
  const closeInvListModalHandler = () => {
    setOpenInvListModal(false);
  };

  // 클릭한 인버터 아바타의 툴팁 상태를 열기
  const handlePlantIvtAvatarClick = (plantkey) => {
    setPlantIvtTooltipOpenMap((prevMap) => ({ ...prevMap, [plantkey]: true }));
  };
  // 인버터 아바타의 툴팁 상태를 닫기
  const handlePlantIvtTooltipClose = (plantkey) => {
    setPlantIvtTooltipOpenMap((prevMap) => ({ ...prevMap, [plantkey]: false }));
  };

  // 클릭한 통계 아바타의 툴팁 상태를 열기
  const handlePlantStatisticsAvatarClick = (plantkey) => {
    setPlantStatisticsTooltipOpenMap((prevMap) => ({ ...prevMap, [plantkey]: true }));
  };
  // 통계 아바타의 툴팁 상태를 닫기
  const handlePlantStatisticsTooltipClose = (plantkey) => {
    setPlantStatisticsTooltipOpenMap((prevMap) => ({ ...prevMap, [plantkey]: false }));
  };

  return (
    <>
      <Box sx={{ mt: 1 }}>
        <Stack spacing={1} direction="row" justifyContent="space-between" alignItems="flex-start">
          <div onClick={showInvListModalHandler} style={{ cursor: isDesktop ? 'pointer' : 'cursor' }}>
            <Typography variant="subtitle2" display="flex" alignItems="center">
              {invData.invertername}
              <AdsClickIcon color="primary" sx={{ ml: 0.25 }} />
            </Typography>
          </div>
          <Stack sx={{ textAlign: 'right' }} spacing={0}>
            <Button
              variant="outlined"
              size="small"
              endIcon={invData.ivtNodata === 1 ? <WifiTetheringOffIcon /> : <WifiTetheringIcon />}
              color={invData.ivtNodata === 1 ? 'error' : 'success'}
              sx={{ color: invData.ivtNodata === 1 ? 'red' : '#64B346', cursor: 'default' }}
            >
              {invData.ivtNodata === 1 ? '통신 중단' : '통신 중'}
            </Button>
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              출력 전력 : <strong>{invData?.pac?.toFixed(1)} kW</strong>
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              일일 발전량 :{' '}
              <strong>{invData?.dailyyield === null ? 0 : invData?.dailyyield?.toFixed(1)} kWh</strong>
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={0} direction="row">
          <Box sx={{ m: 0.8, display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2">
              <strong>인버터</strong>
            </Typography>
            <Box sx={{ ml: 0.8 }}>
              {isDesktop ? (
                <Tooltip title={invData.generatestatus === 1 ? '발전 중' : '발전 중단'} arrow>
                  <Avatar
                    sizes="small"
                    sx={{
                      width: 15,
                      height: 15,
                      bgcolor: invData.generatestatus === 1 ? '#64B346' : 'red',
                      color: invData.generatestatus === 1 ? '#64B346' : 'red',
                    }}
                  >
                    {''}
                  </Avatar>
                </Tooltip>
              ) : (
                <ClickAwayListener onClickAway={() => handlePlantIvtTooltipClose(plantRtuStat[0].plantkey)}>
                  <div>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      open={!!plantIvtTooltipOpenMap[plantRtuStat[0].plantkey]}
                      onClose={() => handlePlantIvtTooltipClose(plantRtuStat[0].plantkey)}
                      title={invData.generatestatus === 1 ? '발전 중' : '발전 중단'}
                      arrow
                    >
                      <Avatar
                        sizes="small"
                        sx={{
                          width: 15,
                          height: 15,
                          bgcolor: invData.generatestatus === 1 ? '#64B346' : 'red',
                          color: invData.generatestatus === 1 ? '#64B346' : 'red',
                        }}
                        onClick={() => handlePlantIvtAvatarClick(plantRtuStat[0].plantkey)}
                      >
                        {''}
                      </Avatar>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              )}
            </Box>
          </Box>
          <Box sx={{ m: 0.8, display: 'flex', alignItems: 'center' }}>
            <Typography variant="body2">
              <strong>통계</strong>
            </Typography>
            <Box sx={{ ml: 0.8 }}>
              {isDesktop ? (
                <Tooltip title={invData.dlstatus === 1 ? '적산 중' : '적산 중단'} arrow>
                  <Avatar
                    sizes="small"
                    sx={{
                      width: 15,
                      height: 15,
                      bgcolor: invData.dlstatus === 1 ? '#64B346' : 'red',
                      color: invData.dlstatus === 1 ? '#64B346' : 'red',
                    }}
                  >
                    {''}
                  </Avatar>
                </Tooltip>
              ) : (
                <ClickAwayListener
                  onClickAway={() => handlePlantStatisticsTooltipClose(plantRtuStat[0].plantkey)}
                >
                  <div>
                    <Tooltip
                      PopperProps={{
                        disablePortal: true,
                      }}
                      open={!!plantStatisticsTooltipOpenMap[plantRtuStat[0].plantkey]}
                      onClose={() => handlePlantStatisticsTooltipClose(plantRtuStat[0].plantkey)}
                      title={invData.dlstatus === 1 ? '적산 중' : '적산 중단'}
                      arrow
                    >
                      <Avatar
                        sizes="small"
                        sx={{
                          width: 15,
                          height: 15,
                          bgcolor: invData.dlstatus === 1 ? '#64B346' : 'red',
                          color: invData.dlstatus === 1 ? '#64B346' : 'red',
                        }}
                        onClick={() => handlePlantStatisticsAvatarClick(plantRtuStat[0].plantkey)}
                      >
                        {''}
                      </Avatar>
                    </Tooltip>
                  </div>
                </ClickAwayListener>
              )}
            </Box>
          </Box>
        </Stack>
      </Box>
      <Divider />
      {openInvListModal && (
        <InvListModal
          open={openInvListModal}
          onClose={closeInvListModalHandler}
          invData={invData}
          subscribeday={subscribeday}
          graceperiodDay={graceperiodDay}
          isDesktop={isDesktop}
        />
      )}
    </>
  );
}
