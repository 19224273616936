import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import InsightsIcon from '@mui/icons-material/Insights';

export default function AllStatisticsDetail({ statisticsDetailClickHandler }) {
  return (
    <List component="nav">
      <ListItemButton onClick={statisticsDetailClickHandler}>
        <ListItemIcon>
          <InsightsIcon />
        </ListItemIcon>
        <ListItemText primary="통계" />
      </ListItemButton>
    </List>
  );
}
