import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Card,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import AlarmHistory from '../../components/Alarm/AlarmHistory/AlarmHistory';
import AlarmCodeAnalysis from '../../components/Alarm/AlarmCodeAnalysis/AlarmCodeAnalysis';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../store/auth-context';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import WebAlarmSearchBar from '../../components/Alarm/WebAlarmSearchBar';
import AppAlarmSearchBar from '../../components/Alarm/AppAlarmSearchBar';
import dayjs from 'dayjs';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import TabMenu from '../../components/Layout/TabMenu';
import Header02 from '../../components/Layout/Header02';
import SubHeader from '../../components/Layout/SubHeader';
import Nav from '../../components/Layout/Nav';
import SubscriptionGuideModal from '../../components/Dashboard/SubscriptionGuideModal';
import usePageLogRecord from '../../hooks/usePageLogRecord';
import UseCustomSort from '../../hooks/useCustomSort';

export default function AlarmPage() {
  const isDesktop = useResponsive('up', 'lg');
  const isIPadMini = useResponsive('up', 'sm');

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const distance = 0;

  const authCtx = useContext(AuthContext);

  const plantStateConditionInitialState = {
    plantkey: '',
    plantname: '',
    fromDate: '',
    toDate: '',
    selectInverter: 'all',
  };

  const [plantState, setPlantState] = useState(plantStateConditionInitialState);
  const [selectPlantState, setSelectPlantState] = useState([]);
  const [selectPlantInvters, setSellectPlantInverters] = useState([]);
  const [searchState, setSearchState] = useState(true);

  const [fromDateValue, setFromDateValue] = useState(Date);
  const [toDateValue, setToDateValue] = useState(Date);

  const [alarmsAlist, setAlarmsAlist] = useState([]);
  const [alarmsBlist, setAlarmsBlist] = useState([]);

  const [plantInvs, setPlantInvs] = useState([]);
  const [acbVcbDailyDevelopment, setAcbVcbDailyDevelopment] = useState([]);
  const [combinePlantInv, setCombinePlantInv] = useState([]);

  const [subscribeday, setSubscribeday] = useState(null);
  const [graceperiodDay, setGraceperiodDay] = useState(null);
  const [openSubscriptionGuideModal, setOpenSubscriptionGuideModal] = useState(false);

  const selectPlantStateChangeHandler = (plant) => {
    setPlantState((prev) => {
      return {
        ...prev,
        plantkey: plant.plantkey,
        plantname: plant.plantname,
        fromDate: dayjs(fromDateValue).format('YYYY-MM-DD'),
        toDate: dayjs(toDateValue).format('YYYY-MM-DD'),
        selectInverter: 'all',
      };
    });
    setSearchState(true);
    setSellectPlantInverters(plant.rsInverters || []);
    localStorage.setItem('selectPlantkey', plant.plantkey);
  };

  const selectPlantInvterChangeHandler = (e) => {
    setPlantState((prev) => {
      return {
        ...prev,
        selectInverter: e.target.value,
      };
    });
    setSearchState(true);
  };

  const selectFromDateChangeHandler = (newValue) => {
    const formattedDate = dayjs(newValue).format('YYYY-MM-DD');

    setPlantState((prev) => {
      return {
        ...prev,
        fromDate: formattedDate,
      };
    });

    // fromDate의 월과 toDate의 월이 다를 때 toDate를 fromDate의 달의 마지막 날짜로 설정
    if (dayjs(newValue).month() !== dayjs(toDateValue).month()) {
      const lastDayOfMonth = dayjs(newValue).endOf('month').format('YYYY-MM-DD');
      setToDateValue(lastDayOfMonth);
    }
  };

  const selectToDateChangeHandler = (newValue) => {
    const formattedDate = dayjs(newValue).format('YYYY-MM-DD');

    setPlantState((prev) => {
      return {
        ...prev,
        toDate: formattedDate,
      };
    });
  };

  const backendRequest = useBackendV2();

  const fetchUserPlantsState = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.userPlantsV2}/${authCtx.currentUser.id}`
      );

      if (data.length === 1) {
        setPlantState((prevPage) => ({
          ...prevPage,
          plantkey: data[0].plantkey,
          plantname: data[0].plantname,
        }));
        setSelectPlantState(data);
      } else {
        setSelectPlantState(data);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다. 인터넷 연결 상태를 확인 후 새로고침 해주세요.');
    }
  };

  const fetchAlarmAListData = async () => {
    try {
      const selectInv = plantState.selectInverter === 'all' ? '' : plantState.selectInverter;

      const queryStringArr = [
        `serialnum=${selectInv}`,
        `fromdate=${
          plantState.fromDate === '' ? dayjs(fromDateValue).format('YYYY-MM-DD') : plantState.fromDate
        }`,
        `todate=${plantState.toDate === '' ? dayjs(toDateValue).format('YYYY-MM-DD') : plantState.toDate}`,
      ];
      const queryString = queryStringArr.join('&');

      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.alarms.aList}/${plantState.plantkey}?${queryString}`
      );

      setAlarmsAlist(data.map((item, index) => ({ ...item, id: index })));
      setSearchState(false);
    } catch (error) {
      console.error(error);
      alert('경보 이력을 불러오는 중 오류가 발생하였습니다. 인터넷 연결 상태를 확인 후 새로고침 해주세요.');
    }
  };
  const fetchAlarmBListData = async () => {
    try {
      const selectInv = plantState.selectInverter === 'all' ? '' : plantState.selectInverter;

      const queryStringArr = [
        `serialnum=${selectInv}`,
        `fromdate=${
          plantState.fromDate === '' ? dayjs(fromDateValue).format('YYYY-MM-DD') : plantState.fromDate
        }`,
        `todate=${plantState.toDate === '' ? dayjs(toDateValue).format('YYYY-MM-DD') : plantState.toDate}`,
      ];
      const queryString = queryStringArr.join('&');

      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.alarms.bList}/${plantState.plantkey}?${queryString}`
      );

      setAlarmsBlist(data.map((item, index) => ({ ...item, id: index })));
      setSearchState(false);
    } catch (error) {
      console.error(error);
      alert('발생 빈도를 불러오는 중 오류가 발생하였습니다. 인터넷 연결 상태를 확인 후 새로고침 해주세요.');
    }
  };

  const fetchSubscriptionPeriod = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.subscribe}/${plantState.plantkey}`
      );

      const filteredData = data.filter((item) => item.subscribeday > -90);

      if (filteredData.length > 0) {
        const maxSubdcribedayItem = filteredData.reduce((maxItem, currentItem) => {
          return currentItem.subscribeday > maxItem.subscribeday ? currentItem : maxItem;
        });

        setSubscribeday(maxSubdcribedayItem.subscribeday);
        setGraceperiodDay(data[0]?.graceperiod);
      } else {
        setSubscribeday(null);
        setGraceperiodDay(null);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchGetPlantInverters = async () => {
    try {
      const invsResponse = await backendRequest.sendRequest(
        `${config.backend.endPoint.inverters.extReal}/${plantState.plantkey}`
      );

      const sortedData = UseCustomSort(invsResponse.data, 'invertername');
      setPlantInvs(sortedData);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchAcbVcbDailyDevelopmentData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.acbvcbs.extReal}/${plantState.plantkey}`
      );
      setAcbVcbDailyDevelopment(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    if (plantState.plantkey !== '') {
      fetchSubscriptionPeriod();
      fetchGetPlantInverters();
      fetchAcbVcbDailyDevelopmentData();
    }
  }, [plantState.plantkey]);

  useEffect(() => {
    if (searchState && plantState.plantkey !== '') {
      if (subValue === 0) {
        fetchAlarmAListData();
      } else if (subValue === 1) {
        fetchAlarmBListData();
      }
    }
  }, [searchState, plantState.plantkey]);

  useEffect(() => {
    if (plantState.plantkey !== '') {
      if (subValue === 0) {
        fetchAlarmAListData();
      } else if (subValue === 1) {
        fetchAlarmBListData();
      }
    }
  }, [subValue]);

  useEffect(() => {
    fetchUserPlantsState();
    fetchUserStylePreference();
  }, []);

  useEffect(() => {
    // 로컬 스토리지에서 selectPlantkey 값을 가져옴
    const localStorageSelectPlantkey = localStorage.getItem('selectPlantkey');

    // selectPlantState에서 해당하는 발전소 탐색
    const initialSelectedPlant = selectPlantState.find(
      (option) => option.plantkey === localStorageSelectPlantkey
    );

    // 첫번째 발전소 탐색
    const initialSelectedPlantN = selectPlantState[0];

    // 찾은 발전소를 초기값으로 설정
    if (initialSelectedPlant) {
      selectPlantStateChangeHandler(initialSelectedPlant);
    }
    // 찾은 발전소가 없는 경우 첫번째 발전소를 초기값으로 설정
    else if (initialSelectedPlant === undefined && initialSelectedPlantN !== undefined) {
      selectPlantStateChangeHandler(initialSelectedPlantN);
    }
  }, [selectPlantState]);

  useEffect(() => {
    // plantInvs에서 name과 serialnum을 추출
    const formattedPlantInvs = plantInvs?.map((item) => ({
      name: item.invertername,
      serialnum: item.serialnum,
    }));

    // acbVcbDailyDevelopment에서 name과 serialnum을 추출
    const formattedAcbVcb = acbVcbDailyDevelopment?.map((item) => ({
      name: item.acbname,
      serialnum: item.serialnum,
    }));

    // acbVcbDailyDevelopment의 길이가 0이면 plantInvs만, 아니면 둘 다 합침
    if (acbVcbDailyDevelopment.length === 0) {
      setCombinePlantInv(formattedPlantInvs);
    } else {
      setCombinePlantInv([...formattedPlantInvs, ...formattedAcbVcb]);
    }
  }, [plantInvs, acbVcbDailyDevelopment]);

  let componentToShow;

  switch (subValue) {
    case 0:
      componentToShow = (
        <AlarmHistory isDesktop={isDesktop} isIPadMini={isIPadMini} alarmsAlist={alarmsAlist} />
      );
      break;
    case 1:
      componentToShow = (
        <AlarmCodeAnalysis
          isDesktop={isDesktop}
          isIPadMini={isIPadMini}
          alarmsBlist={alarmsBlist}
          plantState={plantState}
        />
      );
      break;
    default:
      componentToShow = null;
      break;
  }

  // 만료 발전소 상품 및 구독 라이선스 입력 페이지 안내 모달
  const openSubscriptionGuideModalHandler = () => {
    setOpenSubscriptionGuideModal(true);
  };
  const closeSubscriptionGuideModalHandler = () => {
    setOpenSubscriptionGuideModal(false);
  };

  // 전체 발전소
  const numPlants = Array.isArray(selectPlantState) ? selectPlantState.length : 0;

  usePageLogRecord('alarms', plantState.plantkey);

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        plantkey={plantState.plantkey}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'flex',
          },
        }}
      >
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <StyledBreadcrumb
            label="경보"
            icon={<NotificationsActiveIcon fontSize="small" color="primary" />}
          />
          <StyledBreadcrumb label={subValue === 0 ? '이력' : subValue === 1 ? '발생 빈도' : null} />
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: {
            xs: 'flex',
          },
          my: 2,
          gap: 1,
          mt: isDesktop ? 2.5 : 7.5,
          alignItems: 'center',
        }}
      >
        {selectPlantState.length > 1 ? (
          <>
            {numPlants > 11 ? (
              <Autocomplete
                disableClearable
                id="combo-box-demo"
                options={selectPlantState}
                getOptionLabel={(option) => option.plantname || ''}
                value={
                  selectPlantState.find((option) => option.plantkey === plantState.plantkey) ||
                  selectPlantState.find(
                    (option) => option.plantkey === parseInt(localStorage.getItem('selectPlantkey'), 10)
                  ) ||
                  selectPlantState[0]
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    const selectedPlant = selectPlantState.find(
                      (option) => option.plantkey === newValue.plantkey
                    );
                    selectPlantStateChangeHandler(selectedPlant);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="발전소" variant="outlined" sx={{ bgcolor: '#fff' }} />
                )}
                sx={{ width: isDesktop ? '45%' : '100%' }}
              />
            ) : (
              <FormControl sx={{ width: isDesktop ? '45%' : '100%', bgcolor: 'white' }}>
                <InputLabel>발전소</InputLabel>
                <Select
                  label="발전소"
                  value={
                    selectPlantState.find((option) => option.plantkey === plantState.plantkey)?.plantkey ||
                    selectPlantState.find(
                      (option) => option.plantkey === parseInt(localStorage.getItem('selectPlantkey'), 10)
                    )?.plantkey ||
                    selectPlantState[0]?.plantkey
                  }
                  onChange={(e) => {
                    const selectedPlant = selectPlantState.find(
                      (option) => option.plantkey === e.target.value
                    );
                    selectPlantStateChangeHandler(selectedPlant);
                  }}
                >
                  {selectPlantState.map((option) => (
                    <MenuItem key={option.plantkey} value={option.plantkey}>
                      {option.plantname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {subscribeday !== null && subscribeday > -90 ? (
              subscribeday < 0 ? (
                <Chip
                  label={`서비스 만료 D${subscribeday}`}
                  color="error"
                  variant="outlined"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1, bgcolor: '#fff' }}
                />
              ) : (
                <Chip
                  label="서비스 만료"
                  color="error"
                  variant="contained"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1 }}
                />
              )
            ) : null}
          </>
        ) : (
          <>
            <Card sx={{ border: '0.15px solid #D8D8D8', p: 1.5, width: isDesktop ? '45%' : '100%' }}>
              <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                {plantState.plantname}
              </Typography>
            </Card>
            {subscribeday !== null && subscribeday > -90 ? (
              subscribeday < 0 ? (
                <Chip
                  label={`서비스 만료 D${subscribeday}`}
                  color="error"
                  variant="outlined"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1, bgcolor: '#fff' }}
                />
              ) : (
                <Chip
                  label="서비스 만료"
                  color="error"
                  variant="contained"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1 }}
                />
              )
            ) : null}
          </>
        )}
      </Box>
      {isDesktop || isIPadMini ? (
        <>
          <TabMenu menu="alarms" subValue={subValue} setSubValue={setSubValue} />
          <WebAlarmSearchBar
            setSearchState={setSearchState}
            plantState={plantState}
            fromDateValue={fromDateValue}
            setFromDateValue={setFromDateValue}
            toDateValue={toDateValue}
            setToDateValue={setToDateValue}
            selectFromDateChangeHandler={selectFromDateChangeHandler}
            selectToDateChangeHandler={selectToDateChangeHandler}
            selectPlantInvterChangeHandler={selectPlantInvterChangeHandler}
            combinePlantInv={combinePlantInv}
          />
        </>
      ) : (
        <AppAlarmSearchBar
          setSearchState={setSearchState}
          plantState={plantState}
          fromDateValue={fromDateValue}
          setFromDateValue={setFromDateValue}
          toDateValue={toDateValue}
          setToDateValue={setToDateValue}
          selectFromDateChangeHandler={selectFromDateChangeHandler}
          selectToDateChangeHandler={selectToDateChangeHandler}
          selectPlantInvterChangeHandler={selectPlantInvterChangeHandler}
          combinePlantInv={combinePlantInv}
        />
      )}
      <Box sx={{ mt: isDesktop ? 1 : 0, filter: subscribeday - graceperiodDay > 0 ? 'blur(5px)' : '' }}>
        {componentToShow}
      </Box>
      {openSubscriptionGuideModal && (
        <SubscriptionGuideModal
          plantkey={plantState.plantkey}
          plantName={plantState.plantname}
          open={openSubscriptionGuideModal}
          onClose={closeSubscriptionGuideModalHandler}
        />
      )}
    </>
  );
}
