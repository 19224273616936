// 발전소 연계 요청 수락 혹은 거절 컴포넌트
import {
  Breadcrumbs,
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  useMediaQuery,
  Card,
} from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import SensorOccupiedOutlinedIcon from '@mui/icons-material/SensorOccupiedOutlined';
import { StyledBreadcrumb } from '../../../utils/cssStyles';
import { useTheme } from '@mui/material/styles';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import config from '../../../config';
import { useNavigate, useParams } from 'react-router-dom';
import { useState, useEffect, useContext } from 'react';
import useBackendV2 from '../../../hooks/use-backend-v2';
import Header02 from '../../Layout/Header02';
import SubHeader from '../../Layout/SubHeader';
import Nav from '../../Layout/Nav';
import useResponsive from '../../../hooks/useResponsive';
import AuthContext from '../../../store/auth-context';

export default function AcceptConnectionPlant() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isDesktop = useResponsive('up', 'lg');
  const authCtx = useContext(AuthContext);

  const navigate = useNavigate();

  const goBackClickHandler = () => {
    navigate(`${config.frontend.connectionPlants}`);
  };

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));

  const distance = 0;

  const [row, setRow] = useState([]);
  const { ukey } = useParams();
  const { radioValue } = useParams();
  const [comment, setComment] = useState('');
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const backendRequest = useBackendV2();

  const fetchAcceptRequestConn = async () => {
    try {
      const { data } = await backendRequest.sendRequest(`${config.backend.endPoint.plants.detail}/${ukey}`);
      setRow(data);
      if (data.proctype === '0' || data.proctype === '2') {
        setComment(data.comment);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  function handleChange(e) {
    setComment(e.target.value);
  }

  const handleAprroveClick = async () => {
    try {
      const requestConfig = {
        method: 'PUT',
        body: {
          comment: comment,
          plantkey: row.plantkey,
          id: row.id,
        },
      };
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.approve}/${ukey}`,
        requestConfig
      );
      alert('발전소를 연계하였습니다.');
      navigate(`${config.frontend.connectionPlants}`);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const handleCancelClick = async () => {
    try {
      const requestConfig = {
        method: 'PUT',
        body: {
          comment: comment,
          plantkey: row.plantkey,
          id: row.id,
        },
      };

      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.cancel}/${ukey}`,
        requestConfig
      );
      alert('발전소 연계 취소 처리합니다.');
      navigate(`${config.frontend.connectionPlants}`);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const handleDenyClick = async () => {
    try {
      const requestConfig = {
        method: 'PUT',
        body: {
          comment: comment,
          plantkey: row.plantkey,
          id: row.id,
        },
      };

      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.deny}/${ukey}`,
        requestConfig
      );
      alert('발전소 연계 불가 처리합니다.');
      navigate(`${config.frontend.connectionPlants}`);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const handleCommentForDenyClick = async () => {
    try {
      const requestConfig = {
        method: 'PUT',
        body: {
          comment: comment,
          plantkey: row.plantkey,
          id: row.id,
        },
      };

      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.deny}/${ukey}`,
        requestConfig
      );
      alert('거부 사유 수정합니다.');
      navigate(`${config.frontend.connectionPlants}`);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  // 신청상태에 따른 색상변경 훅
  const getStatusColor = (proctype) => {
    switch (proctype) {
      case '0':
        return '#ffab00'; // 신청 - 노랑
      case '1':
        return '#43a047'; // 완료 - 연두
      case '2':
        return '#f44336'; // 거부 - 붉은색
      default:
        return '#607d8b'; // 취소 - 회색
    }
  };

  // 모바일 및 테블릿 화면 구성을 위한 상수 선언
  const isGalaxyFold = useMediaQuery('(min-width: 280px) and (max-width: 319px)');
  const isGalaxyS9 = useMediaQuery('(min-width: 320px) and (max-width: 369px)');
  const isTablet = useMediaQuery('(min-width: 600px) and (max-width: 672px)');

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchAcceptRequestConn();
    fetchUserStylePreference();
  }, []);

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      {isMobile ? (
        radioValue === 'received' ? (
          <Container
            sx={{
              width: '100%',
              arginBottom: '4%',
            }}
          >
            {' '}
            {/* 모바일 연계신청 받은 발전소 상세페이지 */}
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <StyledBreadcrumb
                label="발전소 연계"
                icon={<SensorOccupiedOutlinedIcon fontSize="small" color="primary" />}
              />
              <StyledBreadcrumb label={isGalaxyFold || isGalaxyS9 ? '내용' : '신청받은 내용'} />
            </Breadcrumbs>
            <Card
              sx={{
                p: 3,
                mt: 2.5,
                borderRadius: '11px',
                boxShadow: '0px 3px 12px #00000014',
              }}
            >
              <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={isGalaxyFold || isGalaxyS9 ? 12 : 6}>
                  <Button
                    variant="text"
                    size="large"
                    startIcon={<ArrowBackIcon fontSize="large" />}
                    onClick={goBackClickHandler}
                    sx={{ mb: isGalaxyFold || isGalaxyS9 ? 1 : 0, color: '#000' }}
                  >
                    돌아가기
                  </Button>
                </Grid>
                <Grid item xs={isGalaxyFold || isGalaxyS9 ? 12 : 6}>
                  <Card
                    sx={{
                      mb: isGalaxyFold || isGalaxyS9 ? 1 : 0,
                      p: 0.3,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '11px',
                      boxShadow: '0px 3px 12px #00000014',
                      backgroundColor: getStatusColor(row.proctype),
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 0.5, color: '#fff', textAlign: 'center', width: '100%' }}
                    >
                      신청상태 :{' '}
                      {row.proctype === '0'
                        ? '신청'
                        : row.proctype === '1'
                        ? '완료'
                        : row.proctype === '2'
                        ? '거부'
                        : '취소'}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
              {row.length === 0 ? null : (
                <>
                  <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
                    신청자: {row.uname}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    소속: {row.ogname}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    전화번호: {row.hp}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    발전소 이름: {row.pname}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    발전소 주소: {row.street}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mr: 2 }}>
                    사유 :
                  </Typography>
                  <TextField
                    fullWidth
                    hiddenLabel
                    variant="outlined"
                    multiline
                    rows={6}
                    value={row.proctype === '0' || row.proctype === '2' ? comment : row.comment}
                    onChange={row.proctype === '0' || row.proctype === '2' ? handleChange : null}
                  />
                  {row.proctype === '0' ? (
                    <Grid item xs={12} display="flex" justifyContent="center" gap={2} mt={3}>
                      <Button variant="contained" sx={{ width: '250px' }} onClick={handleAprroveClick}>
                        발전소 연계 승인
                      </Button>
                      <Button variant="contained" sx={{ width: '250px' }} onClick={handleDenyClick}>
                        발전소 연계 불가
                      </Button>
                    </Grid>
                  ) : row.proctype === '2' ? (
                    <Grid item xs={12} display="flex" justifyContent="center" gap={2} mt={3}>
                      <Button variant="contained" sx={{ width: '250px' }} onClick={handleCommentForDenyClick}>
                        사유 수정
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                </>
              )}
            </Card>
          </Container>
        ) : (
          <Container
            sx={{
              width: '100%',
              arginBottom: '4%',
            }}
          >
            {' '}
            {/* 모바일 연계신청한 발전소 상세페이지 */}
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <StyledBreadcrumb
                label="발전소 연계"
                icon={<SensorOccupiedOutlinedIcon fontSize="small" color="primary" />}
              />
              <StyledBreadcrumb label={isGalaxyFold || isGalaxyS9 ? '내용' : '신청한내용'} />
            </Breadcrumbs>
            <Card
              sx={{
                p: 3,
                mt: 2.5,
                borderRadius: '11px',
                boxShadow: '0px 3px 12px #00000014',
              }}
            >
              <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                <Grid item xs={isGalaxyFold || isGalaxyS9 ? 12 : 6}>
                  <Button
                    variant="text"
                    size="large"
                    startIcon={<ArrowBackIcon fontSize="large" />}
                    onClick={goBackClickHandler}
                    sx={{ mb: isGalaxyFold || isGalaxyS9 ? 1 : 0, color: '#000' }}
                  >
                    돌아가기
                  </Button>
                </Grid>
                <Grid item xs={isGalaxyFold || isGalaxyS9 ? 12 : 6}>
                  <Card
                    sx={{
                      mb: isGalaxyFold || isGalaxyS9 ? 1 : 0,
                      p: 0.3,
                      width: '100%',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      borderRadius: '11px',
                      boxShadow: '0px 3px 12px #00000014',
                      backgroundColor: getStatusColor(row.proctype),
                    }}
                  >
                    <Typography
                      variant="subtitle1"
                      sx={{ ml: 0.5, color: '#fff', textAlign: 'center', width: '100%' }}
                    >
                      신청상태 :{' '}
                      {row.proctype === '0'
                        ? '신청'
                        : row.proctype === '1'
                        ? '완료'
                        : row.proctype === '2'
                        ? '거부'
                        : '취소'}
                    </Typography>
                  </Card>
                </Grid>
              </Grid>
              {row.length === 0 ? null : (
                <>
                  <Typography variant="subtitle1" sx={{ mt: 2, mb: 2 }}>
                    신청자: {row.uname}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    소속: {row.ogname}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    전화번호: {row.hp}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    발전소 이름: {row.pname}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mb: 2 }}>
                    발전소 주소: {row.street}
                  </Typography>
                  <Typography variant="subtitle1" sx={{ mr: 2 }}>
                    사유 :
                  </Typography>
                  <TextField
                    fullWidth
                    hiddenLabel
                    variant="outlined"
                    multiline
                    rows={6}
                    value={row.comment}
                    onChange={handleChange}
                  />
                </>
              )}
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                {row.proctype === '0' ? (
                  <Button
                    variant="contained"
                    size="large"
                    sx={{ width: '250px' }}
                    onClick={handleCancelClick}
                  >
                    발전소 연계 취소
                  </Button>
                ) : (
                  ''
                )}
              </Box>
            </Card>
          </Container>
        )
      ) : radioValue === 'received' ? (
        <Container
          sx={{
            width: '100%',
            arginBottom: '4%',
          }}
        >
          {' '}
          {/* 웹 연계신청 받은 발전소 상세페이지 */}
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <StyledBreadcrumb
              label="발전소 연계"
              icon={<SensorOccupiedOutlinedIcon fontSize="small" color="primary" />}
            />
            <StyledBreadcrumb label="신청 받은 내용" />
          </Breadcrumbs>
          <Card
            sx={{
              p: 3,
              mt: 2.5,
              borderRadius: '11px',
              boxShadow: '0px 3px 12px #00000014',
            }}
          >
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item xs={9} sm={isTablet ? 8 : 9} md={9} lg={9}>
                <Button
                  variant="text"
                  size="large"
                  startIcon={<ArrowBackIcon fontSize="large" />}
                  onClick={goBackClickHandler}
                  sx={{ color: '#000' }}
                >
                  돌아가기
                </Button>
              </Grid>
              <Grid item xs={3} sm={isTablet ? 4 : 3} md={3} lg={3}>
                <Card
                  sx={{
                    p: 0.3,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '11px',
                    boxShadow: '0px 3px 12px #00000014',
                    backgroundColor: getStatusColor(row.proctype),
                  }}
                >
                  <Typography variant="subtitle1" sx={{ ml: 0.5, color: '#fff' }}>
                    신청상태 :{' '}
                    {row.proctype === '0'
                      ? '신청'
                      : row.proctype === '1'
                      ? '완료'
                      : row.proctype === '2'
                      ? '거부'
                      : '취소'}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {row.length === 0 ? null : (
                <>
                  <Grid item xs={12} display="flex" justifyContent="space-between">
                    <Grid item xs={12} sm={7} md={7} lg={6}>
                      <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        신청자: {row.uname}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        소속: {row.ogname}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        전화번호: {row.hp}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        발전소 이름: {row.pname}
                      </Typography>
                      <Typography variant="subtitle1">발전소 주소: {row.street}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={6}>
                      <Typography variant="subtitle1" sx={{ mr: 2 }}>
                        사유 :
                      </Typography>
                      <TextField
                        fullWidth
                        hiddenLabel
                        variant="outlined"
                        multiline
                        rows={6}
                        value={row.proctype === '0' || row.proctype === '2' ? comment : row.comment}
                        onChange={row.proctype === '0' || row.proctype === '2' ? handleChange : null}
                      />
                    </Grid>
                  </Grid>
                  {row.proctype === '0' ? (
                    <Grid item xs={12} display="flex" justifyContent="center" gap={2} mt={3}>
                      <Button
                        variant="contained"
                        size="large"
                        sx={{ width: '250px' }}
                        onClick={handleAprroveClick}
                      >
                        발전소 연계 승인
                      </Button>
                      <Button
                        variant="contained"
                        size="large"
                        sx={{ width: '250px' }}
                        onClick={handleDenyClick}
                      >
                        발전소 연계 불가
                      </Button>
                    </Grid>
                  ) : row.proctype === '2' ? (
                    <Grid item xs={12} display="flex" justifyContent="center" gap={2} mt={3}>
                      <Button
                        variant="contained"
                        size="large"
                        sx={{ width: '250px' }}
                        onClick={handleCommentForDenyClick}
                      >
                        사유 수정
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                </>
              )}
            </Grid>
          </Card>
        </Container>
      ) : (
        <Container
          sx={{
            width: '100%',
            arginBottom: '4%',
          }}
        >
          {' '}
          {/* 웹 연계신청한 발전소 상세페이지 */}
          <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
            <StyledBreadcrumb
              label="발전소 연계"
              icon={<SensorOccupiedOutlinedIcon fontSize="small" color="primary" />}
            />
            <StyledBreadcrumb label="신청한 내용" />
          </Breadcrumbs>
          <Card
            sx={{
              p: 3,
              mt: 2.5,
              borderRadius: '11px',
              boxShadow: '0px 3px 12px #00000014',
            }}
          >
            <Grid container spacing={2} justifyContent="space-between" alignItems="center">
              <Grid item xs={9} sm={isTablet ? 8 : 9} md={9} lg={9}>
                <Button
                  variant="text"
                  size="large"
                  startIcon={<ArrowBackIcon fontSize="large" />}
                  onClick={goBackClickHandler}
                  sx={{ color: '#000' }}
                >
                  돌아가기
                </Button>
              </Grid>
              <Grid item xs={3} sm={isTablet ? 4 : 3} md={3} lg={3}>
                <Card
                  sx={{
                    p: 0.3,
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: '11px',
                    boxShadow: '0px 3px 12px #00000014',
                    backgroundColor: getStatusColor(row.proctype),
                  }}
                >
                  <Typography variant="subtitle1" sx={{ ml: 0.5, color: '#fff' }}>
                    신청상태 :{' '}
                    {row.proctype === '0'
                      ? '신청'
                      : row.proctype === '1'
                      ? '완료'
                      : row.proctype === '2'
                      ? '거부'
                      : '취소'}
                  </Typography>
                </Card>
              </Grid>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {row.length === 0 ? null : (
                <>
                  <Grid item xs={12} display="flex" justifyContent="space-between">
                    <Grid item xs={12} sm={7} md={7} lg={6}>
                      <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        신청자: {row.uname}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        소속: {row.ogname}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        전화번호: {row.hp}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ mb: 2 }}>
                        발전소 이름: {row.pname}
                      </Typography>
                      <Typography variant="subtitle1">발전소 주소: {row.street}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={6}>
                      <Typography variant="subtitle1" sx={{ mr: 2 }}>
                        사유 :
                      </Typography>
                      <TextField
                        fullWidth
                        hiddenLabel
                        variant="outlined"
                        multiline
                        rows={6}
                        value={row.comment}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
              {row.proctype === '0' ? (
                <Button variant="contained" size="large" sx={{ width: '250px' }} onClick={handleCancelClick}>
                  발전소 연계 취소
                </Button>
              ) : (
                ''
              )}
            </Box>
          </Card>
        </Container>
      )}
    </>
  );
}
