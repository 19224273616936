import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import config from '../../config';
import { useNavigate } from 'react-router-dom';
import AttachmentIcon from '@mui/icons-material/Attachment';
import { Link } from 'react-router-dom';

const StyledTableCell = styled(TableCell)(({ theme, processStatusNm }) => {
  const statusColor = getStatusColor(processStatusNm);
  return {
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#2065D1',
      color: theme.palette.common.white,
      fontSize: '13px',
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: '13px',
      color: statusColor.color,
      fontWeight: statusColor.fontWeight,
      borderBottom: '1px solid #e0e0e0',
    },
  };
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f0f0f0',
  },
  '&:last-child td, &:last-child th': {
    borderBottom: '1px solid #e0e0e0',
  },
}));

// 처리 상태 색상을 위한 훅
const getStatusColor = (processStatusNm) => {
  switch (processStatusNm) {
    case '접수':
      return {
        color: '#ffab00',
        fontWeight: 'bold',
      };
    case '완료':
      return {
        color: '#43a047',
        fontWeight: 'bold',
      };
    case '불가':
      return {
        color: '#f44336',
        fontWeight: 'bold',
      };
    case '처리중':
      return {
        color: '#607d8b',
        fontWeight: 'bold',
      };
    default:
      return {
        color: '#000000',
        fontWeight: 'normal',
      };
  }
};

/*
통합문의게시판 리스트 컴포넌트(웹뷰)
InquiryBoardSearchForm > InquiryBoardList(웹뷰 리스트 페이지),InquiryBoardListMobile(모바일뷰 리스트 페이지)
*/
export default function InquiryBoardList({ rows }) {
  const navigate = useNavigate();

  // 통합문의게시판 상세 페이지 이동
  function handleGoDetail(seq) {
    navigate(`${config.frontend.inquiryBoardsdetail}/${seq}`);
  }

  const columns = [
    { field: 'title', headerName: '제목', width: '20%' },
    { field: 'gubunNm', headerName: '문의구분', width: '20%' },
    { field: 'processStatusNm', headerName: '처리 상태', width: '20%' },
    { field: 'regDate', headerName: '등록일', width: '20%' },
    { field: 'fileKey', headerName: '파일', width: '20%' },
  ];

  return (
    <>
      <Card
        sx={{
          mt: 2,
          borderRadius: 0,
          boxShadow: '0px 3px 12px #00000014',
        }}
      >
        <StyledTableContainer>
          <Table size="small" sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.field}
                    align="center"
                    style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                  >
                    {column.headerName}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.length > 0 ? (
                rows.map((row, index) => (
                  <StyledTableRow
                    key={row.seq}
                    onClick={() => handleGoDetail(row.seq)}
                    sx={{
                      cursor: 'pointer',
                      '&:hover': { backgroundColor: '#cfd8dc !important' },
                    }}
                  >
                    {columns.map((column) => (
                      <StyledTableCell
                        key={column.field}
                        align={column.field === 'title' ? 'left' : 'center'}
                        style={{
                          minWidth: column.width,
                          backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                          borderRight: '1px solid #d0d0d0',
                        }}
                      >
                        {column.field === 'processStatusNm' ? (
                          <span
                            style={{
                              color: getStatusColor(row[column.field]).color,
                              fontWeight: getStatusColor(row[column.field]).fontWeight,
                            }}
                          >
                            {row[column.field]}
                          </span>
                        ) : column.field === 'regDate' ? (
                          row[column.field].substring(0, 10)
                        ) : column.field === 'fileKey' ? (
                          row[column.field] === null || row[column.field] === 0 ? (
                            ''
                          ) : (
                            <Link
                              to={`https://filedeliver.sensorcube.co.kr/S/File/GetFile?domain=${process.env.REACT_APP_DOMAIN}&id=${row.fileKey}`}
                            >
                              <AttachmentIcon fontSize="medium" />
                            </Link>
                          )
                        ) : (
                          row[column.field]
                        )}
                      </StyledTableCell>
                    ))}
                  </StyledTableRow>
                ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell colSpan={5} sx={{ textAlign: 'center' }}>
                    조회된 정보가 없습니다.
                  </StyledTableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Card>
    </>
  );
}
