import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CustomModal from '../../components/UI/CustomModal';
import config from '../../config';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../store/auth-context';
import { useNavigate } from 'react-router-dom';
import WalletOutlinedIcon from '@mui/icons-material/WalletOutlined';
import wooribank from '../../assets/EtcImg/wooribank.png';
import { fNumberWithCommas } from '../../utils/formatNumber';
import useBackendV2 from '../../hooks/use-backend-v2';
import { useCookies } from 'react-cookie';
import useInput from '../../hooks/use-input';
import { validatePhone } from '../../utils/validationUtil';
import CloseIcon from '@mui/icons-material/Close';

// 무통장 입금 안내 모달 컴포넌트
export default function DepositorPaymentPage({
  plantkey,
  orderid,
  pdtname,
  price,
  userName,
  phoneNumber,
  address,
  detailAddress,
  zip,
  setCartProducts,
  open,
  onClose,
}) {
  const authCtx = useContext(AuthContext);
  const navigate = useNavigate();

  const name = authCtx?.currentUser?.name;

  const [cookies, setCookie, removeCookie] = useCookies(['orderid']);
  const [plantUserList, setPlantUserList] = useState([]);
  const [selectedPlantUserId, setSelectedPlantUserId] = useState('');
  const [isSmsUse, setIsSmsUse] = useState(true);

  const nameInput = useInput();
  const phoneNumberInput = useInput((value) => validatePhone(value));

  const backendRequest = useBackendV2();

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    navigate(config.frontend.products);
    onClose();
  };

  const fetchUserInfo = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.info}/${authCtx.currentUser.id}`
      );

      phoneNumberInput.setValue(data.hp);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchPlantUserList = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.plantUserList}/${plantkey}`
      );

      setPlantUserList(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const closeDepositorModalHandler = async () => {
    const removeAllRequestConfig = {
      method: 'DELETE',
    };

    const result = await backendRequest.sendRequest(
      `${config.backend.endPoint.carts.allDel}/${orderid}`,
      removeAllRequestConfig
    );

    if (result.data === true) {
      onClose();
    } else {
      window.location.reload();
      onClose();
    }
  };

  const confirmClickHandler = async () => {
    if (nameInput.value === '' || phoneNumberInput.value === '') {
      alert('입금자 명과 전화 번호를 입력해 주세요.');
      return;
    }

    try {
      const requestConfig = {
        method: 'POST',
        body: {
          USerId:
            authCtx.currentUser.id === 'ssadmin' && plantkey !== undefined
              ? selectedPlantUserId
              : authCtx.currentUser.id,
          OrderId: orderid,
          OrderName: pdtname,
          Amount: price,
          PaymentKey: null,
          Zipcode: zip === '' ? null : zip,
          Address: address === '' ? null : address,
          Address2: detailAddress === '' ? null : detailAddress,
          UserName: userName === '' ? null : userName,
          PhoneNumber: phoneNumber === '' ? null : phoneNumber,
          DepositorName: nameInput.value === '' ? null : nameInput.value,
          DepositorPhoneNumber: phoneNumberInput.value === '' ? null : phoneNumberInput.value,
          IsSms: isSmsUse,
        },
      };

      const result = await backendRequest.sendRequest(
        config.backend.endPoint.payments.requestBankTransfer,
        requestConfig
      );

      if (result.data === true) {
        removeCookie('orderid');
        alert('무통장 입금 신청이 완료되었습니다.');
        setCartProducts([]);
      } else {
        alert('진행중 오류가 발생하였습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
    closeModalHandler();
  };

  const getDateAfterSevenDays = () => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + 3);
    return currentDate.toISOString().split('T')[0]; // 'YYYY-MM-DD' 형식으로 반환
  };

  const onChangeSelectedPlantUserId = (e, plantUserList) => {
    const selectedUser = plantUserList?.find((d) => d.id === e.target.value);

    setSelectedPlantUserId(selectedUser.id);
    nameInput.setValue(selectedUser.uname);
    phoneNumberInput.setValue(selectedUser.hp);
  };

  const futureDate = getDateAfterSevenDays();

  useEffect(() => {
    if (name) {
      fetchUserInfo();
      nameInput.setValue(name);
    }

    if (plantkey !== undefined) {
      fetchPlantUserList();
    }
  }, [name]);

  const formatPhoneNumber = (phone) => {
    if (!phone) return phone;
    return phone.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');
  };

  return (
    <>
      <CustomModal
        open={open}
        onClose={closeModalHandler}
        sx={{ width: { xs: '95%', md: 650 }, maxHeight: '95%' }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '16px',
            right: '16px',
            cursor: 'pointer',
          }}
        >
          <CloseIcon onClick={closeDepositorModalHandler} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <WalletOutlinedIcon />
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>무통장 입금</Typography>
        </Box>
        <hr />
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1.5 }}>
            - 무통장 계좌 안내
          </Typography>
          <img src={wooribank} style={{ width: '160px' }} />
          <Typography variant="h3">1005-404-615846</Typography>
          <Typography variant="h6">예금주 : (주)큐브소프트</Typography>
        </Box>
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1">주문번호 : {orderid}</Typography>
          <Typography variant="subtitle1">상품명 : {pdtname}</Typography>
          <Typography variant="subtitle1">
            입금 금액 : <span style={{ color: 'red', fontSize: '1.4rem' }}>{fNumberWithCommas(price)}</span>원
          </Typography>
          <Typography variant="subtitle1">입금 기한 : {futureDate}</Typography>
          <Divider sx={{ mt: 1 }} />
          {authCtx.currentUser.id === 'ssadmin' && plantkey !== undefined ? (
            <>
              <Box sx={{ mt: 1, mb: 2 }}>
                <Typography variant="subtitle1">입금자 아이디:</Typography>
                <Select
                  variant="outlined"
                  sx={{ width: '100%', mt: 0.5 }}
                  value={selectedPlantUserId}
                  onChange={(e) => onChangeSelectedPlantUserId(e, plantUserList)}
                >
                  {plantUserList?.map((item, index) => (
                    <MenuItem key={index} value={item.id}>
                      아이디: {item.id}, 사용자 명: {item.uname}, 휴대폰 뒷자리: {item.hp4}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </>
          ) : null}
          <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
            <Typography variant="subtitle1">
              <span style={{ color: 'red' }}>*</span>입금자 명:
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              required
              sx={{ backgroundColor: 'white', ml: 1 }}
              onChange={nameInput.valueChangeHandler}
              onBlur={nameInput.inputBlurHandler}
              value={nameInput.value}
            />
          </Box>
          <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
            <Typography variant="subtitle1">
              <span style={{ color: 'red' }}>*</span>전화 번호:{' '}
            </Typography>
            <TextField
              variant="outlined"
              size="small"
              required
              sx={{ backgroundColor: 'white', ml: 1 }}
              onChange={phoneNumberInput.valueChangeHandler}
              onBlur={phoneNumberInput.inputBlurHandler}
              error={phoneNumberInput.hasError}
              helperText={phoneNumberInput.hasError && '핸드폰 번호 형식이 올바르지 않습니다.'}
              value={formatPhoneNumber(phoneNumberInput.value)}
            />
          </Box>
          {authCtx.currentUser.id === 'ssadmin' && plantkey !== undefined ? (
            <>
              <Box>
                <FormControlLabel
                  control={<Checkbox onChange={() => setIsSmsUse(!isSmsUse)} />}
                  label="문자 전송 안함"
                  sx={{ ml: 0.5 }}
                />
              </Box>
            </>
          ) : null}
        </Box>
        <Divider sx={{ mt: 1.5, mb: 1.5 }} />
        <Box>
          <Typography variant="subtitle1" sx={{ mb: 1.5 }}>
            - 무통장 입금 안내
          </Typography>
          <Typography variant="subtitle1">
            1. 입금자명을 주문 번호 또는 발전소 이름으로 입력해 주세요.
          </Typography>
          <Typography variant="subtitle2" sx={{ color: '#808080', ml: 1 }}>
            예) 입금자명 : 홍길동 발전소 혹은 Ho123456
          </Typography>
          <Typography variant="subtitle1" sx={{ mt: 1.5 }}>
            2. 주문 금액과 입금액이 다르지 않도록 입금해 주세요.
          </Typography>
          <Typography variant="subtitle2" sx={{ color: '#808080', ml: 1 }}>
            예) 주문 금액 : {fNumberWithCommas(price)}원 vs. 입금 금액 : {fNumberWithCommas(price + 1000)}원
          </Typography>
        </Box>
        <Divider sx={{ mt: 1 }} />
        <Box sx={{ mt: 1 }}>
          <Typography variant="subtitle1" sx={{ mb: 1.5, color: 'red' }}>
            - 세금 계산서 발행 안내
          </Typography>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            입금자명, 연락처(전화), 발행용 이메일, 사업자 등록증 사본(파일 첨부)을
          </Typography>
          <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center' }}>
            다음 이메일(<span style={{ color: 'red' }}>am@qubesoft.kr</span>)로 보내주세요.
          </Typography>
        </Box>
        <Divider sx={{ mt: 1.5, mb: 1 }} />
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">위 사항과 다르게 입금했거나 문의 사항이 있으면,</Typography>
          <Typography variant="h6">
            통합 문의 게시판을 이용하시거나 고객센터(1566-6403)에 연락주시기 바랍니다.
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
          <Button variant="contained" onClick={confirmClickHandler} sx={{ width: 250 }}>
            확인
          </Button>
        </Box>
      </CustomModal>
    </>
  );
}
