import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';

export default function AllBulletinboardDetail({ bulletinboardDetailClickHandler }) {
  return (
    <List component="nav">
      <ListItemButton onClick={bulletinboardDetailClickHandler}>
        <ListItemIcon>
          <ContentPasteIcon />
        </ListItemIcon>
        <ListItemText primary="게시판" />
      </ListItemButton>
    </List>
  );
}
