import { Box, Divider, Tab, Tabs, Typography, useTheme } from '@mui/material';
import CustomModal from '../../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import { useState } from 'react';
import SettingPlant from './SettingPlant';
import ManagementCompanyInfo from './ManagementCompanyInfo';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <>
          <Divider />
          <Box sx={{ p: 0, mt: 2 }}>{children}</Box>
        </>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export default function PlantSettingModal({ plantkey, plantName, open, onClose }) {
  const theme = useTheme();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
      <CustomModal
        open={open}
        onClose={closeModalHandler}
        sx={{ maxHeight: '95%', p: 3, width: { xs: '95%', md: 700 } }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <SolarPowerIcon />
          <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>발전소 설정</Typography>
        </Box>
        <hr />
        <Box sx={{ flexGrow: 1, borderBottom: 0, borderColor: '#808080', bgcolor: '', mt: 2 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons={false}
            allowScrollButtonsMobile
            aria-label="visible arrows tabs example"
          >
            <Tab label="발전소 정보" {...a11yProps(0)} />
            <Tab label="관리업체 정보" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0} dir={theme.direction}>
          <SettingPlant plantkey={plantkey} />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1} dir={theme.direction}>
          <ManagementCompanyInfo plantkey={plantkey} />
        </CustomTabPanel>
      </CustomModal>
    </>
  );
}
