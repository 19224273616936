import { useState, useEffect } from 'react';
import { Button, Checkbox, Typography, FormControlLabel, Box } from '@mui/material';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { blue } from '@mui/material/colors';
import { useCookies } from 'react-cookie';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';

export default function NoticePopup({ noticeType }) {
  const [rows, setRows] = useState([]);

  const [openModals, setOpenModals] = useState({}); // 각각의 모달 열림 상태 관리
  const [checked, setChecked] = useState({}); // 각각의 체크박스 상태 관리
  const [cookieStates, setCookieStates] = useState({}); // 각각의 모달 쿠키 상태
  const [cookies, setCookies] = useCookies(); // 쿠키

  // 모달 열림/닫힘을 관리하는 함수
  const handleCloseModal = (row) => {
    const { seq, blockPeriod } = row;
    setOpenModals((prev) => ({ ...prev, [seq]: false }));

    // 모달이 닫힐 때 쿠키를 설정
    if (checked[seq]) {
      const expires = getExpiredDate(blockPeriod);
      const cookieName = noticeType === '2' ? `beforePopup_${seq}` : `afterPopup_${seq}`;
      setCookies(cookieName, true, { path: '/', expires });
    }
  };

  const handleChange = (e, seq) => {
    setChecked((prev) => ({ ...prev, [seq]: e.target.checked }));
  };

  // 쿠키의 유효기한
  const getExpiredDate = (days) => {
    const date = new Date();

    if (days === -1) {
      // date.setFullYear(date.getFullYear() + 2);
      date.setDate(date.getDate() + 3600);
    } else {
      // days가 양수일 경우, 해당 일수만큼 더함
      date.setDate(date.getDate() + days);
    }

    return date;
  };

  // 페이지 열면 바로 쿠키 유무 확인
  useEffect(() => {
    const checkCookieExistence = () => {
      let updatedCookieStates = {};
      rows.forEach((row) => {
        const cookieName = noticeType === '2' ? `beforePopup_${row.seq}` : `afterPopup_${row.seq}`;
        updatedCookieStates[row.seq] = !!cookies[cookieName];
      });
      setCookieStates(updatedCookieStates);
    };

    if (rows.length > 0) {
      checkCookieExistence();
    }
  }, [rows, cookies]);

  const noticePopupList = useBackendV2();

  const fetchNoticePopupList = async () => {
    try {
      const requestConfig = {
        method: 'GET',
      };
      const isAuthenticated = false;
      const { data } = await noticePopupList.sendRequest(
        `${config.backend.endPoint.noticePopup.basic}?noticeType=${noticeType}`,
        requestConfig,
        isAuthenticated
      );
      setRows(data);

      // 각 공지사항에 대한 모달 초기 상태 설정
      const initialOpenDialogs = data.reduce((acc, row) => {
        acc[row.seq] = true; // 기본적으로 열려 있게 설정
        return acc;
      }, {});
      setOpenModals(initialOpenDialogs);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const getCheckboxLabel = (blockPeriod) => {
    if (blockPeriod === 1) {
      return '하루동안 보지 않기';
    } else if (blockPeriod > 1) {
      return `${blockPeriod}일 보지 않기`;
    } else if (blockPeriod === -1) {
      return '다시 보지 않기';
    }
    return '';
  };

  useEffect(() => {
    fetchNoticePopupList();
  }, []);

  return (
    <>
      {rows.length !== 0 &&
        rows.map((row, index) => {
          // 각 모달의 쿠키 상태에 따라 렌더링
          if (!cookieStates[row.seq]) {
            return (
              <CustomModal
                open={openModals[row.seq] || false}
                onClose={() => handleCloseModal(row)} // row 전체를 전달하여 blockPeriod에 접근
                key={row.seq}
                sx={{
                  maxHeight: '95%',
                  width: { xs: '90%', md: 500 },
                  p: 1,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    position: 'absolute',
                    top: '16px',
                    right: '16px',
                  }}
                >
                  <CloseIcon onClick={() => handleCloseModal(row)} sx={{ cursor: 'pointer' }} />
                </Box>
                <br />
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1, mt: 1 }}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize: '1.09rem' }}>
                    {row.title}
                  </Typography>
                </Box>
                <hr />
                <Box
                  sx={{
                    mt: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                  }}
                >
                  <Box dangerouslySetInnerHTML={{ __html: row.contents }} />
                </Box>
                <hr />
                <Box display="flex" justifyContent="end" sx={{ mt: 2 }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked[row.seq] || false}
                        onChange={(e) => handleChange(e, row.seq)}
                      />
                    }
                    label={
                      <Typography sx={{ fontSize: '14px', fontWeight: 'bold', color: blue[800] }}>
                        {/* 라벨을 동적으로 설정 */}
                        {getCheckboxLabel(row.blockPeriod)}
                      </Typography>
                    }
                  />
                  <Button variant="contained" onClick={() => handleCloseModal(row)} color="primary">
                    닫기
                  </Button>
                </Box>
              </CustomModal>
            );
          }
          return null;
        })}
    </>
  );
}
