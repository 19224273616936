import { Box, Card, Typography } from '@mui/material';

// 개별 발전소 웹뷰 RTU상태 컴포넌트
export default function WebWidgetRtuStatus({ basePlantInfo }) {
  return (
    <Card
      sx={{
        // bgcolor: '#0059B2',
        borderRadius: '15px',
        boxShadow: '5px 6px 12px 5px #00000014',
        height: 115,
        p: 1,
      }}
    >
      <Box display="flex" justifyContent="center" sx={{ mt: 1 }}>
        <Typography variant="subtitle1">RTU 상태</Typography>
      </Box>
      <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
        <Card
          sx={{
            borderRadius: '7px',
            boxShadow: '0px 3px 100px #00000014',
            width: '95%',
            display: 'flex',
            justifyContent: 'center',
            p: 1,
            backgroundColor: basePlantInfo?.some((plant) =>
              plant.rtuinfos?.some((info) => info.rtustatus === 1)
            )
              ? '#4F8C14'
              : '#FF1F30',
          }}
        >
          <Typography variant="subtitle2" sx={{ color: '#fff' }}>
            {basePlantInfo?.some((plant) => plant.rtuinfos?.some((info) => info.rtustatus === 1))
              ? '통신 중'
              : '통신 중단'}
          </Typography>
        </Card>
      </Box>
    </Card>
  );
}
