import { Box, Typography } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import AnnouncementOutlinedIcon from '@mui/icons-material/AnnouncementOutlined';
import CallIcon from '@mui/icons-material/Call';
import useResponsive from '../../hooks/useResponsive';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';

export default function PaymentRtunStatusModal({ plantName, orderStatusRTUN, open, onClose }) {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <CustomModal open={open} onClose={onClose} sx={{ width: { xs: '95%', md: 650 }, maxHeight: '95%' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
        <AnnouncementOutlinedIcon />
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>구독 연장 라이선스 안내</Typography>
      </Box>
      <hr />
      <Box>
        <Typography variant="subtitle1">
          {plantName}의 RTU({orderStatusRTUN.map((rtu) => rtu.rtun).join(', ')})에 대한 구매 내역을
          확인하였습니다.
        </Typography>
        <Typography variant="subtitle1">아래와 같이 구매 내역을 안내드립니다:</Typography>
      </Box>
      <hr />
      <Box>
        {orderStatusRTUN.map((rtu) => {
          if (rtu.status !== 'WAITING' && rtu.status !== 'WAITING_QUBE') {
            return null;
          }

          const approveDate = new Date(rtu.approveDate);
          const formattedDate = approveDate
            .toLocaleDateString('ko-KR')
            .replace(/\. /g, '-')
            .replace(/\.$/, '');
          const formattedTime = approveDate.toLocaleTimeString('ko-KR', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          });
          const formattedApproveDate = `${formattedDate} ${formattedTime}`;

          return (
            <Box key={rtu.orderid}>
              <Typography variant="h6" sx={{ mb: 0.5 }}>
                RTU 번호 : {rtu.rtun}
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                결제 상태 :{' '}
                {rtu.status === 'WAITING_QUBE'
                  ? '무통장 입금 대기중'
                  : rtu.status === 'WAITING'
                  ? '입금 대기중'
                  : null}
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                주문 번호 : {rtu.orderid}
              </Typography>
              <Typography variant="subtitle1" sx={{ mb: 0.5 }}>
                결제일 : {formattedApproveDate}
              </Typography>
              <hr />
            </Box>
          );
        })}
      </Box>
      <Box sx={{ mt: 2 }}>
        <Typography variant="subtitle1">
          해당 내역에 관한 문의 사항이나 추가 도움이 필요하시면 언제든지 고객 센터로 연락해 주시기 바랍니다.
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 2.5 }}>
        <CallIcon sx={{ mr: 0.5 }} />
        <Typography variant="h6">문의전화:</Typography>
        {isDesktop ? (
          <Typography variant="h6" sx={{ ml: 0.5 }}>
            1566-6403
          </Typography>
        ) : (
          <Typography variant="h6" sx={{ ml: 0.5 }}>
            <a href="tel:1566-6403" style={{ textDecoration: 'none', color: 'inherit' }}>
              1566-6403
            </a>
          </Typography>
        )}
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 2, mb: 2 }}>
        <ForwardToInboxIcon sx={{ mr: 1 }} />
        <Typography variant="h6">이메일:</Typography>
        <Typography variant="h6" sx={{ ml: 0.5 }}>
          am@qubesoft.kr
        </Typography>
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 2 }}>
        <Typography variant="subtitle1">경기도 오산시 외삼미로15번길 54-2, 큐브빌딩 (외삼미동)</Typography>
      </Box>
      <Box display="flex" alignItems="center" sx={{ mt: 0 }}>
        <Typography variant="subtitle2">(구주소: 경기도 오산시 외삼미동 459-111)</Typography>
      </Box>
    </CustomModal>
  );
}
