import { useContext, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { useCookies } from 'react-cookie';
import '../../css/PaymentStyle.css';
import { Typography } from '@mui/material';
import { fNumberWithCommas } from '../../utils/formatNumber';
import AuthContext from '../../store/auth-context';

// 결제 성공 및 최종 승인 페이지 컴포넌트
export default function PaymentPageSuccess() {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const [isConfirmed, setIsConfirmed] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['orderid']);

  const [searchParams] = useSearchParams();
  const paymentKey = searchParams.get('paymentKey');
  const orderId = searchParams.get('orderId');
  const amount = searchParams.get('amount');

  const backendRequest = useBackendV2();

  const confirmPayment = async () => {
    try {
      const confirmPaymentRequestConfig = {
        method: 'POST',
        body: {
          userid: authCtx?.currentUser?.id,
          orderId: orderId,
          orderName: localStorage.getItem('pdtname'),
          amount: amount,
          paymentKey: paymentKey,
          Zipcode: localStorage.getItem('zip'),
          Address: localStorage.getItem('address'),
          Address2: localStorage.getItem('detailAddress'),
          UserName: localStorage.getItem('userName'),
          PhoneNumber: localStorage.getItem('phoneNumber'),
          // plantkey: localStorage.getItem('plantkey'),
        },
      };
      const confirmPaymentResult = await backendRequest.sendRequest(
        config.backend.endPoint.payments.confirm,
        confirmPaymentRequestConfig
      );

      if (confirmPaymentResult.data !== null) {
        setIsConfirmed(true);
        removeCookie('orderid');
        // navigate('/');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발행하였습니다.');
    }
  };

  useEffect(() => {
    confirmPayment();
  }, []);

  return (
    <div className="wrapper w-100">
      <div
        className="flex-column align-center confirm-success w-100 max-w-540"
        style={{
          display: 'flex',
        }}
      >
        <img src="https://static.toss.im/illusts/check-blue-spot-ending-frame.png" width="120" height="120" />
        <h2 className="title">결제 완료</h2>
        <div className="response-section w-100">
          <div className="flex justify-between">
            <span className="response-label">상품명</span>
            <span id="amount" className="response-text">
              {localStorage.getItem('pdtname')}
            </span>
          </div>
          <div className="flex justify-between">
            <span className="response-label">결제 금액</span>
            <span id="amount" className="response-text">
              {fNumberWithCommas(amount)}원
            </span>
          </div>
          <div className="flex justify-between">
            <span className="response-label">주문번호</span>
            <span id="orderId" className="response-text">
              {orderId}
            </span>
          </div>
          {/* <div className="flex justify-between">
              <span className="response-label">paymentKey</span>
              <span id="paymentKey" className="response-text">
                {paymentKey}
              </span>
            </div> */}
        </div>

        <div className="w-100 button-group">
          <Typography
            className="btn primary"
            display="flex"
            justifyContent="center"
            onClick={() => {
              localStorage.setItem('menuValue', 0);
              localStorage.setItem('menuSubValue', 0);
              localStorage.removeItem('address');
              localStorage.removeItem('detailAddress');
              localStorage.removeItem('zip');
              localStorage.removeItem('userName');
              localStorage.removeItem('phoneNumber');
              localStorage.removeItem('price');
              localStorage.removeItem('pdtname');
              localStorage.removeItem('plantkey');
              localStorage.removeItem('@payment-widget/previous-payment-method-id');
              localStorage.removeItem('@tosspayments/client-id');
              removeCookie('orderid');
              navigate('/', { state: { subValue: 0 } });
            }}
          >
            홈으로
          </Typography>
        </div>
      </div>
      {/* {isConfirmed ? (
        <div
          className="flex-column align-center confirm-success w-100 max-w-540"
          style={{
            display: 'flex',
          }}
        >
          <img
            src="https://static.toss.im/illusts/check-blue-spot-ending-frame.png"
            width="120"
            height="120"
          />
          <h2 className="title">결제 완료</h2>
          <div className="response-section w-100">
            <div className="flex justify-between">
              <span className="response-label">결제 금액</span>
              <span id="amount" className="response-text">
                {amount}원
              </span>
            </div>
            <div className="flex justify-between">
              <span className="response-label">주문번호</span>
              <span id="orderId" className="response-text">
                {orderId}
              </span>
            </div>
          </div>

          <div className="w-100 button-group">
            <Typography
              className="btn primary"
              display="flex"
              justifyContent="center"
              onClick={() => {
                localStorage.setItem('menuValue', 0);
                localStorage.setItem('menuSubValue', 0);
                localStorage.removeItem('address');
                localStorage.removeItem('detailAddress');
                localStorage.removeItem('zip');
                localStorage.removeItem('userName');
                localStorage.removeItem('phoneNumber');
                localStorage.removeItem('price');
                localStorage.removeItem('pdtname');
                navigate('/', { state: { subValue: 0 } });
              }}
            >
              홈으로
            </Typography>
          </div>
        </div>
      ) : (
        <div className="flex-column align-center confirm-loading w-100 max-w-540">
          <div className="flex-column align-center">
            <img src="https://static.toss.im/lotties/loading-spot-apng.png" width="120" height="120" />
            <h2 className="title text-center">결제 요청까지 성공했어요.</h2>
            <h4 className="text-center description">결제 승인하고 완료해보세요.</h4>
          </div>
          <div className="w-100">
            <button className="btn primary w-100" onClick={confirmPayment}>
              결제 승인하기
            </button>
          </div>
        </div>
      )} */}
    </div>
  );
}
