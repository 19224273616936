import {
  Badge,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Popover,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { fNumberWithCommas } from '../../utils/formatNumber';
import PaymentPage from '../../pages/Payment/PaymentPage';
import { useCookies } from 'react-cookie';
import KakaoFindAddressModal from './KakaoFindAddressModal';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import DepositorPaymentPage from '../../pages/Payment/DepositorPaymentPage';

const StyledRoot = styled('div')(({ theme }) => ({
  zIndex: 999,
  right: 0,
  display: 'flex',
  cursor: 'pointer',
  position: 'fixed',
  alignItems: 'center',
  top: theme.spacing(16),
  height: theme.spacing(7),
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  paddingTop: theme.spacing(1.25),
  boxShadow: theme.customShadows.z20,
  color: '#fff',
  backgroundColor: '#0D63F5',
  borderTopLeftRadius: Number(theme.shape.borderRadius) * 1.5,
  borderBottomLeftRadius: Number(theme.shape.borderRadius) * 1.5,
  transition: theme.transitions.create('opacity'),
  '&:hover': { opacity: 0.72 },
}));

// 장바구니내 상품 표시 컴포넌트
export default function ProductCartWidget({ putInShoppingCart, setPutInShoppingCart }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);
  const [cartProducts, setCartProducts] = useState([]);

  const [checkPaymentStatusModal, setCheckPaymentStatusModal] = useState(false);
  const [kakaoFindAddressModal, setKakaoFindAddressModal] = useState(false);

  const [checkDepositorStatus, setCheckDepositorStatus] = useState(false);
  const [openDepositorStatusModal, setOpenDepositorStatusModal] = useState(false);

  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [address, setAddress] = useState('');
  const [detailAddress, setDetailAddress] = useState('');
  const [zip, setZip] = useState('');

  const [cookies, setCookie, removeCookie] = useCookies(['orderid']);

  const backendRequest = useBackendV2();

  const fetchCartProductList = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.carts.basic}/${cookies.orderid}`
      );
      const cartProductsWithId = data.map((item, index) => ({
        ...item,
        id: index + 1,
      }));

      setCartProducts(cartProductsWithId);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
    setCheckPaymentStatusModal(false);
  };

  const removeAllClickHandelr = async () => {
    setPutInShoppingCart(true);
    try {
      const removeAllRequestConfig = {
        method: 'DELETE',
      };

      const result = await backendRequest.sendRequest(
        `${config.backend.endPoint.carts.allDel}/${cookies.orderid}`,
        removeAllRequestConfig
      );

      if (result.data === true) {
        alert('삭제가 완료되었습니다.');
        removeCookie('orderid', { path: '/' });
        setPutInShoppingCart(false);
        setOpen(null);
      } else {
        alert('오류가 발생하였습니다.');
        setPutInShoppingCart(false);
        setOpen(null);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
      setOpen(null);
    }
  };

  const removeShoppingCartProductClickHandler = async (orderid, ordersubid) => {
    setPutInShoppingCart(true);
    try {
      const removeShoppingCartProductRequestConfig = {
        method: 'DELETE',
      };

      const result = await backendRequest.sendRequest(
        `${config.backend.endPoint.carts.basic}/${orderid}/${ordersubid}`,
        removeShoppingCartProductRequestConfig
      );

      if (result.data === true) {
        alert('삭제가 완료되었습니다.');
        setPutInShoppingCart(false);
        setOpen(null);
      } else {
        alert('오류가 발생하였습니다.');
        setPutInShoppingCart(false);
        setOpen(null);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
      setOpen(null);
    }
  };

  const paymentProgressClickHandler = () => {
    if (cartProducts.length === 0) {
      alert('상품을 장바구니에 담아주세요.');
    } else {
      if (deliveryProduct === true) {
        setKakaoFindAddressModal(true);
      } else {
        setCheckPaymentStatusModal(true);
      }
    }
    setOpen(null);
  };
  const closePaymentPageClickHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setCheckPaymentStatusModal(false);
  };

  const paymentDepostiorClickHandler = () => {
    if (cartProducts.length === 0) {
      alert('상품을 장바구니에 담아주세요.');
    } else {
      if (deliveryProduct === true) {
        setKakaoFindAddressModal(true);
      } else {
        setOpenDepositorStatusModal(true);
      }
    }
    setOpen(null);
  };
  const closePaymentDepositorClickHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setCheckDepositorStatus(false);
    setOpenDepositorStatusModal(false);
  };

  const closeKakaoFindAddressModalClickHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setKakaoFindAddressModal(false);
  };

  const moveProductDetailClickHandler = (pdtid, pdttype) => {
    navigate(`${config.frontend.productdetails}/${pdtid}`, {
      state: { pdttype: pdttype },
    });
  };

  const totalCount = cartProducts.reduce((sum, item) => sum + item.cnt, 0);
  const totalPrice = cartProducts.reduce((sum, item) => sum + item.price + item.vatprice * item.cnt, 0);
  let deliveryPrice = 0;

  let finalTotalPrice = totalPrice;

  // 상품 가격의 합이 50000 이하인 경우
  if (totalPrice <= 50000) {
    // delprice가 0인 경우가 있는지 확인
    const hasZeroDelPrice = cartProducts.some((item) => item.delprice === 0);

    if (!hasZeroDelPrice) {
      // delprice가 모두 0이 아닌 경우 최소 delprice를 구함
      const minDelPrice = Math.min(...cartProducts.map((item) => item.delprice));
      finalTotalPrice += minDelPrice;
      deliveryPrice = minDelPrice;
    }
  }

  let deliveryProduct = false;

  cartProducts.forEach((item) => {
    if (item.pdttype === 1 || item.pdttype === 3 || item.pdttype === 4) {
      deliveryProduct = true;
    }
  });

  useEffect(() => {
    fetchCartProductList();
  }, [putInShoppingCart]);

  return (
    <>
      <StyledRoot onClick={handleOpen}>
        <Badge showZero badgeContent={cartProducts.length} color="error" max={99}>
          <ShoppingCartIcon fontSize="large" />
        </Badge>
      </StyledRoot>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 400,
            boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.4)',
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="h6" noWrap>
            장바구니 목록
          </Typography>
          <Button variant="text" size="small" color="error" onClick={removeAllClickHandelr}>
            전체 삭제
          </Button>
        </Box>
        <Divider sx={{ mt: 1, mb: 1 }} />
        <Stack sx={{ p: 0.5, bgcolor: '#ebebeb' }}>
          {cartProducts.length > 0 ? (
            <>
              {cartProducts.map((item, index) => (
                <Box key={index}>
                  <Card key={item.id} sx={{ mb: 0.5, p: 1 }}>
                    <Box display="flex" alignItems="center" justifyContent="space-between">
                      <Typography
                        variant="subtitle1"
                        onClick={() => moveProductDetailClickHandler(item.pdtid, item.pdttype)}
                        sx={{ cursor: 'pointer' }}
                      >
                        {item.pdtname} X {item.cnt}개
                      </Typography>
                      <Tooltip title="장바구니 삭제" arrow>
                        <IconButton
                          onClick={() => removeShoppingCartProductClickHandler(item.orderid, item.ordersubid)}
                          size="small"
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                    <Typography variant="subtitle2" display="flex" justifyContent="end" sx={{ mr: 1, mt: 1 }}>
                      금액 : {fNumberWithCommas(item.price)}원
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      display="flex"
                      justifyContent="end"
                      sx={{ mr: 1, mt: 0.2, color: 'text.disabled' }}
                    >
                      부가세 : {fNumberWithCommas(item.vatprice * item.cnt)}원
                    </Typography>
                  </Card>
                </Box>
              ))}
            </>
          ) : (
            <Typography variant="subtitle1">장바구니에 등록된 상품이 없습니다</Typography>
          )}
        </Stack>
        {cartProducts.length > 0 ? (
          <>
            <Divider sx={{ mt: 1, mb: 1 }} />
            {deliveryPrice <= 50000 && deliveryPrice !== 0 ? (
              <Typography variant="subtitle2" display="flex" justifyContent="end" sx={{ mr: 1, mb: 1 }}>
                배송 금액 : {fNumberWithCommas(deliveryPrice)}원
              </Typography>
            ) : (
              <>
                <Typography variant="subtitle2" display="flex" justifyContent="end" sx={{ mr: 1 }}>
                  배송 금액 : 무료
                </Typography>
                {totalPrice > 50000 ? (
                  <Typography
                    variant="subtitle2"
                    display="flex"
                    justifyContent="end"
                    sx={{ mr: 1, mb: 1, color: 'text.secondary' }}
                  >
                    (배송 금액이 50,000원 초과 시 배송 무료)
                  </Typography>
                ) : (
                  <Typography
                    variant="subtitle2"
                    display="flex"
                    justifyContent="end"
                    sx={{ mr: 1, mb: 1, color: 'text.secondary' }}
                  >
                    (배송 무료 상품 포함)
                  </Typography>
                )}
              </>
            )}
            <Typography variant="subtitle1" display="flex" justifyContent="end" sx={{ mr: 1 }}>
              총 상품 금액 : {fNumberWithCommas(finalTotalPrice)}원
            </Typography>
          </>
        ) : null}
        <Divider sx={{ mt: 1, mb: 1 }} />
        <FormControlLabel
          control={<Checkbox onChange={() => setCheckDepositorStatus(!checkDepositorStatus)} />}
          label="무통장 입금"
          sx={{ ml: 0.5 }}
        />
        <Box sx={{ ml: 1 }}>
          <Typography variant="subtitle1">
            <span style={{ color: 'red' }}>무통장입금</span>보다는{' '}
            <span style={{ color: 'red' }}>퀵계좌 이체</span> (일반결제)가 더 편리합니다.
          </Typography>
          <Typography variant="subtitle1">
            (<span style={{ color: 'red' }}>지출증빙</span>용 현금 영수증 발행이 즉시 가능합니다.)
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" sx={{ mt: 1.5, mb: 1.5 }}>
          {checkDepositorStatus ? (
            <Button variant="contained" onClick={paymentDepostiorClickHandler} sx={{ mr: 1 }}>
              무통장 입금
            </Button>
          ) : (
            <Button variant="contained" onClick={paymentProgressClickHandler} sx={{ mr: 1 }}>
              구매하기
            </Button>
          )}
        </Box>
      </Popover>
      {kakaoFindAddressModal && (
        <KakaoFindAddressModal
          setCheckPaymentStatusModal={setCheckPaymentStatusModal}
          setOpenDepositorStatusModal={setOpenDepositorStatusModal}
          checkDepositorStatus={checkDepositorStatus}
          userName={userName}
          setUserName={setUserName}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
          address={address}
          setAddress={setAddress}
          detailAddress={detailAddress}
          setDetailAddress={setDetailAddress}
          setZip={setZip}
          setCartProducts={setCartProducts}
          open={kakaoFindAddressModal}
          onClose={closeKakaoFindAddressModalClickHandler}
        />
      )}
      {checkPaymentStatusModal && (
        <PaymentPage
          orderid={cookies.orderid}
          pdtname={
            cartProducts.length > 1
              ? `${cartProducts[0].pdtname} 외 ${totalCount}개`
              : `${cartProducts[0].pdtname} ${totalCount}개`
          }
          price={finalTotalPrice}
          userName={userName}
          phoneNumber={phoneNumber}
          address={address}
          detailAddress={detailAddress}
          zip={zip}
          open={checkPaymentStatusModal}
          onClose={closePaymentPageClickHandler}
        />
      )}
      {openDepositorStatusModal && (
        <DepositorPaymentPage
          orderid={cookies.orderid}
          pdtname={
            cartProducts.length > 1
              ? `${cartProducts[0].pdtname} 외 ${totalCount}개`
              : `${cartProducts[0].pdtname} ${totalCount}개`
          }
          price={finalTotalPrice}
          userName={userName}
          phoneNumber={phoneNumber}
          address={address}
          detailAddress={detailAddress}
          zip={zip}
          setCartProducts={setCartProducts}
          open={openDepositorStatusModal}
          onClose={closePaymentDepositorClickHandler}
        />
      )}
    </>
  );
}
