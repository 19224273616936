const ArrowIcon = ({ color, acbvcb }) => {
  return (
    <>
      <svg width={acbvcb === 'use' ? '120' : '180'} height="32" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <marker id="arrowhead" markerWidth="8" markerHeight="8" refX="6" refY="3.5" orient="auto">
            <path d="M 0 0 L 7 3.5 L 0 7 L 2 3.5 Z" fill="#333" />
          </marker>
          <style>
            {`
          .moving-dash {
            stroke-dasharray: 10, 5;
            animation: dash 0.5s linear infinite;
          }
          @keyframes dash {
            to {
              stroke-dashoffset: -15;
            }
          }
        `}
          </style>
        </defs>
        <line x1="0" y1="15" x2={acbvcb === 'use' ? '120' : '180'} y2="15" stroke="#333" strokeWidth="5" />
        <line
          x1="5"
          y1="15"
          x2={acbvcb === 'use' ? '120' : '180'}
          y2="15"
          stroke={color}
          strokeWidth="3.5"
          className="moving-dash"
          markerEnd="url(#arrowhead)"
        />
      </svg>
    </>
  );
};

export default ArrowIcon;
