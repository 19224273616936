import { Avatar, Box, Card, Collapse, Divider, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function AlarmCodeAnalysisApp({ data }) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          padding: {
            xs: 0,
            sm: 2,
          },
          borderRadius: '14px',
          boxShadow: '0px 3px 12px #00000014',
          '&:hover': { borderColor: 'blue', borderWidth: '2px' },
          width: '100%',
          mb: 1,
          backgroundColor: data.invertername === '합계' ? '#FFC107' : null,
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          width="100%"
          spacing={2}
        >
          <Stack
            sx={{
              padding: {
                xs: 1,
                sm: 0,
              },
            }}
            flex={1}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle2">{data.invertername}</Typography>
              {data.invertername === '합계' ? (
                <Box display="flex">
                  <Typography variant="subtitle2" sx={{ mr: 0.5 }}>
                    경보 발생 횟수 :
                  </Typography>
                  <Typography variant="subtitle2">{data.cnt}</Typography>
                </Box>
              ) : (
                <Avatar sx={{ width: '22px', height: '22px', mb: 1, bgcolor: '#1981E7' }}>
                  <ExpandMoreIcon
                    onClick={handleExpandClick}
                    sx={{ color: '#fff', transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)' }}
                  />
                </Avatar>
              )}
            </Box>
            <Box display="flex" alignItems="center" sx={{ mt: 1 }}>
              {data.invertername === '합계' ? null : (
                <>
                  <Card
                    sx={{
                      display: 'flex',
                      bgcolor: '#fff',
                      color: '#000',
                      borderRadius: '5px',
                      p: 0.25,
                      mr: 1,
                    }}
                  >
                    <Typography variant="body2" sx={{ mr: 0.5, fontWeight: 'bold' }}>
                      경보 발생 횟수 :
                    </Typography>
                    <Typography variant="subtitle2" color="#F3A200">
                      {data.cnt}
                    </Typography>
                  </Card>
                  <Card
                    sx={{
                      display: 'flex',
                      bgcolor: '#fff',
                      color: '#000',
                      borderRadius: '5px',
                      p: 0.25,
                    }}
                  >
                    <Typography variant="body2" sx={{ mr: 0.5, fontWeight: 'bold' }}>
                      경보 코드 :
                    </Typography>
                    <Typography variant="subtitle2" color="error">
                      {data.errnum}
                    </Typography>
                  </Card>
                </>
              )}
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Divider sx={{ mb: 1, mt: 1 }} />
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                <Typography variant="subtitle2">경보 코드</Typography>
                <Typography variant="subtitle2" textAlign="end" color="error">
                  {data.errnum}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                <Typography variant="subtitle2">경보 발생 횟수</Typography>
                <Typography variant="subtitle2" textAlign="end" color="#F3A200">
                  {data.cnt}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                <Typography variant="subtitle2">경보 상태</Typography>
                <Typography variant="subtitle2" textAlign="end">
                  {data.errstatus}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                <Typography variant="subtitle2">경보 내역</Typography>
                <Typography variant="subtitle2">{data.errmsg}</Typography>
              </Box>
              {/* <Box sx={{ mt: 2 }}>
                <Typography variant="subtitle2">경보 내역</Typography>
                <Typography variant="subtitle2" sx={{ mt: 1 }}>
                  {data.errmsg}
                </Typography>
              </Box> */}
            </Collapse>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}
