import { Box, Button, Card, Checkbox, FormControlLabel, Typography } from '@mui/material';
import '../../../css/App.css';
import { useState } from 'react';
import MobilePushSettingCard from './MobilePushSettingCard';

export default function MobilePushSetting({
  errCodes,
  setErrCodes,
  yieldCheckYN,
  setYieldCheckYN,
  saveCheckInfoClickHandler,
}) {
  const [selectAll, setSelectAll] = useState(false);

  const selectAllHandler = () => {
    // 전체 선택 또는 해제 상태를 토글
    setSelectAll((prev) => !prev);

    // 모든 항목의 isSet 값을 변경
    const updatedCheckCode = errCodes.map((row) => ({ ...row, isSet: selectAll ? '0' : '1' }));
    setErrCodes(updatedCheckCode);
  };

  // MobilePushSetting 컴포넌트의 eachCheckboxClickHandler 함수
  const eachCheckboxClickHandler = (index) => {
    // errCodes의 복사본
    const updatedErrCodes = [...errCodes];
    // 선택한 항목의 isSet 값 설정
    updatedErrCodes[index].isSet = updatedErrCodes[index].isSet === '1' ? '0' : '1';
    // 업데이트된 errCodes를 설정
    setErrCodes(updatedErrCodes);
  };

  const checkYieldYN = () => {
    setYieldCheckYN(!yieldCheckYN);
  };

  return (
    <>
      <Card
        sx={{ p: 1.5, mb: 1.5, width: '100%', borderRadius: '13px', boxShadow: '0px 3px 12px #00000014' }}
      >
        <Box display="flex" alignContent="center">
          <FormControlLabel
            control={<Checkbox onClick={selectAllHandler} />}
            label={
              selectAll ? (
                <Typography variant="subtitle2">전체 해제</Typography>
              ) : (
                <Typography variant="subtitle2">전체 선택</Typography>
              )
            }
          />
          <FormControlLabel
            control={<Checkbox onClick={checkYieldYN} checked={yieldCheckYN} />}
            label={<Typography variant="subtitle2">일일 발전량 전송</Typography>}
          />
        </Box>
        <Button variant="contained" onClick={saveCheckInfoClickHandler} sx={{ width: '100%', mt: 1 }}>
          저장
        </Button>
      </Card>
      {errCodes.map((d, index) => (
        <MobilePushSettingCard
          key={index}
          data={d}
          eachCheckboxClickHandler={() => eachCheckboxClickHandler(index)}
        />
      ))}
    </>
  );
}
