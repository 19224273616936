import useResponsive from '../../../hooks/useResponsive';
import HInverterChart from '../../Charts/Statistics/Hour/HInverterChart';
import HourInverterStatisticsTable from './HourInverterStatisticsTable';

export default function HourInverterStatistics({ data, chartView }) {
  const isDesktop = useResponsive('up', 'lg');

  // 데이터를 그룹화하기 위한 누적 객체 초기화
  const groupedData = data.reduce((acc, data) => {
    // 현재 데이터의 그룹명을 가져오기
    const groupName = data.groupname;

    // 만약 그룹이 아직 누적 객체에 존재하지 않으면
    if (!acc[groupName]) {
      acc[groupName] = []; // 빈 배열을 새로운 그룹의 초기값으로 할당
    }

    // 현재 데이터를 해당 그룹의 배열에 추가
    acc[groupName].push(data);
    return acc; // 최종 결과를 누적 객체로 반환
  }, {});

  // data 배열이 비어있는 경우 빈 배열 반환
  if (Object.keys(groupedData).length === 0) {
    return null;
  }

  // 배열 길이를 맞추기
  const maxLength = Math.max(...Object.values(groupedData).map((group) => group.length));

  // 데이터 그룹화 및 정렬
  Object.keys(groupedData).forEach((groupName) => {
    groupedData[groupName].sort((a, b) => a.year_month_day_hour - b.year_month_day_hour);
  });

  // 누락된 시간대에 대한 데이터를 추가하는 함수
  const fillMissingHours = (groupedData) => {
    const allHours = Array.from({ length: 24 }, (_, index) => index + 1); // 1부터 24까지의 시간대 배열 생성

    Object.keys(groupedData).forEach((groupName) => {
      const currentGroup = groupedData[groupName];
      const existingHours = currentGroup.map((data) => data.year_month_day_hour); // 현재 그룹의 시간대들을 가져옴

      // 누락된 시간대를 확인하고 데이터를 추가
      allHours.forEach((hour) => {
        if (!existingHours.includes(hour)) {
          // 누락된 시간대에 대한 데이터 추가
          currentGroup.push({
            groupname: groupName,
            fdate: '', // fdate가 빈 값으로 채워져야 할지 여부는 데이터에 따라 다를 수 있다
            year_month_day_hour: hour,
            yield: null, // yield가 누락된 값으로 채워짐을 나타냄
            totalyield: null, // totalyield도 누락된 값으로 채워짐을 나타냄
          });
        }
      });

      // 시간대를 기준으로 데이터 정렬
      currentGroup.sort((a, b) => a.year_month_day_hour - b.year_month_day_hour);
    });

    return groupedData;
  };

  // fillMissingHours 함수를 호출하여 누락된 시간대의 데이터를 추가
  const filledGroupedData = fillMissingHours(groupedData);

  // groupedData의 각 그룹에 대해 특정 범위의 hour를 제거
  const removeHoursOutOfRange = (group) => {
    return group.filter((data) => data.year_month_day_hour >= 5 && data.year_month_day_hour <= 20);
  };

  Object.keys(filledGroupedData).forEach((groupName) => {
    filledGroupedData[groupName] = removeHoursOutOfRange(filledGroupedData[groupName]);
  });

  // 라인 차트 데이터 포맷으로 변환
  const chartData = Array.from({ length: maxLength }).map((_, index) => {
    const entry = {
      name: filledGroupedData[Object.keys(filledGroupedData)[0]][index].year_month_day_hour,
    };
    Object.keys(filledGroupedData).forEach((groupName) => {
      entry[groupName] = filledGroupedData[groupName][index].yield;
    });

    return entry;
  });

  return (
    <>
      {isDesktop ? (
        <>
          <HInverterChart
            data={data}
            groupedData={filledGroupedData}
            chartData={chartData}
            isDesktop={isDesktop}
          />
          {data.length === 0 ? null : (
            <HourInverterStatisticsTable
              data={data}
              groupedData={filledGroupedData}
              chartData={chartData}
              isDesktop={isDesktop}
            />
          )}
        </>
      ) : chartView ? (
        <HInverterChart
          data={data}
          groupedData={filledGroupedData}
          chartData={chartData}
          isDesktop={isDesktop}
        />
      ) : (
        <HourInverterStatisticsTable
          data={data}
          groupedData={filledGroupedData}
          chartData={chartData}
          isDesktop={isDesktop}
        />
      )}
    </>
  );
}
