import { Box, CardMedia } from '@mui/material';
import { useEffect, useState } from 'react';
import useResponsive from '../../hooks/useResponsive';

export default function ShopProductCardDetailExplain({ productDetailList }) {
  const isDesktop = useResponsive('up', 'lg');

  const [useTypeDetailList, setUseTypeDetailList] = useState([]);

  useEffect(() => {
    if (productDetailList) {
      const sortedList = productDetailList
        .filter((product) => product.imgUsageType === '2')
        .sort((a, b) => a.imgOrder - b.imgOrder);

      setUseTypeDetailList(sortedList);
    }
  }, [productDetailList]);

  return (
    <>
      {useTypeDetailList.map((product, index) => (
        <Box key={index}>
          <Box display="flex" justifyContent="center">
            <CardMedia
              component="img"
              image={`https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=SC&id=${product.filekey}`}
              style={{ width: isDesktop ? '42%' : '100%' }}
            />
          </Box>
          <hr />
        </Box>
      ))}
    </>
  );
}
