import {
  Avatar,
  Box,
  Card,
  Chip,
  ClickAwayListener,
  Collapse,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  PiCloudDuotone,
  PiCloudFogDuotone,
  PiCloudLightningDuotone,
  PiCloudMoonDuotone,
  PiCloudRainDuotone,
  PiCloudSnowDuotone,
  PiCloudSunDuotone,
  PiMoonStarsDuotone,
  PiSunDuotone,
} from 'react-icons/pi';
import { useContext, useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PlantSettingModal from '../../Setting/PlantSetting/PlantSettingModal';
import EntirePlantIvtInfo from './EntirePlantIvtInfo';
import WeatherInfoModal from '../WeatherInfoModal';
import useResponsive from '../../../hooks/useResponsive';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SubscriptionGuideModal from '../SubscriptionGuideModal';
import EntirePlantDetaile from './EntirePlantDetail';
import UseCustomSort from '../../../hooks/useCustomSort';
import AuthContext from '../../../store/auth-context';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';

const weatherIconMap = {
  '001-1': <PiSunDuotone style={{ fontSize: '2rem', color: 'red', marginRight: 3 }} />,
  '001-2': <PiMoonStarsDuotone style={{ fontSize: '2rem', color: '#D5C450', marginRight: 3 }} />,
  '002-1': <PiCloudSunDuotone style={{ fontSize: '2rem', color: '#999999', marginRight: 3 }} />,
  '002-2': <PiCloudMoonDuotone style={{ fontSize: '2rem', color: '#999999', marginRight: 3 }} />,
  '003': <PiCloudDuotone style={{ fontSize: '2rem', color: '#999999', marginRight: 3 }} />,
  '004': <PiCloudRainDuotone style={{ fontSize: '2rem', color: '#8490e1', marginRight: 3 }} />,
  '005': <PiCloudFogDuotone style={{ fontSize: '2rem', color: '#999999', marginRight: 3 }} />,
  '006': <PiCloudSnowDuotone style={{ fontSize: '2rem', color: '#9B9CA2', marginRight: 3 }} />,
  '007': <PiCloudLightningDuotone style={{ fontSize: '2rem', color: '#D3A612', marginRight: 3 }} />,
};

// 전체 대시보드 리스트 구성 컴포넌트
export default function EntirePlantInfoCard({ plantkey, plantName, address, subscribeday, setPage, page }) {
  const isDesktop = useResponsive('up', 'lg');
  const authCtx = useContext(AuthContext);

  const [todayWeather, setTodayWeather] = useState('');

  const [expanded, setExpanded] = useState(false);
  const [abbreviateAddress, setAbbreviateAddress] = useState(false);
  const [openPlantsetting, setOpenPlantSetting] = useState(false);
  const [openWeatherInfoModal, setOpenWeatherInfoModal] = useState(false);
  const [openSubscriptionGuideModal, setOpenSubscriptionGuideModal] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [haveCctv, setHaveCctv] = useState(false);

  const [plantTooltipOpenMap, setPlantTooltipOpenMap] = useState({});
  const [plantIvtTooltipOpenMap, setPlantIvtTooltipOpenMap] = useState({});
  const [plantStatisticsTooltipOpenMap, setPlantStatisticsTooltipOpenMap] = useState({});

  const [rsInverters, setRsInverters] = useState(null);
  const [basePlantInfo, setBasePlantInfo] = useState({});

  const nodata = basePlantInfo?.rtuinfos?.some((info) => info.rtustatus === 1) ? 0 : 1;

  const backendRequest = useBackendV2();

  const fetchWeatherObservation = async () => {
    try {
      const weatherObservationRequestConfig = {
        method: 'POST',
        body: {
          address: address,
        },
      };
      const weatherObservationResult = await backendRequest.sendRequest(
        config.backend.endPoint.envs.weather,
        weatherObservationRequestConfig
      );

      if (weatherObservationResult.data !== null) {
        setTodayWeather(weatherObservationResult.data);
      } else {
        alert('오류가 발생하였습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchBasePlantInfoData = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.baseInfo}/${plantkey}`
      );
      setBasePlantInfo(data[0]);
    } catch (error) {
      setBasePlantInfo([]);
    }
  };

  const fetchInvData = async () => {
    try {
      const invsResponse = await backendRequest.sendRequest(
        `${config.backend.endPoint.inverters.extReal}/${plantkey}`
      );

      const sortedData = UseCustomSort(invsResponse.data, 'invertername');
      setRsInverters(sortedData);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchHaveCctv = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.cctvs.haveCCTV}/${plantkey}`
      );

      if (data === 0) {
        setHaveCctv(false);
      } else {
        setHaveCctv(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
    setAbbreviateAddress(!abbreviateAddress);
  };

  const abbreviateText = (text, maxLength) => {
    if (!abbreviateAddress && text?.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  const moveEachPlantHandler = () => {
    setPage((prevPage) => ({
      ...prevPage,
      pagetype: 'each',
      plantkey: plantkey,
      plantName: plantName,
      plantAddress: address,
    }));
    localStorage.setItem('selectPlantkey', plantkey);
    localStorage.setItem('menuSubValue', 1);
  };

  const showPlantSettingHandler = () => {
    setOpenPlantSetting(true);
  };
  const closePlantSettingHandler = () => {
    setOpenPlantSetting(false);
  };

  const showWeatherInfoModalHandler = () => {
    setOpenWeatherInfoModal(true);
  };
  const closeWeatherInfoModalHandler = () => {
    setOpenWeatherInfoModal(false);
  };

  // 만료 발전소 상품 및 구독 라이선스 입력 페이지 안내 모달
  const openSubscriptionGuideModalHandler = () => {
    setOpenSubscriptionGuideModal(true);
  };
  const closeSubscriptionGuideModalHandler = () => {
    setOpenSubscriptionGuideModal(false);
  };

  // 클릭한 통신 아바타의 툴팁 상태를 열기
  const handlePlantAvatarClick = (plantkey) => {
    setPlantTooltipOpenMap((prevMap) => ({ ...prevMap, [plantkey]: true }));
  };
  // 통신 아바타의 툴팁 상태를 닫기
  const handlePlantTooltipClose = (plantkey) => {
    setPlantTooltipOpenMap((prevMap) => ({ ...prevMap, [plantkey]: false }));
  };

  // 클릭한 인버터 아바타의 툴팁 상태를 열기
  const handlePlantIvtAvatarClick = (plantkey) => {
    setPlantIvtTooltipOpenMap((prevMap) => ({ ...prevMap, [plantkey]: true }));
  };
  // 인버터 아바타의 툴팁 상태를 닫기
  const handlePlantIvtTooltipClose = (plantkey) => {
    setPlantIvtTooltipOpenMap((prevMap) => ({ ...prevMap, [plantkey]: false }));
  };

  // 클릭한 통계 아바타의 툴팁 상태를 열기
  const handlePlantStatisticsAvatarClick = (plantkey) => {
    setPlantStatisticsTooltipOpenMap((prevMap) => ({ ...prevMap, [plantkey]: true }));
  };
  // 통계 아바타의 툴팁 상태를 닫기
  const handlePlantStatisticsTooltipClose = (plantkey) => {
    setPlantStatisticsTooltipOpenMap((prevMap) => ({ ...prevMap, [plantkey]: false }));
  };

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  // 현재출력
  const totalPac = rsInverters?.reduce((accumulator, inverter) => {
    // generatestatus가 0이 아닌 경우에만 pac를 더한다
    if (inverter.generatestatus !== 0) {
      accumulator += inverter.pac;
    }
    return accumulator;
  }, 0);

  const totalPacKw = totalPac;

  const sortedRsInvertersData = UseCustomSort(rsInverters, 'invertername');

  let entirePlantIvtInfo;

  if (sortedRsInvertersData != null) {
    entirePlantIvtInfo = sortedRsInvertersData.map((d) => (
      <EntirePlantIvtInfo
        key={d.serialnum}
        iac={d.iac}
        invertername={d.invertername}
        pac={d.pac}
        serialnum={d.serialnum}
        uac={d.uac}
        nodata={nodata}
        ivtNodata={d.ivtNodata}
        generatestatus={d.generatestatus}
        dlStatus={d.dlstatus}
        isDesktop={isDesktop}
      />
    ));
  }

  useEffect(() => {
    fetchWeatherObservation();
    fetchBasePlantInfoData();
    fetchInvData();
    fetchHaveCctv();
  }, []);

  const renderCCTVIcon = () => {
    let showCCTVIcon = false;

    if (authCtx.isAndroid) {
      const match = navigator.userAgent.match(/AndroidV(\d+,\d+|\d+)/);
      if (match) {
        const android = match[1];
        const appVer = android.includes(',') ? android.split(',')[1] : null;
        if (appVer && parseInt(appVer) >= 9) {
          showCCTVIcon = true;
        }
      }
    } else if (authCtx.isiOS) {
      const match = navigator.userAgent.match(/IOSV(\d+,\d+|\d+)/);
      if (match) {
        const ios = match[1];
        const appVer = ios.includes(',') ? ios.split(',')[1] : null;
        if (appVer && parseInt(appVer) >= 16) {
          showCCTVIcon = true;
        }
      }
    }

    return showCCTVIcon ? (
      <Tooltip title="CCTV" arrow>
        <VideocamOutlinedIcon sx={{ mb: 0 }} />
      </Tooltip>
    ) : null;
  };

  return (
    <>
      <Box sx={{ pb: 0, mb: 1.5 }}>
        <Card
          variant="outlined"
          onClick={isDesktop ? handleDrawerOpen : moveEachPlantHandler}
          sx={{
            padding: {
              xs: 0.5,
              sm: 2,
            },
            borderRadius: '14px',
            boxShadow: '0px 3px 12px #00000014',
            '&:hover': { borderColor: 'blue', borderWidth: '2px' },
          }}
        >
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            width="100%"
            spacing={2}
          >
            <Stack
              sx={{
                padding: {
                  xs: 1,
                  sm: 0,
                },
              }}
              flex={1}
            >
              <Stack spacing={1} direction="row" justifyContent="space-between">
                <div>
                  <Typography
                    variant="subtitle1"
                    sx={{
                      cursor: isDesktop ? 'pointer' : 'cursor',
                      alignContent: 'center',
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    {subscribeday > -90 ? (
                      subscribeday < 0 ? (
                        <Chip
                          label={`서비스 만료 D${subscribeday}`}
                          color="error"
                          variant="outlined"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation(); // 클릭 이벤트가 부모 요소로 전파되지 않도록 함
                            openSubscriptionGuideModalHandler();
                          }}
                          sx={{ mr: 0.5, mb: 0.5 }}
                        />
                      ) : (
                        <Chip
                          label="서비스 만료"
                          color="error"
                          variant="contained"
                          size="small"
                          onClick={(e) => {
                            e.stopPropagation(); // 클릭 이벤트가 부모 요소로 전파되지 않도록 함
                            openSubscriptionGuideModalHandler();
                          }}
                          sx={{ mr: 0.5, mb: 0.5 }}
                        />
                      )
                    ) : null}
                    <span>{plantName}</span>
                    {haveCctv && renderCCTVIcon()}
                  </Typography>
                  <Typography
                    color="primary"
                    variant="subtitle2"
                    display="flex"
                    alignItems="center"
                    sx={{ mt: 1 }}
                  >
                    <LocationOnIcon />
                    {abbreviateText(address, 13)}
                  </Typography>
                </div>
                <Stack sx={{ flexGrow: 1, textAlign: 'right' }} spacing={0}>
                  <Box display="flex" justifyContent="end">
                    <Avatar sx={{ width: '22px', height: '22px', mb: 1, bgcolor: '#1981E7' }}>
                      <ExpandMoreIcon
                        onClick={(e) => {
                          e.stopPropagation(); // 클릭 이벤트가 부모 요소로 전파되지 않도록 함
                          handleExpandClick();
                        }}
                        sx={{
                          color: '#fff',
                          cursor: 'pointer',
                          transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                        }}
                      />
                    </Avatar>
                  </Box>
                  <Typography variant="body2">
                    현재 출력 :
                    <strong style={{ marginLeft: 2 }}>
                      {rsInverters?.some((inverter) => inverter.ivtNodata === 0) ? totalPacKw.toFixed(1) : 0}
                      kW
                    </strong>
                  </Typography>
                  {expanded ? (
                    <>
                      <Typography variant="body2">
                        설비 용량 :
                        <strong style={{ marginLeft: 2 }}>
                          {rsInverters?.some((inverter) => inverter.ivtNodata === 0)
                            ? basePlantInfo.capacity
                            : 0}
                          kW
                        </strong>
                      </Typography>
                    </>
                  ) : null}
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'end',
                      mb: 1,
                    }}
                  >
                    {todayWeather !== '' && weatherIconMap[todayWeather.v_img]}
                    <Typography variant="subtitle2">
                      {todayWeather !== '' ? todayWeather.v_temp : null}°C
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
              <Stack spacing={0} direction="row">
                <Box sx={{ m: 1, display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2">
                    <strong>통신</strong>
                  </Typography>
                  <Box sx={{ ml: 0.8 }}>
                    {isDesktop ? (
                      <Tooltip title={nodata === 0 ? '통신 중' : '통신 중단'} arrow>
                        <Avatar
                          sizes="small"
                          sx={{
                            width: 15,
                            height: 15,
                            bgcolor: nodata === 0 ? '#64B346' : 'red',
                            color: nodata === 0 ? '#64B346' : 'red',
                          }}
                        >
                          {''}
                        </Avatar>
                      </Tooltip>
                    ) : (
                      <ClickAwayListener onClickAway={() => handlePlantTooltipClose(plantkey)}>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            open={!!plantTooltipOpenMap[plantkey]}
                            onClose={() => handlePlantTooltipClose(plantkey)}
                            title={nodata === 0 ? '통신 중' : '통신 중단'}
                            arrow
                          >
                            <Avatar
                              sizes="small"
                              sx={{
                                width: 15,
                                height: 15,
                                bgcolor: nodata === 0 ? '#64B346' : 'red',
                                color: nodata === 0 ? '#64B346' : 'red',
                              }}
                              onClick={() => handlePlantAvatarClick(plantkey)}
                            >
                              {''}
                            </Avatar>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    )}
                  </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2">
                    <strong>인버터</strong>
                  </Typography>
                  <Box sx={{ ml: 0.8 }}>
                    {isDesktop ? (
                      <Tooltip
                        title={
                          rsInverters?.every((inverter) => inverter.generatestatus === 1)
                            ? '발전 중'
                            : rsInverters?.some((inverter) => inverter.generatestatus === 1)
                            ? '부분 발전'
                            : '발전 중단'
                        }
                        arrow
                      >
                        <Avatar
                          sizes="small"
                          sx={{
                            width: 15,
                            height: 15,
                            bgcolor: rsInverters?.every((inverter) => inverter.generatestatus === 1)
                              ? '#64B346'
                              : rsInverters?.some((inverter) => inverter.generatestatus === 1)
                              ? 'gold'
                              : 'red',
                            color: rsInverters?.every((inverter) => inverter.generatestatus === 1)
                              ? '#64B346'
                              : rsInverters?.some((inverter) => inverter.generatestatus === 1)
                              ? 'gold'
                              : 'red',
                          }}
                        >
                          {''}
                        </Avatar>
                      </Tooltip>
                    ) : (
                      <ClickAwayListener onClickAway={() => handlePlantIvtTooltipClose(plantkey)}>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            open={!!plantIvtTooltipOpenMap[plantkey]}
                            onClose={() => handlePlantIvtTooltipClose(plantkey)}
                            title={
                              rsInverters?.every((inverter) => inverter.generatestatus === 1)
                                ? '발전 중'
                                : rsInverters?.some((inverter) => inverter.generatestatus === 1)
                                ? '부분 발전'
                                : '발전 중단'
                            }
                            arrow
                          >
                            <Avatar
                              sizes="small"
                              sx={{
                                width: 15,
                                height: 15,
                                bgcolor: rsInverters?.every((inverter) => inverter.generatestatus === 1)
                                  ? '#64B346'
                                  : rsInverters?.some((inverter) => inverter.generatestatus === 1)
                                  ? 'gold'
                                  : 'red',
                                color: rsInverters?.every((inverter) => inverter.generatestatus === 1)
                                  ? '#64B346'
                                  : rsInverters?.some((inverter) => inverter.generatestatus === 1)
                                  ? 'gold'
                                  : 'red',
                              }}
                              onClick={() => handlePlantIvtAvatarClick(plantkey)}
                            >
                              {''}
                            </Avatar>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    )}
                  </Box>
                </Box>
                <Box sx={{ m: 1, display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2">
                    <strong>통계</strong>
                  </Typography>
                  <Box sx={{ ml: 0.8 }}>
                    {isDesktop ? (
                      <Tooltip
                        title={
                          rsInverters?.every((inverter) => inverter.dlstatus === 1)
                            ? '적산 중'
                            : rsInverters?.some((inverter) => inverter.dlstatus === 1)
                            ? '부분 적산'
                            : '적산 중단'
                        }
                        arrow
                      >
                        <Avatar
                          sizes="small"
                          sx={{
                            width: 15,
                            height: 15,
                            bgcolor: rsInverters?.every((inverter) => inverter.dlstatus === 1)
                              ? '#64B346'
                              : rsInverters?.some((inverter) => inverter.dlstatus === 1)
                              ? 'gold'
                              : 'red',
                            color: rsInverters?.every((inverter) => inverter.dlstatus === 1)
                              ? '#64B346'
                              : rsInverters?.some((inverter) => inverter.dlstatus === 1)
                              ? 'gold'
                              : 'red',
                          }}
                        >
                          {''}
                        </Avatar>
                      </Tooltip>
                    ) : (
                      <ClickAwayListener onClickAway={() => handlePlantStatisticsTooltipClose(plantkey)}>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            open={!!plantStatisticsTooltipOpenMap[plantkey]}
                            onClose={() => handlePlantStatisticsTooltipClose(plantkey)}
                            title={
                              rsInverters?.every((inverter) => inverter.dlstatus === 1)
                                ? '적산 중'
                                : rsInverters?.some((inverter) => inverter.dlstatus === 1)
                                ? '부분 적산'
                                : '적산 중단'
                            }
                            arrow
                          >
                            <Avatar
                              sizes="small"
                              sx={{
                                width: 15,
                                height: 15,
                                bgcolor: rsInverters?.every((inverter) => inverter.dlstatus === 1)
                                  ? '#64B346'
                                  : rsInverters?.some((inverter) => inverter.dlstatus === 1)
                                  ? 'gold'
                                  : 'red',
                                color: rsInverters?.every((inverter) => inverter.dlstatus === 1)
                                  ? '#64B346'
                                  : rsInverters?.some((inverter) => inverter.dlstatus === 1)
                                  ? 'gold'
                                  : 'red',
                              }}
                              onClick={() => handlePlantStatisticsAvatarClick(plantkey)}
                            >
                              {''}
                            </Avatar>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    )}
                  </Box>
                </Box>
                <SettingsOutlinedIcon
                  sx={{
                    color: '#808080',
                    cursor: isDesktop ? 'pointer' : 'cursor',
                    marginLeft: 'auto',
                    mt: 1,
                  }}
                  onClick={(e) => {
                    e.stopPropagation(); // 클릭 이벤트가 부모 요소로 전파되지 않도록 함
                    showPlantSettingHandler();
                  }}
                />
              </Stack>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <Box>{entirePlantIvtInfo}</Box>
              </Collapse>
            </Stack>
          </Stack>
        </Card>
      </Box>
      <EntirePlantDetaile
        page={page}
        plantkey={plantkey}
        plantName={plantName}
        address={address}
        subscribeday={subscribeday}
        rsInverters={rsInverters}
        todayWeather={todayWeather}
        moveEachPlantHandler={moveEachPlantHandler}
        openSubscriptionGuideModalHandler={openSubscriptionGuideModalHandler}
        showWeatherInfoModalHandler={showWeatherInfoModalHandler}
        showPlantSettingHandler={showPlantSettingHandler}
        drawerOpen={drawerOpen}
        handleDrawerClose={handleDrawerClose}
      />
      {openPlantsetting && (
        <PlantSettingModal
          plantkey={plantkey}
          plantName={plantName}
          open={openPlantsetting}
          onClose={closePlantSettingHandler}
        />
      )}
      {openWeatherInfoModal && (
        <WeatherInfoModal
          open={openWeatherInfoModal}
          onClose={closeWeatherInfoModalHandler}
          address={address}
        />
      )}
      {openSubscriptionGuideModal && (
        <SubscriptionGuideModal
          plantkey={plantkey}
          plantName={plantName}
          open={openSubscriptionGuideModal}
          onClose={closeSubscriptionGuideModalHandler}
        />
      )}
    </>
  );
}
