import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import { useParams, useNavigate } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../store/auth-context';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme } from '@mui/material/styles';
import useResponsive from '../../hooks/useResponsive';
import Header02 from '../Layout/Header02';
import SubHeader from '../Layout/SubHeader';
import Nav from '../Layout/Nav';

/*
요청게시판 상세 및 수정 컴포넌트
*/
export default function RequestBoardDetail() {
  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const distance = 0;

  const [row, setRow] = useState([]);

  const { seq } = useParams();

  const authCtx = useContext(AuthContext);
  const id = authCtx?.currentUser?.id;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  const backendRequest = useBackendV2();

  const fetchBulletinBoardDetail = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.bulletinBoard.detail}/${seq}`
      );
      setRow(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchBulletinBoardDetail();
    fetchUserStylePreference();
  }, []);

  const processStatus = [
    { value: '0', label: '접수' },
    { value: '1', label: '처리 중' },
    { value: '2', label: '불가' },
    { value: '3', label: '완료' },
  ];

  function handleGoList() {
    navigate(config.frontend.bulletinboards, { state: { value: 4, subValue: 0 } });
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setRow((prev) => {
      return {
        ...row,
        [name]: value,
      };
    });
  }

  const bulletinbBoardRequestUpdate = useBackendV2();

  const handleUpdate = async () => {
    if (row.plant_name === '') {
      alert('발전소명을 입력하세요');
      return;
    }
    if (row.plant_address === '') {
      alert('발전소 주소를 입력하세요');
      return;
    }
    const regExp = /[^0-9-]/g;
    if (row.owner_hp === '') {
      alert('소유주 연락처를 입력하세요');
      return;
    } else if (regExp.test(row.ownerHp) === true) {
      alert('연락처는 숫자와 - 만 가능합니다.');
      return;
    }
    if (row.safety_hp === '') {
      alert('안전관리자 연락처를 입력하세요');
      return;
    } else if (regExp.test(row.safetyHp) === true) {
      alert('연락처는 숫자와 - 만 가능합니다.');
      return;
    }
    if (row.content === '') {
      alert('내용을 입력하세요.');
      return;
    }
    try {
      const requestConfig = {
        method: 'PUT',
        body: row,
      };
      const { data } = await bulletinbBoardRequestUpdate.sendRequest(
        `${config.backend.endPoint.bulletinBoard.basic}/${seq}/${id}`,
        requestConfig
      );
      alert('수정하였습니다.');
      navigate(`${config.frontend.bulletinboards}`);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  // 모바일 반응형을 위한 상수 선언
  const isSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 513px)');
  const isMoreSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 385px)');

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      {isMobile ? (
        <>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isSmallDevice ? 'column' : null,
            }}
          >
            <Grid
              item
              xs={isSmallDevice ? 12 : 1}
              sx={{
                display: 'flex',
                alignItems: isSmallDevice ? 'left' : 'center',
                justifyContent: isSmallDevice ? 'flex-start' : 'flex-end',
              }}
            >
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <StyledBreadcrumb
                  label={isMoreSmallDevice ? '요청 게시판' : '게시판'}
                  icon={<ContentPasteIcon fontSize="small" color="primary" />}
                  galaxyFoldFontSize={isMoreSmallDevice ? '14px' : '15px'}
                />
                <StyledBreadcrumb
                  label={isMoreSmallDevice ? '작성 내용' : '요청 게시판'}
                  galaxyFoldFontSize={isMoreSmallDevice ? '14px' : '15px'}
                />
                {isMoreSmallDevice ? null : <StyledBreadcrumb label="작성 내용" />}
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              xs={isSmallDevice ? 12 : 1}
              sx={{
                display: 'flex',
                alignItems: isSmallDevice ? 'right' : 'center',
                justifyContent: isSmallDevice ? 'flex-end' : 'flex-start',
              }}
            >
              <Button
                onClick={handleGoList}
                endIcon={<ArrowForwardIcon />}
                sx={{
                  ml: 1,
                  mr: 2,
                  color: '#000',
                  textTransform: 'none',
                  mt: isSmallDevice ? 2 : 0,
                }}
              >
                <Typography variant="subtitle1">돌아가기</Typography>
              </Button>
            </Grid>
          </Grid>
          <Box
            component="form"
            sx={{
              mt: isSmallDevice ? 0 : 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'left',
              width: '100%',
            }}
          >
            {row.length === 0 ? null : (
              <>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    발전소명 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    sx={{ mt: 1, width: '100%' }}
                    name="plant_name"
                    value={row.plant_name}
                    onChange={handleChange}
                    autoComplete="off"
                    placeholder="발전소명을 입력해주세요. ＊"
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    발전소 주소 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    sx={{ mt: 1, width: '100%' }}
                    name="plant_address"
                    value={row.plant_address}
                    onChange={handleChange}
                    autoComplete="off"
                    placeholder="발전소 주소를 입력해주세요. ＊"
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    소유주 연락처 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    sx={{ mt: 1, width: '100%' }}
                    name="owner_hp"
                    value={row.owner_hp}
                    onChange={handleChange}
                    placeholder="(예시)010-0000-0000 *"
                    autoComplete="off"
                    inputProps={{ maxLength: 13 }}
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    안전관리자 연락처 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    sx={{ mt: 1, width: '100%' }}
                    name="safety_hp"
                    value={row.safety_hp}
                    onChange={handleChange}
                    placeholder="(예시)010-0000-0000 *"
                    autoComplete="off"
                    inputProps={{ maxLength: 13 }}
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    내용 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    multiline
                    rows="10"
                    sx={{ mt: 1, width: '100%', whiteSpace: 'pre-wrap' }}
                    onChange={handleChange}
                    name="content"
                    value={
                      row.content === null || row.content.length === 0
                        ? ''
                        : row.content.replace(/<br\s\/>/g, '\n')
                    }
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    처리 상태
                  </Typography>
                  <TextField
                    select
                    variant="outlined"
                    size="Normal"
                    sx={{ width: '200px' }}
                    name="request_status"
                    value={row.request_status}
                    onChange={handleChange}
                    disabled
                  >
                    <MenuItem value="any">전체</MenuItem>
                    {processStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    불가 사유
                  </Typography>
                  <TextField
                    variant="outlined"
                    multiline
                    rows="10"
                    sx={{ mt: 1, width: '100%', whiteSpace: 'pre-wrap' }}
                    onChange={handleChange}
                    name="impossible_reason"
                    value={
                      row.impossible_reason === null || row.impossible_reason.length === 0
                        ? ''
                        : row.impossible_reason.replace(/<br\s\/>/g, '\n')
                    }
                    disabled
                  />
                </CardContent>
                <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    onClick={handleGoList}
                    sx={{
                      mr: 4,
                      background: '#E5EEF7 0% 0% no-repeat padding-box',
                      color: '#0059B2',
                    }}
                  >
                    목록
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleUpdate}
                    sx={{
                      mr: 0,
                      background: '#0059B2 0% 0% no-repeat padding-box',
                      color: '#FFFFFF',
                    }}
                  >
                    등록
                  </Button>
                </Box>
              </>
            )}
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <StyledBreadcrumb label="게시판" icon={<ContentPasteIcon fontSize="small" color="primary" />} />
              <StyledBreadcrumb label="요청 게시판" />
              <StyledBreadcrumb label="작성 내용" />
            </Breadcrumbs>
            <Button
              onClick={handleGoList}
              endIcon={<ArrowForwardIcon />}
              sx={{ textTransform: 'none', ml: 1, mr: 2, color: '#000' }}
            >
              <Typography variant="subtitle1">돌아가기</Typography>
            </Button>
          </Box>
          <Box
            component="form"
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'left',
              width: '100%',
            }}
          >
            {row.length === 0 ? null : (
              <>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    발전소명 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    sx={{ mt: 1, width: '100%' }}
                    name="plant_name"
                    value={row.plant_name}
                    onChange={handleChange}
                    autoComplete="off"
                    placeholder="발전소명을 입력해주세요. ＊"
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    발전소 주소 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    sx={{ mt: 1, width: '100%' }}
                    name="plant_address"
                    value={row.plant_address}
                    onChange={handleChange}
                    autoComplete="off"
                    placeholder="발전소 주소를 입력해주세요. ＊"
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    소유주 연락처 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    sx={{ mt: 1, width: '100%' }}
                    name="owner_hp"
                    value={row.owner_hp}
                    onChange={handleChange}
                    placeholder="(예시)010-0000-0000 *"
                    autoComplete="off"
                    inputProps={{ maxLength: 13 }}
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    안전관리자 연락처 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    sx={{ mt: 1, width: '100%' }}
                    name="safety_hp"
                    value={row.safety_hp}
                    onChange={handleChange}
                    placeholder="(예시)010-0000-0000 *"
                    autoComplete="off"
                    inputProps={{ maxLength: 13 }}
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                    }}
                  >
                    내용 *
                  </Typography>
                  <TextField
                    variant="outlined"
                    required
                    multiline
                    rows="10"
                    sx={{ mt: 1, width: '100%', whiteSpace: 'pre-wrap' }}
                    onChange={handleChange}
                    name="content"
                    value={
                      row.content === null || row.content.length === 0
                        ? ''
                        : row.content.replace(/<br\s\/>/g, '\n')
                    }
                  />
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    처리 상태
                  </Typography>
                  <TextField
                    select
                    variant="outlined"
                    size="Normal"
                    sx={{ width: '200px' }}
                    name="request_status"
                    value={row.request_status}
                    onChange={handleChange}
                    disabled
                  >
                    <MenuItem value="any">전체</MenuItem>
                    {processStatus.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                </CardContent>
                <CardContent>
                  <Typography
                    sx={{
                      textAlign: 'left',
                      font: 'normal normal bold 12px/17px Noto Sans',
                      letterSpacing: '0px',
                      color: '#000000',
                      opacity: '1',
                      mb: 1,
                    }}
                  >
                    불가 사유
                  </Typography>
                  <TextField
                    variant="outlined"
                    multiline
                    rows="10"
                    sx={{ mt: 1, width: '100%', whiteSpace: 'pre-wrap' }}
                    onChange={handleChange}
                    name="impossible_reason"
                    value={
                      row.impossible_reason === null || row.impossible_reason.length === 0
                        ? ''
                        : row.impossible_reason.replace(/<br\s\/>/g, '\n')
                    }
                    disabled
                  />
                </CardContent>
              </>
            )}
            <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
              <Button
                variant="contained"
                onClick={handleGoList}
                sx={{
                  mr: 4,
                  background: '#E5EEF7 0% 0% no-repeat padding-box',
                  color: '#0059B2',
                }}
              >
                목록
              </Button>
              <Button
                variant="contained"
                onClick={handleUpdate}
                sx={{
                  mr: 0,
                  background: '#0059B2 0% 0% no-repeat padding-box',
                  color: '#FFFFFF',
                }}
              >
                등록
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
