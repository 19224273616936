import {
  Button,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import { convertStringToFormatDateString } from '../../../utils/dateUtil';
import { useState } from 'react';
import InverterDeviceControlModal from './InverterDeviceControlModal';
import InverterDeviceControlLogModal from './InverterDeviceControlLogModal';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'grey',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

export default function InverterDeviceControl({ inverterInfo, plantkey, pname }) {
  const columns = [
    { field: 'type', headerName: '장비 타입', width: 60 },
    { field: 'invertername', headerName: '명칭', width: 150 },
    { field: 'generatestatus', headerName: '상태', width: 60 },
    { field: 'rdate', headerName: '마지막 수신 시간', width: 60 },
    { field: 'serialnum', headerName: '장비 정보', width: 150 },
    { field: 'control', headerName: '제어', width: 60 },
    { field: 'log', headerName: '로그', width: 60 },
  ];

  const [invControlModal, setInvControlModal] = useState(false);
  const [invLogControlModal, setInvLogControlModal] = useState(false);

  const [selectInvSerialNum, setSelectInvSerialNum] = useState('');
  const [selectInvName, setSelectInvName] = useState('');

  const invControlClickHandler = (e1, e2) => {
    setInvControlModal(true);
    setSelectInvSerialNum(e1);
    setSelectInvName(e2);
  };
  const closeInvControlClickHandler = (e1, e2) => {
    setInvControlModal(false);
    setSelectInvSerialNum('');
    setSelectInvName('');
  };

  const invControlLogClickHandler = (e1, e2) => {
    setInvLogControlModal(true);
    setSelectInvSerialNum(e1);
    setSelectInvName(e2);
  };
  const closeInvControlLogClickHandler = (e1, e2) => {
    setInvLogControlModal(false);
    setSelectInvSerialNum('');
    setSelectInvName('');
  };

  return (
    <>
      <Typography variant="subtitle1">인버터 장비 목록</Typography>
      <StyledTableContainer>
        <Table size="small" sx={{ mt: 1 }}>
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.field}
                  align="center"
                  style={{
                    minWidth: column.width,
                    backgroundColor: '#2065D1',
                    borderRight: '1px solid #d0d0d0',
                  }}
                >
                  {column.headerName}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {inverterInfo.map((row, index) => {
              return (
                <TableRow key={index}>
                  {columns.map((column, columnIndex) => (
                    <StyledTableCell
                      key={column.field}
                      align="center"
                      style={{
                        minWidth: column.width,
                        backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                        borderRight: '1px solid #d0d0d0',
                      }}
                    >
                      {column.field === 'type' ? (
                        '인버터'
                      ) : column.field === 'generatestatus' ? (
                        <Tooltip title={row.generatestatus === 0 ? '발전 중단' : '발전 중'}>
                          <div
                            style={{
                              width: '15px',
                              height: '15px',
                              borderRadius: '50%',
                              backgroundColor: row.generatestatus === 0 ? 'red' : 'green',
                              margin: '0 auto',
                            }}
                          ></div>
                        </Tooltip>
                      ) : column.field === 'control' ? (
                        <Button
                          variant="contained"
                          size="small"
                          disabled={
                            row.generatestatus === 0 || new Date() - new Date(row.rdate) > 2 * 60 * 1000
                          }
                          onClick={() => invControlClickHandler(row.serialnum, row.invertername)}
                        >
                          제어조회
                        </Button>
                      ) : column.field === 'rdate' ? (
                        convertStringToFormatDateString(row.rdate)
                      ) : column.field === 'log' ? (
                        <Button
                          variant="contained"
                          size="small"
                          onClick={() => invControlLogClickHandler(row.serialnum, row.invertername)}
                        >
                          로그보기
                        </Button>
                      ) : (
                        row[column.field]
                      )}
                    </StyledTableCell>
                  ))}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </StyledTableContainer>
      {invControlModal && (
        <InverterDeviceControlModal
          open={invControlModal}
          onClose={closeInvControlClickHandler}
          plantkey={plantkey}
          selectInvSerialNum={selectInvSerialNum}
          selectInvName={selectInvName}
          selectType="IVT"
        />
      )}
      {invLogControlModal && (
        <InverterDeviceControlLogModal
          open={invLogControlModal}
          onClose={closeInvControlLogClickHandler}
          plantkey={plantkey}
          pname={pname}
          selectInvSerialNum={selectInvSerialNum}
          selectInvName={selectInvName}
          selectType="IVT"
        />
      )}
    </>
  );
}
