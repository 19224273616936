import CustomModal from '../../UI/CustomModal';
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import CloseIcon from '@mui/icons-material/Close';
import {
  Box,
  Divider,
  MenuItem,
  Pagination,
  Select,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useBackendV2 from '../../../hooks/use-backend-v2';
import { useEffect, useState } from 'react';
import config from '../../../config';
import { convertStringToFormatDateString } from '../../../utils/dateUtil';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: 'grey',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

// 장비제어 로그 보기 모달
export default function InverterDeviceControlLogModal({
  open,
  onClose,
  plantkey,
  pname,
  selectInvSerialNum,
  selectInvName,
  selectType,
}) {
  const columns = [
    { field: 'reqdate', headerName: '송신일', width: 80 },
    { field: 'resdate', headerName: '수신일', width: 80 },
    { field: 'acbVcbType', headerName: '장비타입', width: 100 },
    { field: 'acbname', headerName: '장비명', width: 60 },
    { field: 'uitype', headerName: '제어타입', width: 100 },
    { field: 'contName', headerName: '제어명', width: 80 },
    { field: 'reqval', headerName: '제어값', width: 80 },
    { field: 'uname', headerName: '작업자', width: 100 },
  ];

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  const backendRequest = useBackendV2();

  const [ivtDvcInfo, setIvtDvcInfo] = useState([]);
  const [acbVcbIvtDvcLogSearch, setAcbVcbIvtDvcLogSearch] = useState([]);
  const [acbVcbIvtDvcList, setAcbVcbIvtDvcList] = useState([]);

  const [selectIndexNo, setSelectIndexNo] = useState(0);
  const [selectControlOrder, setControlOrder] = useState(0);
  const [selectUIType, setSelectUIType] = useState(0);

  // 페이지 사이즈
  let pageSize = 10;
  // 총 페이지 수
  const [lastPage, setLastPage] = useState(0);
  // 현재 페이지
  const [curPage, setCurPage] = useState(1);

  const fetchIvtControlInfo = async () => {
    if (plantkey !== '') {
      try {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.inverters.ivtControlInfo}/${plantkey}/${selectInvSerialNum}/${selectType}`
        );

        setIvtDvcInfo(data);
      } catch (error) {
        setIvtDvcInfo([]);
      }
    }
  };

  const fetchIvtAcbVcbFindIndexNum = async () => {
    if (plantkey !== '') {
      try {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.acbvcbs.acbVcbIvtFindIndexNum}/${plantkey}/${ivtDvcInfo.rtun}/${selectInvSerialNum}/${selectType}`
        );

        setSelectIndexNo(data.indexNum);
        setSelectUIType(data.uiType);
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchIvtAcbVcbLogSearch = async () => {
    if (plantkey !== '') {
      try {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.acbvcbs.acbVcbIvtDvcLogSearch}/${plantkey}/${ivtDvcInfo.rtun}`
        );

        const sortedArray = data.sort((a, b) => a.indexNum - b.indexNum);

        setAcbVcbIvtDvcLogSearch(sortedArray);
      } catch (error) {
        setAcbVcbIvtDvcLogSearch([]);
      }
    }
  };

  const fetchIvtAcbVcbListCount = async () => {
    if (plantkey !== '') {
      try {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.acbvcbs.acbVcbIvtDvcListCount}/${plantkey}/${ivtDvcInfo.rtun}/${selectIndexNo}/${selectControlOrder}`
        );
        setLastPage(Math.ceil(data.cnt / pageSize));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const fetchIvtAcbVcbList = async () => {
    if (plantkey !== '') {
      try {
        const { data } = await backendRequest.sendRequest(
          `${config.backend.endPoint.acbvcbs.acbVcbIvtDvcList}/${plantkey}/${ivtDvcInfo.rtun}/${selectIndexNo}/${selectControlOrder}/${curPage}/${lastPage}`
        );
        setAcbVcbIvtDvcList(data);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    fetchIvtControlInfo();
  }, []);

  useEffect(() => {
    if (ivtDvcInfo.length !== 0) {
      fetchIvtAcbVcbFindIndexNum();
      fetchIvtAcbVcbLogSearch();
    }
  }, [ivtDvcInfo]);

  useEffect(() => {
    if (ivtDvcInfo.length !== 0) {
      fetchIvtAcbVcbListCount();
    }
  }, [selectIndexNo, selectControlOrder]);

  useEffect(() => {
    if (ivtDvcInfo.length !== 0 && lastPage !== 0) {
      fetchIvtAcbVcbList();
    }
  }, [selectIndexNo, selectControlOrder, lastPage, curPage]);

  const selectIndxNoChangeHandler = (e) => {
    setSelectIndexNo(e.target.value);
    setCurPage(1);
  };

  const selectControlOrderChangeHandler = (e) => {
    setControlOrder(e.target.value);
    setCurPage(1);
  };

  const handlePageChange = (event, value) => {
    setCurPage(value);
  };

  return (
    <CustomModal
      open={open}
      onClose={closeModalHandler}
      sx={{ width: { xs: '95%', md: 960 }, maxHeight: '95%', p: 2.5 }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
        <TextSnippetOutlinedIcon fontSize="large" />
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>{pname} 로그</Typography>
      </Box>
      <hr />
      <Box display="flex" alignItems="center" sx={{ mt: 1.5 }}>
        <Typography variant="subtitle1">RTU번호: {ivtDvcInfo.rtun}</Typography>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ ml: 1, mr: 1, bgcolor: '#000' }} />
        <Typography variant="subtitle1" sx={{ mr: 0.6 }}>
          Index No:
        </Typography>
        <Select size="small" value={selectIndexNo} onChange={selectIndxNoChangeHandler}>
          <MenuItem value={0}>전체</MenuItem>
          {acbVcbIvtDvcLogSearch?.map((item, index) => (
            <MenuItem key={index} value={item.indexNum}>
              {item.indexNum}
            </MenuItem>
          ))}
        </Select>
        <Divider orientation="vertical" variant="middle" flexItem sx={{ ml: 1, mr: 1, bgcolor: '#000' }} />
        <Typography variant="subtitle1" sx={{ mr: 0.6 }}>
          제어명령 실행 내역:
        </Typography>
        <Select size="small" value={selectControlOrder} onChange={selectControlOrderChangeHandler}>
          <MenuItem value={0}>전체</MenuItem>
          <MenuItem value={1}>설정값 제어</MenuItem>
          <MenuItem value={2}>동작 제어</MenuItem>
          <MenuItem value={3}>코드 제어</MenuItem>
        </Select>
      </Box>
      <Box>
        <StyledTableContainer>
          <Table size="small" sx={{ mt: 1 }}>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.field}
                    align="center"
                    style={{
                      minWidth: column.width,
                      backgroundColor: '#2065D1',
                      borderRight: '1px solid #d0d0d0',
                    }}
                  >
                    {column.headerName}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {acbVcbIvtDvcList.map((row, index) => {
                return (
                  <TableRow key={index}>
                    {columns.map((column, conlumnIndex) => (
                      <StyledTableCell
                        key={column.field}
                        align="center"
                        style={{
                          minWidth: column.width,
                          backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                          borderRight: '1px solid #d0d0d0',
                        }}
                      >
                        {column.field === 'reqdate'
                          ? convertStringToFormatDateString(row.reqdate)
                          : column.field === 'resdate'
                          ? convertStringToFormatDateString(row.resdate)
                          : column.field === 'acbVcbType'
                          ? row.acbVcbType + ' (' + row.dvcType + ')'
                          : column.field === 'uitype'
                          ? row.uitype === '1'
                            ? '값 설정'
                            : '동작 제어'
                          : column.field === 'uname'
                          ? row.uname + '(' + row.logid + ')'
                          : row[column.field]}
                      </StyledTableCell>
                    ))}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Box>
      <Box>
        <Pagination
          count={lastPage}
          page={curPage || 1}
          onChange={handlePageChange}
          size="large"
          sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}
        />
      </Box>
    </CustomModal>
  );
}
