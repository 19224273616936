import { Box, Button, Checkbox, Divider, Paper, TextField, Typography } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import useInput from '../../hooks/use-input';
import {
  validateBusinessNo,
  validateEmail,
  validateId,
  validateName,
  validatePhone,
} from '../../utils/validationUtil';
import { useState } from 'react';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';

export default function SafetyManagerApplyModal({ open, onClose }) {
  const [agree, setAgree] = useState(false);
  const [expand1, setExpand1] = useState(true);
  const [expand2, setExpand2] = useState(true);

  const managementCompanyNameInput = useInput((value) => value.length > 0);
  const bizNumInput = useInput((value) => validateBusinessNo(value));
  const bossNameInput = useInput((value) => validateName(value));
  const managerNameInput = useInput((value) => validateName(value));
  const idRankingOneInput = useInput((value) => validateId(value));
  const idRankingTwoInput = useInput((value) => validateId(value));
  const idRankingThreeInput = useInput((value) => validateId(value));
  const phoneInput = useInput((value) => validatePhone(value));
  const emailInput = useInput((value) => validateEmail(value));
  const etcInput = useInput();

  const formIsValid =
    managementCompanyNameInput.isValid &&
    bizNumInput.isValid &&
    bossNameInput.isValid &&
    managerNameInput.isValid &&
    idRankingOneInput.isValid &&
    idRankingTwoInput.isValid &&
    idRankingThreeInput.isValid &&
    phoneInput.isValid &&
    agree;

  const handleAgreeCheckHandler = (event) => {
    setAgree(event.target.checked);
  };

  const handleExpand1ClickHandler = () => {
    setExpand1(!expand1);
  };
  const handleExpand2ClickHandler = () => {
    setExpand2(!expand2);
  };

  const backendRequest = useBackendV2();

  const applySafetyManagerClickHandler = async () => {
    if (!formIsValid) {
      return;
    }

    try {
      const applyRequestConfig = {
        method: 'POST',
        body: {
          companyName: managementCompanyNameInput.value,
          businessNumber: bizNumInput.value,
          header: bossNameInput.value,
          name: managerNameInput.value,
          id1: idRankingOneInput.value,
          id2: idRankingTwoInput.value,
          id3: idRankingThreeInput.value,
          contact: phoneInput.value,
          email: emailInput.value,
          plantInfo: etcInput.value,
        },
      };
      const applyResult = await backendRequest.sendRequest(
        config.backend.endPoint.users.safetyManager,
        applyRequestConfig,
        false
      );

      if (applyResult.data === 0) {
        alert('안전관리자 계정 신청이 실패했습니다.');
      } else if (applyResult.data >= 1) {
        alert('안전관리자 계정 신청이 등록되었습니다.');
        onClose();
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발행하였습니다.');
    }
  };

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <>
      <CustomModal
        open={open}
        onClose={closeModalHandler}
        sx={{ maxHeight: '95%', width: { xs: '95%', md: 750 }, p: 1.5 }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '10px',
            right: '16px',
          }}
        >
          <CloseIcon onClick={closeModalHandler} fontSize="large" sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <RateReviewOutlinedIcon fontSize="large" sx={{ mt: 0.5 }} />
          <Typography variant="h5" sx={{ fontWeight: 'bold', fontSize: '1.09rem' }}>
            안전관리자 계정 신청
          </Typography>
        </Box>
        <hr />
        <Box sx={{ mt: 2 }}>
          <Typography variant="subtitle1" sx={{ color: 'navy', display: 'flex', alignItems: 'center' }}>
            [ 안전관리자 계정 사용 유의사항 ]
            {expand1 ? (
              <ExpandMoreOutlinedIcon onClick={handleExpand1ClickHandler} sx={{ cursor: 'pointer' }} />
            ) : (
              <ExpandLessOutlinedIcon onClick={handleExpand1ClickHandler} sx={{ cursor: 'pointer' }} />
            )}
          </Typography>
          {expand1 ? (
            <>
              <Typography variant="body2" sx={{ mt: 1 }}>
                1. 태양광 발전소 관리의 용도로만 사용하여야 합니다.
              </Typography>
              <Typography variant="body2">2. ID와 패스워드는 타인에게 공유 및 양도할 수 없습니다.</Typography>
              <Typography variant="body2">3. ID를 공유 사용하면 이용이 중지 될 수 있습니다.</Typography>
              <Typography variant="body2">
                4. 안전관리자 계정을 발급 받으신 후 PC로 로그인 하셔서 요청게시판에 발전소 등록 신청을
                진행하시길 바랍니다.
              </Typography>
              <Typography variant="body2">
                5. 그 이외의 이용 시 문의사항은 회사 대표 전화번호(02-6053-6402)나, 메일(am@qubesoft.kr)로
                연락 바랍니다.
              </Typography>
            </>
          ) : null}
        </Box>
        <Box sx={{ mt: 1.5 }}>
          <Typography variant="subtitle1" sx={{ color: 'navy', display: 'flex', alignItems: 'center' }}>
            [ 안전관리자 계정 신청 절차 ]
            {expand2 ? (
              <ExpandMoreOutlinedIcon onClick={handleExpand2ClickHandler} sx={{ cursor: 'pointer' }} />
            ) : (
              <ExpandLessOutlinedIcon onClick={handleExpand2ClickHandler} sx={{ cursor: 'pointer' }} />
            )}
          </Typography>
          {expand2 ? (
            <>
              <Typography variant="body2" sx={{ mt: 1 }}>
                1. 아래의 내용을 입력하여 [신청] → 관리자 [수신 및 내용 확인] → 연락처 번호 혹은 이메일로
                [통보]
              </Typography>
              <Typography variant="body2" sx={{ mt: 1, color: 'red' }}>
                ※ 안전관리자로 로그인 후 요청게시판을 이용하여, 관리하자고 하는 발전소를 연결 요청 할 수
                있습니다.
              </Typography>
            </>
          ) : null}
        </Box>
        <TextField
          label="관리 업체명"
          variant="outlined"
          required
          sx={{ mt: 1.5, width: '100%' }}
          onChange={managementCompanyNameInput.valueChangeHandler}
          onBlur={managementCompanyNameInput.inputBlurHandler}
          value={managementCompanyNameInput.value}
          error={managementCompanyNameInput.hasError}
          helperText={
            managementCompanyNameInput.hasError && '최소 2자. 최대 20자. 한글, 영문, 숫자, 특수문자 가능'
          }
        />
        <TextField
          label="사업자 등록번호"
          variant="outlined"
          required
          sx={{ mt: 1.5, width: '100%' }}
          onChange={bizNumInput.valueChangeHandler}
          onBlur={bizNumInput.inputBlurHandler}
          value={bizNumInput.value}
          error={bizNumInput.hasError}
          placeholder="000-00-00000"
          helperText={bizNumInput.hasError && '사업자 등록 번호 형식이 잘못되었습니다.'}
        />
        <TextField
          label="대표자명"
          required
          sx={{ width: '100%', mt: 1.5 }}
          value={bossNameInput.value}
          onChange={bossNameInput.valueChangeHandler}
          onBlur={bossNameInput.inputBlurHandler}
          error={bossNameInput.hasError}
          helperText={bossNameInput.hasError && '최소 2자. 최대 20자. 한글, 영문만 가능'}
        />
        <TextField
          label="관리자명"
          required
          sx={{ width: '100%', mt: 1.5 }}
          value={managerNameInput.value}
          onChange={managerNameInput.valueChangeHandler}
          onBlur={managerNameInput.inputBlurHandler}
          error={managerNameInput.hasError}
          helperText={managerNameInput.hasError && '최소 2자. 최대 20자. 한글, 영문만 가능'}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 1.5 }}>
          <TextField
            label="1순위 ID"
            required
            sx={{ width: '33%', mr: '1%' }}
            onChange={idRankingOneInput.valueChangeHandler}
            onBlur={idRankingOneInput.inputBlurHandler}
            value={idRankingOneInput.value.replace(/\s/g, '').trim()}
            error={idRankingOneInput.hasError}
          />
          <TextField
            label="2순위 ID"
            required
            sx={{ width: '33%' }}
            onChange={idRankingTwoInput.valueChangeHandler}
            onBlur={idRankingTwoInput.inputBlurHandler}
            value={idRankingTwoInput.value.replace(/\s/g, '').trim()}
            error={idRankingTwoInput.hasError}
          />
          <TextField
            label="3순위 ID"
            required
            sx={{ width: '33%', ml: '1%' }}
            onChange={idRankingThreeInput.valueChangeHandler}
            onBlur={idRankingThreeInput.inputBlurHandler}
            value={idRankingThreeInput.value.replace(/\s/g, '').trim()}
            error={idRankingThreeInput.hasError}
          />
        </Box>
        <Typography
          variant="subtitle2"
          sx={{ mt: 0.5, ml: '0.5%', color: 'red', display: 'flex', justifyContent: 'start' }}
        >
          ※ 3개의 ID중 우선 순위가 높은 순으로 확인 후 생성이 됩니다.
        </Typography>
        {idRankingOneInput.hasError || idRankingTwoInput.hasError || idRankingThreeInput.hasError ? (
          <Typography
            variant="subtitle2"
            sx={{ mt: 0, ml: '0.5%', color: 'red', display: 'flex', justifyContent: 'start' }}
          >
            (ID의 경우, 최소 4자. 최대 20자. 영문자, 숫자를 혼합해서 입력)
          </Typography>
        ) : null}
        <TextField
          label="휴대전화번호"
          required
          sx={{ width: '100%', mt: 1.5 }}
          value={phoneInput.value}
          onChange={phoneInput.valueChangeHandler}
          onBlur={phoneInput.inputBlurHandler}
          error={phoneInput.hasError}
          helperText={phoneInput.hasError && '휴대전화번호 형식이 올바르지 않습니다.'}
        />
        <TextField
          label="이메일"
          sx={{ width: '100%', mt: 1.5 }}
          value={emailInput.value}
          onChange={emailInput.valueChangeHandler}
          onBlur={emailInput.inputBlurHandler}
          error={emailInput.hasError}
          helperText={emailInput.hasError && '이메일 형식이 올바르지 않습니다.'}
        />
        <TextField
          label="비고"
          multiline
          rows="2"
          sx={{ width: '100%', mt: 1.5, mb: 1 }}
          value={etcInput.value}
          onChange={etcInput.valueChangeHandler}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', ml: -1 }}>
          <Checkbox checked={agree} onChange={handleAgreeCheckHandler} />
          <Typography sx={{ fontSize: '14px', fontWeight: 'bold', ml: -0.5 }}>
            위의 유의사항을 확인하였으며, 기입한 내용에 거짓이 없음을 확인합니다.
          </Typography>
        </Box>
        <Divider />
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: 2 }}>
          <Button
            variant="contained"
            disabled={!formIsValid}
            onClick={applySafetyManagerClickHandler}
            sx={{ width: '45%' }}
          >
            신청하기
          </Button>
        </Box>
      </CustomModal>
    </>
  );
}
