import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import JoinForm from '../../components/Authentication/JoinForm';
import { useTheme } from '@emotion/react';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import usePageLogRecord from '../../hooks/usePageLogRecord';

// 회원가입 컴포넌트
export default function Join() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [agreed1, setAgreed1] = useState(false);
  const [agreed2, setAgreed2] = useState(false);
  const [allAgreed, setAllAgreed] = useState(false);

  const handleAgreeCheckboxChange = (event, checkboxNumber) => {
    if (checkboxNumber === 1) {
      setAgreed1(event.target.checked);
    } else if (checkboxNumber === 2) {
      setAgreed2(event.target.checked);
    }
  };

  const cancelJoin = () => {
    navigate('/login02');
  };

  useEffect(() => {
    setAllAgreed(agreed1 && agreed2);
  }, [agreed1, agreed2]);

  usePageLogRecord('joinUsers');

  return (
    <Container
      sx={{
        minHeight: '600px',
        minWidth: '50%',
        marginBottom: '4%',
      }}
    >
      {/* <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 4,
        }}
      >
        <img src={SolLogo} alt="SOLARCUBE" style={{ width: 30 }} />
        &nbsp;
        <Typography
          color="inherit"
          sx={{
            display: 'flex',
            fontFamily: '"Segoe UI Symbol"',
            flexGrow: 1,
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          SOLAR CUBE
        </Typography>
      </Box> */}
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ width: { xs: '100%', md: 500 }, mt: isMobile ? 5 : 9 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <PersonAddIcon
              fontSize="large"
              sx={{
                mr: 1,
              }}
            />
            <Typography variant="h4" fontWeight="bold">
              신규 회원가입
            </Typography>
          </Box>
          <Box display="flex" justifyContent="end" sx={{ mt: 0.5 }}>
            <Typography variant="h6" sx={{ mr: 0.5, color: 'red' }}>
              *
            </Typography>
            <Typography variant="body2">는 필수 입력 사항입니다.</Typography>
          </Box>
          <hr />
          <JoinForm
            allAgreed={allAgreed}
            setAllAgreed={setAllAgreed}
            agreed1={agreed1}
            setAgreed1={setAgreed1}
            agreed2={agreed2}
            setAgreed2={setAgreed2}
            handleAgreeCheckboxChange={handleAgreeCheckboxChange}
            cancelJoin={cancelJoin}
          />
        </Box>
      </Box>
    </Container>
  );
}
