import { useState } from 'react';
import CustomModal from '../../UI/CustomModal';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import SearchUser from './SearchUser';
import JoinForm from '../JoinForm';
import CloseIcon from '@mui/icons-material/Close';
import useResponsive from '../../../hooks/useResponsive';

// 소셜로그인 회원 연동 컴포넌트
export default function SocialLoginModal({ social, socialUserInfo, open, onClose }) {
  const isDesktop = useResponsive('up', 'lg');
  const isIpad = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const isNexthub = useMediaQuery('(min-width: 1025px) and (max-width: 1366px)');

  let loginText = '';
  if (social === 'kakao') {
    loginText = '카카오';
  } else if (social === 'naver') {
    loginText = '네이버';
  }

  const [socialModal, setSocialModal] = useState(false);
  const [socialSearchUserModal, setSocialSearchUserModal] = useState(false);
  const [socialJoinUserModal, setSocialJoinUserModal] = useState(false);

  const [allAgreed, setAllAgreed] = useState(false);
  const [agreed1, setAgreed1] = useState(false);
  const [agreed2, setAgreed2] = useState(false);

  const handleAgreeCheckboxChange = (event, checkboxNumber) => {
    if (checkboxNumber === 1) {
      setAgreed1(event.target.checked);
    } else if (checkboxNumber === 2) {
      setAgreed2(event.target.checked);
    }
  };

  const searchUserHandler = () => {
    setSocialModal(true);
    setSocialSearchUserModal(true);
  };
  const joinUserHandler = () => {
    setSocialModal(true);
    setSocialJoinUserModal(true);
  };

  const cancelJoin = () => {
    setSocialModal(false);
    setSocialJoinUserModal(false);
    setAgreed1(false);
    setAgreed2(false);
    setAllAgreed(false);
  };

  return (
    <CustomModal
      open={open}
      onClose={onClose}
      sx={{ width: { xs: isIpad ? '80%' : isNexthub ? '80%' : '95%', md: 500 }, p: 1 }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
      </Box>
      <br />
      <Typography align="center" variant="h6">
        회원 가입
      </Typography>
      <hr />
      <Box
        // component="form"
        sx={{
          mt: 2,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        {socialModal ? (
          <>
            {socialSearchUserModal ? (
              <SearchUser
                social={social}
                socialUserInfo={socialUserInfo}
                onClose={onClose}
                joinUserHandler={joinUserHandler}
                socialJoinUserModal={socialJoinUserModal}
                agreed1={agreed1}
                setAgreed1={setAgreed1}
                agreed2={agreed2}
                setAgreed2={setAgreed2}
                allAgreed={allAgreed}
                setAllAgreed={setAllAgreed}
                handleAgreeCheckboxChange={handleAgreeCheckboxChange}
                cancelJoin={cancelJoin}
              />
            ) : (
              <JoinForm
                social={social}
                socialUserInfo={socialUserInfo}
                agreed1={agreed1}
                setAgreed1={setAgreed1}
                agreed2={agreed2}
                setAgreed2={setAgreed2}
                allAgreed={allAgreed}
                setAllAgreed={setAllAgreed}
                cancelJoin={cancelJoin}
              />
            )}
          </>
        ) : (
          <>
            <Box>회원이 되신 것을 환영합니다.</Box>
            <br />
            <Box>혹시 기존의 회원이시라면 아래 버튼을 눌러</Box>
            <Box>기존 회원으로서 {loginText} 간편 로그인을 사용하세요.</Box>
            <Box sx={{ mt: 3, width: '100%' }}>
              <Button
                type="button"
                variant="contained"
                size="large"
                sx={{ width: '100%' }}
                onClick={searchUserHandler}
              >
                가입한 적 있습니다.
              </Button>
              <Button
                type="button"
                variant="outlined"
                onClick={joinUserHandler}
                size="large"
                sx={{ width: '100%', mt: 1 }}
              >
                신규 회원으로 시작하기
              </Button>
            </Box>
          </>
        )}
      </Box>
    </CustomModal>
  );
}
