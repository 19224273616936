import { TextField, Box, FormControl, Button, useMediaQuery, Card, IconButton, Tooltip } from '@mui/material';
import { useState, useEffect } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import NoticeBoardList from './NoticeBoardList';
import { useTheme } from '@mui/material/styles';
import NoticeBoardListMobile from './NoticeBoardListMobile';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { styled } from '@mui/material/styles';

const ColorButton = styled(Button)(({ theme }) => ({
  '&:hover': {
    backgroundColor: '#EBFBFF',
  },
}));

export default function NoticeBoardSearchForm() {
  const [title, setTitle] = useState('');
  const [rows, setRows] = useState([]);
  const [clickCount, setClickCount] = useState(1);
  const [viewMoreBtn, setViewMoreBtn] = useState(true);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  function handleTitleChange(e) {
    setTitle(e.target.value);
  }

  const bulletinbBoardNotice = useBackendV2();

  const fetchNoticeBoard = async () => {
    try {
      const { data } = await bulletinbBoardNotice.sendRequest(
        `${config.backend.endPoint.bulletinBoard.noticeBasic}?title=${title}`
      );
      setClickCount((prevCount) => prevCount + 1);
      let nextData = data.slice(0, 5 * clickCount);
      setRows(nextData);
      if (data.length === nextData.length) {
        setViewMoreBtn(false);
      } else {
        setViewMoreBtn(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다. 인터넷 연결 상태를 확인 후 새로고침 해주세요.');
    }
  };

  useEffect(() => {
    fetchNoticeBoard();
  }, []);

  function handleSearch() {
    fetchNoticeBoard();
  }

  function handleInit() {
    setTitle('');
  }

  function handleBtnMore() {
    fetchNoticeBoard();
  }

  function handleKeyDown(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  }

  return (
    <>
      {isMobile ? (
        ''
      ) : (
        <Card sx={{ p: 1.5, mt: 3, borderRadius: '12px', boxShadow: '0px 3px 12px #00000014' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
            <FormControl sx={{ width: '30%', mr: 2, ml: 1 }}>
              <TextField
                label="제목"
                variant="outlined"
                size="small"
                value={title}
                onChange={handleTitleChange}
                autoComplete="off"
                onKeyDown={handleKeyDown}
                InputProps={{
                  endAdornment: (
                    <>
                      <Tooltip title="검색">
                        <IconButton type="button" aria-label="search" onClick={handleSearch}>
                          <SearchOutlinedIcon />
                        </IconButton>
                      </Tooltip>
                    </>
                  ),
                }}
              />
            </FormControl>
            <ColorButton
              variant="contained"
              onClick={handleInit}
              sx={{
                mr: 2,
                ml: 1,
                background: '#E5EEF7 0% 0% no-repeat padding-box',
                color: '#0059B2',
              }}
            >
              초기화
            </ColorButton>
          </Box>
        </Card>
      )}

      {isMobile ? (
        <NoticeBoardListMobile rows={rows} onBtnMore={handleBtnMore} />
      ) : (
        <NoticeBoardList rows={rows} onBtnMore={handleBtnMore} />
      )}
      {viewMoreBtn && (
        <Box display="flex" sx={{ justifyContent: 'center', mt: 5 }}>
          <Button
            variant="contained"
            onClick={handleBtnMore}
            sx={{
              background: '#0059B2 0% 0% no-repeat padding-box',
              color: '#FFFFFF',
            }}
          >
            더보기
          </Button>
        </Box>
      )}
    </>
  );
}
