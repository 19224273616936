import { Box, Divider, Grid, Typography, styled } from '@mui/material';
import MainCard from '../../../theme/MainCard';
import { convertFormatStringToYMD, formatTime } from '../../../utils/dateUtil';

// 개별 대시보드 발전소 정보 표시 앱 - 발전소 설비용량 및 통신시간 컴포넌트
export default function EachPlantInfoMobile({ basePlantInfo }) {
  const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
  }));

  return (
    <>
      <CardWrapper border={false} content={false} sx={{ p: 1 }}>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <Grid container spacing={0}>
              <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5} display="flex" justifyContent="center">
                <Box>
                  <Typography variant="subtitle1" display="flex" justifyContent="center">
                    설비 용량
                  </Typography>
                  {basePlantInfo.length === 0 ? null : (
                    <>
                      <br />
                      <Typography variant="subtitle1" display="flex" justifyContent="center">
                        {basePlantInfo[0].capacity} kW
                      </Typography>
                    </>
                  )}
                </Box>
              </Grid>
              <Grid item lg={1} md={1} sm={1} xs={1} display="flex" justifyContent="center">
                <Divider orientation="vertical" sx={{ bgcolor: '#e0e0e0' }} />
              </Grid>
              <Grid item lg={5.5} md={5.5} sm={5.5} xs={5.5} display="flex" justifyContent="center">
                <Box>
                  <Typography variant="subtitle1" display="flex" justifyContent="center">
                    마지막 통신
                  </Typography>
                  {basePlantInfo.length === 0 ? null : (
                    <>
                      <Typography variant="subtitle1" display="flex" justifyContent="center" sx={{ mt: 1 }}>
                        {convertFormatStringToYMD(basePlantInfo[0].rtuinfos[0].localdate)}
                      </Typography>
                      <Typography variant="subtitle1" display="flex" justifyContent="center">
                        {formatTime(basePlantInfo[0].rtuinfos[0].localdate)}
                      </Typography>
                    </>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardWrapper>
    </>
  );
}
