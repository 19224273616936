import { Box, Card, Grid, MenuItem, Select, Typography } from '@mui/material';
import StringTable from './StringTable';
import StringChart from '../../../Charts/ConnectionBoard/StringChart';

// 스트링 감시 웹 화면 컴포넌트
export default function StringWeb({ stringsList, eachStringsData, setEachStringsData }) {
  const handleSelectChange = (e) => {
    const selectedGcode = e.target.value;
    const selectedStringData = stringsList.find((item) => item.gcode === selectedGcode);

    if (selectedStringData) {
      setEachStringsData(selectedStringData);
    }
  };

  return (
    <>
      {stringsList === undefined ? null : (
        <>
          <Card
            variant="outlined"
            sx={{
              padding: {
                xs: 0,
                sm: 2,
              },
              borderRadius: '14px',
              boxShadow: '0px 3px 12px #00000014',
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Select
                variant="standard"
                value={eachStringsData?.gcode || ''}
                onChange={handleSelectChange}
                sx={{ mr: 1, bgcolor: '#fff' }}
              >
                {stringsList?.map((item, index) => (
                  <MenuItem key={index} value={item.gcode}>
                    {item.stringname}
                  </MenuItem>
                ))}
              </Select>
              <Typography variant="subtitle1">스트링 감시</Typography>
            </Box>
            <Box>
              <Grid item xs={24}>
                <Grid item xs={12}>
                  <Grid container spacing={0.5}>
                    <Grid item lg={4} md={4} sm={4} xs={4}>
                      <Grid item lg={12} md={12} sm={12} xs={12} sx={{ mt: 3 }}>
                        <Card variant="outlined" sx={{ borderColor: '#CECECE', borderRadius: 0, mr: 2 }}>
                          <StringTable data={eachStringsData.channels} />
                        </Card>
                      </Grid>
                    </Grid>
                    <Grid item lg={8} md={8} sm={8} xs={8} sx={{ mt: 3 }}>
                      <Card variant="outlined" sx={{ borderColor: '#CECECE', borderRadius: 2 }}>
                        <StringChart data={eachStringsData.channels} />
                      </Card>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Card>
        </>
      )}
    </>
  );
}
