import useResponsive from '../../../hooks/useResponsive';
import EnvStatHoursChart from '../../Charts/Statistics/EnvStat/EnvStatHoursChart';
import EnvStatHoursTable from './EnvStatHoursTable';

// 환경정보 통계 컴포넌트 - 60분
export default function EnvStatHours({ data, envFirstChar, envSecondChar, envsetUse, chartView }) {
  const isDesktop = useResponsive('up', 'lg');

  // 환경장비 조건에 따른 새로운 배열 생성
  const filteredData = data.map((item) => {
    // envFirstChar가 false이고 envSecondChar가 true인 경우
    if (!envFirstChar && envSecondChar) {
      // trad와 otemp를 제외한 나머지 필드만 유지
      const { trad, otemp, ...rest } = item;
      return rest;
    }
    return item;
  });

  return (
    <>
      {isDesktop ? (
        <>
          <EnvStatHoursChart data={filteredData} envsetUse={envsetUse} isDesktop={isDesktop} />
          {data.length === 0 ? null : (
            <EnvStatHoursTable
              data={filteredData}
              envFirstChar={envFirstChar}
              envSecondChar={envSecondChar}
              isDesktop={isDesktop}
            />
          )}
        </>
      ) : chartView ? (
        <EnvStatHoursChart data={filteredData} envsetUse={envsetUse} isDesktop={isDesktop} />
      ) : (
        <EnvStatHoursTable
          data={filteredData}
          envFirstChar={envFirstChar}
          envSecondChar={envSecondChar}
          isDesktop={isDesktop}
        />
      )}
    </>
  );
}
