import useResponsive from '../../../hooks/useResponsive';
import HAllChart from '../../Charts/Statistics/Hour/HAllChart';
import HourAllStatisticsTable from './HourAllStatisticsTable';

export default function HourAllStatistics({ data, chartView }) {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <>
      {isDesktop ? (
        <>
          <HAllChart data={data} isDesktop={isDesktop} />
          {data.length === 0 ? null : <HourAllStatisticsTable data={data} isDesktop={isDesktop} />}
        </>
      ) : chartView ? (
        <HAllChart data={data} isDesktop={isDesktop} />
      ) : (
        <HourAllStatisticsTable data={data} isDesktop={isDesktop} />
      )}
    </>
  );
}
