import {
  Box,
  Button,
  Card,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import CustomModal from '../../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import SolarPowerIcon from '@mui/icons-material/SolarPower';
import { MdOutlineAddHomeWork } from "react-icons/md";
import useInput from '../../../hooks/use-input';
import { validateName, validateNumberOnly } from '../../../utils/validationUtil';
import { useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';

// 발전소 신규등록 모달 컴포넌트
export default function AddPlantModal({ open, onClose }) {
  // 발전소 명
  const plantNameInput = useInput((value) => value.length > 0);
  // 소유주 명
  const ownerInput = useInput((value) => validateName(value));
  // 설비용량
  const capacityInput = useInput((value) => validateNumberOnly(value));
  // 상세설명
  const plantdescInput = useInput();
  // 주소
  const addressInput = useInput();
  // 상세주소 address2
  const addressDetailInput = useInput();
  // 우편번호
  const zipCodeInput = useInput();

  // 현황판 사용
  const [useStatusboard, setUseStatusboard] = useState(false);
  // 환경센서 - 경사일사량
  const [slopeEnv, setSlopeEnv] = useState(false);
  // 환경센서 - 수평일사량
  const [horizonEnv, setHorizonEnv] = useState(false);
  // 환경센서 - 기타일사량
  const [etcEnv, setEtcEnv] = useState(false);
  // 환경센서 - 모듈온도
  const [moduleTempEnv, setModuleTempEnv] = useState(false);
  // 환경센서 - 외기온도
  const [externalTempEnv, setExternalTempEnv] = useState(false);
  // 환경센서 - 내부온도
  const [internalTempEnv, setInternalTempEnv] = useState(false);
  // 환경센서 - 기타온도
  const [etcTempEnv, setEtcTempEnv] = useState(false);
  // 환경센서 - 풍속계
  const [windSpeed, setWindSpeed] = useState(false);
  // 환경센서 - 풍향계
  const [windDirection, setWindDirection] = useState(false);

  // 환경센서 설정
  const generateEnvset = () => {
    const envSensors = [
      slopeEnv,
      horizonEnv,
      etcEnv,
      moduleTempEnv,
      externalTempEnv,
      internalTempEnv,
      etcTempEnv,
      windSpeed,
      windDirection,
    ];

    // 각 환경센서의 상태를 이진수로 변환하여 조합
    const envset = envSensors.map((sensor) => (sensor ? '1' : '0')).join('');

    return envset;
  };

  const formIsValid =
    plantNameInput.isValid && ownerInput.isValid && capacityInput.isValid && addressInput.isValid;

  const handleComplete = (data) => {
    let fullAddress = data.address;
    let extraAddress = '';
    let zonecode = data.zonecode;

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }

    addressInput.setValue(fullAddress);
    zipCodeInput.setValue(zonecode);
  };

  const slopeHandleChange = () => {
    setSlopeEnv(!slopeEnv);
  };
  const horizonHandleChange = () => {
    setHorizonEnv(!horizonEnv);
  };
  const etcHandleChange = () => {
    setEtcEnv(!etcEnv);
  };
  const moduleTempHandleChange = () => {
    setModuleTempEnv(!moduleTempEnv);
  };
  const externalTempHandleChange = () => {
    setExternalTempEnv(!externalTempEnv);
  };
  const internalTempHandleChange = () => {
    setInternalTempEnv(!internalTempEnv);
  };
  const etcTempHandleChange = () => {
    setEtcTempEnv(!etcTempEnv);
  };
  const windSpeedHandleChange = () => {
    setWindSpeed(!windSpeed);
  };
  const windDirectionHandleChange = () => {
    setWindDirection(!windDirection);
  };

  const useStatusboardHandleChange = () => {
    setUseStatusboard(!useStatusboard);
  };

  const backendRequest = useBackendV2();

  const clickCreatePlantHandler = async () => {
    try {
      const envset = generateEnvset();

      const newRequestConfig = {
        method: 'POST',
        body: {
          Plantname: plantNameInput.value,
          owner: ownerInput.value,
          capacity: capacityInput.value,
          envset: envset,
          statusboard: useStatusboard === true ? '1' : '0',
          plantdesc: plantdescInput.value,
          address: addressInput.value,
          address2: addressDetailInput.value,
          zipcode: zipCodeInput.value,
        },
      };
      const createResult = await backendRequest.sendRequest(
        config.backend.endPoint.plants.addNewPlant,
        newRequestConfig
      );

      if (createResult.data !== (null || 0)) {
        alert('발전소 신규 등록이 완료되었습니다.');
        onClose();
      } else {
        alert('오류가 발생하였습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <CustomModal
      open={open}
      onClose={closeModalHandler}
      sx={{ width: { xs: '95%', md: 650 }, maxHeight: '95%' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
        <MdOutlineAddHomeWork style={{ fontSize: '22px' }} />
        <Typography variant="h6">신규 발전소 등록</Typography>
      </Box>
      <hr />
      <Box display="flex" justifyContent="end" sx={{ mt: 0.5 }}>
        <Typography variant="h6" sx={{ mr: 0.5, color: 'red' }}>
          *
        </Typography>
        <Typography variant="body2">는 필수 입력 사항입니다.</Typography>
      </Box>
      <TextField
        label="발전소 명"
        variant="outlined"
        required
        sx={{ width: '100%', mt: 2 }}
        onChange={plantNameInput.valueChangeHandler}
        onBlur={plantNameInput.inputBlurHandler}
        value={plantNameInput.value}
        error={plantNameInput.hasError}
        helperText={plantNameInput.hasError && '최소 1자 입력'}
      />
      <TextField
        label="소유주 명"
        variant="outlined"
        required
        sx={{ width: '100%', mt: 2 }}
        onChange={ownerInput.valueChangeHandler}
        onBlur={ownerInput.inputBlurHandler}
        value={ownerInput.value}
        error={ownerInput.hasError}
        helperText={ownerInput.hasError && '최소 2자. 최대 20자. 한글, 영문만 가능'}
      />
      <TextField
        label="설비 용량"
        variant="outlined"
        required
        sx={{ width: '100%', mt: 2 }}
        onChange={capacityInput.valueChangeHandler}
        onBlur={capacityInput.inputBlurHandler}
        value={capacityInput.value}
        error={capacityInput.hasError}
        helperText={capacityInput.hasError && '숫자만 입력'}
        InputProps={{
          endAdornment: <InputAdornment position="start">kW</InputAdornment>,
        }}
      />
      <TextField
        label="주소"
        variant="outlined"
        required
        sx={{ width: '100%', mt: 2 }}
        value={addressInput.value}
        onChange={addressInput.valueChangeHandler}
        disabled
      />
      <DaumPostcodeEmbed onComplete={handleComplete} />
      <TextField
        label="상세 주소"
        sx={{ width: '100%', mt: 2 }}
        value={addressDetailInput.value}
        onChange={addressDetailInput.valueChangeHandler}
      />
      <TextField
        label="우편번호"
        disabled
        sx={{ width: '100%', mt: 2 }}
        value={zipCodeInput.value}
        onChange={zipCodeInput.valueChangeHandler}
      />
      <Box sx={{ width: '100%', mt: 2 }}>
        <Typography>환경센서</Typography>
        <Card sx={{ mt: 1, p: 1, border: '1px solid #bfbfbf' }}>
          <FormControlLabel
            label="경사 일사량"
            control={<Checkbox checked={slopeEnv} onChange={slopeHandleChange} />}
          />
          <FormControlLabel
            label="수평 일사량"
            control={<Checkbox checked={horizonEnv} onChange={horizonHandleChange} />}
          />
          <FormControlLabel
            label="기타 일사량"
            control={<Checkbox checked={etcEnv} onChange={etcHandleChange} />}
          />
          <FormControlLabel
            label="판넬 온도"
            control={<Checkbox checked={moduleTempEnv} onChange={moduleTempHandleChange} />}
          />
          <FormControlLabel
            label="외기 온도"
            control={<Checkbox checked={externalTempEnv} onChange={externalTempHandleChange} />}
          />
          <FormControlLabel
            label="내부 온도"
            control={<Checkbox checked={internalTempEnv} onChange={internalTempHandleChange} />}
          />
          <FormControlLabel
            label="기타 온도"
            control={<Checkbox checked={etcTempEnv} onChange={etcTempHandleChange} />}
          />
          <FormControlLabel
            label="풍속계"
            control={<Checkbox checked={windSpeed} onChange={windSpeedHandleChange} />}
          />
          <FormControlLabel
            label="풍향계"
            control={<Checkbox checked={windDirection} onChange={windDirectionHandleChange} />}
          />
        </Card>
      </Box>
      <FormControl variant="outlined" sx={{ mt: 3, mb: 1, width: '100%', backgroundColor: 'white' }}>
        <InputLabel id="carrier-label">현황판 사용</InputLabel>
        <Select
          labelId="carrier-label"
          id="carrier-select"
          value={useStatusboard}
          onChange={useStatusboardHandleChange}
          label="현황판 사용"
        >
          <MenuItem value="true">사용</MenuItem>
          <MenuItem value="false">미사용</MenuItem>
        </Select>
      </FormControl>
      <TextField
        label="상세설명"
        multiline
        rows="2"
        sx={{ mt: 2, width: '100%' }}
        value={plantdescInput.value}
        onChange={plantdescInput.valueChangeHandler}
      />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          mt: 1,
          px: '6%',
        }}
      >
        <Button
          type="button"
          variant="contained"
          disabled={!formIsValid}
          onClick={clickCreatePlantHandler}
          sx={{ width: '45%' }}
        >
          등록
        </Button>
        <Box sx={{ width: '6%' }} />
        <Button type="button" variant="outlined" sx={{ width: '45%' }} onClick={closeModalHandler}>
          취소
        </Button>
      </Box>
    </CustomModal>
  );
}
