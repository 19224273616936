import { Collapse, List, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AppsIcon from '@mui/icons-material/Apps';
import ListIcon from '@mui/icons-material/List';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import AuthContext from '../../../store/auth-context';
import { useContext } from 'react';

export default function InverterMenu({
  value,
  setValue,
  subValue,
  setSubValue,
  inverterClickHandler,
  inverterSelect,
  webNavOpen,
  onClose,
}) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const listClickHandler = () => {
    setValue(1);
    localStorage.setItem('menuValue', 1);
    setSubValue(0);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.inverters, { state: { subValue: 0 } });
    onClose();
  };
  const systematicMapClickHandler = () => {
    setValue(1);
    localStorage.setItem('menuValue', 1);
    setSubValue(1);
    localStorage.setItem('menuSubValue', 1);
    navigate(config.frontend.inverters, { state: { subValue: 1 } });
    onClose();
  };
  const connectBoardClickHandler = () => {
    setValue(1);
    localStorage.setItem('menuValue', 1);
    setSubValue(2);
    localStorage.setItem('menuSubValue', 2);
    navigate(config.frontend.inverters, { state: { subValue: 2 } });
    onClose();
  };
  const deviceControlClickHandler = () => {
    setValue(1);
    localStorage.setItem('menuValue', 1);
    setSubValue(3);
    localStorage.setItem('menuSubValue', 3);
    navigate(config.frontend.inverters, { state: { subValue: 3 } });
    onClose();
  };

  return (
    <List component="nav">
      <ListItemButton onClick={inverterClickHandler}>
        <ListItemIcon>
          <AppsIcon />
        </ListItemIcon>
        {webNavOpen ? <ListItemText primary="인버터" /> : null}
        {webNavOpen ? inverterSelect ? <ExpandLess /> : <ExpandMore /> : null}
      </ListItemButton>
      <Collapse in={inverterSelect} timeout="auto" unmountOnExit>
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 1 && subValue === 0 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={listClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <ListIcon sx={{ color: value === 1 && subValue === 0 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 1 && subValue === 0 ? '#3A3DFF' : null,
                  fontWeight: value === 1 && subValue === 0 ? 'bold' : null,
                }}
              >
                목록
              </Typography>
            ) : null}
          </ListItemButton>
        </List>
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 1 && subValue === 1 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={systematicMapClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <AccountTreeOutlinedIcon sx={{ color: value === 1 && subValue === 1 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 1 && subValue === 1 ? '#3A3DFF' : null,
                  fontWeight: value === 1 && subValue === 1 ? 'bold' : null,
                }}
              >
                계통도
              </Typography>
            ) : null}
          </ListItemButton>
        </List>
        <List
          component="div"
          disablePadding
          sx={{ backgroundColor: value === 1 && subValue === 2 ? '#D2E9FF' : null, borderRadius: 1 }}
        >
          <ListItemButton onClick={connectBoardClickHandler} sx={{ pl: 4 }}>
            <ListItemIcon>
              <InboxIcon sx={{ color: value === 1 && subValue === 2 ? '#3A3DFF' : null }} />
            </ListItemIcon>
            {webNavOpen ? (
              <Typography
                sx={{
                  color: value === 1 && subValue === 2 ? '#3A3DFF' : null,
                  fontWeight: value === 1 && subValue === 2 ? 'bold' : null,
                }}
              >
                접속반
              </Typography>
            ) : null}
          </ListItemButton>
        </List>
        {authCtx.currentUser.role === '사이트 관리자' || authCtx.currentUser.role === '안전관리자' ? (
          <List
            component="div"
            disablePadding
            sx={{ backgroundColor: value === 1 && subValue === 3 ? '#D2E9FF' : null, borderRadius: 1 }}
          >
            <ListItemButton onClick={deviceControlClickHandler} sx={{ pl: 4 }}>
              <ListItemIcon>
                <ControlCameraIcon sx={{ color: value === 1 && subValue === 3 ? '#3A3DFF' : null }} />
              </ListItemIcon>
              {webNavOpen ? (
                <Typography
                  sx={{
                    color: value === 1 && subValue === 3 ? '#3A3DFF' : null,
                    fontWeight: value === 1 && subValue === 3 ? 'bold' : null,
                  }}
                >
                  장비제어
                </Typography>
              ) : null}
            </ListItemButton>
          </List>
        ) : null}
      </Collapse>
    </List>
  );
}
