import {
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
} from '@mui/material';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import HistoryEduOutlinedIcon from '@mui/icons-material/HistoryEduOutlined';
import ListAltOutlinedIcon from '@mui/icons-material/ListAltOutlined';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import { useState } from 'react';

// 웹 메뉴 경보 컴포넌트
export default function AlarmWebMenu({ setValue, setSubValue }) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const alarmHistoryClickHandler = () => {
    setValue(3);
    localStorage.setItem('menuValue', 3);
    setSubValue(0);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.alarms, { state: { subValue: 0 } });
  };
  const alarmCodeAnalysisClickHandler = () => {
    setValue(3);
    localStorage.setItem('menuValue', 3);
    setSubValue(1);
    localStorage.setItem('menuSubValue', 1);
    navigate(config.frontend.alarms, { state: { subValue: 1 } });
  };

  return (
    <>
      <Button
        startIcon={<NotificationsActiveIcon fontSize="large" />}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          color: '#fff',
          fontSize: 'large',
          mr: 0.5,
        }}
        onClick={handleOpen}
      >
        경보
      </Button>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack sx={{ p: 1 }}>
          <List component="nav">
            <ListItemButton onClick={alarmHistoryClickHandler}>
              <ListItemIcon>
                <HistoryEduOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="이력" />
            </ListItemButton>
          </List>
          <Divider />
          <List component="nav">
            <ListItemButton onClick={alarmCodeAnalysisClickHandler}>
              <ListItemIcon>
                <ListAltOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="발생 빈도" />
            </ListItemButton>
          </List>
        </Stack>
      </Popover>
    </>
  );
}
