import { Box, Card, Typography } from '@mui/material';
import DesktopAccessDisabledIcon from '@mui/icons-material/DesktopAccessDisabled';
import useResponsive from '../../../hooks/useResponsive';

// 스트링 및 접속반 모두 존재하지 않을 때 나타나는 컴포넌트
export default function ConnectBoardNot() {
  const isDesktop = useResponsive('up', 'lg');

  return (
    <Card
      variant="outlined"
      sx={{
        p: 1,
        backgroundColor: '#eceff1',
        borderColor: '#b0bec5',
        borderRadius: 0,
        height: 400,
      }}
    >
      {isDesktop ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 3, mt: 16.5 }}>
          <DesktopAccessDisabledIcon sx={{ fontSize: 100 }} />
          <Typography variant="h5">해당 발전소는 스트링 및 접속반이 존재하지 않습니다.</Typography>
        </Box>
      ) : (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 11 }}>
            <DesktopAccessDisabledIcon sx={{ fontSize: 100 }} />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
            <Typography variant="h5">해당 발전소는 스트링 및 접속반이 존재하지 않습니다.</Typography>
          </Box>
        </>
      )}
    </Card>
  );
}
