import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

// 종합분석통계 테이블 컴포넌트
export default function ComprehensiveAnalysisTable({ data, selectPlantInverters, plantInverters }) {
  const columns = [
    { field: 'inverterName', headerName: '인버터 명', width: 100 },
    { field: 'legend', headerName: '범례', width: 100 },
    { field: 'analysis', headerName: '분석 항목', width: 100 },
    { field: 'units', headerName: '단위', width: 50 },
  ];

  const [modifiedData, setModifiedData] = useState([]);

  useEffect(() => {
    // 데이터가 업데이트될 때마다 'fdate'와 'fhour'를 제외한 값들을 추출하여 상태로 설정
    if (data && data.length > 0) {
      const { fdate, fhour, ...rest } = data[0];
      const valuesArray = Object.entries(rest).map(([legend, value]) => {
        // legend 값에서 숫자를 추출하여 숫자가 있다면 해당 숫자에 해당하는 selectPlantInverters를 사용한다.
        const match = legend.match(/\d+/);
        const index = match ? parseInt(match[0]) : 0;
        const inverterName = selectPlantInverters[index];
        let analysis = '';
        let units = '';

        // legend에 따라 analysis 값을 설정
        if (legend.includes('totalyield')) {
          analysis = 'Energy';
        } else if (legend.includes('trad')) {
          analysis = 'Radiation';
        } else if (legend.includes('pac')) {
          analysis = 'AC Power';
        } else if (legend.includes('pdc')) {
          analysis = 'DC Power';
        } else if (legend.includes('uac')) {
          analysis = 'AC Voltage';
        } else if (legend.includes('udc')) {
          analysis = 'DC Voltage';
        } else if (legend.includes('iac')) {
          analysis = 'AC Current';
        } else if (legend.includes('idc')) {
          analysis = 'DC Current';
        }

        // legend에 따라 units 값을 설정
        if (legend.includes('totalyield')) {
          units = 'kWh';
        } else if (legend.includes('trad')) {
          units = 'W/㎡';
        } else if (legend.includes('pac')) {
          units = 'kW';
        } else if (legend.includes('pdc')) {
          units = 'kW';
        } else if (legend.includes('uac')) {
          units = 'V';
        } else if (legend.includes('udc')) {
          units = 'V';
        } else if (legend.includes('iac')) {
          units = 'A';
        } else if (legend.includes('idc')) {
          units = 'A';
        }

        // legend를 새로 갱신
        if (legend.startsWith('totalyield')) {
          const num = legend.match(/\d+/);
          legend = `TotalYield${num ? num[0] : ''}`;
        } else if (legend.startsWith('trad')) {
          legend = 'RADITION';
        } else if (legend.startsWith('pac')) {
          const num = legend.match(/\d+/);
          legend = `PAC${num ? num[0] : ''}`;
        } else if (legend.startsWith('pdc')) {
          const num = legend.match(/\d+/);
          legend = `PDC${num ? num[0] : ''}`;
        } else if (legend.startsWith('uac')) {
          const num = legend.match(/\d+/);
          legend = `UAC${num ? num[0] : ''}`;
        } else if (legend.startsWith('udc')) {
          const num = legend.match(/\d+/);
          legend = `UDC${num ? num[0] : ''}`;
        } else if (legend.startsWith('iac')) {
          const num = legend.match(/\d+/);
          legend = `IAC${num ? num[0] : ''}`;
        } else if (legend.startsWith('idc')) {
          const num = legend.match(/\d+/);
          legend = `IDC${num ? num[0] : ''}`;
        }

        return { inverterName, legend, value, analysis, units };
      });

      // inverterName과 plantInverters의 serialnum을 비교하여 일치하는 경우 inverterName을 plantInverters의 invertername으로 교체
      const updatedValues = valuesArray.map(({ inverterName, legend, value, analysis, units }) => {
        const matchingInverter = plantInverters.find((inverter) => inverter.serialnum === inverterName);
        return {
          inverterName: matchingInverter ? matchingInverter.invertername : inverterName,
          legend,
          value,
          analysis,
          units,
        };
      });

      setModifiedData(updatedValues);
    } else {
      setModifiedData([]);
    }
  }, [data, selectPlantInverters, plantInverters]);

  return (
    <Card
      sx={{
        mt: 1,
        borderRadius: 0,
        boxShadow: '0px 3px 12px #00000014',
      }}
    >
      <StyledTableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <StyledTableCell
                  key={index}
                  legend={column.field}
                  align="center"
                  style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                >
                  {column.headerName}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {modifiedData.map((row, index) => (
              <TableRow key={row.legend} index={index}>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    align="center"
                    style={{
                      minWidth: column.width,
                      backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                      borderRight: '1px solid #d0d0d0',
                    }}
                  >
                    {row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Card>
  );
}
