import { Button, FormControl, InputLabel, MenuItem, Paper, Select, TextField } from '@mui/material';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';

// 회원가입 입력 컴포넌트
export default function JoinInput({
  idIsNotDuplicate,
  setIdIsNotDuplicate,
  idInput,
  passwordInput,
  passwordConfirmInput,
  emailInput,
  nameInput,
  phoneInput,
  selectedCarrier,
  setSelectedCarrier,
}) {
  const checkDuplicationRequest = useBackendV2();

  const checkDuplicationHandler = async () => {
    if (idInput.value === '') {
      alert('아이디를 입력해주세요.');
      return;
    }

    try {
      const result = await checkDuplicationRequest.sendRequest(
        `${config.backend.endPoint.users.checkDuplication}/${idInput.value}`,
        null,
        false
      );

      const isDuplicated = result.data;

      setIdIsNotDuplicate(!isDuplicated);
      if (isDuplicated === true) {
        alert('이미 사용중인 아이디입니다.');
      }
    } catch (error) {
      console.error(error);
      if (error.message === 'INVALID_USER_ID') {
        alert('아이디 형식을 확인해주세요.');
      } else {
        alert('오류가 발생하였습니다.');
      }
    }
  };

  const resetIdInputHandler = () => {
    setIdIsNotDuplicate(false);
  };
  const handleCarrierChange = (event) => {
    setSelectedCarrier(event.target.value);
  };

  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          border: '2px solid #D8D8D8',
          alignItems: 'center',
          mt: 1,
        }}
      >
        <TextField
          label="아이디"
          variant="outlined"
          required
          onChange={idInput.valueChangeHandler}
          onBlur={idInput.inputBlurHandler}
          value={idInput.value.replace(/\s/g, '').trim()}
          error={idInput.hasError}
          helperText={idInput.hasError && '최소 4자. 최대 20자. 영문, 숫자만 가능'}
          disabled={idIsNotDuplicate}
          sx={{ mt: 2, backgroundColor: 'white', width: '95%' }}
        />
        {idIsNotDuplicate ? (
          <Button
            type="button"
            sx={{ mt: 1, width: '95%' }}
            onClick={resetIdInputHandler}
            variant="outlined"
            color="success"
          >
            재설정
          </Button>
        ) : (
          <Button
            type="button"
            sx={{ mt: 1, width: '95%' }}
            onClick={checkDuplicationHandler}
            variant="contained"
            disabled={checkDuplicationRequest.isLoading}
          >
            중복 확인
          </Button>
        )}
        <TextField
          label="이름"
          variant="outlined"
          required
          sx={{ mt: 1, backgroundColor: 'white', width: '95%' }}
          onChange={nameInput.valueChangeHandler}
          onBlur={nameInput.inputBlurHandler}
          value={nameInput.value.replace(/\s/g, '').trim()}
          error={nameInput.hasError}
          helperText={nameInput.hasError && '최소 2자. 최대 20자. 한글, 영문만 가능'}
        />
        <TextField
          type="password"
          label="비밀번호"
          variant="outlined"
          required
          sx={{ mt: 1, backgroundColor: 'white', width: '95%' }}
          onChange={passwordInput.valueChangeHandler}
          onBlur={passwordInput.inputBlurHandler}
          value={passwordInput.value.replace(/\s/g, '').trim()}
          error={passwordInput.hasError}
          helperText={
            passwordInput.hasError &&
            '최소 8자. 최대 25자. 최소 영문자 1개, 숫자 1개, 특수문자 1개 포함. 대소문자 구분'
          }
        />
        <TextField
          type="password"
          label="비밀번호 확인"
          variant="outlined"
          required
          sx={{ mt: 1, backgroundColor: 'white', width: '95%' }}
          onChange={passwordConfirmInput.valueChangeHandler}
          onBlur={passwordConfirmInput.inputBlurHandler}
          value={passwordConfirmInput.value.replace(/\s/g, '').trim()}
          error={passwordConfirmInput.hasError}
          helperText={passwordConfirmInput.hasError && '비밀번호가 일치하지 않습니다.'}
        />
        <TextField
          label="휴대전화번호"
          variant="outlined"
          required
          sx={{ mt: 1, backgroundColor: 'white', width: '95%' }}
          onChange={phoneInput.valueChangeHandler}
          onBlur={phoneInput.inputBlurHandler}
          value={phoneInput.value.replace(/\s/g, '').trim()}
          error={phoneInput.hasError}
          helperText={phoneInput.hasError && '핸드폰 번호 형식이 올바르지 않습니다.'}
        />
        <TextField
          label="이메일"
          variant="outlined"
          sx={{ mt: 1, backgroundColor: 'white', width: '95%' }}
          onChange={emailInput.valueChangeHandler}
          onBlur={emailInput.inputBlurHandler}
          value={emailInput.value.replace(/\s/g, '').trim()}
          error={emailInput.hasError}
          helperText={emailInput.hasError && '이메일 형식이 올바르지 않습니다.'}
        />
        <FormControl variant="outlined" sx={{ mt: 1, mb: 2, width: '95%', backgroundColor: 'white' }}>
          <InputLabel id="carrier-label">가입 구분</InputLabel>
          <Select
            labelId="carrier-label"
            id="carrier-select"
            value={selectedCarrier}
            onChange={handleCarrierChange}
            label="가입 구분"
          >
            {/* <MenuItem value="1">사이트 관리자</MenuItem> */}
            <MenuItem value="2">소유주</MenuItem>
            <MenuItem value="3">안전관리자</MenuItem>
            <MenuItem value="4">전문기업관리자</MenuItem>
            <MenuItem value="5">인버터유통관리자</MenuItem>
          </Select>
        </FormControl>
      </Paper>
    </>
  );
}
