import { useEffect, useRef } from 'react';

// 커스텀 훅으로 setInterval 함수 사용
// 콜백 함수와 딜레이를 받아서 일정한 간격으로 콜백 함수를 호출하는 데 사용

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

export default useInterval;
