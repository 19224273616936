import { Box, Breadcrumbs, Button, Grid, TextField, Typography, useMediaQuery } from '@mui/material';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import { useNavigate } from 'react-router-dom';
import CardContent from '@mui/material/CardContent';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { useState, useContext, useEffect } from 'react';
import AuthContext from '../../store/auth-context';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useTheme } from '@mui/material/styles';
import Header02 from '../Layout/Header02';
import SubHeader from '../Layout/SubHeader';
import Nav from '../Layout/Nav';
import useResponsive from '../../hooks/useResponsive';

/*
요청게시판 등록 컴포넌트
*/
export default function RequestBoardAdd() {
  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const distance = 0;

  const [plantName, setPlantName] = useState('');
  const [plantAddress, setPlantAddress] = useState('');
  const [ownerHp, setOwnerHp] = useState('');
  const [safetyHp, setSafetyHp] = useState('');
  const [content, setContent] = useState('');

  const authCtx = useContext(AuthContext);
  const loggedId = authCtx?.currentUser?.id;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const navigate = useNavigate();

  function handleGoList() {
    navigate(`${config.frontend.bulletinboards}`);
  }

  function handlePlantNmChange(e) {
    setPlantName(e.target.value);
  }

  function handlePlantAddressChange(e) {
    setPlantAddress(e.target.value);
  }

  function handleOwnerHpChange(e) {
    let formattedPhoneNumber = e.target.value.replace(/[^\d-]/g, ''); // 숫자가 아닌 문자 제거
    setOwnerHp(formattedPhoneNumber);
  }

  function handleSafetyHpChange(e) {
    let formattedPhoneNumber = e.target.value.replace(/[^\d-]/g, ''); // 숫자가 아닌 문자 제거
    setSafetyHp(formattedPhoneNumber);
  }

  function handleContentChange(e) {
    setContent(e.target.value);
  }

  const backendRequest = useBackendV2();

  const handleAdd = async () => {
    if (plantName === '' || plantName === null) {
      alert('발전소명을 입력하세요');
      return;
    }
    if (plantAddress === '' || plantAddress === null) {
      alert('발전소 주소를 입력하세요');
      return;
    }
    const regExp = /[^0-9-]/g;
    if (ownerHp === '' || ownerHp === null) {
      alert('소유주 연락처를 입력하세요');
      return;
    } else if (regExp.test(ownerHp) === true) {
      alert('연락처는 숫자와 - 만 가능합니다.');
      return;
    }
    if (safetyHp === '' || safetyHp === null) {
      alert('안전관리자 연락처를 입력하세요');
      return;
    } else if (regExp.test(safetyHp) === true) {
      alert('연락처는 숫자와 - 만 가능합니다.');
      return;
    }
    if (content === '' || content === null) {
      alert('내용을 입력하세요.');
      return;
    }
    try {
      const requestConfig = {
        method: 'POST',
        body: {
          plant_name: plantName,
          plant_address: plantAddress,
          owner_hp: ownerHp,
          safety_hp: safetyHp,
          content,
        },
      };
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.bulletinBoard.add}/${loggedId}`,
        requestConfig
      );
      alert('등록하였습니다.');
      navigate(`${config.frontend.bulletinboards}`);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  // 모바일 반응형을 위한 상수 선언
  const isSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 513px)');
  const isMoreSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 385px)');

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    fetchUserStylePreference();
  }, []);

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      {isMobile ? (
        <>
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: isSmallDevice ? 'column' : null,
            }}
          >
            <Grid
              item
              xs={isSmallDevice ? 12 : 1}
              sx={{
                display: 'flex',
                alignItems: isSmallDevice ? 'left' : 'center',
                justifyContent: isSmallDevice ? 'flex-start' : 'flex-end',
              }}
            >
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <StyledBreadcrumb
                  label={isMoreSmallDevice ? '요청 게시판' : '게시판'}
                  icon={<ContentPasteIcon fontSize="small" color="primary" />}
                  galaxyFoldFontSize={isMoreSmallDevice ? '14px' : '15px'}
                />
                <StyledBreadcrumb
                  label={isMoreSmallDevice ? '작성 내용' : '요청 게시판'}
                  galaxyFoldFontSize={isMoreSmallDevice ? '14px' : '15px'}
                />
                {isMoreSmallDevice ? null : <StyledBreadcrumb label="작성 내용" />}
              </Breadcrumbs>
            </Grid>
            <Grid
              item
              xs={isSmallDevice ? 12 : 1}
              sx={{
                display: 'flex',
                alignItems: isSmallDevice ? 'right' : 'center',
                justifyContent: isSmallDevice ? 'flex-end' : 'flex-start',
              }}
            >
              <Button
                onClick={handleGoList}
                endIcon={<ArrowForwardIcon />}
                sx={{
                  ml: 1,
                  mr: 2,
                  color: '#000',
                  textTransform: 'none',
                  mt: isSmallDevice ? 2 : 0,
                }}
              >
                <Typography variant="subtitle1">돌아가기</Typography>
              </Button>
            </Grid>
          </Grid>
          <Box
            component="form"
            sx={{
              mt: isSmallDevice ? 0 : 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'left',
              width: '100%',
            }}
          >
            <>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  발전소명 *
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  sx={{ mt: 1, width: '100%' }}
                  name="plant_name"
                  value={plantName}
                  onChange={handlePlantNmChange}
                  autoComplete="off"
                  placeholder="발전소명을 입력해주세요. ＊"
                />
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  발전소 주소 *
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  sx={{ mt: 1, width: '100%' }}
                  name="plant_address"
                  value={plantAddress}
                  onChange={handlePlantAddressChange}
                  autoComplete="off"
                  placeholder="발전소 주소를 입력해주세요. ＊"
                />
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  소유주 연락처 *
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  sx={{ mt: 1, width: '100%' }}
                  name="owner_hp"
                  value={ownerHp}
                  onChange={handleOwnerHpChange}
                  placeholder="(예시)010-0000-0000 *"
                  autoComplete="off"
                  inputProps={{ maxLength: 13, inputMode: 'numeric' }}
                />
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  안전관리자 연락처 *
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  sx={{ mt: 1, width: '100%' }}
                  name="safety_hp"
                  value={safetyHp}
                  onChange={handleSafetyHpChange}
                  placeholder="(예시)010-0000-0000 *"
                  autoComplete="off"
                  inputProps={{ maxLength: 13, inputMode: 'numeric' }}
                />
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  내용 *
                </Typography>
                <TextField
                  label="내용"
                  variant="outlined"
                  required
                  multiline
                  rows="10"
                  sx={{ mt: 1, width: '100%', whiteSpace: 'pre-wrap' }}
                  onChange={handleContentChange}
                  name="content"
                  value={content}
                  autoComplete="off"
                />
              </CardContent>
              <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  onClick={handleGoList}
                  sx={{
                    mr: 4,
                    background: '#E5EEF7 0% 0% no-repeat padding-box',
                    color: '#0059B2',
                  }}
                >
                  목록
                </Button>
                <Button
                  variant="contained"
                  onClick={handleAdd}
                  sx={{
                    mr: 0,
                    background: '#0059B2 0% 0% no-repeat padding-box',
                    color: '#FFFFFF',
                  }}
                >
                  등록
                </Button>
              </Box>
            </>
          </Box>
        </>
      ) : (
        <>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <StyledBreadcrumb label="게시판" icon={<ContentPasteIcon fontSize="small" color="primary" />} />
              <StyledBreadcrumb label="요청 게시판" />
              <StyledBreadcrumb label="등록" />
            </Breadcrumbs>
            <Button
              onClick={handleGoList}
              endIcon={<ArrowForwardIcon />}
              sx={{ textTransform: 'none', ml: 1, mr: 2, color: '#000' }}
            >
              <Typography variant="subtitle1">돌아가기</Typography>
            </Button>
          </Box>
          <Box
            component="form"
            sx={{
              mt: 2,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'left',
              width: '100%',
            }}
          >
            <>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  발전소명 *
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  sx={{ mt: 1, width: '100%' }}
                  name="plant_name"
                  value={plantName}
                  onChange={handlePlantNmChange}
                  autoComplete="off"
                  placeholder="발전소명을 입력해주세요. ＊"
                />
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  발전소 주소 *
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  sx={{ mt: 1, width: '100%' }}
                  name="plant_address"
                  value={plantAddress}
                  onChange={handlePlantAddressChange}
                  autoComplete="off"
                  placeholder="발전소 주소를 입력해주세요. ＊"
                />
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  소유주 연락처 *
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  sx={{ mt: 1, width: '100%' }}
                  name="owner_hp"
                  value={ownerHp}
                  onChange={handleOwnerHpChange}
                  placeholder="(예시)010-0000-0000 *"
                  autoComplete="off"
                  inputProps={{ maxLength: 13, inputMode: 'numeric' }}
                />
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  안전관리자 연락처 *
                </Typography>
                <TextField
                  variant="outlined"
                  required
                  sx={{ mt: 1, width: '100%' }}
                  name="safety_hp"
                  value={safetyHp}
                  onChange={handleSafetyHpChange}
                  placeholder="(예시)010-0000-0000 *"
                  autoComplete="off"
                  inputProps={{ maxLength: 13, inputMode: 'numeric' }}
                />
              </CardContent>
              <CardContent>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal bold 12px/17px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                  }}
                >
                  내용 *
                </Typography>
                <TextField
                  label="내용"
                  variant="outlined"
                  required
                  multiline
                  rows="10"
                  sx={{ mt: 1, width: '100%', whiteSpace: 'pre-wrap' }}
                  onChange={handleContentChange}
                  name="content"
                  value={content}
                  autoComplete="off"
                />
              </CardContent>
            </>
            <Box sx={{ display: 'flex', mt: 2, justifyContent: 'center' }}>
              <Button
                variant="contained"
                onClick={handleGoList}
                sx={{
                  mr: 4,
                  background: '#E5EEF7 0% 0% no-repeat padding-box',
                  color: '#0059B2',
                }}
              >
                목록
              </Button>
              <Button
                variant="contained"
                onClick={handleAdd}
                sx={{
                  mr: 0,
                  background: '#0059B2 0% 0% no-repeat padding-box',
                  color: '#FFFFFF',
                }}
              >
                등록
              </Button>
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
