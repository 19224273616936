import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';

// 사용자 메뉴 주문 내역 컴포넌트
export default function OrderInfoDetail({ showOrderInfo, orderInfoClickHandler }) {
  return (
    <List component="nav">
      <ListItemButton onClick={orderInfoClickHandler}>
        <ListItemIcon>
          <InventoryOutlinedIcon />
        </ListItemIcon>
        <ListItemText primary="주문 내역" />
      </ListItemButton>
    </List>
  );
}
