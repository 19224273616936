import { Box, Button, Paper, TextField, Typography } from '@mui/material';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';

// 비밀번호를 찾을 회원정보 입력받는 컴포넌트
export default function FindPwdForm({ idInput, nameInput, phoneInput, setResultInfo, cancelJoin, isMobile }) {
  const formIsValid = idInput.isValid && nameInput.isValid && phoneInput.isValid;

  const tempNumIssuanceRequest = useBackendV2();

  const tempNumIssuanceHandler = async () => {
    if (!formIsValid) {
      return;
    }

    try {
      const tempNumIssuanceRequestConfig = {
        method: 'POST',
        body: {
          id: idInput.value.trim(),
          uname: nameInput.value.trim(),
          hp: phoneInput.value.trim(),
        },
      };
      const tempNumIssuanceResult = await tempNumIssuanceRequest.sendRequest(
        config.backend.endPoint.users.tempNumIssuance,
        tempNumIssuanceRequestConfig,
        false
      );

      if (tempNumIssuanceResult.data === true) {
        alert('인증번호가 발급되었습니다.');
        setResultInfo(true);
      } else {
        alert('인증번호 발급이 실패했습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발행하였습니다.');
    }
  };

  return (
    <>
      <Box sx={{ width: { xs: '100%', md: 500 } }}>
        <Box
          sx={{
            mt: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
          }}
        >
          <Box sx={{ mt: 1 }}>
            <Typography variant="subtitle1" sx={{ fontSize: isMobile ? 14 : 16 }}>
              아이디, 이름, 휴대전화 번호를 입력 후 다음 버튼을 눌러주세요.
            </Typography>
            <Typography variant="body2" sx={{ fontSize: isMobile ? 12 : 14, mt: 0.5 }}>
              (등록된 정보와 일치하면 본인 휴대전화 SMS로 인증번호를 보내드립니다.)
            </Typography>
          </Box>
        </Box>
        <Paper
          variant="outlined"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            border: '2px solid #D8D8D8',
            alignItems: 'center',
            mt: 1.5,
            mb: 1,
          }}
        >
          <TextField
            label="아이디"
            variant="outlined"
            required
            onChange={idInput.valueChangeHandler}
            onBlur={idInput.inputBlurHandler}
            value={idInput.value}
            error={idInput.hasError}
            helperText={idInput.hasError && '최소 4자. 최대 20자. 영문, 숫자만 가능'}
            autoComplete="off"
            sx={{ mt: 2, backgroundColor: 'white', width: '95%' }}
          />
          <TextField
            label="이름"
            variant="outlined"
            required
            sx={{ mt: 1, backgroundColor: 'white', width: '95%' }}
            onChange={nameInput.valueChangeHandler}
            onBlur={nameInput.inputBlurHandler}
            value={nameInput.value}
            error={nameInput.hasError}
            helperText={nameInput.hasError && '최소 2자. 최대 20자. 한글, 영문만 가능'}
            autoComplete="off"
          />
          <TextField
            label="휴대전화번호"
            variant="outlined"
            required
            sx={{ mt: 1, backgroundColor: 'white', width: '95%', mb: 2 }}
            onChange={phoneInput.valueChangeHandler}
            onBlur={phoneInput.inputBlurHandler}
            value={phoneInput.value.replace(/\s/g, '').trim()}
            error={phoneInput.hasError}
            helperText={phoneInput.hasError && '핸드폰 번호 형식이 올바르지 않습니다.'}
            autoComplete="off"
          />
        </Paper>
        <Box sx={{ mt: 2.5, mb: 2, width: '100%' }}>
          <Button
            type="button"
            variant="contained"
            disabled={!formIsValid}
            onClick={tempNumIssuanceHandler}
            size="large"
            sx={{ width: '100%' }}
          >
            다음
          </Button>
          <Button
            type="button"
            variant="outlined"
            onClick={cancelJoin}
            size="large"
            sx={{ width: '100%', mt: 1 }}
          >
            돌아가기
          </Button>
        </Box>
      </Box>
    </>
  );
}
