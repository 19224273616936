import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import '../../../css/App.css';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

export default function MonthInverterStatisticsTable({ data, groupedData, chartData, isDesktop }) {
  if (!chartData || !groupedData) {
    return null;
  }

  const columns = [
    { field: 'groupname', headerName: '인버터명', width: 150 },
    { field: 'flocaldate', headerName: '월(month)', width: 60 },
    { field: 'totalyield', headerName: '발전량(kWh)', width: 200 },
  ];

  return (
    <>
      <Card sx={{ mt: 1, borderRadius: 0, boxShadow: '0px 3px 12px #00000014' }}>
        <StyledTableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <StyledTableCell
                    key={column.field}
                    align="center"
                    style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                    className={column.headerName === '인버터명' ? 'sticky' : ''}
                  >
                    {column.headerName}
                  </StyledTableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.keys(groupedData).map((groupName) => {
                let sum = 0;

                const filteredData = groupedData[groupName].filter((rowData) => {
                  return Object.values(rowData).some((value) => value !== 0);
                });

                const rowsWithSum = filteredData.map((rowData, rowIndex) => {
                  sum += parseFloat(rowData['totalyield'] || 0);

                  return (
                    <TableRow key={rowIndex}>
                      {columns.map((column) => (
                        <StyledTableCell
                          key={column.field}
                          align="center"
                          style={{
                            minWidth: column.width,
                            backgroundColor: rowIndex % 2 === 0 ? '#fff' : '#f0f0f0',
                            borderRight: '1px solid #d0d0d0',
                          }}
                          className={column.headerName === '인버터명' ? 'sticky' : ''}
                        >
                          {rowData[column.field] !== null && rowData[column.field] !== undefined
                            ? rowData[column.field] !== 0
                              ? rowData[column.field].toLocaleString()
                              : '0'
                            : '--'}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  );
                });

                if (sum !== 0) {
                  const formattedSum = parseFloat(sum.toFixed(5));

                  rowsWithSum.push(
                    <TableRow key={`${groupName}-sum`} sx={{ backgroundColor: '#d3d3d3' }}>
                      {columns.map((column, columnIndex) => (
                        <StyledTableCell
                          key={column.field}
                          align="center"
                          style={{
                            minWidth: column.width,
                            borderRight: '1px solid #d0d0d0',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            backgroundColor: '#1769aa',
                            color: 'white',
                          }}
                          className={columnIndex === 0 ? 'sticky' : ''}
                        >
                          {columnIndex === 0
                            ? '합계'
                            : column.field === 'totalyield'
                            ? formattedSum.toLocaleString()
                            : ''}
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  );
                }

                return rowsWithSum;
              })}
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Card>
    </>
  );
}
