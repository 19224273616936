import {
  FormControlLabel,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Switch,
  styled,
} from '@mui/material';
import NotificationsActiveOutlinedIcon from '@mui/icons-material/NotificationsActiveOutlined';
import { useContext, useEffect, useState } from 'react';
import AuthContext from '../../../store/auth-context';
import PhotoAlbumOutlinedIcon from '@mui/icons-material/PhotoAlbumOutlined';
import { useLocation } from 'react-router-dom';
import { saveStyle } from '../../User/UpdateDneTemplate';
import useBackendV2 from '../../../hooks/use-backend-v2';

// 알림메시지 수신 미수신 - switchState 값 수신(true) 미수신(false) 변경 컴포넌트
export default function AlarmReceiveDetail({
  alarmReceiveClickHandler,
  isMobileViewDNE,
  setIsMobileViewDNE,
}) {
  const authCtx = useContext(AuthContext);

  const { isAndroid, isiOS } = authCtx;

  const [switchState, setSwitchState] = useState();

  const location = useLocation(); // 현재 경로 가져오기
  const isHomePage = location.pathname === '/'; // 모니터링 화면

  const handleSwitchChange = () => {
    const newSwitchState = !switchState;

    if (newSwitchState === true) {
      const androidFunc = () => window.QWebApp.pushActive();
      const iOSFunc = () => window.webkit.messageHandlers.pushActive.postMessage('');

      if (isAndroid) {
        androidFunc();
      } else if (isiOS) {
        iOSFunc();
      }
    } else {
      const androidFunc = () => window.QWebApp.pushDeactive('');
      const iOSFunc = () => window.webkit.messageHandlers.pushDeactive.postMessage('');

      if (isAndroid) {
        androidFunc();
      } else if (isiOS) {
        iOSFunc();
      }
    }

    setSwitchState(newSwitchState);
  };

  useEffect(() => {
    const receiveSwitchState = (event) => {
      const message = event.data;
      if (message.action === 'receiveSwitchState') {
        setSwitchState(message.state);
      }
    };
    window.receiveSwitchState = receiveSwitchState;
    window.addEventListener('message', receiveSwitchState);

    let callSwitchState;

    if (isAndroid) {
      if (window.QWebApp && window.QWebApp.triggerSwitchState) {
        callSwitchState = () => window.QWebApp.triggerSwitchState();
      }
    } else if (isiOS) {
      if (window.webkit && window.webkit.messageHandlers.triggerSwitchState) {
        callSwitchState = () =>
          window.webkit.messageHandlers.triggerSwitchState.postMessage({ action: 'triggerSwitchState' });
      }
    }

    if (callSwitchState) {
      callSwitchState();
    }

    return () => {
      window.removeEventListener('message', receiveSwitchState);
    };
  }, [isAndroid, isiOS]);

  const CustomSwitch = styled(Switch)(({ theme }) => ({
    padding: 7,
    '& .MuiSwitch-track': {
      borderRadius: 20 / 2,
      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        top: '50%',
        transform: 'translateY(-50%)',
        width: 16,
        height: 16,
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: 'none',
      width: 18,
      height: 18,
      margin: 1,
    },
  }));

  const backendRequest = useBackendV2();

  // Switch 상태 업데이트 함수
  const handleThemeSwitchChange = async (event) => {
    const checked = event.target.checked;
    setIsMobileViewDNE(checked); // Switch 상태 업데이트
    await saveStyle(checked, authCtx.currentUser.id, backendRequest); // 백엔드로 상태 저장 요청
  };

  return (
    <>
      <List component="div" disablePadding>
        <ListItemButton onClick={alarmReceiveClickHandler}>
          <ListItemIcon>
            <NotificationsActiveOutlinedIcon />
          </ListItemIcon>
          <ListItemText primary="알림메시지 수신" />
          <FormControlLabel
            labelPlacement="start"
            control={<CustomSwitch checked={switchState} onChange={() => handleSwitchChange()} />}
          />
        </ListItemButton>
      </List>
      <List component="nav">
        <List component="div" disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <PhotoAlbumOutlinedIcon />
            </ListItemIcon>
            <ListItemText primary="모니터링 테마 변경" />
            <FormControlLabel
              labelPlacement="start"
              control={<CustomSwitch checked={isMobileViewDNE} onChange={handleThemeSwitchChange} />}
            />
          </ListItemButton>
        </List>
      </List>
    </>
  );
}
