import { useEffect, useState } from 'react';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import { convertFormatStringToYMD } from '../../../utils/dateUtil';
import YearAllStatistics from './YearAllStatistics';
import YearGroupStatistics from './YearGroupStatistics';
import YearInverterStatistics from './YearInverterStatistics';

export default function Year({
  page,
  subValue,
  tabValue,
  dateValue,
  searchState,
  setSearchState,
  chartView,
}) {
  const [yearlyDevelopment, setYearlyDevelopment] = useState([]);

  const yearlyDevelopmentRequest = useBackendV2();

  const fetchYearlyDevelopmentData = async () => {
    try {
      let tab;
      if (tabValue === 'all') {
        tab = 0;
      } else if (tabValue === 'group') {
        tab = 1;
      } else if (tabValue === 'inverter') {
        tab = 2;
      }

      const queryStringArr = [
        `yieldGroupType=${tab}`,
        `yieldtype=${subValue}`,
        `dateTime=${convertFormatStringToYMD(dateValue)}`,
      ];
      const queryString = queryStringArr.join('&');

      const { data } = await yearlyDevelopmentRequest.sendRequest(
        `${config.backend.endPoint.statistics.plantYield}/${page.plantkey}?${queryString}`
      );

      setYearlyDevelopment(data.map((item, index) => ({ ...item, id: index })));
      setSearchState(false);
    } catch (error) {
      setYearlyDevelopment([]);
      setSearchState(false);
    }
  };

  useEffect(() => {
    if (searchState && page.plantkey !== '') {
      fetchYearlyDevelopmentData();
    }
  }, [searchState, page]);

  let componentToShow;

  switch (tabValue) {
    case 'all':
      componentToShow = <YearAllStatistics data={yearlyDevelopment} chartView={chartView} />;
      break;
    case 'group':
      componentToShow = <YearGroupStatistics data={yearlyDevelopment} chartView={chartView} />;
      break;
    case 'inverter':
      componentToShow = <YearInverterStatistics data={yearlyDevelopment} chartView={chartView} />;
      break;
    default:
      componentToShow = null;
      break;
  }

  return <>{componentToShow}</>;
}
