import { Avatar, Box, Card, Checkbox, Collapse, Divider, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export default function MobilePushSettingCard({ data, eachCheckboxClickHandler }) {
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          padding: {
            xs: 0,
            sm: 2,
          },
          borderRadius: '13px',
          boxShadow: '0px 3px 12px #00000014',
          '&:hover': { borderColor: 'blue', borderWidth: '2px' },
          width: '100%',
          mb: 1,
        }}
      >
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          width="100%"
          spacing={2}
        >
          <Stack
            sx={{
              padding: {
                xs: 1,
                sm: 0,
              },
            }}
            flex={1}
          >
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Box display="flex" alignItems="center">
                <Checkbox
                  checked={data.isSet === '1'}
                  onChange={() => eachCheckboxClickHandler(data.isSet)}
                  inputProps={{ 'aria-label': 'checkbox' }}
                />
                <Typography variant="subtitle2">{data.errMsg}</Typography>
              </Box>
              <Avatar sx={{ width: '22px', height: '22px', ml: 2, bgcolor: '#1981E7' }}>
                <ExpandMoreIcon
                  onClick={handleExpandClick}
                  sx={{ color: '#fff', transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)' }}
                />
              </Avatar>
            </Box>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <Divider sx={{ mb: 1, mt: 1 }} />
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                <Typography variant="subtitle2">장비 타입</Typography>
                <Typography variant="subtitle2" textAlign="end" color="primary">
                  {data.ivtAcb}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                <Typography variant="subtitle2">인버터 타입</Typography>
                <Typography variant="subtitle2" textAlign="end">
                  {data.iType}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                <Typography variant="subtitle2">경보 코드</Typography>
                <Typography variant="subtitle2" textAlign="end">
                  {data.errNUM}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mt: 2 }}>
                <Typography variant="subtitle2">경보 상태</Typography>
                <Typography variant="subtitle2" textAlign="end">
                  {data.errStatus}
                </Typography>
              </Box>
            </Collapse>
          </Stack>
        </Stack>
      </Card>
    </>
  );
}
