import {
  Button,
  Divider,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Stack,
} from '@mui/material';
import AppsIcon from '@mui/icons-material/Apps';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ListIcon from '@mui/icons-material/List';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';
import ControlCameraIcon from '@mui/icons-material/ControlCamera';
import AuthContext from '../../../store/auth-context';

// 웹 메뉴 인버터 컴포넌트
export default function InverterWebMenu({ setValue, setSubValue }) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setOpen(null);
  };

  const listClickHandler = () => {
    setValue(1);
    localStorage.setItem('menuValue', 1);
    setSubValue(0);
    localStorage.setItem('menuSubValue', 0);
    navigate(config.frontend.inverters, { state: { subValue: 0 } });
  };
  const systematicMapClickHandler = () => {
    setValue(1);
    localStorage.setItem('menuValue', 1);
    setSubValue(1);
    localStorage.setItem('menuSubValue', 1);
    navigate(config.frontend.inverters, { state: { subValue: 1 } });
  };
  const connectBoardClickHandler = () => {
    setValue(1);
    localStorage.setItem('menuValue', 1);
    setSubValue(2);
    localStorage.setItem('menuSubValue', 2);
    navigate(config.frontend.inverters, { state: { subValue: 2 } });
  };
  const deviceControlClickHandler = () => {
    setValue(1);
    localStorage.setItem('menuValue', 1);
    setSubValue(3);
    localStorage.setItem('menuSubValue', 3);
    navigate(config.frontend.inverters, { state: { subValue: 3 } });
  };

  return (
    <>
      <Button
        startIcon={<AppsIcon fontSize="large" />}
        endIcon={<KeyboardArrowDownIcon />}
        sx={{
          color: '#fff',
          fontSize: 'large',
          mr: 0.5,
        }}
        onClick={handleOpen}
      >
        인버터
      </Button>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 250,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Stack sx={{ p: 1 }}>
          <List component="nav">
            <ListItemButton onClick={listClickHandler}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              <ListItemText primary="목록" />
            </ListItemButton>
          </List>
          <Divider />
          <List component="nav">
            <ListItemButton onClick={systematicMapClickHandler}>
              <ListItemIcon>
                <AccountTreeOutlinedIcon />
              </ListItemIcon>
              <ListItemText primary="계통도" />
            </ListItemButton>
          </List>
          <Divider />
          <List component="nav">
            <ListItemButton onClick={connectBoardClickHandler}>
              <ListItemIcon>
                <InboxIcon />
              </ListItemIcon>
              <ListItemText primary="접속반" />
            </ListItemButton>
          </List>
          {authCtx.currentUser.role === '사이트 관리자' || authCtx.currentUser.role === '안전관리자' ? (
            <>
              <Divider />
              <List component="nav">
                <ListItemButton onClick={deviceControlClickHandler}>
                  <ListItemIcon>
                    <ControlCameraIcon />
                  </ListItemIcon>
                  <ListItemText primary="장비제어" />
                </ListItemButton>
              </List>
            </>
          ) : null}
        </Stack>
      </Popover>
    </>
  );
}
