import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import '../../../css/App.css';
import { useNavigate } from 'react-router-dom';
import { convertFormatStringToYMD } from '../../../utils/dateUtil';

export default function EntirePlantInfoTable({ plant }) {
  const navigate = useNavigate();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  const formatDateHour = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const moveEachPlantHandler = (plantkey) => {
    localStorage.setItem('selectPlantkey', plantkey);
    localStorage.setItem('menuValue', 0);
    localStorage.setItem('menuSubValue', 1);
    navigate('/', { replace: true, state: { value: 0, subValue: 1 } });
  };

  const groupedData = plant.reduce((acc, curr) => {
    const key = curr.plantkeyMerge;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(curr);
    return acc;
  }, {});

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{
          borderRadius: '10px',
          boxShadow: '5px 6px 12px 5px #00000014',
          overflow: 'hidden',
          overflowX: 'auto',
        }}
      >
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                발전소명
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                인버터명
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                인버터정보
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                RTU상태
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                인버터상태
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                RTU통신시간
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                상태
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                출력전력
                <br />
                (kW)
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                일일발전량
                <br />
                (kWh)
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                금월발전량
                <br />
                (kWh)
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                전월발전량
                <br />
                (kWh)
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                총발전량
                <br />
                (MWh)
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                보정값
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                개통월
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                장비 보증기간
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                구독 만료일
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  borderRight: '1px solid #fff',
                  bgcolor: '#323a50',
                  color: 'white',
                }}
              >
                전문기업
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(groupedData).map(([key, group], groupIndex) =>
              group.map((item, index) => (
                <TableRow
                  key={index}
                  sx={{
                    '&:hover': {
                      bgcolor: '#edf4ff',
                      cursor: 'pointer',
                    },
                  }}
                >
                  {index === 0 ? (
                    <>
                      <TableCell
                        rowSpan={group.length}
                        align="center"
                        sx={{ borderRight: '1px solid #323a50', borderBottom: '1px solid #323a50' }}
                      >
                        <Typography
                          variant="subtitle2"
                          onClick={() => moveEachPlantHandler(item.plantkeyMerge)}
                        >
                          {item.plantName}
                        </Typography>
                        <Typography variant="body2" sx={{ mt: 1 }}>
                          {item.street}
                        </Typography>
                      </TableCell>
                    </>
                  ) : null}
                  <TableCell
                    align="center"
                    sx={{ borderRight: '1px solid #323a50', borderBottom: '1px solid #323a50' }}
                  >
                    {item.ivtname}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: '1px solid #323a50', borderBottom: '1px solid #323a50' }}
                  >
                    {item.serialnum}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: '1px solid #323a50',
                      borderBottom: '1px solid #323a50',
                      filter: item.subscribeday - item.graceperiod > 0 ? 'blur(5px)' : '',
                    }}
                  >
                    <Box sx={{ bgcolor: item.nodata === '1' ? 'red' : '#24b726', borderRadius: 1, p: 0.4 }}>
                      {item.nodata === '0' ? (
                        <Typography variant="subtitle2" sx={{ color: '#fff' }}>
                          통신중
                        </Typography>
                      ) : (
                        <Typography variant="subtitle2" sx={{ color: '#fff' }}>
                          통신중단
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: '1px solid #323a50',
                      borderBottom: '1px solid #323a50',
                      filter: item.subscribeday - item.graceperiod > 0 ? 'blur(5px)' : '',
                    }}
                  >
                    <Box
                      sx={{
                        bgcolor:
                          item.ivtnodata === '1' || item.status === '-1' || item.pac <= 0 ? 'red' : '#24b726',
                        borderRadius: 1,
                        p: 0.4,
                      }}
                    >
                      {item.ivtnodata === '1' || item.status === '-1' || item.pac <= 0 ? (
                        <Typography variant="subtitle2" sx={{ color: '#fff' }}>
                          발전중단
                        </Typography>
                      ) : (
                        <Typography variant="subtitle2" sx={{ color: '#fff' }}>
                          발전중
                        </Typography>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: '1px solid #323a50',
                      borderBottom: '1px solid #323a50',
                      filter: item.subscribeday - item.graceperiod > 0 ? 'blur(5px)' : '',
                    }}
                  >
                    {formatDateHour(item.rturegdate)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: '1px solid #323a50',
                      borderBottom: '1px solid #323a50',
                      filter: item.subscribeday - item.graceperiod > 0 ? 'blur(5px)' : '',
                    }}
                  >
                    {item.status}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: '1px solid #323a50',
                      borderBottom: '1px solid #323a50',
                      filter: item.subscribeday - item.graceperiod > 0 ? 'blur(5px)' : '',
                    }}
                  >
                    {(item.pac * 0.001).toFixed(1)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: '1px solid #323a50',
                      borderBottom: '1px solid #323a50',
                      filter: item.subscribeday - item.graceperiod > 0 ? 'blur(5px)' : '',
                    }}
                  >
                    {item.dailyYield.toFixed(1)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: '1px solid #323a50',
                      borderBottom: '1px solid #323a50',
                      filter: item.subscribeday - item.graceperiod > 0 ? 'blur(5px)' : '',
                    }}
                  >
                    {item.nowmonth.toFixed(1)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: '1px solid #323a50',
                      borderBottom: '1px solid #323a50',
                      filter: item.subscribeday - item.graceperiod > 0 ? 'blur(5px)' : '',
                    }}
                  >
                    {item.beforemonth.toFixed(1)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: '1px solid #323a50',
                      borderBottom: '1px solid #323a50',
                      filter: item.subscribeday - item.graceperiod > 0 ? 'blur(5px)' : '',
                    }}
                  >
                    {(item.totalYield * 0.001).toFixed(1)}
                    {/* {item.totalYield} */}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: '1px solid #323a50', borderBottom: '1px solid #323a50' }}
                  >
                    {item.basetotal}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: '1px solid #323a50', borderBottom: '1px solid #323a50' }}
                  >
                    {item.flocaldate}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{ borderRight: '1px solid #323a50', borderBottom: '1px solid #323a50' }}
                  >
                    {formatDate(item.tdate)}
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      borderRight: '1px solid #323a50',
                      borderBottom: '1px solid #323a50',
                      color: item.subscribeday > 0 ? 'red' : 'inherit',
                    }}
                  >
                    <Box display="flex" justifyContent="center">
                      {convertFormatStringToYMD(item.enddate)}
                    </Box>
                  </TableCell>
                  <TableCell align="center" sx={{ borderBottom: '1px solid #323a50' }}>
                    {item.organName}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
