import { Box, Divider, Typography } from '@mui/material';
import CustomModal from '../../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import AssignmentIcon from '@mui/icons-material/Assignment';
import { convertStringToFormatDateString } from '../../../utils/dateUtil';

// 인버터 리스트 개별 발전소 내 인버터 세부 상태 표시 모달 컴포넌트
export default function InvListModal({ open, onClose, subscribeday, graceperiodDay, invData, isDesktop }) {
  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <>
      <CustomModal
        open={open}
        onClose={closeModalHandler}
        sx={{ maxHeight: '95%', width: { xs: '95%', md: 800 } }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <AssignmentIcon sx={{ mr: 0.5 }} />
          <Typography variant="subtitle1">{invData.invertername}</Typography>
          {/* <Typography variant="subtitle2">({invData.serialnum})</Typography> */}
        </Box>
        <hr />
        <Box sx={{ filter: subscribeday - graceperiodDay > 0 ? 'blur(5px)' : '' }}>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} color="primary">
              마지막 통신 시간
            </Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} sx={{ ml: isDesktop ? 1 : 1 }}>
              {convertStringToFormatDateString(invData.rdate)}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>인버터 정보</Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} sx={{ ml: 1 }}>
              {invData.serialnum}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>발전 상태</Typography>
            <Typography
              variant={isDesktop ? 'subtitle1' : 'subtitle2'}
              color={invData.generatestatus === 1 ? 'primary' : 'error'}
              sx={{ ml: 1 }}
            >
              {invData.generatestatus === 1 ? '발전 중' : '발전 중단'}
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>발전 시간</Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} sx={{ ml: 1 }}>
              {invData?.dailyyield === null
                ? 0
                : invData?.ivtcapacity === null
                ? 0
                : (invData?.dailyyield / invData?.ivtcapacity).toFixed(1)}
              h
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>일일 발전량</Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
              {invData?.dailyyield === null ? 0 : invData?.dailyyield?.toFixed(1)} kWh
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>총 발전량</Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
              {invData?.totalyield === null ? 0 : invData?.totalyield?.toFixed(1)} kWh
            </Typography>
          </Box>
          <Divider sx={{ mt: 1.5, mb: 1 }} />
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} color="primary">
              입력(DC)
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>입력 전력</Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
              {invData?.pdc?.toFixed(1)} kW
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>입력 전압</Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
              {invData?.udc?.toFixed(1)} Vdc
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>입력 전류</Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
              {invData?.idc?.toFixed(1)} A
            </Typography>
          </Box>
          <Divider sx={{ mt: 1.5, mb: 1 }} />
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} color="error">
              출력(AC)
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>출력 전력</Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
              {invData?.pac?.toFixed(1)} kW
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>출력 전압</Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
              {invData?.uac?.toFixed(1)} Vac
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>출력 전류</Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
              {invData?.iac?.toFixed(1)} A
            </Typography>
          </Box>
          <Divider sx={{ mt: 1.5, mb: 2 }} />
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>기타 정보</Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>인버터 용량</Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} sx={{ ml: 1 }}>
              {invData.ivtcapacity}kW
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>주파수(Hz)</Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} sx={{ ml: 1 }}>
              {invData?.fac?.toFixed(1)} Hz
            </Typography>
          </Box>
          <Box
            sx={{
              mt: 1.5,
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>지락 전류</Typography>
            <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} sx={{ ml: 1 }}>
              0 A
            </Typography>
          </Box>
        </Box>
      </CustomModal>
    </>
  );
}
