import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

/*
자료실 리스트 컴포넌트(모바일뷰)
ArchiveSearchForm > ArchiveList(웹뷰 리스트 페이지),ArchiveListMobile(모바일뷰 리스트 페이지)
*/
export default function ArchiveListMobile({ rows, onBtnMore }) {
  return (
    <>
      {rows.length > 0 ? (
        rows.map((row, i) => (
          <Card sx={{ width: '100%', mb: 2, borderRadius: '16px' }} key={row.seq}>
            <Stack
              direction="row"
              sx={{
                mt: 2,
                mb: 1,
                ml: 1,
                mr: 2,
                textAlign: 'left',
                font: 'normal normal 600 14px/23px Noto Sans',
                letterSpacing: '0px',
                color: '#000000',
                opacity: '1',
              }}
            >
              {row.title}
            </Stack>
            <Stack
              direction="row"
              sx={{
                mb: 1,
                ml: 1,
                mr: 2,
                textAlign: 'left',
                font: 'normal normal normal 12px/23px Noto Sans',
                letterSpacing: '0px',
                color: '#000000',
                opacity: '1',
              }}
            >
              {row.content}
            </Stack>
            <Stack direction="row">
              <Typography
                sx={{
                  mb: 2,
                  ml: 1,
                  mr: 5,
                  textAlign: 'left',
                  font: 'normal normal normal 12px/17px Noto Sans',
                  letterSpacing: '0px',
                  color: '#8B8C9C',
                  opacity: '1',
                  width: '100%',
                }}
              >
                {`${row.transdate === null ? '' : row.transdate.substring(0, 10)}`}
              </Typography>
              <Typography
                sx={{
                  mb: 2,
                  mr: 2,
                  width: '100%',
                  textAlign: 'right',
                }}
              >
                <Link to={`https://filedeliver.sensorcube.co.kr/S${row.fileServerSuffix}`}>
                  <FileDownloadOutlinedIcon fontSize="small" sx={{ color: 'black' }} />
                </Link>
              </Typography>
            </Stack>
          </Card>
        ))
      ) : (
        <Card sx={{ width: '100%', mt: 2 }}>
          <CardHeader title="조회된 정보가 없습니다." sx={{ textAlign: 'center' }} />
        </Card>
      )}
    </>
  );
}
