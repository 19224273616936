import { useEffect, useState } from 'react';

// 모바일의 경우 화면을 새로고침 할 수 있게 하는 컴포넌트
export default function PullToRefresh({ onRefresh, distance, setDistance }) {
  const [startY, setStartY] = useState(null);

  const handleTouchStart = (e) => {
    setStartY(e.touches[0].clientY);
  };

  const handleTouchMove = (e) => {
    if (startY !== null) {
      const newDistance = e.touches[0].clientY - startY;
      setDistance(newDistance);
    }
  };

  const handleTouchEnd = () => {
    if (startY !== null) {
      // 새로고침이 되지 않았다면 distance를 0으로 설정
      if (distance > 180 && window.scrollY === 0) {
        onRefresh();
      }
      setDistance(0);
    }
    setStartY(null);
  };

  useEffect(() => {
    window.addEventListener('touchstart', handleTouchStart);
    window.addEventListener('touchmove', handleTouchMove);
    window.addEventListener('touchend', handleTouchEnd);
    return () => {
      window.removeEventListener('touchstart', handleTouchStart);
      window.removeEventListener('touchmove', handleTouchMove);
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, [startY, onRefresh, setDistance, distance]);

  return;
}
