import { Avatar, Box, Button, Card, Collapse, Divider, Typography } from '@mui/material';
import { fNumberWithCommas } from '../../utils/formatNumber';
import { convertFormatString } from '../../utils/dateUtil';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import DeliveryProductStatusModal from '../Delivery/DeliveryProductStatusModal';
import BanktransferQuestionModal from './BanktransferQuestionModal';
import MakeDepositStatus from './MakeDepositStatus';
import OrderReceiptButton from './OrderReceiptButton';
import { useNavigate } from 'react-router-dom';

export default function OrderInfoListCard({
  product,
  calculateDateDifference,
  cancelOrderClickHandler,
  setOrderDepositorStatus,
  isDesktop,
}) {
  const navigate = useNavigate();

  const [orderDetailList, setOrderDetailList] = useState([]);
  const [rtuNsData, setRtuNsData] = useState([]);
  const [plantData, setPlantData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [openDeliveryModal, setOpenDeliveryModal] = useState(false);
  const [openBanktransferQuestionModal, setOpenBankTransferQuestionModal] = useState(false);

  const backendRequest = useBackendV2();

  const fetchOrderDetailList = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.orders.listDetail}/${product.orderid}`
      );
      setOrderDetailList(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  // 주문 상세에 발전소 추가
  const fetchFindPlant = async () => {
    try {
      // 주문 상세 정보 목록을 map 함수를 사용하여 반복
      const updatedOrderDetailList = orderDetailList.map(async (order) => {
        // 만약 order의 rtuNs가 비어 있지 않으면 처리 시작
        if (order.rtuNs && order.rtuNs !== ('' || null)) {
          const plantDatas = [];

          // rtuNs를 파싱하여 각 rtuNsItem에 대해 처리
          for (const rtuNsItem of JSON.parse(order.rtuNs)) {
            const { data } = await backendRequest.sendRequest(
              `${config.backend.endPoint.plants.findRtunPlant}/${rtuNsItem}`
            );
            // 가져온 데이터를 발전소 데이터 배열에 추가
            plantDatas.push(data);
          }

          // order에 발전소 데이터 배열을 추가한 새로운 객체를 반환
          return { ...order, plant: plantDatas };
        } else {
          // rtuNs가 비어 있으면 order를 그대로 반환
          return order;
        }
      });
      // Promise.all을 사용하여 모든 프로미스를 병렬로 처리하고 업데이트된 order 목록을 얻음
      const updatedOrders = await Promise.all(updatedOrderDetailList);

      setPlantData(updatedOrders);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const handleExpandClick = () => {
    setExpanded(!expanded);
    fetchOrderDetailList();
  };

  const openDeliveryModalHandler = () => {
    setOpenDeliveryModal(true);
  };
  const closeDeliveryModalHandler = () => {
    setOpenDeliveryModal(false);
  };

  const openBankTransferQuestionModalHandler = () => {
    setOpenBankTransferQuestionModal(true);
  };
  const closeBankTransferQuestionModalHandler = () => {
    setOpenBankTransferQuestionModal(false);
  };

  const moveInquiryBoardAdd = () => {
    navigate(`${config.frontend.inquiryBoardsadd}`, { state: 6 });
  };

  useEffect(() => {
    const newData = orderDetailList
      .map((d) => {
        if (d.rtuNs && d.rtuNs !== '') {
          // rtuNs가 존재하고 비어있지 않은 경우
          const rtuNsArray = JSON.parse(d.rtuNs); // JSON 문자열을 배열로 파싱
          return rtuNsArray;
        } else {
          return null; // 빈 배열 대신 null 반환
        }
      })
      .filter((item) => item !== null); // null 값 제외

    setRtuNsData(newData);
  }, [orderDetailList]);

  useEffect(() => {
    fetchFindPlant();
  }, [rtuNsData]);

  return (
    <>
      <Card
        variant="outlined"
        sx={{
          padding: {
            xs: 0,
            sm: 2,
          },
          borderRadius: '14px',
          boxShadow: '0px 3px 12px #00000014',
          '&:hover': { borderColor: 'blue', borderWidth: '2px' },
          width: '100%',
          mb: 1,
        }}
      >
        <Box sx={{ p: 1 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography
              variant="subtitle1"
              display="flex"
              alignContent="center"
              sx={{
                color: product.status === 'CANCELED' ? 'text.disabled' : '',
                textDecoration: product.status === 'CANCELED' ? 'line-through' : '',
              }}
            >
              {product.orderName}
            </Typography>
            <Box display="flex" alignItems="center">
              {product.deid !== null ? (
                <Button variant="outlined" size="small" onClick={openDeliveryModalHandler} sx={{ mr: 1 }}>
                  배송 조회
                </Button>
              ) : null}
              <Avatar sx={{ width: '22px', height: '22px', bgcolor: '#1981E7' }}>
                <ExpandMoreIcon
                  onClick={handleExpandClick}
                  sx={{
                    color: '#fff',
                    transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                    cursor: 'pointer',
                  }}
                />
              </Avatar>
            </Box>
          </Box>
          <Typography variant="subtitle2" sx={{ mt: 1 }}>
            결제 수단 : {product.method}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 1 }}>
            주문 번호 : {product.orderid}
          </Typography>
          <Typography variant="subtitle2" color="text.secondary">
            주문 날짜 : {convertFormatString(product.approveDate)}
          </Typography>
          {product.istax === 1 ? (
            <Typography variant="subtitle2" color="text.secondary" sx={{ mt: 0.5 }}>
              세금계산서 :{' '}
              <span style={{ color: 'darkgreen', textDecoration: 'underline', fontSize: 16 }}>발행 완료</span>
            </Typography>
          ) : null}
          {expanded && product.address !== '' ? (
            <Box display={isDesktop ? 'flex' : ''} sx={{ mt: 1 }}>
              <Typography variant="subtitle2" color="text.secondary">
                주소지 :
              </Typography>
              <Typography variant="subtitle2" color="text.secondary">
                {product.address}
              </Typography>
            </Box>
          ) : null}
          {product.status === 'CANCELED' && product.method !== '무통장입금' ? (
            <>
              <Button variant="contained" size="small" color="error" sx={{ mt: 1.5, mr: 1 }}>
                결제 취소 완료
              </Button>
            </>
          ) : null}
          {product.method === '무통장입금' ? (
            <>
              <MakeDepositStatus
                status={product.status}
                istax={product.istax}
                open={openBankTransferQuestionModalHandler}
                moveInquiryBoardAdd={moveInquiryBoardAdd}
              />
            </>
          ) : null}
          {product.method === '카드' ||
          product.method === '계좌이체' ||
          product.method === '간편결제' ||
          product.method === '가상계좌' ? (
            <>
              <OrderReceiptButton orderid={product.orderid} method={product.method} />
            </>
          ) : null}
        </Box>
        <Box display="flex" alignItems="center" justifyContent="end" sx={{ mt: 1, mb: isDesktop ? 0 : 0.5 }}>
          <Box display="flex" alignItems="center">
            <Typography
              variant="h5"
              sx={{
                color: product.status === 'CANCELED' ? 'text.disabled' : '',
                textDecoration: product.status === 'CANCELED' ? 'line-through' : '',
                mr: 1,
                ml: 1,
              }}
            >
              {fNumberWithCommas(product.totalAmount)}원
            </Typography>
          </Box>
        </Box>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
          <Divider sx={{ mt: 2, mb: 1 }} />
          <Box sx={{ p: 1 }}>
            {plantData.map((data, index) => (
              <Box key={index}>
                <Box display="flex" alignContent="center">
                  <img
                    src={`https://filedeliver.sensorcube.co.kr/S/File/GetImage?domain=SC&id=${data.filekey}`}
                    style={{ width: 100, height: 100, marginRight: 10 }}
                  />
                  <Divider orientation="vertical" flexItem sx={{ mr: 1 }} />
                  <Box sx={{ width: '100%', p: 1 }}>
                    <Typography variant="subtitle1">
                      {data.pdtname} {data.cnt}개
                    </Typography>
                    {data.pdttype === '0' ? (
                      <Box display={isDesktop ? 'flex' : ''} sx={{ mt: 1 }}>
                        <Typography variant="subtitle2">구독 키:</Typography>
                        <Typography variant="subtitle2" sx={{ ml: 1 }}>
                          {data.subscribekey?.replace(/[\[\]"]/g, ' ')}
                        </Typography>
                      </Box>
                    ) : null}
                    <Typography variant="subtitle1" display="flex" justifyContent="end" sx={{ mt: 2 }}>
                      {fNumberWithCommas(data.price)}원
                    </Typography>
                  </Box>
                </Box>
                <Divider sx={{ mt: 1, mb: 1 }} />
              </Box>
            ))}
          </Box>
        </Collapse>
      </Card>
      {openDeliveryModal && (
        <DeliveryProductStatusModal
          orderid={product.orderid}
          open={openDeliveryModal}
          onClose={closeDeliveryModalHandler}
        />
      )}
      {openBanktransferQuestionModal && (
        <BanktransferQuestionModal
          orderid={product.orderid}
          orderName={product.orderName}
          totalAmount={product.totalAmount}
          setOrderDepositorStatus={setOrderDepositorStatus}
          open={openBanktransferQuestionModal}
          onClose={closeBankTransferQuestionModalHandler}
        />
      )}
    </>
  );
}
