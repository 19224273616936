import { Box, Tab, Tabs } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import AuthContext from '../../store/auth-context';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

// 페이지별 탭 메뉴 컴포넌트
export default function TabMenu({ menu, subValue, setSubValue }) {
  const navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const [value, setValue] = useState(subValue);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setSubValue(newValue);
  };

  useEffect(() => {
    if (menu === 'inverters') {
      if (value === 0) {
        localStorage.setItem('menuValue', 1);
        localStorage.setItem('menuSubValue', 0);
        navigate(config.frontend.inverters, { state: { subValue: 0 } });
      } else if (value === 1) {
        localStorage.setItem('menuValue', 1);
        localStorage.setItem('menuSubValue', 1);
        navigate(config.frontend.inverters, { state: { subValue: 1 } });
      } else if (value === 2) {
        localStorage.setItem('menuValue', 1);
        localStorage.setItem('menuSubValue', 2);
        navigate(config.frontend.inverters, { state: { subValue: 2 } });
      } else if (value === 3) {
        localStorage.setItem('menuValue', 1);
        localStorage.setItem('menuSubValue', 3);
        navigate(config.frontend.inverters, { state: { subValue: 3 } });
      }
    } else if (menu === 'statistics') {
      if (value === 0) {
        localStorage.setItem('menuValue', 2);
        localStorage.setItem('menuSubValue', 0);
        navigate(config.frontend.statistics, { state: { subValue: 0 } });
      } else if (value === 1) {
        localStorage.setItem('menuValue', 2);
        localStorage.setItem('menuSubValue', 1);
        navigate(config.frontend.statistics, { state: { subValue: 1 } });
      } else if (value === 2) {
        localStorage.setItem('menuValue', 2);
        localStorage.setItem('menuSubValue', 2);
        navigate(config.frontend.statistics, { state: { subValue: 2 } });
      } else if (value === 3) {
        localStorage.setItem('menuValue', 2);
        localStorage.setItem('menuSubValue', 3);
        navigate(config.frontend.statistics, { state: { subValue: 3 } });
      } else if (value === 4) {
        localStorage.setItem('menuValue', 2);
        localStorage.setItem('menuSubValue', 4);
        navigate(config.frontend.statistics, { state: { subValue: 4 } });
      } else if (value === 5) {
        localStorage.setItem('menuValue', 2);
        localStorage.setItem('menuSubValue', 5);
        navigate(config.frontend.statistics, { state: { subValue: 5 } });
      }
    } else if (menu === 'alarms') {
      if (value === 0) {
        localStorage.setItem('menuValue', 3);
        localStorage.setItem('menuSubValue', 0);
        navigate(config.frontend.alarms, { state: { subValue: 0 } });
      } else if (value === 1) {
        localStorage.setItem('menuValue', 3);
        localStorage.setItem('menuSubValue', 1);
        navigate(config.frontend.alarms, { state: { subValue: 1 } });
      }
    } else if (menu === 'bulletinboards') {
      if (value === 0) {
        localStorage.setItem('menuValue', 4);
        localStorage.setItem('menuSubValue', 0);
        navigate(config.frontend.bulletinboards, { state: { subValue: 0 } });
      } else if (value === 1) {
        localStorage.setItem('menuValue', 4);
        localStorage.setItem('menuSubValue', 1);
        navigate(config.frontend.bulletinboards, { state: { subValue: 1 } });
      } else if (value === 2) {
        localStorage.setItem('menuValue', 4);
        localStorage.setItem('menuSubValue', 2);
        navigate(config.frontend.bulletinboards, { state: { subValue: 2 } });
      } else if (value === 3) {
        localStorage.setItem('menuValue', 4);
        localStorage.setItem('menuSubValue', 3);
        navigate(config.frontend.bulletinboards, { state: { subValue: 3 } });
      }
    }
  }, [value]);

  useEffect(() => {
    setValue(subValue);
  }, [subValue]);

  const tabTheme = createTheme({
    palette: { primary: { main: '#263238' }, secondary: { main: '#90a4ae' }, info: { main: '#cfd8dc' } },
  }); // 회색
  // const tabTheme = createTheme({ palette: { primary: { main: '#000' }, secondary: { main: '#f57f17' }, info: { main: '#f9a825' } } }); // 주황색 1
  // const tabTheme = createTheme({ palette: { primary: { main: '#263238' }, secondary: { main: '#ffcc80' }, info: { main: '#ffe0b2' } } }); // 주황색 2
  // const tabTheme = createTheme({ palette: { primary: { main: '#fff' }, secondary: { main: '#0d47a1' }, info: { main: '#1565c0' } } }); // 파랑색 1
  // const tabTheme = createTheme({ palette: { primary: { main: '#263238' }, secondary: { main: '#90caf9' }, info: { main: '#a6d4fa' } } }); // 파랑색 2
  // const tabTheme = createTheme({ palette: { primary: { main: '#fff' }, secondary: { main: '#388e3c' }, info: { main: '#4caf50' } } }); // 초록색
  // const tabTheme = createTheme({ palette: { primary: { main: '#fff' }, secondary: { main: '#3d5afe' }, info: { main: '#536dfe' } } }); // 인디고

  return (
    <>
      <ThemeProvider theme={tabTheme}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 1 }}>
          {menu === 'inverters' ? (
            <>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="secondary"
                variant="scrollable"
                scrollButtons={false}
                allowScrollButtonsMobile
                sx={{
                  minHeight: '30px',
                  '.MuiTab-root': { minHeight: '30px' },
                  '.MuiButtonBase-root': { minHeight: '30px' },
                }}
              >
                {authCtx.currentUser.role === '사이트 관리자' || authCtx.currentUser.role === '안전관리자'
                  ? ['목록', '계통도', '접속반', '장비제어'].map((label, index) => (
                      <Tab
                        key={label}
                        label={label}
                        {...a11yProps(index)}
                        sx={{
                          bgcolor: value === index ? 'info.main' : 'transparent',
                          borderRadius: value === index ? '4px' : '0',
                          fontWeight: value === index ? 'bold' : 'normal',
                        }}
                      />
                    ))
                  : ['목록', '계통도', '접속반'].map((label, index) => (
                      <Tab
                        key={label}
                        label={label}
                        {...a11yProps(index)}
                        sx={{
                          bgcolor: value === index ? 'info.main' : 'transparent',
                          borderRadius: value === index ? '4px' : '0',
                          fontWeight: value === index ? 'bold' : 'normal',
                        }}
                      />
                    ))}
              </Tabs>
            </>
          ) : menu === 'statistics' ? (
            <>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="secondary"
                variant="scrollable"
                scrollButtons={false}
                allowScrollButtonsMobile
                sx={{
                  minHeight: '30px',
                  '.MuiTab-root': { minHeight: '30px' },
                  '.MuiButtonBase-root': { minHeight: '30px' },
                }}
              >
                {['시간', '일간', '월간', '연간', '환경정보통계', '종합분석통계'].map((label, index) => (
                  <Tab
                    key={label}
                    label={label}
                    {...a11yProps(index)}
                    sx={{
                      bgcolor: value === index ? 'info.main' : 'transparent',
                      borderRadius: value === index ? '4px' : '0',
                      fontWeight: value === index ? 'bold' : 'normal',
                    }}
                  />
                ))}
              </Tabs>
            </>
          ) : menu === 'alarms' ? (
            <>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="secondary"
                variant="scrollable"
                scrollButtons={false}
                allowScrollButtonsMobile
                sx={{
                  minHeight: '30px',
                  '.MuiTab-root': { minHeight: '30px' },
                  '.MuiButtonBase-root': { minHeight: '30px' },
                }}
              >
                {['이력', '발생 빈도'].map((label, index) => (
                  <Tab
                    key={label}
                    label={label}
                    {...a11yProps(index)}
                    sx={{
                      bgcolor: value === index ? 'info.main' : 'transparent',
                      borderRadius: value === index ? '4px' : '0',
                      fontWeight: value === index ? 'bold' : 'normal',
                    }}
                  />
                ))}
              </Tabs>
            </>
          ) : (
            <>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="secondary"
                variant="scrollable"
                scrollButtons={false}
                allowScrollButtonsMobile
                sx={{
                  minHeight: '30px',
                  '.MuiTab-root': { minHeight: '30px' },
                  '.MuiButtonBase-root': { minHeight: '30px' },
                }}
              >
                {/* {['요청 게시판', '공지 게시판', '자료실', '통합 문의 게시판'].map((label, index) => ( */}
                {['공지 게시판', '자료실', '통합 문의 게시판'].map((label, index) => (
                  <Tab
                    key={label}
                    label={label}
                    {...a11yProps(index)}
                    sx={{
                      bgcolor: value === index ? 'info.main' : 'transparent',
                      borderRadius: value === index ? '4px' : '0',
                      fontWeight: value === index ? 'bold' : 'normal',
                    }}
                  />
                ))}
              </Tabs>
            </>
          )}
        </Box>
      </ThemeProvider>
    </>
  );
}
