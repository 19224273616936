import { Box, Button, Tooltip, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import PlantSettingModal from '../../Setting/PlantSetting/PlantSettingModal';
import config from '../../../config';
import useBackendV2 from '../../../hooks/use-backend-v2';
import MainCard from '../../../theme/MainCard';

// 개별 대시보드 발전소 정보 표시 웹 - 발전소 상태 표시 컴포넌트
export default function EachPlantStateInfo({ page, basePlantInfo, invDailyDevelopment, roundedTotalPac }) {
  const CardWrapper = styled(MainCard)(({ theme }) => ({
    overflow: 'hidden',
    position: 'relative',
  }));

  const [openPlantsetting, setOpenPlantSetting] = useState(false);
  const [subscribeday, setSubscribeday] = useState(null);

  const closePlantSettingHandler = () => {
    setOpenPlantSetting(false);
  };

  const backendRequest = useBackendV2();

  const fetchSubscriptionPeriod = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.subscribe}/${page.plantkey}`
      );

      const filteredData = data.filter((item) => item.subscribeday > -90);

      if (filteredData.length > 0) {
        const maxSubdcribedayItem = filteredData.reduce((maxItem, currentItem) => {
          return currentItem.subscribeday > maxItem.subscribeday ? currentItem : maxItem;
        });

        setSubscribeday(maxSubdcribedayItem.subscribeday);
      } else {
        setSubscribeday(null);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  // 통신 중지된 RTU 개수
  const rtuStatusZeroCount = basePlantInfo[0]?.rtuinfos.filter((rtu) => rtu.rtustatus === 0).length;

  // 발전 중단된 인버터 개수
  const generateStatusZeroCount = invDailyDevelopment.filter(
    (inverter) => inverter.generatestatus === 0
  ).length;

  // 현재출력 계산
  const totalPac = invDailyDevelopment?.reduce((total, inverter) => {
    return total + (inverter.pac || 0);
  }, 0);

  useEffect(() => {
    if (page.plantkey !== '') {
      fetchSubscriptionPeriod();
    }
  }, [page]);

  return (
    <>
      <CardWrapper border={false} content={false} sx={{ p: 1 }}>
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ p: 1 }}>
          <Typography variant="subtitle1">현재 출력</Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{ width: '35%', bgcolor: '#fff', color: '#000', cursor: 'default' }}
          >
            <Typography variant="subtitle1">
              {parseFloat(totalPac?.toFixed(1))}
              <span style={{ fontSize: '90%' }}>kW</span>
            </Typography>
          </Button>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ p: 1 }}>
          <Typography variant="subtitle1">RTU 상태</Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{ width: '35%', bgcolor: '#fff', color: '#000', cursor: 'default' }}
          >
            <Typography variant="subtitle1">
              <Tooltip title="전체 RTU 수" arrow>
                <span>{basePlantInfo[0]?.rtuinfos?.length}</span>
              </Tooltip>
              &nbsp;/&nbsp;
              <Tooltip title="통신 중지 RTU 수" arrow>
                <span style={{ color: 'red' }}>{rtuStatusZeroCount}</span>
              </Tooltip>
            </Typography>
          </Button>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: 0.8, p: 1 }}>
          <Typography variant="subtitle1">인버터 상태</Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{ width: '35%', bgcolor: '#fff', color: '#000', cursor: 'default' }}
          >
            <Typography variant="subtitle1">
              <Tooltip title="전체 인버터 수" arrow>
                <span>{invDailyDevelopment.length}</span>
              </Tooltip>
              &nbsp;/&nbsp;
              <Tooltip title="발전 중지 인버터 수" arrow>
                <span style={{ color: 'red' }}>{generateStatusZeroCount}</span>
              </Tooltip>
            </Typography>
          </Button>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: 0.8, p: 1 }}>
          <Typography variant="subtitle1">발전 상태</Typography>
          <Button
            variant="outlined"
            size="small"
            sx={{
              width: '35%',
              backgroundColor: basePlantInfo.some((plant) =>
                plant.rtuinfos?.some((info) => info.rtustatus === 1)
              )
                ? invDailyDevelopment.some((inverter) => inverter.generatestatus === 1)
                  ? null
                  : 'red'
                : 'red',
              color: basePlantInfo.some((plant) => plant.rtuinfos?.some((info) => info.rtustatus === 1))
                ? invDailyDevelopment.some((inverter) => inverter.generatestatus === 1)
                  ? null
                  : 'white'
                : 'white',
              cursor: 'default',
            }}
          >
            {basePlantInfo.some((plant) => plant.rtuinfos?.some((info) => info.rtustatus === 1))
              ? invDailyDevelopment.some((inverter) => inverter.generatestatus === 1)
                ? '발전 중'
                : '발전 중단'
              : '발전 중단'}
          </Button>
        </Box>
      </CardWrapper>
      <PlantSettingModal
        plantkey={page.plantkey}
        plantName={page.plantName}
        open={openPlantsetting}
        onClose={closePlantSettingHandler}
      />
    </>
  );
}
