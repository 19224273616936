import { useTheme } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { Box, Container, Typography, useMediaQuery } from '@mui/material';
import SolLogo from '../../assets/EtcImg/sol_logo_login.png';
import PersonSearchOutlinedIcon from '@mui/icons-material/PersonSearchOutlined';
import { useState } from 'react';
import { validateName, validatePhone } from '../../utils/validationUtil';
import useInput from '../../hooks/use-input';
import FindIdForm from '../../components/Authentication/FindIdForm';
import FindIdList from '../../components/Authentication/FindIdList';
import InputCaptha from '../../components/Authentication/InputCaptcha';
import usePageLogRecord from '../../hooks/usePageLogRecord';

// 아이디 찾기 컴포넌트
export default function FineId() {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [resultInfo, setResultInfo] = useState([]);
  const [captchaAuthentication, setCaptchaAuthentication] = useState(false);

  const nameInput = useInput((value) => validateName(value));
  const phoneInput = useInput((value) => validatePhone(value));
  const captchaInput = useInput();

  const cancelJoin = () => {
    navigate('/login02');
  };

  const moveFindPwdHandler = () => {
    navigate('/findPwd');
  };

  usePageLogRecord('findIds');

  return (
    <Container
      sx={{
        minHeight: '600px',
        minWidth: '50%',
        marginBottom: '4%',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: 4,
        }}
      >
        <img src={SolLogo} alt="SOLARCUBE" style={{ width: 30 }} />
        &nbsp;
        <Typography
          color="inherit"
          sx={{
            display: 'flex',
            fontFamily: '"Segoe UI Symbol"',
            flexGrow: 1,
            fontWeight: 'bold',
            color: 'black',
          }}
        >
          SOLAR CUBE
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ width: { xs: '100%', md: 500 }, mt: isMobile ? 5 : 9 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <PersonSearchOutlinedIcon
              fontSize="large"
              sx={{
                mr: 1,
              }}
            />
            <Typography variant="h4" fontWeight="bold">
              아이디 찾기
            </Typography>
          </Box>
          <hr />
          {resultInfo.length === 0 ? (
            <FindIdForm
              nameInput={nameInput}
              phoneInput={phoneInput}
              setResultInfo={setResultInfo}
              cancelJoin={cancelJoin}
              isMobile={isMobile}
            />
          ) : captchaAuthentication === true ? (
            <FindIdList
              data={resultInfo}
              cancelJoin={cancelJoin}
              moveFindPwdHandler={moveFindPwdHandler}
              isMobile={isMobile}
            />
          ) : (
            <InputCaptha
              setCaptchaAuthentication={setCaptchaAuthentication}
              captchaInput={captchaInput}
              cancelJoin={cancelJoin}
              isMobile={isMobile}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
}
