import { Box, Card, Divider, Typography, useMediaQuery } from '@mui/material';
import pannelSvg02 from '../../../assets/PlantEletricalSystemImg/pannelSvg02.svg';
import inverterSvg01 from '../../../assets/PlantEletricalSystemImg/inverterSvg01.svg';
import acbImg02 from '../../../assets/PlantEletricalSystemImg/img_acb2.png';
import vcbImg02 from '../../../assets/PlantEletricalSystemImg/img_vcb2.png';
import transtowerSvg02 from '../../../assets/PlantEletricalSystemImg/transtowerSvg02.svg';
import ArrowIcon from '../../../theme/DashboardArrowIcon';

// 개별발전소 웹뷰 시스템 발전도 컴포넌트
export default function WebWidgetPlantEletricSystem({
  invDailyDevelopment,
  acbVcbDailyDevelopment,
  basePlantInfo,
}) {
  const isIpad = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const isNexthub = useMediaQuery('(min-width: 1025px) and (max-width: 1366px)');

  // invDailyDevelopment 각 합계를 저장할 변수 초기화
  let totalInputPower = 0;
  let totalOutputPower = 0;
  let totalInputVoltage = 0;
  let totalOutputVoltage = 0;
  let totalInputCurrent = 0;
  let totalOutputCurrent = 0;

  // invDailyDevelopment 배열을 순회하며 각 값들을 누적
  invDailyDevelopment.forEach((item) => {
    totalInputPower += item.pdc;
    totalOutputPower += item.pac;
    totalInputVoltage += item.udc;
    totalOutputVoltage += item.uac;
    totalInputCurrent += item.idc;
    totalOutputCurrent += item.iac;
  });

  // 입력전압, 출력전압 계산
  const avgInputVoltage = totalInputVoltage / invDailyDevelopment.length;
  const avgOutputVoltage = totalOutputVoltage / invDailyDevelopment.length;

  // ACB 및 VCB에 대한 전력의 합계 계산
  const totalAcbPower = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'ACB')
    .reduce((accumulator, item) => accumulator + item.actp * 0.001, 0);

  const totalVcbPower = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'VCB')
    .reduce((accumulator, item) => accumulator + item.actp * 0.001, 0);

  // ACB 및 VCB에 대한 전압의 합계 계산
  const totalAcbVoltage = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'ACB')
    .reduce((accumulator, item) => accumulator + item.ru + item.su + item.tu, 0);

  const totalVcbVoltage = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'VCB')
    .reduce((accumulator, item) => accumulator + item.ru + item.su + item.tu, 0);

  // ACB 및 VCB에 대한 평균 전압 계산
  const averageAcbVoltage =
    totalAcbVoltage / (acbVcbDailyDevelopment.filter((item) => item.acbvcbtype === 'ACB').length * 3);
  const averageVcbVoltage =
    totalVcbVoltage / (acbVcbDailyDevelopment.filter((item) => item.acbvcbtype === 'VCB').length * 3);

  // ACB 및 VCB에 대한 전류의 합계 계산
  const totalAcbCurrent = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'ACB')
    .reduce((accumulator, item) => accumulator + item.ri + item.si + item.ti, 0);

  const totalVcbCurrent = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'VCB')
    .reduce((accumulator, item) => accumulator + item.ri + item.si + item.ti, 0);

  // RTU 수신상태
  const isRtuStatusOne = basePlantInfo.some((plant) => plant.rtuinfos?.some((info) => info.rtustatus === 1));

  // 인버터 발전상태
  const isAnyInverterGenerating = invDailyDevelopment.some((inverter) => inverter.generatestatus === 1);

  // ACB 발전 상태
  const isAllAcbNodataOne = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'ACB')
    .every((item) => item.nodata === '1');

  const isAnyAcbNodataZero = acbVcbDailyDevelopment.some(
    (item) => item.acbvcbtype === 'ACB' && item.nodata === '0'
  );

  // VCB 발전 상태
  const isAllVcbNodataOne = acbVcbDailyDevelopment
    .filter((item) => item.acbvcbtype === 'VCB')
    .every((item) => item.nodata === '1');

  const isAnyVcbNodataZero = acbVcbDailyDevelopment.some(
    (item) => item.acbvcbtype === 'VCB' && item.nodata === '0'
  );

  return (
    <>
      <Card
        sx={{
          // display: 'flex',
          borderColor: '#fff',
          borderRadius: '16px',
          boxShadow: '5px 6px 12px 5px #00000014',
          position: 'relative',
          height: 210,
        }}
      >
        {acbVcbDailyDevelopment.length === 0 ? (
          <>
            <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 0.5 }}>
              <img
                src={pannelSvg02}
                style={{ width: '110px', height: '150px', marginLeft: isIpad || isNexthub ? 1 : 1 }}
              />
              <Box sx={{ ml: isIpad || isNexthub ? 1 : 0 }}>
                <Card
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bgcolor: '#000',
                    p: 0.5,
                    mt: 1.5,
                    mb: 0.5,
                  }}
                >
                  <Typography variant="subtitle1" sx={{ color: 'orange' }}>
                    입력 전력 :
                  </Typography>
                  <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                    <Typography variant="h5" sx={{ color: 'gold' }}>
                      {isRtuStatusOne
                        ? isAnyInverterGenerating
                          ? totalInputPower.toFixed(1)
                          : '--,--'
                        : '--,--'}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: 'orange', ml: 0.5, mt: 0.5 }}>
                      kW
                    </Typography>
                  </Box>
                </Card>
                <ArrowIcon
                  color={isRtuStatusOne ? (isAnyInverterGenerating ? '#FF5722' : '#333') : '#333'}
                  acbvcb="none"
                />
                <Card sx={{ bgcolor: '#e0e0e0', p: 0.5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">입력 전압 :</Typography>
                    <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                      <Typography variant="h5">
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? avgInputVoltage.toFixed(1)
                            : '--,--'
                          : '--,--'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ ml: 0.5, mt: 0.5 }}>
                        Vdc
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ bgcolor: 'black' }} />
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">입력 전류 :</Typography>
                    <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                      <Typography variant="h5">
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? totalInputCurrent.toFixed(1)
                            : '--,--'
                          : '--,--'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ ml: 0.5, mt: 0.5 }}>
                        A
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
              <img
                src={inverterSvg01}
                style={{ width: '100px', height: '130px', marginLeft: isIpad || isNexthub ? 1 : 5 }}
              />
              <Box sx={{ ml: isIpad || isNexthub ? 0 : 0 }}>
                <Card
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bgcolor: '#000',
                    p: 0.5,
                    mt: 1.5,
                    mb: 0.5,
                  }}
                >
                  <Typography variant="subtitle1" sx={{ color: '#B1CB99' }}>
                    출력 전력 :
                  </Typography>
                  <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                    <Typography variant="h5" sx={{ color: '#83DE69' }}>
                      {isRtuStatusOne
                        ? isAnyInverterGenerating
                          ? totalOutputPower.toFixed(1)
                          : '--,--'
                        : '--,--'}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: '#B1CB99', ml: 0.5, mt: 0.5 }}>
                      kW
                    </Typography>
                  </Box>
                </Card>
                <ArrowIcon
                  color={isRtuStatusOne ? (isAnyInverterGenerating ? '#4F8C14' : '#333') : '#333'}
                  acbvcb="none"
                />
                <Card sx={{ bgcolor: '#e0e0e0', p: 0.5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">출력 전압 :</Typography>
                    <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                      <Typography variant="h5">
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? avgOutputVoltage.toFixed(1)
                            : '--,--'
                          : '--,--'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ ml: 0.5, mt: 0.5 }}>
                        Vac
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ bgcolor: 'black' }} />
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">출력 전류 :</Typography>
                    <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                      <Typography variant="h5">
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? totalOutputCurrent.toFixed(1)
                            : '--,--'
                          : '--,--'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ ml: 0.5, mt: 0.5 }}>
                        A
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
              <img
                src={transtowerSvg02}
                style={{ width: '100px', height: '110px', marginLeft: isIpad || isNexthub ? 0 : 8 }}
              />
            </Box>
          </>
        ) : (
          <>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ mt: 1.5, overflowX: 'auto', width: '100%' }}
            >
              <img
                src={pannelSvg02}
                style={{ width: '90px', height: '150px', marginLeft: isIpad || isNexthub ? 180 : 0 }}
              />
              <Box sx={{ ml: isIpad || isNexthub ? 0 : 0, mr: isIpad || isNexthub ? 0 : 0 }}>
                <Card
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bgcolor: '#000',
                    p: 0.5,
                    mt: 1.5,
                    width: 155,
                    mb: 0.5,
                  }}
                >
                  <Typography variant="subtitle1" sx={{ color: 'orange' }}>
                    전력 :
                  </Typography>
                  <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                    <Typography variant="h6" sx={{ color: 'gold' }}>
                      {isRtuStatusOne
                        ? isAnyInverterGenerating
                          ? totalInputPower.toFixed(1)
                          : '--,--'
                        : '--,--'}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: 'orange', ml: 0.5, mt: 0.5 }}>
                      kW
                    </Typography>
                  </Box>
                </Card>
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" sx={{ color: '#FF5722' }}>
                    입력
                  </Typography>
                  <ArrowIcon
                    color={isRtuStatusOne ? (isAnyInverterGenerating ? '#FF5722' : '#333') : '#333'}
                    acbvcb="use"
                  />
                </Box>
                <Card sx={{ bgcolor: '#e0e0e0', p: 0.5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">전압 :</Typography>
                    <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                      <Typography variant="h6">
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? avgInputVoltage.toFixed(1)
                            : '--,--'
                          : '--,--'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ ml: 0.5, mt: 0.5 }}>
                        Vdc
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ bgcolor: 'black' }} />
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">전류 :</Typography>
                    <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                      <Typography variant="h6">
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? totalInputCurrent.toFixed(1)
                            : '--,--'
                          : '--,--'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ ml: 0.5, mt: 0.5 }}>
                        A
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
              <img
                src={inverterSvg01}
                style={{ width: '90px', height: '130px', marginLeft: isIpad || isNexthub ? 0 : 0 }}
              />
              <Box sx={{ ml: isIpad || isNexthub ? 0 : 0, mr: isIpad || isNexthub ? 0 : 0 }}>
                <Card
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bgcolor: '#000',
                    p: 0.5,
                    mt: 1.5,
                    width: 155,
                    mb: 0.5,
                  }}
                >
                  <Typography variant="subtitle1" sx={{ color: 'orange' }}>
                    전력 :
                  </Typography>
                  <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                    <Typography variant="h6" sx={{ color: 'gold' }}>
                      {isRtuStatusOne
                        ? isAnyInverterGenerating
                          ? totalOutputPower.toFixed(1)
                          : '--,--'
                        : '--,--'}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: 'orange', ml: 0.5, mt: 0.5 }}>
                      kW
                    </Typography>
                  </Box>
                </Card>
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" sx={{ color: '#FF5722' }}>
                    출력
                  </Typography>
                  <ArrowIcon
                    color={isRtuStatusOne ? (isAnyInverterGenerating ? '#FF5722' : '#333') : '#333'}
                    acbvcb="use"
                  />
                </Box>
                <Card sx={{ bgcolor: '#e0e0e0', p: 0.5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">전압 :</Typography>
                    <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                      <Typography variant="h6">
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? avgOutputVoltage.toFixed(1)
                            : '--,--'
                          : '--,--'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ ml: 0.5, mt: 0.5 }}>
                        Vac
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ bgcolor: 'black' }} />
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">전류 :</Typography>
                    <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                      <Typography variant="h6">
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? totalOutputCurrent.toFixed(1)
                            : '--,--'
                          : '--,--'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ ml: 0.5, mt: 0.5 }}>
                        A
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
              <img
                src={acbImg02}
                style={{ width: '70px', height: '50px', marginLeft: isIpad || isNexthub ? 1 : 6 }}
              />
              <Box sx={{ ml: isIpad || isNexthub ? 1 : 1, mr: isIpad || isNexthub ? 1 : 1 }}>
                <Card
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bgcolor: '#000',
                    p: 0.5,
                    mt: 1.5,
                    width: 155,
                    mb: 0.5,
                  }}
                >
                  <Typography variant="subtitle1" sx={{ color: '#B1CB99' }}>
                    전력 :
                  </Typography>
                  <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                    <Typography variant="h6" sx={{ color: '#83DE69' }}>
                      {isRtuStatusOne
                        ? isAnyInverterGenerating
                          ? isAllAcbNodataOne
                            ? '--,--'
                            : isAnyAcbNodataZero
                            ? totalAcbPower.toFixed(1)
                            : '--,--'
                          : '--,--'
                        : '--,--'}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: '#B1CB99', ml: 0.5, mt: 0.5 }}>
                      kW
                    </Typography>
                  </Box>
                </Card>
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" sx={{ color: '#538F43' }}>
                    ACB
                  </Typography>
                  <ArrowIcon
                    color={
                      isRtuStatusOne
                        ? isAnyInverterGenerating
                          ? isAllAcbNodataOne
                            ? '#333'
                            : isAnyAcbNodataZero
                            ? '#538F43'
                            : '#333'
                          : '#333'
                        : '#333'
                    }
                    acbvcb="use"
                  />
                </Box>
                <Card sx={{ bgcolor: '#e0e0e0', p: 0.5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">전압 :</Typography>
                    <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                      <Typography variant="h6">
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? isAllAcbNodataOne
                              ? '--,--'
                              : isAnyAcbNodataZero
                              ? averageAcbVoltage.toFixed(1)
                              : '--,--'
                            : '--,--'
                          : '--,--'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ ml: 0.5, mt: 0.5 }}>
                        Vac
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ bgcolor: 'black' }} />
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">전류 :</Typography>
                    <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                      <Typography variant="h6">
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? isAllAcbNodataOne
                              ? '--,--'
                              : isAnyAcbNodataZero
                              ? totalAcbCurrent.toFixed(1)
                              : '--,--'
                            : '--,--'
                          : '--,--'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ ml: 0.5, mt: 0.5 }}>
                        A
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
              <img
                src={vcbImg02}
                style={{ width: '70px', height: '50px', marginLeft: isIpad || isNexthub ? 1 : 1 }}
              />
              <Box sx={{ ml: isIpad || isNexthub ? 1 : 1, mr: isIpad || isNexthub ? 1 : 1 }}>
                <Card
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    bgcolor: '#000',
                    p: 0.5,
                    mt: 1.5,
                    width: 155,
                    mb: 0.5,
                  }}
                >
                  <Typography variant="subtitle1" sx={{ color: '#B1CB99' }}>
                    전력 :
                  </Typography>
                  <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                    <Typography variant="h6" sx={{ color: '#83DE69' }}>
                      {isRtuStatusOne
                        ? isAnyInverterGenerating
                          ? isAllVcbNodataOne
                            ? '--,--'
                            : isAnyVcbNodataZero
                            ? totalVcbPower.toFixed(1)
                            : '--,--'
                          : '--,--'
                        : '--,--'}
                    </Typography>
                    <Typography variant="subtitle2" sx={{ color: '#B1CB99', ml: 0.5, mt: 0.5 }}>
                      kW
                    </Typography>
                  </Box>
                </Card>
                <Box display="flex" alignItems="center">
                  <Typography variant="h6" sx={{ color: '#538F43' }}>
                    VCB
                  </Typography>
                  <ArrowIcon
                    color={
                      isRtuStatusOne
                        ? isAnyInverterGenerating
                          ? isAllVcbNodataOne
                            ? '#333'
                            : isAnyVcbNodataZero
                            ? '#538F43'
                            : '#333'
                          : '#333'
                        : '#333'
                    }
                    acbvcb="use"
                  />
                </Box>
                <Card sx={{ bgcolor: '#e0e0e0', p: 0.5 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">전압 :</Typography>
                    <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                      <Typography variant="h6">
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? isAllVcbNodataOne
                              ? '--,--'
                              : isAnyVcbNodataZero
                              ? averageVcbVoltage.toFixed(1)
                              : '--,--'
                            : '--,--'
                          : '--,--'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ ml: 0.5, mt: 0.5 }}>
                        Vac
                      </Typography>
                    </Box>
                  </Box>
                  <Divider sx={{ bgcolor: 'black' }} />
                  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="subtitle1">전류 :</Typography>
                    <Box display="flex" alignItems="center" sx={{ ml: 1.5 }}>
                      <Typography variant="h6">
                        {isRtuStatusOne
                          ? isAnyInverterGenerating
                            ? isAllVcbNodataOne
                              ? '--,--'
                              : isAnyVcbNodataZero
                              ? totalVcbCurrent.toFixed(1)
                              : '--,--'
                            : '--,--'
                          : '--,--'}
                      </Typography>
                      <Typography variant="subtitle1" sx={{ ml: 0.5, mt: 0.5 }}>
                        A
                      </Typography>
                    </Box>
                  </Box>
                </Card>
              </Box>
              <img
                src={transtowerSvg02}
                style={{ width: '70px', height: '100px', marginLeft: isIpad || isNexthub ? 0 : 0 }}
              />
            </Box>
          </>
        )}
      </Card>
    </>
  );
}
