import { Box, Breadcrumbs, Button, Card, Container, Typography } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import useInput from '../../hooks/use-input';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import { useContext, useEffect, useState } from 'react';
import AddPlantInverterTable from '../../components/Plant/AddPlantPageComponent/AddPlantInvterTable';
import AuthContext from '../../store/auth-context';
import SearchRtuNumber from '../../components/Plant/AddPlantPageComponent/SearchRtuNumber';
import SelectPlantList from '../../components/Plant/AddPlantPageComponent/SelectPlantList';
import AddPlantModal from '../../components/Plant/AddPlantPageComponent/AddPlantModal';
import { useLocation } from 'react-router-dom';
import DomainAddIcon from '@mui/icons-material/DomainAdd';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header02 from '../../components/Layout/Header02';
import SubHeader from '../../components/Layout/SubHeader';
import Nav from '../../components/Layout/Nav';
import usePageLogRecord from '../../hooks/usePageLogRecord';

// 발전소 및 RTU 등록 페이지 컴포넌트
export default function AddPlantPage() {
  const isDesktop = useResponsive('up', 'lg');
  const authCtx = useContext(AuthContext);

  const location = useLocation();

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));

  const distance = 0;

  const pageConditionInitialState = {
    plantkey: '',
    pname: '',
    subplantkey: '',
  };
  const [page, setPage] = useState(pageConditionInitialState);
  const [haveRtuNumber, setHaveRtuNumber] = useState(false);
  const [haveRtuMacAdds, setHaveRtuMacAdds] = useState([]);
  const [selectPlant, setSelectPlant] = useState([]);
  const [ivtList, setIvtList] = useState([]);
  const [addPlantModal, setAddPlantModal] = useState(false);
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const RtuNumberInput = useInput((value) => value !== '');

  const backendRequest = useBackendV2();

  const findRtuNumberClickHandler = async () => {
    if (RtuNumberInput.value === '' || RtuNumberInput.value === null) {
      alert('RTU 번호를 확인해주세요.');
      return;
    }

    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.plants.findRtunMac}/${RtuNumberInput.value}`
      );

      if (data.length !== 0) {
        setHaveRtuNumber(true);
        setHaveRtuMacAdds(data);
      } else {
        setHaveRtuNumber(false);
        setHaveRtuMacAdds([]);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchUserPlants = async () => {
    try {
      const { data } = await backendRequest.sendRequest(`${config.backend.endPoint.plants.findPlantUser}`);
      setSelectPlant(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchInvterLists = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.inverters.ivtByRTU}/${RtuNumberInput.value}/${page.plantkey}`
      );
      // setIvtList(data);
      setIvtList(data.map((item) => ({ ...item, id: item.serialnum + item.type })));
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const selectPlantChangeHandler = (plant) => {
    setPage((prev) => {
      return { ...prev, plantkey: plant.plantkey, pname: plant.pname, subplantkey: plant.subplantkey };
    });
  };

  const addPlantRtuClickHandler = async () => {
    if (RtuNumberInput.value === '' || RtuNumberInput.value === null || page.plantkey === '') {
      alert('RTU 번호 혹은 발전소 목록을 확인해주세요.');
      return;
    }

    const initialNewIvtList = ivtList.filter((row) => row.nud === 'U');

    const convertedIvtList = initialNewIvtList.map((ivt) => {
      return {
        Serialnum: ivt.serialnum === '' ? null : ivt.serialnum,
        Invertername: ivt.invertername,
        ivtcapacity: Number(ivt.ivtcapacity),
        ivtorder: Number(ivt.ivtorder),
      };
    });

    try {
      const saveCheckIvtListRequestConfig = {
        method: 'POST',
        body: convertedIvtList,
      };
      const saveCheckIvtListResult = await backendRequest.sendRequest(
        `${config.backend.endPoint.inverters.ivtCUD}/${page.plantkey}/${
          page.subplantkey
        }/${RtuNumberInput.value.replace(/\s/g, '').trim().toString()}/${authCtx.currentUser.id}`,
        saveCheckIvtListRequestConfig
      );

      if (saveCheckIvtListResult.data !== (0 || null)) {
        alert('성공적으로 저장했습니다. 추가적인 등록을 위해 관리자에게 문의 바랍니다. 문의전화: 1566-6403');
      } else {
        alert('오류가 발생하였습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const openAddPlantModalHandler = () => {
    setAddPlantModal(true);
  };
  const closeAddPlantModalHandler = () => {
    setAddPlantModal(false);
  };

  useEffect(() => {
    fetchUserPlants();
  }, [haveRtuNumber, addPlantModal]);

  useEffect(() => {
    fetchUserPlants();

    if (haveRtuNumber === true && page.plantkey !== '') {
      fetchInvterLists();
    }
  }, [haveRtuNumber, page, addPlantModal]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const rtuNum = searchParams.get('rtuNum');

    if (rtuNum) {
      RtuNumberInput.setValue(rtuNum);
    }
  }, [location.search]);

  useEffect(() => {
    fetchUserStylePreference();
  }, []);

  // 모바일 반응형 디자인을 위한 상수 선언
  const isSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 368px)');

  usePageLogRecord('addPlantsRtus');

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        plantkey={page.plantkey}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      {isDesktop ? (
        <Container
          sx={{
            width: '100%',
            marginBottom: '4%',
          }}
        >
          <Box>
            <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
              <StyledBreadcrumb
                label="발전소 및 RTU 등록"
                icon={<DomainAddIcon fontSize="small" color="primary" />}
              />
            </Breadcrumbs>
            <Card
              sx={{
                p: 3,
                mt: 2.5,
                borderRadius: '11px',
                boxShadow: '0px 3px 12px #00000014',
              }}
            >
              <Box display="flex" justifyContent="end">
                <Typography variant="subtitle1">
                  <span style={{ color: 'red' }}>*</span> 는 필수입력 사항입니다.
                </Typography>
              </Box>
              <Box display="flex" alignItems="center" sx={{ mt: 2.5 }}>
                <Typography variant="subtitle1" sx={{ mr: 5 }}>
                  RTU 번호*
                </Typography>
                <SearchRtuNumber
                  RtuNumberInput={RtuNumberInput}
                  haveRtuNumber={haveRtuNumber}
                  findRtuNumberClickHandler={findRtuNumberClickHandler}
                />
              </Box>
              <Box sx={{ mt: 1, ml: 1 }}>
                {haveRtuMacAdds.map((data, index) => (
                  <>
                    <Box key={index} display="flex">
                      <Typography variant="subtitle1" sx={{ mt: 0.5 }}>
                        MacAddress : {data.macadds}
                      </Typography>
                    </Box>
                  </>
                ))}
              </Box>
              <Box display="flex" alignItems="center" sx={{ mt: 3 }}>
                <Typography variant="subtitle1" sx={{ mr: 3 }}>
                  발전소 목록*
                </Typography>
                <SelectPlantList
                  selectPlant={selectPlant}
                  haveRtuNumber={haveRtuNumber}
                  selectPlantChangeHandler={selectPlantChangeHandler}
                  openAddPlantModalHandler={openAddPlantModalHandler}
                />
              </Box>
              <Box sx={{ mt: 6 }}>
                <Typography variant="subtitle1">인버터 선택</Typography>
                <AddPlantInverterTable
                  ivtList={ivtList}
                  setIvtList={setIvtList}
                  page={page}
                  selectPlant={selectPlant}
                />
              </Box>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={addPlantRtuClickHandler}
                  sx={{ width: '250px' }}
                >
                  등록
                </Button>
              </Box>
            </Card>
          </Box>
        </Container>
      ) : (
        <Container
          sx={{
            width: '100%',
            marginBottom: '4%',
          }}
        >
          <Box>
            <Box
              sx={{
                display: {
                  xs: 'none',
                  sm: 'flex',
                },
              }}
            >
              <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
                <StyledBreadcrumb
                  label="발전소 및 RTU 등록"
                  icon={<DomainAddIcon fontSize="small" color="primary" />}
                />
              </Breadcrumbs>
            </Box>
            <Card
              sx={{
                p: isSmallDevice ? 1 : 3,
                mt: 2.5,
                borderRadius: '11px',
                boxShadow: '0px 3px 12px #00000014',
              }}
            >
              <Box display="flex" justifyContent={isSmallDevice ? 'left' : 'end'}>
                <Typography variant="subtitle1" sx={{ fontSize: isSmallDevice ? 16 : null }}>
                  <span style={{ color: 'red' }}>*</span> 는 필수입력 사항입니다.
                </Typography>
              </Box>
              <Box sx={{ mt: 2.5 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, mr: 3 }}>
                  RTU 번호*
                </Typography>
                <SearchRtuNumber
                  RtuNumberInput={RtuNumberInput}
                  haveRtuNumber={haveRtuNumber}
                  findRtuNumberClickHandler={findRtuNumberClickHandler}
                />
              </Box>
              <Box sx={{ mt: 1, ml: 1 }}>
                {haveRtuMacAdds.map((data, index) => (
                  <>
                    <Box key={index} sx={{ mt: 0.5 }}>
                      <Typography variant="subtitle1">MacAddress : {data.macadds}</Typography>
                    </Box>
                  </>
                ))}
              </Box>
              <Box sx={{ mt: 2.5 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, mr: 5 }}>
                  발전소 목록*
                </Typography>
                <SelectPlantList
                  selectPlant={selectPlant}
                  haveRtuNumber={haveRtuNumber}
                  selectPlantChangeHandler={selectPlantChangeHandler}
                  openAddPlantModalHandler={openAddPlantModalHandler}
                />
              </Box>
              <Box sx={{ mt: 2.5 }}>
                <Typography variant="subtitle1" sx={{ mb: 1, mt: 3, mr: 5 }}>
                  인버터 선택
                </Typography>
                <AddPlantInverterTable
                  ivtList={ivtList}
                  setIvtList={setIvtList}
                  page={page}
                  selectPlant={selectPlant}
                />
              </Box>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'center' }}>
                <Button
                  variant="contained"
                  size="large"
                  onClick={addPlantRtuClickHandler}
                  sx={{ width: '250px' }}
                >
                  등록
                </Button>
              </Box>
            </Card>
          </Box>
        </Container>
      )}
      {addPlantModal && <AddPlantModal open={addPlantModal} onClose={closeAddPlantModalHandler} />}
    </>
  );
}
