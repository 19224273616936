import { Box, Button, TextField, Typography, useMediaQuery } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import useResponsive from '../../hooks/useResponsive';
import config from '../../config';
import useBackendV2 from '../../hooks/use-backend-v2';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

// 회원 가입 내 기업 회원 등록 요청 회사 조회 모달 컴포넌트
export default function RequestTaxBillSearchModal({
  companyNameInput,
  businessNoInput,
  companyPhoneInput,
  setCompanyKey,
  open,
  onClose,
}) {
  const isDesktop = useResponsive('up', 'lg');
  const isIpad = useMediaQuery('(min-width: 768px) and (max-width: 1024px)');
  const isNexthub = useMediaQuery('(min-width: 1025px) and (max-width: 1366px)');

  const [searchRadioValue, setSearchRadioValue] = useState('BusinessNumber');
  const [searchKeyword, setSearchKeyword] = useState('');

  const changeSearchHandler = (event) => {
    setSearchRadioValue(event.target.value);
  };

  const searchKeywordChangeHandler = (event) => {
    setSearchKeyword(event.target.value);
  };

  const backendRequest = useBackendV2();

  const clickSearchCompanyHandler = async () => {
    if (searchKeyword === '') {
      alert('검색어를 입력해주세요.');
      return;
    }

    try {
      const { data } = await backendRequest.sendRequest(
        `${config.backend.endPoint.companies.search}/${searchRadioValue}/${searchKeyword}`,
        null,
        false
      );

      setCompanyKey(data.companyKey);
      companyNameInput.setValue(data.companyName);
      businessNoInput.setValue(data.businessNumber);
      companyPhoneInput.setValue(data.tel);
    } catch (error) {
      console.error(error);
      alert('입력하신 사업자 등록 번호 또는 회사 이름을 확인해주세요.');
    }
  };

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <CustomModal
      open={open}
      onClose={closeModalHandler}
      sx={{ maxHeight: '95%', width: { xs: '90%', md: 600 } }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon onClick={onClose} sx={{ cursor: 'pointer' }} />
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ mt: 1 }}>
        <SearchOutlinedIcon sx={{ mr: 1 }} />
        <Typography variant="h6">회사 조회</Typography>
      </Box>
      <hr />
      <br />
      <Box>
        <Typography variant="subtitle1">사업자 등록 번호</Typography>
        {/* <FormControl>
          <RadioGroup value={searchRadioValue} onChange={changeSearchHandler} row>
            <FormControlLabel value="BusinessNumber" control={<Radio />} label="사업자 번호" />
            <FormControlLabel value="CompanyName" control={<Radio />} label="회사 이름" />
          </RadioGroup>
        </FormControl> */}
      </Box>
      <Box display="flex" sx={{ mt: 0 }}>
        <TextField variant="standard" onChange={searchKeywordChangeHandler} sx={{ width: '90%' }} />
        <Button variant="text" onClick={clickSearchCompanyHandler} sx={{ width: '10%' }}>
          조회
        </Button>
      </Box>
      <Box align="center" sx={{ mt: 4 }}>
        <TextField
          label="회사 이름"
          variant="outlined"
          value={companyNameInput.value ?? ''}
          disabled
          sx={{ width: '100%' }}
        />
      </Box>
      <Box align="center" sx={{ mt: 1.5 }}>
        <TextField
          label="사업자 등록 번호"
          variant="outlined"
          value={businessNoInput.value ?? ''}
          disabled
          sx={{ width: '100%' }}
        />
      </Box>
      <Box align="center" sx={{ mt: 1.5 }}>
        <TextField
          label="대표전화"
          variant="outlined"
          value={companyPhoneInput.value ?? ''}
          disabled
          sx={{ width: '100%' }}
        />
      </Box>
      <Box display="flex" justifyContent="center" sx={{ mt: 3 }}>
        <Button
          variant="contained"
          onClick={onClose}
          disabled={
            companyNameInput.value === '' || businessNoInput.value === '' || companyPhoneInput.value === ''
          }
          sx={{ width: isDesktop || isIpad || isNexthub ? '50%' : '100%' }}
        >
          선택
        </Button>
      </Box>
    </CustomModal>
  );
}
