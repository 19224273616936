// 사용자 권한 정의

const userRole = {
  superAdmin: 'SUPERADMIN',
  admin: '사이트 관리자',
  user: '소유주',
  safetyManager: '안전관리자',
  specialCompanyManager: '전문기업관리자',
  inverterDisitributionManager: '인버터유통관리자',
};

export default userRole;
