import { Button } from '@mui/material';
import { useCookies } from 'react-cookie';
import { useNavigate, useSearchParams } from 'react-router-dom';
import '../../css/PaymentStyle.css';

// 결제 실패 및 안내 페이지 컴포넌트
export default function PaymentPageFail() {
  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(['orderid']);

  const [searchParams] = useSearchParams();
  const errorCode = searchParams.get('code');
  const errorMessage = searchParams.get('message');

  const failPayment = () => {
    removeCookie('orderid');
    localStorage.setItem('menuValue', 0);
    localStorage.setItem('menuSubValue', 0);
    localStorage.removeItem('address');
    localStorage.removeItem('detailAddress');
    localStorage.removeItem('zip');
    localStorage.removeItem('userName');
    localStorage.removeItem('phoneNumber');
    localStorage.removeItem('price');
    localStorage.removeItem('pdtname');
    localStorage.removeItem('plantkey');
    localStorage.removeItem('@payment-widget/previous-payment-method-id');
    localStorage.removeItem('@tosspayments/client-id');
    removeCookie('orderid');
    navigate('/', { state: { subValue: 0 } });
  };

  return (
    <>
      <div className="wrapper w-100">
        <div className="flex-column align-center w-100 max-w-540">
          <img src="https://static.toss.im/lotties/error-spot-apng.png" width="120" height="120" />
          <h2 className="title">결제를 실패했어요</h2>
          <div className="response-section w-100">
            <div className="flex justify-between">
              <span className="response-label">code</span>
              <span id="error-code" className="response-text">
                {errorCode}
              </span>
            </div>
            <div className="flex justify-between">
              <span className="response-label">message</span>
              <span id="error-message" className="response-text">
                {errorMessage}
              </span>
            </div>
          </div>

          <div className="w-100 button-group"></div>
          <br />
          <Button onClick={failPayment} size="large" variant="contained">
            홈으로
          </Button>
        </div>
      </div>
    </>
  );
}
