import { Box, Grid } from '@mui/material';
import EachPlantInfoCardMobile_DNE from './EachPlant/EachPlantInfoCardMobile_DNE';
import TodayInvertersTable from './EachPlant/TodayInvertersTable';
import TodayVcbAcbTable from './EachPlant/TodayVcbAcbTable';

// DNE 형식의 모바일 뷰 컴포넌트
export default function EachPlantMobileView_DNE({
  subscribeday,
  graceperiodDay,
  page,
  nowTime,
  todayEnvData,
  basePlantInfo,
  invDailyDevelopment,
  roundedTotalGhgReduction,
  roundedTotalPineTree,
  roundedTotalDailyYield,
  sumTotalYield,
  roundedTotalDailyDevelopmentTime,
  roundedTotalMonthyYield,
  acbVcbDailyDevelopment,
  isDesktop,
}) {
  return (
    <>
      {subscribeday - graceperiodDay > 0 ? (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12} sx={{ filter: 'blur(10px)', mt: 1.5 }}>
            <EachPlantInfoCardMobile_DNE
              page={page}
              nowTime={nowTime}
              roundedTotalDailyYield={roundedTotalDailyYield}
              sumTotalYield={sumTotalYield}
              roundedTotalDailyDevelopmentTime={roundedTotalDailyDevelopmentTime}
              roundedTotalMonthyYield={roundedTotalMonthyYield}
              todayEnvData={todayEnvData}
              basePlantInfo={basePlantInfo}
              invDailyDevelopment={invDailyDevelopment}
              roundedTotalGhgReduction={roundedTotalGhgReduction}
              roundedTotalPineTree={roundedTotalPineTree}
              isDesktop={isDesktop}
            />
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <EachPlantInfoCardMobile_DNE
              page={page}
              nowTime={nowTime}
              roundedTotalDailyYield={roundedTotalDailyYield}
              sumTotalYield={sumTotalYield}
              roundedTotalDailyDevelopmentTime={roundedTotalDailyDevelopmentTime}
              roundedTotalMonthyYield={roundedTotalMonthyYield}
              todayEnvData={todayEnvData}
              basePlantInfo={basePlantInfo}
              invDailyDevelopment={invDailyDevelopment}
              roundedTotalGhgReduction={roundedTotalGhgReduction}
              roundedTotalPineTree={roundedTotalPineTree}
              isDesktop={isDesktop}
            />
          </Grid>
        </Grid>
      )}
      <Box sx={{ mt: 1 }}>
        {subscribeday - graceperiodDay > 0 ? (
          <div style={{ filter: 'blur(5px)' }}>
            <Grid container spacing={2}>
              <Grid item lg={acbVcbDailyDevelopment.length === 0 ? 6 : 4} md={12} sm={12} xs={12}>
                <TodayInvertersTable invDailyDevelopment={invDailyDevelopment} isDesktop={isDesktop} />
              </Grid>
              {acbVcbDailyDevelopment.length === 0 ? null : (
                <Grid item lg={4} md={12} sm={12} xs={12}>
                  <TodayVcbAcbTable acbVcbDailyDevelopment={acbVcbDailyDevelopment} isDesktop={isDesktop} />
                </Grid>
              )}
            </Grid>
          </div>
        ) : (
          <Grid container spacing={2}>
            <Grid item lg={acbVcbDailyDevelopment.length === 0 ? 6 : 4} md={12} sm={12} xs={12}>
              <TodayInvertersTable invDailyDevelopment={invDailyDevelopment} isDesktop={isDesktop} />
            </Grid>
            {acbVcbDailyDevelopment.length === 0 ? null : (
              <Grid item lg={4} md={12} sm={12} xs={12}>
                <TodayVcbAcbTable acbVcbDailyDevelopment={acbVcbDailyDevelopment} isDesktop={isDesktop} />
              </Grid>
            )}
          </Grid>
        )}
      </Box>
    </>
  );
}
