import { Box, Card, CircularProgress } from '@mui/material';
import { useState } from 'react';
import InvPlantCard from './InverterList/InvPlantCard';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

// 인버터 리스트 구성 컴포넌트
export default function InverterList({
  isDesktop,
  page,
  plant,
  hideExpanded,
  visibleItems,
  setVisibleItems,
  subscribeday,
  graceperiodDay,
}) {
  const [expanded, setExpanded] = useState(false);

  const loadMoreItems = () => {
    if (!expanded) {
      setExpanded(true);
      const newVisibleItems = visibleItems + 5;
      if (newVisibleItems <= plant.length) {
        setTimeout(() => {
          setVisibleItems(newVisibleItems);
          setExpanded(false);
        }, 1000);
      } else if (visibleItems < plant.length) {
        setTimeout(() => {
          setVisibleItems(plant.length);
          setExpanded(false);
        }, 1000);
      } else {
        setExpanded(false);
      }
    }
  };

  const handleExpandClick = () => {
    expanded ? alert('로드 중입니다.') : loadMoreItems();
  };

  const visiblePlants = plant === undefined ? null : plant.slice(0, visibleItems);
  let entirePlantInfoCard;

  if (plant != null) {
    if (page.plantkey) {
      const selectedPlant = plant.find((d) => d.plantkey === page.plantkey);
      entirePlantInfoCard = selectedPlant ? (
        <InvPlantCard
          key={selectedPlant.plantkey}
          plantkey={selectedPlant.plantkey}
          plantName={selectedPlant.plantname}
          address={selectedPlant.address}
          nodata={selectedPlant.nodata}
          ivtNodata={selectedPlant.ivtNodata}
          subscribeday={subscribeday}
          graceperiodDay={graceperiodDay}
          isDesktop={isDesktop}
        />
      ) : null;
    } else {
      // entirePlantInfoCard = visiblePlants.map((d) => (
      //   <InvPlantCard
      //     key={d.plantkey}
      //     plantkey={d.plantkey}
      //     plantName={d.plantname}
      //     address={d.address}
      //     nodata={d.nodata}
      //     ivtNodata={d.ivtNodata}
      //     isDesktop={isDesktop}
      //   />
      // ));
      entirePlantInfoCard = visiblePlants[0] ? (
        <InvPlantCard
          key={visiblePlants[0].plantkey}
          plantkey={visiblePlants[0].plantkey}
          plantName={visiblePlants[0].plantname}
          address={visiblePlants[0].address}
          nodata={visiblePlants[0].nodata}
          ivtNodata={visiblePlants[0].ivtNodata}
          subscribeday={subscribeday}
          graceperiodDay={graceperiodDay}
          isDesktop={isDesktop}
        />
      ) : null;
    }
  }

  return (
    <>
      <Box>
        {entirePlantInfoCard}
        {plant === undefined || page.plantkey !== '' ? null : !hideExpanded && visibleItems < plant.length ? (
          <Card
            variant="outlined"
            sx={{
              p: 1,
              // borderColor: '#CECECE',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              cursor: isDesktop ? 'pointer' : 'cursor',
              height: '40px',
              borderRadius: '7px',
              boxShadow: '0px 3px 24px #00000014',
            }}
            onClick={handleExpandClick}
          >
            {expanded ? <CircularProgress size={30} /> : <KeyboardDoubleArrowDownIcon />}
          </Card>
        ) : null}
      </Box>
    </>
  );
}
