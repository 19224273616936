import InverterList from '../../components/Inverter/InverterList';
import SystematicMap from '../../components/Inverter/SystematicMap';
import ConnectBoard from '../../components/Inverter/ConnectBoard';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Card,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import useResponsive from '../../hooks/useResponsive';
import AuthContext from '../../store/auth-context';
import useBackendV2 from '../../hooks/use-backend-v2';
import config from '../../config';
import AppsIcon from '@mui/icons-material/Apps';
import { StyledBreadcrumb } from '../../utils/cssStyles';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import TabMenu from '../../components/Layout/TabMenu';
import Header02 from '../../components/Layout/Header02';
import SubHeader from '../../components/Layout/SubHeader';
import Nav from '../../components/Layout/Nav';
import SubscriptionGuideModal from '../../components/Dashboard/SubscriptionGuideModal';
import usePageLogRecord from '../../hooks/usePageLogRecord';
import DeviceControl from '../../components/Inverter/DeviceControl';

// 인버터 페이지 컴포넌트
export default function InverterPage() {
  const authCtx = useContext(AuthContext);
  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);
  const [webNavOpen, setWebNavOpen] = useState(true);
  const [value, setValue] = useState(parseInt(localStorage.getItem('menuValue')));
  const [subValue, setSubValue] = useState(parseInt(localStorage.getItem('menuSubValue')));
  const [isMobileViewDNE, setIsMobileViewDNE] = useState(false);

  const distance = 0;

  const pageConditionInitialState = {
    pagetype: '',
    plantkey: localStorage.getItem('selectPlantkey'),
    plantname: '',
  };

  const plantStateConditionInitialState = {
    pagetype: '',
    tabtype: '',
    plantkey: '',
    plantname: '',
    jboxcnt: '',
    stringcnt: '',
  };

  const [page, setPage] = useState(pageConditionInitialState);
  const [plant, setPlant] = useState();
  const [plantState, setPlantState] = useState(plantStateConditionInitialState);
  const [selectPlantState, setSelectPlantState] = useState([]);
  const [selectPlant, setSelectPlant] = useState([]);
  const [jboxDetail, setJboxDetail] = useState(false);
  const [hideExpanded, setHideExpanded] = useState(false);
  const [visibleItems, setVisibleItems] = useState(5);

  const [subscribeday, setSubscribeday] = useState(null);
  const [graceperiodDay, setGraceperiodDay] = useState(null);

  const [openSubscriptionGuideModal, setOpenSubscriptionGuideModal] = useState(false);

  const useBackendRequest = useBackendV2();

  const fetchUserPlants = async () => {
    try {
      const { data } = await useBackendRequest.sendRequest(
        `${config.backend.endPoint.plants.userPlantsV2}/${authCtx.currentUser.id}`
      );
      setPage((prevPage) => ({ ...prevPage, pagetype: 'entire' }));
      setPlant(data);
    } catch (error) {
      console.error(error);
      alert('데이터를 불러오는 중 오류가 발생하였습니다. 인터넷 연결 상태를 확인 후 새로고침 해주세요.');
    }
  };

  const fetchUserPlantsState = async () => {
    try {
      const { data } = await useBackendRequest.sendRequest(
        `${config.backend.endPoint.plants.userPlantsV2}/${authCtx.currentUser.id}`
      );

      if (data.length === 1) {
        setPage((prevPage) => ({
          ...prevPage,
          plantkey: data[0].plantkey,
          plantname: data[0].plantname,
        }));
        setSelectPlantState(data);
        setSelectPlant(data);
      } else {
        setSelectPlantState(data);
        setSelectPlant(data);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchSubscriptionPeriod = async () => {
    if (plantState.plantkey === '') {
      return;
    } else {
      try {
        const { data } = await useBackendRequest.sendRequest(
          `${config.backend.endPoint.plants.subscribe}/${plantState.plantkey}`
        );

        const filteredData = data.filter((item) => item.subscribeday > -90);

        if (filteredData.length > 0) {
          const maxSubdcribedayItem = filteredData.reduce((maxItem, currentItem) => {
            return currentItem.subscribeday > maxItem.subscribeday ? currentItem : maxItem;
          });

          setSubscribeday(maxSubdcribedayItem.subscribeday);
          setGraceperiodDay(data[0]?.graceperiod);
        } else {
          setSubscribeday(null);
          setGraceperiodDay(null);
        }
      } catch (error) {
        console.error(error);
        alert('오류가 발생하였습니다.');
      }
    }
  };

  const fetchUserStylePreference = async () => {
    try {
      const { data } = await useBackendRequest.sendRequest(
        `${config.backend.endPoint.users.styleGet}/${authCtx.currentUser.id}`
      );

      if (data.style === 'DEFAULT') {
        setIsMobileViewDNE(false);
      } else {
        setIsMobileViewDNE(true);
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const selectPlantStateChangeHandler = (plant) => {
    setPlantState((prev) => {
      return {
        ...prev,
        plantkey: plant.plantkey,
        plantname: plant.plantname,
        jboxcnt: plant.jboxcnt,
        stringcnt: plant.stringcnt,
      };
    });
    setJboxDetail(false);
    localStorage.setItem('selectPlantkey', plant.plantkey);
  };

  const selectPlantChangeHandler = (selectedPlant) => {
    setPage({ ...page, plantkey: selectedPlant.plantkey, plantname: selectedPlant.plantname });
    setHideExpanded(true);
  };

  const moveEntirePlantHandler = () => {
    setPage((prevPage) => ({ ...prevPage, pagetype: 'entire', plantkey: '', plantname: '' }));
    setVisibleItems(5);
    setHideExpanded(false);
  };

  // 만료 발전소 상품 및 구독 라이선스 입력 페이지 안내 모달
  const openSubscriptionGuideModalHandler = () => {
    setOpenSubscriptionGuideModal(true);
  };
  const closeSubscriptionGuideModalHandler = () => {
    setOpenSubscriptionGuideModal(false);
  };

  // 전체 발전소
  const numPlants = Array.isArray(selectPlant) ? selectPlant.length : 0;

  useEffect(() => {
    fetchUserPlants();
    fetchUserPlantsState();
    fetchUserStylePreference();
  }, []);

  useEffect(() => {
    fetchSubscriptionPeriod();
  }, [plantState]);

  useEffect(() => {
    // 로컬 스토리지에서 selectPlantkey 값을 가져옴
    const localStorageSelectPlantkey = localStorage.getItem('selectPlantkey');

    // selectPlantState에서 해당하는 발전소 탐색
    const initialSelectedPlant = selectPlantState.find(
      (option) => option.plantkey === localStorageSelectPlantkey
    );

    // 첫번째 발전소 탐색
    const initialSelectedPlantN = selectPlantState[0];

    // 찾은 발전소를 초기값으로 설정
    if (initialSelectedPlant) {
      selectPlantStateChangeHandler(initialSelectedPlant);
    }
    // 찾은 발전소가 없는 경우 첫번째 발전소를 초기값으로 설정
    else if (initialSelectedPlant === undefined && initialSelectedPlantN !== undefined) {
      selectPlantStateChangeHandler(initialSelectedPlantN);
    }
  }, [selectPlantState]);

  let componentToShow;

  switch (subValue) {
    case 0:
      componentToShow = (
        <InverterList
          isDesktop={isDesktop}
          setPage={setPage}
          page={page}
          plant={plant}
          subValue={subValue}
          selectPlantState={selectPlantState}
          hideExpanded={hideExpanded}
          visibleItems={visibleItems}
          setVisibleItems={setVisibleItems}
          selectPlant={selectPlant}
          setSelectPlant={setSelectPlant}
          subscribeday={subscribeday}
          graceperiodDay={graceperiodDay}
        />
      );
      break;
    case 1:
      componentToShow = (
        <SystematicMap plantState={plantState} subscribeday={subscribeday} graceperiodDay={graceperiodDay} />
      );
      break;
    case 2:
      componentToShow = (
        <ConnectBoard
          plantState={plantState}
          jboxDetail={jboxDetail}
          setJboxDetail={setJboxDetail}
          subscribeday={subscribeday}
          graceperiodDay={graceperiodDay}
        />
      );
      break;
    case 3:
      componentToShow = (
        <DeviceControl plantState={plantState} subscribeday={subscribeday} graceperiodDay={graceperiodDay} />
      );
      break;
    default:
      componentToShow = null;
      break;
  }

  usePageLogRecord('inverters', plantState.plantkey);

  return (
    <>
      <Header02
        onOpenNav={() => setOpen(true)}
        value={value}
        setValue={setValue}
        subValue={subValue}
        setSubValue={setSubValue}
        plantkey={plantState.plantkey}
        distance={distance > 0 ? distance : 0}
        webNavOpen={webNavOpen}
        isDesktop={isDesktop}
        isMobileViewDNE={isMobileViewDNE}
        setIsMobileViewDNE={setIsMobileViewDNE}
      />
      {isDesktop ? null : (
        <SubHeader
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          distance={distance > 0 ? distance : 0}
          isDesktop={isDesktop}
        />
      )}
      {isDesktop ? null : (
        <Nav
          openNav={open}
          onCloseNav={() => setOpen(false)}
          value={value}
          setValue={setValue}
          subValue={subValue}
          setSubValue={setSubValue}
          webNavOpen={webNavOpen}
          setWebNavOpen={setWebNavOpen}
        />
      )}
      <Box
        sx={{
          display: {
            xs: 'none',
            sm: 'flex',
          },
        }}
      >
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <StyledBreadcrumb label="인버터" icon={<AppsIcon fontSize="small" color="primary" />} />
          <StyledBreadcrumb
            label={
              subValue === 0
                ? '목록'
                : subValue === 1
                ? '계통도'
                : subValue === 2
                ? '접속반'
                : subValue === 3
                ? '장비제어'
                : null
            }
          />
        </Breadcrumbs>
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          my: 2,
          gap: 1,
          mt: isDesktop ? null : 7.5,
        }}
        // justifyContent="space-between"
      >
        {selectPlantState.length > 1 ? (
          <>
            {numPlants > 11 ? (
              <Autocomplete
                disableClearable
                id="combo-box-demo"
                options={selectPlant}
                getOptionLabel={(option) => option.plantname || ''}
                value={
                  selectPlant.find((option) => option.plantkey === page.plantkey) ||
                  selectPlant.find(
                    (option) => option.plantkey === parseInt(localStorage.getItem('selectPlantkey'), 10)
                  ) ||
                  selectPlant[0]
                }
                onChange={(e, newValue) => {
                  if (newValue) {
                    const selectedPlant = selectPlant.find((option) => option.plantkey === newValue.plantkey);
                    selectPlantStateChangeHandler(selectedPlant);
                    selectPlantChangeHandler(selectedPlant);
                  }
                }}
                renderInput={(params) => (
                  <TextField {...params} label="발전소" variant="outlined" sx={{ bgcolor: '#fff' }} />
                )}
                sx={{ width: isDesktop ? '45%' : '100%' }}
              />
            ) : (
              <FormControl sx={{ width: isDesktop ? '45%' : '100%', bgcolor: '#fff' }}>
                <InputLabel>발전소</InputLabel>
                <Select
                  label="발전소"
                  value={
                    selectPlantState.find((option) => option.plantkey === plantState.plantkey)?.plantkey ||
                    selectPlantState.find(
                      (option) => option.plantkey === parseInt(localStorage.getItem('selectPlantkey'), 10)
                    )?.plantkey ||
                    selectPlantState[0]?.plantkey
                  }
                  onChange={(e) => {
                    const selectedPlant = selectPlantState.find(
                      (option) => option.plantkey === e.target.value
                    );
                    selectPlantStateChangeHandler(selectedPlant);
                    selectPlantChangeHandler(selectedPlant);
                  }}
                >
                  {(subValue === 0 ? selectPlant : selectPlantState).map((option) => (
                    <MenuItem key={option.plantkey} value={option.plantkey}>
                      {option.plantname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            {subscribeday !== null && subscribeday > -90 ? (
              subscribeday < 0 ? (
                <Chip
                  label={`서비스 만료 D${subscribeday}`}
                  color="error"
                  variant="outlined"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1, bgcolor: '#fff' }}
                />
              ) : (
                <Chip
                  label="서비스 만료"
                  color="error"
                  variant="contained"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1 }}
                />
              )
            ) : null}
          </>
        ) : (
          <>
            <Card sx={{ border: '0.15px solid #D8D8D8', p: 1.5, width: isDesktop ? '45%' : '100%' }}>
              <Typography variant="body1" sx={{ fontSize: '1rem' }}>
                {page.plantname}
              </Typography>
            </Card>
            {subscribeday !== null && subscribeday > -90 ? (
              subscribeday < 0 ? (
                <Chip
                  label={`서비스 만료 D${subscribeday}`}
                  color="error"
                  variant="outlined"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1, bgcolor: '#fff' }}
                />
              ) : (
                <Chip
                  label="서비스 만료"
                  color="error"
                  variant="contained"
                  onClick={openSubscriptionGuideModalHandler}
                  sx={{ ml: 1 }}
                />
              )
            ) : null}
          </>
        )}
      </Box>
      {isDesktop ? (
        <TabMenu menu="inverters" subValue={subValue} setSubValue={setSubValue} />
      ) : (
        <Box display="flex" justifyContent="end"></Box>
      )}
      <Box>{componentToShow}</Box>
      {openSubscriptionGuideModal && (
        <SubscriptionGuideModal
          plantkey={plantState.plantkey}
          plantName={plantState.plantname}
          open={openSubscriptionGuideModal}
          onClose={closeSubscriptionGuideModalHandler}
        />
      )}
    </>
  );
}
