import MainCard from '../../theme/MainCard';
import { Button, Box, Typography } from '@mui/material';

// 현황판 RTU 수신 표시 컴포넌트
export default function PlantRtuInverterStateCard_02({ basePlantInfo, isIpad, isNexthub }) {
  return (
    <>
      <MainCard
        sx={{
          borderRadius: '15px',
          boxShadow: '0px 3px 100px #00000014',
          height: 102,
        }}
      >
        <Box>
          <Typography
            display="flex"
            justifyContent="center"
            variant={isIpad || isNexthub ? 'subtitle2' : 'subtitle1'}
          >
            {isIpad || isNexthub ? '통신' : '통신 상태'}
          </Typography>
          <Button
            variant="contained"
            size="small"
            sx={{
              mt: 1.5,
              cursor: 'default',
              width: '100%',
              backgroundColor: basePlantInfo.some((plant) =>
                plant.rtuinfos?.some((info) => info.rtustatus === 1)
              )
                ? '#22C58B'
                : '#FF525F',
            }}
          >
            {basePlantInfo.some((plant) => plant.rtuinfos?.some((info) => info.rtustatus === 1))
              ? '통신 중'
              : '통신 중단'}
          </Button>
        </Box>
      </MainCard>
    </>
  );
}
