import { Box } from '@mui/material';
import AlarmCodeAnalysisWeb from './AlarmCodeAnalysisWeb';
import AlarmCodeAnalysisApp from './AlarmCodeAnalysisApp';

export default function AlarmCodeAnalysis({ isDesktop, isIPadMini, alarmsBlist, plantState }) {
  const calculateSum = (filteredData) => {
    return filteredData.reduce((sum, row) => sum + row.cnt, 0);
  };

  const uniqueErrNums = Array.from(new Set(alarmsBlist.map((row) => row.errnum)));
  const newAlarmsBlist = [];

  uniqueErrNums.forEach((errnum) => {
    const filteredData = alarmsBlist.filter((row) => row.errnum === errnum);
    newAlarmsBlist.push(...filteredData);

    if (plantState.selectInverter === 'all') {
      newAlarmsBlist.push({ invertername: '합계', cnt: calculateSum(filteredData) });
    }
  });

  return (
    <Box sx={{ mt: 1 }}>
      {isDesktop ? (
        <AlarmCodeAnalysisWeb data={newAlarmsBlist} />
      ) : (
        <>
          {newAlarmsBlist.map((d, index) => (
            <AlarmCodeAnalysisApp key={index} data={d} />
          ))}
        </>
      )}
    </Box>
  );
}
