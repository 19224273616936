import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { Box, Chip, Stack, Typography, useMediaQuery } from '@mui/material';

/*
통합문의게시판 리스트 컴포넌트(모바일뷰)
InquiryBoardSearchForm > InquiryBoardList(웹뷰 리스트 페이지),InquiryBoardListMobile(모바일뷰 리스트 페이지)
*/
export default function InquiryBoardListMobile({ rows, onBtnMore }) {
  //const isSmallDevice = useMediaQuery('(min-width: 280px) and (max-width: 320px)');

  const navigate = useNavigate();

  function handleGoDetail(seq) {
    navigate(`${config.frontend.inquiryBoardsdetail}/${seq}`);
  }

  const textSubstring = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.substring(0, maxLength) + '...';
    }
    return text;
  };

  return (
    <>
      {rows.length > 0 ? (
        rows.map((row) => (
          <Card
            sx={{
              mt: 2,
              width: '100%',
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              boxShadow: '0px 3px 24px #00000014',
              borderRadius: '16px',
              opacity: '1',
            }}
            key={row.seq}
            onClick={() => handleGoDetail(row.seq)}
          >
            <Box sx={{ width: '100%' }}>
              <Stack
                direction="row"
                sx={{ mt: 2, mb: 2 }}
                justifyContent="space-between"
                alignItems="flex-start"
              >
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal 600 14px/19px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                    width: '100%',
                    ml: 1,
                    // mr: isSmallDevice ? 10 : 18,
                  }}
                >
                  {textSubstring(row.title, 10)}
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'right',
                    font: 'normal normal normal 14px/19px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                    width: '100%',
                    mr: 1,
                  }}
                >
                  {row.gubunNm}
                </Typography>
              </Stack>
            </Box>
            <Box sx={{ width: '100%' }}>
              <Stack direction="row" sx={{ mb: 2 }}>
                <Typography
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    textAlign: 'left',
                    font: 'normal normal 600 14px/19px Noto Sans',
                    letterSpacing: '0px',
                    color: '#000000',
                    opacity: '1',
                    width: '100%',
                    ml: 1,
                    // mr: 20,
                  }}
                >
                  {row.regDate.substring(0, 10)}
                </Typography>
                {row.processStatusCcd === '0' ? (
                  <Chip label={row.processStatusNm} sx={{ bgcolor: '#ffab00', fontWeight: 'bold', color: '#FFFFFF', mr: 1 }} />
                ) : row.processStatusCcd === '1' ? (
                  <Chip label={row.processStatusNm} sx={{ bgcolor: '#607d8b', fontWeight: 'bold', color: '#FFFFFF', mr: 1 }} />
                ) : row.processStatusCcd === '2' ? (
                  <Chip label={row.processStatusNm} sx={{ bgcolor: '#f44336', fontWeight: 'bold', color: '#FFFFFF', mr: 1 }} />
                ) : (
                  <Chip label={row.processStatusNm} sx={{ bgcolor: '#43a047', fontWeight: 'bold', color: '#FFFFFF', mr: 1 }} />
                )}
              </Stack>
            </Box>
          </Card>
        ))
      ) : (
        <Card sx={{ maxWidth: '100%', mt: 2 }}>
          <CardHeader title="조회된 정보가 없습니다." sx={{ textAlign: 'center' }} />
        </Card>
      )}
    </>
  );
}
