import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import '../../../css/App.css';
import { useEffect, useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

export default function HourAllStatisticsTable({ data, isDesktop }) {
  const columns = [
    { field: 'groupname', headerName: '발전소명', width: 150 },
    { field: 'year_month_day_hour', headerName: '시(hour)', width: 60 },
    { field: 'yield', headerName: '발전량(kWh)', width: 100 },
    { field: 'radiation', headerName: '경사 일사량(W/㎡)', width: 100 },
    { field: 'tco2', headerName: '온실가스 감축량(tCO2eq)', width: 100 },
  ];

  const mobileColumns = [
    { field: 'year_month_day_hour', headerName: '시(hour)', width: 60 },
    { field: 'yield', headerName: '발전량(kWh)', width: 100 },
    { field: 'radiation', headerName: '경사 일사량(W/㎡)', width: 100 },
    { field: 'tco2', headerName: '온실가스 감축량(tCO2eq)', width: 100 },
  ];

  const [total, setTotal] = useState({
    yield: 0,
    tco2: 0,
  });

  const calculateTotal = () => {
    let yieldTotal = 0;
    let tco2Total = 0;

    data.forEach((row) => {
      yieldTotal += row.yield;
      tco2Total += row.tco2;
    });

    setTotal({
      yield: yieldTotal,
      tco2: tco2Total,
    });
  };

  useEffect(() => {
    calculateTotal();
  }, [data]);

  return (
    <>
      <Card
        sx={{
          mt: 1,
          borderRadius: 0,
          boxShadow: '0px 3px 12px #00000014',
        }}
      >
        <StyledTableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                {isDesktop
                  ? columns.map((column) => (
                      <StyledTableCell
                        key={column.field}
                        align="center"
                        style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                        className={column.headerName === '발전소명' ? 'sticky' : ''}
                      >
                        {column.headerName}
                      </StyledTableCell>
                    ))
                  : mobileColumns.map((column) => (
                      <StyledTableCell
                        key={column.field}
                        align="center"
                        style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                      >
                        {column.headerName}
                      </StyledTableCell>
                    ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => (
                <TableRow key={row.id} index={index}>
                  {isDesktop
                    ? columns.map((column) => (
                        <TableCell
                          key={column.field}
                          align="center"
                          style={{
                            minWidth: column.width,
                            backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                            borderRight: '1px solid #d0d0d0',
                          }}
                          className={column.headerName === '발전소명' ? 'sticky' : ''}
                        >
                          {row[column.field] !== null && row[column.field] !== undefined
                            ? row[column.field] !== 0
                              ? row[column.field].toLocaleString()
                              : '0'
                            : '--'}
                        </TableCell>
                      ))
                    : mobileColumns.map((column) => (
                        <TableCell
                          key={column.field}
                          align="center"
                          style={{
                            minWidth: column.width,
                            backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                            borderRight: '1px solid #d0d0d0',
                          }}
                        >
                          {row[column.field] !== null && row[column.field] !== undefined
                            ? row[column.field] !== 0
                              ? row[column.field].toLocaleString()
                              : '0'
                            : '--'}
                        </TableCell>
                      ))}
                </TableRow>
              ))}
              <TableRow>
                {isDesktop
                  ? columns.map((column, columnIndex) => (
                      <TableCell
                        key={column.field}
                        align="center"
                        style={{
                          minWidth: column.width,
                          borderRight: '1px solid #d0d0d0',
                          fontSize: '15px',
                          fontWeight: 'bold',
                          backgroundColor: '#1769aa',
                          color: 'white',
                        }}
                        className={columnIndex === 0 ? 'sticky' : ''}
                      >
                        {columnIndex === 0
                          ? '합계'
                          : column.field === 'yield'
                          ? total.yield.toLocaleString()
                          : column.field === 'tco2'
                          ? total.tco2.toFixed(5)
                          : ''}
                      </TableCell>
                    ))
                  : mobileColumns.map((column, columnIndex) => (
                      <TableCell
                        key={column.field}
                        align="center"
                        style={{
                          minWidth: column.width,
                          borderRight: '1px solid #d0d0d0',
                          fontSize: '15px',
                          fontWeight: 'bold',
                          backgroundColor: '#1769aa',
                          color: 'white',
                        }}
                      >
                        {columnIndex === 0
                          ? '합계'
                          : column.field === 'yield'
                          ? total.yield.toLocaleString()
                          : column.field === 'tco2'
                          ? total.tco2.toFixed(5)
                          : ''}
                      </TableCell>
                    ))}
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </Card>
    </>
  );
}
