import {
  Avatar,
  Box,
  CardContent,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Grid,
  Tooltip,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MainCard from '../../../theme/MainCard';
import { useState } from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { useNavigate } from 'react-router-dom';
import config from '../../../config';

// 개별 대시보드 VCB ACB 상태 컴포넌트
export default function TodayVcbAcbTable({ acbVcbDailyDevelopment, isDesktop }) {
  const theme = useTheme();
  const navigate = useNavigate();

  const [plantAcbVcbTooltipOpenMap, setPlantAcbVcbTooltipOpenMap] = useState({});

  // 클릭한 ACB,VCB 아바타의 툴팁 상태를 열기
  const handlePlantAcbVcbAvatarClick = (serialnum) => {
    setPlantAcbVcbTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: true }));
  };
  // ACB,VCB 툴팁 상태를 닫기
  const handlePlantAcbVcbTooltipClose = (serialnum) => {
    setPlantAcbVcbTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: false }));
  };

  const acbvcbListClickHandler = () => {
    localStorage.setItem('menuValue', 1);
    localStorage.setItem('menuSubValue', 1);
    navigate(config.frontend.inverters, { state: { subValue: 1 } });
  };

  return (
    <>
      <MainCard content={false} sx={{ height: 350, overflowY: 'auto' }}>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography color="primary" fontWeight="bold" fontSize="0.89rem" sx={{ mb: 1, mt: 1 }}>
              VCB, ACB
            </Typography>
            <Avatar
              variant="square"
              onClick={acbvcbListClickHandler}
              sx={{ width: '25px', height: '25px', bgcolor: '#1981E7', cursor: 'pointer' }}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </Avatar>
          </Box>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <Divider sx={{ my: 1 }} />
              {acbVcbDailyDevelopment.map((data, index) => (
                <div key={index}>
                  <Grid container direction="column" key={index}>
                    <Grid item>
                      <Grid container alignItems="center" justifyContent="space-between">
                        <Grid item>
                          <Typography variant="subtitle1" color="inherit">
                            {data.acbname}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Grid container alignItems="center" justifyContent="space-between">
                            <Grid item>
                              <Typography variant="subtitle1" color="inherit">
                                {(Math.floor((data.actp / 1000) * 10) / 10).toFixed(1)} kW
                              </Typography>
                            </Grid>
                            <Grid item>
                              {isDesktop ? (
                                <Tooltip
                                  title={data.nodata === '0' && data.status === '1' ? '발전 중' : '발전 중단'}
                                  arrow
                                >
                                  <Avatar
                                    sizes="small"
                                    sx={{
                                      width: 15,
                                      height: 15,
                                      ml: 1,
                                      bgcolor: data.nodata === '0' && data.status === '1' ? '#64B346' : 'red',
                                      color: data.nodata === '0' && data.status === '1' ? '#64B346' : 'red',
                                    }}
                                  >
                                    {''}
                                  </Avatar>
                                </Tooltip>
                              ) : (
                                <ClickAwayListener
                                  onClickAway={() => handlePlantAcbVcbTooltipClose(data.serialnum)}
                                >
                                  <div>
                                    <Tooltip
                                      PopperProps={{
                                        disablePortal: true,
                                      }}
                                      open={!!plantAcbVcbTooltipOpenMap[data.serialnum]}
                                      onClose={() => handlePlantAcbVcbTooltipClose(data.serialnum)}
                                      title={
                                        data.nodata === '0' && data.status === '1' ? '발전 중' : '발전 중단'
                                      }
                                      arrow
                                    >
                                      <Avatar
                                        sizes="small"
                                        sx={{
                                          width: 15,
                                          height: 15,
                                          ml: 1,
                                          bgcolor:
                                            data.nodata === '0' && data.status === '1' ? '#64B346' : 'red',
                                          color:
                                            data.nodata === '0' && data.status === '1' ? '#64B346' : 'red',
                                        }}
                                        onClick={() => handlePlantAcbVcbAvatarClick(data.serialnum)}
                                      >
                                        {''}
                                      </Avatar>
                                    </Tooltip>
                                  </div>
                                </ClickAwayListener>
                              )}
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 1 }} />
                </div>
              ))}
            </Grid>
          </Grid>
        </CardContent>
      </MainCard>
    </>
  );
}
