import { Grid } from '@mui/material';
import useResponsive from '../../../hooks/useResponsive';
import { useEffect, useState } from 'react';
import useBackendV2 from '../../../hooks/use-backend-v2';
import config from '../../../config';
import InverterMapCard from './InverterMapCard';
import useInterval from '../../../hooks/use-interval';
import INVERTER01 from '../../../assets/SystematicMapImg/inverter01.svg';
import UseCustomSort from '../../../hooks/useCustomSort';

// 인버터 계통도 내 인버터 컴포넌트
export default function InverterMap({ plantState }) {
  const isDesktop = useResponsive('up', 'lg');

  const [expanded, setExpanded] = useState(true);
  const [inverterInfo, setInverterInfo] = useState([]);

  const getInverterInfoRequest = useBackendV2();

  const fetchGetInverterInfo = async () => {
    try {
      const { data } = await getInverterInfoRequest.sendRequest(
        `${config.backend.endPoint.inverters.extReal}/${plantState.plantkey}`
      );

      const sortedData = UseCustomSort(data, 'invertername');

      setInverterInfo(sortedData);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const fetchPollingInterval = 60000;

  useInterval(() => {
    fetchGetInverterInfo();
  }, fetchPollingInterval);

  useEffect(() => {
    if (plantState.plantkey !== '') {
      fetchGetInverterInfo();
    }
  }, [plantState, inverterInfo.length]);

  let InverterCard;

  if (inverterInfo.length !== 0 && expanded === true) {
    InverterCard = inverterInfo.map((d, index) => (
      <InverterMapCard key={index} inverterInfo={d} isDesktop={isDesktop} />
    ));
  }

  return (
    <>
      <Grid item lg={3} sx={{ display: 'flex', mt: 1 }}>
        <img src={INVERTER01} alt="inverter" />
      </Grid>
      {expanded === false ? null : InverterCard}
    </>
  );
}
