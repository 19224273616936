import { Box, Button, Divider, Typography } from '@mui/material';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import KeyIcon from '@mui/icons-material/Key';
import useBackendV2 from '../../hooks/use-backend-v2';
import { useEffect, useState } from 'react';
import config from '../../config';

// 구독 라이선스 입력 구독권 조회 모달 컴포넌트
export default function SearchSubscribeKeyModal({
  plantkey,
  setSelectSubscribekey,
  setSelectServiceTerm,
  open,
  onClose,
  isDesktop,
}) {
  const [subscribedKeyList, setSubscribedayKeyList] = useState([]);

  const backendRequest = useBackendV2();

  const fetchSubscribedKeyList = async () => {
    try {
      const { data } = await backendRequest.sendRequest(config.backend.endPoint.carts.keyList);
      setSubscribedayKeyList(data);
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  const selectKeyClickHandler = (subscribekey, serviceterm) => {
    setSelectSubscribekey(subscribekey);
    setSelectServiceTerm(serviceterm);
    onClose();
  };

  useEffect(() => {
    fetchSubscribedKeyList();
  }, []);

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    onClose();
  };

  return (
    <CustomModal
      open={open}
      onClose={closeModalHandler}
      sx={{ width: { xs: '95%', md: 650 }, maxHeight: '95%' }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          alignItems: 'center',
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
        <KeyIcon />
        <Typography sx={{ fontSize: '18px', fontWeight: 'bold' }}>이용권 조회</Typography>
      </Box>
      <hr />
      <Box sx={{ mt: 2 }}>
        {subscribedKeyList.length === 0 ? (
          <Box>
            <Typography variant="subtitle1">조회된 이용권이 없습니다.</Typography>
            <Typography variant="subtitle1" sx={{ mt: 1 }}>
              상품 페이지에서 이용권을 구매해 주세요.
            </Typography>
          </Box>
        ) : null}
        {subscribedKeyList.map((list, index) => (
          <Box key={index} sx={{ mt: 1 }}>
            <Box display="flex" alignItems="center" justifyContent="space-between">
              <Typography variant="subtitle1">조회 키: {list.subscribekey}</Typography>
            </Box>
            <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ mt: 1 }}>
              <Typography variant="subtitle1">이용 기간: {list.serviceterm}개월</Typography>
              <Button
                variant="outlined"
                onClick={() => selectKeyClickHandler(list.subscribekey, list.serviceterm)}
              >
                선택
              </Button>
            </Box>
            <Divider sx={{ mt: 1 }} />
          </Box>
        ))}
      </Box>
    </CustomModal>
  );
}
