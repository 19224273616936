import { Avatar, Box, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import MainCard from '../../../theme/MainCard';
import EnergySavingsLeafIcon from '@mui/icons-material/EnergySavingsLeaf';

// 개별 대시보드 일일발전량 표시 컴포넌트
export default function AppWidgetEachSummary({ total, price, isDesktop, color = 'primary' }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const CardWrapper = styled(MainCard)(({ theme }) => ({
    backgroundColor: theme.palette[color].lighter,
    // color: '#fff',
    // overflow: 'hidden',
    // position: 'relative',
    // '&:after': {
    //   content: '""',
    //   position: 'absolute',
    //   width: 210,
    //   height: 210,
    //   background: theme.palette[color].darker,
    //   borderRadius: '50%',
    //   top: -85,
    //   right: -95,
    //   [theme.breakpoints.down('sm')]: {
    //     top: -105,
    //     right: -140,
    //   },
    // },
    // '&:before': {
    //   content: '""',
    //   position: 'absolute',
    //   width: 210,
    //   height: 210,
    //   background: theme.palette[color].darker,
    //   borderRadius: '50%',
    //   top: -125,
    //   right: -15,
    //   opacity: 0.5,
    //   [theme.breakpoints.down('sm')]: {
    //     top: -155,
    //     right: -70,
    //   },
    // },
  }));

  return (
    <CardWrapper border={false} content={false}>
      <Box sx={{ p: 2 }}>
        <Grid container direction="column">
          <Grid item>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Box display={isMobile ? 'flex' : ''} alignItems="center">
                  <Avatar
                    variant="rounded"
                    sx={{
                      ...theme.typography.commonAvatar,
                      ...theme.typography.largeAvatar,
                      // backgroundColor: theme.palette[color].darker,
                      bgcolor: '#FBE2E6',
                      mr: 1,
                    }}
                  >
                    <EnergySavingsLeafIcon fontSize="large" sx={{ color: '#F96436' }} />
                  </Avatar>
                  <Typography sx={{ fontSize: '1.6rem', fontWeight: 600, color: '#fff' }}>{total}</Typography>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display="flex" alignItems="center">
            <Typography
              sx={{
                fontSize: isMobile ? '0.9rem' : '1rem',
                fontWeight: 600,
                color: '#fff',
                display: 'flex',
                mt: 1,
              }}
            >
              일일 발전량
            </Typography>
            <Typography sx={{ fontSize: '0.75rem', fontWeight: 600, color: '#fff', mt: 1.2 }}>
              (kWh)
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </CardWrapper>
  );
}
