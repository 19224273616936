import {
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import '../../../css/App.css';
import { useEffect, useState } from 'react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

export default function YearGroupStatisticsTable({ data, isDesktop }) {
  const columns = [
    { field: 'groupname', headerName: '그룹명', width: 150 },
    { field: 'flocaldate', headerName: '연(year)', width: 100 },
    { field: 'totalyield', headerName: '발전량(kWh)', width: 200 },
  ];

  const [total, setTotal] = useState({
    totalyield: 0,
  });

  const calculateTotal = () => {
    let totalyieldTotal = 0;

    data.forEach((row) => {
      totalyieldTotal += row.totalyield;
    });

    setTotal({
      totalyield: totalyieldTotal,
    });
  };

  useEffect(() => {
    calculateTotal();
  }, [data]);

  return (
    <Card
      sx={{
        mt: 1,
        borderRadius: 0,
        boxShadow: '0px 3px 12px #00000014',
      }}
    >
      <StyledTableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <StyledTableCell
                  key={column.field}
                  align="center"
                  style={{ minWidth: column.width, borderRight: '1px solid #d0d0d0' }}
                  className={column.headerName === '그룹명' ? 'sticky' : ''}
                >
                  {column.headerName}
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {' '}
            {data.map((row, index) => (
              <TableRow key={row.id} index={index}>
                {columns.map((column) => (
                  <TableCell
                    key={column.field}
                    align="center"
                    style={{
                      minWidth: column.width,
                      backgroundColor: index % 2 === 0 ? '#fff' : '#f0f0f0',
                      borderRight: '1px solid #d0d0d0',
                    }}
                    className={column.headerName === '그룹명' ? 'sticky' : ''}
                  >
                    {column.field === 'flocaldate'
                      ? row.flocaldate
                      : column.field === 'totalyield' && row[column.field]
                        ? row[column.field].toLocaleString()
                        : row[column.field]}
                  </TableCell>
                ))}
              </TableRow>
            ))}
            <TableRow>
              {columns.map((column, columnIndex) => (
                <TableCell
                  key={column.field}
                  align="center"
                  style={{
                    minWidth: column.width,
                    borderRight: '1px solid #d0d0d0',
                    fontSize: '16px',
                    fontWeight: 'bold',
                    backgroundColor: '#1769aa',
                    color: 'white',
                  }}
                  className={columnIndex === 0 ? 'sticky' : ''}
                >
                  {columnIndex === 0
                    ? '합계'
                    : column.field === 'totalyield' && total.totalyield
                      ? total.totalyield.toLocaleString()
                      : ''}
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Card>
  );
}
