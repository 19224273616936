import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  tableCellClasses,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CustomModal from '../UI/CustomModal';
import CloseIcon from '@mui/icons-material/Close';
import ThermostatIcon from '@mui/icons-material/Thermostat';
import { convertFormatStringToYMD } from '../../utils/dateUtil';
import { useEffect, useState } from 'react';
import {
  PiCloudDuotone,
  PiCloudFogDuotone,
  PiCloudLightningDuotone,
  PiCloudMoonDuotone,
  PiCloudRainDuotone,
  PiCloudSnowDuotone,
  PiCloudSunDuotone,
  PiMoonStarsDuotone,
  PiSunDuotone,
} from 'react-icons/pi';
import { TbNavigationFilled } from 'react-icons/tb';
import config from '../../config';
import useBackendV2 from '../../hooks/use-backend-v2';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#2065D1',
    color: theme.palette.common.white,
    fontSize: '13px',
  },
}));
const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  overflowX: 'auto',
}));

const weatherIconMap = {
  '001-1': <PiSunDuotone style={{ fontSize: '3rem', color: 'red' }} />,
  '001-2': <PiMoonStarsDuotone style={{ fontSize: '3rem', color: '#D5C450' }} />,
  '002-1': <PiCloudSunDuotone style={{ fontSize: '3rem', color: '#999999' }} />,
  '002-2': <PiCloudMoonDuotone style={{ fontSize: '3rem', color: '#999999' }} />,
  '003': <PiCloudDuotone style={{ fontSize: '3rem', color: '#999999' }} />,
  '004': <PiCloudRainDuotone style={{ fontSize: '3rem', color: '#8490e1' }} />,
  '005': <PiCloudFogDuotone style={{ fontSize: '3rem', color: '#999999' }} />,
  '006': <PiCloudSnowDuotone style={{ fontSize: '3rem', color: '#9B9CA2' }} />,
  '007': <PiCloudLightningDuotone style={{ fontSize: '3rem', color: '#D3A612' }} />,
};

// 날씨 정보 모달 컴포넌트
export default function WeatherInfoModal({ open, onClose, page, address }) {
  const [weatherInfo, setWeatherInfo] = useState('');

  const [nowTime, setNowTime] = useState(Date.now());

  const weatherInfoRequest = useBackendV2();

  const fetchWeatherInfo = async () => {
    try {
      const weatherObservationRequestConfig = {
        method: 'POST',
        body: {
          address: page === undefined ? address : page.plantAddress,
          targetdate: convertFormatStringToYMD(nowTime),
        },
      };
      const weatherInfoResult = await weatherInfoRequest.sendRequest(
        config.backend.endPoint.envs.weather,
        weatherObservationRequestConfig
      );

      if (weatherInfoResult.data !== null) {
        setWeatherInfo(weatherInfoResult.data.Datas);
      } else {
        alert('오류가 발생하였습니다.');
      }
    } catch (error) {
      console.error(error);
      alert('오류가 발생하였습니다.');
    }
  };

  useEffect(() => {
    if (open === true) {
      fetchWeatherInfo();
    }
  }, [open]);

  const closeModalHandler = (event, reason) => {
    if (reason && reason === 'backdropClick') {
      return;
    }
    setWeatherInfo('');
    onClose();
  };

  return (
    <>
      <CustomModal
        open={open}
        onClose={closeModalHandler}
        sx={{ maxHeight: '95%', width: { xs: '95%', md: 800 } }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            position: 'absolute',
            top: '16px',
            right: '16px',
          }}
        >
          <CloseIcon onClick={closeModalHandler} sx={{ cursor: 'pointer' }} />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
          <ThermostatIcon />
          <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
            날씨 정보
          </Typography>
        </Box>
        <hr />
        <StyledTableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <StyledTableCell align="center" sx={{ minWidth: 120, borderRight: '1px solid #d0d0d0' }}>
                  시각
                </StyledTableCell>
                {weatherInfo.length === 0
                  ? ''
                  : weatherInfo.map((column, index) => (
                      <StyledTableCell
                        key={index}
                        align="center"
                        sx={{ minWidth: 120, borderRight: '1px solid #d0d0d0' }}
                      >
                        {column.Date.split(' ')[1]}시
                      </StyledTableCell>
                    ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <StyledTableCell align="center" sx={{ minWidth: 120, borderRight: '1px solid #d0d0d0' }}>
                  날씨
                </StyledTableCell>
                {weatherInfo.length === 0
                  ? ''
                  : weatherInfo.map((column, index) => (
                      <StyledTableCell
                        key={index}
                        align="center"
                        sx={{ minWidth: 120, borderRight: '1px solid #d0d0d0' }}
                      >
                        {weatherIconMap[column.Data.v_img] || 'Unknown Icon'}
                      </StyledTableCell>
                    ))}
              </TableRow>
              <TableRow>
                <StyledTableCell align="center" sx={{ minWidth: 120, borderRight: '1px solid #d0d0d0' }}>
                  기온(°C)
                </StyledTableCell>
                {weatherInfo.length === 0
                  ? ''
                  : weatherInfo.map((column, index) => (
                      <StyledTableCell
                        key={index}
                        align="center"
                        sx={{ minWidth: 120, borderRight: '1px solid #d0d0d0' }}
                      >
                        {column.Data.v_temp} °C
                      </StyledTableCell>
                    ))}
              </TableRow>
              <TableRow>
                <StyledTableCell align="center" sx={{ minWidth: 120, borderRight: '1px solid #d0d0d0' }}>
                  강수량(mm)
                </StyledTableCell>
                {weatherInfo.length === 0
                  ? ''
                  : weatherInfo.map((column, index) => (
                      <StyledTableCell
                        key={index}
                        align="center"
                        sx={{ minWidth: 120, borderRight: '1px solid #d0d0d0' }}
                      >
                        {column.Data.v_rain === '' ? '-' : column.Data.v_rain} mm
                      </StyledTableCell>
                    ))}
              </TableRow>
              <TableRow>
                <StyledTableCell align="center" sx={{ minWidth: 120, borderRight: '1px solid #d0d0d0' }}>
                  바람(㎧)
                </StyledTableCell>
                {weatherInfo.length === 0
                  ? ''
                  : weatherInfo.map((column, index) => (
                      <StyledTableCell
                        key={index}
                        align="center"
                        sx={{ minWidth: 120, borderRight: '1px solid #d0d0d0' }}
                      >
                        <TbNavigationFilled
                          style={{
                            fontSize: '1.25rem',
                            transform: `rotate(${column.Data.v_windDirRaw}deg)`,
                          }}
                        />
                        <br />
                        {column.Data.v_wind} ㎧
                      </StyledTableCell>
                    ))}
              </TableRow>
              <TableRow>
                <StyledTableCell align="center" sx={{ minWidth: 120, borderRight: '1px solid #d0d0d0' }}>
                  습도(%)
                </StyledTableCell>
                {weatherInfo.length === 0
                  ? ''
                  : weatherInfo.map((column, index) => (
                      <StyledTableCell
                        key={index}
                        align="center"
                        sx={{ minWidth: 120, borderRight: '1px solid #d0d0d0' }}
                      >
                        {column.Data.v_humidity} %
                      </StyledTableCell>
                    ))}
              </TableRow>
            </TableBody>
          </Table>
        </StyledTableContainer>
      </CustomModal>
    </>
  );
}
