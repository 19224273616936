import {
  Avatar,
  Box,
  Card,
  ClickAwayListener,
  Collapse,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useState } from 'react';
import { convertStringToFormatDateString } from '../../../utils/dateUtil';

// 인버터 계통도 내 ACB 세부정보 표시 컴포넌트
export default function TransMapCard({ acbListInfo, isDesktop }) {
  const [expanded, setExpanded] = useState(false);
  const [plantAcbTooltipOpenMap, setPlantAcbTooltipOpenMap] = useState({});
  const [plantCbTooltipOpenMap, setPlantCbTooltipOpenMap] = useState({});

  const handleInvExpandClick = () => {
    setExpanded(!expanded);
  };

  // 클릭한 ACB 아바타의 툴팁 상태를 열기
  const handlePlantAcbAvatarClick = (serialnum) => {
    setPlantAcbTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: true }));
  };
  // ACB 아바타의 툴팁 상태를 닫기
  const handlePlantAcbTooltipClose = (serialnum) => {
    setPlantAcbTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: false }));
  };

  // 클릭한 CB 아바타의 툴팁 상태를 열기
  const handlePlantCbAvatarClick = (serialnum) => {
    setPlantCbTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: true }));
  };
  // CB 아바타의 툴팁 상태를 닫기
  const handlePlantCbTooltipClose = (serialnum) => {
    setPlantCbTooltipOpenMap((prevMap) => ({ ...prevMap, [serialnum]: false }));
  };

  return (
    <>
      <Box sx={{ pb: 0, mt: 1 }}>
        <Card
          variant="outlined"
          sx={{
            padding: {
              xs: 0,
              sm: 2,
            },
            borderRadius: '14px',
            boxShadow: '0px 3px 12px #00000014',
            '&:hover': { borderColor: 'blue', borderWidth: '2px' },
            width: isDesktop ? '50%' : '100%',
          }}
        >
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            width="100%"
            spacing={2}
          >
            <Stack
              sx={{
                padding: {
                  xs: 1,
                  sm: 0,
                },
              }}
              flex={1}
            >
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="body2">
                  <strong>{acbListInfo.acbname}</strong>
                </Typography>
                <Avatar sx={{ width: '25px', height: '25px', bgcolor: '#1981E7' }}>
                  <ExpandMoreIcon
                    onClick={handleInvExpandClick}
                    sx={{
                      color: '#fff',
                      transform: !expanded ? 'rotate(0deg)' : 'rotate(180deg)',
                      cursor: 'pointer',
                    }}
                  />
                </Avatar>
              </Box>
              <Stack spacing={0} direction="row">
                <Box sx={{ m: 1, display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2">
                    <strong>발전 상태</strong>
                  </Typography>
                  <Box sx={{ ml: 1 }}>
                    {isDesktop ? (
                      <Tooltip title={acbListInfo.nodata === '0' ? '발전 중' : '발전 중단'} arrow>
                        <Avatar
                          sizes="small"
                          sx={{
                            width: 15,
                            height: 15,
                            bgcolor: acbListInfo.nodata === '0' ? '#64B346' : 'red',
                            color: acbListInfo.nodata === '0' ? '#64B346' : 'red',
                          }}
                        >
                          {''}
                        </Avatar>
                      </Tooltip>
                    ) : (
                      <ClickAwayListener
                        onClickAway={() => handlePlantAcbTooltipClose(acbListInfo.serialnum)}
                      >
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            open={!!plantAcbTooltipOpenMap[acbListInfo.serialnum]}
                            onClose={() => handlePlantAcbTooltipClose(acbListInfo.serialnum)}
                            title={acbListInfo.nodata === '0' ? '발전 중' : '발전 중단'}
                            arrow
                          >
                            <Avatar
                              sizes="small"
                              sx={{
                                width: 15,
                                height: 15,
                                bgcolor: acbListInfo.nodata === '0' ? '#64B346' : 'red',
                                color: acbListInfo.nodata === '0' ? '#64B346' : 'red',
                              }}
                              onClick={() => handlePlantAcbAvatarClick(acbListInfo.serialnum)}
                            >
                              {''}
                            </Avatar>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    )}
                  </Box>
                </Box>
                <Box sx={{ m: 1, display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2">
                    <strong>CB 상태</strong>
                  </Typography>
                  <Box sx={{ ml: 1 }}>
                    {isDesktop ? (
                      <Tooltip title={acbListInfo.cbstatus === '0' ? '발전 중' : '발전 중단'} arrow>
                        <Avatar
                          sizes="small"
                          sx={{
                            width: 15,
                            height: 15,
                            bgcolor: acbListInfo.cbstatus === '0' ? '#64B346' : 'red',
                            color: acbListInfo.cbstatus === '0' ? '#64B346' : 'red',
                          }}
                        >
                          {''}
                        </Avatar>
                      </Tooltip>
                    ) : (
                      <ClickAwayListener onClickAway={() => handlePlantCbTooltipClose(acbListInfo.serialnum)}>
                        <div>
                          <Tooltip
                            PopperProps={{
                              disablePortal: true,
                            }}
                            open={!!plantCbTooltipOpenMap[acbListInfo.serialnum]}
                            onClose={() => handlePlantCbTooltipClose(acbListInfo.serialnum)}
                            title={acbListInfo.cbstatus === '0' ? '발전 중' : '발전 중단'}
                            arrow
                          >
                            <Avatar
                              sizes="small"
                              sx={{
                                width: 15,
                                height: 15,
                                bgcolor: acbListInfo.cbstatus === '0' ? '#64B346' : 'red',
                                color: acbListInfo.cbstatus === '0' ? '#64B346' : 'red',
                              }}
                              onClick={() => handlePlantCbAvatarClick(acbListInfo.serialnum)}
                            >
                              {''}
                            </Avatar>
                          </Tooltip>
                        </div>
                      </ClickAwayListener>
                    )}
                  </Box>
                </Box>
              </Stack>
              <Collapse in={expanded} timeout="auto" unmountOnExit>
                <Divider sx={{ mb: 1, mt: 1 }} />
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} color="primary">
                    마지막 통신 시간 :
                  </Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} sx={{ ml: 1 }}>
                    {convertStringToFormatDateString(acbListInfo.localdate)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>발전 상태</Typography>
                  <Typography
                    variant={isDesktop ? 'subtitle1' : 'subtitle2'}
                    color={acbListInfo.nodata === '0' ? 'primary' : 'error'}
                    sx={{ ml: 1 }}
                  >
                    {acbListInfo.nodata === '0' ? '발전 중' : '발전 중단'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>상태 코드</Typography>
                  <Typography
                    variant={isDesktop ? 'subtitle1' : 'subtitle2'}
                    color={acbListInfo.status === '1' ? 'primary' : 'error'}
                    textAlign="end"
                  >
                    {acbListInfo.status}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>CB 상태</Typography>
                  <Typography
                    variant={isDesktop ? 'subtitle1' : 'subtitle2'}
                    color={acbListInfo.cbstatus === '0' ? 'primary' : 'error'}
                    textAlign="end"
                  >
                    {acbListInfo.cbstatus === '0' ? '발전 중' : '발전 중단'}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>지락 전류</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    0 A
                  </Typography>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} color="primary">
                    전압(AC)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>R상(V)</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {acbListInfo.ru?.toFixed(2)} V
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>S상(V)</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {acbListInfo.su?.toFixed(2)} V
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>T상(V)</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {acbListInfo.tu?.toFixed(2)} V
                  </Typography>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} color="error">
                    전류(AC)
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>R상(A)</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {acbListInfo.ri?.toFixed(2)} A
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>S상(A)</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {acbListInfo.si?.toFixed(2)} A
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>T상(A)</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {acbListInfo.ti?.toFixed(2)} A
                  </Typography>
                </Box>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>기타 정보</Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>역률(%)</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {acbListInfo.pFactor?.toFixed(1)} %
                  </Typography>
                </Box>
                <Box
                  sx={{
                    mt: 2,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'}>주파수(Hz)</Typography>
                  <Typography variant={isDesktop ? 'subtitle1' : 'subtitle2'} textAlign="end">
                    {acbListInfo.fac?.toFixed(1)} Hz
                  </Typography>
                </Box>
              </Collapse>
            </Stack>
          </Stack>
        </Card>
      </Box>
    </>
  );
}
