import { Box, CircularProgress } from '@mui/material';
import solLogoLogin from '../../assets/EtcImg/sol_logo_login.png';

// 로그인 상태에 따라 새로고침을 할 때 다른 화면을 보여주는 역할을 하는 컴포넌트

function CircularProgressWithLabel() {
  return (
    <Box sx={{ position: 'relative', display: 'inline-flex', mb: 15 }}>
      <CircularProgress size={150} thickness={2.5} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img src={solLogoLogin} alt="SOLARCUBE" />
      </Box>
    </Box>
  );
}

export default function LaunchScreen() {
  const circleStyle = {
    width: '160px',
    height: '160px',
    backgroundColor: '#5373EC',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
  };

  const imageStyle = {
    width: '48%',
    height: 'auto',
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}
    >
      {localStorage.getItem('login') === 'login' ? (
        <CircularProgressWithLabel />
      ) : (
        <Box style={circleStyle} sx={{ mb: 15 }}>
          <img src={solLogoLogin} alt="SOLARCUBE" style={imageStyle} />
        </Box>
      )}
    </Box>
  );
}
